// React
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// Material Ui
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// Utils
import { isAdmin } from '../Utils/utils'

const useStyles = makeStyles(theme => ({
  root: {
    alignSelf: 'flex-start',
    marginTop: 16,
    marginBottom: 16,
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'center',
      margin: '5% 0'
    },
    fontSize: theme.typography.generalFontSize
  },
  button: {
    borderRadius: 8,
    padding: '4px 24px'
  },
}))

const AddButton = props => {
  const {
    children,
    isForAdmin,
    onChange,
    to,
    ...rest
  } = props
  const classes = useStyles()
  const change = onChange ? {
    onChange
  } : {}
  const toLink = to ? {
    component: Link,
    to
  } : {}
  if(isForAdmin && !isAdmin()) {
    return null
  }
  return (
    <Grid item className={classes.root}>
      <Button
        className={clsx(classes.root)}
        variant='contained'
        color='primary'
        {...change}
        {...toLink}
        {...rest}
      >
        {children}
      </Button>
    </Grid>
  )
}

AddButton.defaultProps = {
  isForAdmin: true
}

AddButton.propTypes = {
  onChange: PropTypes.func,
  to: PropTypes.string,
  type: PropTypes.string,
}
export default AddButton

