import React from 'react';
import NumberFormat from 'react-number-format';

export default function NumberFormatCustom(props) {
	const {
		inputRef,
		onChange,
		name,
		notifyFn = null,
		significantFigures = 2,
		...other
	} = props;
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						value: values.value,
						name,
					},
				});
			}}
			thousandSeparator
			allowNegative
			decimalScale={significantFigures}
		/>
	);
}
