import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// Helmet
import Helmet from 'react-helmet'
// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './DistanceContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_DISTANCE, CREATE_DISTANCE_INFORMATION } from '../Queries/Queries'

// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_DISTANCE } from '../Mutations/Mutations'
// Utils
import {
  ecaDistRequired,
  fullDistRequired,
  portID1Required,
  portID2Required,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import CreateOptionsContainer from '../Utils/CreateOptionsContainer'
import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'

import Grid from '@material-ui/core/Grid'
import { makeStyles } from "@material-ui/core/styles";


const UpsertDistanceQueryHelper = (props) => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.distance
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper query={CREATE_DISTANCE_INFORMATION}>
      {({ data }) => {
        const { createDistanceInformation } = data

        if (createDistanceInformation) {
          const { ports } = createDistanceInformation
          const emptyOptions = []

          if (ports.length <= 1) {
            emptyOptions.push(upsertContent.emptyObjects.ports)
          }

          if (emptyOptions.length > 0) {
            return (
              <CreateOptionsContainer
                options={emptyOptions}
                name={upsertContent.module.name}
              />
            )
          }
          const portsArray = []
          if (ports.length >= 0) {
            ports.map((item) => {
              return portsArray.push({ id: item.id, name: item.portName })
            })
          }
          return (
            <UpsertDistance
              searchInput={searchInput}
              skip={skip}
              ports={portsArray}
              {...rest}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}
UpsertDistanceQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default withRouter(UpsertDistanceQueryHelper)

const UpsertDistance = (props) => {
  const { searchInput, skip, ...rest } = props

  return (
    <QueryHelper
      query={GET_DISTANCE}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let distance = null
        if (data && data.getDistance && data.getDistance.distance) {
          distance = data.getDistance.distance
        }
        return (
          <>
            {
              distance
                ? <Helmet><title>Update Distance</title></Helmet>
                : <Helmet><title>Create Distance</title></Helmet>
            }
            <CreateDistance {...rest} distance={distance} />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertDistance.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const updateStack = (stack, currentKey) => {
  const newStack = stack.filter((element) => element !== currentKey)
  newStack.push(currentKey)
  return newStack
}

// const formatNumber = (numberString) => {
//   console.log(numberString)
//   try {
//     return parseInt(numberString.split(',').join(''))
//   } catch (err) {
//     return numberString
//   }
// }

//! ! Important Updating a Distance will always update results that's why there's no edit
const useStyles = makeStyles((theme) => ({
  roundCorners: {
    borderRadius: '10px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

const CreateDistance = (props) => {
  const { distance, ports, refetchQueries } = props
  const content = distance ? upsertContent.update : upsertContent.create
  const { user } = useContext(UserContext)

  const classes = useStyles()

  const [stack, setStack] = useState(['total', 'ecaDist', 'fullDist'])

  const validation = {
    ecaDist: ecaDistRequired,
    fullDist: fullDistRequired,
    portID1: portID1Required,
    portID2: portID2Required
  }

  const initialValues = {
    fullDist: distance ? distance.fullDist : '',
    portID1: distance ? distance.portID1.id : '',
    portID2: distance ? distance.portID2.id : '',
    ecaDist: distance ? distance.ecaDist : '',
    total: distance ? distance.ecaDist + distance.fullDist : ''
  }

  const port1Form = {
    portID1: useFormInput({
      initialValue: initialValues.portID1,
      select: true,
      selectValues: ports,
      label: 'portID1',
      required: true,
      smallInput: true
    })
  }

  const port2Form = {
    portID2: useFormInput({
      initialValue: initialValues.portID2,
      select: true,
      selectValues: ports,
      label: 'portID2',
      required: true,
      smallInput: true
    })
  }

  const fullDistForm = {
    fullDist: useFormInput({
      initialValue: initialValues.fullDist,
      label: 'nonecaDist',
      type: 'number',
      required: true,
      notifyFn: handleFullDistChange
    })
  }

  const ecaDistForm = {
    ecaDist: useFormInput({
      initialValue: initialValues.ecaDist,
      label: 'ecaDist',
      type: 'number',
      required: true,
      notifyFn: handleEcaDistChange
    })
  }

  const totalForm = {
    total: useFormInput({
      initialValue: initialValues.total,
      label: 'Total',
      type: 'number',
      required: false,
      notifyFn: handleTotalChange
    })
  }

  const [changedByComputer, setChangedByComputer] = useState({
    ecaDist: false,
    fullDist: false,
    total: false
  })

  function handleEcaDistChange (event) {
    if (changedByComputer.ecaDist) {
      setChangedByComputer((prevState) => {
        return {
          ecaDist: false,
          fullDist: false,
          total: false
        }
      })
    } else {
      const currentStack = updateStack(stack, 'ecaDist')
      const ecaDist = event.target.value
      const total = totalForm.total.getValue()
      const fullDist = fullDistForm.fullDist.getValue()

      if (currentStack[0] !== 'ecaDist') {
        if (currentStack[0] === 'total') {
          if (fullDist && ecaDist) {
            setChangedByComputer({
              ecaDist: false,
              total: true,
              fullDist: false
            })
            totalForm.total.setValue(parseFloat(ecaDist) + parseFloat(fullDist))
          }
        }

        if (currentStack[0] === 'fullDist') {
          if (total && ecaDist) {
            setChangedByComputer({
              ecaDist: false,
              total: false,
              fullDist: true
            })
            fullDistForm.fullDist.setValue(total - ecaDist)
          }
        }
      }

      setStack(currentStack)
    }
  }

  function handleFullDistChange (event) {
    if (changedByComputer.fullDist) {
      setChangedByComputer((prevState) => {
        return {
          ecaDist: false,
          fullDist: false,
          total: false
        }
      })
    } else {
      const currentStack = updateStack(stack, 'fullDist')
      const ecaDist = ecaDistForm.ecaDist.getValue()
      const total = totalForm.total.getValue()
      const fullDist = event.target.value

      if (currentStack[0] !== 'fullDist') {
        if (currentStack[0] === 'total') {
          if (ecaDist && fullDist) {
            setChangedByComputer({
              ecaDist: false,
              total: true,
              fullDist: false
            })
            totalForm.total.setValue(parseFloat(ecaDist) + parseFloat(fullDist))
          }
        } else if (currentStack[0] === 'ecaDist') {
          if (total && fullDist) {
            setChangedByComputer({
              ecaDist: true,
              total: false,
              fullDist: false
            })
            ecaDistForm.ecaDist.setValue(total - fullDist)
          }
        }
      }

      setStack(currentStack)
    }
  }

  function handleTotalChange (event) {
    if (changedByComputer.total) {
      setChangedByComputer((prevState) => {
        return {
          ecaDist: false,
          fullDist: false,
          total: false
        }
      })
    } else {
      const currentStack = updateStack(stack, 'total')
      const total = event.target.value
      const ecaDist = ecaDistForm.ecaDist.getValue()
      const fullDist = fullDistForm.fullDist.getValue()

      if (currentStack[0] !== 'total') {
        if (currentStack[0] === 'ecaDist') {
          if (total && fullDist) {
            setChangedByComputer({
              ecaDist: true,
              total: false,
              fullDist: false
            })
            ecaDistForm.ecaDist.setValue(total - fullDist)
          }
        } else if (currentStack[0] === 'fullDist') {
          if (total && ecaDist) {
            setChangedByComputer({
              ecaDist: false,
              total: false,
              fullDist: true
            })
            fullDistForm.fullDist.setValue(total - ecaDist)
          }
        }
      }

      setStack(currentStack)
    }
  }

  const input = {
    ...(distance && { id: distance.id }),
    fullDist: fullDistForm.fullDist.getValue(),
    portID1: port1Form.portID1.getValue(),
    portID2: port2Form.portID2.getValue(),
    ecaDist: ecaDistForm.ecaDist.getValue()
  }

  const next = ({ mutation }) => {
    validateStep({
      form: {
        portID1: port1Form.portID1,
        portID2: port2Form.portID2,
        fullDist: fullDistForm.fullDist,
        ecaDist: ecaDistForm.ecaDist
      },
      next: () => mutation(),
      validation
    })
  }
  return (
    <MutationHelper
      mutation={UPSERT_DISTANCE}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted })}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.roundCorners} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb
                current={content.current}
                links={content.breadCrumbs}
              />
              <Grid container>
                <Grid item xs={12} container>
                  <Grid item md={6}>
                    <RootForm form={port1Form} validation={validation} />
                  </Grid>
                  <Grid item md={6}>
                    <RootForm autoFocus={false} form={port2Form} validation={validation} />
                  </Grid>
                </Grid>
                <Grid item xs={12} container>
                  <Grid item md={4}>
                    <RootForm form={totalForm} autoFocus={false} validation={{}} />
                  </Grid>
                  <Grid item md={4}>
                    <RootForm form={ecaDistForm} autoFocus={false} validation={validation} />
                  </Grid>
                  <Grid item md={4}>
                    <RootForm form={fullDistForm} autoFocus={false} validation={validation} />
                  </Grid>

                </Grid>
              </Grid>

              <RootButton
                className={classes.button}
                disabled={result.loading || user.disabled}
                justifyContent='center'
                onClick={() => next({ mutation })}
              >
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateDistance.propTypes = {
  distance: PropTypes.object,
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}
