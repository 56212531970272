// React Library
import React, { useState, useRef, useEffect } from 'react'

// Helmet
import Helmet from 'react-helmet'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Content
import { show as content } from './ShipSectorsContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_SHIPSECTORS } from '../Queries/Queries'

// Mutation Related
import { DELETE_SHIPSECTOR } from '../Mutations/Mutations'

// Utils
import AddIconButton from '../Utils/AddIconButton'
import RootAgGrid from '../Utils/RootAgGrid'
import RootGrid from '../Utils/RootGrid'
import SearchTextInput from '../Utils/SearchTextInput'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Query Component
const ShowShipSectors = props => {
  return (
    <>
      <Helmet>
        <title>Ship Sectors</title>
      </Helmet>
      <QueryHelper
        query={GET_SHIPSECTORS}
      >
        {({ data }) => {
          const { getShipSectors: { shipSectors } } = data

          // No Formatting Necessary...

          // Render
          return (
            <ShowShipSectorsTable
              shipSectors={shipSectors}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowShipSectorsTable = props => {
  const { shipSectors = [] } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchShipSector, setSearchShipSector] = useState(shipSectors)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['shipSectorName'], {
    idFieldOrFunction: 'id'
  })

  // The Array To Be Filtered (All Ship Sectors In the DB)
  ts.addAll(shipSectors)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchShipSector(shipSectors)
      } else {
        const foundShipSectors = ts.get(search, TrieSearch.UNION_REDUCER)
        setSearchShipSector(foundShipSectors)
      }
    }
  }, [search, shipSectors, ts])

  // Column Structure
  const columnDefs = [
    { field: 'shipSectorName', headerName: 'Ship Sector', cellRenderer: 'link' },
    { field: 'dwtMin', headerName: 'Min Dwt' },
    { field: 'dwtMax', headerName: 'Max Dwt' },
    { field: 'sw', headerName: 'SW' },
    { field: 'bw', headerName: 'BW' },
    { field: 'fw', headerName: 'FW' },
    { field: 'tf', headerName: 'TF' }
  ]

  // Link Of The Update Ship Sector Page
  const updateLink = 'update-ship-sector'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>

      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Ship Sectors Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={searchShipSector}
        mutation={DELETE_SHIPSECTOR}
        objectNameKey1='shipSectorName'
        type='Ship Sector'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowShipSectors)
