import React, { useReducer, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
// Helmet
import Helmet from 'react-helmet'
// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './VesselsContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_VESSEL } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_VESSEL } from '../Mutations/Mutations'
// Utils
import {
  ballastIFOConsumptionRequired,
  ballastMGOConsumptionRequired,
  ballastSpeedRequired,
  cubicFtRequired,
  cubicMtRequired,
  deadWeightRequired,
  draftRequired,
  // IMONUmberRequired,
  inPortIFOIdleRequired,
  inPortIFOWorkingRequired,
  inPortMGOIdleRequired,
  inPortMGOWorkingRequired,
  ladenIFOConsumptionRequired,
  ladenMGOConsumptionRequired,
  ladenSpeedRequired,
  nameRequired,
  tpcRequired,
  validateStep,
  yearRequired
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import RemoveIconButton from '../Utils/RemoveIconButton'
import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import RootTextField from '../Utils/RootTextField'
import Grid from '@material-ui/core/Grid'
import TextError from '../Utils/TextError'

import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

const validatejs = require('validate.js')

// const _updatedRoutes = subscribeToMore => {
//   subscribeToMore({
//     document: UPDATING_AFTER_EDIT,
//     updateQuery: (previousData, { subscriptionData }) => {
//       if (!subscriptionData.data) {
//         return previousData
//       }

//       const updatedRoutes = subscriptionData.data.counter

//       //Checks ids to see if its repeated
//       //const exists = previousData.getAllMessages.messages.find(({ id }) => id === newMessage.id)

//       //if (exists) return previousData
//       console.log('Updated Routes', updatedRoutes)
//       return Object.assign({}, previousData, {
//         counter: subscriptionData.data.counter
//       })
//     },
//   })
// }

const AddIconButton = (props) => {
  return (
    <IconButton {...props}>
      <AddIcon />
    </IconButton>
  )
}

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: '0 auto',
    width: '80%'
  },
  container: {
    width: '95%',
    marginLeft: '2.5%'
  },
  fiveContainer: {
    width: '95%',
    marginLeft: '2.5%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  fiveItem: {
    flexBasis: 'auto',
    flexGrow: '1'
  },
  paper: {
    borderRadius: '10px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.emptyValue]
    case 'remove':
      if (state.length - 1 === 0) {
        return state
      }
      return state.filter((_, index) => index !== action.index)
    case 'bspeed':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              bspeed: parseFloat(action.value)
            },
            {
              bspeed: ballastSpeedRequired
            }
          )
          return {
            ...item,
            bspeed: {
              value: parseFloat(action.value),
              valid: !validationResult,
              errorMessage: validationResult ? validationResult.bspeed[0] : ''
            }
          }
        } else {
          return item
        }
      })
    case 'bifo':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              bifo: parseFloat(action.value)
            },
            {
              bifo: ballastIFOConsumptionRequired
            }
          )
          return {
            ...item,
            bifo: {
              value: parseFloat(action.value),
              valid: !validationResult,
              errorMessage: validationResult ? validationResult.bifo[0] : ''
            }
          }
        } else {
          return item
        }
      })
    case 'bmgo':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              bmgo: parseFloat(action.value)
            },
            {
              bmgo: ballastMGOConsumptionRequired
            }
          )
          return {
            ...item,
            bmgo: {
              value: parseFloat(action.value),
              valid: !validationResult,
              errorMessage: validationResult ? validationResult.bmgo[0] : ''
            }
          }
        } else {
          return item
        }
      })
    case 'lspeed':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              lspeed: parseFloat(action.value)
            },
            {
              lspeed: ladenSpeedRequired
            }
          )
          return {
            ...item,
            lspeed: {
              value: parseFloat(action.value),
              valid: !validationResult,
              errorMessage: validationResult ? validationResult.lspeed[0] : ''
            }
          }
        } else {
          return item
        }
      })
    case 'lifo':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              lifo: parseFloat(action.value)
            },
            {
              lifo: ladenIFOConsumptionRequired
            }
          )
          return {
            ...item,
            lifo: {
              value: parseFloat(action.value),
              valid: !validationResult,
              errorMessage: validationResult ? validationResult.lifo[0] : ''
            }
          }
        } else {
          return item
        }
      })
    case 'lmgo':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              lmgo: parseFloat(action.value)
            },
            {
              lmgo: ladenMGOConsumptionRequired
            }
          )
          return {
            ...item,
            lmgo: {
              value: parseFloat(action.value),
              valid: !validationResult,
              errorMessage: validationResult ? validationResult.lmgo[0] : ''
            }
          }
        } else {
          return item
        }
      })
    default:
      break
  }
}

const UpsertVessel = (props) => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.vessel
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={GET_VESSEL}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let vessel = null
        if (data && data.getVessel && data.getVessel.vessel) {
          vessel = data.getVessel.vessel
        }
        return (
          <>
            {
              vessel
                ? <Helmet><title>Update Vessel</title></Helmet>
                : <Helmet><title>Create Vessel</title></Helmet>
            }
            <CreateVessel {...rest} vessel={vessel} />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertVessel.defaultProps = {
  refetchQueries: []
}

UpsertVessel.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array.isRequired
}

export default withRouter(UpsertVessel)

const CreateVessel = (props) => {
  const { vessel, refetchQueries } = props

  const content = vessel ? upsertContent.update : upsertContent.create
  const [editRouteResults, setEditRouteResults] = useState(false)
  // const localState = JSON.parse(localStorage.getItem(USER))
  const { user } = useContext(UserContext)

  const validation = {
    vesselName: nameRequired,
    cubicFT: cubicFtRequired,
    cubicMT: cubicMtRequired,
    dwt: deadWeightRequired,
    draft: draftRequired,
    // IMONumber: IMONUmberRequired,
    inPortIFOIdle: inPortIFOIdleRequired,
    inPortIFOWorking: inPortIFOWorkingRequired,
    inPortMGOIdle: inPortMGOIdleRequired,
    inPortMGOWorking: inPortMGOWorkingRequired,
    yearBuilt: yearRequired,
    tpc: tpcRequired
  }

  const emptySpeedConsumption = {
    bspeed: {
      value: 0,
      valid: true,
      errorMessage: ''
    },
    bifo: {
      value: 0,
      valid: true,
      errorMessage: ''
    },
    bmgo: {
      value: 0,
      valid: true,
      errorMessage: ''
    },
    lspeed: {
      value: 0,
      valid: true,
      errorMessage: ''
    },
    lifo: {
      value: 0,
      valid: true,
      errorMessage: ''
    },
    lmgo: {
      value: 0,
      valid: true,
      errorMessage: ''
    }
  }

  const vesselSpeedConsumption = {
    bspeed: vessel ? vessel.ballastSpeedArray : [0],
    bifo: vessel ? vessel.ballastIFOConsumption : [0],
    bmgo: vessel ? vessel.ballastMGOConsumption : [0],
    lspeed: vessel ? vessel.ladenSpeedArray : [0],
    lifo: vessel ? vessel.ladenIFOConsumption : [0],
    lmgo: vessel ? vessel.ladenMGOConsumption : [0]
  }

  const createInitialSpeedConsumption = (params = {}) => {
    const { data } = params
    const result = []
    for (let i = 0; i < data.bspeed.length; i++) {
      result.push({
        bspeed: {
          value: data.bspeed[i],
          valid: data.bspeed[i] !== null,
          errorMessage:
            data.bspeed[i] === null && '*Ballast Speed must be a valid number'
        },
        bifo: {
          value: data.bifo[i],
          valid: data.bifo[i] !== null,
          errorMessage:
            data.bifo[i] === null &&
            '*Ballast IFO Consumption must be a valid number'
        },
        bmgo: {
          value: data.bmgo[i],
          valid: data.bmgo[i] !== null,
          errorMessage:
            data.bmgo[i] === null &&
            'Ballast MGO Consumption must be a valid number'
        },
        lspeed: {
          value: data.lspeed[i],
          valid: data.lspeed[i] !== null,
          errorMessage:
            data.lspeed[i] === null && '*Laden Speed must be a valid number'
        },
        lifo: {
          value: data.lifo[i],
          valid: data.lifo[i] !== null,
          errorMessage:
            data.lifo[i] === null &&
            '*Laden IFO Consumption must be a valid number'
        },
        lmgo: {
          value: data.lmgo[i],
          valid: data.lmgo[i] !== null,
          errorMessage:
            data.lmgo[i] === null &&
            '*Laden MGO Consumption must be a valid number'
        }
      })
    }
    return result
  }

  const initialValues = {
    vesselName: vessel ? vessel.vesselName : '',
    cubicFT: vessel ? vessel.cubicFT : '',
    cubicMT: vessel ? vessel.cubicMT : '',
    dwt: vessel ? vessel.dwt : '',
    draft: vessel ? vessel.draft : '',
    IMONumber: vessel ? vessel.IMONumber : '',
    inPortIFOIdle: vessel ? vessel.inPortIFOIdle : '',
    inPortIFOWorking: vessel ? vessel.inPortIFOWorking : '',
    inPortMGOIdle: vessel ? vessel.inPortMGOIdle : '',
    inPortMGOWorking: vessel ? vessel.inPortMGOWorking : '',
    yard: vessel ? vessel.yard : '',
    yearBuilt: vessel ? vessel.yearBuilt : '',
    tpc: vessel ? vessel.tpc : '',
    speedConsumption: vessel
      ? createInitialSpeedConsumption({
        data: vesselSpeedConsumption
      })
      : [emptySpeedConsumption]
  }

  const form11 = {
    vesselName: useFormInput({
      initialValue: initialValues.vesselName,
      label: 'Name',
      required: true,
      autoFocus: !vessel
    })
  }

  const form12 = {
    IMONumber: useFormInput({
      initialValue: initialValues.IMONumber,
      type: 'text',
      label: 'IMO',
      required: false,
      autoFocus: false
    })
  }

  const form13 = {
    yard: useFormInput({ initialValue: initialValues.yard, label: 'Yard', autoFocus: false })
  }

  const form14 = {
    yearBuilt: useFormInput({
      initialValue: initialValues.yearBuilt,
      label: 'Built',
      type: 'text',
      autoFocus: false
    })
  }

  const form15 = {
    dwt: useFormInput({
      initialValue: initialValues.dwt,
      label: 'Dwt',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults,
      autoFocus: false
    })
  }

  const form16 = {
    draft: useFormInput({
      initialValue: initialValues.draft,
      type: 'number',
      label: 'Draft',
      required: true,
      isCalc: true,
      setEditRouteResults,
      autoFocus: false
    })
  }

  const form17 = {
    tpc: useFormInput({
      initialValue: initialValues.tpc,
      label: 'Tpc',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults,
      autoFocus: false
    })
  }

  const form18 = {
    cubicFT: useFormInput({
      initialValue: initialValues.cubicFT,
      notifyFn: handleCubicFT,
      label: 'Cubic FT',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults,
      autoFocus: false
    })
  }

  const form19 = {
    cubicMT: useFormInput({
      initialValue: initialValues.cubicMT,
      label: 'Cubic M3',
      notifyFn: handleCubicMT,
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults,
      autoFocus: false
    })
  }

  const [changedByComputer, setChangedByComputer] = useState({
    cubicFT: false,
    cubicMT: false
  })

  function handleCubicFT (event) {
    const { value } = event.target
    if (changedByComputer.cubicFT) {
      setChangedByComputer({ cubicFT: false, cubicMT: false })
    } else {
      if (value && parseFloat(value) > 0) {
        form19.cubicMT.setValue(parseFloat(value) / 35.316)
        setChangedByComputer((prevState) => ({
          cubicFT: false,
          cubicMT: true
        }))
      } else {
        form19.cubicMT.setValue(null)
        setChangedByComputer((prevState) => ({
          cubicFT: false,
          cubicMT: true
        }))
      }
    }
  }

  function handleCubicMT (event) {
    const { value } = event.target
    if (changedByComputer.cubicMT) {
      setChangedByComputer({ cubicFT: false, cubicMT: false })
    } else {
      if (value) {
        form18.cubicFT.setValue(parseFloat(value) * 35.316)
        setChangedByComputer((prevState) => ({
          cubicMT: false,
          cubicFT: true
        }))
      } else {
        form18.cubicFT.setValue(null)
        setChangedByComputer((prevState) => ({
          cubicMT: false,
          cubicFT: true
        }))
      }
    }
  }
  const form1 = {
    vesselName: useFormInput({
      initialValue: initialValues.vesselName,
      label: 'Name',
      required: true
    }),
    IMONumber: useFormInput({
      initialValue: initialValues.IMONumber,
      type: 'number',
      label: 'IMO'
    }),
    yard: useFormInput({ initialValue: initialValues.yard, label: 'yard' }),
    yearBuilt: useFormInput({
      initialValue: initialValues.yearBuilt,
      label: 'Built',
      type: 'number'
    }),
    dwt: useFormInput({
      initialValue: initialValues.dwt,
      label: 'Dwt',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    }),
    draft: useFormInput({
      initialValue: initialValues.draft,
      type: 'number',
      label: 'Draft',
      required: true,
      isCalc: true,
      setEditRouteResults
    }),
    tpc: useFormInput({
      initialValue: initialValues.tpc,
      label: 'tpc',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    }),
    cubicFT: useFormInput({
      initialValue: initialValues.cubicFT,
      label: 'cubicFT',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    }),
    cubicMT: useFormInput({
      initialValue: initialValues.cubicMT,
      label: 'cubicMT',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    })
  }

  const form2 = {
    inPortIFOIdle: useFormInput({
      initialValue: initialValues.inPortIFOIdle,
      label: 'Idle IFO',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    })
  }

  const form3 = {
    inPortIFOWorking: useFormInput({
      initialValue: initialValues.inPortIFOWorking,
      label: 'Working IFO',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    })
  }

  const form4 = {
    inPortMGOIdle: useFormInput({
      initialValue: initialValues.inPortMGOIdle,
      label: 'Idle MGO',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    })
  }

  const form5 = {
    inPortMGOWorking: useFormInput({
      initialValue: initialValues.inPortMGOWorking,
      label: 'Working MGO',
      type: 'number',
      required: true,
      isCalc: true,
      setEditRouteResults
    })
  }

  const [speedConsumption, setSpeedConsumption] = useReducer(reducer, [
    ...initialValues.speedConsumption
  ])

  const form = {
    ...form12,
    ...form13,
    ...form14,
    ...form15,
    ...form16,
    ...form17,
    ...form18,
    ...form19,
    ...form11,
    ...form2,
    ...form3,
    ...form4,
    ...form5
  }

  const customValidation = {
    bspeed: false,
    bifo: false,
    bmgo: false,
    lspeed: false,
    lifo: false,
    lmgo: false
  }

  const getSpeedConsumptionValues = (data, target) => {
    return data.map((item) => {
      if (item[target] === '') {
        customValidation[target] = true
        return customValidation[target]
      } else if (item[target] && item[target].value) {
        return parseFloat(item[target].value)
      } else {
        return parseFloat(item[target])
      }
    })
  }

  const validateSpeedConsumptionValues = (data, target) => {
    if (target === 'bspeed') {
      return getSpeedConsumptionValues(data, target)
    } else if (target === 'bifo') {
      return getSpeedConsumptionValues(data, target)
    }
    if (target === 'bmgo') {
      return getSpeedConsumptionValues(data, target)
    } else if (target === 'lspeed') {
      return getSpeedConsumptionValues(data, target)
    }
    if (target === 'lifo') {
      return getSpeedConsumptionValues(data, target)
    } else if (target === 'lmgo') {
      return getSpeedConsumptionValues(data, target)
    }
  }

  const arrayValidator = (array, key) => {
    let valid = true
    for (let i = 0; i < array.length; i++) {
      const element = array[i]
      valid = valid && element[key].valid
    }
    return valid
  }

  const validateSpeedConsumptionArray = (speedConsumption) => {
    if (
      arrayValidator(speedConsumption, 'bspeed') &&
      arrayValidator(speedConsumption, 'bifo') &&
      arrayValidator(speedConsumption, 'bmgo') &&
      arrayValidator(speedConsumption, 'lspeed') &&
      arrayValidator(speedConsumption, 'lifo') &&
      arrayValidator(speedConsumption, 'lmgo')
    ) {
      return true
    } else {
      return false
    }
  }

  const input = {
    ...(vessel && { id: vessel.id }),
    vesselName: form.vesselName.getValue(),
    cubicFT: form.cubicFT.getValue(),
    cubicMT: form.cubicMT.getValue(),
    dwt: form.dwt.getValue(),
    draft: form.draft.getValue(),
    IMONumber: parseFloat(form.IMONumber.getValue()),
    inPortIFOIdle: form.inPortIFOIdle.getValue(),
    inPortIFOWorking: form.inPortIFOWorking.getValue(),
    inPortMGOIdle: form.inPortMGOIdle.getValue(),
    inPortMGOWorking: form.inPortMGOWorking.getValue(),
    yard: form.yard.getValue(),
    yearBuilt: parseFloat(form.yearBuilt.getValue()),
    tpc: form.tpc.getValue(),
    ballastSpeedArray: speedConsumption.map((element) => element.bspeed.value),
    ballastIFOConsumption: speedConsumption.map(
      (element) => element.bifo.value
    ),
    ballastMGOConsumption: speedConsumption.map(
      (element) => element.bmgo.value
    ),
    ladenSpeedArray: speedConsumption.map((element) => element.lspeed.value),
    ladenIFOConsumption: speedConsumption.map((element) => element.lifo.value),
    ladenMGOConsumption: speedConsumption.map((element) => element.lmgo.value),
    editRouteResults
  }

  const next = ({ mutation }) => {
    validateSpeedConsumptionArray(speedConsumption) &&
      validateStep({
        form,
        next: () => mutation(),
        validation
      })
  }

  const classes = useStyles()
  return (
    <MutationHelper
      mutation={UPSERT_VESSEL}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted })}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.paper} className={classes.paper} {...!props.onCompleted}>
              <RootBreadCrumb
                current={content.current}
                links={content.breadCrumbs}
              />
              <Grid container className={classes.container}>
                <Grid item md={3}>
                  <RootForm autoFocus={!vessel} form={form11} validation={validation} />
                </Grid>
                <Grid item md={3}>
                  <RootForm form={form12} validation={validation} />
                </Grid>
                <Grid item md={3}>
                  <RootForm form={form13} validation={validation} />
                </Grid>
                <Grid item md={3}>
                  <RootForm form={form14} validation={validation} />
                </Grid>
              </Grid>
              <Box className={classes.fiveContainer}>
                <Box className={classes.fiveItem}>
                  <RootForm form={form15} validation={validation} />
                </Box>
                <Box className={classes.fiveItem}>
                  <RootForm form={form16} validation={validation} />
                </Box>
                <Box className={classes.fiveItem}>
                  <RootForm form={form17} validation={validation} />
                </Box>
                <Box className={classes.fiveItem}>
                  <RootForm form={form18} validation={validation} />
                </Box>
                <Box className={classes.fiveItem}>
                  <RootForm form={form19} validation={validation} />
                </Box>
              </Box>

              <SpeedConsumptionInput
                emptyValue={emptySpeedConsumption}
                speedConsumption={speedConsumption}
                setSpeedConsumption={setSpeedConsumption}
                setEditRouteResults={setEditRouteResults}
              />

              <Grid container className={classes.container}>
                <RootForm
                  autoFocus={false}
                  form={form2}
                  validation={validation}
                  itemSize={3}
                />
                <RootForm
                  autoFocus={false}
                  form={form4}
                  validation={validation}
                  itemSize={3}
                />
                <RootForm
                  autoFocus={false}
                  form={form3}
                  validation={validation}
                  itemSize={3}
                />
                <RootForm
                  autoFocus={false}
                  form={form5}
                  validation={validation}
                  itemSize={3}
                />
              </Grid>
              <RootButton
                className={classes.button}
                disabled={user.disabled}
                justifyContent='center'
                onClick={() => next({ mutation })}
              >
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateVessel.defaultProps = {
  refetchQueries: []
}

CreateVessel.propTypes = {
  vessel: PropTypes.object,
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array.isRequired
}

const speedConsumptionInputStyles = makeStyles((theme) => ({
  root: {
    marginLeft: '2.5%',
    width: '95%'
  },
  boxContainer: {
    marginLeft: 7,
    width: '100%'
  },
  flexContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  flexItem: {
    flexBasis: '10%',
    flexGrow: '1'
  },
  button: {
    color: 'rgba(91, 196, 34, 1)'
  }
}))
const SpeedConsumptionInput = (props) => {
  const classes = speedConsumptionInputStyles()
  const {
    emptyValue,
    speedConsumption,
    setSpeedConsumption,
    setEditRouteResults
  } = props

  return (
    <Grid container className={classes.root}>
      <Box className={classes.boxContainer} display='flex' alignItems='center'>
        <Typography>Speed Consumption</Typography>
        <AddIconButton
          className={classes.button}
          justifyContent='center'
          onClick={() =>
            setSpeedConsumption({ type: 'add', emptyValue: emptyValue })}
        />
      </Box>
      {speedConsumption.map((item, index) => (
        <Grid key={index} container>
          <Box className={classes.flexContainer}>
            <Box className={classes.flexItem}>
              <RootTextField
                smallInput
                type='number'
                label='Ballast Speed'
                onChange={(e) => {
                  setSpeedConsumption({
                    value: e.target.value,
                    type: 'bspeed',
                    index: index
                  })
                  setEditRouteResults(true)
                }}
                value={item.bspeed.value}
              />
              <TextError error={item.bspeed.errorMessage} />
            </Box>
            <Box className={classes.flexItem}>
              <RootTextField
                smallInput
                type='number'
                label='Ballast IFO'
                onChange={(e) => {
                  setSpeedConsumption({
                    value: e.target.value,
                    type: 'bifo',
                    index: index
                  })
                  setEditRouteResults(true)
                }}
                value={item.bifo.value}
              />
              <TextError error={item.bifo.errorMessage} />
            </Box>
            <Box className={classes.flexItem}>
              <RootTextField
                smallInput
                type='number'
                label='Ballast MGO'
                onChange={(e) =>
                  setSpeedConsumption({
                    value: e.target.value,
                    type: 'bmgo',
                    index: index
                  })}
                value={item.bmgo.value}
              />
              <TextError error={item.bmgo.errorMessage} />
            </Box>
            <Box className={classes.flexItem}>
              <RootTextField
                smallInput
                type='number'
                label='Laden Speed'
                onChange={(e) =>
                  setSpeedConsumption({
                    value: e.target.value,
                    type: 'lspeed',
                    index: index
                  })}
                value={item.lspeed.value}
              />
              <TextError error={item.lspeed.errorMessage} />
            </Box>
            <Box className={classes.flexItem}>
              <RootTextField
                smallInput
                type='number'
                label='Laden IFO'
                onChange={(e) =>
                  setSpeedConsumption({
                    value: e.target.value,
                    type: 'lifo',
                    index: index
                  })}
                value={item.lifo.value}
              />
              <TextError error={item.lifo.errorMessage} />
            </Box>
            <Box className={classes.flexItem}>
              <RootTextField
                smallInput
                type='number'
                label='Laden MGO'
                onChange={(e) =>
                  setSpeedConsumption({
                    value: e.target.value,
                    type: 'lmgo',
                    index: index
                  })}
                value={item.lmgo.value}
              />
              <TextError error={item.lmgo.errorMessage} />
            </Box>
            {speedConsumption.length > 1 && (
              <Box className={classes.flexItem}>
                <RemoveIconButton
                  onClick={() => setSpeedConsumption({ type: 'remove', index })}
                />
              </Box>
            )}
          </Box>

        </Grid>
      ))}
    </Grid>
  )
}
