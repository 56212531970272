import gql from 'graphql-tag'

export const ACCEPT_CARGO_AND_OFFER = gql`
mutation acceptCargoAndOffer($input: AcceptCargoAndOfferInput!) {
  acceptCargoAndOffer(input: $input)
}
`
export const CREATE_APIKEY = gql`
mutation CreateApiKey($input: CreateApiKeyInput!) {
  createApiKey(input: $input) {
    apiKey {
      id
      name
      token
    }
  }
}
`

export const CREATE_BASE_ROUTE_FOR_CLIENT = gql`
mutation createBaseRouteForClient($input: CreateBaseRouteForClientInput!) {
  createBaseRouteForClient(input: $input) {
    route
  }
}
`

export const CREATE_CARGO_WORKING_WITHOUT_OFFER = gql`
mutation createCargoOfferWithoutOffer($input: CreateCargoOfferWithoutOfferInput!) {
  createCargoOfferWithoutOffer(input: $input)
}
`
export const CREATE_ROUTE_RESULTS_ARRAY = gql`
mutation CreateRouteResultsArray($input: CreateRouteResultsArrayInput!){
  createRouteResultsArray(input: $input) {
    routeResultsArray {
      id
      route {
        id
        routeName
        stem
        tolerance
        constants
        miscCost
        commodity {
          id
          commodityName
        }
        constants
        deliveryPort {
          portName
          zone {
            weatherMarginArray
          }
        }
        deliveryPortBunker
        loadPortArray {
          portName
          zone {
            weatherMarginArray
          }
        }
        loadPortArrayBunkerArray
        loadTermsArray
        loadTimeCountingArray
        dischargePortArray {
          portName
          zone {
            weatherMarginArray
          }
        }
        dischargePortArrayBunker
        dischargeTermsArray
        dischargeTimeCountingArray
        redeliveryPort {
          portName
          zone {
            weatherMarginArray
          }
        }
        redeliveryPortBunker
        viaBallastPortIDArray {
          portName
          zone {
            weatherMarginArray
          }
        }
        viaBallastPortBunkerArray
        viaLadenPortIDArray {
          portName
          zone {
            weatherMarginArray
          }
        }
        viaLadenPortBunkerArray
        viaRedeliveryPort {
          portName
          zone {
            weatherMarginArray
          }
        }
        viaRedeliveryPortBunker
        vessel {
          ballastSpeedArray
          ballastIFOConsumption
          ballastMGOConsumption
          inPortIFOIdle
          inPortIFOWorking
          inPortMGOIdle
          inPortMGOWorking
          ladenSpeedArray
          ladenIFOConsumption
          ladenMGOConsumption
          vesselName
          cubicFT
          dwt
          draft
          tpc
        }
      }
      routeResults {
        id
        portAccurateDistance
        intake
        commission
        dailyHire
        durationAtLoad
        durationAtDischarge
        durationAtSea
        positionDate
        portCosts
        fuelFOIDPrice
        fuelMGOPrice
        fuelFOIDTotal
        fuelMGOTotal
        profit
        totalCosts
        totalDuration
        totalFuel
        rate
        tce
      }
    }
    routeResultsArray2 {
      id
      route {
        id
        routeName
      }
      routeResults {
        id
        portAccurateDistance
        intake
        durationAtLoad
        durationAtDischarge
        durationAtSea
        positionDate
        portCosts
        fuelFOIDPrice
        fuelMGOPrice
        fuelFOIDTotal
        fuelMGOTotal
        profit
        totalCosts
        totalDuration
        rate
        totalFuel
        tce
      }
    }
    routeResultsArray3 {
      id
      route {
        id
        routeName
      }
      routeResults {
        id
        portAccurateDistance
        intake
        durationAtLoad
        durationAtDischarge
        durationAtSea
        positionDate
        portCosts
        fuelFOIDPrice
        fuelMGOPrice
        fuelFOIDTotal
        fuelMGOTotal
        profit
        totalCosts
        totalDuration
        rate
        totalFuel
        tce
      }
    }
  }
}
`

export const CREATE_MAPPING_FOR_ROUTE =  gql`
  mutation CreateMappingForRoute($input: CreateMappingForRouteInput!) {
    createMappingForRoute(input: $input) {
      fobFutureMapping {
        id
        client {
          id
        }
        commodity {
          id
        }
        route {
          id
        }
      }
    }
  }
`

export const DELETE_APIKEY = gql`
mutation DeleteApiKey($input: DeleteApiKeyInput!) {
  deleteApiKey(input: $input)
}
`

export const DELETE_BASE_ROUTE_FOR_CLIENT = gql`
mutation deleteBaseRouteForClient($input: DeleteBaseRouteForClientInput!) {
  deleteBaseRouteForClient(input: $input) {
    route
  }
}
`

export const DELETE_COMMODITY = gql`
  mutation DeleteCommodity($input: DeleteCommodityInput!){
    deleteCommodity(input: $input) {
      commodityId
    }
  }
`

export const DELETE_DISTANCE = gql`
mutation DeleteDistance($input: DeleteDistanceInput!){
  deleteDistance(input: $input) {
    distance {
      id
      portID1 {
        id
      }
      portID2 {
        id
      }
      fullDist
      ecaDist
    }
  }
}
`

export const DELETE_FORWARDCURVE = gql`
mutation DeleteForwardCurve($input: DeleteForwardCurveInput!){
  deleteForwardCurve(input: $input){
    forwardCurveId
  }
}
`

export const DELETE_FORWARDVALIDATION = gql`
mutation DeleteForwardValidation($input: DeleteForwardValidationInput!){
  deleteForwardValidation(input: $input) {
    forwardValidation {
      id
    }
  }
}
`

export const DELETE_OWNER = gql`
mutation DeleteOwner($input: DeleteOwnerInput!) {
  deleteOwner(input: $input) {
    owner {
      id
    }
  }
}
`

export const DELETE_PORT = gql`
mutation DeletePort($input: DeletePortInput!){
  deletePort(input: $input) {
    portId
  }
}
`

export const DELETE_POSITIONDATE = gql`
mutation DeletePositionDate($input: DeletePositionDateInput!){
  deletePositionDate(input: $input) {
    positionDate {
      id
    }
  }
}
`

export const DELETE_ROUTE = gql`
mutation DeleteRoute($input: DeleteRouteInput!){
  deleteRoute(input: $input) {
    route {
      id
    }
  }
}
`

export const DELETE_SHIPSECTOR = gql`
mutation DeleteShipSector($input: DeleteShipSectorInput!){
  deleteShipSector(input: $input) {
    shipSectorId
  }
}
`

export const DELETE_SPOTINDEX = gql`
  mutation DeleteSpotIndex($input: DeleteSpotIndexInput!){
    deleteSpotIndex(input: $input) {
      spotIndexId
    }
  }
`

export const DELETE_SPOTTCSPREAD = gql`
mutation DeleteSpotTCSpread($input: DeleteSpotTCSpreadInput!){
  deleteSpotTCSpread(input: $input) {
    spotTCSpreadId
  }
}
`

export const DELETE_TIMECOUNTING = gql`
  mutation DeleteTimeCounting($input: DeleteTimeCountingInput!){
    deleteTimeCounting(input: $input) {
      timeCountingId
    }
  }
`

export const DELETE_USER = gql`
  mutation deleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`

export const DELETE_VESSEL = gql`
  mutation DeleteVessel($input: DeleteVesselInput!){
    deleteVessel(input: $input) {
      vesselId
    }
  }
`

export const DELETE_VOYAGE_OFFER = gql`
  mutation DeleteVoyageOffer($input: DeleteVoyageOfferInput!){
    deleteVoyageOffer(input: $input) {
      voyageOffer {
        id
      }
    }
  }
`

export const DELETE_ZONE = gql`
  mutation DeleteZone($input: DeleteZoneInput!){
    deleteZone(input: $input) {
      zoneId
    }
  }
`

export const DUPLICATE_PORT = gql`
  mutation DuplicatePort($input: DuplicatePortInput!){
    duplicatePort(input: $input){
      port {
        id
      }
    }
  }
`

export const DUPLICATE_ROUTE = gql`
mutation DuplicateRoute($input: DuplicateRouteInput!){
  duplicateRoute(input: $input){
    route{
      id
    }
  }
}
`

export const LINK_CURVES_TO_SPREAD = gql`
mutation LinkCurvesToSpread($input: LinkCurvesToSpreadInput!){
  linkCurvesToSpread(input: $input){
    spotTCSpread {
      id
      spotTCSpreadName
    }
  }
}
`

export const LOCK_SINGLE_ROW = gql`
  mutation LockSingleRow($input: LockSingleRowInput!) {
    lockSingleRow(input: $input)
  }
`

export const LOCK_VALIDATIONCURVE = gql`
  mutation LockValidationCurve($input: LockValidationCurveInput!) {
    lockValidationCurve(input: $input)
  }
`

export const LOCK_ALL_CURVES_IN_SECTOR = gql`
  mutation LockAllCurvesInSector($input: LockAllCurvesInSectorInput!) {
    lockAllCurvesInSector(input: $input)
  }
`

export const LOCK_FORWARD_CURVE_INSTANCE = gql`
mutation LockForwardCurveInstance($input: LockForwardCurveInput!) {
  lockForwardCurveInstance(input: $input){
    forwardCurve {
      id
      locked
    }
  }
}
`

export const LOCK_ALL_FORWARD_CURVE_INSTANCES = gql`
mutation LockAllForwardCurveInstances($input: LockAllForwardCurveInstancesInput!) {
  lockAllForwardCurveInstances(input: $input) {
    forwardCurves {
      id
      locked
    }
  }
}
`

export const LOCK_ALL_FORWARD_CURVES_FROM_SECTOR = gql`
mutation LockAllForwardCurvesFromSector($input: LockAllForwardCurvesFromSectorInput!) {
  lockAllForwardCurvesFromSector(input: $input)
}
`

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      role {
        key
      }
      token
      user {
        id
      }
    }
  }
`

export const LOGOUT = gql`
  mutation Logout{
    logout{
      user{
        id
      }
      token
    }
  }
`

export const REGISTER = gql`
  mutation createCorporationUser($input: CreateCorporationUserInput!) {
    createCorporationUser(input: $input) {
      user {
        id
        email
        name
      }
      token
    }
  }
`

export const SAVE_ROUTE_RESULTS = gql`
mutation SaveRouteResults($input: SaveRouteResultInput!) {
  saveRouteResults(input: $input)
}
`

export const FORWARD_VALIDATE_RESULT = gql`
  mutation ForwardValidateResult($input: ForwardValidateResultInput!) {
    forwardValidateResult(input: $input)
  }
`

export const SPOTINDEX_INDEX_EQUIVALENT = gql`
  mutation IndexEquivalentResult($input: IndexEquivalentResultInput!){
    indexEquivalentResult(input: $input)
  }
`
export const SPOTINDEX_APS = gql`
  mutation ApsResult($input: ApsResultInput!){
    apsResult(input: $input)
  }
`

export const SPOTINDEX_DOP = gql`
  mutation DopResult($input: DopResultInput!){
    dopResult(input: $input)
  }
`

export const DELETE_CARGOWORKING = gql`
mutation DeleteCargoWorking($input: DeleteCargoWorkingInput!){
  deleteCargoWorking(input: $input){
    cargoWorking{
      id
      status
      account {
        id
      }
      route {
        id
      }
      commission
      month {
        id
      }
      bid
    }
  }
}`

export const DELETE_CARGO_OFFER = gql`
mutation DeleteCargoOffer($input: DeleteCargoOfferInput!) {
  deleteCargoOffer(input: $input) {
    cargoOffer {
      id
      offer {
        id
      }
      vessel {
        id
      }
      fakeVessel
      port {
        id
      }
      fakePort
      date
      comments
    }
  }
}`

export const UPSERT_CARGOWORKING = gql`
mutation UpsertCargoWorking($input: UpsertCargoWorkingInput!){
  upsertCargoWorking(input: $input) {
    cargoWorking {
    id
    status
    account {
      id
    }
    route {
      id
    }
    commission
    month {
      id
    }
    bid
  }
  }
}
`

export const UPSERT_CARGOWORKING_TABLE = gql`
mutation UpsertCargoWorkingTable($input: UpsertCargoWorkingTableInput!){
  upsertCargoWorkingTable(input: $input) {
    cargoWorking {
    id
    status
    account {
      id
    }
    route {
      id
    }
    commission
    month {
      id
    }
    bid
  }
  }
}
`

export const UPDATE_APIKEY_NAME = gql`
mutation UpdateApiKeyName($input: UpdateApiKeyInput!) {
  updateApiKeyName(input: $input) {
    apiKey {
      id
    }
  }
}
`
export const UPDATE_APS_RESULT = gql`
  mutation UpdateApsResult($input: UpdateApsResultInput!) {
    updateApsResult(input: $input)
  }
`

export const UPDATE_CARGO_OFFER = gql`
mutation UpdateCargoOffer($input: UpdateCargoOfferInput!){
  updateCargoOffer(input: $input)
}
`

export const UPSERT_COMMODITY = gql`
mutation UpsertCommodity($input: UpsertCommodityInput!){
  upsertCommodity(input: $input){
    commodity {
      id
      commodityName
      stowFactor
      forwardArray
    }
  }
}
`

export const UPDATE_VIA_PORTS = gql`
mutation UpdateViaPorts($input: UpdateViaPortsInput!){
  updateViaPorts(input: $input) {
    userSetting {
      id
    }
  }
}
`

export const UPSERT_CSV_VESSEL = gql`
  mutation upsertCsvVessel($input: UpsertCsvVesselInput!){
    upsertCsvVessel(input: $input)
  }
`

export const UPSERT_CSV_SPOTINDEX = gql`
  mutation upsertCsvSpotIndex($input: UpsertCsvSpotIndexInput!){
    upsertCsvSpotIndex(input: $input)
  }
`

export const UPSERT_DISTANCE = gql`
  mutation UpsertDistance($input: UpsertDistanceInput!){
    upsertDistance(input: $input){
      distance {
        id
        portID1 {
          id
        }
        portID2 {
          id
        }
        fullDist
        ecaDist
      }
    }
  }
`

export const UPSERT_EXPORT_CLIENT_RESULT = gql`
  mutation  upsertExportClientResult($input: UpsertExportClientResultInput!) {
    upsertExportClientResult(input: $input) {
      exportClientResults {
        Route
        Country
        Load
        Disch
        Vessel
        Stem
        Intake
        Draft
        LoadTerm_DischTerm
        Spread
        PD0
        PD1
        PD2
        PD3
        PD4
        PD5
        PD6
        PD7
        PD8
        PD9
        PD10
        PD11
        PD12
        PD13
      }
    }
  }
`

export const UPSERT_FORWARDCURVE = gql`
  mutation UpsertForwardCurve($input: UpsertForwardCurveInput!){
    upsertForwardCurve(input: $input){
      forwardCurve {
        id
        forwardCurveName
        forwardCommodity {
          id
        }
        spotIndex {
          id
        }
      }
    }
  }
`

export const UPSERT_FORWARDVALIDATION = gql`
mutation UpsertForwardValidation($input: UpsertForwardValidationInput!){
  upsertForwardValidation(input: $input){
    forwardValidation {
      id
    }
  }
}
`

export const UPSERT_OWNER = gql`
mutation UpsertOwner($input: UpsertOwnerInput!) {
  upsertOwner(input: $input) {
    owner {
      id
    }
  }
}
`

export const UPSERT_PORT = gql`
mutation UpsertPort($input: UpsertPortInput!){
  upsertPort(input: $input){
    port {
      id
      bunkeringCost
      bunkeringTime
      country {
        id
      }
      draftArray
      portName
      salinity
      waitingIdleArray
      zone {
        id
      }
    }
  }
}
`

export const UPSERT_POSITIONDATE = gql`
  mutation UpsertPositionDate($input: UpsertPositionDateInput!){
    upsertPositionDate(input: $input){
      positionDate {
        id
      }
    }
  }
`

export const UPDATE_RESULT = gql`
  mutation UpdateResult($input: UpdateResultInput!) {
    updateResult(input: $input)
  }
`

export const UPSERT_ROUTE = gql`
mutation UpsertRoute($input: UpsertRouteInput!){
  upsertRoute(input: $input){
    route {
      id
      routeName
      commodity {
        id
      }
      constants
      spotTCSpread {
        id
      }
      deliveryPort {
        id
      }
      deliveryPortBunker
      description
      dischargePortArray {
        id
      }
      dischargeTermsArray
      miscCost
      linkedRouteArrays {
        id
      }
      loadPortArray {
        id
      }
      loadTermsArray
      redeliveryPort {
        id
      }
      redeliveryPortBunker
      stem
      tolerance
      vessel {
        id
      }
      viaRedeliveryPort {
        id
      }
    }
  }
}
`

export const UPDATE_ROUTE_RESULT = gql`
  mutation UpdateRouteResult($input: UpdateRouteResultsInput!) {
    updateRouteResult(input: $input)
  }
`

export const UPSERT_SHIPSECTOR = gql`
  mutation UpsertShipSector($input: UpsertShipSectorInput!){
    upsertShipSector(input: $input){
      shipSector {
        id
      }
    }
  }
`

export const UPSERT_SPOTINDEX = gql`
  mutation UpsertSpotIndex($input: UpsertSpotIndexInput!){
    upsertSpotIndex(input: $input){
      spotIndex {
        id
        indexAdjustmentAbsolute
        indexAdjustmentPercent
        indexName
        indexValue
      }
    }
  }
`

export const UPSERT_SPOTFIXTURE = gql`
  mutation UpsertSpotFixture($input: UpsertSpotFixtureInput!){
    upsertSpotFixture(input: $input) {
      spotFixture {
        id
      }
    }
  }
`
export const UPSERT_SPOTTCSPREAD = gql`
  mutation UpsertSpotTCSpread($input: UpsertSpotTCSpreadInput!){
    upsertSpotTCSpread(input: $input){
      spotTCSpread {
        id
      }
    }
  }
`

export const UPSERT_TIMECOUNTING = gql`
  mutation UpsertTimeCounting($input: UpsertTimeCountingInput!){
    upsertTimeCounting(input: $input){
      timeCounting {
        id
        abbrev
        multiplier
        timeCountingName
      }
    }
  }
`

export const UPSERT_USER = gql`
  mutation upsertUser($input: UpsertUserInput!) {
    upsertUser(input: $input) {
      user {
        id
        email
        name
      }
    }
  }
`

export const UPDATE_CLIENT_RESULTS = gql`
  mutation updateClientResults($input: UpdateClientResultsInput!) {
    updateClientResults(input: $input) {
      userSetting {
        id
        clientResults
      }
    }
  }
`

export const UPDATE_USER_ADCOM_ONLY = gql`
mutation UpdateUserAdcomOnly($input: UpdateAdcomOnlyInput!) {
  updateUserAdcomOnly(input: $input) {
    userSetting {
      id
      adcom
    }
  }
}
`

export const UPSERT_VESSEL = gql`
  mutation UpsertVessel($input: UpsertVesselInput!){
    upsertVessel(input: $input){
      vessel {
        id
      }
    }
  }
`

export const UPSERT_VOYAGEOFFER = gql`
  mutation UpsertVoyageOffer($input: UpsertVoyageOfferInput!){
    upsertVoyageOffer(input: $input){
      voyageOffer {
        id
        offer
        user {
          id
        }
      }
    }
  }
`

export const UPSERT_ZONE = gql`
  mutation UpsertZone($input: UpsertZoneInput!){
    upsertZone(input: $input){
      zone {
        id
      }
    }
  }
`

// Import Mutations

export const UPSERT_FILE = gql`
  mutation upsertFile($input: UpsertFileInput!) {
    upsertFile(input: $input)
  }
`
export const SAVE_SPOT_VALIDATION = gql`
  mutation spotValidationSave($input: SpotValidationSaveInput!){
    spotValidationSave(input: $input)
  }
`

export const SAVE_VALIDATIONCURVE = gql`
  mutation SaveValidationCurve($input: SaveValidationCurveInput!) {
    saveValidationCurve(input: $input)
  }
`

export const UPSERT_CSV_COMMODITY = gql`
  mutation upsertCsvCommodity($input: UpsertCsvCommodityInput!){
    upsertCsvCommodity(input: $input)
  }
`

export const UPSERT_CSV_DISTANCE = gql`
  mutation upsertCsvDistance($input: UpsertCsvDistanceInput!){
    upsertCsvDistance(input: $input)
  }
`

export const UPSERT_CSV_FORWARDCURVE = gql`
  mutation upsertCsvForwardCurve($input: UpsertCsvForwardCurveInput!){
    upsertCsvForwardCurve(input: $input)
  }
`

export const UPSERT_CSV_PORT = gql`
  mutation upsertCsvPort($input: UpsertCsvPortInput!){
    upsertCsvPort(input: $input)
  }
`

export const UPSERT_CSVROUTE = gql`
  mutation upsertCsvRoute($input: UpsertCsvRouteInput!){
    upsertCsvRoute(input: $input)
  }
`

export const UPSERT_CSV_SHIPSECTOR = gql`
  mutation upsertCsvShipSector($input: UpsertCsvShipSectorInput!){
    upsertCsvShipSector(input: $input)
  }
`

export const UPSERT_CSV_SPOTTCSPREAD = gql`
  mutation upsertCsvSpotTCSpread($input: UpsertCsvSpotTCSpreadInput!){
    upsertCsvSpotTCSpread(input: $input)
  }
`

export const UPSERT_CSV_TIME_COUNTING = gql`
  mutation upsertCsvTimeCounting($input: UpsertCsvTimeCountingInput!){
    upsertCsvTimeCounting(input: $input)
  }
`

export const UPSERT_CSV_ZONE = gql`
  mutation upsertCsvZone($input: UpsertCsvZoneInput!){
    upsertCsvZone(input: $input)
  }
`

export const UPDATE_SUMMARY = gql`
  mutation updateSummary($input: UpdateSummaryInput!){
    updateSummary(input: $input)
  }
`

export const UPDATE_SYSTEM_SETTINGS = gql`
  mutation UpdateSystemSetting($input: UpdateSystemSettingInput!) {
    updateSystemSetting(input: $input) {
      systemSetting {
        id
      }
    }
  }
`

export const UPDATE_FOB_FUTURE_MAPPING = gql`
  mutation UpdateFobFutureMapping($input: UpdateFobFutureMappingInput!) {
    updateFobFutureMapping(input: $input) {
      fobFutureMapping {
        id
        monthKeys
        monthValues
        monthsMapping
      }
    }
  }
`

export const UPDATE_FOBFUTURE_FOR_CLIENT = gql`
  mutation UpdateFobFutureForClient($input: UpdateFobFutureForClientInput!) {
    updateFobFutureForClient(input: $input) {
      fobFuture {
        id
        value
      }
    }
  }
`

export const UPDATE_FOBIMPORT_FOR_CLIENT = gql`
  mutation UpdateFobImportForClient($input: UpdateFobImportForClientInput!) {
    updateFobImportForClient(input: $input) {
      fobImport {
        id
        value
      }
    }
  }
`

export const UPDATE_FOBPREMIUM_FOR_CLIENT = gql`
  mutation UpdateFobPremiumForClient($input: UpdateFobPremiumForClientInput!) {
    updateFobPremiumForClient(input: $input) {
      fobPremium {
        id
        value
      }
    }
  }
`

export const VALIDATE_CURVE_POSITION = gql`
  mutation ValidateCurvePosition($input: ValidateCurvePositionInput!) {
    validateCurvePosition(input: $input)
  }
`

// Suscriptions Mutations

export const SAVE_RESULT = gql`
  mutation saveResult($input: SaveResultInput!){
    saveResult(input: $input)
  }
`

export const RESET_CURVES = gql`
  mutation resetCurves($input: ResetCurvesInput!){
    resetCurves(input: $input)
  }
`
export const RESET_VALIDATION_CURVE = gql`
  mutation ResetValidationCurve($input: ResetValidationCurveInput!) {
    resetValidationCurve(input: $input)
  }
`
