import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Content
import { upsert as upsertContent } from './UsersContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { CREATE_USER_INFORMATION, GET_USER } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_USER } from '../Mutations/Mutations'
// Utils
import {
  email,
  nameRequired,
  roleRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils' // formatNumber, getFormatValues,

import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import RootAutocomplete from '../Utils/RootAutocomplete'
import TrieSearch from 'trie-search'

import Card from '@material-ui/core/Card'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'

// Sortable
import { ReactSortable } from 'react-sortablejs'

const useStyles = makeStyles({
  card: {
    marginBottom: '1%',
    maxHeight: '40px',
    paddingTop: '1%',
    paddingLeft: '1%',
    paddingBottom: '1%'
  },
  paper: {
    borderRadius: '10px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
})

const CreateUserQueryHelper = props => {
  const { ...rest } = props
  return (
    <QueryHelper
      query={CREATE_USER_INFORMATION}
    >
      {({ data }) => {
        const { createUserInformation } = data
        return (
          <UpsertUserWithRouter
            roles={createUserInformation.roles}
            routes={createUserInformation.routes}
            {...rest}
          />
        )
      }}
    </QueryHelper>
  )
}

CreateUserQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default CreateUserQueryHelper

const UpsertUser = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.user
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={GET_USER}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let user = null
        if (data && data.getUser && data.getUser.user) {
          user = data.getUser.user
        }
        return (
          <>
            {
              user
                ? <Helmet><title>Update User</title></Helmet>
                : <Helmet><title>Create User</title></Helmet>
            }
            <CreateUser
              {...rest}
              user={user}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertUser.propTypes = {
  roles: PropTypes.array.isRequired,
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const UpsertUserWithRouter = withRouter(UpsertUser)

const CreateUser = props => {
  const { refetchQueries, roles, user } = props
  let { routes } = props

  const [autoCompleteValue, setAutoCompleteValue] = useState([])

  let maxCountries = 0
  let maxLoadPorts = 0
  let maxDischPorts = 0
  routes = routes.map(item => {
    // Get load ports length
    const loadPortsLength = item.loadPortArray.length
    if (loadPortsLength > maxLoadPorts) {
      maxLoadPorts = loadPortsLength
    }
    const routeCountries = []
    // Get load ports keys
    let loadPorts = {}
    for (let index = 0; index < loadPortsLength; index++) {
      const loadPort = item.loadPortArray[index]
      loadPorts = {
        ...loadPorts,
        [`loadPort${index}`]: loadPort.portName
      }
      if (!routeCountries.includes(loadPort.country.name)) {
        routeCountries.push(loadPort.country.name)
      }
    }
    // Get disch ports length
    const dischPortsLength = item.dischargePortArray.length
    if (dischPortsLength > maxDischPorts) {
      maxDischPorts = dischPortsLength
    }
    // Get disch ports keys
    let dischPorts = {}
    for (let index = 0; index < dischPortsLength; index++) {
      const dischPort = item.dischargePortArray[index]
      dischPorts = {
        ...dischPorts,
        [`dischPort${index}`]: dischPort.portName
      }
      if (!routeCountries.includes(dischPort.country.name)) {
        routeCountries.push(dischPort.country.name)
      }
    }
    // Get countries length
    const countriesLength = routeCountries.length
    if (countriesLength > maxCountries) {
      maxCountries = countriesLength
    }
    // Create countries keys
    let countriesKeys = {}
    for (let index = 0; index < countriesLength; index++) {
      countriesKeys = {
        ...countriesKeys,
        [`country${index}`]: routeCountries[index]
      }
    }
    return {
      id: item.id,
      routeName: item.routeName,
      commodityName: item.commodity.commodityName,
      ...loadPorts,
      ...dischPorts,
      ...countriesKeys
    }
  })

  const classes = useStyles()

  const content = user ? upsertContent.update : upsertContent.create

  const initialValues = {
    email: user ? user.email : '',
    name: user ? user.name : '',
    role: user ? user.role.key : 'admin',
    password: user ? user.password : '',
    visibleRoutes: user ? user.visibleRoutes : []
  }

  const getRoutesFormat = (routeOrder, visibleRoutes) => {
    const result = []
    for (let i = 0; i < routeOrder.length; i++) {
      for (let j = 0; j < visibleRoutes.length; j++) {
        if (routeOrder[i] === visibleRoutes[j].id) {
          result.push({ ...visibleRoutes[j] })
        }
      }
    }
    return result
  }

  const handleChangeSelected = (selected, options) => {
    const selectedIds = selected.map(item => item.id)

    const resultOptions = options.filter((item) => {
      if (!selectedIds.includes(item.id)) {
        return true
      }
    })

    return resultOptions
  }

  const [routeOrder, setRouteOrder] = useState(user && user.routeOrder.length > 0 ? getRoutesFormat(user.routeOrder, user.visibleRoutes) : [])

  const form = {
    name: useFormInput({ initialValue: initialValues.name, label: 'Name', required: true }),
    email: useFormInput({ initialValue: initialValues.email, label: 'Email' }),
    password: useFormInput({ initialValue: initialValues.password, label: 'Password', type: 'password' }),

    role: useFormInput({
      label: 'Roles',
      required: true,
      initialValue: initialValues.role,
      select: true,
      selectValues: roles,
      smallInput: true
    })

    // visibleRoutes: useFormInput({ initialValue: getFormatValues({ values: initialValues.visibleRoutes, keyname: 'routeName' }), label: 'visibleRoutes', select: true, selectValues: routes, isMulti: true, smallInput: true }),
  }

  const [routesSelected, setRoutesSelected] = useState(user && user.routeOrder.length > 0 ? getRoutesFormat(user.routeOrder, user.visibleRoutes) : [])

  // const handleInputChange = (v) => {
  //   setAutoCompleteValue(v)
  // }
  const loadPortKeys = []
  for (let index = 0; index < maxLoadPorts; index++) {
    loadPortKeys.push(`loadPort${index}`)
  }
  const dischargePortKeys = []
  for (let index = 0; index < maxDischPorts; index++) {
    dischargePortKeys.push(`dischPort${index}`)
  }
  const countriesKeys = []
  for (let index = 0; index < maxCountries; index++) {
    dischargePortKeys.push(`country${index}`)
  }
  const [optionRoutes, setOptionRoutes] = useState(() => handleChangeSelected(routesSelected, routes))
  const ts = new TrieSearch(['routeName', 'commodityName', ...loadPortKeys, ...dischargePortKeys, ...countriesKeys], {
    idFieldOrFunction: 'id'
  })

  ts.addAll(routes)

  const searchRef = useRef(autoCompleteValue)

  useEffect(() => {
    const options = handleChangeSelected(routesSelected, routes)
    setOptionRoutes(options)
  }, [routesSelected])

  useEffect(() => {
    if (searchRef.current !== autoCompleteValue) {
      searchRef.current = autoCompleteValue

      if (!autoCompleteValue) {
        const allOptionRoutes = handleChangeSelected(routesSelected, routes)
        setOptionRoutes(allOptionRoutes)
      } else {
        const searchArray = autoCompleteValue.split(',')
        let foundRoutes = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        foundRoutes = handleChangeSelected(routesSelected, foundRoutes)
        setOptionRoutes(foundRoutes)
      }
    }
  }, [autoCompleteValue, optionRoutes, routes, routesSelected, ts])

  const formatRouteOrder = (params) => {
    const { routeOrder = [] } = params

    const result = []
    for (let i = 0; i < routeOrder.length; i++) {
      result.push(routeOrder[i].id)
    }
    return result
  }

  const input = {
    ...(user && { id: user.id }),
    email: form.email.getValue(),
    name: form.name.getValue(),
    userPassword: form.password.getValue(),
    role: form.role.getValue(),
    userPassword: form.password.getValue(),
    visibleRoutes: Array.isArray(routesSelected) ? routesSelected.map(route => route.id) : routesSelected,
    routeOrder: formatRouteOrder({ routeOrder })
  }

  const validation = {
    email: form.email.getValue() ? email : null,
    name: nameRequired,
    role: roleRequired
  }


  const next = ({ mutation }) => {
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_USER}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.paper} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <RootForm
                form={form}
                validation={validation}
              />
              <Autocomplete
                id='visibleRoutes-select'
                style={{ width: '100%' }}
                label='visibleRoutes'
                filterOptions={(x) => {
                  return x
                }}
                labelKey='routeName'
                options={optionRoutes}
                value={routesSelected}
                getOptionLabel={(option) => option.routeName}
                onChange={(_e, v) => {
                  setRoutesSelected(v)
                  setRouteOrder(v)
                }}
                onInputChange={(_e, v) => {
                  setAutoCompleteValue(v)
                }}
                autoHighlight
                multiple
                freeSolo
                renderInput={(params) => (
                  <TextField {...params} label='visibleRoutes' margin='normal' />
                )}
              />
              <ReactSortable
                list={routeOrder} setList={(v) => {
                  setRouteOrder(v)
                  setRoutesSelected(v)
                }}
              >
                {routeOrder.map(item => (
                  <Card key={item.id} className={classes.card}>{item.routeName}</Card>
                ))}
              </ReactSortable>
              <RootButton className={classes.button} disabled={result.loading} justifyContent='center' onClick={() => next({ mutation })}>
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateUser.propTypes = {
  user: PropTypes.object
}
