import React, { useReducer, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
// Helmet
import Helmet from 'react-helmet'
// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './PortsContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_PORT, CREATE_PORT_INFORMATION } from '../Queries/Queries' // customValidation[target] = true
// Material UI
import Box from '@material-ui/core/Box'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { DUPLICATE_PORT, UPSERT_PORT } from '../Mutations/Mutations'
// Utils
import {
  berthRequired,
  bunkeringCostRequired,
  bunkeringTimeRequired,
  costRequired,
  countryRequired,
  draftArrayRequired,
  fo05Required,
  forwardCommodityFOIDRequired,
  forwardCommodityMGOIDRequired,
  HSFO380Required,
  MGO01Required,
  monthRequired,
  nameRequired,
  salinityRequired,
  shipSectorRequired,
  validateStep,
  waitingIdleArrayRequired,
  zoneRequired
  // route
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import CreateOptionsContainer from '../Utils/CreateOptionsContainer'
import RemoveIconButton from '../Utils/RemoveIconButton'
import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootCheckbox from '../Utils/RootCheckbox'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootAutocomplete from '../Utils/RootAutocomplete'
import RootPaper from '../Utils/RootPaper'
import RootTextField from '../Utils/RootTextField'
import TextError from '../Utils/TextError'

import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'

import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'

//  import gql from 'graphql-tag'
// import { USER } from '../../constants'

const validatejs = require('validate.js')

const styles = makeStyles(theme => ({
  input: {
    paddingLeft: '3%'
  },
  column: {
    padding: '0.6%'
  },
  marginTop: {
    marginTop: '0.19em'
  },
  marginBottom: {
    marginBottom: '7%'
  },
  noMargin: {
    margin: '0%'
  },
  addButton: {
    marginLeft: 8,
    padding: 0
  },
  portCostDropDown: {
    height: 25
  },
  paper: {
    borderRadius: '10px'
  },
  submitButton: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  },
  duplicateButton: {
    borderRadius: '5px',
    backgroundColor: 'rgba(22, 174, 177, 1)',
    color: 'white'
  }
}))

const AddIconButton = (props) => {
  return (
    <IconButton {...props}>
      <AddIcon />
    </IconButton>
  )
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.emptyValue]
    case 'remove':
      if (state.length - 1 === 0) {
        return state
      }
      return state.filter((_, index) => index !== action.index)
    case 'cost':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              cost: parseFloat(action.value)
            },
            {
              cost: costRequired
            }
          )
          return {
            ...item,
            cost: {
              value: parseFloat(action.value),
              valid: !validationResult,
              errorMessage: validationResult ? validationResult.cost[0] : ''
            }
          }
        } else {
          return item
        }
      })
    case 'ship':
      return state.map((item, index) => {
        if (index === action.index) {
          const validationResult = validatejs(
            {
              shipSector: action.value
            },
            {
              shipSector: shipSectorRequired
            }
          )
          let duplicationCheck = null

          if (action.value !== null) {
            duplicationCheck = validateUniqueShipSector(state, action.value.id)
          }

          return {
            ...item,
            shipSector: {
              value: action.value,
              id: action.value ? action.value.id : null,
              valid: validationResult ? false : !duplicationCheck,
              errorMessage: validationResult ? validationResult.shipSector[0] : duplicationCheck || ''
            }
          }
        } else {
          return item
        }
      })
    default:
      break
  }
}

const validateUniqueShipSector = (costShipArray, currentValue) => {
  for (let i = 0; i < costShipArray.length; i++) {
    const element = costShipArray[i]
    if (element.shipSector.value && element.shipSector.value.id === currentValue) {
      return '*Cannot choose same Ship Sector twice'
    }
  }
  return null
}

// const setUnvalidatedShips = (array, setCostShipArray) => {
//   for (let i = 0; i < array.length; i++) {
//     const shipCost = array[i]
//     if (shipCost.shipSector.value === null) {
//       setCostShipArray({ value: shipCost.shipSector.value, type: 'ship', index: i })
//     }
//   }
// }

const validateShipCost = (array) => {
  const validCost = array.reduce((validCost, shipCost, index) => {
    if (index === 1) {
      return validCost.cost.valid && shipCost.cost.valid
    } else {
      return validCost && shipCost.cost.valid
    }
  })

  const validShipSector = array.reduce((validShipSector, shipCost, index) => {
    if (index === 1) {
      return validShipSector.shipSector.valid && shipCost.shipSector.valid
    } else {
      return validShipSector && shipCost.shipSector.valid
    }
  })

  return validCost && validShipSector
}

const UpsertPortQueryHelper = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  let duplicate = false
  if (match && match.params) {
    const res = match.params.port ? match.params.port.split('+$dup') : ''
    if (res.length > 0 && res[1] === 'true') {
      duplicate = true
      id = res[0]
    } else {
      id = match.params.port
    }
  }

  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={CREATE_PORT_INFORMATION}
    >
      {({ data }) => {
        const { createPortInformation } = data
        if (createPortInformation) {
          const { commodities, countries, positionDates, shipSectors, zones } = createPortInformation

          const emptyOptions = []
          if (commodities.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.commodity)
          }

          if (positionDates.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.positionDate)
          }

          if (shipSectors.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.shipSector)
          }

          if (zones.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.zone)
          }

          const commoditiesArray = []
          if (commodities.length >= 0) {
            commodities.map(item => {
              return (commoditiesArray.push({ id: item.id, name: item.commodityName }))
            })
          }

          const positionDatesArray = []
          if (positionDates.length >= 0) {
            positionDates.map(item => {
              return (positionDatesArray.push({ id: item.id, name: item.positionDateName }))
            })
          }

          const shipSectorsArray = []
          if (shipSectors.length >= 0) {
            shipSectors.map(item => {
              return (shipSectorsArray.push({ id: item.id, name: item.shipSectorName }))
            })
          }

          const zonesArray = []
          if (zones.length >= 0) {
            zones.map(item => {
              return (zonesArray.push({ id: item.id, name: item.zoneName }))
            })
          }

          if (emptyOptions.length > 0) {
            return (
              <CreateOptionsContainer
                options={emptyOptions}
                name={upsertContent.module.name}
              />
            )
          }

          return (
            <UpsertPort
              commodities={commoditiesArray}
              countries={countries}
              positionDates={positionDatesArray}
              searchInput={searchInput}
              shipSectors={shipSectorsArray}
              skip={skip}
              history={_history}
              duplicate={duplicate}
              zones={zonesArray}
              {...rest}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}

UpsertPortQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default withRouter(UpsertPortQueryHelper)

const UpsertPort = props => {
  const { searchInput, skip, ...rest } = props

  return (
    <QueryHelper
      query={GET_PORT}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let port = null
        if (data && data.getPort && data.getPort.port) {
          port = data.getPort.port
        }
        return (
          <>
            {
              port
                ? <Helmet><title>Update Port</title></Helmet>
                : <Helmet><title>Create Port</title></Helmet>
            }
            <CreatePort
              {...rest}
              port={port}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertPort.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const CreatePort = props => {
  const { port, commodities, countries = [], duplicate, history, positionDates, shipSectors, zones, refetchQueries } = props
  const content = port ? upsertContent.update : upsertContent.create
  const [editRouteResults, setEditRouteResults] = useState(false)
  const [eca, setEca] = useState(port ? port.eca : false)
  const [distanceOnly, setDistanceOnly] = useState(port ? port.distanceOnly : false)
  const { user } = useContext(UserContext)
  // const getFormatValues = ({ values, keyname = 'name' }) => {
  //   const result = []
  //   values.forEach(element => {
  //     result.push({ value: element.id, label: element[keyname] })
  //   })
  //   return result
  // }

  const validation = {
    berth: berthRequired,
    bunkeringCost: bunkeringCostRequired,
    bunkeringTime: bunkeringTimeRequired,
    country: countryRequired,
    draftArray: draftArrayRequired,
    FO05: fo05Required,
    forwardCommodityFOID: forwardCommodityFOIDRequired,
    forwardCommodityMGOID: forwardCommodityMGOIDRequired,
    HSFO380: HSFO380Required,
    MGO01: MGO01Required,
    portName: nameRequired,
    salinity: salinityRequired,
    waitingIdleArray: waitingIdleArrayRequired,
    zone: zoneRequired
  }
  const salinityOptions = [{ name: 'tf', id: 1 }, { name: 'bw', id: 2 }, { name: 'fw', id: 3 }, { name: 'sw', id: 4 }]

  const getSalinity = data => {
    for (let i = 0; i < salinityOptions.length; i++) {
      if (salinityOptions[i].name === data) {
        return salinityOptions[i].id
      }
    }
  }

  const emptyCostShip = {
    cost: {
      value: 0,
      valid: true,
      errorMessage: ''
    },
    shipSector: {
      value: null,
      id: null,
      valid: false,
      errorMessage: null
    }
  }

  const createCostShip = (params = {}) => {
    const {
      costs,
      shipSectors,
      shipSectorsInfo
    } = params
    const result = []
    if (shipSectors.length > 0) {
      for (let i = 0; i < costs.length; i++) {
        for (let j = 0; j < shipSectorsInfo.length; j++) {
          if (shipSectorsInfo[j].id === shipSectors[i]) {
            result.push({
              cost: {
                value: costs[i],
                valid: costs[i] !== null,
                errorMessage: ''
              },
              shipSector: {
                value: shipSectorsInfo[j] ? shipSectorsInfo[j] : null,
                id: shipSectorsInfo[j] ? shipSectorsInfo[j].id : null,
                valid: !!shipSectorsInfo[j],
                errorMessage: ''
              }
            })
          }
        }
      }
    } else {
      for (let i = 0; i < costs.length; i++) {
        result.push({
          cost: {
            value: costs[i],
            valid: costs[i] !== null,
            errorMessage: ''
          },
          shipSector: {
            value: shipSectorsInfo[i] ? shipSectorsInfo[i] : null,
            id: shipSectorsInfo[i] ? shipSectorsInfo[i].id : null,
            valid: !!shipSectorsInfo[i],
            errorMessage: ''
          }
        })
      }
    }
    return result
  }

  let defaultForwardCommodityFoid = commodities.filter((item) => item.name === 'Singapore 0.5 Forward Curve')[0]
  defaultForwardCommodityFoid = defaultForwardCommodityFoid ? defaultForwardCommodityFoid.id : ''

  let defaultForwardCommodityMGO = commodities.filter((item) => item.name === 'Singapore MGO Forward Curve')[0]
  defaultForwardCommodityMGO = defaultForwardCommodityMGO ? defaultForwardCommodityMGO.id : ''

  const initialValues = {
    portName: port ? port.portName : '',
    berth: port ? port.berth : '',
    bunkeringCost: port ? port.bunkeringCost : 0,
    bunkeringTime: port ? port.bunkeringTime : 0,
    country: port && port.country ? port.country.id : '',
    distanceOnly: port && port.distanceOnly ? port.distanceOnly : false,
    eca: port ? port.eca : false, // TODO UNCOMMENT
    FO05: port ? port.FO05 : 0,
    HSFO380: port ? port.HSFO380 : 0,
    MGO01: port ? port.MGO01 : 0,
    salinity: port ? getSalinity(port.salinity) : '',
    forwardCommodityFOID: port ? port.forwardCommodityFOID.id : defaultForwardCommodityFoid,
    forwardCommodityMGOID: port ? port.forwardCommodityMGOID.id : defaultForwardCommodityMGO,
    zone: port ? port.zone.id : '',
    costShipArray: port ? createCostShip({ costs: port.costArray, shipSectorsInfo: port.shipSectorIDArray, shipSectors: port.shipSectors }) : [emptyCostShip],
    costArray: port
      ? port.costArray
      : new Array(upsertContent.form.costArray.array.initialQty).fill(upsertContent.form.costArray.item.emptyValue),
    draftArray: port ? port.draftArray : Array.from(Array(12), () => 0),
    fuelDeliveryPremiumArray: port ? port.fuelDeliveryPremiumArray : Array.from(Array(positionDates.length), () => 0),
    waitingIdleArray: port ? port.waitingIdleArray : Array.from(Array(12), () => 0)
  }

  const form = {
    portName: useFormInput({ initialValue: initialValues.portName, disabled: duplicate, label: 'portName', required: true, isCalc: true, setEditRouteResults }),
    berth: useFormInput({ initialValue: initialValues.berth, label: 'berth', required: true, isCalc: true, setEditRouteResults }),
    country: useFormInput({ initialValue: initialValues.country, readOnly: duplicate, label: 'Country', required: true, select: true, selectValues: countries, smallInput: true }),
    zone: useFormInput({ initialValue: initialValues.zone, readOnly: duplicate, select: true, selectValues: zones, label: 'Zone', required: true, isCalc: true, setEditRouteResults, smallInput: true }),
    salinity: useFormInput({ initialValue: initialValues.salinity, readOnly: duplicate, select: true, selectValues: salinityOptions, label: 'salinity', required: true, isCalc: true, setEditRouteResults, smallInput: true }),
    forwardCommodityFOID: useFormInput({ initialValue: initialValues.forwardCommodityFOID, readOnly: duplicate, select: true, selectValues: commodities, label: 'forwardCommodityFOID', required: true, isCalc: true, setEditRouteResults, smallInput: true }),
    forwardCommodityMGOID: useFormInput({ initialValue: initialValues.forwardCommodityMGOID, readOnly: duplicate, select: true, selectValues: commodities, label: 'forwardCommodityMGOID', required: true, isCalc: true, setEditRouteResults, smallInput: true }),
    bunkeringCost: useFormInput({ initialValue: initialValues.bunkeringCost, label: 'Bunkering cost', type: 'number', required: true, isCalc: true, setEditRouteResults }),
    bunkeringTime: useFormInput({ initialValue: initialValues.bunkeringTime, disabled: duplicate, label: 'Bunkering time', type: 'number', required: true, isCalc: true, setEditRouteResults }),
    FO05: useFormInput({ initialValue: initialValues.FO05, disabled: duplicate, label: 'FO05', type: 'number', required: true, isCalc: true, setEditRouteResults }),
    HSFO380: useFormInput({ initialValue: initialValues.HSFO380, disabled: duplicate, label: 'HSFO380', type: 'number', required: true, isCalc: true, setEditRouteResults }),
    MGO01: useFormInput({ initialValue: initialValues.MGO01, disabled: duplicate, label: 'MGO01', type: 'number', required: true, isCalc: true, setEditRouteResults })
  }

  const [draftArray, setDraftArray] = useState(
    initialValues.draftArray.map(element => {
      return {
        value: element,
        valid: element !== null,
        errorMessage: ''
      }
    })
  )
  const [fuelDeliveryPremiumArray, setFuelDeliveryPremiumArray] = useState(
    initialValues.fuelDeliveryPremiumArray.map(fuel => {
      return {
        value: fuel,
        valid: fuel !== null,
        errorMessage: ''
      }
    })
  )
  const [waitingIdleArray, setWaitingIdleArray] = useState(
    initialValues.waitingIdleArray.map(element => {
      return {
        value: element,
        valid: element !== null,
        errorMessage: ''
      }
    })
  )

  const [costShipArray, setCostShipArray] = useReducer(reducer, [...initialValues.costShipArray])
  // const customValidation = {
  //   costArray: false,
  //   shipSectorIDArray: false
  // }

  // const getCostShipValues = (data, target) => {
  //   return data.map((item) => {
  //     if (item[target] === '') {
  //       customValidation[target] = true
  //       return customValidation[target]
  //     } else if (item[target] && item[target].value) {
  //       return item[target].value
  //     } else {
  //       return item[target]
  //     }
  //   })
  // }

  // const validateCostShipValues = (data, target) => {
  //   if (target === 'cost') {
  //     if (data[0].cost) {
  //       return getCostShipValues(data, target)
  //     }
  //   } else if (target === 'shipSector') {
  //     if (data[0].shipSector) {
  //       return getCostShipValues(data, target)
  //     }
  //   }
  // }

  const getSalinityName = (data) => {
    for (let i = 0; i < salinityOptions.length; i++) {
      if (data === salinityOptions[i].id) {
        return salinityOptions[i].name
      }
    }
  }
  const input = {
    ...(port && { id: port.id }),
    portName: form.portName.getValue() ? form.portName.getValue().trim() : '',
    berth: form.berth.getValue(),
    bunkeringCost: parseFloat(form.bunkeringCost.getValue()),
    bunkeringTime: form.bunkeringTime.getValue(),
    FO05: parseFloat(form.FO05.getValue()),
    HSFO380: parseFloat(form.HSFO380.getValue()),
    MGO01: parseFloat(form.MGO01.getValue()),
    country: form.country.getValue(),
    // shipSectorIDArray: validateCostShipValues(costShipArray, 'shipSector'),
    ...(costShipArray[0].shipSector && { shipSectorIDArray: costShipArray.map(costShip => { return costShip.shipSector.id }) }),
    zone: form.zone.getValue(),
    // costArray: validateCostShipValues(costShipArray, 'cost'),
    costArray: costShipArray.map(costShip => costShip.cost.value),
    draftArray: draftArray.map(element => element.value),
    forwardCommodityFOID: form.forwardCommodityFOID.getValue(),
    forwardCommodityMGOID: form.forwardCommodityMGOID.getValue(),
    fuelDeliveryPremiumArray: fuelDeliveryPremiumArray.map(fuel => fuel.value),
    salinity: getSalinityName(form.salinity.getValue()),
    ...(costShipArray[0].shipSector && { shipSectors: costShipArray.map(costShip => { return costShip.shipSector.id }) }),
    waitingIdleArray: waitingIdleArray.map(element => element.value),
    editRouteResults,
    eca, // TODO UNCOMMENT
    distanceOnly
  }
  const positionArrayInputsValidation = {
    month: monthRequired
  }

  const arrayValidator = array => {
    const result = array.reduce((valid, currentObj, index) => {
      if (index === 1) {
        return valid.valid && currentObj.valid
      } else {
        return valid && currentObj.valid
      }
    })
    return result
  }

  const previousValidation = () => {
    const validA = arrayValidator(fuelDeliveryPremiumArray)
    const validB = arrayValidator(draftArray)
    const validC = arrayValidator(waitingIdleArray)
    const validD = validateShipCost(costShipArray)
    return (validA && validB && validC && validD)
  }

  const next = ({ mutation }) => {
    // setUnvalidatedShips(costShipArray, setCostShipArray)
    previousValidation() &&
      validateStep({
        form,
        next: () => mutation(),
        validation
      })
  }
  const classes = styles()

  const { portName, berth, country, zone, salinity, ...restOfForm } = form

  const initialForm = {
    berth,
    country,
    zone,
    salinity
  }

  return (
    <MutationHelper
      mutation={UPSERT_PORT}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.paper} {...(!props.onCompleted && { smallForm: false })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <Grid container justify='space-evenly'>
                <Grid item xs={12} sm={3} className={classes.column} alignContent='space-between'>
                  <Grid container item xs={12} className={classes.marginBottom}>
                    <Grid container item>
                      <Grid item xs={12} md={6}>
                        <RootForm form={{ form: portName }} validation={validation} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <RootCheckbox
                          label='Eca'
                          onChange={(checked) => {
                            setEditRouteResults(true)
                            setEca(checked)
                          }}
                          checked={eca}
                          value={eca}
                        />
                        <RootCheckbox
                          label='Dist Only'
                          onChange={(checked) => {
                            setEditRouteResults(true)
                            setDistanceOnly(checked)
                          }}
                          checked={distanceOnly}
                          value={distanceOnly}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item xs={12} md={12}>
                        <RootForm
                          autoFocus={false}
                          form={initialForm}
                          validation={validation}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container item xs={12}>
                    <Grid container item>
                      <Grid item xs={12} md={12}>
                        <RootForm
                          autoFocus={false}
                          form={restOfForm}
                          validation={validation}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.column}>
                  <CostShipSectorInputs
                    costShipArray={costShipArray}
                    disabled={duplicate}
                    emptyValue={emptyCostShip}
                    setCostShipArray={setCostShipArray}
                    shipSectors={shipSectors}
                    setEditRouteResults={setEditRouteResults}
                  />
                  <PositionArrayInputs
                    positions={positionDates}
                    disabled={duplicate}
                    setData={setFuelDeliveryPremiumArray}
                    data={fuelDeliveryPremiumArray}
                    setEditRouteResults={setEditRouteResults}
                    title='Fuel Delivery Premium'
                    validation={positionArrayInputsValidation}
                  />
                </Grid>
                <Grid container item xs={12} sm={4} justify='space-between'>
                  <DateArrayInputs
                    setData={setDraftArray}
                    data={draftArray}
                    disabled={false}
                    setEditRouteResults={setEditRouteResults}
                    style={classes.input}
                    title='Draft Array'
                    validation={positionArrayInputsValidation}
                  />
                  <DateArrayInputs
                    setData={setWaitingIdleArray}
                    data={waitingIdleArray}
                    disabled={duplicate}
                    setEditRouteResults={setEditRouteResults}
                    style={classes.input}
                    title='Waiting Idle'
                    validation={positionArrayInputsValidation}
                  />
                </Grid>
              </Grid>

              {(port && port.id) &&
                <HandleDuplicate
                  id={port.id}
                  history={history}
                  user={user}
                />}
              <RootButton className={classes.submitButton} disabled={result.loading || user.disabled} justifyContent='center' onClick={() => next({ mutation })}>
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

const HandleDuplicate = props => {
  const { id, user, history } = props

  const classes = styles()

  const input = {
    id
  }
  return (
    <MutationHelper
      mutation={DUPLICATE_PORT}
      onCompleted={(data) => {
        history.push(`${data.duplicatePort.port.id}+$duptrue`)
      }}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootButton className={classes.duplicateButton} justifyContent='center' disabled={user.disabled} onClick={() => mutation()}>
            Duplicate
          </RootButton>
        )
      }}
    </MutationHelper>
  )
}

const DateArrayInputs = props => {
  const { setData, data, disabled, setEditRouteResults, style, title, validation } = props
  const classes = styles()

  const [allValue, setAllValue] = useState()
  const setAll = (value) => {
    setAllValue(parseFloat(value))
    setData(prevState => {
      const newState = new Array(prevState.length)
      const validationResult = validatejs(
        {
          month: parseFloat(value)
        },
        validation
      )
      return newState.fill({
        value: parseFloat(value),
        valid: !validationResult,
        errorMessage: validationResult ? validationResult.month[0] : null
      })
    })
  }

  if (data.length !== 12) {
    return null
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

  return (
    <Grid item sm={12} md={6} className={style}>
      <DateArrayHeader title={title} onChange={setAll} value={allValue} />
      {data.map((weather, index) => {
        return (
          <>
            <RootTextField
              key={index}
              label={months[index]}
              disabled={disabled}
              className={classes.noMargin}
              onChange={(e) => {
                const newData = data
                setEditRouteResults(true)
                newData[index] = parseFloat(e.target.value)
                setData(prevState => (
                  prevState.map((element, i) => {
                    // TODO VERIFY LOGIC
                    if (i === index) {
                      const validationResult = validatejs(
                        {
                          month: parseFloat(e.target.value)
                        },
                        validation
                      )
                      return {
                        value: parseFloat(e.target.value),
                        valid: !validationResult,
                        errorMessage: validationResult ? validationResult.month[0] : null
                      }
                    } else {
                      return element
                    }
                  })
                ))
              }}
              type='number'
              value={parseFloat(weather.value)}
            />
            <TextError error={weather.errorMessage} />
          </>
        )
      })}
    </Grid>
  )
}

const DateArrayHeader = ({ title, disabled, onChange, value }) => {
  const classes = styles()
  return (
    <Grid
      container
      alignContent='center'
    >
      <Grid
        item
        sm={6}
        container
        alignContent='flex-start'
      >
        <Box>
          <Typography>{title}</Typography>
        </Box>
      </Grid>
      <Grid
        item
        sm={6}
      >
        <RootTextField
          label='Set All'
          disabled={disabled}
          className={classes.noMargin}
          onChange={e => {
            onChange(e.target.value)
          }}
          value={value}
          type='number'
        />
      </Grid>
    </Grid>
  )
}

DateArrayInputs.propTypes = {
  setData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
}

const PositionArrayInputs = props => {
  const { positions, disabled, setData, data, setEditRouteResults, title, validation } = props
  const classes = styles()
  const [allValue, setAllValue] = useState()

  if (data.length !== positions.length) {
    return null
  }

  const setAll = (value) => {
    setAllValue(parseFloat(value))
    setData(prevState => {
      const newState = new Array(prevState.length)
      const validationResult = validatejs(
        {
          month: parseFloat(value)
        },
        validation
      )
      return newState.fill({
        value: parseFloat(value),
        valid: !validationResult,
        errorMessage: validationResult ? validationResult.month[0] : null
      })
    })
  }

  return (
    <Grid>
      <DateArrayHeader
        title={title}
        onChange={setAll}
        value={allValue}
      />
      {
        data.map((weather, index) => {
          return (
            <>
              <RootTextField
                smallInput
                key={index}
                disabled={disabled}
                label={positions[index].name}
                className={classes.noMargin}
                onChange={(e) => {
                  setEditRouteResults(true)
                  const newData = data
                  newData[index] = parseFloat(e.target.value)
                  setData(prevState => (
                    prevState.map((element, i) => {
                      // TODO VERIFY LOGIC
                      if (i === index) {
                        const validationResult = validatejs(
                          {
                            month: parseFloat(e.target.value)
                          },
                          validation
                        )
                        return {
                          value: parseFloat(e.target.value),
                          valid: !validationResult,
                          errorMessage: validationResult ? validationResult.month[0] : null
                        }
                      } else {
                        return element
                      }
                    })
                  ))
                }}
                type='number'
                value={parseFloat(weather.value)}
              />
              <TextError error={weather.errorMessage} />
            </>
          )
        })
      }
    </Grid>
  )
}

const CostShipSectorInputs = props => {
  const { costShipArray, disabled, emptyValue, setCostShipArray, shipSectors, setEditRouteResults } = props
  const classes = styles()
  return (
    <Grid container>
      <Box
        display='flex'
        width='100%'
        alignItems='center'
      >
        <Typography>Port Cost</Typography>
        <AddIconButton
          className={classes.addButton}
          justifyContent='center'
          disabled={disabled}
          onClick={() => setCostShipArray({ type: 'add', emptyValue: emptyValue })}
        />
      </Box>
      {costShipArray.map((item, index) => {
        return (
          <Grid container key={index}>
            <Grid container item xs={11} md={11} alignItems='flex-end' justify='center'>
              <Grid item md={6} xs={6}>
                <>
                  <RootAutocomplete
                    smallInput
                    readOnly={disabled}
                    onChange={(e) => {
                      setEditRouteResults(true)
                      setCostShipArray({ value: e.value, type: 'ship', index: index })
                    }}
                    value={item.shipSector.value}
                    options={shipSectors}
                  />
                </>
              </Grid>
              <Grid item md={6} xs={6}>
                <>
                  <RootTextField
                    smallInput
                    disabled={disabled}
                    className={classes.marginTop}
                    onChange={(e) => {
                      setEditRouteResults(true)
                      setCostShipArray({ value: e.target.value, type: 'cost', index: index })
                    }}
                    value={item.cost.value}
                    type='number'
                  />
                </>
              </Grid>

            </Grid>
            {
              costShipArray.length > 1 &&
                <Grid container item xs={1} md={1}>
                  <RemoveIconButton
                    onClick={
                      () => setCostShipArray({ type: 'remove', index })
                    }
                    size='small'
                  />
                </Grid>
            }
            <Grid container item xs={12} md={12} alignItems='center' justify='center'>
              <Grid item md={6} xs={6} style={{ height: 25 }}>
                <Typography style={{ fontSize: 14 }} color='error'>{item.shipSector.errorMessage}</Typography>
              </Grid>
              <Grid item md={6} xs={6} style={{ height: 25 }}>
                <Typography style={{ fontSize: 14 }} color='error'>{item.cost.errorMessage}</Typography>
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}

PositionArrayInputs.propTypes = {
  setData: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired
}

CreatePort.propTypes = {
  countries: PropTypes.array.isRequired,
  onCompleted: PropTypes.func,
  port: PropTypes.object,
  refetchQueries: PropTypes.array
}
