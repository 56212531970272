// React
import React, { Fragment } from 'react'
// Material UI
// import TextField from '@material-ui/core/TextField'
// Prop types
import PropTypes from 'prop-types'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles/'
// Utils
import NumberFormatCustom from '../Utils/NumberFormatCustom'
import RootDatePicker from '../Utils/RootDatePicker'
import RootFileInput from '../Utils/RootFileInput'
import RootAutoComplete from '../Utils/RootAutocomplete'
// import RootSelect from './RootSelect'
import TextError from './TextError'

import Grid from '@material-ui/core/Grid'
import RootTextField from './RootTextField'
const useStyles = makeStyles((theme) => ({
  textField: {
    // width: '95%',
    // marginLeft: '2.5%',
    marginTop: '3px',
    // padding: '3px 1px 1px 1px',
    background: theme.palette.common.white,
    textAlign: 'left',
    fontSize: theme.typography.generalFontSize
  },
  multiline: {
    flexGrow: 1
  },
  date: {
    border: 'none'
  },
  autocomplete: {
    padding: 0,
    margin: 0
  }
}))

const RootForm = (props) => {
  const classes = useStyles()
  const {
    autoFocus: autoFocusProps = true,
    quitFocus,
    form,
    itemSize = 12,
    index: propIndex,
    readOnly,
    validation,
    marginBottom = true,
    size = 'medium',
    gridWidth,
    gridHeight,
    errorFontSize,
    onKeyDown,
  } = props

  const formArray = Object.keys(form)
  return formArray.map((field, index) => {
    const {
      forceFocus,
      clearable,
      creatable,
      error,
      getValue,
      grouped,
      helperText,
      isMulti,
      minDate,
      multiline,
      onChange,
      onInputChange,
      select,
      selectValues,
      filterOptions,
      setError,
      setValue,
      type,
      notifyFn = null,
      value,
      ...rest
    } = form[field]
    let autoFocus = false
    if (autoFocusProps && !quitFocus) {
      autoFocus = index === 0
    }
    if (field === 'document') {
      return null
    }
    if (type === 'file') {
      return (
        <Fragment key={index}>
          <Grid item xs={12} md={itemSize}>
            <RootFileInput
              className={classes.textField}
              index={propIndex}
              name={field}
              onChange={onChange}
              readOnly={readOnly}
              setError={setError}
              validation={validation}
              value={value || null}
              {...rest}
            />
            <TextError error={error} />
          </Grid>
        </Fragment>
      )
    }
    if (type === 'date') {
      return (
        <Fragment key={index}>
          <RootDatePicker
            className={classes.textField}
            name={field}
            onChange={onChange}
            readOnly={readOnly}
            setError={setError}
            validation={validation}
            value={value || null}
            minDate={minDate}
            classes={classes}
            {...rest}
          />
          <TextError error={helperText} />
        </Fragment>
      )
    }
    if (selectValues || grouped) {
      return (
        <Fragment key={index}>
          <Grid item xs={12} md={itemSize} className={classes.autocomplete}>
            {/* <RootSelect
            className={classes.textField}
            clearable={clearable}
            creatable={creatable}
            grouped={grouped}
            isMulti={isMulti}
            name={field}
            onChange={onChange}
            options={selectValues}
            readOnly={readOnly}
            validation={validation}
            value={value || null}
            {...rest}
          /> */}
            <RootAutoComplete
              autoFocus={forceFocus || autoFocus}
              creatable={creatable}
              name={field}
              marginBottom={marginBottom}
              multiple={isMulti}
              onChange={onChange}
              onInputChange={onInputChange}
              onKeyDown={onKeyDown}
              filterOptions={filterOptions}
              options={selectValues}
              value={value}
              readOnly={readOnly}
              validation={validation}
              label={rest.label}
              labelKey={rest.labelKey}
              {...rest}
              size={size}
            />
            {helperText && <TextError error={helperText} />}
          </Grid>
        </Fragment>
      )
    }
    let defaultValue = ''
    if (value || value === 0) {
      defaultValue = value
    }
    return (
      <Fragment key={index}>
        <Grid
          item 
          style={
            (gridWidth || gridHeight)
            ? {
                width: gridWidth,
                height: gridHeight 
              }
            : {}
          } 
          xs={12} 
          md={itemSize}
        >
          <RootTextField
            autoFocus={autoFocus}
            className={clsx(classes.textField, {
              [classes.multiline]: multiline
            })}
            fullWidth
            InputProps={{
              readOnly: readOnly
            }}
            marginBottom={marginBottom}
            multiline={multiline}
            name={field}
            onChange={event => {
              onChange(event, validation)
              if (notifyFn && typeof notifyFn === 'function') {
                notifyFn(event)
              }
            }}
            onKeyDown={onKeyDown}
            type={type === 'number' || type === 'select' ? 'text' : type}
            {...type === 'number' && {
              InputProps: { inputComponent: NumberFormatCustom }
            }}
            {...(type === 'date' && { InputLabelProps: { shrink: true } })}
            value={defaultValue}
            // variant='outlined'
            size='small'
            {...(multiline && { rows: 5 })}
            {...rest}
          />
          {helperText && <TextError error={helperText} fontSize={errorFontSize} />}
        </Grid>
      </Fragment>
    )
  })
}

RootForm.defaultProps = {
  clearable: false,
  creatable: false,
  form: {},
  grouped: false,
  multiline: false,
  readOnly: false,
  select: false,
  type: 'text',
  validation: null
}

RootForm.propTypes = {
  clearable: PropTypes.bool.isRequired,
  creatable: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  getValue: PropTypes.func,
  grouped: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  multiline: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool.isRequired,
  select: PropTypes.bool.isRequired,
  selectValues: PropTypes.array,
  setError: PropTypes.func,
  setValue: PropTypes.func,
  type: PropTypes.string,
  validation: PropTypes.object,
  value: PropTypes.string
}

export default RootForm
