import React, { useReducer, useEffect } from 'react'
import { USER } from '../../constants'
import SnackBar from '../Utils/SnackBar'

const fontSizeBoundaries = {
  maxFontSize: 24,
  minFontSize: 6
}

const initialState = { role: null, fontSize: 16, voyageOffer: false, adcom: 0 }

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return {
        ...initialState
      }
    case 'voyage-offer':
      return {
        ...state,
        voyageOffer: action.value,
        voyageRoute: action.voyageRoute,
        voyageRouteName: action.voyageRouteName,
        voyageClient: action.voyageClient,
        voyageDate: action.voyageDate,
        adcomFromClientResult: action.adcomFromClientResult,
        voyageFromClientResults: action.voyageFromClientResults
      }
    case 'update-route':
      return {
        ...state,
        disabled: action.value
      }
    case 'update-role':
      return {
        ...state,
        role: action.value,
        fontSize: state.fontSize || initialState.fontSize
      }
    case 'remove-role':
      return {
        ...state,
        role: null
      }
    case 'addFontSize':
      return {
        ...state,
        fontSize: state.fontSize + 1 <= fontSizeBoundaries.maxFontSize ? state.fontSize + 1 : state.fontSize
      }
    case 'substractFontSize':
      return {
        ...state,
        fontSize: state.fontSize - 1 >= fontSizeBoundaries.minFontSize ? state.fontSize - 1 : state.fontSize
      }
    case 'add-forwardValidation-info':
      return {
        ...state,
        forwardValidation: {
          ...state.forwardValidation,
          ...action.value
        }
      }
    case 'add-spotValidation-info':
      return {
        ...state,
        spotValidation: {
          ...state.spotValidation,
          ...action.value
        }
      }
    case 'add-calc-info':
      return {
        ...state,
        calc: {
          ...state.spotValidation,
          ...action.value
        }
      }
    case 'add-client-info':
      return {
        ...state,
        client: {
          ...state.client,
          ...action.value
        }
      }
    case 'adcom':
      return {
        ...state,
        adcom: action.value
      }
    default: break
  }
}

const snackbarReducer = (state, action) => {
  switch (action.type) {
    case 'success':
      return {
        ...state,
        open: true,
        variant: 'success',
        message: action.message
      }
    case 'close': {
      return {
        ...state,
        open: false,
        variant: '',
        message: '',
        disabled: false
      }
    }
    default: break
  }
}

const localState = JSON.parse(localStorage.getItem(USER))

const UserContext = React.createContext()

const UserProvider = props => {
  const [user, setUser] = useReducer(reducer, localState || initialState)
  const [snackbar, setSnackbar] = useReducer(snackbarReducer, { open: false, message: '' })

  useEffect(() => {
    localStorage.setItem(USER, JSON.stringify(user))
  }, [user])

  return (
    <UserContext.Provider value={{ user, setUser, snackbar, setSnackbar }}>
      <SnackBar
        open={snackbar.open}
        onClose={() => {
          setSnackbar({
            type: 'close'
          })
        }}
        variant={snackbar.variant}
        message={snackbar.message}
      />
      {props.children}
    </UserContext.Provider>
  )
}

export { UserContext, UserProvider }
