import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// Helmet
import Helmet from 'react-helmet'

// Context
import { UserContext } from "../App/AppContext";
// Content
import { upsert as upsertContent } from "./ForwardCurvesContent";
// Queries
import QueryHelper from "../Queries/QueryHelper";
import {
  GET_FORWARDCURVE,
  CREATE_FORWARDCURVE_INFORMATION,
} from "../Queries/Queries";

// Mutations
import MutationHelper from "../Mutations/MutationHelper";
import { UPSERT_FORWARDCURVE } from "../Mutations/Mutations";
// Utils
import {
  commodityRequired,
  basedOnForwardCurveRequired,
  forwardCommodityRequired,
  monthRequired,
  nameRequired,
  spotIndexRequried,
  validateStep,
  draftArray,
} from "../Utils/form-validations";
import { useFormInput } from "../Utils/utils";

import CreateOptionsContainer from "../Utils/CreateOptionsContainer";
import RootBreadCrumb from "../Utils/RootBreadcrumb";
import RootButton from "../Utils/RootButton";
import RootForm from "../Utils/RootForm";
import RootGrid from "../Utils/RootGrid";
import RootPaper from "../Utils/RootPaper";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import RootTextField from "../Utils/RootTextField";
import RootAutocomplete from "../Utils/RootAutocomplete";
import RootCheckbox from '../Utils/RootCheckbox';
import TextError from "../Utils/TextError";
import TrieSearch from 'trie-search'

const validatejs = require("validate.js");

const styles = makeStyles((theme) => ({
  input: {
    padding: "2%",
  },
  noMargin: {
    margin: "0%",
  },
  addButton: {
    marginLeft: 8,
    padding: 0,
  },
  header: {
    marginBottom: 10,
  },
}));

const UpsertForwardCurveQueryHelper = (props) => {
  const { match, history: _history, location: _location, ...rest } = props;
  let id;
  if (match && match.params) {
    id = match.params.forwardCurve;
  }
  const skip = !id;
  const searchInput = {
    id,
  };

  return (
    <QueryHelper query={CREATE_FORWARDCURVE_INFORMATION}>
      {({ data }) => {
        const { createForwardCurveInformation } = data;
        if (createForwardCurveInformation) {
          const {
            positionDates,
            commodities,
            forwardCurves,
            spotIndexes,
            routes
          } = createForwardCurveInformation;
          const emptyOptions = [];
          if (commodities.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.commodity);
          }
          if (spotIndexes.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.spotIndex);
          }

          if (emptyOptions.length > 0) {
            return (
              <CreateOptionsContainer
                options={emptyOptions}
                name={upsertContent.module.name}
              />
            );
          }
          const commoditiesArray = [];
          if (commodities.length >= 0) {
            commodities.map((item) => {
              return commoditiesArray.push({
                id: item.id,
                name: item.commodityName,
              });
            });
          }
          // Remove dependant curves
          const forwardCurvesArray = [];
          if (forwardCurves.length >= 0) {
            forwardCurves.map((item) => {
              return forwardCurvesArray.push({
                id: item.id,
                name: item.forwardCurveName
              })
            })
          }
          const spotIndexesArray = [];
          if (spotIndexes.length >= 0) {
            spotIndexes.map((item) => {
              return spotIndexesArray.push({
                id: item.id,
                name: item.indexName,
              });
            });
          }
          const positionDatesArray = [];
          if (positionDates.length >= 0) {
            positionDates.map((item) => {
              return positionDatesArray.push({
                id: item.id,
                name: item.positionDateName,
              });
            });
          }

          const routesArray = [];
          if (routes.length > 0) {
            routes.map(item => {
              return (routesArray.push({ id: item.id, name: item.codeNameLong ? item.codeNameLong : item.routeName }))
            })
          }

          return (
            <UpsertForwardCurve
              searchInput={searchInput}
              skip={skip}
              commodities={commoditiesArray}
              curvesWithData={forwardCurves}
              forwardCurves={forwardCurvesArray}
              spotIndexes={spotIndexesArray}
              positionDates={positionDatesArray}
              routes={routesArray}
              {...rest}
            />
          );
        }
        return <div />;
      }}
    </QueryHelper>
  );
};
UpsertForwardCurveQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
};
export default withRouter(UpsertForwardCurveQueryHelper);

const useStyles = makeStyles((theme) => ({
  leftContainer: {
    marginTop: 55,
    paddingRight: 8,
  },
  breadCrumb: {
    display: "flex",
    justifyContent: "space-between",
  },
  someMargin: {
    marginRight: '10px'
  }
}));

const UpsertForwardCurve = (props) => {
  const { searchInput, skip, ...rest } = props;

  return (
    <QueryHelper
      query={GET_FORWARDCURVE}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let forwardCurve = null;
        let familyTree = null
        if (data && data.getForwardCurve && data.getForwardCurve.forwardCurve) {
          forwardCurve = data.getForwardCurve.forwardCurve;
        }
        if (data && data.getForwardCurve && data.getForwardCurve.familyTree) {
          familyTree = data.getForwardCurve.familyTree
        }
        return (
          <>
            {
              forwardCurve
                ? <Helmet><title>Update Forward Curve</title></Helmet>
                : <Helmet><title>Create Forward Curve</title></Helmet>
            }
            <CreateForwardCurve {...rest} familyTree={familyTree} forwardCurve={forwardCurve} />
          </>
        )
      }}
    </QueryHelper>
  );
};

UpsertForwardCurve.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
};

const pageStyles = makeStyles(theme => ({
  roundCorners: {
    borderRadius: '10px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

const CreateForwardCurve = (props) => {
  const {
    forwardCurve,
    familyTree,
    commodities,
    forwardCurves,
    spotIndexes,
    positionDates,
    routes,
    refetchQueries,
  } = props;

  const pageClasses = pageStyles()

  let everyOtherCurve
  if (forwardCurve) {
    everyOtherCurve = forwardCurves.filter(item => !familyTree.includes(item.id))
  } else {
    everyOtherCurve = forwardCurves
  }

  const getFormat = (objectId, objectArray) => {
    for (let i = 0; i < objectArray.length; i++) {
      if (objectArray[i].id === objectId) {
        return { id: objectArray[i].id, name: objectArray[i].name}
      }
    }
  }
  const coreRoute = forwardCurve && forwardCurve.coreRoute ? getFormat(forwardCurve.coreRoute.id, routes) : { id: undefined, name: undefined }
  const forwardCommodity = forwardCurve && forwardCurve.forwardCommodity ? getFormat(forwardCurve.forwardCommodity.id, commodities) : { id: undefined, name: undefined }
  const basedOnForwardCurve = forwardCurve && forwardCurve.basedOnForwardCurve ? getFormat(forwardCurve.basedOnForwardCurve.id, forwardCurves) : { id: undefined, name: undefined }

  
  const content = forwardCurve ? upsertContent.update : upsertContent.create;
  const [editRouteResults, setEditRouteResults] = useState(false);
  const { user } = useContext(UserContext);

  const validation = {
    commodities: commodityRequired,
    basedOnForwardCurve: basedOnForwardCurveRequired,
    forwardCommodity: forwardCommodityRequired,
    forwardCurveName: nameRequired,
    spotIndexes: spotIndexRequried
  };

  const monthValidation = {
    month: monthRequired,
  };

  const initialValues = {
    spotIndexes: forwardCurve ? forwardCurve.spotIndex.id : "",
    coreRoute,
    forwardCurveName: forwardCurve ? forwardCurve.forwardCurveName : "",
    forwardCommodity,
    basedOnForwardCurve,
    curveAdjustmentPercent: forwardCurve
      ? forwardCurve.curveAdjustmentPercent
      : Array.from(Array(14), () => 0), // forwardCurve ? forwardCurve.curveAdjustmentPercent :
    curveAdjustmentAbsolute: forwardCurve
      ? forwardCurve.curveAdjustmentAbsolute
      : Array.from(Array(14), () => 0), // forwardCurve ? forwardCurve.curveAdjustmentAbsolute :
    check: !!basedOnForwardCurve.id
  };

  // TODO: Finish Autocomplete filtering -> Cesar
  const [routeId, setRouteId] = useState(initialValues.coreRoute)

  const [check, setCheck] = useState(initialValues.check)

  const [commodityOrCurve, setCommodityOrCurve] = useState(initialValues.check ? initialValues.basedOnForwardCurve : initialValues.forwardCommodity)

  const handleCommOrCurve = (e) => {
    if (e.value) {
      setCommodityOrCurve({
        id: e.value.id,
        name: e.value.name
      })
    }
  }

  const [optionRoutes, setOptionRoutes] = useState(routes)
  const ts = new TrieSearch(['name', 'commodityName', 'loadPorts', 'dischargePorts'], {
    idFieldOrFunction: 'id'
  })
  ts.addAll(routes)
  const [autocompleteValue, setAutoCompleteValue] = useState({})
  const searchRef = useRef(autocompleteValue)
  useEffect(() => {
    if (searchRef.current !== autocompleteValue) {
      searchRef.current = autocompleteValue
      if (!autocompleteValue) {
        setOptionRoutes(routes)
      } else {
        const searchArray = autocompleteValue.split(',')
        const foundRoutes = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setOptionRoutes(foundRoutes)
      }
    }
  }, [autocompleteValue, optionRoutes, routes, ts])

  const arrayValidator = (array) => {
    const result = array.reduce((valid, currentObj, index) => {
      if (index === 1) {
        return valid.valid && currentObj.valid;
      } else {
        return valid && currentObj.valid;
      }
    });
    return result;
  };

  const checkRef = useRef(initialValues.check)
  useEffect(() => {
    if (check !== checkRef.current) {
      checkRef.current = check
      if (check) {
        setCommodityOrCurve({
          id: forwardCurves[0].id,
          name: forwardCurves[0].name
        })
      } else {
        setCommodityOrCurve({
          id: commodities[0].id,
          name: commodities[0].name
        })
      }
    }
  }, [check])

  const form = {
    forwardCurveName: useFormInput({
      initialValue: initialValues.forwardCurveName,
      label: "Name",
      required: true,
    }),
    spotIndexes: useFormInput({
      initialValue: initialValues.spotIndexes,
      select: true,
      selectValues: spotIndexes,
      label: "Spot Index",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    coreRoute: useFormInput({
      initialValue: initialValues.coreRoute,
      select: true,
      selectValues: optionRoutes,
      label: "Route",
      required: true,
      isCalc: true,
      setEditRouteResults,
      creatable: true,
      value: routeId,
      onChange: (e) => {
        setRouteId({ id: e.value ? e.value.id : null,  name: e.value ? e.value.name : null })
      },
      onInputChange: (_e, v) => {
        setAutoCompleteValue(v)
      },
      filterOptions: (x) => {
        return x
      }
    })
    // forwardCommodity: useFormInput({
    //   initialValue: initialValues.forwardCommodity,
    //   select: true,
    //   selectValues: commodities,
    //   label: "ForwardCommodity",
    //   required: true,
    //   isCalc: true,
    //   setEditRouteResults,
    // }),
  };

  const form2 = {
    commodityOrCurve: useFormInput({
      initialValue: check ? initialValues.basedOnForwardCurve : initialValues.forwardCommodity,
      select: true,
      clearable: true,
      value: commodityOrCurve,
      selectValues: check ? everyOtherCurve : commodities,
      label: check ? 'Based On Curve' : 'ForwardCommodity',
      required: true,
      isCalc: true,
      setEditRouteResults,
      onChange: (e) => {
        handleCommOrCurve(e)
      }
    })
  }

  // const form3 = {
  //   basedOnForwardCurve: useFormInput({
  //     initialValue: initialValues.basedOnForwardCurve,
  //     select: true,
  //     selectValues: forwardCurves,
  //     label: 'Based On Curve',
  //     required: true,
  //     isCalc: true,
  //     setEditRouteResults
  //   })
  // }

  const [curveAdjustmentPercent, setCurveAdjustmentPercent] = useState(
    initialValues.curveAdjustmentPercent.map((element) => {
      return {
        value: element,
        valid: element !== null,
        errorMessage:
          element === null && "*A valid number is required for month",
      };
    })
  );
  const [curveAdjustmentAbsolute, setCurveAdjustmentAbsolute] = useState(
    initialValues.curveAdjustmentAbsolute.map((element) => {
      return {
        value: element,
        valid: element !== null,
        errorMessage:
          element === null && "*A valid number is required for month",
      };
    })
  );

  const input = {
    ...(forwardCurve && { id: forwardCurve.id }),
    spotIndex: form.spotIndexes.getValue(),
    coreRoute: routeId && routeId.id ? routeId.id : undefined,
    forwardCurveName: form.forwardCurveName.getValue(),
    curveAdjustmentPercent: curveAdjustmentPercent.map(
      (element) => element.value
    ),
    curveAdjustmentAbsolute: curveAdjustmentAbsolute.map(
      (element) => element.value
    ),
    forwardCommodity: !check ? commodityOrCurve.id : null,
    basedOnForwardCurve: check ? commodityOrCurve.id : null,
    editRouteResults
  };

  const previousValidation = () => {
    const validA = arrayValidator(curveAdjustmentPercent);
    const validB = arrayValidator(curveAdjustmentAbsolute);
    return validA && validB;
  };

  const classes = useStyles();

  const next = ({ mutation }) => {
    previousValidation() &&
      validateStep({
        form: {
          forwardCurveName: form.forwardCurveName,
          spotIndexes: form.spotIndexes,
          coreRoute: form.coreRoute,
          commodityOrCurve: form2.commodityOrCurve
          // basedOnForwardCurve: form3.basedOnForwardCurve
        },
        next: () => mutation(),
        validation
      });
  };
  return (
    <MutationHelper
      mutation={UPSERT_FORWARDCURVE}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted })}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={pageClasses.roundCorners} {...(!props.onCompleted && { smallForm: true })}>
              <Grid container>
                <Grid item xs={12} clasName={classes.breadCrumb}>
                  <RootBreadCrumb
                    current={content.current}
                    links={content.breadCrumbs}
                    paperClass={classes.breadCrumb}
                  />
                </Grid>
                <Grid item xs={12} container justify="center">
                  <Grid
                    className={classes.leftContainer}
                    container
                    item
                    xs={12}
                    sm={6}
                    alignContent="flex-start"
                  >
                    <Grid item xs={12}>
                      <RootForm
                        size={"small"}
                        marginBottom={false}
                        form={form}
                        validation={validation}
                      />

                      {/* { !check && (
                        <RootForm
                          size={"small"}
                          marginBottom={false}
                          form={form2}
                          validation={validation}
                        />
                      )}

                      { check && (
                        <RootForm
                          size={"small"}
                          marginBottom={false}
                          form={form3}
                          validation={validation}
                        />
                      )} */}

                      <RootForm
                        size={"small"}
                        marginBottom={false}
                        form={form2}
                        validation={validation}
                      />
                      <Box display='flex' direction='row' justifyContent='flex-start' alignItems='center'>
                        <Typography
                          className={classes.someMargin}
                        >
                          Link Curve
                        </Typography>
                        <RootCheckbox
                          checked={check}
                          onChange={e => {
                            setCheck(prev => !prev)
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} container justify="center">
                      <RootButton
                        className={pageClasses.button}
                        disabled={result.loading || user.disabled}
                        justifyContent="center"
                        onClick={() => next({ mutation })}
                      >
                        {content.submitButton}
                      </RootButton>
                    </Grid>
                  </Grid>

                  <Grid container item xs={12} sm={6}>
                    <SpreadArrays
                      positionDates={positionDates}
                      curveAdjustmentAbsolute={curveAdjustmentAbsolute}
                      curveAdjustmentPercent={curveAdjustmentPercent}
                      setCurveAdjustmentPercent={setCurveAdjustmentPercent}
                      setCurveAdjustmentAbsolute={setCurveAdjustmentAbsolute}
                      setEditRouteResults={setEditRouteResults}
                      validation={monthValidation}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </RootPaper>
          </RootGrid>
        );
      }}
    </MutationHelper>
  );
};

const SpreadArrays = (props) => {
  const {
    positionDates,
    curveAdjustmentPercent,
    curveAdjustmentAbsolute,
    setCurveAdjustmentPercent,
    setCurveAdjustmentAbsolute,
    setEditRouteResults,
    validation,
  } = props;

  const [allAbsoluteValue, setAllAbsoluteValue] = useState();

  const [allPercentValue, setAllPercentValue] = useState();

  const setAllAbsolute = (value) => {
    setAllAbsoluteValue(parseFloat(value));
    setCurveAdjustmentAbsolute((prevState) => {
      const newState = new Array(prevState.length);
      const validationResult = validatejs(
        {
          month: parseFloat(value),
        },
        validation
      );
      return newState.fill({
        value: parseFloat(value),
        valid: !validationResult,
        errorMessage: validationResult ? validationResult.month[0] : null,
      });
    });
  };

  const setAllPercent = (value) => {
    setAllPercentValue(parseFloat(value));
    setCurveAdjustmentPercent((prevState) => {
      const newState = new Array(prevState.length);
      const validationResult = validatejs(
        {
          month: parseFloat(value),
        },
        validation
      );
      return newState.fill({
        value: parseFloat(value),
        valid: !validationResult,
        errorMessage: validationResult ? validationResult.month[0] : null,
      });
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <ArrayHeader
          title="Absolute"
          onChange={setAllAbsolute}
          value={allAbsoluteValue}
        />
        {positionDates.map((item, index) => {
          return (
            <>
              <RootTextField
                key={index}
                label={item.name}
                onChange={(e) => {
                  setEditRouteResults(true);
                  const newCurveAdjustmentAbsolute = curveAdjustmentAbsolute;
                  newCurveAdjustmentAbsolute[index] = parseFloat(
                    e.target.value
                  );
                  setCurveAdjustmentAbsolute((prevState) =>
                    prevState.map((value, i) => {
                      if (i === index) {
                        const validationResult = validatejs(
                          {
                            month: parseFloat(e.target.value),
                          },
                          validation
                        );
                        return {
                          value: parseFloat(e.target.value),
                          valid: !validationResult,
                          errorMessage: validationResult
                            ? validationResult.month[0]
                            : "",
                        };
                      } else {
                        return value;
                      }
                    })
                  );
                }}
                type="number"
                value={parseFloat(curveAdjustmentAbsolute[index].value)}
              />
              {curveAdjustmentAbsolute[index]?.errorMessage && (
                <TextError
                  error={curveAdjustmentAbsolute[index].errorMessage}
                />
              )}
            </>
          );
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        <ArrayHeader
          title="Percent"
          onChange={setAllPercent}
          value={allPercentValue}
        />
        {positionDates.map((item, index) => {
          return (
            <Grid key={index} container justifyContent="flex-start">
              <RootTextField
                key={index}
                label={item.name}
                onChange={(e) => {
                  setEditRouteResults(true);
                  const newCurveAdjustmentPercent = curveAdjustmentPercent;
                  newCurveAdjustmentPercent[index] = parseFloat(e.target.value);
                  setCurveAdjustmentPercent((prevState) =>
                    prevState.map((value, i) => {
                      if (i === index) {
                        const validationResult = validatejs(
                          {
                            month: parseFloat(e.target.value),
                          },
                          validation
                        );
                        return {
                          value: parseFloat(e.target.value),
                          valid: !validationResult,
                          errorMessage: validationResult
                            ? validationResult.month[0]
                            : "",
                        };
                      } else {
                        return value;
                      }
                    })
                  );
                }}
                type="number"
                value={parseFloat(curveAdjustmentPercent[index].value)}
              />
              {curveAdjustmentPercent[index]?.errorMessage && (
                <TextError error={curveAdjustmentPercent[index].errorMessage} />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

const ArrayHeader = ({ title, disabled, onChange, value }) => {
  const classes = styles();
  return (
    <Grid container alignContent="center" className={classes.header}>
      <Grid item sm={6} container alignContent="flex-end">
        <Box>
          <Typography>{title}</Typography>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <RootTextField
          label="Set All"
          disabled={disabled}
          className={classes.noMargin}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          value={value}
          type="number"
        />
      </Grid>
    </Grid>
  );
};

CreateForwardCurve.defaultProps = {
  refetchQueries: [],
};
CreateForwardCurve.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array.isRequired,
  forwardCurve: PropTypes.object,
};
