import React, { useState, useRef, useEffect } from 'react'

import Button from '@material-ui/core/Button'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { makeStyles } from '@material-ui/core/styles'

import { formatDate, fireSwalError } from '../Utils/utils'

import { Query } from 'react-apollo'

import { GET_ROUTES_FROM_LOGGED_CLIENT_CSV } from '../Queries/Queries'

import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'

const useStyles = makeStyles(theme => ({
  downloadIcon: {
    margin: theme.spacing(1),
    width: 150
  }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ClientRoutesExcel = props => {
  const { clientName, adcom = 0, client } = props

  const classes = useStyles()

  const input = {
    csv: true
  }

  const [skip, setSkip] = useState(true)
  const [csv, setCsv] = useState(null)
  const [showExport, setShowExport] = useState(false)
  const [currentClient, setCurrentClient] = useState(client)
  const clientRef = useRef(currentClient)
  const adcomRef = useRef(adcom)
  const [noData, setNoData] = useState(false)

  const filename = `${clientName || 'client'}-adcom${adcom}-${formatDate({ date: new Date(Date.now()) })}`

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  useEffect(() => {
    if (adcomRef.current !== adcom) {
      setShowExport(false)
      setCsv(null)
      adcomRef.current = adcom
    }
  }, [adcom, setCurrentClient, setCsv, setShowExport])

  useEffect(() => {
    if (clientRef.current !== client) {
      setCurrentClient(client)
      setShowExport(false)
      setCsv(null)
      clientRef.current = client
    }
  }, [client, setCsv, setCurrentClient, setShowExport])

  const exportTableToExcel = (csvData) => {
    const arr = csvData.data

    const finalArr = []
    let auxArr = []
    for (let i = 0; i < arr.length; i++) {
      if (i > 0 && arr[i].country === arr[i - 1].country) {
        auxArr.push(arr[i])
      } else if (i === 0 && arr[i].country === arr[i + 1].country) {
        auxArr.push(arr[i])
      } else if (i > 0 && arr[i].country !== arr[i - 1].country) {
        finalArr.push(auxArr)
        finalArr.push(false)
        auxArr = [arr[i]]
      }

      if (i === arr.length - 1) {
        finalArr.push(auxArr)
      }
    }

    if (finalArr.length > 0) {
      const ws = XLSX.utils.json_to_sheet(finalArr[0])

      let space = finalArr[0].length + 1
      for (let j = 0; j < finalArr.length; j++) {
        if (j > 0) {
          if (finalArr[j] === false) {
            space += 3
          } else {
            XLSX.utils.sheet_add_json(ws, finalArr[j], { skipHeader: false, origin: `A${space}` })
            space += finalArr[j].length
          }
        }
      }

      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      saveAs(data, filename + fileExtension)
    } else {
      setNoData(true)
    }
  }

  if (!client) {
    return <></>
  }

  const generateCsv = () => {
    setSkip(false)
  }

  // Create download link element
  return (
    <Query
      onError={(error) => {
        fireSwalError({
          title: 'Error',
          text: error ? error.message.split(':')[1] : 'Error generando el reporte'
        })
        setSkip(true)
      }}
      onCompleted={async (data) => {
        const { csv: csvData } = data?.getRoutesFromLoggedClient || {}
        if (csvData) {
          setSkip(true)
          setShowExport(true)
          setCsv(csvData)
        }
      }}
      query={GET_ROUTES_FROM_LOGGED_CLIENT_CSV}
      skip={skip}
      variables={{
        input
      }}
      fetchPolicy='network-only'
    >
      {({ data, loading }) => {
        if (loading) {
          return (
            <Box display='flex' alignItems='center' justifyContent='center' className={classes.downloadIcon}>
              <CircularProgress />
            </Box>
          )
        }

        return (
          <>
            {
              !showExport && (
                <div>
                  <Button
                    aria-label='Export'
                    className={classes.downloadIcon}
                    onClick={generateCsv}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Export XLS
                  </Button>
                </div>
              )
            }

            {
              showExport && csv && (
                <div>
                  <Button
                    aria-label='Export'
                    className={classes.downloadIcon}
                    onClick={(e) => exportTableToExcel(csv)}
                    startIcon={<CloudDownloadIcon />}
                    color='primary'
                  >
                    Download
                  </Button>
                </div>
              )
            }

            <Snackbar open={noData} onClose={() => setNoData(false)} autoHideDuration={5000}>
              <Alert severity='error'>There is no data to display!</Alert>
            </Snackbar>
          </>
        )
      }}
    </Query>
  )
}

export default ClientRoutesExcel
