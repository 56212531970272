import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const createTheme = (props = {}) => {
  const { fontSize = 12 } = props
  const spacing = 8
  let theme = createMuiTheme({
    palette: {
      common: {
        black: '#31393c',
        blue: '#2176ff',
        green: '#72cb5b',
        grey: '#cacfd6',
        pink: '#d34f73',
        red: '#d7263d',
        white: '#fcfbfb',
        yellow: '#f3a712'
      },
      background: {
        paper: '#fcfbfb',
        default: '#e6e7e9'
      },
      primary: {
        main: '#154ca3'
      },
      secondary: {
        main: '#06162f'
      },
      error: {
        main: '#d7263d'
      },
      text: {
        primary: 'rgba(86, 93, 95, 1)',
        secondary: 'rgba(123, 129, 130, 1)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
        success: '#72cb5b',
        error: '#e84855',
        action: '#2176ff'
      }
    },
    shape: {
      borderRadius: 0,
      unit: spacing
    },
    typography: {
      generalFontSize: fontSize,
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    },
    overrides: {
      MuiCardHeader: {
        title: {
          fontSize: fontSize + 2
        }
      },
      MuiSvgIcon: {
        root: {
          fontSize: fontSize * 1.5
        }
      },
      MuiSelect: {
        select: {
          padding: undefined,
          height: undefined
        },
        selectMenu: {
          height: undefined,
          lineHeight: undefined
        }
      },
      MuiMenuItem: {
        root: {
          fontSize: 16,
          minHeight: 32
        }
      },
      MuiFormControl: {
        root: {
          borderRadius: 8,
          margin: '16px 0 8px 0',
          fontSize: fontSize
        }
      },
      MuiInputBase: {
        root: {
          fontSize: fontSize
        },
        input: {
          fontSize: fontSize
        }
      },
      MuiInputLabel: {
        animated: {
          fontSize: fontSize
        }
      },
      MuiTableCell: {
        root: {
          fontSize,
          padding: '0'
        },
        head: {
          fontSize,
          fontWeight: 'bold'
        },
        sizeSmall: {
          padding: `0 ${spacing / 2}px`,
          lineHeight: 1,
          '&:last-child': {
            paddingRight: 4
          }
        }
      },
      MuiTableBody: {
        root: {
          whiteSpace: 'nowrap'
        }
      },
      MuiSkeleton: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, 0.08)'
        }
      }
    }
  })

  theme = responsiveFontSizes(theme)
  return theme
}
