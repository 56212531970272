import { CREATE_SPOTTCSPREAD_INFORMATION } from '../Queries/Queries'

const breadCrumbs = [
  {
    link: '/spot-tc-spreads',
    name: 'Spot TC Spread'
  }
]

const refetchQueries = [{
  query: CREATE_SPOTTCSPREAD_INFORMATION
}]

export const show = {
  createButton: {
    name: 'Create Spot TC Spread',
    link: '/create-spot-tc-spread'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'spotTCSpreadName', name: 'spotTCSpreadName' },
      { key: 'spreadTCValue', name: 'spreadTCValue' },
      { key: 'shipSector', name: 'shipSector' },
      { key: 'type', name: 'type' },
      { key: 'deliveryZone', name: 'deliveryZone' },
      { key: 'redeliveryZone', name: 'redeliveryZone' }
    ],
    rows: 3
  },
  module: {
    name: 'Spot TC Spread'
  },
  update: {
    link: '/update-spot-tc-spread'
  }
}

export const upsert = {
  module,
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Spot TC Spread Created',
      path: '/spot-tc-spreads'
    },
    submitButton: 'Create Spot TC Spread'
  },
  emptyObjects: {
    shipSectors: {
      name: 'Ship Sector',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Spot TC Spread updated',
      path: '/spot-tc-spreads'
    },
    submitButton: 'Update Spot TC Spread'
  }
}
