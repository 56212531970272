import React from 'react'
import { withRouter, useHistory } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Content
import { show as content } from './OwnerContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_OWNERS } from '../Queries/Queries'
// Mutations
// import { DELETE_OWNER } from '../Mutations/Mutations'
// Utils
import Grid from '@material-ui/core/Grid'
import AddIconButton from '../Utils/AddIconButton'
import TrieSearch from 'trie-search'
import SearchTextInput from '../Utils/SearchTextInput'

import ShowOwnersAgGrid from './ShowOwnersAgGrid'
const ShowOwners = props => {
  const history = useHistory()

  return (
    <>
      <Helmet>
        <title>Owners</title>
      </Helmet>
      <QueryHelper query={GET_OWNERS}>
        {({ data }) => {
          const { getOwners } = data
          return (
            <Grid>
              <OwnersTable ownersData={getOwners} history={history} />
            </Grid>
          )
        }}
      </QueryHelper>
    </>
  )
}

const OwnersTable = (props) => {
  const { ownersData, history } = props
  const [search, setSearch] = React.useState('')
  const [owners, setOwners] = React.useState(ownersData)
  const ts = new TrieSearch(['ownerName'], {
    idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  })
  ts.addAll(ownersData)
  const searchRef = React.useRef(search)
  React.useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setOwners(ownersData)
      } else {
        const searchArray = search.split(',')
        const foundDistances = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setOwners(foundDistances)
      }
    }
  }, [search, ownersData, ts])

  return (
    <Grid>
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>

      </SearchTextInput>
      <ShowOwnersAgGrid
        history={history}
        owners={owners}
      />
    </Grid>
  )
}

export default withRouter(ShowOwners)
