import React, { useState } from 'react'
import { Route, withRouter } from 'react-router-dom'
// Utils
import RootButton from './RootButton'
import RootTextField from './RootTextField'
import { fireSwalError } from './utils'
import { Box } from '@material-ui/core'
import RootH4 from './RootH4'

const passwd = 'PlutoIsAPlanet'
const version = ''
const PasswordWall = props => {
  const [ value, setValue ] = useState('')

  const _saveUserData = () => {
    localStorage.setItem('test-valid', value)
    props.history.push(`/`)
    props.history.go('/')
  }

  const validate = () => {
    if (value === passwd) {
      _saveUserData()
    } else {
      fireSwalError({
        title: 'ERROR',
        text: 'Wrong password'
      })
    }
  }

  return (
    <Box display='flex' height='100vh' justifyContent='center' flexDirection='column' alignItems='center'>
      <Box width='60%'>
        <RootH4>
          {version}
        </RootH4>
        <RootTextField
          value={value}
          onChange={ (e) => setValue(e.target.value)}
          label='Key'
        />
        <RootButton onClick={validate}>Submit</RootButton>
      </Box>
    </Box>
  )
}

const ServerTestRoute = ({ component: Component, ...rest }) => {
  const validTrueHagridLmao = localStorage.getItem('test-valid')
  return (
    <Route {...rest} render={props => {
      if (validTrueHagridLmao === passwd) {
        return <Component {...props} {...rest}/>
      }

      return <PasswordWall {...props} {...rest}/>
    }}/>
  )
}

export default withRouter(ServerTestRoute)
