import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between'
  },
}))

const BreadCrumbLink = props => {
  const { link } = props
  return (
    <Link color="inherit" href={link.link}>
      {link.name}
    </Link>
  )
}

BreadCrumbLink.propTypes = {
  link: PropTypes.object.isRequired,
}

const RootBreadCrumb = props => {
  const classes = useStyles()
  const { children, current, links, paperClass  } = props

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={paperClass ? paperClass : classes.paper }>
        <Breadcrumbs aria-label="breadcrumb">
          {
            links.map((link, index) => (
              <BreadCrumbLink link={link} key={index} />
            ))
          }
          <Typography color="textPrimary">{current}</Typography>
        </Breadcrumbs>
        {children}
      </Paper>
    </div>
  )
}

RootBreadCrumb.propTypes = {
  current: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
}

export default RootBreadCrumb