import React from 'react'

const CustomError = props => {
  // TODO IMplement error
  return (
    <div>
      Error
    </div>
  )
}

export default CustomError

