const breadCrumbs = [
  {
    link: '/owners',
    name: 'Owner'
  }
]

export const show = {
  createButton: {
    name: 'Create owner',
    link: '/create-owner'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'ownerName', name: 'ownerName' }
    ],
    rows: 2
  },
  module: {
    name: 'Owner'
  },
  update: {
    link: '/update-owner'
  }
}

export const upsert = {
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Owner created',
      path: '/owners'
    },
    submitButton: 'Create Owner'
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Owner updated',
      path: '/owners'
    },
    submitButton: 'Update Owner'
  }
}
