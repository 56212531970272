// React Library
import React, { useState, useRef, useEffect } from 'react'

// Helmet
import Helmet from 'react-helmet'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Content
import { show as content } from './SpotTCSpreadContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_SPOTTCSPREADS } from '../Queries/Queries'

// Mutation Related
import { DELETE_SPOTTCSPREAD } from '../Mutations/Mutations'

// Material UI Components
import AddIconButton from '../Utils/AddIconButton'
import RootGrid from '../Utils/RootGrid'
import SearchTextInput from '../Utils/SearchTextInput'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Utils
import { formatArrayForAgGrid } from '../Utils/utils'
import SpreadsAgGrid from './SpreadsAgGrid'

// Query Component
const ShowSpotTCSpreads = props => {
  return (
    <>
      <Helmet>
        <title>Spot TC Spread</title>
      </Helmet>
      <QueryHelper
        query={GET_SPOTTCSPREADS}
      >
        {({ data }) => {
          const { getSpotTCSpreads: { spotTCSpreads } } = data

          // Some Formatting...
          const spotTCSpreadsInformation = spotTCSpreads.map(spotTCSpread => {
            return {
              ...spotTCSpread,
              spreadTCValue: spotTCSpread?.spreadTCValue.map((elem, pos, arr) => formatArrayForAgGrid(elem, pos, arr)),
              shipSector: spotTCSpread.shipSector ? spotTCSpread.shipSector.shipSectorName : '-',
              deliveryZone: spotTCSpread.deliveryZone ? spotTCSpread.deliveryZone.zoneName : '-',
              redeliveryZone: spotTCSpread.redeliveryZone ? spotTCSpread.redeliveryZone.zoneName : '-'
            }
          })

          // Render
          return (
            <ShowSpotTCSpreadTable
              spotTCSpreadsInformation={spotTCSpreadsInformation}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowSpotTCSpreadTable = (props) => {
  const { spotTCSpreadsInformation = [] } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchSpotTCSpread, setSearchSpotTCSpread] = useState(spotTCSpreadsInformation)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['spotTCSpreadName', 'type', 'deliveryZone', 'redeliveryZone', 'shipSector'], {
    idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  })
  // The Array To Be Filtered (All Spot TC Spreads In the DB)
  ts.addAll(spotTCSpreadsInformation)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchSpotTCSpread(spotTCSpreadsInformation)
      } else {
        const searchArray = search.split(',')
        const foundSpreads = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setSearchSpotTCSpread(foundSpreads)
      }
    }
  }, [search, spotTCSpreadsInformation, ts])

  // The Update Spot TC Spread Link
  const updateLink = 'update-spot-tc-spread'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>

      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Spot TC Spreads Grid (Made By Us) */}
      <SpreadsAgGrid
        filteredArray={searchSpotTCSpread}
        mutation={DELETE_SPOTTCSPREAD}
        objectNameKey1='spotTCSpreadName'
        type='Spot TC Spread'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowSpotTCSpreads)
