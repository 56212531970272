import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    // marginLeft: 8,
    // marginRight: 16,
    // [theme.breakpoints.up('md')]: {
    //   marginLeft: 16
    // }
  },
  noMargin: {
    margin: 0
  },
  noMarginAutoComplete: {
    margin: 0,
    '& .MuiFormControl-root': {
      margin: 0
    }
  },
  marginBottomAutocomplete: {
    margin: 0,
    marginBottom: 8,
    '& .MuiFormControl-root': {
      margin: 0,
      marginBottom: 8
    }
  }
}))

const filterForObject = (options, selectedOption, comparator) => {
  for (let i = 0; i < options.length; i++) {
    const element = options[i]
    if (element[comparator] === selectedOption[comparator]) {
      return element
    }
  }
}

const filterForSingleValue = (options, selectedOption, comparator) => {
  for (let i = 0; i < options.length; i++) {
    const element = options[i]
    if (element[comparator] === selectedOption) {
      return element
    }
  }
}

const filterForArray = (options, selectedOptions, comparator, labelKey) => {
  const newArray = []
  for (let i = 0; i < selectedOptions.length; i++) {
    const element = selectedOptions[i]
    if (typeof element === 'string' || typeof element === 'number') {
      const filteredOption = filterForSingleValue(options, element, comparator)
      if (filteredOption) {
        newArray.push(filteredOption)
      }
    } else if (typeof element === 'object') {
      if (Object.keys(element).includes(labelKey)) {
        newArray.push(element)
      } else if (Object.keys(element).includes(comparator)) {
        const filteredOption = filterForObject(options, element, comparator)
        if (filteredOption) {
          newArray.push(filteredOption)
        }
      }
    }
  }
  return newArray
}

const RootAutocomplete = ({
  autoFocus,
  creatable,
  disableClearable,
  options,
  label,
  labelKey,
  placeholder,
  value,
  onChange,
  onInputChange,
  filterOptions,
  name,
  marginBottom,
  multiple,
  readOnly,
  validation,
  comparator,
  className: classNameProps,
  rootClassName,
  classes: classesProps,
  inputClass,
  optionClass,
  ...rest
}) => {
  const classes = useStyles()

  if (value === '' || value === null) {
    value = []
  }

  if (typeof value === 'string' || typeof value === 'number') {
    value = filterForSingleValue(options, value, comparator)
  } else if (Array.isArray(value)) {
    value = filterForArray(options, value, comparator, labelKey)
  } else if (typeof value === 'object') {
    if (Object.keys(value).includes(labelKey)) {
      value = value
    } else if (Object.keys(value).includes(comparator)) {
      value = filterForObject(options, value, comparator)
    }
  }

  const filterFunction = (currentValue) => {
    for (let index = 0, lt = value ? value.length : 0; index < lt; index++) {
      const element = value[index]
      if (element[labelKey] === currentValue[labelKey]) { return false }
    }
    return true
  }

  const filteredOptions = options.filter(option => filterFunction(option))

  let marginAutocomplete = classes.noMarginAutoComplete
  if (marginBottom) {
    marginAutocomplete = classes.marginBottomAutocomplete
  }

  return (
    <div className={clsx(classes.root, rootClassName)}>
      <Autocomplete
        autoHighlight
        classes={{
          root: marginAutocomplete,
          options: optionClass,
          input: inputClass
        }}
        className={clsx(classNameProps)}
        disableClearable={disableClearable}
        multiple={multiple}
        options={filteredOptions}
        renderOption={(option, { selected }) => {
          return (
            <Typography className={clsx([optionClass])}>{option.name}</Typography>
          )
        }}
        getOptionLabel={option => option[labelKey]}
        disabled={readOnly}
        value={value}
        filterOptions={filterOptions}
        onChange={(_e, v) => {
          const event = {
            type: 'select',
            value: v,
            name: name
          }
          onChange(event, validation)
        }}
        onInputChange={onInputChange}

        freeSolo={creatable}
        renderInput={params => (
          <TextField
            {...params}
            autoFocus={autoFocus}
            label={label}
            placeholder={placeholder}
          />
        )}
        {...rest}
      />
    </div>
  )
}

RootAutocomplete.defaultProps = {
  comparator: 'id',
  creatable: false,
  labelKey: 'name',
  multiple: false,
  name: '',
  options: [],
  readOnly: false,
  value: []
}

RootAutocomplete.propTypes = {
  comparator: PropTypes.string,
  creatable: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  validation: PropTypes.object
}

export default RootAutocomplete
