import React, { useState } from 'react'
// Utils
import BodyTypography from '../Utils/BodyTypography'
import RootButton from '../Utils/RootButton'
import RootGrid from '../Utils/RootGrid'
import RootH4 from '../Utils/RootH4'
import RootH5 from '../Utils/RootH5'
import RootPaper from '../Utils/RootPaper'
import Loading from '../Utils/Loading'
import RootDivider from '../Utils/RootDivider'
import RootForm from '../Utils/RootForm'
import { useFormInput } from '../Utils/utils'
// Validations
import { exampleObject } from '../Utils/form-validations'

export const countries = [
  {
    country: {
      id: null,
      name: 'Sin asignar'
    },
    states: []
  },
  {
    country: {
      id: 'México',
      name: 'México',
    },
    states: [
      { label: 'Aguascalientes' },
      { label: 'Baja California' },
      { label: 'Baja California Sur' },
      { label: 'Campeche' },
      { label: 'Chiapas' },
      { label: 'Chihuahua' },
      { label: 'Ciudad de México' },
      { label: 'Coahuila de Zaragoza' },
      { label: 'Colima' },
      { label: 'Durango' },
      { label: 'Estado de México' },
      { label: 'Guanajuato' },
      { label: 'Guerrero' },
      { label: 'Hidalgo' },
      { label: 'Jalisco' },
      { label: 'Michoacán' },
      { label: 'Morelos' },
      { label: 'Nayarit' },
      { label: 'Nuevo León' },
      { label: 'Oaxaca' },
      { label: 'Puebla' },
      { label: 'Querétaro' },
      { label: 'Quintana Roo' },
      { label: 'San Luis Potosí' },
      { label: 'Sinaloa' },
      { label: 'Sonora' },
      { label: 'Tabasco' },
      { label: 'Tamaulipas' },
      { label: 'Tlaxcala' },
      { label: 'Veracruz' },
      { label: 'Yucatán' },
      { label: 'Zacatecas' },
    ].map(suggestion => ({
      id: suggestion.label,
      name: suggestion.label,
    }))
  },
  {
    country: {
      id: 'Estados Unidos',
      name: 'Estados Unidos',
    },
    states: [
      { label: 'Alabama' },
      { label: 'Alaska' },
      { label: 'Arizona' },
      { label: 'Arkansas' },
      { label: 'California' },
      { label: 'Carolina del Norte' },
      { label: 'Carolina del Sur' },
      { label: 'Colorado' },
      { label: 'Connecticut' },
      { label: 'Dakota del Norte' },
      { label: 'Dakota del Sur' },
      { label: 'Delaware' },
      { label: 'Florida' },
      { label: 'Georgia' },
      { label: 'Hawái' },
      { label: 'Idaho' },
      { label: 'Illinois' },
      { label: 'Indiana' },
      { label: 'Iowa' },
      { label: 'Kansas' },
      { label: 'Kentucky' },
      { label: 'Luisiana' },
      { label: 'Maine' },
      { label: 'Maryland' },
      { label: 'Massachusetts' },
      { label: 'Míchigan' },
      { label: 'Minnesota' },
      { label: 'Misisipi' },
      { label: 'Misuri' },
      { label: 'Montana' },
      { label: 'Nebraska' },
      { label: 'Nevada' },
      { label: 'Nueva Jersey' },
      { label: 'Nueva York' },
      { label: 'Nuevo Hampshire' },
      { label: 'Nuevo México' },
      { label: 'Ohio' },
      { label: 'Oklahoma' },
      { label: 'Oregón' },
      { label: 'Pensilvania' },
      { label: 'Rhode Island' },
      { label: 'Tennessee' },
      { label: 'Texas' },
      { label: 'Utah' },
      { label: 'Vermont' },
      { label: 'Virginia' },
      { label: 'Virginia Occidental' },
      { label: 'Washington' },
      { label: 'Wisconsin' },
      { label: 'Wyoming' },
    ].map(suggestion => ({
      id: suggestion.label,
      name: suggestion.label,
    }))
  }
]

const Example = props => {
  const country = 'México'
  const countriesOptions = countries.map(c => c.country)
  let tmpState = countries.filter(c => c.country.id === country)
  const [stateOptions] = useState(
    tmpState.length ? tmpState[0].states : countries[0].states
  )
  const form = {
    left: {
      email: useFormInput({
        initialValue: '',
        type: 'text',
        label: 'Email'
      }),
      colony: useFormInput({
        initialValue:  '',
        type: 'text',
        label: 'Colonia'
      }),
      city: useFormInput({
        initialValue:  '',
        type: 'text',
        label: 'Ciudad'
      }),
      country: useFormInput({
        initialValue:  'México',
        label: 'País',
        select: true,
        selectValues: countriesOptions
      }),
      state: useFormInput({
        initialValue:  '',
        label: 'Estado',
        select: true,
        selectValues: stateOptions
      }),
      zipCode: useFormInput({
        initialValue:  new Date(Date.now()),
        type: 'date',
        label: 'Fecha'
      })
    },
    right: {
      phone1: useFormInput({
        initialValue:  '',
        type: 'text',
        label: 'Teléfono 1'
      }),
      emergencyContact: useFormInput({
        initialValue:  '',
        type: 'text',
        label: 'Nombre contacto de emergencia'
      }),
      phone2: useFormInput({
        initialValue: new Date(Date.now()),
        type: 'date',
        label: 'Fecha'
      }),
      email: useFormInput({
        initialValue:  '',
        type: 'text',
        label: 'Email'
      }),
      notes: useFormInput({
        initialValue:  '',
        type: 'text',
        label: 'Notas'
      })
    }
  }

  return (
    <RootGrid>
      <RootPaper>
        Root Paper
      </RootPaper>
      <RootButton>
        RootButton
      </RootButton>
      <RootH4>H4</RootH4>
      <RootH5>H5</RootH5>
      <BodyTypography> Body Typography </BodyTypography>
      <Loading open={false} />
      <RootPaper>
        <BodyTypography>Root divider</BodyTypography>
      </RootPaper>
      <RootDivider />
      <RootPaper>
        <BodyTypography>Root form</BodyTypography>
        <RootForm
          readOnly={false}
          form={form.left}
          validation={exampleObject}
        />
      </RootPaper>
    </RootGrid>
  );
}

export default Example
