import { makeStyles } from '@material-ui/core'

export const useSearchTextInputStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    marginLeft: '1px',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    width: 600,
    minWidth: 600
  },
  customRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    width: '100%',
    overflow: 'scroll',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden'
    }
  },
  autocompleteRoot: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    width: '100%',
    minWidth: '100%'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 150
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 20,
    margin: 4
  },
  container: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  inputContainer: {
    display: 'flex',
    height: 40,
    flexGrow: 4,
    width: '100%',
    alignContent: 'center',
    alignItems: 'center',
    marginRight: 8
  },
  textInput: {
    flexGrow: 7,
    padding: '0px 5px 0px 5px'
  },
  typography: {
    margin: '0px 8px 0px 8px'
  },
  autocomplete: {
    width: '100%',
    minWidth: '100%'
  }
}))
