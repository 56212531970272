// React
import React, { useState, useEffect, useReducer, useRef, useCallback } from 'react'

// Apollo
import { useMutation, useQuery } from 'react-apollo'

// Material UI
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Input from '@material-ui/core/Input'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

// Styles
import { makeStyles } from '@material-ui/core/styles'

// React Image
import ReactImage from 'react-image'

// CNF
import ShowClientRoutesCnf from './ShowClientRoutesCnf'

// Queries
import {
  GET_FOBDATA_FROM_CLIENT,
  GET_ROUTES_PER_COMMODITY
} from '../Queries/Queries'

// Mutations
import {
  UPDATE_FOBFUTURE_FOR_CLIENT,
  UPDATE_FOBIMPORT_FOR_CLIENT,
  UPDATE_FOBPREMIUM_FOR_CLIENT
} from '../Mutations/Mutations'

// Utils
import { formatNumber } from '../Utils/utils'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingTop: '1%',
    paddingBottom: '1%'
    // background: theme.palette.common.white
  },
  table: {
    tableLayout: 'fixed'
  },
  routesColumn: {
    flex: '0 0 10%',
    maxWidth: '15%'
  },
  routesColumnTypography: {
    fontSize: theme.typography.generalFontSize
  },
  flag: {
    flex: '0 0 85%',
    marginLeft: '15%'
  },
  flagTypography: {
    fontSize: theme.typography.generalFontSize,
    fontWeight: 'bold'
  },
  smallCell: {
    fontSize: theme.typography.generalFontSize - 2,
    fontWeight: 'light'
  },
  boldCell: {
    fontWeight: 'bold'
  },
  cellName: {
    minWidth: 100,
    maxWidth: 100,
    // fontWeight: 'bold'
    fontWeight: 400
  },
  quartersCell: {
    // borderRight: '1px solid #000',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  baseRoute: {
    backgroundColor: '#70FFC1'
  },
  divider: {
    display: 'block',
    height: 20
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  quarter: {
    borderRight: '1px solid rgba(123, 129, 130, 1)'
  },
  errorClass: {
    color: 'red'
  },
  countryStyle: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '170px'
  },
  countryStyleTypography: {
    marginLeft: 8,
    fontSize: theme.typography.generalFontSize + 1,
    fontWeight: 'bold'
  },
  countriesNames: {
    marginLeft: '15px',
    fontSize: theme.typography.generalFontSize - 1
  },
  routeResultsCell: {
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    }
  },
  flag: {
    width: 32
  },
  tableBoxes: {
    // marginLeft: '750px'
  },
  appBar: {
    // width: '1107px',
    // marginLeft: '750px',
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft: '0px'
    // }
  },
  mainBox: {
    width: '1107px',
    height: '300px',
    marginLeft: '750px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px'
    },
    overflowY: 'auto'
  }
}))

const ShowCnf = (props) => {
  const { client, commodities, adcom } = props

  const classes = useStyles()

  const [opt, setOpt] = useState(0)

  const handleChangeOpt = (event, newValue) => {
    setOpt(newValue)
  }

  return (
    <div>
      <AppBar
        position='static'
        color='white'
        className={classes.appBar}
        elevation={2}
      >
        <Tabs
          value={opt}
          onChange={handleChangeOpt}
          textColor='primary'
          indicatorColor='primary'
        >
          {commodities.map((item, i) => {
            return (
              <Tab
                key={i}
                value={i}
                label={item.commodityName}
                style={{
                  fontFamily: 'Arial'
                }}
              />
            )
          })}
        </Tabs>
      </AppBar>
      {
        <FobDataQueryHelper
          client={client}
          commodity={commodities[opt]}
          opt={opt}
          adcom={adcom}
        />
      }
    </div>
  )
}

const FobDataQueryHelper = (props) => {
  const { client, commodity, opt, adcom } = props

  const classes = useStyles()

  const fobInput = {
    client,
    commodity: commodity.id
  }

  const { data: fobData, loading: fobLoading, error: fobError } = useQuery(GET_FOBDATA_FROM_CLIENT, {
    fetchPolicy: 'network-only',
    variables: {
      input: fobInput
    },
    onCompleted (data) {
      console.log('fob completed', data)
    }
  })

  const fobFutures = fobData?.getFobDataFromClient?.fobFutures
  const fobImports = fobData?.getFobDataFromClient?.fobImports
  const fobPremiums = fobData?.getFobDataFromClient?.fobPremiums
  const loadCountries = fobData?.getFobDataFromClient?.loadCountries
  const dischargeCountries = fobData?.getFobDataFromClient?.dischargeCountries
  const fobDataStructure = fobData?.getFobDataFromClient?.fobDataStructure

  return (
    <>
      {
        fobLoading && (
          <Box
            width='100%'
            height='20vh'
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress />
          </Box>
        )
      }
      {
        !fobLoading && (
          <CnfInterface
            client={client}
            commodity={commodity}
            opt={opt}
            adcom={adcom}
            fobFutures={fobFutures}
            fobImports={fobImports}
            fobPremiums={fobPremiums}
            fobInput={fobInput}
            loadCountries={loadCountries}
            dischargeCountries={dischargeCountries}
            fobDataStructure={fobDataStructure}
          />
        )
      }
    </>
  )
}

const fobReducer = (state, action) => {
  switch (action.type) {
    case 'premium-value': {
      const countryId = action.countryId
      const index = action.index

      const newState = { ...state }

      newState.fobPremiums[countryId][index].value = action.value

      return {
        ...newState
      }
    }

    case 'import-value': {
      const countryId = action.countryId
      const index = action.index

      const newState = { ...state }

      newState.fobImports[countryId][index].value = action.value

      return {
        ...newState
      }
    }

    case 'future-value': {
      const index = action.index

      const newState = { ...state }

      newState.fobFutures.main[index].value = action.value

      return {
        ...newState
      }
    }

    case 'select-mapping-value': {
      const newState = { ...state }
      newState.mappings[action.mappingId].monthsMapping[action.key] = action.value
      // to-do: when updating the mapping change the value of the correct future within the fob structure

      const apiFuture = newState.apiFuture
      const futures = newState.fobFutures[action.mappingId]

      for (const f in futures) {
        if (f > 0) {
          if (futures[f].posdate.includes(action.key.substring(0, 3))) {
            newState.fobFutures[action.mappingId][f].value = formatNumber({ number: apiFuture[action.value], format: '0.00' })
          }
        }
      }

      return {
        ...newState
      }
    }

    case 'add': {
      const newState = action.data

      return {
        ...newState
      }
    }
    default: break
  }
}

const CnfInterface = React.memo((props) => {
  const {
    adcom,
    client,
    commodity,
    opt,
    fobPremiums,
    fobImports,
    fobFutures,
    fobInput,
    loadCountries,
    dischargeCountries,
    fobDataStructure,
  } = props

  const classes = useStyles()

  const [fobStruct, setFobStruct] = useReducer(fobReducer, fobDataStructure)

  const { data: routesPerCommodity, loading, error } = useQuery(GET_ROUTES_PER_COMMODITY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pdf: true,
        commodity: commodity.id
      }
    },
    onCompleted (data) {
      console.log('routes per commodity completed', data)
    }
  })

  const clientRoutes = routesPerCommodity?.getRoutesPerCommodity?.clientRoutes
  const report = routesPerCommodity?.getRoutesPerCommodity?.report
  const headers = clientRoutes?.headers

  useEffect(() => {
    setFobStruct({
      type: 'add',
      data: fobDataStructure
    })
  }, [fobDataStructure])

  const [updateFobFuture] = useMutation(UPDATE_FOBFUTURE_FOR_CLIENT)
  const [updateFobImport] = useMutation(UPDATE_FOBIMPORT_FOR_CLIENT)
  const [updateFobPremium] = useMutation(UPDATE_FOBPREMIUM_FOR_CLIENT)

  return (
    <div>
      {!!clientRoutes && !!fobStruct && (
        <ShowClientRoutesCnf
          apiFuture={fobDataStructure.apiFuture}
          fobFutures={fobFutures}
          fobStruct={fobStruct}
          adcom={adcom}
          client={client}
          commodity={commodity}
          clientRoutes={clientRoutes}
          hasNextPage={clientRoutes?.hasNextPage}
          quarterArray={clientRoutes?.quarterArray}
          report={report}
          headers={headers}
          fobPremiums={fobPremiums}
          fobImports={fobImports}
          loadCountries={loadCountries}
          dischargeCountries={dischargeCountries}
          setFobStruct={setFobStruct}
          updateFobFuture={updateFobFuture}
          updateFobPremium={updateFobPremium}
          updateFobImport={updateFobImport}
        />
      )}
    </div>
  )
})

export default ShowCnf
