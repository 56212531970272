import React, { useState } from 'react'

// Helmet
import Helmet from 'react-helmet'

// Apollo
import { Mutation } from 'react-apollo'

import { UPSERT_FILE } from '../Mutations/Mutations'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import { useFormInput } from '../Utils/utils'
import { Typography, Box, CircularProgress } from '@material-ui/core'
import Snackbar from '../Utils/SnackBar'

// Material UI
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

const Import = props => {
  const classes = useStyles()

  const [file, setFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const form = {
    file: useFormInput({
      initialValue: null,
      label: 'CSV',
      type: 'file',
      file,
      setFile
    })
  }

  const input = {
    file: form.file.file
  }

  return (
    <>
      <Helmet>
        <title>Daily Update</title>
      </Helmet>
      <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <Mutation
          mutation={UPSERT_FILE}
          onCompleted={() => {
            setOpen(true)
          }}
          variables={{ input }}
          onError={(error) => {
            setShowErrors(true)
          }}
        >
          {(mutation, data, result) => {
            const { loading } = data
            if (data) {
              if (data.error && showErrors) {
                console.log(data)
                console.log('Error', data.error)
                console.log('Message', data.error.message)
                console.log('GQL', data.error.graphQLErrors[0])
                if (data.error.graphQLErrors[0]) {
                  if (data.error.graphQLErrors[0].data) {
                    if (data.error.graphQLErrors[0].data.allErrors) {
                      console.log(data.error.graphQLErrors[0].data.allErrors)
                      return (
                        <>
                          <Typography variant='h4' gutterBottom color='error'>
                            {data.error.graphQLErrors[0].message}
                          </Typography>
                          <Typography variant='h6' gutterBottom>
                            The rest of the data was updated succesfully!
                          </Typography>
                          <Typography gutterBottom variant='subtitle1'>
                            Errors
                          </Typography>
                          {data.error.graphQLErrors[0].data.allErrors.map((item, i) => {
                            return (
                              <Typography gutterBottom key={i}>
                                {`Table: ${item.table} ${item.index ? `Row: ${item.index}` : ''} - Message: ${item.error}`}
                              </Typography>
                            )
                          })}
                          <RootButton className={classes.button} onClick={() => {
                            setFile(null)
                            setShowErrors(false)
                          }}
                          >Try Again
                          </RootButton>
                        </>
                      )
                    }
                  }
                }
              }
            }
            return (
              <>
                <RootForm
                  form={form}
                  validation={{}}
                />
                {
                  !loading &&
                  <RootButton className={classes.button} disabled={loading} onClick={() => mutation()}>Upload</RootButton>
                }
                {
                  loading &&
                  <CircularProgress />
                }
              </>
            )
          }}
        </Mutation>
        <Snackbar open={open} onClose={() => setOpen(false)} variant='success' message='Updated succesfully!' />
      </Box>
    </>
  )
}

Import.propTypes = {

}

export default Import
