import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
// Context
import { UserContext } from '../App/AppContext'
// Material UI
import Grid from '@material-ui/core/Grid'
// React data grid
import ReactDataGrid from 'react-data-grid'
import { Data, Menu, Toolbar } from 'react-data-grid-addons'
// Styles
// import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// Utils
import DeleteModal from './DeleteModal'
import RootColumnLink from './RootColumnLink'
import SecondaryButton from './SecondaryButton'
import VerifyPermissionsHOC from './VerifyPermissionsHOC'

const { ContextMenu, ContextMenuTrigger } = Menu
export const { MenuItem } = Menu

class EmptyToolbar extends React.Component {
  componentDidMount () {
    this.props.onToggleFilter()
  }

  render () {
    return (<div />)
  }
}

const CustomContextMenu = props => {
  const {
    contextChildren: ContextChildren,
    deleteMenu,
    id,
    idx,
    moduleName,
    name,
    onRowDelete,
    rowIdx,
    type
  } = props
  return (
    <ContextMenu id={id}>
      {(!ContextChildren && !deleteMenu) &&
        <MenuItem>No actions available</MenuItem>}
      {
        deleteMenu &&
          <VerifyPermissionsHOC
            moduleName={moduleName}
            type={type}
            permission='delete'
          >
            <MenuItem
              data={{ rowIdx, idx }}
              onClick={onRowDelete}
            >
            Delete {name}
            </MenuItem>
          </VerifyPermissionsHOC>
      }
      {
        ContextChildren &&
          <ContextChildren
            data={{ rowIdx, idx }}
            rows={props.rows}
          />
      }
    </ContextMenu>
  )
}

CustomContextMenu.propTypes = {
  deleteMenu: PropTypes.bool,
  id: PropTypes.string.isRequired,
  idx: PropTypes.number,
  contextChildren: PropTypes.func,
  onRowDelete: PropTypes.func,
  moduleName: PropTypes.string.isRequired,
  rowIdx: PropTypes.number,
  type: PropTypes.string.isRequired
}

const SelectAll = props => {
  return <div />
}

const selectors = Data.Selectors

const handleFilterChange = filter => filters => {
  const newFilters = { ...filters }
  if (filter.filterTerm) {
    newFilters[filter.column.key] = filter
  } else {
    delete newFilters[filter.column.key]
  }
  return newFilters
}

const getValidFilterValues = (rows, columnId) => {
  return rows
    .map(r => r[columnId])
    .filter((item, i, a) => {
      return i === a.indexOf(item)
    })
}

const getRows = (rows, filters) => {
  return selectors.getRows({ rows, filters })
}

const RootDataGrid = props => {
  const {
    columns,
    contextChildren,
    fixedWidth,
    deleteMenu,
    deleteCalcPageLink,
    deleteMutation,
    editLink,
    filterable,
    name,
    moduleName,
    refetchQueries,
    rows: initialRows,
    rowsCount,
    resizable,
    selectable,
    sortable,
    type,
    toolbar,
    ...rest
  } = props

  const { user } = useContext(UserContext)
  const [rows, setRows] = useState(initialRows)
  const [filters, setFilters] = useState({})
  const [selectedIndexes, setSelectedIndexes] = useState([])
  const filteredRows = getRows(rows, filters)

  // Delete
  const [open, setOpen] = useState(false)
  const [input, setInput] = useState({})

  const handleDeleteModalOpen = ({ idx }) => {
    if (idx === 0 || idx >= 0) {
      setInput({ id: filteredRows[idx].id })
    } else {
      setInput({ id: filteredRows[selectedIndexes[0]].id })
    }
    setOpen(true)
  }

  const defaultColumnProperties = {
    filterable,
    resizable,
    sortable
  }

  let combinedColumnWidth = 600

  const formatColumns = (data) => {
    // const gridWidth = parseInt(document.querySelector('#root').clientWidth, 10) // selector for grid
    combinedColumnWidth = 0

    for (let i = 0; i < data.columns.length; i++) {
      data.columns[i].width = getTextWidth(data, i)
      combinedColumnWidth += data.columns[i].width
    }

    // if (combinedColumnWidth < gridWidth) {
    //   data.columns = distributeRemainingSpace(
    //     combinedColumnWidth,
    //     data.columns,
    //     gridWidth
    //   )
    // }
    return data
  }

  const getTextWidth = (data, i) => {
    const rowValues = []
    const reducer = (a, b) => {
      if (!a) return b
      if (!b) return a
      return a.length > b.length ? a : b
    }
    const cellPadding = 8
    const arrowWidth = 18
    let longestCellData,
      longestCellDataWidth,
      longestColName,
      longestColNameWidth,
      longestString

    for (const row of data.rows) {
      rowValues.push(row[data.columns[i].key])
    }

    longestCellData = rowValues.reduce(reducer)
    longestColName = data.columns[i].name
    longestCellDataWidth = Math.ceil(
      getCanvas().measureText(longestCellData).width
    )
    longestColNameWidth =
      Math.ceil(getCanvas('bold ').measureText(longestColName).width) +
      arrowWidth

    longestString = Math.max(longestCellDataWidth, longestColNameWidth)

    return longestString + cellPadding
  }

  let canvas, canvasContext
  const fontSize = user.fontSize || 16
  const getCanvas = (fontWeight = '') => {
    if (!canvas) {
      canvas = document.createElement('canvas')
      canvasContext = canvas.getContext('2d')
    }
    canvasContext.font = `${fontWeight} ${fontSize + 2}px sans-serif`

    return canvasContext
  }

  // const distributeRemainingSpace = (combinedColumnWidth, columns, gridWidth) => {
  //   const spaceLeftOver = gridWidth - combinedColumnWidth
  //   const remainder = spaceLeftOver % columns.length
  //   const equalSpaceLeft = spaceLeftOver - remainder

  //   columns[0].width += remainder // any remaining space after distributing equally should go on first column

  //   for (const col of columns) {
  //     col.width += equalSpaceLeft / columns.length
  //   }
  //   return columns
  // }

  const onRowsSelected = rows => {
    if (rows.length > 1) {
      setSelectedIndexes([])
      return
    }
    const localSelectedIndexes = rows.map(r => r.rowIdx)
    setSelectedIndexes(localSelectedIndexes)
  }

  const onRowsDeselected = rows => {
    const localSelectedIndexes = rows.map(r => r.rowIdx)
    const newIndexes = selectedIndexes.filter(i => localSelectedIndexes.indexOf(i) === -1)
    setSelectedIndexes(newIndexes)
  }

  const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
    const comparer = (a, b) => {
      if (sortDirection === 'ASC') {
        return a[sortColumn] > b[sortColumn] ? 1 : -1
      } else if (sortDirection === 'DESC') {
        return a[sortColumn] < b[sortColumn] ? 1 : -1
      }
    }
    return sortDirection === 'NONE' ? initialRows : [...rows].sort(comparer)
  }

  useEffect(() => {
    const resizeEvent = document.createEvent('HTMLEvents')
    resizeEvent.initEvent('resize', true, false)
    window.dispatchEvent(resizeEvent)
    setTimeout(() => {
      window.dispatchEvent(resizeEvent)
    }, 100)
  }, [])

  useEffect(() => {
    setRows(initialRows)
    setSelectedIndexes([])
  }, [initialRows])

  let rootColumns = []
  columns.forEach(col => {
    if (col.hidden !== true) {
      rootColumns.push({ ...col, ...defaultColumnProperties })
    }
  })

  rootColumns[0] = { ...rootColumns[0], formatter: (props) => <RootColumnLink {...props} viewLink={`${editLink}/${props.row.id}`} /> }

  if (filteredRows.length > 0) {
    const formattedColumns = formatColumns({ columns: rootColumns, rows: filteredRows })
    rootColumns = formattedColumns.columns
  }

  const combinedColumnWidthRef = React.useRef(combinedColumnWidth)
  const width = fixedWidth ? combinedColumnWidthRef.current : `${combinedColumnWidth + 60}px`

  const widthRef = React.useRef(width)

  const useStyles = makeStyles(theme => ({
    root: {
      width: widthRef.current,
      fontSize: theme.typography.generalFontSize,
      margin: '0 auto'
    },
    grid: {
      width: widthRef.current,
      fontSize: theme.typography.generalFontSize,
      margin: '0 auto'
    }
  }))

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <ReactDataGrid
        className={classes.grid}
        columns={rootColumns}
        contextMenu={
          <CustomContextMenu
            contextChildren={contextChildren}
            {
              ...contextChildren && {
                rows: filteredRows
              }
            }
            deleteMenu={!!deleteMutation}
            id={name}
            moduleName={moduleName}
            name={name}
            onRowDelete={(e, { rowIdx }) => handleDeleteModalOpen({ idx: rowIdx })}
            type={type}
          />
        }
        onGridSort={(sortColumn, sortDirection) =>
          setRows(sortRows(initialRows, sortColumn, sortDirection))}
        rowGetter={i => filteredRows[i]}
        rowsCount={filteredRows.length}
        minHeight={500}
        toolbar={<Toolbar className={classes.root} enableFilter />}
        onAddFilter={filter => setFilters(handleFilterChange(filter))}
        onClearFilters={() => setFilters({})}
        getValidFilterValues={columnKey => getValidFilterValues(rows, columnKey)}
        RowsContainer={ContextMenuTrigger}
        selectAllRenderer={SelectAll}
        toolbar={<EmptyToolbar />} // here's the EmptyToolbar component placed
        {
          ...(selectable &&
        {
          rowSelection: {
            minWidth: 5,
            maxWidth: 50,
            resizable: true,
            showCheckbox: true,
            enableShiftSelect: false,
            onRowsSelected: onRowsSelected,
            onRowsDeselected: onRowsDeselected,
            selectBy: {
              indexes: selectedIndexes
            }
          }
        })
        }
        {...rest}
      />
      {
        selectedIndexes.length > 0 &&
          <Grid container>
            <SecondaryButton
              onClick={() => {
                props.history.push(`${editLink}/${rows[selectedIndexes[0]].id}`)
              }}
            >
            Edit
            </SecondaryButton>
            <SecondaryButton
              onClick={() => handleDeleteModalOpen(rows[selectedIndexes[0]].id)}
            >
            Delete
            </SecondaryButton>
          </Grid>

      }
      {
        deleteMutation &&
          <DeleteModal
            deleteCalcPageLink={deleteCalcPageLink}
            mutation={deleteMutation}
            name={name}
            input={input}
            open={open}
            refetchQueries={refetchQueries}
            setOpen={setOpen}
          />
      }
    </div>
  )
}

RootDataGrid.defaultProps = {
  editLink: '',
  filterable: true,
  name: 'object',
  refetchQueries: [],
  resizable: true,
  rowsCount: 10,
  selectable: true,
  sortable: true
}

RootDataGrid.propTypes = {
  columns: PropTypes.array.isRequired,
  contextChildren: PropTypes.func,
  deleteMutation: PropTypes.object,
  editLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  refetchQueries: PropTypes.array.isRequired,
  resizable: PropTypes.bool.isRequired,
  rows: PropTypes.array.isRequired,
  rowsCount: PropTypes.number,
  selectable: PropTypes.bool.isRequired,
  sortable: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
}

export default withRouter(RootDataGrid)
