import { CREATE_COMMODITY_INFORMATION } from '../Queries/Queries'

import { Filters } from 'react-data-grid-addons'
const { SingleSelectFilter } = Filters

const breadCrumbs = [
  {
    link: '/commodities',
    name: 'Commodity'
  }
]

const module = {
  name: 'Commodity'
}
const refetchQueries = [{
  query: CREATE_COMMODITY_INFORMATION
}]

export const show = {
  module,
  createButton: {
    name: 'Create Commodity',
    link: '/create-commodity'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'commodityName', name: 'commodityName', filterRenderer: SingleSelectFilter, width: 140 },
      { key: 'cargo', name: 'cargo' },
      { key: 'stowFactor', name: 'stowFactor', width: 80 },
      { key: 'forwardArray', name: 'forwardArray' }
    ],
    rows: 3
  },
  update: {
    link: '/update-commodity'
  }
}

export const upsert = {
  module,
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Commodity created',
      path: '/commodities'
    },
    submitButton: 'Create Commodity'
  },
  emptyObjects: {
    positionDate: {
      name: 'Position Dates',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Commodity updated',
      path: '/calculation-page'
    },
    submitButton: 'Update Commodity'
  }
}
