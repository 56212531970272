import React, { Fragment, useState, useReducer, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
// Clsx
import clsx from 'clsx'
// Material UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DescriptionIcon from '@material-ui/icons/Description'
import ShuffleIcon from '@material-ui/icons/Shuffle'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import WarningIcon from '@mui/icons-material/Warning'

import { makeStyles } from '@material-ui/core/styles'
// React Image
import ReactImage from 'react-image'
// Utils
import { formatNumber, formatNumberWithMultiplesOfFive, formatNumberWithMultiplesOfTwentyFive } from '../Utils/utils'
import { UserContext } from '../App/AppContext'
import { route } from '../Utils/form-validations'
import InputBase from '@material-ui/core/InputBase'
import FobFutureMapping from '../FobFutureMapping/FobFutureMapping'
import FuturePrices from '../FobFutureMapping/FuturePrices'
import { useMutation } from 'react-apollo'

//Mutations
import { CREATE_MAPPING_FOR_ROUTE } from '../Mutations/Mutations'

// Queries
import {
  GET_FOBDATA_FROM_CLIENT,
  GET_ROUTES_PER_COMMODITY
} from '../Queries/Queries'

const useTooltipClasses = makeStyles(theme => ({
  text: {
    fontSize: theme.typography.generalFontSize - 2
  }
}))

// Custom Alert
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const HtmlTooltip = props => {
  const { children, onHoverData = [] } = props
  const classes = useTooltipClasses()
  return (
    <Tooltip
      title={
        <div className={classes.tooltip}>
          {
            onHoverData.map(data => {
              return (
                <Typography key={data} className={classes.text} color='inherit'>{data}</Typography>
              )
            })
          }
        </div>
      }
    >
      {children}
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingTop: '1%',
    paddingBottom: '1%'
    // background: theme.palette.common.white
  },
  table: {
    tableLayout: 'fixed'
  },
  routesColumn: {
    flex: '0 0 10%',
    maxWidth: '15%'
  },
  routesColumnTypography: {
    fontSize: theme.typography.generalFontSize
  },
  flag: {
    // flex: '0 0 85%',
    // marginLeft: '15%',
    width: 32
  },
  flagTypography: {
    fontSize: theme.typography.generalFontSize,
    fontWeight: 'bold'
  },
  smallCell: {
    lineHeight: 1,
    fontSize: theme.typography.generalFontSize - 2,
    fontWeight: 'light'
  },
  boldCell: {
    fontWeight: 'bold'
  },
  cellName: {
    minWidth: 100,
    maxWidth: 100,
    // fontWeight: 'bold'
    fontWeight: 400
  },
  quartersCell: {
    // borderRight: '1px solid #000',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  baseRoute: {
    backgroundColor: '#70FFC1'
  },
  divider: {
    display: 'block',
    height: 20
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  quarter: {
    borderRight: '1px solid rgba(123, 129, 130, 1)'
  },
  errorClass: {
    color: 'red'
  },
  countryStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  countryStyleTypography: {
    marginLeft: 8,
    fontSize: theme.typography.generalFontSize + 1,
    fontWeight: 'bold'
  },
  routeResultsCell: {
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    }
  },
  cnfFlag: {
    height: '18px'
  },
  countriesNames: {
    marginLeft: '15px',
    fontSize: theme.typography.generalFontSize - 2
  },
  cancelButton: {
    color: '#EA695F'
  },
  continueButton: {
    color:'#55A9E1'
  }
}))

const spreadFobReducer = (state, action) => {
  switch (action.type) {
    case 'spread-fob': {
      const countryIdx = action.countryIdx
      const routeIdx = action.routeIdx
      
      const newState = [ ...state ]
      newState[countryIdx][routeIdx].spread = true

      return [
        ...newState
      ]
    }
    case 'unspread-fob': {
      const countryIdx = action.countryIdx
      const routeIdx = action.routeIdx
      
      const newState = [ ...state ]
      newState[countryIdx][routeIdx].spread = false

      return [
        ...newState
      ]
    }
    default: break
  }
}

const menuContextInit = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

const menuContextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...menuContextInit
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

const ShowClientRoutesCnf = React.memo((props) => {
  const { adcom, apiFuture, client, commodity, clientRoutes, dischargeCountries, fobFutures, fobPremiums, fobImports, hasNextPage, loadCountries, quarterArray, fobStruct, setFobStruct, updateFobPremium, updateFobFuture, updateFobImport, headers } = props

  const classes = useStyles()

  const initSpreadFobs = clientRoutes?.clientRoutes?.map((clientRoute, i) => {
    return clientRoute?.routes?.map((route, j) => {
      return {
        index: j,
        spread: false
      }
    })
  })

  let scalar = commodity.commodityName === 'Sugar Raw' ? 22.04623 : 1
  
  const [openMapping, setOpenMapping] = useState(false)
  const [openPrices, setOpenPrices] = useState(false)
  const [openCreateMappingDialog, setOpenCreateMappingDialog] = useState(false)
  const [spreadFobs, setSpreadFobs] = useReducer(spreadFobReducer, initSpreadFobs)
  const [contextState, setContextState] = useReducer(menuContextReducer, menuContextInit)
  const [openSnackBar, setOpenSnackBar] = useState(false)

  const tempClientHeaders = ['loadPort', 'dischargePort', 'intake', 'draft', 'loadDischTerm', 'spread', 'spreadFob'] //  'stem', 'commodity', 'sector',
  headers.headersData = headers.headersData.filter(item => tempClientHeaders.includes(item.key))

  const [countries, setCountries] = React.useState(clientRoutes.clientRoutes)

  const recalculateCountries = props => {
    const a = new Date(Date.now())
    const { adcom, countries } = props

    const newCountries = countries.map((country, i) => {
      const newRoutes = country.routes.map((route, i) => {
        let first = formatNumber({ number: parseFloat(country.routes[0].routeResults[0].rate) * (1 + adcom / 100) });
        ({ formattedRate: first } = formatNumberWithMultiplesOfTwentyFive({ formattedRate: first }))
        first = formatNumber({ number: first })
        let spread = route.routeResults[0].rate
        try {
          let current = parseFloat(spread)
          current = formatNumber({ number: current * (1 + adcom / 100) })
          const { formattedRate } = formatNumberWithMultiplesOfTwentyFive({ formattedRate: current })
          current = formatNumber({ number: formattedRate })
          // Calculate rate with adcom
          spread = formatNumber({
            number: parseFloat(current) - parseFloat(first)
          })
        } catch (e) {
          spread = route.spread
        }
        const newRouteResults = route.routeResults.map((result, j) => {
          let text = result.rate
          try {
            // Calculate rate with adcom
            let formattedRate = formatNumber({ number: parseFloat(text) * (1 + adcom / 100) });
            // Format the rate with decimals with multiple of five
            ({ formattedRate } = formatNumberWithMultiplesOfTwentyFive({ formattedRate }))
            text = formatNumber({ number: formattedRate })
          } catch (e) {
            text = result.text
          }
          return {
            ...result,
            text
          }
        })

        return {
          ...route,
          routeResults: newRouteResults,
          spread
        }
      })
      return {
        ...country,
        routes: newRoutes
      }
    })
    const b = new Date(Date.now())
    return newCountries
  }

  React.useEffect(() => {
    const newCountries = recalculateCountries({ adcom, countries })
    setCountries(newCountries)
  }, [adcom, setCountries])

  React.useEffect(() => {
    if (countries.length !== clientRoutes.clientRoutes) {
      const newCountries = recalculateCountries({ adcom, countries: clientRoutes.clientRoutes })
      setCountries(newCountries)
    }
  }, [clientRoutes])

  const handleOpenCommodityMapping = () => {
    setOpenMapping(true)
  }

  const handleOpenPrices = () => {
    setOpenPrices(true)
  }

  const handleOpenMenu = (event, route, index) =>  {
    event.preventDefault()

    setContextState({
      type: 'open',
      event,
      selectedRow: {
        routeId: route.id,
        routeName: route.routeName,
        index
      }
    })
  }

  const handleCloseMenu = () => {
    setContextState({
      type: 'close'
    })
  }

  return (
    <>
      <Paper className={classes.root} elevation={2}>
        <Table size='small'>
          {/* {
            fobFutures.map((futures) => {

            })
            return ()
          } */}
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align='right' style={{ borderBottom: 'none' }}>
                {
                  !!fobStruct.apiFuture && (
                    <Tooltip title='Last future prices saved' arrow>
                      <Button
                        onClick={handleOpenPrices}
                      >
                        <DescriptionIcon />
                      </Button>
                    </Tooltip>
                  )
                }
                <Button
                  onClick={handleOpenCommodityMapping}
                >
                  <Tooltip title='Default mapping' arrow>
                    <ShuffleIcon />
                  </Tooltip>
                </Button>
              </TableCell>
              <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                <Typography className={classes.countryStyleTypography} display='inline'>
                  Fob Futures
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align='right' style={{ borderBottom: 'none' }} />
              {
                headers?.headersPositionDates.map((header, i) => {
                  return (
                    <TableCell style={{ borderBottom: 'none' }} v key={`${header.text}${i}`} align={header.align} className={classes[header.className]}>{header.text}</TableCell>
                  )
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} style={{ borderBottom: 'none' }} />
              {fobStruct.fobFutures.main.map((fobFuture, i) => {
                return (
                  <TableCell key={i} align='center' style={{ borderBottom: 'none' }}>
                    {/* {formatNumber({ number: 1055, format: '0.00' })} */}
                    <FobFutureInput
                      key={i}
                      i={i}
                      fobData={fobFuture}
                      setFobStruct={setFobStruct}
                      fobStruct={fobStruct}
                      action='future-value'
                      mutation={updateFobFuture}
                    />
                  </TableCell>
                )
              })}
            </TableRow>
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align='right' style={{ borderBottom: 'none' }} />
              <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                <Typography className={classes.countryStyleTypography} display='inline'>
                  Fob Premium
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align='right' style={{ borderBottom: 'none' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {loadCountries?.map((loadCountry, i) => {
              return (
                <TableRow key={i}>
                  <TableCell colSpan={6} style={{ borderBottom: 'none' }} />
                  <TableCell align='left' className={classes.smallCell}>
                    <Box display='flex' justifyContent='flex-start' className={classes.smallCell}>
                      <ReactImage className={classes.cnfFlag} src={`/flags/${loadCountry.flag}.png`} alt={loadCountry.flag} />
                      <Typography className={classes.countriesNames} display='inline'>
                        {loadCountry.flag}
                      </Typography>
                    </Box>
                  </TableCell>
                  {fobPremiums?.filter(fobP => fobP.country.id === loadCountry.id).map((fobPremium, i) => {
                    return (
                      <TableCell key={i} align='center' className={classes.smallCell}>
                        <FobDataInput
                          key={i}
                          i={i}
                          fobData={fobPremium}
                          setFobStruct={setFobStruct}
                          fobStruct={fobStruct}
                          fobKey='fobPremiums'
                          action='premium-value'
                          mutation={updateFobPremium}
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} align='right' style={{ borderBottom: 'none' }} />
              <TableCell colSpan={2} style={{ borderBottom: 'none' }}>
                <Typography className={classes.countryStyleTypography} display='inline'>
                  Import
                </Typography>
              </TableCell>
              <TableCell colSpan={1} align='right' style={{ borderBottom: 'none' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {dischargeCountries?.map((dischargeCountry, i) => {
              return (
                <TableRow key={i}>
                  <TableCell colSpan={6} className={classes.smallCell} style={{ borderBottom: 'none' }} />
                  <TableCell align='left' className={classes.smallCell}>
                    {/* <Box display='flex' justifyContent='flex-start'> */}
                    <ReactImage className={classes.cnfFlag} src={`/flags/${dischargeCountry.flag}.png`} alt={dischargeCountry.flag} />
                    <Typography className={classes.countriesNames} display='inline'>
                      {dischargeCountry.flag}
                    </Typography>
                    {/* </Box> */}
                  </TableCell>
                  {fobImports?.filter(fobP => fobP.country.id === dischargeCountry.id).map((fobImport, i) => {
                    return (
                      <TableCell key={i} align='center' className={classes.smallCell}>
                        <FobDataInput
                          key={i}
                          i={i}
                          fobData={fobImport}
                          setFobStruct={setFobStruct}
                          fobStruct={fobStruct}
                          fobKey='fobImports'
                          action='import-value'
                          mutation={updateFobImport}
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
          {
            countries.map((country, indexCountry) => {
              const flag = country.flag
              const name = country.name
              const routes = country.routes

              return (
                <Fragment key={indexCountry}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={8}>
                        <div className={classes.countryStyle}>
                          <ReactImage className={classes.flag} src={`/flags/${flag}.png`} alt={flag} />
                          <Typography className={classes.countryStyleTypography} display='inline'>
                            {country.name}
                          </Typography>
                        </div>
                        {/* <ReactImage src={country.flag} alt={country.name} /> */}
                      </TableCell>
                      {/* <TableCell>{country.name} </TableCell> */}
                    </TableRow>
                    <TableRow>
                      {
                        headers.headersData.map((header, i) => {
                          if(header.key === 'spreadFob') {
                            return (
                              <TableCell></TableCell>
                            )
                          } else {
                            return (
                              <TableCell key={`${header.text}${i}`} align={header.align} className={classes[header.className]}>{header.text}</TableCell>
                            )
                          }
                        })
                      }
                      {
                        headers.headersPositionDates.map((header, i) => {
                          return (
                            <TableCell key={`${header.text}${i}`} align={header.align} className={classes[header.className]}>{header.text}</TableCell>
                          )
                        })
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      routes.map((route, i) => {

                        const routeObj = {
                          id: route.id,
                          routeName: `${route.routeData.loadPort.text}-${route.routeData.dischargePort.text}`
                        }

                        return (
                          <>
                            <TableRow index={i} key={i} className={[classes.quartersCell]}>
                              {
                                headers.headersData.map((header, j) => {
                                  const data = route.routeData[header.key]
                                  if (j === 0) {
                                    return (
                                      <>
                                      {
                                        !!fobStruct.mappings[route.id] && (
                                          <Tooltip arrow title='Uses special mapping'>
                                            <TableCell key={`${data.text}${j}`} onContextMenu={e => handleOpenMenu(e, routeObj, i)}>
                                              {data.text}
                                            </TableCell>
                                          </Tooltip>
                                        )
                                      }
                                      {
                                        !fobStruct.mappings[route.id] && (
                                          <Tooltip arrow title='Uses default mapping'>
                                            <TableCell key={`${data.text}${j}`} onContextMenu={e => handleOpenMenu(e, routeObj, i)}>
                                              {data.text}
                                            </TableCell>
                                          </Tooltip>
                                        )
                                      }
                                      </>
                                    )
                                  }
                                  if (header.key === 'loadDischTerm') {
                                    return (
                                      <HtmlTooltip key={`${data.text}${j}`} onHoverData={route.onHoverData}>
                                        <TableCell align={data.align} className={classes[data.className]}>{data.text}</TableCell>
                                      </HtmlTooltip>
                                    )
                                  }
                                  if (header.key === 'spreadFob') {
                                    return (
                                      <TableCell>
                                        <SpreadFobButton
                                          spreadFobs={spreadFobs}
                                          setSpreadFobs={setSpreadFobs}
                                          indexCountry={indexCountry}
                                          indexRoute={i}
                                        />
                                      </TableCell>
                                    )
                                  }
                                  if (header.key === 'spread') {
                                    return (
                                      <TableCell style={{ width: '80px'}} key={`${data.text}${j}`} align={data.align} className={clsx(classes[data.className], classes.quarter)}>{data.text}</TableCell>
                                    )
                                  }
                                  return (
                                    <TableCell key={`${data.text}${j}`} align={data.align} className={classes[data.className]}>{data.text}</TableCell>
                                  )
                                })
                              }
                              {
                                route.routeResults.map((result, j) => {
                                  const needsBorderQuarter = [10, 7, 4, 0]
                                  const quarter = quarterArray.includes(j)
                                  const text = result.rate

                                  const fpremium = !!fobStruct?.fobPremiums && !!fobStruct?.fobPremiums[route.loadCountryId] && !!fobStruct?.fobPremiums[route.loadCountryId][j]
                                  const fimport  = !!fobStruct?.fobImports && !!fobStruct?.fobImports[route.dischCountryId] && !!fobStruct?.fobImports[route.dischCountryId][j]

                                  const ffuture = !!fobStruct?.fobFutures
                                  const futureRouteId = ffuture && fobStruct.fobFutures[result.route] ? result.route : 'main'

                                  const fobPremium = (fpremium && isNaN(parseFloat(fobStruct.fobPremiums[route.loadCountryId][j].value)))
                                    ? 0
                                    : parseFloat(fobStruct.fobPremiums[route.loadCountryId][j].value)
                                  
                                  const fobImport = (fimport && isNaN(parseFloat(fobStruct.fobImports[route.dischCountryId][j].value)))
                                    ? 0
                                    :parseFloat(fobStruct.fobImports[route.dischCountryId][j].value)

                                  const fobFuture = (ffuture && isNaN(parseFloat(fobStruct.fobFutures[futureRouteId][j].value)))
                                  ? 0
                                  : parseFloat(fobStruct.fobFutures[futureRouteId][j].value)

                                  const routeResult = parseFloat(result.text)

                                  return (
                                    <TableCell
                                      key={`${text}${j}`}
                                      align={result.align}
                                      className={classes[result.className]}
                                      className={clsx(classes.routeResultsCell, {
                                        [classes.quarter]: quarter
                                      })}
                                    >
                                      {formatNumber({ number: routeResult + scalar * fobFuture + fobPremium + fobImport, format: '0.00' })}
                                    </TableCell>
                                  )
                                })
                              }
                            </TableRow>

                            {/* Spread Fob rows  */}
                            {
                              spreadFobs[indexCountry][i].spread && (
                                <>
                                  <TableRow>
                                    {
                                      headers.headersData.map((header, j) => {
                                        if (j === headers.headersData.length - 1) {
                                          return (
                                            <TableCell align='right' style={{ fontSize: '11px' }} className={classes.quarter}>
                                              Futures
                                            </TableCell>
                                          )
                                        } else {
                                          return (
                                            <TableCell></TableCell>
                                          )
                                        }
                                      })
                                    }
                                    {
                                      route.routeResults.map((result, j) => {
                                        const quarter = quarterArray.includes(j)
                                        const ffuture = !!fobStruct?.fobFutures
                                        const futureRouteId = ffuture && fobStruct.fobFutures[result.route] ? result.route : 'main'

                                        const fobFuture = (ffuture && isNaN(parseFloat(fobStruct.fobFutures[futureRouteId][j].value)))
                                          ? 0
                                          : parseFloat(fobStruct.fobFutures[futureRouteId][j].value)
                                        
                                        return (
                                          <TableCell
                                            align='center'
                                            style={{ fontSize: '11px' }}
                                            className={clsx(classes.routeResultsCell, {
                                              [classes.quarter]: quarter
                                            })}
                                          >
                                            {formatNumber({ format: '0.00', number: scalar * fobFuture })}
                                          </TableCell>
                                        )
                                      })
                                    }
                                  </TableRow>
                                  <TableRow>
                                  {
                                    headers.headersData.map((header, j) => {
                                      if (j === headers.headersData.length - 1) {
                                        return (
                                          <TableCell align='right' style={{ fontSize: '11px' }} className={classes.quarter}>
                                            Fob Premium
                                          </TableCell>
                                        )
                                      } else {
                                        return (
                                          <TableCell></TableCell>
                                        )
                                      }
                                    })
                                  }
                                  {
                                    route.routeResults.map((result, j) => {
                                      const quarter = quarterArray.includes(j)
                                      const fpremium = !!fobStruct?.fobPremiums && !!fobStruct?.fobPremiums[route.loadCountryId] && !!fobStruct?.fobPremiums[route.loadCountryId][j]
                                      const fobPremium = (fpremium && isNaN(parseFloat(fobStruct.fobPremiums[route.loadCountryId][j].value)))
                                        ? 0
                                        : parseFloat(fobStruct.fobPremiums[route.loadCountryId][j].value)

                                      
                                      return (
                                        <TableCell
                                            align='center'
                                            style={{ fontSize: '11px' }}
                                            className={clsx(classes.routeResultsCell, {
                                              [classes.quarter]: quarter
                                            })}
                                          >
                                          {formatNumber({ format: '0.00', number: fobPremium })}
                                        </TableCell>
                                      )
                                    })
                                  }
                                </TableRow>
                                <TableRow>
                                  {
                                    headers.headersData.map((header, j) => {
                                      if (j === headers.headersData.length - 1) {
                                        return (
                                          <TableCell align='right' style={{ fontSize: '11px' }} className={classes.quarter}>
                                            Import
                                          </TableCell>
                                        )
                                      } else {
                                        return (
                                          <TableCell></TableCell>
                                        )
                                      }
                                    })
                                  }
                                  {
                                    route.routeResults.map((result, j) => {
                                      const quarter = quarterArray.includes(j)
                                      const fimport  = !!fobStruct?.fobImports && !!fobStruct?.fobImports[route.dischCountryId] && !!fobStruct?.fobImports[route.dischCountryId][j]
                                      const fobImport = (fimport && isNaN(parseFloat(fobStruct.fobImports[route.dischCountryId][j].value)))
                                        ? 0
                                        :parseFloat(fobStruct.fobImports[route.dischCountryId][j].value)

                                      
                                      return (
                                        <TableCell
                                            align='center'
                                            style={{ fontSize: '11px' }}
                                            className={clsx(classes.routeResultsCell, {
                                              [classes.quarter]: quarter
                                            })}
                                          >
                                          {formatNumber({ format: '0.00', number: fobImport })}
                                        </TableCell>
                                      )
                                    })
                                  }
                                </TableRow>
                                <TableRow>
                                  {
                                    headers.headersData.map((header, j) => {
                                      if (j === headers.headersData.length - 1) {
                                        return (
                                          <TableCell align='right' style={{ fontSize: '11px'}} className={classes.quarter}>
                                            Freight
                                          </TableCell>
                                        )
                                      } else {
                                        return (
                                          <TableCell></TableCell>
                                        )
                                      }
                                    })
                                  }
                                  {
                                    route.routeResults.map((result, j) => {
                                      const quarter = quarterArray.includes(j)
                                      const routeResult = parseFloat(result.text)

                                      return (
                                        <TableCell
                                            align='center'
                                            style={{ fontSize: '11px' }}
                                            className={clsx(classes.routeResultsCell, {
                                              [classes.quarter]: quarter
                                            })}
                                          >
                                          {formatNumber({ format: '0.00', number: routeResult })}
                                        </TableCell>
                                      )
                                    })
                                  }
                                </TableRow>
                                </>
                              )
                            }
                          </>
                        )
                      })
                    }
                  </TableBody>
                </Fragment>
              )
            })
          }

        </Table>
        {
          hasNextPage && (
            <Table style={{ marginTop: 8 }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={8}>
                    <div className={classes.countryStyle}>
                      <Skeleton animation='wave' variant='rect' width={32} height={32} />
                      <Typography className={classes.countryStyleTypography} display='inline'>
                        <Skeleton animation='wave' width={200} />
                      </Typography>
                    </div>
                    {/* <ReactImage src={country.flag} alt={country.name} /> */}
                  </TableCell>
                  {/* <TableCell>{country.name} </TableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton animation='wave' width='100%' />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Skeleton animation='wave' width='100%' />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton animation='wave' width='100%' />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Skeleton animation='wave' width='100%' />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )
        }
      </Paper>
      {
        !!fobStruct.apiFuture && (
          <FuturePrices 
            apiFuture={fobStruct.apiFuture}
            openPrices={openPrices}
            setOpenPrices={setOpenPrices}
          />
        )
      }
      <FobFutureMapping
        apiFuture={apiFuture}
        mapping={
          (contextState.selectedRow && fobStruct.mappings[contextState.selectedRow.routeId])
            ? fobStruct.mappings[contextState.selectedRow.routeId]
            : fobStruct.mappings.main
        }
        mappingId={
          (contextState.selectedRow && fobStruct.mappings[contextState.selectedRow.routeId])
            ? contextState.selectedRow.routeId
            : 'main'
        }
        setFobStruct={setFobStruct}
        client={client}
        commodity={commodity}
        route={contextState.selectedRow}
        open={openMapping}
        setOpen={setOpenMapping}
        setContextState={setContextState}
      />
      <CreateMappingDialog 
        open={openCreateMappingDialog}
        setOpen={setOpenCreateMappingDialog}
        setOpenMapping={setOpenMapping}
        setContextState={setContextState}
        client={client}
        commodity={commodity}
        route={contextState.selectedRow && contextState.selectedRow.routeId}
        setOpenSnackBar={setOpenSnackBar}
      />

      <Menu
        keepMounted
        open={contextState.mouseY !== null}
        onClose={handleCloseMenu}
        anchorReference='anchorPosition'
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX }
            : undefined
        }
      >
        {
          (contextState.selectedRow && !fobStruct.mappings[contextState.selectedRow.routeId]) && (
            <MenuItem onClick={() => setOpenCreateMappingDialog(true)}>
              <AddIcon style={{ height: '20px', width: '20px', color: 'green', marginRight: '7px' }} />
                Create custom mapping for this route
            </MenuItem>

          )
        }
        {
          (contextState.selectedRow && fobStruct.mappings[contextState.selectedRow.routeId]) && (
            <>
              <MenuItem onClick={() => setOpenMapping(true)}>
                  <EditIcon style={{ height: '20px', width: '20px', color: 'gray', marginRight: '7px' }} />
                    Edit mapping for this route
                </MenuItem>
                {/* <MenuItem>
                  <RemoveIcon style={{ height: '20px', width: '20px', color: 'red', marginRight: '7px' }} />
                    Remove mapping for this route
                </MenuItem>               */}
            </>
          )
        }
      </Menu>
      <Snackbar open={openSnackBar} onClose={() => setOpenSnackBar(false)} autoHideDuration={5000}>
        <Alert severity='success'>The new mapping has been created!</Alert>
      </Snackbar>
    </>
  )
})


const SpreadFobButton = props => {
  const {
    spreadFobs,
    setSpreadFobs,
    indexCountry,
    indexRoute
  } = props

  const handleAdd = () => {
    setSpreadFobs({
      type: 'spread-fob',
      countryIdx: indexCountry,
      routeIdx: indexRoute
    })
  }

  const handleRemove = () => {
    setSpreadFobs({
      type: 'unspread-fob',
      countryIdx: indexCountry,
      routeIdx: indexRoute
    })
  }

  if (spreadFobs) {
    if (!spreadFobs[indexCountry][indexRoute].spread) {
      return (
        <IconButton
          style={{ padding: 0 }}
          onClick={handleAdd}
          >
          <AddIcon style={{ height: '15px', width: '15px', color: 'green' }} />
        </IconButton>
      )
    } else {
      return (
        <IconButton
          onClick={handleRemove}
          style={{ padding: 0 }}
          >
          <RemoveIcon style={{ height: '15px', width: '15px', color: 'red' }} />
        </IconButton>
      )
    }

    
  } else {
    return (
      <>
      </>
    )
  }
}

const FobFutureInput = React.memo((props) => {
  const {
    i,
    fobData,
    setFobStruct,
    fobStruct,
    action,
    mutation
  } = props

  const handleChange = (event) => {
    setFobStruct({
      type: action,
      index: i,
      value: event.target.value
    })
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 9 || event.keyCode === 13) {
      setFobStruct({
        type: action,
        index: i,
        value: formatNumber({ format: '0.00', number: event.target.value })
      })
      mutation({
        variables: {
          input: {
            id: fobData.id,
            value: isNaN(parseFloat(fobStruct.fobFutures.main[i].value))
              ? 0
              : parseFloat(fobStruct.fobFutures.main[i].value)
          }
        }
      })
    }
  }

  const handleOnBlur = (event) => {
    setFobStruct({
      type: action,
      index: i,
      value: formatNumber({ format: '0.00', number: event.target.value })
    })
    mutation({
      variables: {
        input: {
          id: fobData.id,
          value: isNaN(parseFloat(fobStruct.fobFutures.main[i].value))
            ? 0
            : parseFloat(fobStruct.fobFutures.main[i].value)
        }
      }
    })
  }

  return (
    <InputBase
      margin='dense'
      inputProps={{ style: { padding: 0, textAlign: 'center' } }}
      value={fobStruct.fobFutures.main[i].value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
    />
  )
})

const FobDataInput = React.memo((props) => {
  const {
    i,
    fobData,
    setFobStruct,
    fobStruct,
    fobKey,
    action,
    mutation
  } = props

  const handleChange = (event) => {
    setFobStruct({
      type: action,
      countryId: fobData.country.id,
      index: i,
      value: event.target.value
    })
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 9 || event.keyCode === 13) {
      setFobStruct({
        type: action,
        countryId: fobData.country.id,
        index: i,
        value: formatNumber({ format: '0.00', number: event.target.value })
      })
      mutation({
        variables: {
          input: {
            id: fobData.id,
            value: isNaN(parseFloat(fobStruct[fobKey][fobData.country.id][i].value))
              ? 0
              : parseFloat(fobStruct[fobKey][fobData.country.id][i].value)
          }
        }
      })
    }
  }

  const handleOnBlur = (event) => {
    setFobStruct({
      type: action,
      countryId: fobData.country.id,
      index: i,
      value: formatNumber({ format: '0.00', number: event.target.value })
    })
    mutation({
      variables: {
        input: {
          id: fobData.id,
          value: isNaN(parseFloat(fobStruct[fobKey][fobData.country.id][i].value))
            ? 0
            : parseFloat(fobStruct[fobKey][fobData.country.id][i].value)
        }
      }
    })
  }

  return (
    <InputBase
      inputProps={{ style: { padding: 0, textAlign: 'center', margin: 0 } }}
      value={fobStruct[fobKey][fobData.country.id][i].value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleOnBlur}
    />
  )
})

const CreateMappingDialog = (props) => {
  const {
    open,
    setOpen,
    commodity,
    client,
    route,
    setOpenSnackBar,
    setContextState
  } = props

  const classes = useStyles()

  // Mutation
  const [createMappingForRoute] = useMutation(CREATE_MAPPING_FOR_ROUTE, {
    refetchQueries: [
      { 
        query: GET_FOBDATA_FROM_CLIENT,
        variables: {
          input: {
            client,
            commodity: commodity.id
          }
        }
      },
      { 
        query: GET_ROUTES_PER_COMMODITY,
        variables: {
          input: {
            pdf: true,
            commodity: commodity.id
          }
        }
      }
    ],
    onCompleted () {
      setOpenSnackBar(true)
    }
  })

  const handleClickContinue = () => {
    createMappingForRoute({
      variables:{
        input: {
          client,
          commodity: commodity.id,
          route
        }
      }
    })
    setOpen(false)
    setContextState({ type: 'close' })
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false)
          setContextState({ type: 'close' })
        }}
        PaperProps={{
          style: {
            borderRadius: '1%'
          }
        }}
      >
        <DialogTitle
          style={{ paddingBottom: '0px'}}
        >
          Do you wish to continue?
        </DialogTitle>
        <DialogContent>
          <Box
            display='flex'
            flexDirection='column'
          >
            <Box display='flex' flexDirection='row' style={{ marginBottom: '15px'}}>
              <WarningIcon style={{ color: 'gray', transform: 'scale(0.65)' }} />
              <Typography style={{ color: '#F17300' }}>
                WARNING: The following action will create new futures for this route only!
              </Typography>
            </Box>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='center'
            >
              <Button
                className={classes.cancelButton}
                style={{ marginRight: '7px'}}
                onClick={() => {
                  setOpen(false)
                  setContextState({ type: 'close' })
                }}
              >
                Cancel
              </Button>
              <Button
                className={classes.continueButton}
                onClick={handleClickContinue}
              >
                Continue
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

ShowClientRoutesCnf.propTypes = {

}

export default ShowClientRoutesCnf
