import { useContext } from 'react'
import PropTypes from 'prop-types'
// Context
import { UserContext } from '../App/AppContext'
// Permissions
import  RolesConstants from '../App/AppContent'
// Utils
import { getRoleValue } from '../Utils/utils'

const VerifyPermissionsHOC = props => {
  const { children, type, moduleName, permission  } = props

  const { user } = useContext(UserContext)
  let currentRole = null
  if (user) {
    currentRole = getRoleValue({ role: user.role })
  }

  if (!currentRole) {
    return null
  }
  const allowed = (((RolesConstants.roles[currentRole] || {})[type] || {})[moduleName] || {})[permission]
  if (!allowed) {
    return  null
  }
  return children
}

VerifyPermissionsHOC.propTypes = {
  moduleName: PropTypes.string.isRequired,
  permission: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default VerifyPermissionsHOC
