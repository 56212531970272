// React
import React from 'react'
// Apollo
import {
  Query
} from 'react-apollo'
// PropTypes
import PropTypes from 'prop-types'
// Utils
import CustomError from '../Utils/CustomError'
import LoadingModal from '../Utils/Loading'
import RootLinearProgress from '../Utils/RootLinearProgress'

const QueryHelper = props => {
  const {
    children,
    fetchPolicy,
    linearProgress,
    noLoading,
    query,
    skip,
    variables,
    ...rest
  } = props

  return (
    <Query
      fetchPolicy={fetchPolicy}
      query={query}
      skip={skip}
      variables={variables ? { ...variables } : {}}
    >
      {(data, result) => {
        if (!skip) {
          if (!noLoading) {
            if (data.loading) {
              if (linearProgress) {
                return <RootLinearProgress open={data.loading} />
              }
              return <LoadingModal open={data.loading} />
            }
            if (data.error) {
              let message = data.error.message.split(':')[1]
              if (message.trim() === 'Response not successful') {
                message = 'La petición no es válida.'
              }
              return <CustomError message={message} />
            }
            if (Object.getOwnPropertyNames(data.data).length === 0 || !data.data) {
              return <CustomError message='Ha ocurrido un error. Intente de nuevo más tarde.' />
            }
          }
        }

        return children(data, result, { props: rest })
      }}
    </Query>
  )
}

QueryHelper.defaultProps = {
  fetchPolicy: 'network-only',
  linearProgress: true,
  noLoading: false,
  skip: false
}

QueryHelper.propTypes = {
  fetchPolicy: PropTypes.string,
  loading: PropTypes.bool,
  query: PropTypes.object.isRequired,
  skip: PropTypes.bool,
  variables: PropTypes.object
}

export default QueryHelper
