import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Helmet
import Helmet from 'react-helmet'

// Context
import { UserContext } from "../App/AppContext";
// Content
import { upsert as upsertContent } from "./CommoditesContent";
// Queries
import QueryHelper from "../Queries/QueryHelper";
import {
  GET_COMMODITY,
  CREATE_COMMODITY_INFORMATION,
} from "../Queries/Queries";

// Mutations
import MutationHelper from "../Mutations/MutationHelper";
import { UPSERT_COMMODITY } from "../Mutations/Mutations";
// Utils
import {
  monthRequired,
  nameRequired,
  stowFactorRequired,
  validateStep,
} from "../Utils/form-validations";
import { useFormInput } from "../Utils/utils";

import { makeStyles } from "@material-ui/core/styles";

import CreateOptionsContainer from "../Utils/CreateOptionsContainer";
import RootBreadCrumb from "../Utils/RootBreadcrumb";
import RootButton from "../Utils/RootButton";
import RootForm from "../Utils/RootForm";
import RootGrid from "../Utils/RootGrid";
import RootPaper from "../Utils/RootPaper";
import RootTextField from "../Utils/RootTextField";
import TextError from "../Utils/TextError";
import RootCheckbox from "../Utils/RootCheckbox";

import Grid from "@material-ui/core/Grid";

const validatejs = require("validate.js");

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "0 auto",
    width: "80%",
    borderRadius: '10px'
  },
  container: {
    width: "95%",
    marginLeft: "5.0%",
  },
  smMg: {
    marginLeft: "2.5%",
  },
  fiveContainer: {
    width: "95%",
    marginLeft: "2.5%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  fiveItem: {
    flexBasis: "auto",
    flexGrow: "1",
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}));

const UpsertCommodityQueryHelper = (props) => {
  const { match, history: _history, location: _location, ...rest } = props;
  let id;
  if (match && match.params) {
    id = match.params.commodity;
  }
  const skip = !id;
  const searchInput = {
    id,
  };
  return (
    <QueryHelper query={CREATE_COMMODITY_INFORMATION}>
      {({ data }) => {
        const { createCommodityInformation } = data;
        if (createCommodityInformation) {
          const { positionDates } = createCommodityInformation;

          const emptyOptions = [];
          if (positionDates.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.positionDate);
          }

          const positionDatesArray = [];
          if (positionDates.length >= 0) {
            positionDates.map((item) => {
              return positionDatesArray.push({
                id: item.id,
                name: item.positionDateName,
              });
            });
          }

          if (emptyOptions.length > 0) {
            return (
              <CreateOptionsContainer
                options={emptyOptions}
                name={upsertContent.module.name}
                positionDates={positionDatesArray}
              />
            );
          }
          return (
            <UpsertCommodity
              searchInput={searchInput}
              positionDates={positionDates}
              skip={skip}
              {...rest}
            />
          );
        }
        return <div />;
      }}
    </QueryHelper>
  );
};

UpsertCommodityQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
};

export default withRouter(UpsertCommodityQueryHelper);

const UpsertCommodity = (props) => {
  const { searchInput, skip, ...rest } = props;

  return (
    <QueryHelper
      query={GET_COMMODITY}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let commodity = null;
        if (data && data.getCommodity && data.getCommodity.commodity) {
          commodity = data.getCommodity.commodity;
          console.log(commodity);
        }
        return (
          <>
            {
              commodity
                ? <Helmet><title>Update Commodity</title></Helmet>
                : <Helmet><title>Create Commodity</title></Helmet>
            }
            <CreateCommodity {...rest} commodity={commodity} />
          </>
        )
      }}
    </QueryHelper>
  );
};

UpsertCommodity.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
};

const CreateCommodity = (props) => {
  const { commodity, positionDates, refetchQueries } = props;
  const content = commodity ? upsertContent.update : upsertContent.create;
  const [editRouteResults, setEditRouteResults] = useState(false);
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const validation = {
    commodityName: nameRequired,
    stowFactor: stowFactorRequired,
  };

  const forwardArrayValidation = {
    month: monthRequired,
  };

  // TODO fix this thing pls :D
  // const meanwhilefunc = (params = {}) => {
  //   const {
  //     forwardArray
  //   } = params
  //   return forwardArray
  // }
  const initialValues = {
    cargo: commodity ? commodity.cargo : false,
    commodityName: commodity ? commodity.commodityName : "",
    stowFactor: commodity ? commodity.stowFactor : "",
    forwardArray: commodity
      ? commodity.forwardArray
      : Array.from(Array(14), () => 0),
  };

  const form = {
    commodityName: useFormInput({
      initialValue: initialValues.commodityName,
      label: "Commodity Name",
      required: true,
    }),
    stowFactor: useFormInput({
      initialValue: initialValues.stowFactor,
      label: "stowFactor",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
  };

  const [cargo, setCargo] = useState(initialValues.cargo);
  const [forwardArray, setForwardArray] = useState(
    initialValues.forwardArray.map((forward) => {
      return {
        value: forward,
        valid: forward !== null,
        errorMessage: "",
      };
    })
  );
  const input = {
    ...(commodity && { id: commodity.id }),
    cargo,
    commodityName: form.commodityName.getValue(),
    stowFactor: form.stowFactor.getValue(),
    forwardArray: forwardArray.map((forward) => forward.value),
    editRouteResults,
  };

  const next = ({ mutation }) => {
    forwardArray.reduce((valid, currentObj, index) => {
      if (index === 1) {
        return valid.valid && currentObj.valid;
      } else {
        return valid && currentObj.valid;
      }
    }) &&
      validateStep({
        form,
        next: () => mutation(),
        validation,
      });
  };
  return (
    <MutationHelper
      mutation={UPSERT_COMMODITY}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted })}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.paper} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb
                current={content.current}
                links={content.breadCrumbs}
              />

              <Grid container direction="row">
                <Grid
                  container
                  item
                  md={6}
                  alignContent="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} className={classes.smMg}>
                    <RootForm form={form} validation={validation} marginBottom={false}/>
                  </Grid>
                  <Grid item xs={12} className={classes.container}>
                    <RootCheckbox
                      label="Cargo"
                      onChange={(checked) => {
                        setEditRouteResults(true);
                        setCargo(checked);
                      }}
                      checked={cargo}
                      value={cargo}
                    />
                  </Grid>
                </Grid>
                <Grid container item md={6}>
                  <ForwardArrayInputs
                    positionDates={positionDates}
                    setForwardArray={setForwardArray}
                    forwardArray={forwardArray}
                    setEditRouteResults={setEditRouteResults}
                    validation={forwardArrayValidation}
                  />
                </Grid>
              </Grid>

              <RootButton
                className={classes.button}
                disabled={result.loading || user.disabled}
                justifyContent="center"
                onClick={() => next({ mutation })}
              >
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        );
      }}
    </MutationHelper>
  );
};

CreateCommodity.propTypes = {
  commodity: PropTypes.object,
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
};

const ForwardArrayInputs = (props) => {
  const {
    positionDates,
    setForwardArray,
    forwardArray,
    setEditRouteResults,
    validation,
  } = props;

  // if (forwardArray.length !== positionDates.length) {
  //   return null
  // }

  return positionDates.map((weather, index) => {
    return (
      <>
        <RootTextField
          smallInput
          key={index}
          label={positionDates[index].positionDateName}
          onChange={(e) => {
            setEditRouteResults(true);
            const newForwardArray = forwardArray;
            newForwardArray[index] = parseFloat(e.target.value);
            setForwardArray((prevState) =>
              prevState.map((value, i) => {
                if (i === index) {
                  const validationResult = validatejs(
                    {
                      month: parseFloat(e.target.value),
                    },
                    validation
                  );
                  return {
                    value: parseFloat(e.target.value),
                    valid: !validationResult,
                    errorMessage: validationResult
                      ? validationResult.month[0]
                      : null,
                  };
                } else {
                  return value;
                }
              })
            );
          }}
          type="number"
          value={parseFloat(forwardArray[index].value)}
        />
        {forwardArray[index]?.errorMessage && (
          <TextError error={forwardArray[index].errorMessage} />
        )}
      </>
    );
  });
};

ForwardArrayInputs.propTypes = {
  setForwardArray: PropTypes.func.isRequired,
  forwardArray: PropTypes.array.isRequired,
};
