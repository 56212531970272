import React, { Fragment, useState, useContext } from 'react'
import PropTypes from 'prop-types'
// Context
import { UserContext } from '../App/AppContext'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPDATE_CLIENT_RESULTS } from '../Mutations/Mutations'
// Utils
import { useFormInput } from '../Utils/utils'
import RootButton from '../Utils/RootButton'
import RootPaper from '../Utils/RootPaper'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import TuneIcon from '@material-ui/icons/Tune'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { GET_CLIENTS } from '../Queries/Queries'

const OptionSwitch = withStyles((theme) => ({
  // root: {
  //   width: 28,
  //   height: 16,
  //   padding: 0,
  //   display: 'flex'
  // },
  // switchBase: {
  //   padding: 2,
  //   color: theme.palette.grey[500],
  //   '&$checked': {
  //     transform: 'translateX(12px)',
  //     color: theme.palette.common.white,
  //     '& + $track': {
  //       opacity: 1,
  //       backgroundColor: theme.palette.primary.main,
  //       borderColor: theme.palette.primary.main
  //     }
  //   }
  // },
  // thumb: {
  //   width: 12,
  //   height: 12,
  //   boxShadow: 'none'
  // },
  // track: {
  //   border: `1px solid ${theme.palette.grey[500]}`,
  //   borderRadius: 16 / 2,
  //   opacity: 1,
  //   backgroundColor: theme.palette.common.white
  // },
  // checked: {}
}))(Switch)

const useStyles = makeStyles(theme => ({
  tuneIcon: {
    margin: theme.spacing(1),
    width: 150
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: 300,
    maxHeight: 620,
  },
  textAlign: {
    textAlign: 'center'
  }
}))

export const TableSettingsModal = props => {
  const { saveSettings, gridColumnApi } = props


  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  if (!gridColumnApi) {
    return null
  }

  const settings = {
    columns: gridColumnApi.getColumnState()
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        aria-label='Table Settings'
        onClick={handleOpen}
        className={classes.tuneIcon}
        startIcon={<TuneIcon />}
      > Table Settings
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="table-settings"
        aria-describedby="table-settings"
        className={classes.modal}
      >
        <TableSettings
          classes={classes}
          saveSettings={saveSettings}
          settings={settings}
          handleClose={handleClose}
        />
      </Modal>
    </>
  )
}

TableSettingsModal.prototypes = {
  saveSettings: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    columns: PropTypes.array.isRequired,
  }).isRequired,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'update-hide': {
      const columns = state.columns.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            hide: action.checked
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        columns
      }
    }
    default:
      return {
        ...state
      }

  }
}

export const TableSettings = props => {
  const { classes, settings, saveSettings } = props


  const [settingsData, setSettingsData] = React.useReducer(reducer, settings)

  let clientResultsInput = {}
  const input = {
  }

  const refetchQueries = []

  const onCompleted = () => {
    // refetch()
  }

  console.log('\x1b[32m%s\x1b[0m', '=================================================')
  console.log(settingsData.columns,)
  console.log('\x1b[32m%s\x1b[0m', '=================================================')

  const onSubmit = () => {
    saveSettings(settingsData)
  }
  return (
    // <MutationHelper
    //   mutation={UPDATE_CLIENT_RESULTS}
    //   onCompleted={onCompleted}
    //   variables={{ input }}
    //   // refetchQueries={refetchQueries}
    //   awaitRefetchQueries={true}
    // >
    //   {(mutation, result) => {
    //     const onSubmit = () => {
    //       mutation()
    //       handleClose()
    //     }
    //     return (
    <RootPaper className={classes.paper} smallForm>
      <Typography className={classes.textAlign} variant='body1'>Settings</Typography>
      <Grid container justify='space-around'>
        {
          settingsData.columns.map((column, i) => {
            const handleChange = e => {
              const checked = !e.target.checked
              setSettingsData({
                checked,
                index: i,
                type: 'update-hide'
              })
            }
            return (
              <Fragment key={i}>
                <Grid item xs={6} >
                  <Typography>{column.colId}</Typography>
                </Grid>
                <Grid justify='flex-end' container item xs={6} >
                  <OptionSwitch
                    checked={column.hide ? false : true}
                    onChange={handleChange}
                    name={column.colId}
                  />
                </Grid>
              </Fragment>
            )
          })
        }
      </Grid>
      <RootButton
        onClick={onSubmit}
        justifyContent='center'
      >
        Update
            </RootButton>
    </RootPaper>
    //     )
    //   }}
    // </MutationHelper>
  )

}


export default TableSettingsModal