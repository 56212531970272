import React from 'react'
import { AUTH_TOKEN } from '../../constants'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({
  component: Component,
  ...rest
}) => {
  const authToken = localStorage.getItem(AUTH_TOKEN)

  return (
    <Route {...rest} render={props => (
      authToken ? (
        <Component {...props} {...rest}/>
      ) : (
        <Redirect to ={{
          pathname: '/login',
          state: {from: props.location}
        }}/>
      )
    )}/>
  )
}

export default PrivateRoute