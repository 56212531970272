import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { useSearchTextInputStyles } from './SearchTextInputStyles'
import NumberFormatCustom from './NumberFormatCustom'

const CustomTextField = ({
  label,
  autoFocus,
  placeholder,
  value,
  onChange,
  field,
  type = 'text',
  allowedDecimalSeparators = ['.'],
  iconButton = null,
  ...rest
}) => {
  const classes = useSearchTextInputStyles()

  let defaultValue = ''
  if (value || value === 0) {
    defaultValue = value
  }

  return (
    <Paper component='form' className={classes.customRoot} elevation={0}>
      {iconButton && (
        <>
          <IconButton
            disabled
            className={classes.iconButton}
            aria-label={label}
          >
            {iconButton}
          </IconButton>
          <Divider className={classes.divider} orientation='vertical' />
        </>
      )}
      {label && (
        <>
          <Typography className={classes.typography} variant='overline'>
            {label}
          </Typography>
          <Divider className={classes.divider} orientation='vertical' />
        </>
      )}

      <InputBase
        autoFocus={autoFocus}
        name={field}
        type={type === 'number' || type === 'select' ? 'text' : type}
        {...(type === 'number' && {
          inputComponent: NumberFormatCustom,
          inputProps: allowedDecimalSeparators
        })}
        className={classes.input}
        placeholder={placeholder}
        value={defaultValue}
        label={label}
        onChange={(event) => onChange(event)}
        {...rest}
      />
    </Paper>
  )
}
CustomTextField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  onClear: PropTypes.func,
  children: PropTypes.object,
  field: PropTypes.string,
  allowedDecimalSeparators: PropTypes.array,
  type: PropTypes.string,
  label: PropTypes.string,
  autoFocus: PropTypes.bool,
  iconButton: PropTypes.object
}

export default CustomTextField
