import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// Context
import { UserContext } from '../App/AppContext'
// Helmet
import Helmet from 'react-helmet'
// Content
import { upsert as upsertContent } from './TimeCountingsContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_TIMECOUNTING } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_TIMECOUNTING } from '../Mutations/Mutations'
// Utils
import {
  abbreviationRequired,
  multiplierRequired,
  nameRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'

// Material UI
import { makeStyles } from '@material-ui/core/styles'

const UpsertTimeCounting = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.timeCounting
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={GET_TIMECOUNTING}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let timeCounting = null
        if (data && data.getTimeCounting && data.getTimeCounting.timeCounting) {
          timeCounting = data.getTimeCounting.timeCounting
        }
        return (
          <>
            {
              timeCounting
                ? <Helmet><title>Update Time Counting</title></Helmet>
                : <Helmet><title>Create Time Counting</title></Helmet>
            }
            <CreateTimeCounting
              {...rest}
              timeCounting={timeCounting}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

const UpsertTimeCountingWithRouter = withRouter(UpsertTimeCounting)

UpsertTimeCountingWithRouter.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

export default UpsertTimeCountingWithRouter

const CreateTimeCounting = props => {
  const { refetchQueries, timeCounting } = props
  const { user } = useContext(UserContext)

  const classes = useStyles()

  const content = timeCounting ? upsertContent.update : upsertContent.create
  const [editRouteResults, setEditRouteResults] = useState(false)

  const validation = {
    timeCountingName: nameRequired,
    abbrev: abbreviationRequired,
    multiplier: multiplierRequired
  }

  const initialValues = {
    abbrev: timeCounting ? timeCounting.abbrev : '',
    multiplier: timeCounting ? timeCounting.multiplier : '',
    timeCountingName: timeCounting ? timeCounting.timeCountingName : ''
  }

  const form = {
    timeCountingName: useFormInput({ initialValue: initialValues.timeCountingName, label: 'timeCoutingName', required: true }),
    abbrev: useFormInput({ initialValue: initialValues.abbrev, label: 'abbrev', required: true }),
    multiplier: useFormInput({ initialValue: initialValues.multiplier, label: 'multiplier', required: true, type: 'number', isCalc: true, setEditRouteResults })
  }

  const input = {
    ...(timeCounting && { id: timeCounting.id }),
    abbrev: form.abbrev.getValue(),
    multiplier: parseFloat(form.multiplier.getValue()),
    timeCountingName: form.timeCountingName.getValue(),
    editRouteResults
  }

  const next = ({ mutation }) => {
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_TIMECOUNTING}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.paper} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <RootForm
                form={form}
                validation={validation}
              />
              <RootButton className={classes.button} disabled={result.loading || user.disabled} justifyContent='center' onClick={() => next({ mutation })}>
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateTimeCounting.defaultProps = {
  refetchQueries: []
}

CreateTimeCounting.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
  timeCounting: PropTypes.object
}
