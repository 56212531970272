// React
import React, { Fragment, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
// Context
import { UserContext } from '../App/AppContext'
// Material UI
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.common.black}5`
  },
  container: {
    flexBasis: '90%'
  }
}))

const itemStyles = makeStyles(theme => ({
  listTypography: {
    fontSize: theme.typography.pxToRem(14)
  },
  activeLink: {
    borderLeft: `4px solid ${theme.palette.primary.main}`
  },
  divider: {
    height: '4px'
  }
}))

const Item = props => {
  const { user, setUser } = useContext(UserContext)
  const classes = itemStyles()
  const { activeLinks = [], closeDrawer, current, index, link, setCurrentSection, text } = props
  return (
    <>
      {index !== 5
        ? <ListItem
          button
          component={Link}
          to={link}
          key={text}
          onClick={() => {
            if (link.includes('http')) {
              window.open(link, '_blank');
              closeDrawer();
              return;
            }

            setCurrentSection(text)
            closeDrawer()
          }}
          className={clsx({
            [classes.activeLink]: activeLinks.includes(`/${current.split('/')[1]}`)
          })}
          >
          <ListItemText
            className={clsx(classes.listTypography)}
            primary={text}
            disableTypography
          />
          </ListItem>
        : <>
          <ListItem
            button
            component={Link}
            to={link}
            key={text}
            onClick={() => {
              setCurrentSection(text)
              closeDrawer()
            }}
            className={clsx({
              [classes.activeLink]: activeLinks.includes(`/${current.split('/')[1]}`)
            })}
          >
            <ListItemText
              className={clsx(classes.listTypography)}
              primary={text}
              disableTypography
            />
          </ListItem>

          </>}
      {index === 2 && <Divider className={classes.divider} />}
    </>
  )
}

Item.propTypes = {
  activeLinks: PropTypes.array.isRequired,
  current: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

const Menu = props => {
  const classes = useStyles()
  const { items, ...rest } = props
  const current = props.history.location.pathname
  return (
    <List disablePadding className={clsx(classes.container)}>
      {items.map((item, index) => {
        return <Item {...item} current={current} index={index} key={index} {...rest} />
      })}
    </List>
  )
}

Menu.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string,
      text: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  setCurrentSection: PropTypes.func.isRequired
}

export default withRouter(Menu)
