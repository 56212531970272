import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
// Material UI
import Link from '@material-ui/core/Link'
// Styles
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer'
  },
  errorClass: {
    cursor: 'pointer',
    color: 'red'
  }
}))

const RootColumnLink = props => {
  const { value, viewLink, row } = props
  const classes = useStyles()
  return (
    <Link
      className={(row.error) ? classes.errorClass : classes.link}
      onClick={() => props.history.push(viewLink)}
    >
      {value}
    </Link>
  )
}

RootColumnLink.propTypes = {
  value: PropTypes.string.isRequired,
  viewLink: PropTypes.string.isRequired
}

export default withRouter(RootColumnLink)
