import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useReducer,
  useRef
} from 'react'
import { withRouter } from 'react-router-dom'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
// Helmet
import Helmet from 'react-helmet'
// Context
import { UserContext } from '../App/AppContext'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import {
  GET_SIMPLE_ROUTES,
  GET_CARGO_COMMODITIES,
  GET_SIMPLE_VESSELS,
  GET_CALCULATION_ROUTE_INFO,
  GET_PORTS
} from '../Queries/Queries'
// Mutation
import MutationHelper from '../Mutations/MutationHelper'
import { UPDATE_ROUTE_RESULT } from '../Mutations/Mutations'

// Styles
// import clsx from 'clsx'
// Material UI
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import Checkbox from '@material-ui/core/Checkbox'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Clsx
import clsx from 'clsx'

// import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
// import InputBase from '@material-ui/core/InputBase'
// import RootSelect from '../Utils/RootSelect'
import RootButton from '../Utils/RootButton'
import CustomAutocomplete from '../Utils/CustomAutocomplete'
// import Select from '@material-ui/core/Select'
// import MenuItem from '@material-ui/core/MenuItem'
// import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'

// Utils
import EditableInputBase from '../Utils/EditableInputBase'
import LoadingModal from '../Utils/Loading'
// import RootGrid from '../Utils/RootGrid'
import VesselContainer from './VesselContainer'
import { formatNumber } from '../Utils/utils'
import { Box, Button, Tooltip } from '@material-ui/core'

import RootAutocomplete from '../Utils/RootAutocomplete'

import TrieSearch from 'trie-search'
import { ja } from 'date-fns/locale'
import { InfoOutlined } from '@material-ui/icons'

const mobile = require('is-mobile')
const isMobile = mobile({ tablet: true, featureDetect: true })

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)
// const BootstrapInput = withStyles(theme => ({
//   root: {
//     'label + &': {
//       marginTop: theme.spacing(3),
//     },
//   },
//   input: {
//     borderRadius: 4,
//     position: 'relative',
//     backgroundColor: theme.palette.background.paper,
//     border: '1px solid #ced4da',
//     fontSize: theme.typography.generalFontSize,
//     padding: '10px 26px 10px 12px',
//     transition: theme.transitions.create(['border-color', 'box-shadow']),
//     // Use the system font instead of the default Roboto font.
//     fontFamily: [
//       '-apple-system',
//       'BlinkMacSystemFont',
//       '"Segoe UI"',
//       'Roboto',
//       '"Helvetica Neue"',
//       'Arial',
//       'sans-serif',
//       '"Apple Color Emoji"',
//       '"Segoe UI Emoji"',
//       '"Segoe UI Symbol"',
//     ].join(','),
//     '&:focus': {
//       borderRadius: 4,
//       borderColor: '#80bdff',
//       boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//     },
//   },
// }))(InputBase)

const fixedClasses = makeStyles((theme) => ({
  fixed50: {
    minWidth: 50,
    maxWidth: 50
  },
  fixed60: {
    minWidth: 60,
    maxWidth: 60
  },
  fixed70: {
    minWidth: 70,
    maxWidth: 70
  },
  fixed80: {
    minWidth: 80,
    maxWidth: 80
  },
  fixed90: {
    minWidth: 90,
    maxWidth: 90
  },
  fixed100: {
    minWidth: 100,
    maxWidth: 100
  }
}))

const titleTypogrphyStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.generalFontSize + 2
  },
  posDate: {
    fontSize: theme.typography.generalFontSize + 2
  }
}))

const TitleTypography = (props) => {
  const { children, ...rest } = props
  const classes = titleTypogrphyStyles()
  return (
    <Typography className={classes.root} {...rest}>
      {children}
    </Typography>
  )
}

// let defaultPort = {
//   name: 'default',
//   bunker: false,
//   terms: '',
//   timeCounting: ''
// }

const defaultVessel = {
  vessel: '',
  checked0: false,
  checked1: false,
  checked2: false,
  prevCheck: 0,
  customDelivery: {
    index: '',
    id: ''
  },
  customBallast: {
    index: '',
    id: ''
  },
  dop: {
    dailyHire: 0,
    bunkeringCost: 0,
    result: 0
  },
  aps: {
    dailyHire: 0,
    bunkeringCost: 0,
    result: 0
  },
  results: {
    intake: 0,
    result: 0
  }
}
// TODO check this one
const complexIntakeResultsMapper = (items, key, value, index) => {
  const result = items.map((item, i) => {
    if (index === i) {
      for (let j = 0; j < item.portAccurateDistance.loadPort.length; j++) {
        item.portAccurateDistance.loadPort.modifiedIntake = false
      }
      for (let j = 0; j < item.portAccurateDistance.dischargePort.length; j++) {
        item.portAccurateDistance.dischargePort.modifiedIntake = false
      }
      return {
        ...item,
        intake: {
          ...item.intake,
          [key]: parseFloat(value)
        }
      }
    } else {
      return item
    }
  })
  return result
}
// const resultsMapper = (items, key, value, index) => {
//   let result = items.map((item, index) => {
//     if (index === index) {
//       return {
//         ...item,
//         [key]: parseFloat(value)
//       }
//     } else {
//       return item
//     }
//   })
//   return result
// }
const getPortInfo = (port, ports) => {
  for (let i = 0; i < ports.length; i++) {
    if (ports[i].id === port) {
      return `${ports[i].portName} - ${ports[i].berth}` // {...ports[i]}
    }
  }
}
const vesselsApsMapper = (vessels, isVessel, key, value) => {
  const newVessels = { ...vessels }
  const newAps = { ...vessels[isVessel].aps }
  newAps[key] = value ? parseFloat(value) : 0.0
  newVessels[isVessel] = {
    ...newVessels[isVessel],
    aps: {
      ...newAps
    }
  }
  return newVessels
}

const vesselsMapper = (vessels, isVessel, key, value) => {
  const newVessels = { ...vessels }
  const newDop = { ...vessels[isVessel].dop }
  newDop[key] = parseFloat(value)
  newVessels[isVessel] = {
    ...newVessels[isVessel],
    dop: {
      ...newDop
    }
  }
  return newVessels
}

const vesselsResultsMapper = (vessels, isVessel, value, index) => {
  const newDop = { ...vessels[isVessel].dop }
  newDop.result = parseFloat(value.dopUpdatedCalc[index])
  const newAps = { ...vessels[isVessel].aps }
  newAps.result = parseFloat(value.apsUpdatedCalc[index])
  return {
    dop: {
      ...newDop
    },
    aps: {
      ...newAps
    }
  }
}

const changeDeliveryPort = (vessels, value, id, isVessel) => {
  const newVessels = { ...vessels }
  newVessels[isVessel] = {
    ...newVessels[isVessel],
    customDelivery: {
      index: value,
      id: id
    }
  }

  return newVessels
}
const changeBallastPort = (vessels, value, id, isVessel) => {
  const newVessels = { ...vessels }
  newVessels[isVessel] = {
    ...newVessels[isVessel],
    customBallast: {
      index: value,
      id: id
    }
  }
  return newVessels
}

const correctParseFloat = (string) => {
  const toNumber = parseFloat(string.replace(',', '.'))
  if (isNaN(toNumber) || toNumber === null) {
    return 0
  } else {
    return toNumber
  }
}

const reducer = (state, action) => {
  let results = []
  let newResults = []
  let port = ''
  switch (action.type) {
    // TODO change vessel, how? with [vesselTarget]
    case 'customDelivery': {
      action.updateCheckFn()

      return {
        ...state,
        ...(action.isVessel === 'vessel1'
          ? {
            vessels: {
              ...changeDeliveryPort(
                state.vessels,
                action.value,
                action.id,
                action.isVessel
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            vessels: {
              ...changeDeliveryPort(
                state.vessels,
                action.value,
                action.id,
                action.isVessel
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            vessels: {
              ...changeDeliveryPort(
                state.vessels,
                action.value,
                action.id,
                action.isVessel
              )
            }
					  }
          : ''),
        target: 'speed'
      }
    }
    case 'customBallast': {
      const newBallastCargo = { ...state.cargoPorts }
      if (newBallastCargo.length === 0) {
        newBallastCargo.viaBallast.push({ name: 'new Ballast', bunker: false })
      } else {
        newBallastCargo.viaBallast[0] = { name: 'new Ballast', bunker: false }
      }
      action.updateCheckFn()
      return {
        ...state,
        cargoPorts: newBallastCargo,
        ...(action.isVessel === 'vessel1'
          ? {
            vessels: {
              ...changeBallastPort(
                state.vessels,
                action.value,
                action.id,
                action.isVessel
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            vessels: {
              ...changeBallastPort(
                state.vessels,
                action.value,
                action.id,
                action.isVessel
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            vessels: {
              ...changeBallastPort(
                state.vessels,
                action.value,
                action.id,
                action.isVessel
              )
            }
					  }
          : ''),
        target: 'speed'
      }
    }
    case 'apsDailyHire': {
      // state.vessels[action.isVessel].dop.dailyHire = action.value

      return {
        ...state,
        target: 'aps',
        ...(action.isVessel === 'vessel1'
          ? {
            vessels: {
              ...vesselsApsMapper(
                state.vessels,
                action.isVessel,
                'dailyHire',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            vessels: {
              ...vesselsApsMapper(
                state.vessels,
                action.isVessel,
                'dailyHire',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            vessels: {
              ...vesselsApsMapper(
                state.vessels,
                action.isVessel,
                'dailyHire',
                action.value
              )
            }
					  }
          : '')
      }
    }
    case 'apsBunkeringCost': {
      // state.vessels[action.isVessel].dop.dailyHire = action.value

      return {
        ...state,
        target: 'aps',
        ...(action.isVessel === 'vessel1'
          ? {
            vessels: {
              ...vesselsApsMapper(
                state.vessels,
                action.isVessel,
                'bunkeringCost',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            vessels: {
              ...vesselsApsMapper(
                state.vessels,
                action.isVessel,
                'bunkeringCost',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            vessels: {
              ...vesselsApsMapper(
                state.vessels,
                action.isVessel,
                'bunkeringCost',
                action.value
              )
            }
					  }
          : '')
      }
    }
    case 'dop': {
      // state.vessels[action.isVessel].dop.dailyHire = action.value

      return {
        ...state,
        ...(action.isVessel === 'vessel1'
          ? {
            vessels: {
              ...vesselsMapper(
                state.vessels,
                action.isVessel,
                'dailyHire',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            vessels: {
              ...vesselsMapper(
                state.vessels,
                action.isVessel,
                'dailyHire',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            vessels: {
              ...vesselsMapper(
                state.vessels,
                action.isVessel,
                'dailyHire',
                action.value
              )
            }
					  }
          : ''),
        target: 'dop'
      }
    }

    case 'dopBunkeringCost': {
      return {
        ...state,
        target: 'dop',
        ...(action.isVessel === 'vessel1'
          ? {
            vessels: {
              ...vesselsMapper(
                state.vessels,
                action.isVessel,
                'bunkeringCost',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            vessels: {
              ...vesselsMapper(
                state.vessels,
                action.isVessel,
                'bunkeringCost',
                action.value
              )
            }
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            vessels: {
              ...vesselsMapper(
                state.vessels,
                action.isVessel,
                'bunkeringCost',
                action.value
              )
            }
					  }
          : '')
      }
    }

    case 'adcom': {
      return {
        ...state,
        adcom: action.value,
        target: 'adcom'
      }
    }
    case 'ballastSpeed': {
      const newVesselArray = { ...state.vessels }
      newVesselArray[action.vessel].vessel.ballastSpeedArray[action.speed] = action.value
      const newCheckedVesselArray = newVesselArray
      if (action.speed === 0) {
        newCheckedVesselArray[action.vessel].checked0 = true
      } else {
        newCheckedVesselArray[action.vessel].checked0 = false
      }
      if (action.speed === 1) {
        newCheckedVesselArray[action.vessel].checked1 = true
      } else {
        newCheckedVesselArray[action.vessel].checked1 = false
      }
      if (action.speed === 2) {
        newCheckedVesselArray[action.vessel].checked2 = true
      } else {
        newCheckedVesselArray[action.vessel].checked2 = false
      }

      return {
        ...state,
        vessels: {
          ...newCheckedVesselArray
        },
        target: 'speed',
        targetVessel: action.vessel
      }
    }

    case 'ballastIfo': {
      const newVesselArray = { ...state.vessels }
      newVesselArray[action.vessel].vessel.ballastIFOConsumption[action.speed] = action.value
      const newCheckedVesselArray = newVesselArray
      if (action.speed === 0) {
        newCheckedVesselArray[action.vessel].checked0 = true
      } else {
        newCheckedVesselArray[action.vessel].checked0 = false
      }
      if (action.speed === 1) {
        newCheckedVesselArray[action.vessel].checked1 = true
      } else {
        newCheckedVesselArray[action.vessel].checked1 = false
      }
      if (action.speed === 2) {
        newCheckedVesselArray[action.vessel].checked2 = true
      } else {
        newCheckedVesselArray[action.vessel].checked2 = false
      }

      return {
        ...state,
        vessels: {
          ...newCheckedVesselArray
        },
        target: 'speed',
        targetVessel: action.vessel
      }
    }

    case 'ballastMgo': {
      const newVesselArray = { ...state.vessels }
      newVesselArray[action.vessel].vessel.ballastMGOConsumption[action.speed] = action.value
      const newCheckedVesselArray = newVesselArray
      if (action.speed === 0) {
        newCheckedVesselArray[action.vessel].checked0 = true
      } else {
        newCheckedVesselArray[action.vessel].checked0 = false
      }
      if (action.speed === 1) {
        newCheckedVesselArray[action.vessel].checked1 = true
      } else {
        newCheckedVesselArray[action.vessel].checked1 = false
      }
      if (action.speed === 2) {
        newCheckedVesselArray[action.vessel].checked2 = true
      } else {
        newCheckedVesselArray[action.vessel].checked2 = false
      }

      return {
        ...state,
        vessels: {
          ...newCheckedVesselArray
        },
        target: 'speed',
        targetVessel: action.vessel
      }
    }

    case 'ladenSpeed': {
      const newVesselArray = { ...state.vessels }
      newVesselArray[action.vessel].vessel.ladenSpeedArray[action.speed] = action.value
      const newCheckedVesselArray = newVesselArray
      if (action.speed === 0) {
        newCheckedVesselArray[action.vessel].checked0 = true
      } else {
        newCheckedVesselArray[action.vessel].checked0 = false
      }
      if (action.speed === 1) {
        newCheckedVesselArray[action.vessel].checked1 = true
      } else {
        newCheckedVesselArray[action.vessel].checked1 = false
      }
      if (action.speed === 2) {
        newCheckedVesselArray[action.vessel].checked2 = true
      } else {
        newCheckedVesselArray[action.vessel].checked2 = false
      }
      return {
        ...state,
        vessels: {
          ...newCheckedVesselArray
        },
        target: 'speed',
        targetVessel: action.vessel
      }
    }
    case 'ladenIfo': {
      const newVesselArray = { ...state.vessels }
      newVesselArray[action.vessel].vessel.ladenIFOConsumption[action.speed] = action.value
      if (action.speed === 0) {
        newVesselArray[action.vessel].checked0 = true
      } else {
        newVesselArray[action.vessel].checked0 = false
      }
      if (action.speed === 1) {
        newVesselArray[action.vessel].checked1 = true
      } else {
        newVesselArray[action.vessel].checked1 = false
      }
      if (action.speed === 2) {
        newVesselArray[action.vessel].checked2 = true
      } else {
        newVesselArray[action.vessel].checked2 = false
      }
      return {
        ...state,
        vessels: {
          ...newVesselArray
        },
        target: 'speed',
        targetVessel: action.vessel
      }
    }
    case 'ladenMgo': {
      const newVesselArray = { ...state.vessels }
      newVesselArray[action.vessel].vessel.ladenMGOConsumption[action.speed] = action.value
      if (action.speed === 0) {
        newVesselArray[action.vessel].checked0 = true
      } else {
        newVesselArray[action.vessel].checked0 = false
      }
      if (action.speed === 1) {
        newVesselArray[action.vessel].checked1 = true
      } else {
        newVesselArray[action.vessel].checked1 = false
      }
      if (action.speed === 2) {
        newVesselArray[action.vessel].checked2 = true
      } else {
        newVesselArray[action.vessel].checked2 = false
      }
      return {
        ...state,
        vessels: {
          ...newVesselArray
        },
        target: 'speed',
        targetVessel: action.vessel
      }
    }
    case 'stem': {
      return {
        ...state,
        cargo: {
          ...state.cargo,
          stem: parseFloat(action.value)
        },
        target: action.type
      }
    }
    case 'setVessel': {
      const getArrayValues = (array) => {
        const result = []
        for (let index = 0; index < array.length; index++) {
          result.push(array[index])
        }
        return result
      }
      const newVesselArray = state.vessels
      newVesselArray[action.targetVessel].vessel = {
        ...action.value,
        ballastSpeedArray: getArrayValues(action.value.ballastSpeedArray),
        ballastIFOConsumption: getArrayValues(
          action.value.ballastIFOConsumption
        ),
        ladenSpeedArray: getArrayValues(action.value.ladenSpeedArray),
        ladenIFOConsumption: getArrayValues(action.value.ladenIFOConsumption)
      }

      const checked = 'checked0'
      if (checked === 'checked0') {
        newVesselArray[action.targetVessel].checked0 = true
      } else {
        newVesselArray[action.targetVessel].checked0 = false
      }
      if (checked === 'checked1') {
        newVesselArray[action.targetVessel].checked1 = true
      } else {
        newVesselArray[action.targetVessel].checked1 = false
      }
      if (checked === 'checked2') {
        newVesselArray[action.targetVessel].checked2 = true
      } else {
        newVesselArray[action.targetVessel].checked2 = false
      }
      action.calculation({
        variables: {
          input: {
            data: {
              ...state,
              target: 'speed',
              vessels: newVesselArray,
              targetVessel: action.targetVessel,
              selectedResult: action.selectedResult
            }
          }
        }
      })
      return {
        ...state,
        target: 'speed',
        vessels: newVesselArray,
        targetVessel: action.targetVessel
      }
    }
    case 'tolerance':
      return {
        ...state,
        cargo: {
          ...state.cargo,
          tolerance: parseFloat(action.value)
        },
        target: action.type
      }
    case 'commission':
      return {
        ...state,
        cargo: {
          ...state.cargo,
          commission: parseFloat(action.value)
        },
        target: action.type
      }
    case 'miscCost':
      return {
        ...state,
        cargo: {
          ...state.cargo,
          miscCost: parseFloat(action.value)
        },
        target: action.type
      }
    case 'commodity': {
      let newCommodity
      for (let i = 0; i < action.commodities.length; i++) {
        if (action.commodities[i].id === action.value) {
          newCommodity = { ...action.commodities[i] }
        }
      }
      action.updateCheckFn()
      return {
        ...state,
        commodity: newCommodity,
        target: 'commodity'
      }
    }
    case 'bunker': {
      if (action.item === 'Delivery') {
        port = 'deliveryPort'
      } else if (action.item === 'Ballast') {
        port = 'viaBallast'
      } else if (action.item === 'Load') {
        port = 'loadPort'
      } else if (action.item === 'Laden') {
        port = 'viaLaden'
      } else if (action.item === 'Discharge') {
        port = 'dischargePort'
      } else if (action.item === 'ViaRedelivery') {
        port = 'viaRedelivery'
      } else if (action.item === 'Redelivery') {
        port = 'redelivery'
      }

      const newBunkerArray = state.cargoPorts
      newBunkerArray[port][action.index].bunker = action.value

      if (action.isVessel === 'vessel1') {
        newResults = state.results
      } else if (action.isVessel === 'vessel2') {
        newResults = state.results2
      } else {
        newResults = state.results3
      }

      for (let i = 0; i < newResults.length; i++) {
        newResults[i].portAccurateDistance[port][action.index].bunker =
					action.value
      }

      action.updateCheckFn()

      return {
        ...state,
        cargoPorts: newBunkerArray,
        target: action.type,
        ...(action.isVessel === 'vessel1'
          ? {
            results: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            results2: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            results3: newResults
					  }
          : '')
      }
    }
    case 'customIntake': {
      const info = { ...state }
      const allResults = ['results', 'results2', 'results3']
      for (const result of allResults) {
        for (let j = 0; j < info[result].length; j++) {
          if (
            info[result][j].portAccurateDistance[action.port][
              action.index
            ].customIntake !== parseFloat(action.value)
          ) {
            info[result][j].portAccurateDistance[action.port][
              action.index
            ].modifiedIntake = true
            info[result][j].portAccurateDistance[action.port][
              action.index
            ].customIntake = parseFloat(action.value)
          }
          if (
            info[result][j].portAccurateDistance[action.port][
              action.index
            ].customIntake === 0
          ) {
            info[result][j].portAccurateDistance[action.port][
              action.index
            ].modifiedIntake = false
          }
        }
      }
      info.target = action.type

      return {
        ...info
      }
    }
    case 'terms': {
      const info = { ...state }
      const allResults = ['results', 'results2', 'results3']

      for (const result of allResults) {
        if (info[result].length > 0) {
          for (let j = 0; j < info[result].length; j++) {
            info[result][j].portAccurateDistance[action.port][
              action.index
            ].terms = parseFloat(action.value)
          }
        }
      }

      info.target = action.type
      return {
        ...info
      }
    }
    case 'timeCounting': {
      const info = { ...state }
      const allResults = ['results', 'results2', 'results3']

      for (const result of allResults) {
        if (info[result].length > 0) {
          for (let j = 0; j < info[result].length; j++) {
            info[result][j].portAccurateDistance[action.port][
              action.index
            ].timeCounting = parseFloat(action.value)
          }
        }
      }

      info.target = action.type

      return {
        ...info
      }
    }
    case 'indiv-foid': {
      port = ''
      if (action.item === 'Delivery') {
        port = 'deliveryPort'
      } else if (action.item === 'Ballast') {
        port = 'viaBallast'
      } else if (action.item === 'Load') {
        port = 'loadPort'
      } else if (action.item === 'Laden') {
        port = 'viaLaden'
      } else if (action.item === 'Discharge') {
        port = 'dischargePort'
      } else if (action.item === 'ViaRedelivery') {
        port = 'viaRedelivery'
      } else if (action.item === 'Redelivery') {
        port = 'redelivery'
      }

      if (action.isVessel === 'vessel1') {
        newResults = state.results
      } else if (action.isVessel === 'vessel2') {
        newResults = state.results2
      } else {
        newResults = state.results3
      }

      if (
        newResults.length > 0 &&
				newResults[action.index].portAccurateDistance[port].length > 0
      ) {
        newResults[action.index].portAccurateDistance[port][
          action.i
        ].foid = parseFloat(action.value)
      }

      return {
        ...state,
        target: action.type,
        ...(action.isVessel === 'vessel1'
          ? {
            results: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            results2: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            results3: newResults
					  }
          : '')
      }
    }
    case 'indiv-mgo': {
      port = ''
      if (action.item === 'Delivery') {
        port = 'deliveryPort'
      } else if (action.item === 'Ballast') {
        port = 'viaBallast'
      } else if (action.item === 'Load') {
        port = 'loadPort'
      } else if (action.item === 'Laden') {
        port = 'viaLaden'
      } else if (action.item === 'Discharge') {
        port = 'dischargePort'
      } else if (action.item === 'ViaRedelivery') {
        port = 'viaRedelivery'
      } else if (action.item === 'Redelivery') {
        port = 'redelivery'
      }
      if (action.isVessel === 'vessel1') {
        newResults = state.results
      } else if (action.isVessel === 'vessel2') {
        newResults = state.results2
      } else {
        newResults = state.results3
      }

      if (
        newResults.length > 0 &&
				newResults[action.index].portAccurateDistance[port].length > 0
      ) {
        newResults[action.index].portAccurateDistance[port][
          action.i
        ].mgo = parseFloat(action.value)
      }

      return {
        ...state,
        target: action.type,
        ...(action.isVessel === 'vessel1'
          ? {
            results: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            results2: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            results3: newResults
					  }
          : '')
      }
    }
    case 'portCosts': {
      port = ''
      if (action.port === 'Delivery') {
        port = 'deliveryPort'
      } else if (action.port === 'Ballast') {
        port = 'viaBallast'
      } else if (action.port === 'Load') {
        port = 'loadPort'
      } else if (action.port === 'Laden') {
        port = 'viaLaden'
      } else if (action.port === 'Discharge') {
        port = 'dischargePort'
      } else if (action.port === 'ViaRedelivery') {
        port = 'viaRedelivery'
      } else if (action.port === 'Redelivery') {
        port = 'redelivery'
      }

      if (action.isVessel === 'vessel1') {
        newResults = state.results
      } else if (action.isVessel === 'vessel2') {
        newResults = state.results2
      } else {
        newResults = state.results3
      }

      if (port) {
        for (let i = 0; i < newResults.length; i++) {
          for (
            let j = 0;
            j < newResults[i].portAccurateDistance[port].length;
            j++
          ) {
            if (
              newResults[i].portAccurateDistance[port][j].port.id ===
							action.item.port.id
            ) {
              newResults[i].portAccurateDistance[port][j].portCost = parseFloat(
                action.value
              )
            }
          }
        }
        for (let i = 0; i < newResults.length; i++) {
          let newPortCosts = 0
          for (const key in newResults[i].portAccurateDistance) {
            for (
              let j = 0;
              j < newResults[i].portAccurateDistance[key].length;
              j++
            ) {
              newPortCosts +=
								newResults[i].portAccurateDistance[key][j].portCost
            }
          }
          newResults[i].portCosts = newPortCosts
        }
      }

      return {
        ...state,
        target: action.type,
        ...(action.isVessel === 'vessel1'
          ? {
            results: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            results2: newResults
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            results3: newResults
					  }
          : '')
      }
    }
    case 'fuelFOIDPrice': {
      let fuelFOIDPriceResults = ''

      if (action.isVessel === 'vessel1') {
        fuelFOIDPriceResults = 'results'
      } else if (action.isVessel === 'vessel2') {
        fuelFOIDPriceResults = 'results2'
      } else {
        fuelFOIDPriceResults = 'results3'
      }

      results = state[fuelFOIDPriceResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            fuelFOIDPrice: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [fuelFOIDPriceResults]: results,
        target: action.type
      }
    }
    case 'fuelFOIDTotal': {
      let fuelFOIDTotalResults = ''

      if (action.isVessel === 'vessel1') {
        fuelFOIDTotalResults = 'results'
      } else if (action.isVessel === 'vessel2') {
        fuelFOIDTotalResults = 'results2'
      } else {
        fuelFOIDTotalResults = 'results3'
      }
      results = state[fuelFOIDTotalResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            fuelFOIDTotal: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [fuelFOIDTotalResults]: results,
        target: action.type
      }
    }
    case 'fuelMGOPrice': {
      let fuelMGOPriceResults = ''

      if (action.isVessel === 'vessel1') {
        fuelMGOPriceResults = 'results'
      } else if (action.isVessel === 'vessel2') {
        fuelMGOPriceResults = 'results2'
      } else {
        fuelMGOPriceResults = 'results3'
      }
      results = state[fuelMGOPriceResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            fuelMGOPrice: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [fuelMGOPriceResults]: results,
        target: action.type
      }
    }
    case 'fuelMGOTotal': {
      let fuelMGOTotalResults = ''

      if (action.isVessel === 'vessel1') {
        fuelMGOTotalResults = 'results'
      } else if (action.isVessel === 'vessel2') {
        fuelMGOTotalResults = 'results2'
      } else {
        fuelMGOTotalResults = 'results3'
      }
      results = state[fuelMGOTotalResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            fuelMGOTotal: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [fuelMGOTotalResults]: results,
        target: action.type
      }
    }
    case 'totalFuel': {
      let totalFuelResults = ''

      if (action.isVessel === 'vessel1') {
        totalFuelResults = 'results'
      } else if (action.isVessel === 'vessel2') {
        totalFuelResults = 'results2'
      } else {
        totalFuelResults = 'results3'
      }
      results = state[totalFuelResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            totalFuel: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [totalFuelResults]: results,
        target: action.type
      }
    }
    case 'profit': {
      let profitResults = ''

      if (state.targetVessel === 'vessel1') {
        profitResults = 'results'
      } else if (state.targetVessel === 'vessel2') {
        profitResults = 'results2'
      } else {
        profitResults = 'results3'
      }
      results = state[profitResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            profit: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [profitResults]: results,
        target: action.type
      }
    }
    // case 'totalCosts': {
    //   let totalCostsResult = ''

    //   if (state.targetVessel === 'vessel1') {
    //     totalCostsResult = 'results'
    //   } else if (state.targetVessel === 'vessel2') {
    //     totalCostsResult = 'results2'
    //   } else {
    //     totalCostsResult = 'results3'
    //   }
    //   results = state[totalCostsResult].map((item, index) => {
    //     if (index === action.index) {
    //       return {
    //         ...item,
    //         totalCosts: parseFloat(action.value)
    //       }
    //     } else {
    //       return item
    //     }
    //   })
    //   return {
    //     ...state,
    //     [totalCostsResult]: results,
    //     target: action.type
    //   }
    // }

    case 'dailyHire': {
      let dailyHireResults = ''

      if (state.targetVessel === 'vessel1') {
        dailyHireResults = 'results'
      } else if (state.targetVessel === 'vessel2') {
        dailyHireResults = 'results2'
      } else {
        dailyHireResults = 'results3'
      }
      results = state[dailyHireResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            dailyHire: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [dailyHireResults]: results,
        target: action.type
      }
    }
    case 'tce': {
      let tceResults = ''
      if (action.isVessel === 'vessel1') {
        tceResults = 'results'
      } else if (action.isVessel === 'vessel2') {
        tceResults = 'results2'
      } else {
        tceResults = 'results3'
      }
      results = state[tceResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            tce: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [tceResults]: results,
        target: action.type
      }
    }
    case 'rate': {
      let rateResults = ''
      if (action.isVessel === 'vessel1') {
        rateResults = 'results'
      } else if (action.isVessel === 'vessel2') {
        rateResults = 'results2'
      } else {
        rateResults = 'results3'
      }
      results = state[rateResults].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            rate: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [rateResults]: results,
        target: action.type
      }
    }
    case 'governingDraft':
      return {
        ...state,
        ...(action.isVessel === 'vessel1'
          ? {
            results: complexIntakeResultsMapper(
              state.results,
              'governingDraft',
              action.value,
              action.index
            )
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            results2: complexIntakeResultsMapper(
              state.results2,
              'governingDraft',
              action.value,
              action.index
            )
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            results3: complexIntakeResultsMapper(
              state.results3,
              'governingDraft',
              action.value,
              action.index
            )
					  }
          : ''),
        target: action.type
      }
    case 'intake':
      return {
        ...state,
        ...(action.isVessel === 'vessel1'
          ? {
            results: complexIntakeResultsMapper(
              state.results,
              'intake',
              action.value,
              action.index
            )
					  }
          : ''),
        ...(action.isVessel === 'vessel2'
          ? {
            results2: complexIntakeResultsMapper(
              state.results2,
              'intake',
              action.value,
              action.index
            )
					  }
          : ''),
        ...(action.isVessel === 'vessel3'
          ? {
            results3: complexIntakeResultsMapper(
              state.results3,
              'intake',
              action.value,
              action.index
            )
					  }
          : ''),
        target: action.type
      }
    case 'resultCommission': {
      let commResult = ''

      if (state.targetVessel === 'vessel1') {
        commResult = 'results'
      } else if (state.targetVessel === 'vessel2') {
        commResult = 'results2'
      } else {
        commResult = 'results3'
      }

      results = state[commResult].map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            commission: parseFloat(action.value)
          }
        } else {
          return item
        }
      })
      return {
        ...state,
        [commResult]: results,
        target: action.type
      }
    }
    case 'change-checked': {
      const newVessels = { ...state.vessels }
      // let same = false

      // if (
      //   state.vessels[action.targetVessel].prevCheck === 0 &&
      // 	action.value === 'checked0'
      // ) {
      //   same = true
      // }
      // if (
      //   state.vessels[action.targetVessel].prevCheck === 1 &&
      // 	action.value === 'checked1'
      // ) {
      //   same = true
      // }
      // if (
      //   state.vessels[action.targetVessel].prevCheck === 2 &&
      // 	action.value === 'checked2'
      // ) {
      //   same = true
      // }

      if (action.value === 'checked0') {
        newVessels[action.targetVessel].checked0 = true
        newVessels[action.targetVessel].prevCheck = 0
      } else {
        newVessels[action.targetVessel].checked0 = false
      }
      if (action.value === 'checked1') {
        newVessels[action.targetVessel].checked1 = true
        newVessels[action.targetVessel].prevCheck = 1
      } else {
        newVessels[action.targetVessel].checked1 = false
      }
      if (action.value === 'checked2') {
        newVessels[action.targetVessel].checked2 = true
        newVessels[action.targetVessel].prevCheck = 2
      } else {
        newVessels[action.targetVessel].checked2 = false
      }

      let countFalse = 0
      for (const vessels in newVessels) {
        if (newVessels[vessels].checked0 === false) {
          countFalse += 1
        }
        if (newVessels[vessels].checked1 === false) {
          countFalse += 1
        }
        if (newVessels[vessels].checked2 === false) {
          countFalse += 1
        }
      }

      //! ! if true check first who has true, set it to false
      // const target = same ? 'no-target' : 'speed'

      // if (target !== 'no-target') {
      action.updateCheckFn()
      // }

      return {
        ...state,
        targetVessel: action.targetVessel,
        target: 'speed',
        vessels: countFalse === 9 ? { ...state.vessels } : { ...newVessels }
      }
    }
    case 'add': {
      // TODO in here

      const deliveryPort = [
        {
          name: action.value.deliveryPort.portName,
          bunker: action.value.deliveryPortBunker
        }
      ]
      const viaBallast = []
      for (let i = 0; i < action.value.viaBallastPorts.length; i++) {
        viaBallast.push({
          name: getPortInfo(
            action.value.viaBallastPorts[i],
            action.ports.ports
          ),
          bunker: action.value.viaBallastPortBunkerArray[i]
        })
      }
      const loadPort = []
      for (let i = 0; i < action.value.loadPorts.length; i++) {
        const name = getPortInfo(action.value.loadPorts[i], action.ports.ports)
        loadPort.push({
          name,
          bunker: action.value.loadPortArrayBunkerArray[i],
          customIntake:
						action.value.routeResults[action.value.routeResults.length - 1]
						  .routeResults[0].portAccurateDistance.loadPort[i].customIntake,
          terms:
						action.value.routeResults[action.value.routeResults.length - 1]
						  .routeResults[0].portAccurateDistance.loadPort[i].terms,
          timeCounting:
						action.value.routeResults[action.value.routeResults.length - 1]
						  .routeResults[0].portAccurateDistance.loadPort[i].timeCounting
        })
      }
      const viaLaden = []
      for (let i = 0; i < action.value.viaLadenPorts.length; i++) {
        viaLaden.push({
          name: getPortInfo(action.value.viaLadenPorts[i], action.ports.ports),
          bunker: action.value.viaLadenPortBunkerArray[i]
        })
      }
      const dischargePort = []
      for (let i = 0; i < action.value.dischargePorts.length; i++) {
        dischargePort.push({
          name: getPortInfo(action.value.dischargePorts[i], action.ports.ports),
          bunker: action.value.dischargePortArrayBunker[i],
          customIntake:
						action.value.routeResults[action.value.routeResults.length - 1]
						  .routeResults[0].portAccurateDistance.dischargePort[i]
						  .customIntake,
          terms:
						action.value.routeResults[action.value.routeResults.length - 1]
						  .routeResults[0].portAccurateDistance.dischargePort[i].terms,
          timeCounting:
						action.value.routeResults[action.value.routeResults.length - 1]
						  .routeResults[0].portAccurateDistance.dischargePort[i]
						  .timeCounting
        })
      }

      const viaRedelivery = []
      if (action.value.viaRedeliveryPort) {
        viaRedelivery.push({
          name: action.value.viaRedeliveryPort.portName,
          bunker: action.value.viaRedeliveryPortBunker
        })
      }
      const redelivery = []
      if (action.value.redeliveryPort) {
        redelivery.push({
          name: action.value.redeliveryPort.portName,
          bunker: action.value.redeliveryPortBunker
        })
      }
      for (
        let i = 0;
        i <
				action.value.routeResults[action.value.routeResults.length - 1]
				  .routeResults.length;
        i++
      ) {
        results.push(
          action.value.routeResults[action.value.routeResults.length - 1]
            .routeResults[i]
        )
      }
      return {
        ...state,
        id: action.value.id,
        routeId: action.value.id,
        cargo: {
          stem: action.value.stem,
          tolerance: action.value.tolerance,
          commission: action.value.commission,
          miscCost: action.value.miscCost
        },
        commodity: {
          id: action.value.commodity.id,
          name: action.value.commodity.commodityName,
          stowFactor: action.value.commodity.stowFactor,
          forwardArray: action.value.commodity.forwardArray
        },
        cargoPorts: {
          deliveryPort,
          viaBallast,
          loadPort,
          viaLaden,
          dischargePort,
          viaRedelivery,
          redelivery
        },
        vessels: {
          ...state.vessels,
          vessel1: {
            ...defaultVessel,
            checked0: true,
            vessel: action.value.vessel,
            results: {
              intake:
								action.value.routeResults[action.value.routeResults.length - 1]
								  .routeResults[0].intake.intake,
              rate:
								action.value.routeResults[action.value.routeResults.length - 1]
								  .routeResults[0].rate
            }
          }
        },
        results
      }
    }
    case 'update': {
      const deepCopy = (src) => {
        const target = Array.isArray(src) ? [] : {}
        for (const key in src) {
          const v = src[key]
          if (v) {
            if (typeof v === 'object') {
              target[key] = deepCopy(v)
            } else {
              target[key] = v
            }
          } else {
            target[key] = v
          }
        }
        return target
      }

      const newArrayUpdate = (array, value) => {
        const newArray = []
        for (let index = 0; index < 14; index++) {
          if (action.index === index) {
            newArray.push(deepCopy(value[index]))
          } else {
            newArray.push(deepCopy(value[index]))
          }
        }
        return newArray
      }

      const topLeftFields = ['stem', 'tolerance', 'commission', 'commodity', 'customIntake', 'terms', 'timeCounting']

      return {
        ...state,
        ...(action.value.updatedCalc[0]
          ? {
            results: newArrayUpdate(
              state.results,
              action.value.updatedCalc[0].routeResults
            )
          }
          : ''),
        ...(action.value.updatedCalc[1]
          ? {
            results2: newArrayUpdate(
              state.results2,
              action.value.updatedCalc[1].routeResults
            )
          }
          : ''),
        ...(action.value.updatedCalc[2]
          ? {
            results3: newArrayUpdate(
              state.results3,
              action.value.updatedCalc[2].routeResults
            )
          }
          : ''),
        vessels: {
          ...state.vessels,
          ...(action.value.apsUpdatedCalc[0] && action.value.dopUpdatedCalc[0]
            ? {
              vessel1: {
                ...state.vessels.vessel1,
                ...vesselsResultsMapper(
                  state.vessels,
                  'vessel1',
                  action.value,
                  0
                )
              }
            }
            : ''),
          ...(action.value.apsUpdatedCalc[1] && action.value.dopUpdatedCalc[1]
            ? {
              vessel2: {
                ...state.vessels.vessel2,
                ...vesselsResultsMapper(
                  state.vessels,
                  'vessel2',
                  action.value,
                  1
                )
              }
            }
            : ''),
          ...(action.value.apsUpdatedCalc[2] && action.value.dopUpdatedCalc[2]
            ? {
              vessel3: {
                ...state.vessels.vessel3,
                ...vesselsResultsMapper(
                  state.vessels,
                  'vessel3',
                  action.value,
                  2
                )
              }
            }
            : '')
        }
      }
    }

    case 'setTarget': {
      return {
        ...state,
        target: action.target
      }
    }

    default:
      return {
        ...state
      }
  }
}
// const useStyles = makeStyles(theme => ({
//   card: {
//     padding: '2.5%',
//     minHeight: '250px',
//   },
//   title: {
//     flexGrow: 1,
//   },
// }))

const weatherStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600
  },
  zonesTraversedTable: {
    whiteSpace: 'normal'
  },
  row: {
    backgroundColor: '#F4F4F4'
  },
  input: {
    maxWidth: '70px'
  },
  textCell: {
    fontSize: theme.typography.generalFontSize
  },
  redTextCell: {
    fontSize: theme.typography.generalFontSize,
    color: 'red'
  },
  normalCell: {
    // fontSize: 15,
    height: 25
  },
  higherCell: {
    fontSize: 15,
    height: 45
  }
}))

const RouteResultsQueryHelper = (props) => {
  const { match, history: _history } = props // history: _history, location: _location, ...rest
  let route
  if (match && match.params) {
    route = match.params.route
  }
  return (
    <>
      <Helmet>
        <title>Calculation Page</title>
      </Helmet>
      <QueryHelper query={GET_SIMPLE_VESSELS}>
        {({ data }) => {
          const { getVessels: vessels } = data
          return (
            <QueryHelper query={GET_CARGO_COMMODITIES}>
              {({ data, loading }) => {
                const { getCargoCommodities: commodities } = data
                return (
                  <QueryHelper query={GET_PORTS}>
                    {({ data }) => {
                      const { getPorts: ports } = data
                      if (data) {
                        return (
                          <ShowRouteResultsArray
                            commodities={commodities}
                            vessels={vessels}
                            ports={ports}
                            route={route}
                            history={_history}
                          />
                        )
                      } else if (loading) {
                        return (
                          <LoadingModal
                            open={true}
                            pageName='Calculation Page'
                          />
                        )
                      } else {
                        return <></>
                      }
                    }}
                  </QueryHelper>
                )
              }}
            </QueryHelper>
          )
        }}
      </QueryHelper>
    </>
  )
}

export default withRouter(RouteResultsQueryHelper)

const cargoStyles = makeStyles((theme) => ({
  card: {
    minHeight: '300px',
    // minWidth: 420,
    maxWidth: 600
  },
  portName: {
    marginRight: '2%',
    fontSize: theme.typography.generalFontSize
  },
  bigInput: {
    // width: '20%',
    // marginLeft: '2%',
  },
  smallInputBase: {
    // width: '12%',
    // marginLeft: '2%',
  },
  textCell: {
    // padding: '6px 24px 6px 0',
    fontSize: theme.typography.generalFontSize
  },
  redTextCell: {
    // padding: '6px 24px 6px 0',
    fontSize: theme.typography.generalFontSize,
    color: 'red'
  },
  inputCell: {
    marginRight: 12,
    textAlign: 'right',
    '&:hover': {
      borderBottom: '1px solid rgba(0,0,0,0.2)'
    }
  },
  tableRowNoBorderBottom: {
    borderBottom: 'none'
  },
  cellWrap: {
    whiteSpace: 'normal'
  },
  redCellWrap: {
    color: 'red'
  },
  alignRight: {
    textAlign: 'right'
  },
  noMarginAutoComplete: {
    margin: 0,
    '& .MuiFormControl-root': {
      margin: 0
    },
    '& .MuiInputBase-input': {
      textAlign: 'right'
    }
  },
  classesNoMarginAutoComplete: {
    margin: 0
  },
  normalCell: {
    height: 25
    // fontSize: 15
  }
}))

const CargoCalc = (props) => {
  const {
    selectedResult,
    calcUpdateMutation,
    info,
    setInfo,
    setUpdateCheck,
    updateCheck,
    commodities,
    adcomValue,
    setAdcomValue,
    recalc,
    setRecalc
  } = props // data, routes, route

  const classes = cargoStyles()
  // const fixedStyles = fixedClasses()
  let result
  if (info.targetVessel === 'vessel1') {
    result = info.results ? info.results : []
  } else if (info.targetVessel === 'vessel2') {
    result = info.results2 ? info.results2 : []
  } else {
    result = info.results3 ? info.results3 : []
  }

  const theme = useTheme()

  const mobileSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Card className={classes.card}>
      <CardContent>
        <Table size='small' aria-label='cargo table'>
          <TableBody>
            <TableRow style={{ width: '100%' }}>

              <TableCell colSpan={3} align='right' className={clsx([classes.normalCell])}>
                Adcom
              </TableCell>

              <TableCell colSpan={1} align='right'>
                <EditableInputBase
                  type='text'
                  inputProps={{ style: { height: 25 } }}
                  noFormat
                  alignRight
                  onChange={(e) => setAdcomValue(e.target.value)} // setInfo({ value: e.target.value, type: 'adcom' })
                  // onKeyDown={(e) => {
                  // }}
                  value={adcomValue}
                />
              </TableCell>

            </TableRow>
            {Object.keys(info.cargo).map((item, i) => {
              if (item === 'miscCost') {
                return
              }
              return (
                <TableRow key={i}>
                  <TableCell
                    size='small'
                    align='left'
                    className={clsx([classes.normalCell])}
                  >
                    {item === 'commission'
                      ? 'Brokerage'
                      : item.charAt(0).toUpperCase() + item.slice(1)}
                  </TableCell>
                  <TableCell colSpan={4} className={classes.alignRight}>
                    <EditableInputBase
                      alignRight
                      inputProps={{ style: { height: 25 } }}
                      onChange={(e) =>
                        setInfo({ value: e.target.value, type: item })}
                      onKeyDown={(e) => {
                        if (e.keyCode === 9 || e.keyCode === 13) {
                          setInfo({
                            type: 'setTarget',
                            target: item
                          })
                          setRecalc(true)
                          // calcUpdateMutation();
                        }
                      }}
                      onBlur={(e) => {
                        if (mobileSize || isMobile) {
                          setInfo({
                            type: 'setTarget',
                            target: item
                          })
                          setRecalc(true)
                        }
                      }}
                      value={info.cargo[item]}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
            {info && info.commodity && (
              <TableRow>
                <TableCell className={clsx([classes.normalCell])}>Commodity</TableCell>
                <TableCell colSpan={3} className={classes.alignRight}>
                  <RootAutocomplete
                    autoHighlight
                    autoSelect
                    rootClassName={classes.noMarginAutoComplete}
                    classes={{
                      root: classes.classesNoMarginAutoComplete
                    }}
                    optionClass={classes.normalCell}
                    inputClass={classes.normalCell}
                    disableClearable
                    id='commodity-select'
                    value={info.commodity}
                    onChange={(e) => {
                      // setDeliveryPortIndex(e.target.value)
                      setInfo({
                        value: e.value.id,
                        type: 'commodity',
                        commodities,
                        updateCheckFn: () => setUpdateCheck(updateCheck + 1)
                      })
                    }}
                    size='small'
                    options={commodities.map((item, i) => {
                      return {
                        ...item
                      }
                    })}
                  />
                </TableCell>
              </TableRow>
            )}
            {result[selectedResult] &&
							result[selectedResult].portAccurateDistance.loadPort.map((item, i) => {
							    return (
							    <Fragment key={i}>
							      <TableRow key={i}>
							        <TableCell className={clsx([classes.textCell])}>
							          <HtmlTooltip title={`${item.port.portName} - ${item.port.berth}`}>
							            <Typography
							              className={
							                  clsx([
							                    item.port.distanceOnly
							                    ? classes.redTextCell
							                    : classes.textCell,
							                    classes.normalCell
							                  ])
							                }
  >
							              {`${item.port.portName}`}
  </Typography>
  </HtmlTooltip>
  </TableCell>
							        <TableCell size='small'>
							          <EditableInputBase
							            alignRight
    inputProps={{ style: { height: 25 } }}
							            onChange={(e) =>
							                setInfo({
							                  value: e.target.value,
							                  type: 'customIntake',
							                  index: i,
							                  port: 'loadPort'
							                })}
							            value={item.customIntake ? item.customIntake : 0}
							            onKeyDown={(e) => {
							                if (e.keyCode === 9 || e.keyCode === 13) {
							                  setInfo({
							                    type: 'setTarget',
							                    target: 'customIntake'
							                  })
							                  setRecalc(true)
							                  // calcUpdateMutation();
							                }
  }}
							            onBlur={(e) => {
							              if (mobileSize || isMobile) {
							                setInfo({
							                  type: 'setTarget',
							                  target: 'customIntake'
							                })
							                setRecalc(true)
							              }
							              // calcUpdateMutation();
  }}
  />
  </TableCell>
							        <TableCell size='small'>
							          <EditableInputBase
							            isInteger
							            alignRight
    inputProps={{ style: { height: 25 } }}

							            onChange={(e) =>
							                setInfo({
							                  value: e.target.value,
							                  type: 'terms',
							                  index: i,
							                  port: 'loadPort'
							                })}
							            value={item.terms}
							            onKeyDown={(e) => {
							                if (e.keyCode === 9 || e.keyCode === 13) {
							                  setInfo({
							                    type: 'setTarget',
							                    target: 'terms'
							                  })
							                  setRecalc(true)
							                  // calcUpdateMutation();
							                }
  }}
    onBlur={(e) => {
							              if (mobileSize || isMobile) {
							              setInfo({
							                type: 'setTarget',
							                target: 'terms'
							              })
							              setRecalc(true)
							              }
}}
  />
  </TableCell>
							        <TableCell size='small'>
							          <EditableInputBase
							            alignRight
							            maxWidth={80}
    inputProps={{ style: { height: 25 } }}

							            onChange={(e) =>
							                setInfo({
							                  value: e.target.value,
							                  type: 'timeCounting',
							                  index: i,
							                  port: 'loadPort'
							                })}
							            value={item.timeCounting}
							            onKeyDown={(e) => {
							                if (e.keyCode === 9 || e.keyCode === 13) {
							                  setInfo({
							                    type: 'setTarget',
							                    target: 'timeCounting'
							                  })
							                  setRecalc(true)
							                  // calcUpdateMutation();
							                }
  }}
    onBlur={(e) => {
							              if (mobileSize || isMobile) {
							                setInfo({
							                  type: 'setTarget',
							                  target: 'timeCounting'
							                })
							                setRecalc(true)
							              }
}}
  />
  </TableCell>
  </TableRow>
  </Fragment>
							    )
							  }
							)}
            {result[selectedResult] &&
							result[selectedResult].portAccurateDistance.dischargePort.map((item, i) => {
							    return (
							    <Fragment key={i}>
							      <TableRow key={i}>
							        <TableCell className={classes.textCell}>
							          <HtmlTooltip
							            title={`${item.port.portName} - ${item.port.berth}`}
  >
							            <Typography
							              className={classes.normalCell}
  >{`${item.port.portName} `}
  </Typography>
  </HtmlTooltip>
  </TableCell>
							        <TableCell size='small'>
							          <EditableInputBase
							            alignRight
    inputProps={{ style: { height: 25 } }}
							            onChange={(e) =>
							                setInfo({
							                  value: e.target.value,
							                  type: 'customIntake',
							                  index: i,
							                  port: 'dischargePort'
							                })}
							            value={item.customIntake ? item.customIntake : 0}
							            onKeyDown={(e) => {
							                if (e.keyCode === 9 || e.keyCode === 13) {
							                  setInfo({
							                    type: 'setTarget',
							                    target: 'customIntake'
							                  })
							                  setRecalc(true)
							                  // calcUpdateMutation();
							                }
  }}
							            onBlur={(e) => {
							              if (mobileSize || isMobile) {
							                setInfo({
							                  type: 'setTarget',
							                  target: 'customIntake'
							                })
							                setRecalc(true)
							              }
  }}
  />
  </TableCell>
							        <TableCell size='small'>
							          <EditableInputBase
							            isInteger
    inputProps={{ style: { height: 25 } }}
							            alignRight
							            onChange={(e) =>
							                setInfo({
							                  value: e.target.value,
							                  type: 'terms',
							                  index: i,
							                  port: 'dischargePort'
							                })}
							            value={item.terms}
							            onKeyDown={(e) => {
							                if (e.keyCode === 9 || e.keyCode === 13) {
							                  setInfo({
							                    type: 'setTarget',
							                    target: 'terms'
							                  })
							                  setRecalc(true)
							                  // calcUpdateMutation();
							                }
  }}
							            onBlur={(e) => {
							              if (mobileSize || isMobile) {
							              setInfo({
							                type: 'setTarget',
							                target: 'terms'
							              })
							              setRecalc(true)
							              }
  }}
  />
  </TableCell>
							        <TableCell size='small'>
							          <EditableInputBase
							            alignRight
    inputProps={{ style: { height: 25 } }}
							            maxWidth={80}
							            onChange={(e) =>
							                setInfo({
							                  value: e.target.value,
							                  type: 'timeCounting',
							                  index: i,
							                  port: 'dischargePort'
							                })}
							            value={item.timeCounting}
							            onKeyDown={(e) => {
							                if (e.keyCode === 9 || e.keyCode === 13) {
							                  setInfo({
							                    type: 'setTarget',
							                    target: 'timeCounting'
							                  })
							                  setRecalc(true)
							                  // calcUpdateMutation();
							                }
  }}
    onBlur={(e) => {
							              if (mobileSize || isMobile) {
							                setInfo({
							                  type: 'setTarget',
							                  target: 'timeCounting'
							                })
							                setRecalc(true)
							              }
}}
  />
  </TableCell>
  </TableRow>
  </Fragment>
							    )
							  }
							)}

            {/* {data.map((item, i) => {
              return(
                <Fragment>
                {typeof(item.value) === 'object' ?
                  <TableRow key={i}>
                    <TableCell size='small'>{item.title}</TableCell>
                    <TableCell><Typography variant= 'body2'>{`${[item.value[0].name]} ${([item.value[0].bunker]) ? 'bunker' : ''} ${formatNumber({ number: ([item.value[0].terms])})} ${([item.value[0].timeCounting])} `}</Typography></TableCell>
                  </TableRow>
                  :
                  <TableRow key={i}>
                    <TableCell size='small'>{item.title}</TableCell>
                    <TableCell><Typography variant= 'body2'>{routes[route] ? [item.value] : ''}</Typography></TableCell>
                  </TableRow>
                }
                </Fragment>
              )
            })} */}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

const WeatherMarginCalc = (props) => {
  const {
    data,
    selectedRoutes,
    info,
    setInfo,
    calcUpdateMutation,
    avgs,
    updateCheck,
    setUpdateCheck,
    recalc,
    setRecalc
  } = props
  const classes = weatherStyles()
  const getPortsWeather = (params = {}) => {
    const { ports } = params
    const result = []
    if (ports) {
      for (let i = 0; i < ports.length; i++) {
        const obj = {
          name: `${ports[i].port.portName}`,
          berth: `${ports[i].port.berth}`,
          bunker: ports[i].bunker,
          weather: ` ${ports[i].weatherMargin} %`,
          foid: `${ports[i].foid}`,
          mgo: `${ports[i].mgo}`,
          distanceOnly: ports[i].port.distanceOnly
        }
        result.push(obj)
      }
    }

    return result
  }
  const weatherData = [
    {
      title: 'Delivery',
      value:
				typeof data !== 'undefined'
				  ? getPortsWeather({ ports: data.deliveryPort })
				  : [{ name: '', bunker: false, weather: '', foid: '', mgo: '' }]
    },
    {
      title: 'Ballast',
      value:
				typeof data !== 'undefined'
				  ? getPortsWeather({ ports: data.viaBallast })
				  : [{ name: '', bunker: false, weather: '', foid: '', mgo: '' }]
    },
    {
      title: 'Load',
      value:
				typeof data !== 'undefined'
				  ? getPortsWeather({ ports: data.loadPort })
				  : [{ name: '', bunker: false, weather: '', foid: '', mgo: '' }]
    },
    {
      title: 'Laden',
      value:
				typeof data !== 'undefined'
				  ? getPortsWeather({ ports: data.viaLaden })
				  : [{ name: '', bunker: false, weather: '', foid: '', mgo: '' }]
    },
    {
      title: 'Discharge',
      value:
				typeof data !== 'undefined'
				  ? getPortsWeather({ ports: data.dischargePort })
				  : [{ name: '', bunker: false, weather: '', foid: '', mgo: '' }]
    },
    {
      title: 'ViaRedelivery',
      value:
				typeof data !== 'undefined'
				  ? getPortsWeather({ ports: data.viaRedelivery })
				  : [{ name: '', bunker: false, weather: '', foid: '' }]
    },
    {
      title: 'Redelivery',
      value:
				typeof data !== 'undefined'
				  ? getPortsWeather({ ports: data.redelivery })
				  : [{ name: '', bunker: false, weather: '', foid: '' }]
    }
  ]
  const getAvgWM = (data) => {
    let avg = 0
    let count = 0
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].value.length; j++) {
        if (data[i].value[j].bunker) {
          count++
          avg += parseInt(data[i].value[j].weather.split('%')[0])
        }
      }
    }
    return avg / count
  }

  const figureOutVessel = (selectedVessel) => {
    if (selectedVessel === 'vessel1') {
      return 'results'
    } else if (selectedVessel === 'vessel2') {
      return 'results2'
    } else if (selectedVessel === 'vessel3') {
      return 'results3'
    }
  }

  const figureOutPort = (inputPort) => {
    if (inputPort === 'Delivery') {
      return 'deliveryPort'
    } else if (inputPort === 'Ballast') {
      return 'viaBallast'
    } else if (inputPort === 'Load') {
      return 'loadPort'
    } else if (inputPort === 'Laden') {
      return 'viaLaden'
    } else if (inputPort === 'Discharge') {
      return 'dischargePort'
    } else if (inputPort === 'ViaRedelivery') {
      return 'viaRedelivery'
    } else if (inputPort === 'Redelivery') {
      return 'redelivery'
    }
  }

  const theme = useTheme()
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Card className={classes.card}>
      <CardContent>
        <TitleTypography gutterBottom style={{ fontSize: 16 }}>Zones Traversed</TitleTypography>

        <Table size='small' aria-label='weather-margin'>
          <TableHead>
            <TableRow>
              <TableCell className={clsx([classes.normalCell])}>Zones Traversed</TableCell>
              <TableCell className={clsx([classes.normalCell])} align='right'>Bunker</TableCell>
              <TableCell className={clsx([classes.normalCell])} align='right'>Weather Margin</TableCell>
              <TableCell className={clsx([classes.normalCell])} align='right'>FOID</TableCell>
              <TableCell className={clsx([classes.normalCell])} align='right'>MGO</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.zonesTraversedTable}>
            {weatherData.map((item, i) => {
              if (item.value.length === 0) {
                return null
              }
              return (
                <Fragment key={i}>
                  {item.value.map((port, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell style={{ height: 45 }}>
                          <HtmlTooltip title={`${port.name} - ${port.berth}`}>
                            <Typography
                              style={{ fontSize: 15 }}
                              className={
                                clsx([
                                  port.distanceOnly
                                    ? classes.redTextCell
                                    : classes.textCell
                                ])

                              }
                            >
                              {
                                `${port.name}`.split('').length >= 10
                                  ? `${`${port.name}`.split('').filter((char, i) => i <= 10).join('')}... ${item.title}`
                                  : `${port.name} ${item.title}`
                              }
                              {/* {`${port.name} ${item.title}`} */}
                            </Typography>
                          </HtmlTooltip>
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={port.bunker}
                            onChange={(e) => {
                              setInfo({
                                value: e.target.checked,
                                type: 'bunker',
                                index,
                                item: item.title,
                                updateCheckFn: () =>
                                  setUpdateCheck(updateCheck + 1),
                                isVessel: info.targetVessel
                              })
                            }}
                            value={port.bunker}
                            disabled={port.foid === '0'}
                            color='primary'
                            onKeyDown={(e) => {
                              if (e.keyCode === 9 || e.keyCode === 13) {
                                calcUpdateMutation()
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell className={clsx([classes.normalCell])} align='right'>{port.weather}</TableCell>
                        <TableCell align='right' className={classes.input}>
                          <EditableInputBase
                            inputProps={{ style: { height: 25 } }}
                            onChange={(e) =>
                              setInfo({
                                value: e.target.value,
                                type: 'indiv-foid',
                                index: selectedRoutes,
                                i: index,
                                item: item.title,
                                isVessel: info.targetVessel
                              })}
                            // value={formatNumber({ number: port.foid, format: '0,0' })}
                            value={
                              info[figureOutVessel(info.targetVessel)].length >
																0 &&
															info[figureOutVessel(info.targetVessel)][
															  selectedRoutes
															].portAccurateDistance[figureOutPort(item.title)][
															  index
															].foid
                            }
                            // value={info[figureOutVessel(info.targetVessel)][selectedRoutes]}
                            alignRight
                            noMargin
                            isInteger
                            onKeyDown={(e) => {
                              if (e.keyCode === 9 || e.keyCode === 13) {
                                setInfo({
                                  type: 'setTarget',
                                  target: 'indiv-foid'
                                })
                                setRecalc(true)
                              }
                            }}
                            onBlur={(e) => {
                              if (mobileSize || isMobile) {
                                setInfo({
                                  type: 'setTarget',
                                  target: 'indiv-foid'
                                })
                                setRecalc(true)
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell align='right' className={classes.input}>
                          <EditableInputBase
                            inputProps={{ style: { height: 25 } }}
                            onChange={(e) =>
                              setInfo({
                                value: e.target.value,
                                type: 'indiv-mgo',
                                index: selectedRoutes,
                                i: index,
                                item: item.title,
                                isVessel: info.targetVessel
                              })}
                            // value={port.mgo
                            value={
                              info[figureOutVessel(info.targetVessel)].length >
																0 &&
															info[figureOutVessel(info.targetVessel)][
															  selectedRoutes
															].portAccurateDistance[figureOutPort(item.title)][
															  index
															].mgo
                            }
                            // value={info[figureOutVessel(info.targetVessel)][selectedRoutes]}
                            alignRight
                            noMargin
                            isInteger
                            onKeyDown={(e) => {
                              if (e.keyCode === 9 || e.keyCode === 13) {
                                setInfo({
                                  type: 'setTarget',
                                  target: 'indiv-mgo'
                                })
                                setRecalc(true)
                                // calcUpdateMutation();
                              }
                            }}
                            onBlur={(e) => {
                              if (mobileSize || isMobile) {
                                setInfo({
                                  type: 'setTarget',
                                  target: 'indiv-mgo'
                                })
                                setRecalc(true)
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </Fragment>
              )
            })}
            {avgs && (
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell align='right' />
                <TableCell />
                <TableCell className={clsx([classes.higherCell])} align='right'>{`${formatNumber({
									number: avgs.fuelFOIDPrice
								})}`}
                </TableCell>
                <TableCell className={clsx([classes.higherCell])} align='right'>{`${formatNumber({
									number: avgs.fuelMGOPrice
								})}`}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

const positionDatesStyles = makeStyles((theme) => ({
  alignRight: {
    // fontSize: '12px',
    fontSize: theme.typography.generalFontSize,
    textAlign: 'right'
  },
  posDate: {
    // fontSize: '12px',
    fontSize: theme.typography.generalFontSize
  },
  card: {
    minHeight: '300px',
    margin: '0',
    padding: '0'
  },
  fixed250: {
    minWidth: '230px',
    width: '230px',
    maxWidth: '230px'
  },
  normalCell: {
    height: 25
    // fontSize: 15
  }
}))
const RouteResults = (props) => {
  const { info, selectedResult, setSelectedResult, adcomValue } = props
  const classes = positionDatesStyles()
  let data = info.results ? info.results : []

  if (info.targetVessel === 'vessel1') {
    data = info.results ? info.results : []
  } else if (info.targetVessel === 'vessel2') {
    data = info.results2 ? info.results2 : []
  } else {
    data = info.results3 ? info.results3 : []
  }

  return (
    <Card className={classes.card}>
      <CardContent>
        <TitleTypography gutterBottom style={{ fontSize: 16 }}>Position Date Results</TitleTypography>
        <Table size='small' aria-label='position date results'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.normalCell}>Month</TableCell>
              <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>TCE Gross</TableCell>
              <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>Rate</TableCell>
              <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>IFO</TableCell>
              <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>Intake</TableCell>
              <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>
								Gross Freight
              </TableCell>
              <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, i) => {
              return (
                <TableRow
                  key={i}
                  selected={selectedResult === i}
                  onClick={() => setSelectedResult(i)}
                >
                  <TableCell className={classes.posDate}>
                    {item.positionDate.positionDateName}
                  </TableCell>
                  <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>
                    {formatNumber({ number: item.dailyHire, format: '0,0' })}
                  </TableCell>
                  <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>
                    {formatNumber({
                      number:
												item.rate / (1 - correctParseFloat(adcomValue) / 100)
                    })}
                  </TableCell>
                  <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>
                    {formatNumber({ number: item.fuelFOIDPrice })}
                  </TableCell>
                  <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>
                    {formatNumber({ number: item.intake.intake })}
                  </TableCell>
                  <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>
                    {formatNumber({ number: item.totalCosts, format: '0,0' })}
                  </TableCell>
                  <TableCell className={clsx([classes.alignRight, classes.fixed250, classes.normalCell])}>
                    {formatNumber({ number: item.profit, format: '0,0' })}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

const mainStyles = makeStyles((theme) => ({
  routeHeaderSelect: {
    width: '600px',
    marginRight: '1%'
  },
  rootSelect: {
    zIndex: 99,
    width: '100%'
  },
  rootButton: {
    margin: 'auto',
    height: 41,
    marginTop: 2,
    borderRadius: '5px'
  },
  vesseslContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  errorClass: {
    color: 'red'
  },
  firstThirdContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '30vw',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  thirds: {
    flexBasis: '70vw'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    alignItems: 'stretch',
    height: '100%'
  },
  cargo: {
    order: 0,
    flex: '0 1 40%',
    alignSelf: 'auto',
    width: '30%'
  },
  weather: {
    order: 0,
    flex: '0 1 40%',
    alignSelf: 'auto',
    width: '30%'
  },
  vessels: {
    order: 0,
    flex: '0 1 23%',
    alignSelf: 'auto',
    width: '60%'
  },
  calcs: {
    order: 0,
    flex: '0 1 23',
    alignSelf: 'auto',
    width: '60%'
  },
  results: {
    order: 0,
    flex: '0 1 23%',
    alignSelf: 'auto',
    width: '60%'
  },
  // root: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   flexWrap: 'wrap',
  //   justifyContent: 'center',
  //   alignContent: 'stretch',
  //   alignItems: 'stretch',
  // },
  firstContainer: {
    order: 0,
    flex: '0 1 100%',
    alignSelf: 'auto',
    [theme.breakpoints.up('md')]: {
      flex: '0 1 33%'
    }
  },
  secondContainer: {
    order: 0,
    flex: '0 1 100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 1 66%'
    },
    alignSelf: 'auto'
  },
  selectContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  autocomplete: {
    width: '40%',
    marginRight: 8
  }
}))
const ShowRouteResultsArray = (props) => {
  const classesMain = mainStyles()

  const [input] = useState({}) // SetInput
  const initialState = {
    id: '',
    routeId: '',
    adcom: 0,
    cargo: {
      stem: null,
      tolerance: null,
      commission: null,
      miscCost: null
    },
    commodity: '',
    cargoPorts: {
      deliveryPort: [],
      viaBallast: [],
      loadPort: [],
      viaLaden: [],
      dischargePort: [],
      viaRedelivery: [],
      redelivery: []
    },
    vessels: {
      vessel1: {
        ...defaultVessel,
        // TODO Check this logic!
        // vessel: {vessel: routes[route] ? routes[route].vessel : ''},
        checked0: true
      },
      vessel2: {
        ...defaultVessel
      },
      vessel3: {
        ...defaultVessel
      }
    },
    results: [],
    results2: [],
    results3: [],
    target: '',
    targetVessel: 'vessel1'
  }
  const { vessels, ports, commodities, history, route: routeFromLink } = props

  const [adcomValue, setAdcomValue] = useState('0.00')
  const [info, setInfo] = useReducer(reducer, initialState)

  const { user, setUser } = useContext(UserContext)
  // const [routeResultsArray, setRouteResultsArray] = useState()
  // const [routeResultsArray2, setRouteResultsArray2] = useState()
  // const [routeResultsArray3, setRouteResultsArray3] = useState()
  const [routes, setRoutes] = useState([])

  const getRouteFromLink = (id, routeArray) => {
    for (let i = 0; i < routeArray.length; i++) {
      if (routeArray[i].id === id) {
        return {
          index: routeArray[i].index,
          name: routeArray[i].name
        }
      }
    }

    return {
      index: null,
      name: ''
    }
  }

  const formattedRoutes = routes.map((item, i) => {
    return {
      id: item.id,
      index: i + 1,
      routeName: item.routeName,
      name: item.routeName,
      commodityName: item.commodity.commodityName,
      dischargePorts: item.dischargePortArray.map((item) => item.portName),
      loadPorts: item.loadPortArray.map((item) => item.portName)
    }
  })

  const [routeId, setRouteId] = useState(
    routeFromLink
      ? getRouteFromLink(routeFromLink, formattedRoutes)
      : user.calc && user.calc.routeId && user.calc.routeName
        ? { index: user.calc.routeId, name: user.calc.routeName }
        : { index: null, name: null }
  )
  const [optionRoutes, setOptionRoutes] = useState(formattedRoutes)

  const ts = new TrieSearch(
    ['routeName', 'commodityName', 'loadPorts', 'dischargePorts'],
    {
      idFieldOrFunction: 'id'
    }
  )

  ts.addAll(formattedRoutes)

  const [autocompleteValue, setAutoCompleteValue] = useState('')

  const searchRef = useRef(autocompleteValue)

  const [recalc, setRecalc] = useState(false)

  useEffect(() => {
    if (recalc) {
      setRecalc(false)
      calcUpdateMutation()
    }
  }, [recalc, info.target])

  useEffect(() => {
    if (searchRef.current !== autocompleteValue) {
      searchRef.current = autocompleteValue
      if (autocompleteValue === '') {
        setOptionRoutes(formattedRoutes)
      } else {
        const searchArray = autocompleteValue.split(',')
        const foundRoutes = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setOptionRoutes(foundRoutes)
      }
    }
  }, [autocompleteValue, optionRoutes, routes, formattedRoutes, ts])

  useEffect(() => {
    setOptionRoutes(formattedRoutes)
    let initialRouteId = -1
    if (routeFromLink) {
      initialRouteId = getRouteFromLink(routeFromLink, formattedRoutes)
    } else if (user.calc && user.calc.routeId && user.calc.routeName) {
      initialRouteId = {
        index: user.calc.routeIndex,
        name: user.calc.routeName
      }
    } else {
      initialRouteId = { index: null, name: null }
    }
    setRouteId(initialRouteId)
  }, [routes])

  const [updateCheck, setUpdateCheck] = useState(0)
  const updateCheckRef = useRef(updateCheck)

  useEffect(() => {
    if (updateCheck !== updateCheckRef.current) {
      updateCheckRef.current = updateCheck
      calcUpdateMutation()
    }
  }, [updateCheck])

  const calcMutation = () => {}
  let calcUpdateMutation = () => {}

  let setUpdate = () => {}
  const handleChange = async ({ value, mutation }) => {
    // setUser({ type: 'add-spotValidation-info', value: { routeIndex: value }})
    setRoute(value)
    // await handleVesselsVessel({
    //   name: 'vessel1',
    //   value: routes[value].vessel
    // })
    // setInput({ id: routes[value].id })
  }
  let initialRoute1 = ''
  let initialRouteIndex = ''
  if (routeFromLink) {
    initialRoute1 = routeFromLink
    initialRouteIndex = routes.map((x) => x.id).indexOf(routeFromLink) + 1
    // setUser({ type: 'add-calc-info', value: { routeIndex: initialRouteIndex, routeId: routeFromLink } })
  } else if (user.calc && user.calc.routeIndex) {
    initialRouteIndex = user.calc.routeIndex
    initialRoute1 = user.calc.routeId
  } else {
    initialRouteIndex = null
    initialRoute1 = null
  }

  useEffect(() => {
    setRoute(initialRouteIndex)
  }, [initialRouteIndex])

  const [route, setRoute] = useState(initialRouteIndex)

  const inputRef = React.useRef(input)
  const [route1, setRoute1] = useState(initialRoute1)
  const [selectedResult, setSelectedResult] = useState(0)
  // const route1Ref = React.useRef(route1)

  const [routeUpdate, setRouteUpdate] = useState('')
  const updateRouteRef = React.useRef(routeUpdate)
  useEffect(() => {
    if (input.id !== inputRef.current.id) {
      inputRef.current = input
      calcMutation()
    }
  })

  const [retrievedRoute, setRetrievedRoute] = useState('')
  const retrievedRouteRef = React.useRef(retrievedRoute.id)
  useEffect(() => {
    if (retrievedRoute.id !== retrievedRouteRef.current) {
      retrievedRouteRef.current = retrievedRoute.id
      setInfo({ value: retrievedRoute, type: 'add', ports })
    }
  }, [retrievedRoute])

  useEffect(() => {
    if (routeUpdate !== updateRouteRef.current) {
      updateRouteRef.current = routeUpdate
      setUpdate()
    }
  }, [routeUpdate])

  const formatCommodities = (commodities) => {
    const array = []
    for (let i = 0; i < commodities.commodities.length; i++) {
      array.push({
        id: commodities.commodities[i].id,
        name: commodities.commodities[i].commodityName,
        stowFactor: commodities.commodities[i].stowFactor,
        forwardArray: commodities.commodities[i].forwardArray
      })
    }
    return array
  }
  return (
    <>
      <QueryHelper query={GET_SIMPLE_ROUTES}>
        {({ data, loading }) => {
          const {
            getRoutes: { routes }
          } = data

          setRoutes(routes)

          if (data) {
            return (
              <Grid>
                <FormControl className={classesMain.rootSelect}>
                  <QueryHelper
                    query={GET_CALCULATION_ROUTE_INFO}
                    variables={{ input: { id: route1 } }}
                    skip={!route1}
                  >
                    {({ data }) => {
                      let dataRoute
                      if (data) {
                        const { getRoute } = data
                        dataRoute = getRoute.route
                        if (dataRoute.routeResults.length > 0) {
                          setRetrievedRoute(dataRoute)
                        } else {
                          return (
                            <>
                              <CustomAutocomplete
                                autoSelect
                                autoHighlight
                                creatable
                                placeholder='Select Route'
                                id='route-select'
                                value={
                                  routeId
                                    ? routes.filter(
                                      (route_element) =>
                                        route_element === routeId
                                      )
                                    : ''
                                }
                                comparator='index'
                                optionsKey='id'
                                labelKey='name'
                                rootClassName={classesMain.routeHeaderSelect}
                                options={optionRoutes}
                                onChange={(e) => {
                                  let value
                                  if (e.value) {
                                    value = e.value.index
                                  }
                                  handleChange({ value })
                                  setRoute1(routes[parseInt(value - 1)].id)
                                  history.push(
                                    `/calculation-page/${
                                      routes[parseInt(value - 1)].id
                                    }`
                                  )
                                  setUser({
                                    type: 'add-calc-info',
                                    value: {
                                      routeIndex: value,
                                      routeId: routes[value - 1].id,
                                      routeName: routes[value - 1].routeName
                                    }
                                  })
                                  setRouteId({
                                    index: e.value ? e.value.index : null,
                                    name: e.value ? e.value.name : null
                                  })
                                }}
                              />
  
                              <Typography>
                                This Route does not have results. Please update it
                                or change it.
                              </Typography>
                            </>
                          )
                        }
                      }
                      let value = { index: '', name: '' }
                      if (routes[route - 1]) {
                        value = {
                          index: route,
                          name: routes[route - 1].routeName
                        }
                      }
                      return (
                        <Box className={classesMain.selectContainer}>
                          <Box className={classesMain.autocomplete}>
                            <CustomAutocomplete
                              autoSelect
                              autoHighlight
                              placeholder='Select Route'
                              id='route-select'
                              value={routeId}
                              comparator='index'
                              optionsKey='index'
                              labelKey='name'
                              rootClassName={classesMain.routeHeaderSelect}
                              onChange={(e) => {
                                let value
                                if (e.value) {
                                  value = e.value.index
                                }
                                handleChange({ value })
                                setRoute1(routes[parseInt(value - 1)].id)
                                setUser({
                                  type: 'add-calc-info',
                                  value: {
                                    routeIndex: value,
                                    routeId: routes[value - 1].id,
                                    routeName: routes[value - 1].routeName
                                  }
                                })
                                setInfo({
                                  value: 'checked0',
                                  type: 'change-checked',
                                  targetVessel: 'vessel1',
                                  target: 'speed',
                                  updateCheckFn: () =>
                                    setUpdateCheck(updateCheck + 1)
                                })
                                history.push(
                                  `/calculation-page/${
                                    routes[parseInt(value - 1)].id
                                  }`
                                )
                                history.go(0)
                                setRouteId({
                                  index: e.value ? e.value.index : null,
                                  name: e.value ? e.value.name : null
                                })
                              }}
                              onInputChange={(_e, v) => {
                                setAutoCompleteValue(v)
                              }}
                              disableClearable
                              options={optionRoutes}
                              filterOptions={(x) => {
                                return x
                              }}
                              // routes.map((item, i) => {
                              //   return {
                              //     id: i + 1,
                              //     name: item.routeName
                              //   }
                              // })/>
                            />
                          </Box>
                          <Box>
                            <Button
                              variant='outlined'
                              color='primary'
                              align='center'
                              justify='center'
                              className={classesMain.rootButton}
                              disabled={!info.routeId}
                              onClick={() =>
                                props.history.push(
                                  `/update-route/${info.routeId}`
                                )}
                            >
                              Go To Route
                            </Button>
                          </Box>
                        </Box>
                      )
                    }}
                  </QueryHelper>
                  {retrievedRoute.error && (
                    <Typography
                      className={classesMain.errorClass}
                    >{`${retrievedRoute.error}. Showing outdated Results.`}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            )
          } 
          if (loading) {
            return (
              <LoadingModal
                open={true}
                pageName='Calculation Page'
              />
            )
          }
          return <></>
          // }}
          // </MutationHelper>
        }}
      </QueryHelper>
      <MutationHelper
        mutation={UPDATE_ROUTE_RESULT}
        {...(props.onCompleted
          ? { onCompleted: props.onCompleted }
          : {
            onCompletedObject: {
              title: 'Success',
              message: 'Result Created'
            }
					  })}
        variables={{ input: { data: { ...info, selectedResult } } }}
      >
        {(mutation, result) => {
          calcUpdateMutation = (props) => mutation(props)
          if (result.data) {
            setRouteUpdate(result.data.updateRouteResult.newNumber)
            setUpdate = () => {
              setInfo({
                type: 'update',
                value: result.data.updateRouteResult,
                index: selectedResult,
                targetVessel: info.targetVessel,
                resultType: info.target
              })
            }
          }
          if (!info.id) {
            return null
          }

          const whichResults = (targetVessel) => {
            if (targetVessel === 'vessel1') {
              return {
                data:
									info.results.length > 0
									  ? info.results[selectedResult].portAccurateDistance
									  : undefined,
                avg:
									info.results.length > 0
									  ? info.results[selectedResult]
									  : undefined
              }
            } else if (targetVessel === 'vessel2') {
              return {
                data:
									info.results2.length > 0
									  ? info.results2[selectedResult].portAccurateDistance
									  : undefined,
                avg:
									info.results2.length > 0
									  ? info.results2[selectedResult]
									  : undefined
              }
            } else if (targetVessel === 'vessel3') {
              return {
                data:
									info.results3.length > 0
									  ? info.results3[selectedResult].portAccurateDistance
									  : undefined,
                avg:
									info.results3.length > 0
									  ? info.results3[selectedResult]
									  : undefined
              }
            } else {
              return undefined
            }
          }

          return (
            <Box className={classesMain.root}>
              <Box className={classesMain.firstThirdContainer}>
                <Box>
                  <CargoCalc
                    selectedResult={selectedResult}
                    history={history}
                    calcUpdateMutation={calcUpdateMutation}
                    title='Cargo'
                    info={info}
                    setInfo={setInfo}
                    setUpdateCheck={setUpdateCheck}
                    updateCheck={updateCheck}
                    routes={routes}
                    routeid={info.id}
                    commodities={formatCommodities(commodities)}
                    route={route}
                    adcomValue={adcomValue}
                    setAdcomValue={setAdcomValue}
                    recalc={recalc}
                    setRecalc={setRecalc}
                  />
                  <WeatherMarginCalc
                    data={whichResults(info.targetVessel).data}
                    selectedRoutes={selectedResult}
                    avgs={whichResults(info.targetVessel).avg}
                    info={info}
                    setInfo={setInfo}
                    calcUpdateMutation={calcUpdateMutation}
                    setUpdateCheck={setUpdateCheck}
                    updateCheck={updateCheck}
                    recalc={recalc}
                    setRecalc={setRecalc}
                  />
                </Box>
                <Box>
                  <Box className={classesMain.vesseslContainer}>
                    <VesselContainer
                      title={
                        info.vessels.vessel1.vessel
                          ? info.vessels.vessel1.vessel.vesselName
                          : 'Vessel 1'
                      }
                      index={selectedResult}
                      adcomValue={adcomValue}
                      vessel={info.vessels.vessel1}
                      targetVessel='vessel1'
                      isVessel='vessel1'
                      setInfo={setInfo}
                      info={info}
                      calcUpdateMutation={calcUpdateMutation}
                      vessels={vessels}
                      checks={[
                        info.vessels.vessel1.checked0,
                        info.vessels.vessel1.checked1,
                        info.vessels.vessel1.checked2
                      ]}
                      ports={ports}
                      result={
                        info.results ? info.results[selectedResult] : null
                      }
                      setUpdateCheck={setUpdateCheck}
                      updateCheck={updateCheck}
                      selectedResult={selectedResult}
                      setAdcomValue={setAdcomValue}
                      setRecalc={setRecalc}
                    />
                    <VesselContainer
                      title={
                        info.vessels.vessel2.vessel
                          ? info.vessels.vessel2.vessel.vesselName
                          : 'Vessel 2'
                      }
                      index={selectedResult}
                      adcomValue={adcomValue}
                      vessel={info.vessels.vessel2}
                      targetVessel='vessel2'
                      info={info}
                      isVessel='vessel2'
                      setInfo={setInfo}
                      calcUpdateMutation={calcUpdateMutation}
                      vessels={vessels}
                      ports={ports}
                      checks={[
                        info.vessels.vessel2.checked0,
                        info.vessels.vessel2.checked1,
                        info.vessels.vessel2.checked2
                      ]}
                      result={
                        info.results2 ? info.results2[selectedResult] : null
                      }
                      setUpdateCheck={setUpdateCheck}
                      updateCheck={updateCheck}
                      selectedResult={selectedResult}
                      setAdcomValue={setAdcomValue}
                      setRecalc={setRecalc}
                    />
                    <VesselContainer
                      title={
                        info.vessels.vessel3.vessel
                          ? info.vessels.vessel3.vessel.vesselName
                          : 'Vessel 3'
                      }
                      index={selectedResult}
                      adcomValue={adcomValue}
                      vessel={info.vessels.vessel3}
                      targetVessel='vessel3'
                      info={info}
                      isVessel='vessel3'
                      setInfo={setInfo}
                      calcUpdateMutation={calcUpdateMutation}
                      vessels={vessels}
                      ports={ports}
                      checks={[
                        info.vessels.vessel3.checked0,
                        info.vessels.vessel3.checked1,
                        info.vessels.vessel3.checked2
                      ]}
                      result={
                        info.results3 ? info.results3[selectedResult] : null
                      }
                      setUpdateCheck={setUpdateCheck}
                      updateCheck={updateCheck}
                      selectedResult={selectedResult}
                      setAdcomValue={setAdcomValue}
                      setRecalc={setRecalc}
                    />
                  </Box>
                  <Box>
                    <CalculationResult
                      calcUpdateMutation={calcUpdateMutation}
                      info={info}
                      selectedResult={selectedResult}
                      index={selectedResult}
                      setInfo={setInfo}
                      recalc={recalc}
                      setRecalc={setRecalc}
                    />
                    <RouteResults
                      adcomValue={adcomValue}
                      info={info}
                      selectedResult={selectedResult}
                      setSelectedResult={setSelectedResult}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          )
        }}
      </MutationHelper>
    </>
  )
}

const calcultationResultsStyles = makeStyles((theme) => ({
  card: {
    margin: '0',
    padding: '0'
  },
  total: {
    fontWeight: 'bold'
  },
  totalPortCell: {
    fontWeight: 'bold',
    width: '120px'
  },
  lastChildNoPadding: {
    '&:last-child': { paddingBottom: 0 }
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden'
  },
  redCellWrap: {
    color: 'red'
  },
  extraContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'no-wrap',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  extraElement: {
    // width: '100%',
    // flex: '0 1 33.33%',
    // maxWidth: '35%',
    maxWidth: 350,
    alignSelf: 'auto',
    margin: `${theme.spacing(1)}px 0`,
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(1)}px`,
      '&:first-child': {
        marginLeft: 0
      },
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  normalCell: {
    // fontSize: 15,
    height: 25
  }
}))

const CalculationResult = (props) => {
  const { calcUpdateMutation, index, info, selectedResult, setInfo, setRecalc } = props
  const classes = calcultationResultsStyles()
  const fixedStyles = fixedClasses()
  let result
  if (info.targetVessel === 'vessel1') {
    result = info.results[selectedResult]
  } else if (info.targetVessel === 'vessel2') {
    result = info.results2[selectedResult]
  } else {
    result = info.results3[selectedResult]
  }

  const newPortAccurateArray = []
  const portIndexer = []
  if (result) {
    if (result.portAccurateDistance.deliveryPort.length > 0) {
      newPortAccurateArray.push(...result.portAccurateDistance.deliveryPort)
      // TODO Change this, this is temporary, has to be a way to make this better
      for (
        let index = 0;
        index < result.portAccurateDistance.deliveryPort.length;
        index++
      ) {
        portIndexer.push('Delivery')
      }
    }
    if (result.portAccurateDistance.viaBallast.length > 0) {
      newPortAccurateArray.push(...result.portAccurateDistance.viaBallast)
      for (
        let index = 0;
        index < result.portAccurateDistance.viaBallast.length;
        index++
      ) {
        portIndexer.push('Ballast')
      }
    }
    if (result.portAccurateDistance.loadPort.length > 0) {
      newPortAccurateArray.push(...result.portAccurateDistance.loadPort)
      for (
        let index = 0;
        index < result.portAccurateDistance.loadPort.length;
        index++
      ) {
        portIndexer.push('Load')
      }
    }
    if (result.portAccurateDistance.viaLaden.length > 0) {
      newPortAccurateArray.push(...result.portAccurateDistance.viaLaden)
      for (
        let index = 0;
        index < result.portAccurateDistance.viaLaden.length;
        index++
      ) {
        portIndexer.push('Laden')
      }
    }
    if (result.portAccurateDistance.dischargePort.length > 0) {
      newPortAccurateArray.push(...result.portAccurateDistance.dischargePort)
      for (
        let index = 0;
        index < result.portAccurateDistance.dischargePort.length;
        index++
      ) {
        portIndexer.push('Discharge')
      }
    }
    if (result.portAccurateDistance.viaRedelivery.length > 0) {
      newPortAccurateArray.push(...result.portAccurateDistance.viaRedelivery)
      for (
        let index = 0;
        index < result.portAccurateDistance.viaRedelivery.length;
        index++
      ) {
        portIndexer.push('Via Redelivery')
      }
    }
    if (result.portAccurateDistance.redelivery.length > 0) {
      newPortAccurateArray.push(...result.portAccurateDistance.redelivery)
      for (
        let index = 0;
        index < result.portAccurateDistance.redelivery.length;
        index++
      ) {
        portIndexer.push('Redelivery')
      }
    }
  }

  const getWaitingIdle = (params = {}) => {
    const { sea = 0, load = 0, discharge = 0, total = 0 } = params
    const result = total - sea - load - discharge
    return result < 0.00000001 ? 0 : result
  }

  let grossFreight = '-'
  let netFreight = '-'
  let resultFreight = '-'
  let hire = '-'
  let netHire = '-'

  if (result) {
    netFreight = formatNumber({ number: result.totalCosts })
    grossFreight = formatNumber({
      number: result.totalCosts + result.commission
    })
    resultFreight = formatNumber({ number: result.profit })
    hire = formatNumber({ number: result.dailyHire })
    netHire = formatNumber({ number: result.dailyHire * 0.95, format: '0,0' })
  }

  const theme = useTheme()
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Card className={classes.card}>
        <CardContent className={classes.lastChildNoPadding}>
          <Box className={classes.tableContainer}>
            <Table size='small' aria-label='results'>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell className={classes.normalCell} align='right'>Non Eca Dist</TableCell>
                  <TableCell className={classes.normalCell} align='right'>Eca Dist</TableCell>
                  <TableCell className={classes.normalCell} align='right'>Margin</TableCell>
                  <TableCell className={classes.normalCell} align='right'>Days at Sea</TableCell>
                  <TableCell className={classes.normalCell} align='right'>Month</TableCell>
                  <TableCell className={classes.normalCell} align='right'>Idle / Working</TableCell>
                  <TableCell className={classes.normalCell} align='right'>Days in Port</TableCell>
                  <TableCell className={classes.normalCell} align='right'>Port Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newPortAccurateArray && (
                  <RenderPorts
                    name='Delivery'
                    ports={newPortAccurateArray}
                    portIndexer={portIndexer}
                    info={info}
                    setInfo={setInfo}
                    setRecalc={setRecalc}
                    calcUpdateMutation={calcUpdateMutation}
                    portCosts={result ? result.portCosts : ''}
                    portCostCellProps={{
                      alignRight: true,
                      className: classes.totalPortCell,
                      // onChange: (e) => setInfo({ value: e.target.value, type: 'portCosts', index }),
                      noMargin: true,
                      value: result ? result.portCosts : '',
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Typography>$</Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              </TableBody>
            </Table>
          </Box>
          <Box className={classes.extraContainer}>
            <Box className={classes.extraElement}>
              <Table size='small' aria-label='days-table'>
                <TableHead>
                  <TableRow>
                    <TableCell className={clsx([fixedStyles.fixed100, classes.normalCell])}>
											Duration
                    </TableCell>
                    <TableCell className={classes.normalCell} align='right'>Days</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                    <TableCell>At Sea</TableCell>
                    <TableCell align='right'>{result ? `${formatNumber({ number: result.durationAtSea })}` : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>At Load</TableCell>
                    <TableCell align='right'>
                      {
                        result ? result.durationAtLoad.map((item, i) => (
                          formatNumber({ number: item })
                        )) : ''
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>At Discharge</TableCell>
                    <TableCell align='right'>
                      {
                        result ? result.durationAtDischarge.map((item, i) => (
                          (i !== result.durationAtDischarge.length - 1) ? `${formatNumber({ number: item })}, ` : formatNumber({ number: item })
                        )) : ''
                      }
                    </TableCell> */}
                  {/* </TableRow> */}
                  <TableRow>
                    <TableCell className={clsx([fixedStyles.fixed100, classes.normalCell])}>
											Waiting Idle
                    </TableCell>
                    <TableCell align='right' className={clsx([fixedStyles.fixed100, classes.normalCell])}>
                      {result &&
												`${formatNumber({
													number: getWaitingIdle({
														sea: result.durationAtSea,
														load: result.durationAtLoad[0],
														discharge: result.durationAtDischarge[0],
														total: result.totalDuration
													})
												})}`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={clsx([fixedStyles.fixed100, classes.normalCell])}>
											Total
                    </TableCell>
                    <TableCell align='right' className={clsx([fixedStyles.fixed100, classes.normalCell])}>
                      {result
                        ? `${formatNumber({ number: result.totalDuration })}`
                        : ''}
                    </TableCell>
                  </TableRow>
                </TableBody>
                {/* <TableHead>
                  <TableRow>
                    <TableCell>Port Cost</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        alignRight
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            calcUpdateMutation()
                          }
                        }}
                        onChange={(e) => setInfo({ value: e.target.value, type: 'portCosts', index })}
                        noMargin
                        value={(result) ? result.portCosts : ''}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead> */}
              </Table>
            </Box>

            <Box className={classes.extraElement}>
              <Table size='small' aria-label='days-table'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.normalCell}>Fuel</TableCell>
                    <TableCell className={classes.normalCell} align='right'>MT</TableCell>
                    <TableCell className={classes.normalCell} align='right'>Avg</TableCell>
                    <TableCell className={classes.normalCell} align='right'>Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.normalCell}>FOID</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        // onChange={(e) => setInfo({ value: e.target.value, type: 'fuelFOIDPrice', index })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            calcUpdateMutation()
                          }
                        }}
                        disabled
                        noMargin
                        value={
                          result
                            ? result.fuelFOIDTotal / result.fuelFOIDPrice
                            : ''
                        }
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        disabled
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'fuelFOIDPrice',
                            index,
                            isVessel: info.targetVessel
                          })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'fuelFOIDPrice'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        noMargin
                        value={result ? result.fuelFOIDPrice : ''}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        noMargin
                        disabled
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'fuelFOIDTotal',
                            index,
                            isVessel: info.targetVessel
                          })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'fuelFOIDTotal'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        value={result ? result.fuelFOIDTotal : ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>MGO</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        noMargin
                        disabled
                        // onChange={(e) => setInfo({ value: e.target.value, type: 'fuelMGOPrice', index })}
                        value={
                          result
                            ? result.fuelMGOTotal / result.fuelMGOPrice
                            : ''
                        }
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            calcUpdateMutation()
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        noMargin
                        disabled
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'fuelMGOPrice',
                            index,
                            isVessel: info.targetVessel
                          })}
                        value={result ? result.fuelMGOPrice : ''}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'fuelMGOPrice'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        noMargin
                        disabled
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'fuelMGOTotal',
                            index,
                            isVessel: info.targetVessel
                          })}
                        value={result ? result.fuelMGOTotal : ''}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'fuelMGOTotal'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>Total Cost</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'totalFuel'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        disabled
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'totalFuel',
                            index,
                            isVessel: info.targetVessel
                          })}
                        noMargin
                        value={result ? result.totalFuel : ''}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            {/* **********************************
						 ********* FINANCIALS *************
						 *********************************** */}
            <Box className={classes.extraElement}>
              <Table size='small' aria-label='days-table'>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.normalCell}>Financials</TableCell>
                    <TableCell className={classes.normalCell} align='right'>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    {/* <TableCell>Profits</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        alignRight
                        noMargin
                        onChange={(e) => setInfo({ value: e.target.value, type: 'profit', index })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            calcUpdateMutation()
                          }
                        }}
                        value={(result) ? result.profit : ''}
                      />
                    </TableCell> // ! REPLACED BY GROSS FREIGHT */}
                    <TableCell className={classes.normalCell}>Gross Freight</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        noMargin
                        disabled
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'totalCosts',
                            index
                          })} // TODO UPDATE ON CHANGE
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'totalCosts'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        onBlur={(e) => {
                          if (mobileSize || isMobile) {
                            setInfo({
                              type: 'setTarget',
                              target: 'totalCosts'
                            })
                            setRecalc(true)
                          }
                        }}
                        value={grossFreight}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>Commision</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        noMargin
                        onChange={(e) => {
                          setInfo({
                            value: e.target.value,
                            type: 'resultCommission',
                            index
                          })
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'resultCommission'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        onBlur={(e) => {
                          if (mobileSize || isMobile) {
                            setInfo({
                              type: 'setTarget',
                              target: 'resultCommission'
                            })
                            setRecalc(true)
                          }
                        }}
                        value={result ? result.commission : ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>Net Freight</TableCell>
                    <TableCell className={classes.normalCell} align='right'>
                      {formatNumber({ number: netFreight, format: '0,0' })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>Miscellaneous</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        onChange={(e) =>
                          setInfo({ value: e.target.value, type: 'miscCost' })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'miscCost'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        onBlur={(e) => {
                          if (mobileSize || isMobile) {
                            setInfo({
                              type: 'setTarget',
                              target: 'miscCost'
                            })
                            setRecalc(true)
                          }
                        }}
                        value={info.cargo.miscCost}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>Result</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'profit',
                            index
                          })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'profit'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        onBlur={(e) => {
                          if (mobileSize || isMobile) {
                            setInfo({
                              type: 'setTarget',
                              target: 'profit'
                            })
                            setRecalc(true)
                          }
                        }}
                        value={result ? result.profit : ''}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>TCE Net</TableCell>
                    <TableCell className={classes.normalCell} align='right'>{netHire}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.normalCell}>TCE Gross</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        isInteger
                        alignRight
                        inputProps={{ style: { height: 25 } }}
                        noMargin
                        onChange={(e) =>
                          setInfo({
                            value: e.target.value,
                            type: 'dailyHire',
                            index
                          })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            setInfo({
                              type: 'setTarget',
                              target: 'dailyHire'
                            })
                            setRecalc(true)
                            // calcUpdateMutation();
                          }
                        }}
                        onBlur={(e) => {
                          if (mobileSize || isMobile) {
                            setInfo({
                              type: 'setTarget',
                              target: 'dailyHire'
                            })
                            setRecalc(true)
                          }
                        }}
                        value={result ? result.dailyHire : ''}
                      />
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>TCE</TableCell>
                    <TableCell align='right'>
                      <EditableInputBase
                        alignRight
                        noMargin
                        onChange={(e) => setInfo({ value: e.target.value, type: 'tce', index })}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9 || e.keyCode === 13) {
                            calcUpdateMutation()
                          }
                        }}
                        value={(result) ? result.tce : ''}
                      />
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

const RenderPorts = (props) => {
  const {
    portCostCellProps,
    portCosts,
    ports,
    portIndexer,
    info,
    setInfo,
    calcUpdateMutation,
    results,
    setRecalc
  } = props
  const classes = calcultationResultsStyles()

  let sumOfDist = 0
  let sumOfEca = 0
  const sumOfMiles = 0
  let sumDaysInPort = 0
  let sumDaysAtSea = 0

  const theme = useTheme()
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      {ports.map((item, i, arr) => {
        const port = ports[i - 1]
        if (port) {
          sumOfDist += port.fullDistInNauticalMiles
          sumOfEca += port.ecaDistInNauticalMiles
          // sumOfMiles += port.miles
          sumDaysAtSea += port.accDistance
        }
        let daysInPort
        if (item.daysInPort) {
          sumDaysInPort += item.daysInPort // TODO SUM DAYS IN PORT
          if (item.turnTime) {
            daysInPort = item.daysInPort
          } else {
            daysInPort = item.daysInPort
          }
        }
        return (
          <TableRow key={i}>
            <TableCell className={classes.cellWrap}>
              <Typography
                className={clsx([item.port.distanceOnly && classes.redCellWrap, classes.normalCell])}
              >{`${portIndexer[i]} ${item.port.portName}`}
              </Typography>
            </TableCell>
            {/* <TableCell align='right'>{(i > 0) ? ports[i - 1].miles : '-'}</TableCell> // Removed and replaced by distance */}
            <TableCell className={classes.normalCell} align='right'>
              {item.distance !== -1 && i > 0
                ? formatNumber({
                  number: ports[i - 1].fullDistInNauticalMiles,
                  format: '0,0'
								  })
                : '-'}
            </TableCell>
            <TableCell className={classes.normalCell} align='right'>
              {item.ecaDist !== -1 && i > 0
                ? formatNumber({
                  number: ports[i - 1].ecaDistInNauticalMiles,
                  format: '0,0'
								  })
                : '-'}
            </TableCell>
            <TableCell className={classes.normalCell} align='right'>{`${
							i !== 0 ? `${ports[i - 1].weatherMargin}%` : ''
						} `}
            </TableCell>
            <TableCell className={classes.normalCell} align='right'>
              {item.accDistance !== -1 && i > 0
                ? formatNumber({ number: ports[i - 1].accDistance })
                : '-'}
            </TableCell>
            <TableCell className={classes.normalCell} align='right'>{item.month}</TableCell>
            <TableCell className={classes.normalCell} align='right'>
              {item.working ? 'Working' : 'Idle'}
            </TableCell>
            <TableCell className={classes.normalCell} align='right'>
              {item.daysInPort !== -1
                ? formatNumber({ number: daysInPort })
                : '-'}
            </TableCell>
            {/* <TableCell align='right'>{(item.accDistance !== -1 * (1 + (item.weatherMargin / 100))) ? formatNumber({ number: item.accFullDist }) : '-'}</TableCell>
            <TableCell align='right'>{(item.accDistance !== -1 * (1 + (item.weatherMargin / 100))) ? formatNumber({ number: item.accEcaDist }) : '-'}</TableCell>
            <TableCell align='right'>{(item.accDistance !== -1 * (1 + (item.weatherMargin / 100))) ? formatNumber({ number: item.accDistance }) : '-'}</TableCell> */}
            {/* <TableCell align='right'>{(i > 0) ? formatNumber({ number: ports[i - 1].accFullDist }) : '-'}</TableCell>
            <TableCell align='right'>{(i > 0) ? formatNumber({ number: ports[i - 1].accEcaDist }) : '-'}</TableCell>
            <TableCell align='right'>{(i > 0) ? formatNumber({ number: ports[i - 1].accDistance }) : '-'}</TableCell> */}
            {/* <TableCell align='right'>{item.portCost ? formatNumber({ number: item.portCost, type: 'currency' }) : '-'}</TableCell> */}
            <TableCell align='right'>
              <EditableInputBase
                isInteger
                inputProps={{ style: { height: 25 } }}
                alignRight
                noMargin
                onChange={(e) =>
                  setInfo({
                    value: e.target.value,
                    type: 'portCosts',
                    index: i,
                    item,
                    port: portIndexer[i],
                    isVessel: info.targetVessel
                  })}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'portCosts'
                    })
                    setRecalc(true)
                    // calcUpdateMutation();
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'portCosts'
                    })
                    setRecalc(true)
                  }
                }}
                value={item.portCost}
              />
            </TableCell>
          </TableRow>
        )
      })}
      <TableRow>
        <TableCell className={classes.normalCell}>Total</TableCell>
        <TableCell className={clsx([classes.total, classes.normalCell])} align='right'>
          {formatNumber({ number: sumOfDist, format: '0,0' })}
        </TableCell>
        <TableCell className={clsx([classes.total, classes.normalCell])} align='right'>
          {formatNumber({ number: sumOfEca, format: '0,0' })}
        </TableCell>
        <TableCell />
        <TableCell className={clsx([classes.total, classes.normalCell])} align='right'>
          {formatNumber({ number: sumDaysAtSea, format: '0,0.00' })}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell className={clsx([classes.total, classes.normalCell])} align='right'>
          {formatNumber({ number: sumDaysInPort, format: '0,0.00' })}
        </TableCell>
        <TableCell align='right' className={clsx([classes.total, classes.normalCell])}>
          {formatNumber({ number: portCosts, type: 'currency', format: '0,0' })}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Total Dist</TableCell>
        <TableCell />
        <TableCell className={clsx([classes.total, classes.normalCell])} align='right'>
          {formatNumber({ number: sumOfDist + sumOfEca, format: '0,0' })}
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    </>
  )
}
