import { useState } from 'react'
import { validateWithObject } from './form-validations'
import numeral from 'numeral'
import Swal from 'sweetalert2'
// import { format } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'

// TODO Update Swal style
export const CustomSwal = Swal.mixin({
  scrollbarPadding: false,
  background: '#DADADA',
  showCloseButton: true,
  confirmButtonColor: '#334A5C'
})

/**
 *
 * @param {Object} swalOptions
 * @param {Object} [swalOptions.title]
 * @param {Object} [swalOptions.text]
 */
export const fireSwalError = swalOptions => {
  const {
    title = 'Error',
    text = 'Something went wrong. Please try again later',
    ...rest
  } = swalOptions
  CustomSwal.fire({
    ...rest,
    title,
    text,
    type: 'error'
  })
}

/**
 *
 * @param {Object} swalOptions
 * @param {Object} [swalOptions.title]
 * @param {Object} [swalOptions.text]
 */
export const fireSwalSuccess = swalOptions => {
  const {
    title = 'Success',
    text = 'This action was successfully completed',
    ...rest
  } = swalOptions
  CustomSwal.fire({
    ...rest,
    title,
    text,
    type: 'success'
    // timer: 3000
  })
}

/**
 * @description fucntion to format arrays to show in AgGrid
 *
 */
export const formatArrayForAgGrid = (elem, pos, arr) => {
  if (pos === arr.length - 1) {
    return `${elem}`
  } else {
    return `${elem}, `
  }
}

/**
 *
 * @param {Object} params
 * @param {Date} params.date
 * @param {String} [params.formatString='dd/MM/yyyy']
 */
export const formatDate = ({ date, formatString = 'MM/dd/yyyy' }) => {
  if (!date) {
    return null
  }
  return format(new Date(date), formatString)
}

/**
 *
 * @param {Object} params
 * @param {Number} params.number
 * @param {String} [params.number]
 */
export const formatNumber = ({ number, format = '0,0.00', type }) => {
  if (type === 'currency') {
    format = `$${format}`
  }
  return numeral(number).format(format)
}
/**
 * Function that will take a number in a string format and will round the decimals
 * to the closes multiple of 5 (5 or 0)
 * Ex:
 *  - 29.38 will be 29.40
 *  - 29.33 will be 29.35
 *  - 29.32 will be 29.30
*/
export const formatNumberWithMultiplesOfFive = ({ formattedRate }) => {
  const formattedRateSplitted = formattedRate.split('.')
  const lastTwoDigits = parseInt(`${formattedRateSplitted[1]}`)
  let roundedDecimals = Math.ceil(lastTwoDigits / 5) * 5
  if (formattedRateSplitted[1][0] === '0') {
    if (roundedDecimals === 10) {
      roundedDecimals = `${roundedDecimals}0`
    } else {
      roundedDecimals = `0${roundedDecimals}`
    }
    formattedRate = `${formattedRateSplitted[0]}.${roundedDecimals}`
  } else if (formattedRateSplitted[1][0] === '9') {
    if (parseInt(formattedRateSplitted[1][1]) < 5) {
      formattedRate = `${formattedRateSplitted[0]}.${roundedDecimals}`
    } else {
      formattedRate = `${parseInt(formattedRateSplitted[0]) + 1}.00`
    }
  } else {
    formattedRate = `${formattedRateSplitted[0]}.${roundedDecimals}`
  }

  return {
    formattedRate
  }
}

/**
 * Function that will take a number in a string format and will round the decimals
 * to the closes multiple of 25 (25, 50, 75, 0)
 * Ex:
 *  - 29.38 will be 29.50
 *  - 29.57 will be 29.75
 *  - 29.87 will be 30.00
*/
export const formatNumberWithMultiplesOfTwentyFive = ({ formattedRate }) => {
  const formattedRateSplitted = formattedRate.split('.')
  const lastTwoDigits = parseInt(`${formattedRateSplitted[1]}`)
  const roundedDecimals = Math.ceil(lastTwoDigits / 25) * 25
  const decimals = formattedRateSplitted[1][0]
  // If it needs to sum 1. Ex: 29.87 -> 30.00
  if (roundedDecimals === 100) {
    if (roundedDecimals - lastTwoDigits < 13) {
      formattedRate = `${parseInt(formattedRateSplitted[0]) + 1}.00`
    } else {
      const newRoundedDecimal = roundedDecimals - 25 // Need to substract to be the nearest 25
      formattedRate = `${parseInt(formattedRateSplitted[0])}.${newRoundedDecimal}`
    }
  } else {
    // If difference is greater than 13 then difference is nearest to 25 below
    // Ex: 11.07 -> 11.00; 11.33 -> 11.25
    if (roundedDecimals - lastTwoDigits > 13) {
      const newRoundedDecimal = roundedDecimals - 25 // Need to substract to be the nearest 25
      if (newRoundedDecimal === 0) {
        formattedRate = `${formattedRateSplitted[0]}.00` // If 25 - 25 then add .00
      } else {
        formattedRate = `${formattedRateSplitted[0]}.${newRoundedDecimal}` // Use the nearest low 25
      }
    } else {
      formattedRate = `${formattedRateSplitted[0]}.${roundedDecimals}`
    }
  }
  return {
    formattedRate
  }
}

export const getFormattedUtcDate = ({ date: dateToUpdate, timeZone = 'Europe/Copenhagen', timeFormat = 'HH:mm:ss' }) => {
  let date, zonedTime
  zonedTime = (dateToUpdate && dateToUpdate) ? utcToZonedTime(dateToUpdate, timeZone) : ''
  date = (dateToUpdate && dateToUpdate) ? new Date(dateToUpdate) : ''

  const year = date.toLocaleString('default', { year: 'numeric', timeZone })
  const month = date.toLocaleString('default', { month: 'short', timeZone })
  const day = date.toLocaleString('default', { day: 'numeric', timeZone })

  const timeString = `${format(zonedTime, timeFormat, { timeZone })}`

  return timeString // returns a string
}

/**
 *
 * @param {Object} params
 * @param {Array} params
 */
export const getFormatValues = ({ values, keyname = 'name' }) => {
  const result = []
  values.forEach(element => {
    result.push({ value: element.id, label: element[keyname] })
  })
  return result
}

/**
 * Return the current role value from the key stored in local storage
 */
export const getRoleValue = ({ role }) => {
  let roleName
  switch (role) {
    case 'ksio209':
      roleName = 'owner'
      break
    case 'syuej22':
      roleName = 'admin'
      break
    case 'cliko02':
      roleName = 'client'
      break
    default:
      break
  }

  return roleName
}

/**
 * Returns true if user is admin
 * @return Boolean
 */
export const isAdmin = () => {
  // TODO Implement is admin
  // const rand = localStorage.getItem('rand')
  // return ['ik082', 'lo0jk'].includes(rand)

  return true
}

/**
 *
 * @param {Object} params
 * @param {Array} [params.selectValues]
 * @param {Boolean} [params.clearable=false]
 * @param {Boolean} [params.creatable=false]
 * @param {Boolean} [params.disabled=false]
 * @param {Boolean} [params.select=false]
 * @param {Object} [params.grouped=null]
 * @param {String} [params.initialValue='']
 * @param {String} [params.label='']
 * @param {String} [params.type='text']
 * @param {Boolean} [params.required=false]
 * @param {Boolean} [params.multiline=false]
 */
export const useFormInput = params => {
  const {
    clearable = false,
    creatable = false,
    disabled = false,
    grouped = null,
    initialValue = '',
    isBoolean = false,
    isMulti = false,
    isCalc = false,
    setEditRouteResults,
    label = '',
    multiline = false,
    required = false,
    select = false,
    selectValues,
    type = 'text',
    ...rest
  } = params

  const [value, setValue] = useState(initialValue)
  const [error, setError] = useState(null)

  const handleChange = (e, validation = {}) => {
    if (isMulti) {
      setValue(e)
      // let validation = validate(e.label, e.value)
      let notValid
      if (e && e.length > 0) {
        notValid = validateWithObject({
          fieldName: e,
          value: e,
          validation
        })
        if (notValid) {
          setError(notValid)
        } else {
          setError(null)
        }
      }
    }
    if (e) {
      // if (isMulti) {
      //   setValue(e)
      //   // let validation = validate(e.label, e.value)
      //   let notValid
      //   if (e.length > 0) {
      //     notValid = validateWithObject({
      //       fieldName: e,
      //       value: e,
      //       validation,
      //     })
      //     if (notValid) {
      //       setError(notValid)
      //     } else {
      //       setError(null)
      //     }
      //   }
      // }

      if (e.type && e.type === 'select') {
        setValue(e.value)
        const notValid = validateWithObject({
          fieldName: e.name,
          value: e.value,
          validation
        })
        if (notValid) {
          setError(notValid)
        } else {
          setError(null)
        }
      }

      if (e.target) {
        setValue(e.target.value)
        // let validation = validate(e.target.name, e.target.value)
        const notValid = validateWithObject({
          fieldName: e.target.name,
          value: e.target.value,
          validation
        })
        if (notValid) {
          setError(notValid)
        } else {
          setError(null)
        }
      }
      if (e.label) {
        setValue(e)
        // let validation = validate(e.label, e.value)
        const notValid = validateWithObject({
          fieldName: e.label,
          value: e.value,
          validation
        })
        if (notValid) {
          setError(notValid)
        } else {
          setError(null)
        }
      }
    }
    if (isCalc) {
      setEditRouteResults(true)
    }
  }

  function getValue () {
    if (isMulti && value) {
      value.map(item => {
        console.log(item)
        return item
      })
    }
    if (isBoolean && value) {
      return value.value === 1
    }
    if (value === '') {
      return null
    } else if (!value && value !== 0) {
      return null
    }
    if (type === 'number') {
      return parseFloat(value)
    }
    if (value.label) {
      return value.value
    }
    if (value.id) {
      return value.id
    }
    if (isMulti && value) {
      return value.map(item => {
        return item.id
      })
    }
    return value
  }

  return {
    clearable,
    creatable,
    disabled,
    error: !!error,
    getValue,
    grouped,
    helperText: error || '',
    isMulti,
    label: required ? `${label}*` : label,
    multiline,
    onChange: handleChange,
    select,
    selectValues,
    setError,
    setValue,
    type,
    value,
    ...rest
  }
}
