import React from 'react'
import { withRouter } from 'react-router-dom'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
  summary: {
    width: '300px',
    height: '400px',
    // minHeight: '300px',
    borderRadius: 3 // TODO Find way to do this with theme
  },
  typography: {
    marginTop: '2%',
    marginLeft: '2.5%'
    // padding: theme.spacing(2)
  },
  singleLine: {
    width: '95%',
    fontSize: theme.typography.generalFontSize * 0.6,
    height: '30px',
    marginLeft: '2.5%'
  },
  multiline: {
    margin: 'auto',
    fontSize: theme.typography.generalFontSize * 0.8
  }
}))
const EditSummary = props => {
  const { anchorEl, handleClose, open, setSummary, stem, summary, tolerance } = props
  const classes = useStyles()
  const value = `${stem.value}` + '\n' + `${tolerance.value}` + '\n'// `Intake: ${&nbsp}`
  return (
    <Popover
      id='prob'
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      className={classes.summary}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      PaperProps={{
        className: classes.summary
      }}
    >
      <Typography className={classes.typography}>Summary</Typography>
      {/* {Object.keys(summary).map((item, i) => {
        console.log(item, summary[item])
        const current = summary[item]
        return ( */}
      <TextField
        key={0}
        multiline
        id='standard-basic'
        label='item'
        autoFocus
        className={classes.singleLine}
        onChange={(e) => {
          // setSummary({
          //   ...summary,
          //   [item]: e.target.value
          // })
        }}
        value={value}
      />
      {/* )
      })} */}
    </Popover>
  )
}

export default withRouter(EditSummary)
