import { CREATE_SPOTINDEX_INFORMATION } from '../Queries/Queries'

const breadCrumbs = [
  {
    link: '/spot-indexes',
    name: 'Spot Index'
  }
]
const refetchQueries = [{
  query: CREATE_SPOTINDEX_INFORMATION
}]

export const show = {
  createButton: {
    name: 'Create spot index',
    link: '/create-spot-index'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'indexName', name: 'Name' },
      { key: 'indexValue', name: 'Index value' },
      { key: 'indexAdjustmentAbsolute', name: 'Index adjustment absolute' },
      { key: 'indexAdjustmentPercent', name: 'Index adjustment percent' },
      { key: 'shipSector', name: 'Ship Sector' }
    ],
    rows: 3
  },
  module: {
    name: 'Spot index'
  },
  update: {
    link: '/update-spot-index'
  }
}

export const upsert = {
  module: {
    name: 'Spot Index'
  },
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Spot index created',
      path: '/spot-indexes'
    },
    submitButton: 'Create Spot index'
  },
  emptyObjects: {
    shipSectors: {
      name: 'ship sector',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Spot index updated',
      path: '/spot-indexes'
    },
    submitButton: 'Update Spot Index'
  }
}
