// React Library
import React, { useState, useContext, useReducer, forwardRef, useEffect } from 'react'

// Prop Types
import PropTypes from 'prop-types'

// Mutation Related
import { DELETE_ROUTE } from '../Mutations/Mutations'

// Ag Grid
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

// MAterial UI
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MuiAlert from '@material-ui/lab/Alert'
import Typography from '@material-ui/core/Typography'
import Slide from '@material-ui/core/Slide'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import { Snackbar } from '@material-ui/core'

// Utils
import RootColumnLink from '../Utils/RootColumnLink'
import Summary from '../Summary/ShowSummary'
import { UserContext } from '../App/AppContext'

// Apollo
import { useMutation } from 'react-apollo'

// Styles
import { makeStyles, useTheme } from '@material-ui/core/styles'

// Transition Component For Delete Dialog
const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// Alert For the Error Message
function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

// Styles
const useStyles = makeStyles((theme) => ({
  delete: {
    marginLeft: '5px',
    color: theme.palette.common.red
  },
  dialogContent: {
    width: '350px !important',
    maxHeight: '300px !important'
  },
  dialogButtons: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    margin: '20px'
  },
  cancel: {
    marginRight: '5px',
    color: theme.palette.common.black
  }
}))

const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

// COLUMN DEFS
const columnsDefs = [
  { field: 'routeName', headerName: 'Route Name', cellRenderer: 'link' },
  { field: 'codeNameLong', headerName: 'Long Name' },
  { field: 'vesselSector', headerName: 'Vessel', width: 80 },
  { field: 'forwardCurve', headerName: 'Forward Curve' },
  // { field: 'intake', headerName: 'Intake' },
  // { field: 'governingDraft', headerName: 'Governing Draft' },
  { field: 'deliveryPort', headerName: 'Delivery Port', width: 120, cellRenderer: 'portsDelivery' },
  { field: 'loadCountry', headerName: 'Load Country', width: 120 },
  { field: 'loadPort0', headerName: 'Load Port', width: 120, cellRenderer: 'portsLoad' },
  { field: 'viaBallastPort', headerName: 'Ballast Port', width: 120, cellRenderer: 'portBallast' },
  { field: 'dischargeCountry', headerName: 'Disch Country', width: 120 },
  { field: 'dischargePort0', headerName: 'Disch Port', width: 120, cellRenderer: 'portDisch' },
  // { field: 'loadCountry', headerName: 'Load Country' },
  { field: 'commodity', headerName: 'Commodity' }
]

const editLink = 'update-route'

const ShowRouteAgGrid = props => {
  const {
    handleOpenSummary,
    history,
    routes = [],
    timeCountings
  } = props

  const { setUser } = useContext(UserContext)
  const classes = useStyles()

  const [openSummary, setOpenSummary] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)

  // Hook To Open Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  // The Objects To Be Displayed In The Grid
  const [rowData, setRowData] = useState(routes)

  // The Array Of Deleted Objects
  const [deletedObjectArray, setDeletedObjectArray] = useState([])

  // The Current Object
  const [currentRoute, setCurrentRoute] = useState({})

  // When Filtered Array Changes (Trie-Search)
  useEffect(() => {
    if (routes) {
      // Show The Filtered Objects Minus The Deleted Ones
      setRowData((prev) => {
        return routes.filter(item => !deletedObjectArray.includes(item.id))
      })
    }
  }, [routes])

  /*
  CONTEXT MENU STATES
  */
  const [contextState, setContextState] = useReducer(contextReducer, initialState)
  const [route, setRoute] = useState()

  const handleClose = () => {
    setContextState({
      type: 'close'
    })
  }

  const handleClick = (event, params) => {
    event.preventDefault()
    setContextState({
      event,
      selectedRow: params,
      type: 'open'
    })
    setCurrentRoute(params)
  }

  // Handle Click To Delete Row
  const handleClickDelete = (props) => {
    setOpenDeleteDialog(true)
  }

  // Handle Close Dialog
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false)
  }

  /*
  CELL RENDERER WITH CONTEXT MENU
  */
  const ContextMenuCell = props => {
    const { value } = props
    // return <div>{`Renderer 1: ${value}`}</div>
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }
    return (
      <div onContextMenu={handleClickCell}>
        {value}
      </div>
    )
  }

  const LinkWithContextMenu = props => {
    const { value } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }
    return (
      <div onContextMenu={handleClickCell}>
        <RootColumnLink viewLink={`${editLink}/${props.data.id}`} value={value} row={props.data} />
      </div>
    )
  }

  const PortBallastDistanceOnly = props => {
    const { value } = props
    console.log(props)
    return (
      <div className={props.data.viaBallastePortDistanceOnly && classes.distanceOnly}>{value}</div>
    )
  }

  const PortDischargeDistanceOnly = props => {
    const { value } = props
    console.log(props)
    return (
      <div className={props.data.dischargePortDistanceOnly0 && classes.distanceOnly}>{value}</div>
    )
  }

  const PortDeliveryDistanceOnly = props => {
    const { value } = props
    console.log(props)
    return (
      <div className={props.data.deliveryDistanceOnly && classes.distanceOnly}>{value}</div>
    )
  }

  const PortLoadDistanceOnly = props => {
    const { value } = props
    console.log(props)
    return (
      <div className={props.data.loadPortDistanceOnly0 && classes.distanceOnly}>{value}</div>
    )
  }

  const gridOptions = {
    columnDefs: columnsDefs.map(c => { return { ...c, cellRenderer: c.cellRenderer ? c.cellRenderer : 'contextCell' } }),
    defaultColDef: {
      filter: 'agTextColumnFilter',
      resizable: true,
      floatingFilter: false,
      sortable: true,
      debounceVerticalScrollbar: true,
      debounceHorizontalScrollbar: true,
      flex: 1,
      minWidth: 150
    }
  }

  function onGridReady (params) {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  // Menu items handle clicks
  const handleGoToCalcClick = (props) => {
    history.push(`/calculation-page/${contextState.selectedRow.id}`)
    setContextState({
      type: 'close'
    })
  }

  const handleGoToSummary = (props) => {
    setRoute(contextState.selectedRow)
    setContextState({
      type: 'close'
    })
    setOpenSummary(true)
  }

  const handleGoToOffer = (props) => {
    setUser({ type: 'voyage-offer', value: true, voyageRoute: contextState.selectedRow.id })
    setContextState({
      type: 'close'
    })
  }

  const handleCloseSummary = () => {
    setAnchorEl(null)
    setOpenSummary(false)
    setRoute(null)
    setContextState({
      type: 'close'
    })
  }

  return (
    <>
      <div className='ag-theme-balham' style={{ height: 600, width: '100%' }}>
        <AgGridReact
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          animateRows
          rowData={rowData}
          rowBuffer={50}
          frameworkComponents={{
            contextCell: ContextMenuCell,
            link: LinkWithContextMenu,
            portsDelivery: PortDeliveryDistanceOnly,
            portsLoad: PortLoadDistanceOnly,
            portDisch: PortDischargeDistanceOnly,
            portBallast: PortBallastDistanceOnly
          }}
        />
      </div>
      <Menu
        keepMounted
        open={contextState.mouseY !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleGoToCalcClick}>Go to calc</MenuItem>
        <MenuItem onClick={handleGoToOffer}>Voyage Offer</MenuItem>
        <MenuItem onClick={handleClickDelete}>Delete Route</MenuItem>
      </Menu>

      {/* Popup Before Deleting*/}
      <DeleteObjectDialog
        closeDialog={handleCloseDialog}
        closeMenu={handleClose}
        mutation={DELETE_ROUTE}
        object={currentRoute}
        objectNameKey1='routeName'
        open={openDeleteDialog}
        setRowData={setRowData}
        setDeletedObjectArray={setDeletedObjectArray}
      />
    </>
  )
}

const DeleteObjectDialog = props => {
  const {
    closeDialog,
    closeMenu,
    mutation,
    object,
    objectNameKey1,
    objectNameKey2,
    open,
    setRowData,
    setDeletedObjectArray
  } = props

  const classes = useStyles()

  // Delete Error
  const [snackBarMessage, setSnackBarMessage] = useState({ open: false, message: '', severity: ''})

  // Delete Object Mutation Hook #MoveBack
  const [deleteObject] = useMutation(mutation, {

    onError (error) {
      // The Error From The Server
      const errorString = error?.message?.split(':')[1]

      // The Only Acceptable Errors...
      if (errorString.includes('Routes have errors') || errorString.includes('Route has errors')) {

        // Set Message For Error Snack Bar
        setSnackBarMessage({
          open: true,
          message: `${errorString} But Route was deleted succesfully!`,
          severity: 'error'
        })

        // Remove Object From The Grid
        setRowData((prev) => prev.filter(item => item.id !== object.id))

        // Save It As Deleted Object
        setDeletedObjectArray((prev) => {
          const arr = prev
          arr.push(object.id)
          return arr
        })

      } else {

        setSnackBarMessage({
          open: true,
          message: errorString,
          severity: 'error'
        })

      }
    },

    onCompleted () {
      
      // Remove Object From The Grid
      setRowData((prev) => prev.filter(item => item.id !== object.id))

      // Save It As Deleted Object
      setDeletedObjectArray((prev) => {
        const arr = prev
        arr.push(object.id)
        return arr
      })

      setSnackBarMessage({
        open: true,
        message: `Route deleted succesfully!`,
        severity: 'success'
      })

    }
  })


  // Close Both Dialog and Menu
  const closeDialogAndMenu = () => {
    closeMenu()
    closeDialog()
  }

  // Handle Delete Object
  const handleDelete = () => {
    // Delete Object Mutation (See onError & onCompleted)
    deleteObject({
      variables: {
        input: {
          id: object.id
        }
      }
    })

    // Close Popups
    closeDialogAndMenu()
  }

  // Render Dialog
  if (object) {
    return (
      <Grid>
        <Dialog
          TransitionComponent={Transition}
          aria-labelledby='delete-object-dialog'
          onClose={closeDialogAndMenu}
          open={open}
        >
          <Grid className={classes.dialogContent}>
            {/* Feedback Message */}
            <DialogTitle>{`You're about to delete Route "${object[objectNameKey1]}${(!objectNameKey2) ? '"' : ''}${(objectNameKey2) ? ` - ${object[objectNameKey2]}"` : ''}. How to proceed?`}</DialogTitle>

            {/* Buttons */}
            <Grid className={classes.dialogButtons}>
              <Button variant='outlined' className={classes.cancel} onClick={closeDialogAndMenu}>Cancel</Button>
              <Button variant='outlined' className={classes.delete} onClick={handleDelete}>Delete it</Button>
            </Grid>

          </Grid>
        </Dialog>

        {/* Error Snack Bar */}
        <Snackbar open={snackBarMessage.open} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })} variant='error'>
          <Alert severity={snackBarMessage.severity} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </Grid>
    )
  } else {
    // Prevents Bug...
    return (
      <>
      </>
    )
  }
}


ShowRouteAgGrid.propTypes = {
  history: PropTypes.object.isRequired,
  rowData: PropTypes.array.isRequired
}

export default ShowRouteAgGrid
