// React Library
import React, { useState, useRef, useEffect } from 'react'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Content
import { show as content } from './SpotIndexesContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_SPOTINDEXES } from '../Queries/Queries'

// Mutation Related
import { DELETE_SPOTINDEX } from '../Mutations/Mutations'

// Material UI Components
import AddIconButton from '../Utils/AddIconButton'
import SearchTextInput from '../Utils/SearchTextInput'
import RootGrid from '../Utils/RootGrid'

// Trie Search Algorithm
import TrieSearch from 'trie-search'

// Utils
import RootAgGrid from '../Utils/RootAgGrid'

// Query Component
const ShowSpotIndexes = props => {
  return (
    <>
      <Helmet>
        <title>Spot Index</title>
      </Helmet>
      <QueryHelper
        query={GET_SPOTINDEXES}
      >
        {({ data }) => {
          const { getSpotIndexes: { spotIndexes } } = data

          // Some Formatting...
          const spotIndexesArray = spotIndexes.map(spotIndex => {
            return {
              ...spotIndex,
              shipSector: spotIndex.shipSector ? spotIndex.shipSector.shipSectorName : '-'
            }
          })

          // Render
          return (
            <ShowSpotIndexesTable
              indexes={spotIndexesArray}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowSpotIndexesTable = (props) => {
  const { indexes = [] } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchIndex, setSearchIndex] = useState(indexes)

  // Trie-Search Too Look For The Following Fields...
  const ts = new TrieSearch(['indexName', 'shipSector'], {
    idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  })

  // The Array To Be Filtered (All Spot Indexes In the DB)
  ts.addAll(indexes)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchIndex(indexes)
      } else {
        const searchArray = search.split(',')
        const foundIndexes = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setSearchIndex(foundIndexes)
      }
    }
  }, [search, indexes, ts])

  // Column Structure
  const columnDefs = [
    { field: 'indexName', headerName: 'Index Name', cellRenderer: 'link' },
    { field: 'indexValue', headerName: 'Index Value' },
    { field: 'indexAdjustmentAbsolute', headerName: 'Index Adjustment Absolute' },
    { field: 'indexAdjustmentPercent', headerName: 'Index Adjustment Percent' },
    { field: 'shipSector', headerName: 'Ship Sector' }
  ]

  // Link Of The Update Spot Index Page
  const updateLink = 'update-spot-index'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>
      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Spot Indexes Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={searchIndex}
        mutation={DELETE_SPOTINDEX}
        objectNameKey1='indexName'
        type='Spot Index'
        updateLink={updateLink}
      />
    </RootGrid>
  )
}

export default withRouter(ShowSpotIndexes)
