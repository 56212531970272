import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// Helmet
import Helmet from 'react-helmet'
// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './ShipSectorsContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_SHIPSECTOR } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_SHIPSECTOR } from '../Mutations/Mutations'
// Utils
import {
  bwRequired,
  dwtMaxRequired,
  dwtMinRequired,
  tfRequired,
  fwRequired,
  nameRequired,
  swRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import TextError from '../Utils/TextError'

// Material UI
import { makeStyles } from '@material-ui/core/styles'

const UpsertShipSector = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.shipSector
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={GET_SHIPSECTOR}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let shipSector = null
        if (data && data.getShipSector && data.getShipSector.shipSector) {
          shipSector = data.getShipSector.shipSector
        }
        return (
          <>
            {
              shipSector
                ? <Helmet><title>Update Ship Sector</title></Helmet>
                : <Helmet><title>Create Ship Sector</title></Helmet>
            }
            <CreateShipSector
              {...rest}
              shipSector={shipSector}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertShipSector.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

export default withRouter(UpsertShipSector)

const CreateShipSector = props => {
  const { refetchQueries, shipSector } = props

  const classes = useStyles()

  const { user } = useContext(UserContext)
  const [editRouteResults, setEditRouteResults] = useState(false)

  const content = shipSector ? upsertContent.update : upsertContent.create

  const [minMaxError, setMinMaxError] = useState(false)

  const validation = {
    bw: bwRequired,
    dwtMax: dwtMaxRequired,
    dwtMin: dwtMinRequired,
    tf: tfRequired,
    fw: fwRequired,
    shipSectorName: nameRequired,
    sw: swRequired
  }

  const initialValues = {
    shipSectorName: shipSector ? shipSector.shipSectorName : '',
    dwtMin: shipSector ? shipSector.dwtMin : '',
    dwtMax: shipSector ? shipSector.dwtMax : '',
    sw: shipSector ? shipSector.sw : '',
    bw: shipSector ? shipSector.bw : '',
    fw: shipSector ? shipSector.fw : '',
    tf: shipSector ? shipSector.tf : ''
  }

  const form = {
    shipSectorName: useFormInput({ initialValue: initialValues.shipSectorName, label: 'shipSectorName', required: true }),
    dwtMin: useFormInput({ initialValue: initialValues.dwtMin, label: 'dwtMin', required: true, type: 'number', setEditRouteResults, isCalc: true }),
    dwtMax: useFormInput({ initialValue: initialValues.dwtMax, label: 'dwtMax', required: true, type: 'number', setEditRouteResults, isCalc: true }),
    sw: useFormInput({ initialValue: initialValues.sw, label: 'sw', required: true, type: 'number', setEditRouteResults, isCalc: true }),
    bw: useFormInput({ initialValue: initialValues.bw, label: 'bw', required: true, type: 'number', setEditRouteResults, isCalc: true }),
    fw: useFormInput({ initialValue: initialValues.fw, label: 'fw', required: true, type: 'number', setEditRouteResults, isCalc: true }),
    tf: useFormInput({ initialValue: initialValues.tf, label: 'tf', required: true, type: 'number', setEditRouteResults, isCalc: true })
  }

  const input = {
    ...(shipSector && { id: shipSector.id }),
    shipSectorName: form.shipSectorName.getValue(),
    dwtMin: parseFloat(form.dwtMin.getValue()),
    dwtMax: parseFloat(form.dwtMax.getValue()),
    sw: parseFloat(form.sw.getValue()),
    bw: parseFloat(form.bw.getValue()),
    fw: parseFloat(form.fw.getValue()),
    tf: parseFloat(form.tf.getValue()),
    editRouteResults
  }

  const next = ({ mutation }) => {
    const minDwt = form.dwtMin.getValue()
    const maxDwt = form.dwtMax.getValue()
    if (minDwt && maxDwt) {
      if (minDwt >= maxDwt) {
        setMinMaxError(true)
        return
      }
    }
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_SHIPSECTOR}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.paper} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <RootForm
                form={form}
                validation={validation}
              />
              {
                minMaxError &&
                  <TextError
                    error='* The maximum dead weight should have a higher value than the minimum dead weight'
                  />
              }
              <RootButton className={classes.button} disabled={result.loading || user.disabled} justifyContent='center' onClick={() => next({ mutation })}>
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateShipSector.propTypes = {
  shipSector: PropTypes.object
}
