import React from 'react'
import { Redirect } from 'react-router-dom'
// Content
import content from './RegisterContent'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { REGISTER } from '../Mutations/Mutations'
// Utils
import { AUTH_TOKEN } from '../../constants'
import {
  emailRequired,
  nameRequired,
  passwordRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'

const Register = _props => {

  const validation = {
    email: emailRequired,
    name: nameRequired,
    password: passwordRequired,
    repeatPassword: passwordRequired,
  }

  const form = {
    name: useFormInput({ label: 'Name', required: true }),
    email: useFormInput({ label: 'Email', required: true, type: 'email'}),
    password: useFormInput({ label: 'Password', required: true, type: 'password' }),
    repeatPassword: useFormInput({ label: 'Repeat Password', required: true, type: 'password' }),
  }

  const input = {
    name: form.name.getValue(),
    email: form.email.getValue(),
    password: form.password.getValue(),
  }

  const next = ({ mutation }) => {
    let valid = true
    if (form.password.getValue() !== form.repeatPassword.getValue()) {
      form.repeatPassword.setError(content.passwordsNotMatch)
      valid = false
    }
    if (valid) {
      validateStep({
        form,
        next: () => mutation(),
        validation
      })
    }
  }

  const authToken = localStorage.getItem(AUTH_TOKEN)
  if (authToken) {
    return (
      <Redirect
        to={{
          pathname: '/'
        }}
      />
    )
  }

  return (
    <MutationHelper
      mutation={REGISTER}
      onCompletedObject={content.onCompleted}
      variables={{ input }}
    >
      {( mutation, result ) => {
        return (
          <RootGrid>
            <RootPaper smallForm>
              <RootForm form={form} validation={validation} />
              <RootButton
                disabled={result.loading}
                justifyContent='center'
                onClick={() => next({ mutation })}
              >
                { content.addButton }
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

export default Register

