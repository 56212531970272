import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import FilledInput from '@material-ui/core/FilledInput';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ClearIcon from '@material-ui/icons/Clear';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import { useSearchTextInputStyles } from './SearchTextInputStyles';
import { Grid } from 'react-virtualized';
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	margin: {
		// margin: theme.spacing(1)
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: '50ch',
	},
	input: {
		paddingLeft: '8px',
		background: '#fff',
		// borderRadius: 0,
		'&:after': {
			border: 'light',
		},
		'&:before': {
			border: 'light',
		},
		'&:hover::before': {
			border: 'light',
		},
		'&:hover': {
			background: '#F5F5F5',
		},
	},
	container: {
		display: 'flex',
		flexDirection: 'column-reverse',
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.up('md')]: {
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'flex-start',
		},
	},
}));

const SearchTextInput = (props) => {
	const { children, value, onChange, autoFocus } = props;
	const classes = useStyles();
	const onClear = (props) => {
		onChange({
			target: {
				value: '',
			},
		});
	};
	return (
		<Box className={classes.container}>
			<FormControl
				className={clsx(classes.margin, classes.textField)}
				variant='filled'
			>
				{/* <InputLabel htmlFor='search-text-input'>Search</InputLabel> */}
				<CustomInput
					value={value}
					onChange={onChange}
					onClear={onClear}
					placeholder='Search'
				>
					{children}
				</CustomInput>
			</FormControl>
		</Box>
	);
};

const CustomInput = ({ value, onChange, placeholder, onClear, children }) => {
	const classes = useSearchTextInputStyles();

	return (
		<Box className={classes.container}>
			<Paper component='form' className={classes.root} elevation={0}>
				<IconButton
					disabled={value === ''}
					onClick={value !== '' && onClear}
					className={classes.iconButton}
					aria-label='search'
				>
					{value ? <ClearIcon /> : <SearchIcon />}
				</IconButton>
				<Divider className={classes.divider} orientation='vertical' />
				<InputBase
					onChange={onChange}
					value={value}
					className={classes.input}
					placeholder={placeholder}
					inputProps={{ 'aria-label': { placeholder } }}
				/>
			</Paper>
			{children}
		</Box>
	);
};

CustomInput.propTypes = {
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	onClear: PropTypes.func,
	children: PropTypes.object,
};

SearchTextInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
};

export default SearchTextInput;
