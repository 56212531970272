import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Clear from '@material-ui/icons/Clear'
import Download from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}))

const RootFileInput = props => {
  const classes = useStyles()
  const {
    file,
    fileLink,
    filename,
    index,
    readOnly,
    setError,
    setDeleted,
    setFile,
    setFilename,
  } = props


  const validFile = file => {
    let flag = true
    const fileSize = file.size / 1024 / 1024
    if (fileSize > 2) {
      flag = false
      setError('El archivo tiene que ser menor de 10mb')
      return null
    }
    if (file.name.length > 500) {
      flag = false
      setError('El nombre del archivo tiene que ser menor de 500 caracteres')
      return null
    }
    let blob = file
    let fileReader = new FileReader()
    fileReader.onloadend = function(e) {
      // let arr = (new Uint8Array(e.target.result)).subarray(0, 4)
      let flag = true
      // for(let i = 0; i < arr.length; i++) {
      //   header += arr[i].toString(16)
      // }

      // switch (header) {
      //   case "89504e47":
      //       flag = true
      //       break;
      //   case "47494638":
      //       flag = true
      //       break;
      //   case "ffd8ffe0":
      //   case "ffd8ffe1":
      //   case "ffd8ffe2":
      //   case "ffd8ffe3":
      //   case "ffd8ffe8":
      //       flag = true
      //       break;
      //   default:
      //       flag = false
      //       break;
      // }
      if (!flag) {
        setError('Solo puedes seleccionar imagenes para subir.')
        return null
      }
    }
    if (flag) {
      setFile(file)
      setError(null)
    }
    fileReader.readAsArrayBuffer(blob)
  }
  const removeFile = () => {
    setFilename(null)
    setDeleted(true)
  }

  if (readOnly) {
    if (filename) {
      return (
        <Box display='flex' alignItems='center'>
          <Typography>{filename}</Typography>
          <IconButton href={fileLink}>
            <Download />
          </IconButton>
        </Box>
      )
    }
    return null
  }

  if (filename) {
    return (
      <Box display='flex' alignItems='center'>
        <Typography>{filename}</Typography>
        <IconButton onClick={() => removeFile()}>
          <Clear />
        </IconButton>
      </Box>
    )
  }

  if (file) {
    return (
      <Box display='flex' alignItems='center'>
        <Typography>{file.name}</Typography>
        <IconButton onClick={() => setFile(null)}>
          <Clear />
        </IconButton>
      </Box>
    )
  }
  return (
    <div>
      <input
        accept='.xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv'
        className={classes.input}
        id={`file-${index}`}
        multiple
        onChange={event => validFile(event.target.files[0])}
        type='file'
        value={file || ''}
      />
      <label htmlFor={`file-${index}`}>
        <Button variant='contained' component='span'>
          Choose a File
        </Button>
      </label>
    </div>
  )
}

RootFileInput.defaultProps = {
  readOnly: false
}

RootFileInput.propTypes = {
  file: PropTypes.object,
  fileLink: PropTypes.string,
  filename: PropTypes.string,
  readOnly: PropTypes.bool,
  setError: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  setFilename: PropTypes.func.isRequired,
}

export default RootFileInput
