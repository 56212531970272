import React from 'react'
import PropTypes from 'prop-types'
// Material Ui
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
// Styles
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  title: {
    alignSelf: 'center'
  },
  contentText: {
    paddingBottom: '5%'
  }
}))

const LoadingModal = props => {
  const classes = useStyles()
  const { open, pageName } = props
  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle className={classes.title}>{`Loading ${pageName}...`}</DialogTitle>
      <DialogContent>
        <Grid container direction='column' alignItems='center'>
          <CircularProgress/>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

LoadingModal.defaultProps = {
  open: false
}

LoadingModal.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default LoadingModal