// React Library
import React, { useState, useRef, useEffect } from 'react'

// Helmet
import Helmet from 'react-helmet'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Content
import { show as content } from './VesselsContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_VESSELS } from '../Queries/Queries'

// Mutation Related
import { DELETE_VESSEL } from '../Mutations/Mutations'

// Utils
import AddIconButton from '../Utils/AddIconButton'
import { formatNumber } from '../Utils/utils'
import RootGrid from '../Utils/RootGrid'
import RootAgGrid from '../Utils/RootAgGrid'
import SearchTextInput from '../Utils/SearchTextInput'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Query Component
const ShowVessels = (props) => {
  return (
    <>
      <Helmet>
        <title>Vessels</title>
      </Helmet>
      <QueryHelper query={GET_VESSELS}>
        {({ data }) => {
          const {
            getVessels: { vessels }
          } = data

          // Some Formatting...
          const vesselsArray = vessels.map((vessel) => {
            return {
              ...vessel,
              cubicFT: formatNumber({ number: vessel.cubicFT, format: '0,0' }),
              dwt: formatNumber({ number: vessel.dwt, format: '0,0' }),
              ballastSpeedArray:
                vessel.ballastSpeedArray.length > 0
                  ? vessel.ballastSpeedArray[0]
                  : '-',
              ballastIFOConsumption:
                vessel.ballastIFOConsumption.length > 0
                  ? vessel.ballastIFOConsumption[0]
                  : '-',
              ballastMGOConsumption:
                vessel.ballastMGOConsumption.length > 0
                  ? vessel.ballastMGOConsumption[0]
                  : '-',
              ladenSpeedArray:
                vessel.ladenSpeedArray.length > 0
                  ? vessel.ladenSpeedArray[0]
                  : '-',
              ladenIFOConsumption:
                vessel.ladenIFOConsumption.length > 0
                  ? vessel.ladenIFOConsumption[0]
                  : '-',
              ladenMGOConsumption:
                vessel.ladenMGOConsumption.length > 0
                  ? vessel.ladenMGOConsumption[0]
                  : '-'
            }
          })

          // Render
          return (
            <ShowVesselsTable
              vessels={vesselsArray}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowVesselsTable = props => {
  const { vessels = [] } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchVessel, setSearchVessel] = useState(vessels)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['vesselName', 'yearBuilt', 'yard', 'IMONumber'], {
    idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  })

  // The Array To Be Filtered (All Vessels In the DB)
  ts.addAll(vessels)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchVessel(vessels)
      } else {
        const searchArray = search.split(',')
        const foundVessels = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setSearchVessel(foundVessels)
      }
    }
  }, [search, vessels, ts])

  // Column Structure
  const columnDefs = [
    { field: 'vesselName', headerName: 'Vessel', cellRenderer: 'link' },
    { field: 'dwt', headerName: 'Dead Weight' },
    { field: 'draft', headerName: 'Draft' },
    { field: 'tpc', headerName: 'TPC' },
    { field: 'cubicFT', headerName: 'Cubic Feet' },
    { field: 'yard', headerName: 'Yard' },
    { field: 'yearBuilt', headerName: 'Built' },
    { field: 'ballastSpeedArray', headerName: 'Ballast Speed' },
    { field: 'ballastIFOConsumption', headerName: 'Ballast IFO Consumption' },
    { field: 'ballastMGOConsumption', headerName: 'Ballast MGO Consumption' },
    { field: 'ladenSpeedArray', headerName: 'Laden Speed' },
    { field: 'ladenIFOConsumption', headerName: 'Laden IFO Consumption' },
    { field: 'ladenMGOConsumption', headerName: 'Laden MGO Consumption' }
  ]

  // Link Of The Update Vessel Page
  const updateLink = 'update-vessel'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>

      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Vessels Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={searchVessel}
        mutation={DELETE_VESSEL}
        objectNameKey1='vesselName'
        type='Vessel'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowVessels)
