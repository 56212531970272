import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// Helmet
import Helmet from 'react-helmet'
// Content
import { upsert as upsertContent } from './CargoWorkingsContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { CREATE_CARGOWORKING_INFORMATION, GET_CARGOWORKING } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_CARGOWORKING } from '../Mutations/Mutations'
// Utils
import {
  statusRequired,
  accountRequired,
  routeRequired,
  adcomRequired,
  monthPositionDateRequired,
  bidRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'
import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import Box from '@material-ui/core/Box'
import RootAutocomplete from '../Utils/RootAutocomplete'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { set } from 'react-ga'
import creatable from 'react-select/creatable'

const privacyReducer = (state, action) => {
  switch (action.type) {
    case 'privacy-type': {
      return {
        ...state,
        semiPrivs: [],
        semiPubs: [],
        type: action.value.id
      }
    }
    case 'pick-admin-semi-priv': {
      const result = { ...state }
      if (action.value === false) {
        result.semiPrivs = []
        for (let i = 0; i < state.semiPrivs.length; i++) {
          if (state.semiPrivs[i].admin !== action.admin.id) {
            result.semiPrivs.push({
              ...state.semiPrivs[i]
            })
          }
        }
      } else {
        result.semiPrivs.push({
          admin: action.admin.id,
          owners: []
        })
      }
      return {
        ...result
      }
    }
    case 'pick-admin-semi-pub': {
      const result = { ...state }

      if (action.value === false) {
        result.semiPubs = []
        for (let i = 0; i < state.semiPubs.length; i++) {
          if (state.semiPubs[i] !== action.admin.id) {
            result.semiPubs.push(state.semiPubs[i])
          }
        }
      } else {
        result.semiPubs.push(action.admin.id)
      }

      return result
    }
    case 'pick-owner-semi-priv': {
      const result = { ...state }
      if (action.value === false) {
        for (let i = 0; i < state.semiPrivs.length; i++) {
          if (state.semiPrivs[i].admin === action.admin.id) {
            const owners = []
            for (let j = 0; j < state.semiPrivs[i].owners.length; j++) {
              if (state.semiPrivs[i].owners[j].id !== action.owner.id) {
                owners.push({ ...state.semiPrivs[i].owners[j] })
              }
            }
            result.semiPrivs[i].owners = owners
            // for (let j = 0; j < state.semiPrivs.owners.length; j++) {
            //   if (condition) {

            //   }
            // }
          }
        }
      } else {
        for (let i = 0; i < state.semiPrivs.length; i++) {
          if (state.semiPrivs[i].admin === action.admin.id) {
            result.semiPrivs[i].owners.push(action.owner)
          }
        }
      }

      return {
        ...result
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

const CreateCargoWorkingQueryHelper = props => {
  const { ...rest } = props
  return (
    <QueryHelper
      query={CREATE_CARGOWORKING_INFORMATION}
    >
      {({ data }) => {
        const { createCargoWorkingInformation } = data
        if (createCargoWorkingInformation) {
          const routes = []
          for (let index = 0; index < createCargoWorkingInformation.routes.length; index++) {
            routes.push(
              {
                id: createCargoWorkingInformation.routes[index].id,
                name: createCargoWorkingInformation.routes[index].routeName
              }
            )
          }
          const positionDates = []
          for (let index = 0; index < createCargoWorkingInformation.positionDates.length; index++) {
            positionDates.push(
              {
                id: createCargoWorkingInformation.positionDates[index].id,
                name: createCargoWorkingInformation.positionDates[index].positionDateName
              }
            )
          }
          const owners = []
          for (let index = 0; index < createCargoWorkingInformation.owners.length; index++) {
            owners.push(
              {
                id: createCargoWorkingInformation.owners[index].id,
                name: createCargoWorkingInformation.owners[index].ownerName
              }
            )
          }
          return (
            <UpsertCargoWorkingWithRouter
              admins={createCargoWorkingInformation.admins}
              routes={routes}
              owners={owners}
              positionDates={positionDates}
              privacyTypes={createCargoWorkingInformation.privacyTypes}
              users={createCargoWorkingInformation.users}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}

CreateCargoWorkingQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default CreateCargoWorkingQueryHelper

const UpsertCargoWorking = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.cargoWorking
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={GET_CARGOWORKING}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let cargoWorking = null
        if (data && data.getCargoWorking) {
          cargoWorking = data.getCargoWorking.cargoWorking
        }
        return (
          <>
            {
              cargoWorking
                ? <Helmet><title>Update Cargo Working</title></Helmet>
                : <Helmet><title>Create Cargo Working</title></Helmet>
            }
            <CreateCargoWorking
              {...rest}
              cargoWorking={cargoWorking}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertCargoWorking.propTypes = {
  users: PropTypes.array.isRequired,
  routes: PropTypes.array.isRequired,
  positionDates: PropTypes.array.isRequired,
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const UpsertCargoWorkingWithRouter = withRouter(UpsertCargoWorking)

const getPrivacySemiPubAdmin = (privacy, adminId) => {
  for (let i = 0; i < privacy.semiPubs.length; i++) {
    if (privacy.semiPubs[i] === adminId) {
      return true
    }
  }
  return false
}

const SemiPubComponent = props => {
  const {
    admins,
    privacy,
    setPrivacy
  } = props

  return (
    <>
      <List>
        {admins.map((item, i) => {
          return (
            <ListItem key={i} role={undefined} dense button onClick={setPrivacy}>
              <ListItemIcon>
                <Checkbox
                  edge='start'
                  checked={getPrivacySemiPubAdmin(privacy, item.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': item.id }}
                  onClick={(e) => {
                    setPrivacy({ type: 'pick-admin-semi-pub', value: e.target.checked, admin: item })
                  }}
                />
              </ListItemIcon>
              <ListItemText id={item.id} primary={item.name} />
            </ListItem>
          )
        })}
      </List>
    </>
  )
}

const getPrivacySemiPriAdmin = (privacy, adminId) => {
  for (let i = 0; i < privacy.semiPrivs.length; i++) {
    if (privacy.semiPrivs[i].admin === adminId) {
      return true
    }
  }
  return false
}

const getPrivacySemiPriOwner = (privacy, ownerId, adminId) => {
  for (let i = 0; i < privacy.semiPrivs.length; i++) {
    if (privacy.semiPrivs[i].admin === adminId) {
      for (let j = 0; j < privacy.semiPrivs[i].owners.length; j++) {
        if (privacy.semiPrivs[i].owners[j].id === ownerId) {
          return true
        }
      }
    }
  }
  return false
}

const SemiPriComponent = props => {
  const {
    admins,
    privacy,
    setPrivacy,
    owners
  } = props

  return (
    <>
      {admins.map((item, i) => {
        return (
          <ExpansionPanel key='i'>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label='Expand'
              aria-controls='additional-actions1-content'
              id='additional-actions1-header'
            >
              <FormControlLabel
                aria-label='Acknowledge'
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={<Checkbox checked={getPrivacySemiPriAdmin(privacy, item.id)} onClick={(e) => { setPrivacy({ type: 'pick-admin-semi-priv', value: e.target.checked, admin: item }) }} />}
                label={item.name}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <List>
                {owners.map((owner, index) => {
                  return (
                    <ListItem key={index} role={undefined} dense button onClick={setPrivacy}>
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          checked={getPrivacySemiPriOwner(privacy, owner.id, item.id)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': owner.id }}
                          onClick={(e) => { setPrivacy({ type: 'pick-owner-semi-priv', value: e.target.checked, admin: item, owner }) }}
                        />
                      </ListItemIcon>
                      <ListItemText id={owner.id} primary={owner.name} />
                    </ListItem>
                  )
                })}
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )
      })}
    </>
  )
}

const findClientAdcom = (users, account) => {
  for (const user of users) {
    if (user.id === account.id) {
      return user.userSetting ? user.userSetting.adcom : 0
    }
  }
}

const CreateCargoWorking = props => {
  const {
    admins,
    owners,
    refetchQueries,
    positionDates,
    privacyTypes,
    cargoWorking,
    routes,
    users
  } = props

  const content = cargoWorking
    ? upsertContent.update
    : upsertContent.create

  const initialValues = {
    account: cargoWorking ? cargoWorking.account : '',
    bid: cargoWorking ? cargoWorking.bid : '',
    commission: cargoWorking ? cargoWorking.commission : '',
    month: cargoWorking ? cargoWorking.month : '',
    route: cargoWorking ? cargoWorking.route : '',
    status: cargoWorking ? cargoWorking.status : '',
    laycan: cargoWorking ? cargoWorking.laycan : ''
  }
  const privacyInititalValue = {
    type: cargoWorking && cargoWorking.privacySettings ? cargoWorking.privacySettings.type : 'PUBLIC',
    semiPubs: cargoWorking && cargoWorking.privacySettings ? cargoWorking.privacySettings.semiPubs : [],
    semiPrivs: cargoWorking && cargoWorking.privacySettings ? cargoWorking.privacySettings.semiPrivs : []
  }

  const [privacy, setPrivacy] = React.useReducer(privacyReducer, privacyInititalValue)
  const [account, setAccount] = useState(initialValues.account)
  const [commission, setCommission] = useState(initialValues.commission)
  
  const accountRef = useRef(initialValues.account.id)
  
  const handleChangeAccount = (event) => {
    setAccount({ id:  event.value.id, name: event.value.name })
  }

  const handleChangeCommission = (event) => {
    setCommission(parseFloat(event.target.value))
  }

  useEffect(() => {
    const differentAccount = accountRef.current !== account.id
    if (differentAccount) {
      accountRef.current = account.id
      setCommission(findClientAdcom(users, account))
    }
  }, [account])

  const statuses = [
    { id: 'FIRM', name: 'FIRM' },
    { id: 'SUB-SALE', name: 'SUB-SALE' },
    { id: 'PRICING', name: 'PRICING' },
    { id: 'DEAD', name: 'DEAD' },
    { id: 'OTHERS', name: 'OTHERS' },
    { id: 'US', name: 'US' },
    { id: 'FAILED', name: 'FAILED' }
  ]

  const form = {
    status: useFormInput(
      {
        initialValue: initialValues.status,
        label: 'Status',
        required: true,
        select: true,
        selectValues: statuses
      }
    ),
    account: useFormInput(
      {
        label: 'Account',
        required: true,
        initialValue: initialValues.account,
        select: true,
        selectValues: users.map(user => { return { id: user.id, name: user.name } }),
        smallInput: true,
        disabled: !!cargoWorking,
        creatable: true,
        value: account,
        onChange: handleChangeAccount,
      }
    ),
    route: useFormInput(
      {
        label: 'Route',
        required: true,
        initialValue: initialValues.route,
        select: true,
        selectValues: routes,
        smallInput: true,
        disabled: !!cargoWorking
      }
    ),
    commission: useFormInput(
      {
        initialValue: initialValues.commission,
        label: 'Adcom',
        required: true,
        type: 'number',
        creatable: true,
        value: commission,
        onChange: handleChangeCommission,
      }
    ),
    laycan: useFormInput(
      {
        initialValue: initialValues.laycan,
        label: 'Laycan'
      }
    ),
    month: useFormInput(
      {
        label: 'Month',
        required: true,
        initialValue: initialValues.month,
        select: true,
        selectValues: positionDates,
        smallInput: true,
        disabled: !!cargoWorking
      }
    ),
    bid: useFormInput(
      {
        initialValue: initialValues.bid,
        label: 'Bid',
        required: true,
        type: 'number'
      }
    )
  }

  const input = {
    ...(cargoWorking && { id: cargoWorking.id }),
    status: form.status.getValue(),
    account: account.id,
    route: form.route.getValue(),
    commission: parseFloat(commission),
    month: form.month.getValue(),
    bid: form.bid.getValue(),
    laycan: form.laycan.getValue(),
    privacySettings: { ...privacy }
  }

  const validation = {
    status: statusRequired,
    account: accountRequired,
    route: routeRequired,
    commission: adcomRequired,
    month: monthPositionDateRequired,
    bid: bidRequired
  }

  const next = ({ mutation }) => {
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_CARGOWORKING}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb
                current={content.current}
                links={content.breadCrumbs}
              />
              <RootForm
                form={form}
                validation={validation}
              />
              <Box>
                <RootAutocomplete
                  autoHighlight
                  autoSelect
                  disableClearable
                  label='Privacy'
                  onChange={e => {
                    setPrivacy({
                      type: 'privacy-type',
                      value: e.value
                    })
                  }}
                  options={privacyTypes}
                  value={privacy.type}
                />
                {privacy.type === 'SEMI_PUB'
                  ? <SemiPubComponent admins={admins} privacy={privacy} setPrivacy={setPrivacy} />
                  : ''}
                {privacy.type === 'SEMI_PRI'
                  ? <SemiPriComponent admins={admins} privacy={privacy} setPrivacy={setPrivacy} owners={owners} />
                  : ''}
              </Box>
              <RootButton
                disabled={result.loading}
                justifyContent='center'
                onClick={() => next({ mutation })}
              >
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateCargoWorking.propTypes = {
  cargoWorking: PropTypes.object
}
