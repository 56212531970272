import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
// Content
import content from './LoginContent'
// Context
import { UserContext } from '../App/AppContext'
// Mutation
import MutationHelper from '../Mutations/MutationHelper'
import { LOGIN } from '../Mutations/Mutations'
// Styles
import { makeStyles } from '@material-ui/core/styles'
// Utils
import { AUTH_TOKEN } from '../../constants'
import {
  emailRequired,
  passwordLogin,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'
import RootForm from '../Utils/RootForm'
import RootPaper from '../Utils/RootPaper'

// Material UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '0px',
    borderRadius: '10px',
    height: '350px',
    width: '300px',     // If image, then change to 50%
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  // loginImage: {
  //   width: '60%',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center'
  // },                      // If image, then uncomment
  loginForm: {
    height: '100%',
    width: '100%', // If image, then change to 40%
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleBox: {
    height: '25%',
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    color: theme.palette.common.blue,
    fontSize: theme.typography.generalFontSize + 10
  },
  formBox: {
    height: '65%',
    width: '80%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  form: {
    width: '80%'
  },
  button: {
    height: '60%',
    width: '40%',
    borderRadius: '3px',
    fontSize: theme.typography.generalFontSize
  }
}))

const LoginForm = props => {
  const classes = useStyles()

  const { setUser } = useContext(UserContext)

  const validation = {
    email: emailRequired,
    password: passwordLogin
  }

  const emailForm = {
    email: useFormInput({ label: 'Email', required: true, type: 'email' })
  }

  const passwordForm = {
    password: useFormInput({ label: 'Password', required: true, type: 'password' })
  }

  const input = {
    email: emailForm.email.getValue(),
    password: passwordForm.password.getValue()
  }

  const saveData = ({ data }) => {
    localStorage.setItem(AUTH_TOKEN, data.token)
    setUser({ type: 'update-role', value: data.role.key })
    setUser({ type: 'update-route', value: false })
    props.history.push('/')
  }

  const next = ({ mutation }) => {
    validateStep({
      form: {
        email: emailForm.email,
        pasword: passwordForm.password
      },
      next: () => mutation(),
      validation
    })
  }

  const authToken = localStorage.getItem(AUTH_TOKEN)
  if (authToken) {
    return (
      <Redirect to={{
        pathname: '/routes'
      }}
      />
    )
  }

  return (
    <MutationHelper
      mutation={LOGIN}
      variables={{ input }}
      onCompleted={data => saveData({ data: data.login })}
    >
      {(mutation, result) => {
        return (
          <Box className={classes.loginForm}>

            <Box className={classes.titleBox}>
              <Typography
                className={classes.title}
              >
                Login
              </Typography>
            </Box>

            <Box className={classes.formBox}>

              <Box
                style={{
                  height: '30%'
                }}
                display='flex'
                justifyContent='center'
              >
                <RootForm
                  form={emailForm}
                  gridWidth='85%'
                  gridHeight='1%'
                  errorFontSize='small'
                  validation={validation}
                />
              </Box>

              <Box
                style={{
                  height: '30%'
                }}
                display='flex'
                justifyContent='center'
              >
                <RootForm
                  form={passwordForm}
                  gridWidth='85%'
                  gridHeight='1%'
                  errorFontSize='small'
                  validation={validation}
                />
              </Box>

              <Box
                style={{
                  height: '20%',
                  width: '100%'
                }}
                display='flex'
                justifyContent='center'
                alignItems='flex-start'
              >
                <Button
                  color='primary'
                  variant='contained'
                  className={classes.button}
                  disabled={result.loading}
                  justifyContent='center'
                  onClick={() => next({ mutation })}
                >
                  {content.loginButton}
                </Button>
              </Box>

            </Box>
          </Box>
        )
      }}
    </MutationHelper>
  )
}

// const LoginImage = props => {

//   const classes = useStyles()

//   return (
//     <Box
//       className={classes.loginImage}
//     >
//       <img
//         style={{
//           borderRadius: '3px',
//           margin: '0px',
//           padding: '0px'
//         }}
//         src={'https://live.staticflickr.com/7550/15947941600_7711a33636_b.jpg'}
//         height='100%'
//         width='100%'
//       />
//     </Box>
//   )
// }

const Login = props => {
  const classes = useStyles()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      style={{ height: '80vh' }}
    >
      <RootPaper
        elevation={3}
        smallForm
        className={classes.paper}
      >
        {/* <LoginImage/> */}
        <LoginForm history={props.history} />
      </RootPaper>
    </Box>
  )
}

export default Login
