import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

// Helmet
import Helmet from 'react-helmet'

// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './SpotTCSpreadContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { CREATE_SPOTTCSPREAD_INFORMATION, GET_SPOTTCSPREAD } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_SPOTTCSPREAD } from '../Mutations/Mutations'

// Utils
import {
  validateStep,
  monthRequired,
  shipSectorRequired,
  spotTCSpreadNameRequired,
  spreadTCValueRequired
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import CreateOptionsContainer from '../Utils/CreateOptionsContainer'
import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import RootTextField from '../Utils/RootTextField'
import TextError from '../Utils/TextError'

const validatejs = require('validate.js')

const styles = makeStyles((theme) => ({
  input: {
    padding: '2%'
  },
  noMargin: {
    margin: '0%',
  },
  textField: {
    width: '250px'
  },
  addButton: {
    marginLeft: 8,
    padding: 0
  },
  header: {
    width: '250px',
    marginBottom: 10
  }
}))

const UpsertspotTCSpreadQueryHelper = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.spotTCSpread
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={CREATE_SPOTTCSPREAD_INFORMATION}
    >
      {({ data }) => {
        const { createSpotTCSpreadInformation } = data
        if (createSpotTCSpreadInformation) {
          const { shipSectors, positionDates, zones } = createSpotTCSpreadInformation
          const emptyOptions = []
          if (shipSectors.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.shipSectors)
          }
          if (zones.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.zones)
          }

          const shipSectorsArray = []
          if (shipSectors.length >= 0) {
            shipSectors.map(item => {
              return (shipSectorsArray.push({ id: item.id, name: item.shipSectorName }))
            })
          }
          const positionDatesArray = []
          if (positionDates.length >= 0) {
            positionDates.map(item => {
              return (positionDatesArray.push({ id: item.id, name: item.positionDateName }))
            })
          }
          const zonesArray = []
          if (zones.length >= 0) {
            zones.map(item => {
              return (zonesArray.push({ id: item.id, name: ` ${item.zoneDescription} ${item.zoneName}` }))
            })
          }

          if (emptyOptions.length > 0) {
            return (
              <CreateOptionsContainer
                options={emptyOptions}
                name={upsertContent.module.name}
              />
            )
          }
          return (
            <UpsertspotTCSpread
              searchInput={searchInput}
              skip={skip}
              positionDates={positionDatesArray}
              shipSectors={shipSectorsArray}
              zones={zonesArray}
              {...rest}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}

UpsertspotTCSpreadQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default withRouter(UpsertspotTCSpreadQueryHelper)

const UpsertspotTCSpread = props => {
  const { searchInput, skip, ...rest } = props

  return (
    <QueryHelper
      query={GET_SPOTTCSPREAD}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let spotTCSpread = null
        if (data && data.getSpotTCSpread && data.getSpotTCSpread.spotTCSpread) {
          spotTCSpread = data.getSpotTCSpread.spotTCSpread
        }
        return (
          <>
            {
              spotTCSpread
                ? <Helmet><title>Update Spot Spread</title></Helmet>
                : <Helmet><title>Create Spot Spread</title></Helmet>
            }
            <CreatespotTCSpread
              {...rest}
              spotTCSpread={spotTCSpread}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertspotTCSpread.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const pageStyles = makeStyles((theme) => ({
  roundCorners: {
    borderRadius: '10px',
  },
  upperBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    maxHeight: '250px',
    marginLeft: '15px',
    marginRight: '30px'
  },
  spreadArray: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '250px',
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

const CreatespotTCSpread = props => {
  const { refetchQueries, spotTCSpread, shipSectors, positionDates, zones } = props
  const { user } = useContext(UserContext)

  const classes = pageStyles()

  let typeArray = ['Delivery', 'Size', 'Re-Delivery', 'Dirty Cargo', 'Duration', 'Special']
  typeArray = typeArray.map((item) => { return { id: item, name: item } })

  const content = spotTCSpread ? upsertContent.update : upsertContent.create
  const [editRouteResults, setEditRouteResults] = useState(false)

  const validation = {
    shipSector: shipSectorRequired,
    spotTCSpreadName: spotTCSpreadNameRequired,
    spreadTCValue: spreadTCValueRequired
  }

  const initialValues = {
    shipSector: spotTCSpread ? spotTCSpread.shipSector.id : '',
    spotTCSpreadName: spotTCSpread ? spotTCSpread.spotTCSpreadName : '',
    spreadTCValue: spotTCSpread && spotTCSpread.spreadTCValue.length > 0
      ? spotTCSpread.spreadTCValue
      : Array.from(Array(12), () => 0),
    type: spotTCSpread ? spotTCSpread.type : '',
    deliveryZone: spotTCSpread && spotTCSpread.deliveryZone ? spotTCSpread.deliveryZone.id : '',
    redeliveryZone: spotTCSpread && spotTCSpread.redeliveryZone ? spotTCSpread.redeliveryZone.id : ''
  }
  const form = {
    shipSector: useFormInput({ initialValue: initialValues.shipSector, select: true, selectValues: shipSectors, label: 'shipSector', required: true, isCalc: true, setEditRouteResults, smallInput: true }),
    spotTCSpreadName: useFormInput({ label: 'spotTCSpreadName', required: true, initialValue: initialValues.spotTCSpreadName }),
    // spreadTCValue: useFormInput({ label: 'spreadTCValue', required: true, type: 'number', initialValue: initialValues.spreadTCValue, isCalc: true, setEditRouteResults }),
    type: useFormInput({ label: 'type', required: true, initialValue: initialValues.type, select: true, selectValues: typeArray }),
    deliveryZone: useFormInput({ initialValue: initialValues.deliveryZone, select: true, selectValues: zones, label: 'deliveryZone', required: true, isCalc: true, setEditRouteResults, smallInput: true }),
    redeliveryZone: useFormInput({ initialValue: initialValues.redeliveryZone, select: true, selectValues: zones, label: 'redeliveryZone', required: true, isCalc: true, setEditRouteResults, smallInput: true })
  }

  const [spreadTCValue, setSpreadTCValue] = useState(
    initialValues.spreadTCValue.map((element) => {
      return {
        value: element,
        valid: element !== null,
        errorMessage:
          element === null && '*A valid number is required for month'
      }
    })
  )
  const input = {
    ...(spotTCSpread && { id: spotTCSpread.id }),
    shipSector: form.shipSector.getValue(),
    spotTCSpreadName: form.spotTCSpreadName.getValue(),
    spreadTCValue: spreadTCValue.map(
      (element) => element.value
    ),
    type: form.type.getValue(),
    deliveryZone: form.deliveryZone.getValue(),
    redeliveryZone: form.redeliveryZone.getValue(),
    editRouteResults
  }

  const monthValidation = {
    month: monthRequired
  }

  const next = ({ mutation }) => {
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_SPOTTCSPREAD}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.roundCorners} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <Box className={classes.upperBox}>
                <Box className={classes.form}>
                  <RootForm
                    form={form}
                    validation={validation}
                  />
                  <RootButton className={classes.button} disabled={result.loading || user.disabled} justifyContent='center' onClick={() => next({ mutation })}>
                    {content.submitButton}
                  </RootButton>
                </Box>
                <Box className={classes.spreadArray}>
                  <SpreadArrays
                    spreadTCValue={spreadTCValue}
                    setSpreadTCValue={setSpreadTCValue}
                    setEditRouteResults={setEditRouteResults}
                    validation={monthValidation}
                  />
                </Box>
              </Box>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

const SpreadArrays = (props) => {
  const {
    spreadTCValue,
    setSpreadTCValue,
    setEditRouteResults,
    validation
  } = props

  const classes = styles()

  const [allSpreadsValue, setAllSpreadsValue] = useState()

  const setAllSpreads = (value) => {
    setAllSpreadsValue(parseFloat(value))
    setSpreadTCValue((prevState) => {
      const newState = new Array(prevState.length)
      const validationResult = validatejs(
        {
          month: parseFloat(value)
        },
        validation
      )
      return newState.fill({
        value: parseFloat(value),
        valid: !validationResult,
        errorMessage: validationResult ? validationResult.month[0] : null
      })
    })
  }
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  return (
    <Box /*container spacing={1}*/>
      <Box /*item xs={12} md={6}*/>
        <ArrayHeader
          title='Spreads'
          onChange={setAllSpreads}
          value={allSpreadsValue}
        />
        {months.map((item, index) => {
          return (
            <>
              <RootTextField
                className={classes.textField}
                key={index}
                label={item}
                onChange={(e) => {
                  setEditRouteResults(true)
                  const newCurveAdjustmentSpreads = spreadTCValue
                  newCurveAdjustmentSpreads[index] = parseFloat(
                    e.target.value
                  )
                  setSpreadTCValue((prevState) =>
                    prevState.map((value, i) => {
                      if (i === index) {
                        const validationResult = validatejs(
                          {
                            month: parseFloat(e.target.value)
                          },
                          validation
                        )
                        return {
                          value: parseFloat(e.target.value),
                          valid: !validationResult,
                          errorMessage: validationResult
                            ? validationResult.month[0]
                            : ''
                        }
                      } else {
                        return value
                      }
                    })
                  )
                }}
                type='number'
                value={parseFloat(spreadTCValue[index].value)}
              />
              {spreadTCValue[index] && spreadTCValue[index].errorMessage && (
                <TextError
                  error={spreadTCValue[index].errorMessage}
                />
              )}
            </>
          )
        })}
      </Box>
    </Box>
  )
}

const ArrayHeader = ({ title, disabled, onChange, value }) => {
  const classes = styles()
  return (
    <Grid container alignContent='center' className={classes.header}>
      <Grid item sm={6} container alignContent='flex-end'>
        <Box>
          <Typography>{title}</Typography>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <RootTextField
          label='Set All'
          disabled={disabled}
          className={classes.noMargin}
          onChange={(e) => {
            onChange(e.target.value)
          }}
          value={value}
          type='number'
        />
      </Grid>
    </Grid>
  )
}

CreatespotTCSpread.defaultProps = {
  refetchQueries: []
}

CreatespotTCSpread.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array.isRequired,
  spotTCSpread: PropTypes.object
}
