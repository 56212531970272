import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './OwnerContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_OWNER } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_OWNER } from '../Mutations/Mutations'
// Utils
import {
  nameRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

// Ag Grid
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

// Material UI
import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import Box from '@material-ui/core/Box'
import Switch from '@material-ui/core/Switch'

import Grid from '@material-ui/core/Grid'

// Date-fns-tz
import { format, utcToZonedTime } from 'date-fns-tz'

import { makeStyles } from '@material-ui/core/styles'

const UpsertOwner = props => {
  const { match, history: _histroy, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.owner
  }
  const skip = !id

  const searchInput = { id }

  return (
    <QueryHelper
      query={GET_OWNER}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let owner = null
        if (data && data.getOwner && data.getOwner.owner) {
          owner = data.getOwner.owner
        }
        return (
          <>
            {
              owner
                ? <Helmet><title>Update Owner</title></Helmet>
                : <Helmet><title>Create Owner</title></Helmet>
            }
            <CreateOwner
              {...rest}
              owner={owner}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

const UpsertOwnerWithRouter = withRouter(UpsertOwner)

UpsertOwnerWithRouter.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default UpsertOwnerWithRouter

const useStyles = makeStyles(theme => ({
  button: {
    width: 200,
    fontSize: theme.typography.generalFontSize,
    borderRadius: theme.shape.borderRadius
  },
  grid: {
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'space-around'
  },
  paper: {
    margin: 0,
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between'
  },
  switch: {
    display: 'flex',
    direction: 'row'
  },
  rootPaper: {
    borderRadius: '10px'
  },
  submitButton: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  },
  agGrid: {
    height: '80%'
  }
}))

const CreateOwner = props => {
  const { refetchQueries, owner } = props
  const { user, setUser } = useContext(UserContext)

  const classes = useStyles()
  const [accepted, setAccepted] = useState(true)

  const getFormattedDate = (updated, timeZone, i) => {
    let date, zonedTime
    if (i >= 0) {
      zonedTime = (updated[i] && updated[i]) ? utcToZonedTime(updated[i], timeZone) : ''
      date = (updated[i] && updated[i]) ? new Date(updated[i]) : ''
    } else {
      zonedTime = (updated && updated) ? utcToZonedTime(updated, timeZone) : ''
      date = (updated && updated) ? new Date(updated) : ''
    }

    const year = date.toLocaleString('default', { year: 'numeric', timeZone })
    const month = date.toLocaleString('default', { month: 'short', timeZone })
    const day = date.toLocaleString('default', { day: 'numeric', timeZone })

    const timeString = `${day}${month}${year} ${format(zonedTime, 'HH:mm:ss', { timeZone })}`

    return timeString // returns a string
  }

  const getAcceptedOffers = () => {
    if (owner && owner.offers) {
      const acceptedOffersOnly = owner.offers.filter(item => item.accepted)
      return acceptedOffersOnly.map(item => {
        return {
          active: (item.active) ? 'Yes' : 'No',
          createdBy: (item.createdBy.name) && item.createdBy.name,
          createdAt: (item.createdAt) && getFormattedDate(item.createdAt, 'Europe/Copenhagen'),
          routeName: (item.route) && item.route.routeName,
          positionDateName: (item.positionDate) && item.positionDate.positionDateName,
          offer: (item.originalOffer) && (item.originalOffer).toFixed(2),
          bid: (item.originalBid) && (item.originalBid).toFixed(2),
          adcome: (item.adcome) && (item.adcome).toFixed(2)
        }
      })
    } else {
      return []
    }
  }

  const getAllOffers = () => {
    if (owner && owner.offers) {
      return owner.offers.map(item => {
        return {
          active: (item.active) ? 'Yes' : 'No',
          createdBy: (item.createdBy.name) && item.createdBy.name,
          createdAt: (item.createdAt) && getFormattedDate(item.createdAt, 'Europe/Copenhagen'),
          routeName: (item.route) && item.route.routeName,
          positionDateName: (item.positionDate) && item.positionDate.positionDateName,
          offer: (item.originalOffer) && (item.originalOffer).toFixed(2),
          bid: (item.originalBid) && (item.originalBid).toFixed(2),
          adcome: (item.adcome) && (item.adcome).toFixed(2)
        }
      })
    } else {
      return []
    }
  }

  const content = owner ? upsertContent.update : upsertContent.create

  const validation = {
    ownerName: nameRequired
  }

  const initialValues = {
    ownerName: owner ? owner.ownerName : ''
  }

  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)

  function onGridReady (params) {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const columnDefs = [
    { field: 'active', headerName: 'Active', width: 90 },
    { field: 'createdBy', headerName: 'Created By', width: 120 },
    { field: 'createdAt', headerName: 'Date', width: 180 },
    { field: 'routeName', headerName: 'Route', width: 250 },
    { field: 'positionDateName', headerName: 'Month', width: 90 },
    { field: 'offer', headerName: 'Offer', width: 90 },
    { field: 'bid', headerName: 'Bid', width: 90 },
    { field: 'adcome', headerName: 'Adcom', width: 110 }
  ]

  const [rowData, setRowData] = useState(getAcceptedOffers)

  // useEffect(() => {
  //   if (accepted) {

  //   } else {

  //   }
  // }, [accepted])

  const handleChangeAccepted = (event) => {
    if (event) {
      const acceptedOffersOnly = getAcceptedOffers()
      setRowData(acceptedOffersOnly)
      setAccepted(event)
    } else {
      const givenOffers = getAllOffers()
      setRowData(givenOffers)
      setAccepted(event)
    }
  }

  const form = {
    ownerName: useFormInput({
      initialValue: initialValues.ownerName,
      label: 'ownerName',
      required: true
    })
  }

  const input = {
    ...(owner && { id: owner.id }),
    ownerName: form.ownerName.getValue(),
    codeName: form.ownerName.getValue() && form.ownerName.getValue().toLowerCase().trim().replace(' ', '')
  }

  const next = ({ mutation }) => {
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_OWNER}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper
              elevation={2}
              className={classes.rootPaper}
              {...(!props.onCompleted && { smallForm: true })}
            >
              <Grid
                className={classes.grid}
              >
                <RootBreadCrumb
                  paperClass={classes.paper}
                  current={content.current}
                  links={content.breadCrumbs}
                />
                <RootForm
                  form={form}
                  validation={validation}
                />
                <RootButton
                  className={classes.submitButton}
                  disabled={result.loading || user.disabled}
                  justifyContent='center'
                  onClick={() => next({ mutation })}
                >
                  {content.submitButton}
                </RootButton>

                {[0].map(item => {
                  if (owner) {
                    return (
                      <div className='ag-theme-alpine' style={{ height: 350, width: 765 }}>
                        <Box className={classes.switch}>
                          <Grid component='label' container alignItems='center' spacing={1}>
                            <Grid item>
                              Given
                            </Grid>
                            <Switch
                              checked={accepted}
                              onChange={e => handleChangeAccepted(e.target.checked)}
                              value={accepted}
                            />
                            <Grid item>
                              Accepted
                            </Grid>
                          </Grid>
                        </Box>
                        <Box className={classes.agGrid}>
                          <AgGridReact
                            onGridReady={onGridReady}
                            rowData={rowData}
                          >
                            {
                              columnDefs.map((c, i) => {
                                return (
                                  <AgGridColumn
                                    key={c.field}
                                    field={c.field}
                                    headerName={c.headerName}
                                    width={c.width}
                                    filter
                                    sortable
                                  />
                                )
                              })
                            }
                          </AgGridReact>
                        </Box>
                      </div>
                    )
                  }
                })}

              </Grid>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateOwner.defaultProps = {
  refetchQueries: []
}

CreateOwner.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
  owner: PropTypes.object
}
