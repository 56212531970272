import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { useQuery, useMutation } from 'react-apollo'
import { GET_SUMMARY_FROM_CLIENT } from '../Queries/Queries'
import { UPDATE_SUMMARY } from '../Mutations/Mutations'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const useStyles = makeStyles((theme) => ({
  summary: {
    minWidth: '500px',
    // minHeight: '300px',
    borderRadius: 3 // TODO Find way to do this with theme
  },
  typography: {
    padding: theme.spacing(2)
  },
  multiline: {
    margin: 'auto',
    fontSize: theme.typography.generalFontSize * 0.8

  }
}))

const ShowSummary = props => {
  const { open, handleClose, routeId, user } = props
  const classes = useStyles()
  // console.log(handleClose)
  const [summaryText, setSummaryText] = useState('')
  const [summaryId, setSummaryId] = useState('')
  const { data, loading, error } = useQuery(GET_SUMMARY_FROM_CLIENT, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        route: routeId || '',
        user: user
      }
    },
    skip: !routeId,
    onCompleted (data) {
      // console.log(data)
      setSummaryId(data ? data.getSummaryFromUser.summary.id : '')
      setSummaryText(data ? data.getSummaryFromUser.summary.summary : '')
    }
  })
  const [updateSummary] = useMutation(UPDATE_SUMMARY, {
    skip: !routeId
  })

  if (loading) {
    return (
      <div />
    )
  }
  // const clientsToString = (clients = []) => {
  //   let result = ''
  //   for (let i = 0; i < clients.length; i++) {
  //     result += `${clients[i].name} `
  //   }

  //   return result
  // }
  // const getTimeCountingName = (timeCounting) => {
  //   for (let i = 0; i < timeCountings.length; i++) {
  //     if (timeCounting === timeCountings[i].id) {
  //       return timeCountings[i].timeCountingName
  //     }
  //   }
  // }
  // const clientString = route ? `${clientsToString(route.clients)} ${(route.summary && route.summary.clients) ? route.summary.clients : ''}` : ''
  // const stemToleranceString = route ? `${route.stem / 1000}k'${route.tolerance} ${route.commodity} ${(route.summary && route.summary.stemTolerance) ? route.summary.stemTolerance : ''}` : ''
  // const ports = route ? `${route.loadPort0} / ${route.dischargePort0} ${route.summary && route.summary.loadDischarge ? route.summary.loadDischarge : ''}` : ''
  // const terms = route ? `${route.loadTermsArray[0] / 1000}k (${getTimeCountingName(route.loadTimeCountingArray[0])}) / ${route.dischargeTermsArray[0] / 1000}k (${getTimeCountingName(route.dischargeTimeCountingArray[0])}) ${route.summary && route.summary.loadDischRateTerms ? route.summary.loadDischRateTerms : ''}` : ''
  // const commission = route ? `${route.commission}% ${route.summary && route.summary.addcom ? route.summary.addcom : ''}` : ''
  // const text = route ? `${clientString}\n${stemToleranceString}\n${ports}\n${terms}\n${commission}` : ''

  return (
    <Popover
      id='prob'
      open={open}
      onClose={handleClose}
      className={classes.summary}
      PaperProps={{
        className: classes.summary
      }}
    >
      <Typography className={classes.typography}>Summary</Typography>
      <TextField
        id='outlined-multiline-static'
        label='Route Summary'
        multiline
        autoFocus
        fullWidth
        rows={8}
        onChange={(e) => { setSummaryText(e.target.value) }}
        defaultValue={summaryText || ''}
        variant='outlined'
        className={classes.multiline}
      />
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Button
            className={classes.button} color='primary' disabled={loading} onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </Button>
          <Button
            className={classes.button} color='primary' disabled={loading} onClick={() => {
              // console.log(summaryId, summaryText)
              updateSummary({
                variables: {
                  input: {
                    id: summaryId,
                    text: summaryText
                  }
                }
              })
            }}
          >
            Update
          </Button>
        </Box>
        <Box>
          <CopyToClipboard text={summaryText}>
            <Button className={classes.button} color='primary' disabled={loading}>
              Copy
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>

    </Popover>
  )
}

export default withRouter(ShowSummary)
