import React from 'react'
// Material Ui
import Divider from '@material-ui/core/Divider'
// Styles
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.secondary.main,
    marginBottom: '0.5em',
    marginTop: '0.5em'
  },
}))

const RootDivider = props => {
  const classes = useStyles()
  return (
    <Divider className={classes.divider} />
  )
}

export default RootDivider
