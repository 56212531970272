// React Library
import React, { useState, useRef, useEffect } from 'react'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Content
import { show as content } from './DistanceContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_DISTANCES } from '../Queries/Queries'

// Mutation Related
import { DELETE_DISTANCE } from '../Mutations/Mutations'

// Material UI Components
import AddIconButton from '../Utils/AddIconButton'
import RootGrid from '../Utils/RootGrid'
import SearchTextInput from '../Utils/SearchTextInput'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Utils
import RootAgGrid from '../Utils/RootAgGrid'

// Query Component
const ShowDistances = props => {
  return (
    <>
      <Helmet>
        <title>Distance</title>
      </Helmet>
      <QueryHelper query={GET_DISTANCES}>
        {({ data }) => {
          const { getDistances: { distances } } = data

          // Some Formatting...
          const distancesArray = distances.map(distance => {
            return {
              ...distance,
              portID1: distance.portID1.portName,
              portID2: distance.portID2.portName
            }
          })

          // Render
          return (
            <ShowDistanceTable
              distancesArray={distancesArray}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowDistanceTable = (props) => {
  const { distancesArray = [] } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchDistance, setSearchDistance] = useState(distancesArray)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['portID1', 'portID2'], {
    idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  })
  // The Array To Be Filtered (All Distances In the DB)
  ts.addAll(distancesArray)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchDistance(distancesArray)
      } else {
        const searchArray = search.split(',')
        const foundDistances = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setSearchDistance(foundDistances)
      }
    }
  }, [search, distancesArray, ts])

  // The Column Structure
  const columnDefs = [
    { field: 'portID1', headerName: 'Port 1', cellRenderer: 'link' },
    { field: 'portID2', headerName: 'Port 2' },
    { field: 'ecaDist', headerName: 'Eca Distance' },
    { field: 'fullDist', headerName: 'Full Distance' }
  ]

  // The Update Distance Link
  const updateLink = 'update-distance'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>

      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Distances Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={searchDistance}
        mutation={DELETE_DISTANCE}
        objectNameKey1='portID1'
        objectNameKey2='portID2'
        type='Distance'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowDistances)
