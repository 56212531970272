// React
import React from 'react'
import { withRouter } from 'react-router-dom'
// Apollo
import { Mutation } from 'react-apollo'
// PropTypes
import PropTypes from 'prop-types'
// User
// import { UserContext } from '../App/AppContext'
// Utils
// import Loading from '../Utils/Loading';
import RootLinearProgress from '../Utils/RootLinearProgress'
import { fireSwalError } from '../Utils/utils'
// import SnackBar from '../Utils/SnackBar'

const MutationHelper = props => {
  const {
    children,
    errorObject,
    mutation,
    onCompleted,
    onCompletedObject,
    onError,
    refetchQueries,
    awaitRefetchQueries,
    variables,
    ...rest
  } = props

  // const { setSnackbar } = useContext(UserContext)

  let defaultOnError = (error) => {
    fireSwalError({
      title: 'Error',
      text: error ? error.message.split(':')[1] : errorObject.message
    })
  }

  if (onError) {
    defaultOnError = onError
  }

  let defaultOnCompleted = (data) => {
    // setSnackbar({
    //   type: 'success',
    //   message: onCompletedObject.message
    // })
    // fireSwalSuccess({
    //   title: onCompletedObject.title,
    //   text: onCompletedObject.message,
    // })
    if (onCompletedObject.path) {
      props.history.replace(onCompletedObject.path)
    }
  }

  if (onCompleted) {
    defaultOnCompleted = onCompleted
  }

  return (
    <Mutation
      mutation={mutation}
      variables={variables}
      refetchQueries={refetchQueries}
      onCompleted={(data) => defaultOnCompleted(data)}
      onError={(error) => {
        defaultOnError(error)
      }}
      awaitRefetchQueries={awaitRefetchQueries}
    >
      {(data, result, subscribeToMore) => {
        // if(result.loading){
        //   return(
        //     <Fragment>
        //       <Loading open={result.loading}/>
        //       {children(data,result, rest)}
        //     </Fragment>
        //   )
        // }
        return (
          <>
            <RootLinearProgress open={result.loading} />
            {children(data, result, subscribeToMore, rest)}
          </>
        )
      }}
    </Mutation>
  )
}

MutationHelper.defaultValues = {
  errorObject: {
    message: 'Ocurrió un error. Intente de nuevo más tarde.'
  },
  onCompletedObject: {
    message: 'Se ha realizado con éxito esta acción',
    path: '/'
  },
  variables: {},
  refetchQueries: []
}

MutationHelper.propTypes = {
  errorObject: PropTypes.object,
  mutation: PropTypes.object.isRequired,
  onCompleted: PropTypes.func,
  onCompletedObject: PropTypes.object,
  onError: PropTypes.func,
  variables: PropTypes.object
}

export default withRouter(MutationHelper)
