import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './SpotIndexesContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_SPOTINDEX, CREATE_SPOTINDEX_INFORMATION } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_SPOTINDEX } from '../Mutations/Mutations'
// Utils
import {
  indexAdjustmentAbsoluteRequired,
  indexAdjustmentPercentRequired,
  indexValueRequired,
  nameRequired,
  routeRequired,
  shipSectorRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

// MUI
import { makeStyles } from "@material-ui/core/styles"

import CreateOptionsContainer from '../Utils/CreateOptionsContainer'
import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'

const UpsertSpotIndexQueryHelper = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.spotIndex
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={CREATE_SPOTINDEX_INFORMATION}
    >
      {({ data }) => {
        const { createSpotIndexInformation } = data
        if (createSpotIndexInformation) {
          const { shipSectors, routes } = createSpotIndexInformation
          const emptyOptions = []
          // This one deletes the current route from the options
          if (shipSectors.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.shipSectors)
          }

          if (emptyOptions.length > 0) {
            return (
              <CreateOptionsContainer
                options={emptyOptions}
                name={upsertContent.module.name}
              />
            )
          }

          const shipSectorsArray = []
          if (shipSectors.length >= 0) {
            shipSectors.map(item => {
              return (shipSectorsArray.push({ id: item.id, name: item.shipSectorName }))
            })
          }
          const routesArray = []
          if (routes.length >= 0) {
            routes.map(item => {
              return (routesArray.push({ id: item.id, name: item.routeName }))
            })
          }
          return (
            <UpsertSpotIndex
              history={_history}
              searchInput={searchInput}
              skip={skip}
              shipSectors={shipSectorsArray}
              routes={routesArray}
              {...rest}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}

export default withRouter(UpsertSpotIndexQueryHelper)

const UpsertSpotIndex = props => {
  const { searchInput, skip, ...rest } = props
  return (
    <QueryHelper
      query={GET_SPOTINDEX}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let spotIndex = null
        if (data && data.getSpotIndex && data.getSpotIndex.spotIndex) {
          spotIndex = data.getSpotIndex.spotIndex
        }
        return (
          <>
            {
              spotIndex
                ? <Helmet><title>Update Spot Index</title></Helmet>
                : <Helmet><title>Create Spot Index</title></Helmet>
            }
            <CreateSpotIndex
              {...rest}
              spotIndex={spotIndex}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertSpotIndex.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const useStyles = makeStyles((theme) => ({
  roundCorners: {
    borderRadius: '10px'
  }
}))

const CreateSpotIndex = props => {
  const { refetchQueries, shipSectors, spotIndex } = props
  const { user } = useContext(UserContext)

  const classes = useStyles()

  const content = spotIndex ? upsertContent.update : upsertContent.create
  const [editRouteResults, setEditRouteResults] = useState(false)

  const validation = {
    indexAdjustmentAbsolute: indexAdjustmentAbsoluteRequired,
    indexAdjustmentPercent: indexAdjustmentPercentRequired,
    indexName: nameRequired,
    indexValue: indexValueRequired,
    shipSector: shipSectorRequired
  }
  const initialValues = {
    indexAdjustmentAbsolute: spotIndex ? spotIndex.indexAdjustmentAbsolute : '',
    indexAdjustmentPercent: spotIndex ? spotIndex.indexAdjustmentPercent : '',
    indexName: spotIndex ? spotIndex.indexName : '',
    indexValue: spotIndex ? spotIndex.indexValue : '',
    shipSector: spotIndex && spotIndex.shipSector ? spotIndex.shipSector.id : ''
  }

  const form = {
    indexName: useFormInput({ initialValue: initialValues.indexName, label: 'Name', required: true }),
    indexValue: useFormInput({ initialValue: initialValues.indexValue, label: 'Value', required: true, type: 'number', isCalc: true, setEditRouteResults }),
    indexAdjustmentAbsolute: useFormInput({ initialValue: initialValues.indexAdjustmentAbsolute, label: 'Index adjustment absolute', required: true, type: 'number', isCalc: true, setEditRouteResults }),
    indexAdjustmentPercent: useFormInput({ initialValue: initialValues.indexAdjustmentPercent, label: 'Index adjustment percent', required: true, type: 'number', isCalc: true, setEditRouteResults }),
    shipSector: useFormInput({ initialValue: initialValues.shipSector, label: 'Ship Sector', select: true, selectValues: shipSectors, required: true, smallInput: true })
  }

  const input = {
    ...(spotIndex && { id: spotIndex.id }),
    indexName: form.indexName.getValue(),
    indexValue: parseFloat(form.indexValue.getValue()),
    indexAdjustmentAbsolute: parseFloat(form.indexAdjustmentAbsolute.getValue()),
    indexAdjustmentPercent: parseFloat(form.indexAdjustmentPercent.getValue()),
    shipSector: form.shipSector.getValue(),
    editRouteResults
  }

  const next = ({ mutation }) => {
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_SPOTINDEX}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.roundCorners} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <RootForm
                form={form}
                validation={validation}
              />
              <RootButton
                style={{
                  borderRadius: '5px',
                  backgroundColor: 'rgba(91, 196, 34, 1)',
                  color: 'white'
                }}
                disabled={result.loading || user.disabled}
                justifyContent='center'
                onClick={() => next({ mutation })}
              >
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateSpotIndex.propTypes = {
  spotIndex: PropTypes.object
}
