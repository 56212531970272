// TODO probably import useReducer
import React, {
  Fragment,
  useState,
  useReducer,
  useContext,
  useRef,
  useEffect,
} from "react" // useEffect
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { makeStyles, useTheme } from "@material-ui/core/styles"
// Helmet
import Helmet from 'react-helmet'
// Context
import { UserContext } from "../App/AppContext"
// Content
import { upsert as upsertContent } from "./RoutesContent"
// Queries
import QueryHelper from "../Queries/QueryHelper"
import {
  GET_ROUTE,
  GET_ROUTE_WITH_NET_DAILYHIRE,
  CREATE_ROUTE_INFORMATION,
  GET_CALCULATION_ROUTE_INFO,
  GET_SIMPLE_ROUTES,
  GET_SHIPSECTORS,
} from "../Queries/Queries"
import RemoveIconButton from "../Utils/RemoveIconButton"
// Mutations
import MutationHelper from "../Mutations/MutationHelper"
import { DUPLICATE_ROUTE, UPSERT_ROUTE } from "../Mutations/Mutations"
// Utils
import {
  ballastBonusRequired,
  commodityRequired,
  constantsRequired,
  deliveryPortRequired,
  deliveryPortBunkerRequired,
  extraInsuranceRequired,
  forwardCurveRequired,
  miscRequired,
  // linkedRouteArraysRequired,
  // spotTCSpreadArrayRequired,
  stemRequired,
  toleranceRequired,
  vesselRequired,
  validateStep,
  linkedRouteArrays,
  nameRequired,
  draft,
} from "../Utils/form-validations"
import { useFormInput } from "../Utils/utils"

import CreateOptionsContainer from "../Utils/CreateOptionsContainer"
import RootBreadCrumb from "../Utils/RootBreadcrumb"
import RootButton from "../Utils/RootButton"
import RootForm from "../Utils/RootForm"
import RootSelect from "../Utils/RootSelect"
import AddButton from "../Utils/AddButton"
import RootGrid from "../Utils/RootGrid"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import RootPaper from "../Utils/RootPaper"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import Button from "@material-ui/core/Button"
import StepLabel from "@material-ui/core/StepLabel"
import RootTextField from "../Utils/RootTextField"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"
import TextError from "../Utils/TextError"
import Divider from "@material-ui/core/Divider"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import RootAutocomplete from "../Utils/RootAutocomplete"
import AddIconButton from "../Utils/AddIconButton"
import AddIcon from "@material-ui/icons/Add"

import { Box, IconButton } from "@material-ui/core"
import EditSummary from '../Summary/EditSummary'

const useStyles = makeStyles((theme) => ({
  divider: {
    width: "100%",
    height: 2,
    background: "#d3d0cb",
    margin: `${theme.spacing(2)}px 0`,
  },
  paper: {
    margin: "0 auto",
    width: "100%",
    borderRadius: '10px'
  },
  greyDivider: {
    marginTop: "20px",
    border: "0.5px solid #c5cbd4",
    width: "100%",
  },
  errorName: {
    color: "red",
  },
  bsMg: {
    marginLeft: "2.5%",
    marginRight: "2.5%",
  },
  container: {
    width: "95%",
    marginLeft: "2.5%",
  },
  textField: {
    width: '95%',
    marginLeft: '0.5%',
    // margin: '0%',
    background: theme.palette.common.white,
    textAlign: 'left',
    fontSize: theme.typography.generalFontSize
  },
}))

const getSteps = () => {
  return ["Create Route"]
}

const findDataWithId = (params) => {
  const { key = '-1', searchIn, id } = params

  if (key === '-1') {
    for (const element of searchIn) {
      if (element === id) {
        return element
      }
    }
  } else {
    for (const element of searchIn) {
      if (element[key] === id) {
        return element
      }
    }
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case "add":
      return [...state, action.emptyValue]
    case "remove":
      if (action.shouldRemoveOne !== null && action.shouldRemoveOne === true) {
        return state.filter((_, index) => index !== action.index)
      }
      if (state.length - 1 === 0) {
        return state
      }
      return state.filter((_, index) => index !== action.index)
    case "load":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portArray: action.value,
          }
        } else {
          return item
        }
      })
    case "bunker":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portBunkerArray: action.value,
          }
        } else {
          return item
        }
      })
    case "idleWorking":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portIdleWorkingArray: action.value,
          }
        } else {
          return item
        }
      })
    case "waitingIdle":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portWaitingIdleArray: parseFloat(action.value),
          }
        } else {
          return item
        }
      })
    case "terms":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portTerms: parseFloat(action.value),
          }
        } else {
          return item
        }
      })
    case "time":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portTimeCounting: action.value,
          }
        } else {
          return item
        }
      })
    case "update-showViaLaden": {
      const newState = [...state]
      for (let i = 0; i < newState.length; i++) {
        newState[i].showViaLadenPort = action.array[i]
      }
      return [
        ...newState
      ]
    }
    default:
      break
  }
}

const validatePortRow = (portRow) => {
  const { portArray, portTerms, portTimeCounting } = portRow
  if (portArray && portTerms && portTimeCounting) {
    return true
  }
  return false
}

const orderBySpreadName = (a, b) => {
  const nameA = a.name
  const nameB = b.name

  if (nameA > nameB) {
    return 1
  } else if (nameA < nameB) {
    return -1
  }
}

const reducerLoadDischarge = (state, action) => {
  switch (action.type) {
    case "add":
      return [...state, action.emptyValue]
    case "remove":
      if (action.shouldRemoveOne !== null && action.shouldRemoveOne === true) {
        return state.filter((_, index) => index !== action.index)
      }
      if (state.length - 1 === 0) {
        return state
      }
      return state.filter((_, index) => index !== action.index)
    case "load":
      return state.map((item, index) => {
        if (index === action.index) {
          const newRow = {
            ...item,
            portArray: action.value,
          }
          return {
            ...newRow,
            validate: validatePortRow(newRow),
          }
        } else {
          return item
        }
      })
    case "idleWorking":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portIdleWorkingArray: action.value,
          }
        } else {
          return item
        }
      })
    case "waitingIdle":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portWaitingIdleArray: parseFloat(action.value),
          }
        } else {
          return item
        }
      })
    case "bunker":
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...item,
            portBunkerArray: action.value,
          }
        } else {
          return item
        }
      })
    case "terms":
      return state.map((item, index) => {
        if (index === action.index) {
          const newRow = {
            ...item,
            portTerms: parseFloat(action.value),
          }
          return {
            ...newRow,
            validate: validatePortRow(newRow),
          }
        } else {
          return item
        }
      })
    case "time":
      return state.map((item, index) => {
        if (index === action.index) {
          const newRow = {
            ...item,
            portTimeCounting: action.value,
          }
          return {
            ...newRow,
            validate: validatePortRow(newRow),
          }
        } else {
          return item
        }
      })
    default:
      break
  }
}

const handleCheckbox = (prevState, action) => {
  switch (action.type) {
    case "handleChange":
      return {
        ...prevState,
        [action.name]: action.checked,
      }
    default:
      return { ...prevState }
  }
}

const UpsertRouteQueryHelper = (props) => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.route
  }
  const skip = !id
  const searchInput = {
    id,
  }
  return (
    <QueryHelper query={CREATE_ROUTE_INFORMATION}>
      {({ data }) => {
        const { createRouteInformation } = data
        if (createRouteInformation) {
          const {
            commodities,
            forwardCurves,
            ports,
            routes,
            spotTCSpreads,
            timeCountings,
            users,
            vessels,
          } = createRouteInformation

          const emptyOptions = []
          // This one deletes the current route from the options
          const routeResults = []
          if (commodities.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.commodities)
          }

          if (forwardCurves.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.forwardCurves)
          }

          if (ports.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.ports)
          }
          if (routes && searchInput.id) {
            routes.map((element) => {
              if (element.id !== searchInput.id) {
                routeResults.push({ id: element.id, name: element.routeName })
              }
            })
          }
          if (spotTCSpreads.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.spotTCSpreads)
          }
          if (timeCountings.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.timeCountings)
          }
          if (vessels.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.vessels)
          }

          if (emptyOptions.length > 0) {
            return (
              <CreateOptionsContainer
                options={emptyOptions}
                name={upsertContent.module.name}
              />
            )
          }

          const commoditiesArray = []
          if (commodities.length >= 0) {
            commodities.map((item) => {
              return commoditiesArray.push({
                id: item.id,
                name: item.commodityName,
              })
            })
          }

          const spotTCSpreadsArray = []
          
          if (spotTCSpreads.length >= 0) {
            spotTCSpreads.map((item) => {
              return spotTCSpreadsArray.push({
                id: item.id,
                name: item.spotTCSpreadName,
                shipSector: item.shipSector.id,
                shipSectorName: item.shipSector.shipSectorName
              })
            })
          }

          const forwardCurvesArray = []
          if (forwardCurves.length >= 0) {
            forwardCurves.map((item) => {
              return forwardCurvesArray.push({
                id: item.id,
                name: item.forwardCurveName,
                shipSector: item.spotIndex.shipSector.id,
                shipSectorName: item.spotIndex.shipSector.shipSectorName,
                spotTCSpreads: item.spotTCSpreads
              })
            })
          }
          const portsArray = []
          if (ports.length >= 0) {
            ports.map((item) => {
              return portsArray.push({
                id: item.id,
                name: `${item.portName} - ${item.berth}`,
                shortName: item.portName,
                foid: item.FO05,
                draft: item.draftArray[0],
                distanceOnly: item.distanceOnly
              })
            })
          }
          const timeCountingsArray = []
          if (timeCountings.length >= 0) {
            timeCountings.map((item) => {
              return timeCountingsArray.push({
                id: item.id,
                name: item.timeCountingName,
              })
            })
          }
          const usersArray = []
          if (users && users.length >= 0) {
            users.map((item) => {
              return usersArray.push({ id: item.id, name: item.name })
            })
          }
          const vesselsArray = []
          if (vessels.length >= 0) {
            vessels.map((item) => {
              return vesselsArray.push({
                id: item.id,
                name: item.vesselName,
                dwt: item.dwt,
              })
            })
          }

          const routesArray = []
          if (routes.length >= 0) {
            routes.map((item) => {
              return routesArray.push({
                id: item.id,
                name: item.routeName
              })
            })
          }
          return (
            <UpsertRoute
              history={_history}
              searchInput={searchInput}
              skip={skip}
              commodities={commoditiesArray}
              forwardCurves={forwardCurvesArray}
              ports={portsArray}
              routes={!searchInput.id ? routesArray : routeResults}
              spotTCSpreads={spotTCSpreads}
              timeCountings={timeCountingsArray}
              users={usersArray}
              vessels={vesselsArray}
              {...rest}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}

export default withRouter(UpsertRouteQueryHelper)

const UpsertRoute = (props) => {
  const { searchInput, skip, ...rest } = props

  return (
    <QueryHelper
      query={GET_ROUTE_WITH_NET_DAILYHIRE}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let route = null
        if (data && data.getRouteWithNetDailyHire && data.getRouteWithNetDailyHire.route) {
          route = data.getRouteWithNetDailyHire.route
        }

        return (
          <>
            {
              route
                ? <Helmet><title>Update Route</title></Helmet>
                : <Helmet><title>Create Route</title></Helmet>
            }
            <ShipSectorsWrapper {...rest} route={route} />
          </>
        )
      }}
    </QueryHelper>
  )
}

const ShipSectorsWrapper = ({ ...rest }) => {
  return (
    <QueryHelper query={GET_SHIPSECTORS} skip={false}>
      {({ data }) => {
        let shipSectors = null
        if (data && data.getShipSectors && data.getShipSectors.shipSectors) {
          shipSectors = data.getShipSectors.shipSectors
        }
        return <CreateRoute {...rest} shipSectors={shipSectors} />
      }}
    </QueryHelper>
  )
}

const getShipSector = (shipSectors, dwt) => {
  let foundShipSector = null

  if (dwt) {
    for (let i = 0; i < shipSectors.length; i++) {
      if (dwt >= shipSectors[i].dwtMin && dwt <= shipSectors[i].dwtMax) {
        return { ...shipSectors[i] }
      }
    }
  }

  return foundShipSector
}

const getVessel = (vessels, id) => {
  let foundVessel = null

  if (id) {
    for (let i = 0; i < vessels.length; i++) {
      if (vessels[i].id === id) {
        foundVessel = vessels[i]
        break
      }
    }
  }

  return foundVessel
}

const getFilteredForwardCurves = (forwardCurves, shipSector) => {
  const filteredForwardCurves = []
  if (shipSector && shipSector.id && shipSector.shipSectorName !== 'Panamax') {
    for (let i = 0; i < forwardCurves.length; i++) {
      if (forwardCurves[i].shipSector === shipSector.id) {
        filteredForwardCurves.push(forwardCurves[i])
      }
    }
    return filteredForwardCurves
  } else if (shipSector && shipSector.id && shipSector.shipSectorName === 'Panamax') {
    for (let i = 0; i < forwardCurves.length; i++) {
      if (forwardCurves[i].shipSectorName === 'Panamax' || forwardCurves[i].shipSectorName === 'Kamsarmax') {
        filteredForwardCurves.push(forwardCurves[i])
      }
    }
    return filteredForwardCurves
  } else {
    return forwardCurves
  }
}

const getFilteredSpotTCSpread = (spotTCSpreads, shipSector, netDailyHire) => {
  const result = []
  if (shipSector && shipSector.id && shipSector.shipSectorName !== 'Panamax') {
    for (let i = 0; i < spotTCSpreads.length; i++) {
      if (spotTCSpreads[i].shipSector.id === shipSector.id) {
        result.push({
          id: spotTCSpreads[i].id,
          spotTCSpreadName: `${spotTCSpreads[i].spotTCSpreadName} ${(netDailyHire && spotTCSpreads[i].spreadTCValue.length > 0) ? ` (${(netDailyHire * (spotTCSpreads[i].spreadTCValue[0] / 100)).toFixed(0)})` : ''}`,
          shipSector: {
            id: spotTCSpreads[i].shipSector.id,
            shipSectorName: spotTCSpreads[i].shipSector.shipSectorName
          }
        })
      }
    }
    return result
  } else if (shipSector && shipSector.id && shipSector.shipSectorName === 'Panamax') {
    for (let i = 0; i < spotTCSpreads.length; i++) {
      // console.log(spotTCSpreads[i].shipSectorName)
      if (spotTCSpreads[i].shipSector.shipSectorName === 'Panamax' || spotTCSpreads[i].shipSector.shipSectorName === 'Kamsarmax') {
        result.push({
          id: spotTCSpreads[i].id,
          spotTCSpreadName: `${spotTCSpreads[i].spotTCSpreadName} ${(netDailyHire && spotTCSpreads[i].spreadTCValue.length > 0) ? ` (${(netDailyHire * (spotTCSpreads[i].spreadTCValue[0] / 100)).toFixed(0)})` : ''}`,
          shipSector: {
            id: spotTCSpreads[i].shipSector.id,
            shipSectorName: spotTCSpreads[i].shipSector.shipSectorName
          }
        })
      }
    }
    return result
  } else {
    return spotTCSpreads
  }
}
const CreateRoute = (props) => {
  const {
    route,
    commodities,
    forwardCurves,
    history,
    ports,
    routes,
    spotTCSpreads,
    timeCountings,
    vessels,
    shipSectors,
  } = props


  const content = route ? upsertContent.update : upsertContent.create
  const [editRouteResults, setEditRouteResults] = useState(true)
  const { user } = useContext(UserContext)


  const getFormatValues = ({ values, keyname = "name" }) => {
    const result = []
    values.forEach((element) => {
      result.push({ id: element.id, name: element[keyname] })
    })
    return result
  }

  const getFormatSpotTcValues = ({ values, keyname = "name" }) => {
    const result = []
    values.forEach((element) => {
      result.push({ id: element.id, name: `${element[keyname]} ${(route && route.netDailyHire && element.spreadTCValue.length > 0) ? ` (${(route.netDailyHire * (element.spreadTCValue[0] / 100)).toFixed(0)})` : ''}` })
    })

    return result
  }

  const getTimeCountingsFormatValues = ({ values, keyname = "name" }) => {
    const result = []
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < timeCountings.length; j++) {
        if (timeCountings[j].id === values[i]) {
          result.push({ id: timeCountings[j].id, name: timeCountings[j].name }) // changed index i for j.
        }
      }
    }
    return result
  }

  const getPortsFormatValues = ({ values }) => {
    const result = []
    for (let i = 0; i < values.length; i++) {
      for (let j = 0; j < ports.length; j++) {
        if (ports[j].id === values[i]) {
          result.push({
            id: ports[j].id,
            name: ports[j].name,
            shortName: ports[j].shortName,
            draft: ports[j].draft,
          })
        }
      }
    }
    return result
  }
  const customValidation = {
    dischargePortArray: false,
    dischargePortArrayBunker: false,
    dischargePortArrayIdleWorkingArray: false,
    dischargePortWaitingIdle: false,
    dischargeTermsArray: false,
    dischargeTimeCountingArray: false,
    loadPortArray: false,
    loadPortArrayBunkerArray: false,
    loadPortArrayIdleWorking: false,
    loadPortWaitingIdle: false,
    loadTermsArray: false,
    loadTimeCountingArray: false,
    viaBallastPortIDArray: false,
    viaBallastPortBunkerArray: false,
    viaBallastPortIdleWorking: false,
    viaBallastPortWaitingIdle: false,
    viaLadenPortIDArray: false,
    viaLadenPortBunkerArray: false,
    viaLadenPortIdleWorking: false,
    viaLadenPortWaitingIdle: false,
    showViaLadenPort: false,
  }

  const validation = {
    ballastBonus: ballastBonusRequired,
    commodity: commodityRequired,
    constants: constantsRequired,
    deliveryPort: deliveryPortRequired,
    deliveryPortBunker: deliveryPortBunkerRequired,
    extraInsurance: extraInsuranceRequired,
    forwardCurve: forwardCurveRequired,
    miscCost: miscRequired,
    routeName: nameRequired,
    linkedRouteArrays: linkedRouteArrays,
    stem: stemRequired,
    tolerance: toleranceRequired,
    vessel: vesselRequired,
  }

  const emptyPorts = {
    portArray: "",
    portBunkerArray: false,
    portIdleWorkingArray: false,
    portWaitingIdleArray: "",
    portTerms: "",
    portTimeCounting: "",
    validate: false,
  }

  const emptySpeedPorts = {
    portArray: "",
    portBunkerArray: false,
    portIdleWorkingArray: false,
    portWaitingIdleArray: "",
    validate: false,
    showViaLadenPort: false
  }

  const emptyViaLaden = {
    portArray: "",
    portBunkerArray: false,
    portIdleWorkingArray: false,
    portWaitingIdleArray: "",
    validate: false,
    showViaLadenPort: true
  }

  const createPorts = (
    portsArray,
    portBunkerArray,
    portTerms,
    portTimeCounting,
    portIdleWorking,
    portWaitingIdle
  ) => {
    const result = []
    for (let i = 0; i < portsArray.length; i++) {
      result.push({
        portArray: portsArray[i],
        portBunkerArray: portBunkerArray[i],
        portIdleWorkingArray: !!portIdleWorking[i],
        portWaitingIdleArray: portWaitingIdle[i],
        portTerms: portTerms[i],
        portTimeCounting: portTimeCounting[i],
        validate: !!(portsArray[i] && portTerms[i] && portTimeCounting[i]),
      })
    }
    return result
  }

  const createSpeedPorts = (
    portsArray,
    portBunkerArray,
    portIdleWorking,
    portWaitingIdle,
    showViaLadenPort = false
  ) => {

    const result = []
    for (let i = 0; i < portsArray.length; i++) {
      result.push({
        portArray: portsArray[i],
        portBunkerArray: portBunkerArray[i],
        portIdleWorkingArray: !!portIdleWorking[i],
        portWaitingIdleArray: portWaitingIdle[i],
        ...(showViaLadenPort ?  { showViaLadenPort: showViaLadenPort[i] } : '')
      })
    }
    return result
  }

  const neededLoadValues = {
    loadPortArray: route
      ? getPortsFormatValues({ values: route.loadPorts, keyname: "portName" })
      : "",
    loadPortArrayBunkerArray: route ? route.loadPortArrayBunkerArray : "",
    loadPortArrayIdleWorking: route ? route.loadPortArrayIdleWorking : "",
    loadPortWaitingIdle: route ? route.loadPortWaitingIdle : "",
    loadTermsArray: route ? route.loadTermsArray : "",
    loadTimeCountingArray: route
      ? getTimeCountingsFormatValues({
        values: route.loadTimeCountingArray,
        keyname: "timeCountingName",
      })
      : "",
  }

  const neededDischargeValues = {
    dischargePortArray: route
      ? getPortsFormatValues({
        values: route.dischargePorts,
        keyname: "portName",
      })
      : "",
    dischargePortArrayBunker: route ? route.dischargePortArrayBunker : "",
    dischargePortArrayIdleWorkingArray: route
      ? route.dischargePortArrayIdleWorkingArray
      : "",
    dischargePortWaitingIdle: route ? route.dischargePortWaitingIdle : "",
    dischargeTermsArray: route ? route.dischargeTermsArray : "",
    dischargeTimeCountingArray: route
      ? getTimeCountingsFormatValues({
        values: route.dischargeTimeCountingArray,
        keyname: "timeCountingName",
      })
      : "",
  }

  const neededBallastValues = {
    viaBallastPortIDArray: route
      ? getPortsFormatValues({
        values: route.viaBallastPorts,
        keyname: "portName",
      })
      : "",
    viaBallastPortBunkerArray: route ? route.viaBallastPortBunkerArray : "",
    viaBallastPortWaitingIdle: route ? route.viaBallastPortWaitingIdle : "",
    viaBallastPortIdleWorking: route ? route.viaBallastPortIdleWorking : "",
  }

  const neededLadenValues = {
    viaLadenPortIDArray: route
      ? getPortsFormatValues({
        values: route.viaLadenPorts,
        keyname: "portName",
      })
      : "",
    viaLadenPortBunkerArray: route ? route.viaLadenPortBunkerArray : "",
    viaLadenPortWaitingIdle: route ? route.viaLadenPortWaitingIdle : "",
    viaLadenPortIdleWorking: route ? route.viaLadenPortIdleWorking : "",
    showViaLadenPort: route ? route.showViaLadenPort : ""
  }

  const initialValues = {
    ballastBonus: route ? route.ballastBonus : "",
    clients:
      route && route.clients
        ? getFormatValues({ values: route.clients, keyname: "name" })
        : [],
    commodity: route ? route.commodity.id : "",
    spotTCSpread: route
      ? getFormatSpotTcValues({
        values: route.spotTCSpread,
        keyname: "spotTCSpreadName",
      })
      : "",
    commission: route ? route.commission : 1.25,
    constants: route ? route.constants : 1500,
    apsDeliveryPort:
      route && route.apsDeliveryPort ? route.apsDeliveryPort.id : "",
    deliveryPort: route && route.deliveryPort ? route.deliveryPort.id : "",
    deliveryPortBunker: route ? route.deliveryPortBunker : false,
    deliveryPortIdleWorking: false,
    deliveryPortWaitingIdle: route ? route.deliveryPortWaitingIdle : 0,
    description: route ? route.description : "",
    dischargePorts: route
      ? createPorts(
        neededDischargeValues.dischargePortArray,
        neededDischargeValues.dischargePortArrayBunker,
        neededDischargeValues.dischargeTermsArray,
        neededDischargeValues.dischargeTimeCountingArray,
        neededDischargeValues.dischargePortArrayIdleWorkingArray,
        neededDischargeValues.dischargePortWaitingIdle
      )
      : [emptyPorts],
    extraInsurance: route ? route.extraInsurance : "0",
    forwardCurve: route ? route.forwardCurve.id : "",
    miscCost: route ? route.miscCost : "10000",
    routeName: route ? route.routeName : "",
    linkedRouteArrays: route
      ? getFormatValues({
        values: route.linkedRouteArrays,
        keyname: "routeName",
      })
      : "",
    loadPorts: route
      ? createPorts(
        neededLoadValues.loadPortArray,
        neededLoadValues.loadPortArrayBunkerArray,
        neededLoadValues.loadTermsArray,
        neededLoadValues.loadTimeCountingArray,
        neededLoadValues.loadPortArrayIdleWorking,
        neededLoadValues.loadPortWaitingIdle
      )
      : [emptyPorts],
    redeliveryPort:
      route && route.redeliveryPort ? route.redeliveryPort.id : "",
    redeliveryPortBunker: route ? route.redeliveryPortBunker : false,
    redeliveryPortIdleWorking: route ? route.redeliveryPortIdleWorking : false,
    redeliveryPortWaitingIdle: route ? route.redeliveryPortWaitingIdle : 0,
    stem: route ? route.stem : "",
    tolerance: route ? route.tolerance : "",
    vessel: route ? route.vessel.id : "",
    viaBallastPorts: route
      ? createSpeedPorts(
        neededBallastValues.viaBallastPortIDArray,
        neededBallastValues.viaBallastPortBunkerArray,
        neededBallastValues.viaBallastPortIdleWorking,
        neededBallastValues.viaBallastPortWaitingIdle
      )
      : [emptySpeedPorts],
    viaLadenPorts: route
      ? createSpeedPorts(
        neededLadenValues.viaLadenPortIDArray,
        neededLadenValues.viaLadenPortBunkerArray,
        neededLadenValues.viaLadenPortIdleWorking,
        neededLadenValues.viaLadenPortWaitingIdle,
        neededLadenValues.showViaLadenPort
      )
      : [emptyViaLaden],
    viaRedeliveryPort:
      route && route.viaRedeliveryPort ? route.viaRedeliveryPort.id : "",
    viaRedeliveryPortBunker: route ? route.viaRedeliveryPortBunker : false,
    viaRedeliveryPortIdleWorking: route
      ? route.viaRedeliveryPortIdleWorking
      : false,
    viaRedeliveryPortWaitingIdle: route
      ? route.viaRedeliveryPortWaitingIdle
      : 0,
  }

  const booleanOptions = [
    {
      name: "True",
      id: 1,
    },
    {
      name: "False",
      id: 2,
    },
  ]

  const [forwardOptions, setForwardOptions] = useState(
    getFilteredForwardCurves(
      forwardCurves,
      getShipSector(
        shipSectors,
        getVessel(vessels, initialValues ? initialValues.vessel : null)?.dwt
      )
    )
  )

  const [spotTCSpreadOptions, setSpotTCSpreadOptions] = useState(
    route ? route.forwardCurve.spotTCSpreads.map(item => { return { id: item.id, name: item.spotTCSpreadName } }).sort(orderBySpreadName) : []
  )

  const [selectedSpreads, setSelectedSpreads] = useState(
    route ? route.spotTCSpread.map(a => { return { id: a.id, name: a.spotTCSpreadName } }) : []
  )

  const form1 = {
    routeName: useFormInput({
      initialValue: initialValues.routeName,
      label: "routeName",
      required: true,
    }),
    description: useFormInput({
      initialValue: initialValues.description,
      label: "Description",
    }),
    vessel: useFormInput({
      initialValue: initialValues.vessel,
      label: "Vessel",
      select: true,
      selectValues: vessels,
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    tolerance: useFormInput({
      initialValue: initialValues.tolerance,
      label: "Tolerance",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    stem: useFormInput({
      initialValue: initialValues.stem,
      label: "Stem",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    commodity: useFormInput({
      initialValue: initialValues.commodity,
      clearable: true,
      label: "Commodity",
      select: true,
      selectValues: commodities,
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    forwardCurve: useFormInput({
      initialValue: initialValues.forwardCurve,
      label: "forwardCurves",
      select: true,
      selectValues: forwardOptions,
      required: true,
      isCalc: true,
      setEditRouteResults
    }),
    // ballastBonus: useFormInput({ initialValue: initialValues.ballastBonus, label: 'ballastBonus', type: 'number', required: true, isCalc: true, setEditRouteResults }),
    commission: useFormInput({
      initialValue: initialValues.commission,
      label: "Commission",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    constants: useFormInput({
      initialValue: initialValues.constants,
      label: "Constants",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    extraInsurance: useFormInput({
      initialValue: initialValues.extraInsurance,
      label: "extraInsurance",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
    miscCost: useFormInput({
      initialValue: initialValues.miscCost,
      label: "miscCost",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }

  useEffect(() => {
    const selectedCurve = findDataWithId({ key: 'id', searchIn: forwardCurves, id: form1.forwardCurve.getValue()})
    const newSpreadOptions = selectedCurve ? selectedCurve.spotTCSpreads.map(item => { return { id: item.id, name: item.spotTCSpreadName } }).sort(orderBySpreadName) : []
    const newSpreadOptionsIds = newSpreadOptions.map(item => item.id)
    setSpotTCSpreadOptions(newSpreadOptions)
    setSelectedSpreads(prev => prev.filter(spread => newSpreadOptionsIds.includes(spread.id)))
  }, [form1.forwardCurve.getValue()])


  const initialSummary = {
    clients: route && route.summary ? route.summary.clients : '',
    stemTolerance: route && route.summary ? route.summary.stemTolerance : '',
    loadDischarge: route && route.summary ? route.summary.loadDischarge : '',
    laycan: route && route.summary ? route.summary.laycan : '',
    loadDischRateTerms: route && route.summary ? route.summary.loadDischRateTerms : '',
    addcom: route && route.summary ? route.summary.addcom : ''
  }

  const [summary, setSummary] = useState({ ...initialSummary })
  const [openSummary, setOpenSummary] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOpenSummary = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenSummary(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenSummary(false)
  }

  const [codeNameVessel, setCodeNameVessel] = useState("")

  const firstRender = useRef(true)

  const vesselRef = useRef(form1.vessel.getValue())

  useEffect(() => {
    if (vesselRef.current !== form1.vessel.getValue()) {
      vesselRef.current = form1.vessel.getValue()

      setCodeNameVessel(
        getShipSector(
          shipSectors,
          getVessel(vessels, form1.vessel.getValue())?.dwt
        )?.shipSectorName
      )
      setForwardOptions(
        getFilteredForwardCurves(
          forwardCurves,
          getShipSector(
            shipSectors,
            getVessel(vessels, form1.vessel.getValue())?.dwt
          )
        )
      )

      form1.forwardCurve.setValue("")
    }
  })

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      if (initialValues?.vessel) {
        setCodeNameVessel(
          getShipSector(
            shipSectors,
            getVessel(vessels, initialValues.vessel)?.dwt
          )?.shipSectorName
        )
      }
    }
  })
  const form5 = {
    apsDeliveryPort: useFormInput({
      initialValue: initialValues.apsDeliveryPort,
      label: "Aps Delivery Port",
      select: true,
      selectValues: ports,
      required: false,
      smallInput: true,
    }),
    // apsDeliveryPortBunker: useFormInput({ initialValue: initialValues.deliveryPortBunker, label: 'Delivery Port Bunker', select: true, selectValues: booleanOptions, isBoolean: true, required: true }),
    deliveryPort: useFormInput({
      initialValue: initialValues.deliveryPort,
      label: "Delivery Port",
      select: true,
      selectValues: ports,
      required: true,
      isCalc: true,
      setEditRouteResults,
      smallInput: true,
    }),
  }

  const form5a = {
    deliveryPortWaitingIdle: useFormInput({
      initialValue: initialValues.deliveryPortWaitingIdle,
      label: "Waiting Idle",
      type: "number",
      required: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }

  const form4 = {
    spotTCSpread: useFormInput({
      initialValue: initialValues.spotTCSpread,
      label: "spotTCSpread",
      select: true,
      selectValues: spotTCSpreadOptions,
      isMulti: true,
      isCalc: true,
      setEditRouteResults,
      value: selectedSpreads,
      onChange: (event) => {
        setSelectedSpreads(event.value)
      }
      // labelKey: "spotTCSpreadName",
    }),
  }

  const form2 = {
    viaRedeliveryPort: useFormInput({
      initialValue: initialValues.viaRedeliveryPort,
      label: "Via Redelivery Port",
      select: true,
      selectValues: ports,
      required: false,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
    viaRedeliveryPortBunker: useFormInput({
      initialValue: initialValues.viaRedeliveryPortBunker,
      label: "viaRedeliveryPortBunker",
      select: true,
      selectValues: booleanOptions,
      isBoolean: true,
      required: true,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
    redeliveryPort: useFormInput({
      initialValue: initialValues.redeliveryPort,
      label: "Redelivery Port",
      select: true,
      selectValues: ports,
      required: false,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
    redeliveryPortBunker: useFormInput({
      initialValue: initialValues.redeliveryPortBunker,
      label: "Redelivery Port Bunker",
      select: true,
      selectValues: booleanOptions,
      isBoolean: true,
      required: true,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }
  const form2a = {
    viaRedeliveryPort: useFormInput({
      initialValue: initialValues.viaRedeliveryPort,
      label: "Via Redelivery Port",
      select: true,
      selectValues: ports,
      required: false,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }
  const form2b = {
    viaRedeliveryPortBunker: useFormInput({
      initialValue: initialValues.viaRedeliveryPortBunker,
      label: "viaRedeliveryPortBunker",
      select: true,
      selectValues: booleanOptions,
      isBoolean: true,
      required: true,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }
  const form2e = {
    viaRedeliveryPortWaitingIdle: useFormInput({
      initialValue: initialValues.viaRedeliveryPortWaitingIdle,
      label: "Waiting Idle",
      type: "number",
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }
  const form2c = {
    redeliveryPort: useFormInput({
      initialValue: initialValues.redeliveryPort,
      label: "Redelivery Port",
      select: true,
      selectValues: ports,
      required: false,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }
  const form2f = {
    redeliveryPortWaitingIdle: useFormInput({
      initialValue: initialValues.redeliveryPortWaitingIdle,
      label: "Waiting Idle",
      type: "number",
      required: true,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }
  const form2d = {
    redeliveryPortBunker: useFormInput({
      initialValue: initialValues.redeliveryPortBunker,
      label: "Redelivery Port Bunker",
      select: true,
      selectValues: booleanOptions,
      isBoolean: true,
      required: true,
      smallInput: true,
      isCalc: true,
      setEditRouteResults,
    }),
  }


  const form3 = {
    linkedRouteArrays: useFormInput({
      initialValue: initialValues.linkedRouteArrays,
      label: "Linked Routes",
      select: true,
      selectValues: routes,
      isMulti: true,
      required: false,
      smallInput: true,
    }),
    // clients: useFormInput({ initialValue: initialValues.clients, label: 'Clients', select: true, selectValues: users, isMulti: true }),
  }

  const form = {
    ...form1,
    ...form2a,
    ...form2b,
    ...form2c,
    ...form2d,
    ...form2e,
    ...form2f,
    ...form3,
    ...form4,
    ...form5,
    ...form5a,
  }
  const [handleLoadPorts, setHandleLoadPorts] = useReducer(
    reducerLoadDischarge,
    [...initialValues.loadPorts]
  )
  const [
    handleDischargePorts,
    setHandleDischargePorts,
  ] = useReducer(reducerLoadDischarge, [...initialValues.dischargePorts])
  const [handleViaBallastPorts, setHandleViaBallastPorts] = useReducer(
    reducer,
    [...initialValues.viaBallastPorts]
  )
  const [handleViaLadenPorts, setHandleViaLadenPorts] = useReducer(reducer, [
    ...initialValues.viaLadenPorts,
  ])
  const [portsValidationFailed, setPortsValidationFailed] = useState(false)
  const [checkboxValues, setCheckboxValues] = useReducer(handleCheckbox, {
    deliveryPortBunker: initialValues.deliveryPortBunker,
    deliveryPortIdleWorking: initialValues.deliveryPortIdleWorking,
    redeliveryPortBunker: initialValues.redeliveryPortBunker,
    redeliveryPortIdleWorking: initialValues.redeliveryPortIdleWorking,
    viaRedeliveryPortBunker: initialValues.viaRedeliveryPortBunker,
    viaRedeliveryPortIdleWorking: initialValues.viaRedeliveryPortIdleWorking,
  })

  const getPortsValues = (data, target) => {
    return data.map((item) => {
      if (item[target] === "") {
        customValidation[target] = true
        return customValidation[target]
      } else if (item[target] && item[target].id) {
        return item[target].id
      } else {
        return item[target]
      }
    })
  }

  const validatePortValues = (data, target) => {
    // TODO CHECK
    if (data[0]) {
      if (target === "portArray") {
        if (data[0].portArray) {
          return getPortsValues(data, target)
        }
      } else if (target === "portBunkerArray") {
        return getPortsValues(data, target)
      } else if (target === "showViaLadenPort") {
        return getPortsValues(data, target)
      } else if (target === "portTerms") {
        if (data[0].portTerms) {
          return getPortsValues(data, target)
        }
      } else if (target === "portTimeCounting") {
        if (data[0].portTimeCounting) {
          return getPortsValues(data, target)
        }
      } else if (target === "portIdleWorkingArray") {
        return getPortsValues(data, target)
      } else if (target === "portWaitingIdleArray") {
        if (data[0].portWaitingIdleArray) {
          return getPortsValues(data, target)
        }
      }
    }
  }

  // Names
  const concatClientNames = (array) => {
    let string = ""
    for (let i = 0; i < array.length; i++) {
      string += `${array[i].name} `
    }
    return string
  }

  const getCommodityName = (id) => {
    let string = ""
    // console.log(id);
    for (let i = 0; i < commodities.length; i++) {
      if (commodities[i].id === id) {
        string = commodities[i].name
      }
    }
    return string
  }

  const getPortsName = (id) => {
    let string = ""
    for (let i = 0; i < ports.length; i++) {
      if (ports[i].id === id) {
        string = ports[i].shortName
      }
    }
    return string
  }

  const codeNameDraft =
    handleDischargePorts[0].portArray && handleDischargePorts[0].portArray.draft
      ? handleDischargePorts[0].portArray.draft
      : ""
  const codeNameClients =
    route && route.clients ? concatClientNames(route.clients) : ""
  const codeNameStem = form.stem.getValue() ? (form.stem.getValue() / 1000).toFixed(0) : ""
  const codeNameTolerance = form.tolerance.getValue()
    ? form.tolerance.getValue()
    : ""
  const codeNameCommodity = !form.commodity.value?.id
    ? getCommodityName(form.commodity.value)
    : getCommodityName(form.commodity.value.id)
  const codeNameLoadPort =
    handleLoadPorts[0].portArray && handleLoadPorts[0].portArray.shortName
      ? handleLoadPorts[0].portArray.shortName
      : ""
  const codeNameDischargePort =
    handleDischargePorts[0].portArray && handleDischargePorts[0].portArray.shortName
      ? handleDischargePorts[0].portArray.shortName
      : ""
  const codeNameDeliveryPort = !form.deliveryPort.value?.id
    ? getPortsName(form.deliveryPort.value)
    : getPortsName(form.deliveryPort.value.id)
  const codeNameViaLadenPort =
    handleViaLadenPorts.length > 0 &&
      handleViaLadenPorts[0].portArray &&
      handleViaLadenPorts[0].portArray.shortName
      ? handleViaLadenPorts[0].portArray.shortName
      : ""
  const codeNameRedeliveryPort = !form.redeliveryPort.value?.id
    ? getPortsName(form.redeliveryPort.value)
    : getPortsName(form.redeliveryPort.value.id)
  const codeNameLong =
    codeNameVessel &&
    `${codeNameVessel} ${codeNameStem}'${codeNameTolerance} ${codeNameCommodity} ${codeNameLoadPort}/${codeNameDischargePort}${codeNameDraft ? "(" : ""
    }${codeNameDraft}${codeNameDraft ? 'm' : ''}${codeNameDraft ? ")" : ""} - ${codeNameDeliveryPort}${codeNameViaLadenPort ? '/' : ''}${codeNameViaLadenPort}${codeNameRedeliveryPort ? "/" : ''}${codeNameRedeliveryPort}`
  const input = {
    ...(route && { id: route.id }),
    ballastBonus: 0, // form.ballastBonus.getValue(),
    codeNameLong,
    // clients: form.clients.getValue() || [],
    commodity: form.commodity.getValue(),
    commission: form.commission.getValue(),
    constants: form.constants.getValue(),
    apsDeliveryPort: form.apsDeliveryPort.getValue(),
    deliveryPort: form.deliveryPort.getValue(),
    deliveryPortBunker: checkboxValues.deliveryPortBunker,
    deliveryPortIdleWorking: false, // TODO UNCOMMENT
    deliveryPortWaitingIdle: form.deliveryPortWaitingIdle.getValue(), // TODO UNCOMMENT
    description: form.description.getValue(),
    dischargePortArray: validatePortValues(handleDischargePorts, "portArray"),
    dischargePortArrayBunker: validatePortValues(
      handleDischargePorts,
      "portBunkerArray"
    ),
    dischargePortArrayIdleWorkingArray: validatePortValues(
      handleDischargePorts,
      "portIdleWorkingArray"
    ), // TODO UNCOMMENT
    dischargePortWaitingIdle: validatePortValues(
      handleDischargePorts,
      "portWaitingIdleArray"
    )
      ? validatePortValues(handleDischargePorts, "portWaitingIdleArray")
      : [],
    dischargeTermsArray: validatePortValues(handleDischargePorts, "portTerms"),
    dischargeTimeCountingArray: validatePortValues(
      handleDischargePorts,
      "portTimeCounting"
    )
      ? validatePortValues(handleDischargePorts, "portTimeCounting")
      : [],
    extraInsurance: form.extraInsurance.getValue(),
    forwardCurve: form.forwardCurve.getValue(),
    miscCost: form.miscCost.getValue(),
    routeName: form.routeName.getValue(),
    linkedRouteArrays: form.linkedRouteArrays.getValue(),
    loadPortArray: validatePortValues(handleLoadPorts, "portArray"),
    loadPortArrayBunkerArray: validatePortValues(
      handleLoadPorts,
      "portBunkerArray"
    ),
    loadPortArrayIdleWorking: validatePortValues(
      handleLoadPorts,
      "portIdleWorkingArray"
    ), // TODO UNCOMMENT
    loadPortWaitingIdle: validatePortValues(
      handleLoadPorts,
      "portWaitingIdleArray"
    )
      ? validatePortValues(handleLoadPorts, "portWaitingIdleArray")
      : [],
    loadTermsArray: validatePortValues(handleLoadPorts, "portTerms"),
    loadTimeCountingArray: validatePortValues(
      handleLoadPorts,
      "portTimeCounting"
    )
      ? validatePortValues(handleLoadPorts, "portTimeCounting")
      : [],
    redeliveryPort: form.redeliveryPort.getValue(),
    redeliveryPortBunker:
      form.redeliveryPort.getValue() !== null
        ? checkboxValues.redeliveryPortBunker
        : false,
    redeliveryPortIdleWorking:
      form.redeliveryPort.getValue() !== null
        ? checkboxValues.redeliveryPortIdleWorking
        : false, // TODO UNCOMMENT
    redeliveryPortWaitingIdle:
      form.redeliveryPort.getValue() !== null
        ? form.redeliveryPortWaitingIdle.getValue()
        : 0,
    stem: form.stem.getValue(),
    summary,
    spotTCSpread: selectedSpreads.map(item => item.id),
    tolerance: form.tolerance.getValue(),
    vessel: form.vessel.getValue(),
    viaBallastPortIDArray: validatePortValues(
      handleViaBallastPorts,
      "portArray"
    ),
    viaBallastPortBunkerArray: validatePortValues(
      handleViaBallastPorts,
      "portBunkerArray"
    ),
    viaBallastPortIdleWorking: validatePortValues(
      handleViaBallastPorts,
      "portIdleWorkingArray"
    ), // TODO UNCOMMENT
    viaBallastPortWaitingIdle: validatePortValues(
      handleViaBallastPorts,
      "portWaitingIdleArray"
    )
      ? validatePortValues(handleViaLadenPorts, "portWaitingIdleArray")
      : [],
    viaLadenPortIDArray: validatePortValues(handleViaLadenPorts, "portArray"),
    viaLadenPortBunkerArray: validatePortValues(
      handleViaLadenPorts,
      "portBunkerArray"
    ),
    showViaLadenPort: validatePortValues(
      handleViaLadenPorts,
      "showViaLadenPort"
    ),
    viaLadenPortIdleWorking: validatePortValues(
      handleViaLadenPorts,
      "portIdleWorkingArray"
    ), // TODO UNCOMMENT
    viaLadenPortWaitingIdle: validatePortValues(
      handleViaLadenPorts,
      "portWaitingIdleArray"
    )
      ? validatePortValues(handleViaLadenPorts, "portWaitingIdleArray")
      : [],
    viaRedeliveryPort: form.viaRedeliveryPort.getValue(),
    viaRedeliveryPortBunker:
      form.viaRedeliveryPort.getValue() !== null
        ? checkboxValues.viaRedeliveryPortBunker
        : false,
    viaRedeliveryPortIdleWorking:
      form.viaRedeliveryPort.getValue() !== null
        ? checkboxValues.viaRedeliveryPortIdleWorking
        : false, // TODO UNCOMMENT
    viaRedeliveryPortWaitingIdle:
      form.viaRedeliveryPort.getValue() !== null
        ? form.viaRedeliveryPortWaitingIdle.getValue()
        : 0, // TODO UNCOMMENT
    editRouteResults,
    dischargePorts: validatePortValues(handleDischargePorts, "portArray"),
    loadPorts: validatePortValues(handleLoadPorts, "portArray"),
    viaBallastPorts: validatePortValues(handleViaBallastPorts, "portArray"),
    viaLadenPorts: validatePortValues(handleViaLadenPorts, "portArray"),
  }

  const [activeStep, setActiveStep] = useState(0)
  const steps = getSteps()

  const handleNext = () => {
    // This is only for this component, wheen a next is clicked we will handle the bunker arrays here
    const valid = validateStep({
      form,
      next: () => setActiveStep((prevActiveStep) => prevActiveStep + 1),
      validation,
    })

    if (valid) {
      let length = form.dischargePortArray.getValue().length
      let array = []
      if (length > 0) {
        for (let i = 0; i < length; i++) {
          array.push(false)
        }
      }
      // setDischargePortArrayBunkerBoolean(array)
      length = form.loadPortArray.getValue().length
      array = []
      if (length > 0) {
        for (let i = 0; i < length; i++) {
          array.push(false)
        }
      }
      // setLoadPortArrayBunkerArrayBoolean(array)
    }
  }

  const validatePortsInitial = (portsArray) => {
    let validate = true
    for (let i = 0; i < portsArray.length; i++) {
      const currentPort = portsArray[i]
      !currentPort.portArray && (validate = false)
      !currentPort.portTimeCounting && (validate = false)
      !currentPort.portTerms && (validate = false)
      if (!validate) {
        break
      }
    }
    return validate
  }

  // const validatePorts = (portsArray, setValidation) => {
  //   let validate = true
  //   for (let i = 0; i < portsArray.length; i++) {
  //     const currentPort = portsArray[i]
  //     !currentPort.portArray && (validate = false)
  //     !currentPort.portTimeCounting && (validate = false)
  //     !currentPort.portTerms && (validate = false)
  //     if (!validate) {
  //       break
  //     }
  //   }
  //   if (validate && typeof setValidation === 'function') {
  //     setValidation(true)
  //   } else {
  //     setValidation(false)
  //   }
  //   return validate
  // }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const [validationDischargePorts, setValidationDischargePorts] = useState(
    validatePortsInitial(handleDischargePorts)
  )

  const [validationLoadPorts, setValidationLoadPorts] = useState(
    validatePortsInitial(handleLoadPorts)
  )
  const next = ({ mutation }) => {
    let validate = true

    for (let i = 0; i < handleDischargePorts.length; i++) {
      const element = handleDischargePorts[i]
      if (element.validate === false) {
        validate = false
        break
      }
    }

    for (let i = 0; i < handleLoadPorts.length; i++) {
      const element = handleLoadPorts[i]
      if (element.validate === false) {
        validate = false
        break
      }
    }

    // for (let i = 0; i < handleViaBallastPorts.length; i++) {
    //   if (handleViaBallastPorts[i].portArray === '') {
    //     validate = true
    //   }
    // }
    // for (let i = 0; i < handleViaLadenPorts.length; i++) {
    //   if (handleViaLadenPorts[i].portArray === '') {
    //     validate = true
    //   }
    // }

    if (validate) {
      validateStep({
        form,
        next: () => mutation(),
        validation,
      })
    } else {
      setPortsValidationFailed(true)
    }
  }

  const classes = useStyles()
  const refetchQueries = [
    {
      query: CREATE_ROUTE_INFORMATION,
    },
    {
      query: GET_SIMPLE_ROUTES,
    },
  ]

  if (route) {
    refetchQueries.push({
      query: GET_CALCULATION_ROUTE_INFO,
      variables: { input: { id: route.id } },
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_ROUTE}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted })}
      variables={{ input }}
      refetchQueries={refetchQueries}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper className={classes.paper}>
              <RootBreadCrumb
                current={content.current}
                links={content.breadCrumbs}
                style={{ flexGrow: 1 }}
              >
                <Box flexGrow={1} display='flex' justifyContent='flex-end' alignItems='center'>
                  <Button
                    color="primary"
                    onClick={handleOpenSummary}
                  >
                    Summary
                </Button>
                  <EditSummary stem={form.stem} tolerance={form.tolerance} anchorEl={anchorEl} open={openSummary} handleClose={handleClose} setSummary={setSummary} summary={summary} />
                  {route && (
                    <Button
                      noMargin
                      color="primary"
                      onClick={() =>
                        props.history.push(`/calculation-page/${route.id}`)
                      }
                    >
                      Go To Calc
                    </Button>
                  )}
                </Box>

              </RootBreadCrumb>
              {route && route.error ? (
                <Typography
                  className={classes.errorName}
                >{`Error: ${route.error}`}</Typography>
              ) : (
                  ""
                )}
              {activeStep === 0 ? (
                <CreateRouteStepOne
                  emptyValue={emptyPorts}
                  emptySpeedValue={emptySpeedPorts}
                  summary={summary}
                  form1={form1}
                  form2={form2}
                  form2a={form2a}
                  form2b={form2b}
                  form2c={form2c}
                  form2d={form2d}
                  form2e={form2e}
                  form2f={form2f}
                  form3={form3}
                  form4={form4}
                  form5={form5}
                  form5a={form5a}
                  codeNameLong={codeNameLong}
                  checkboxValues={checkboxValues}
                  setCheckboxValues={setCheckboxValues}
                  initialValues={initialValues}
                  handleDischargePorts={handleDischargePorts}
                  setEditRouteResults={setEditRouteResults}
                  handleLoadPorts={handleLoadPorts}
                  handleViaBallastPorts={handleViaBallastPorts}
                  handleViaLadenPorts={handleViaLadenPorts}
                  portsValidationFailed={portsValidationFailed}
                  setHandleDischargePorts={setHandleDischargePorts}
                  setHandleLoadPorts={setHandleLoadPorts}
                  setHandleViaBallastPorts={setHandleViaBallastPorts}
                  setHandleViaLadenPorts={setHandleViaLadenPorts}
                  validation={validation}
                  ports={ports}
                  timeCountings={timeCountings}
                  validationLoadPorts={validationLoadPorts}
                  validationDischargePorts={validationDischargePorts}
                  setValidationDischargePorts={setValidationDischargePorts}
                  setValidationLoadPorts={setValidationLoadPorts}
                />
              ) : (
                  ""
                )}
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <RootGrid direction="row" justify="flex-end">
                <RootButton
                  style={{
                    borderRadius: '5px',
                    marginRight: '15px'
                  }}
                  justifyContent="center"
                  disabled={activeStep === 0}
                  onClick={() => handleBack()}
                >
                  Back
                </RootButton>
                {route && route.id ? (
                  <HandleDuplicate
                    id={route.id}
                    history={history}
                    user={user}
                  />
                ) : (
                    ""
                  )}
                <RootButton
                  style={{
                    borderRadius: '5px',
                    backgroundColor: 'rgba(91, 196, 34, 1)',
                    color: 'white',
                    marginRight: '15px'
                  }}
                  disabled={
                    (activeStep !== -1 && result.loading) || user.disabled
                  }
                  justifyContent="center"
                  onClick={
                    activeStep === 0
                      ? () => next({ mutation })
                      : () => handleNext()
                  }
                >
                  {activeStep === -1 ? "Continue" : content.submitButton}
                </RootButton>
              </RootGrid>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateRoute.propTypes = {
  route: PropTypes.object,
}

const CreateRouteStepOne = ({ initialValues, ...props }) => {
  const {
    codeNameLong,
    checkboxValues,
    emptyValue,
    emptySpeedValue,
    form1,
    // form2,
    form2a,
    // form2b,
    form2c,
    // form2d,
    // form2e,
    // form2f,
    form3,
    form4,
    form5,
    // form5a,
    handleDischargePorts,
    handleLoadPorts,
    handleViaBallastPorts,
    handleViaLadenPorts,
    portsValidationFailed,
    setCheckboxValues,
    setEditRouteResults,
    setHandleDischargePorts,
    setHandleLoadPorts,
    setHandleViaBallastPorts,
    setHandleViaLadenPorts,
    validation,
    ports,
    summary,
    timeCountings,
    validationDischargePorts,
    validationLoadPorts,
    setValidationDischargePorts,
    setValidationLoadPorts,
  } = props
  // TODO DELETE VIA BALLAST
  // TODO FORWARD CURVE IS REQUIRED BUT NO ERROR IS SEND IF IT IS NOT PRESSENT
  const classes = useStyles()

  const ViaBallastButton = () => {
    return (
      <IconButton
        justifyContent="center"
        size="small"
        onClick={() =>
          setHandleViaBallastPorts({ type: "add", emptyValue: emptySpeedValue })
        }
      >
        <AddIcon />
      </IconButton>
    )
  }

  const LoadPortButton = () => {
    return (
      <IconButton
        justifyContent="center"
        size="small"
        onClick={() =>
          setHandleLoadPorts({ type: "add", emptyValue: emptyValue })
        }
      >
        <AddIcon />
      </IconButton>
    )
  }

  const DischargeButton = () => {
    return (
      <IconButton
        justifyContent="center"
        size="small"
        onClick={() =>
          setHandleDischargePorts({ type: "add", emptyValue: emptyValue })
        }
      >
        <AddIcon />
      </IconButton>
    )
  }

  return (
    <Grid container>
      <Grid container>
        <RootTextField fullWidth marginBottom className={classes.textField} disabled value={codeNameLong} />
      </Grid>
      <Grid container>
        <RootForm form={form1} itemSize={3} validation={validation} />
        <RootForm
          autoFocus={false}
          form={form4}
          itemSize={12}
          validation={validation}
          autoFocus={false}
        />
        <RootForm
          autoFocus={false}
          form={form5}
          itemSize={4}
          autoFocus={false}
          validation={validation}
        />
        {/* <RootForm
          form={form5a}
          itemSize={1}
          validation={validation}
        /> */}
        <RootCheckbox
          label="Bunker"
          onChange={(checked) =>
            setCheckboxValues({
              type: "handleChange",
              checked,
              name: "deliveryPortBunker",
            })
          }
          checked={checkboxValues.deliveryPortBunker}
          value={checkboxValues.deliveryPortBunker}
          classes={classes}
        />
      </Grid>
      <Divider className={classes.greyDivider} />
      <PortsRenderComponent
        addButtonProps={{
          justifyContent: "center",
          onClick: () =>
            setHandleViaBallastPorts({
              type: "add",
              emptyValue: emptySpeedValue,
            }),
        }}
        isValid={portsValidationFailed}
        setEditRouteResults={setEditRouteResults}
        name="Via Ballast Ports"
        setHandlePorts={setHandleViaBallastPorts}
        ports={ports}
        button={<ViaBallastButton />}
        portsToMap={handleViaBallastPorts}
        shouldRemoveOne
        validationLoadPorts={validationLoadPorts}
        setValidationLoadPorts={setValidationLoadPorts}
        setValidationDischargePorts={setValidationDischargePorts}
        validationDischargePorts={validationDischargePorts}
      />
      <Divider className={classes.greyDivider} />
      <PortsRenderComponent
        addButtonProps={{
          justifyContent: "center",
          onClick: () =>
            setHandleLoadPorts({ type: "add", emptyValue: emptyValue }),
        }}
        isValid={portsValidationFailed}
        setEditRouteResults={setEditRouteResults}
        name="Load Ports"
        setHandlePorts={setHandleLoadPorts}
        ports={ports}
        portsToMap={handleLoadPorts}
        timeCountings={timeCountings}
        button={<LoadPortButton />}
      />
      <Divider className={classes.greyDivider} />
      <PortsRenderComponent
        addButtonProps={{
          justifyContent: "center",
          onClick: () =>
            setHandleViaLadenPorts({
              type: "add",
              emptyValue: emptySpeedValue,
            }),
        }}
        isValid={portsValidationFailed}
        setEditRouteResults={setEditRouteResults}
        name="Via Laden Ports"
        setHandlePorts={setHandleViaLadenPorts}
        ports={ports}
        portsToMap={handleViaLadenPorts}
        emptySpeedValue={emptySpeedValue}
      />
      <Divider className={classes.greyDivider} />
      <PortsRenderComponent
        addButtonProps={{
          justifyContent: "center",
          onClick: () =>
            setHandleDischargePorts({ type: "add", emptyValue: emptyValue }),
        }}
        isValid={portsValidationFailed}
        setEditRouteResults={setEditRouteResults}
        name="Discharge Ports"
        setHandlePorts={setHandleDischargePorts}
        ports={ports}
        portsToMap={handleDischargePorts}
        timeCountings={timeCountings}
        button={<DischargeButton />}
      />
      <Divider className={classes.greyDivider} />
      <Grid container>
        <RootForm
          autoFocus={false}
          form={form2a}
          itemSize={3}
          validation={validation}
        />
        {/* <RootForm
          form={form2e}
          itemSize={1}
          validation={validation}
        /> */}
        <Grid container alignItems="flex-end" item md={2}>
          <RootCheckbox
            label="I/W"
            onChange={(checked) =>
              setCheckboxValues({
                type: "handleChange",
                checked,
                name: "viaRedeliveryPortIdleWorking",
              })
            }
            checked={checkboxValues.viaRedeliveryPortIdleWorking}
            value={checkboxValues.viaRedeliveryPortIdleWorking}
          />
          <RootCheckbox
            label="Bunker"
            onChange={(checked) =>
              setCheckboxValues({
                type: "handleChange",
                checked,
                name: "viaRedeliveryPortBunker",
              })
            }
            checked={checkboxValues.viaRedeliveryPortBunker}
            value={checkboxValues.viaRedeliveryPortBunker}
          />
        </Grid>
        <RootForm
          autoFocus={false}
          form={form2c}
          itemSize={3}
          validation={validation}
        />
        {/* <RootForm
          form={form2f}
          itemSize={1}
          validation={validation}
        /> */}
        <Grid container alignItems="flex-end" item md={2}>
          <RootCheckbox
            label="I/W"
            onChange={(checked) =>
              setCheckboxValues({
                type: "handleChange",
                checked,
                name: "redeliveryPortIdleWorking",
              })
            }
            checked={checkboxValues.redeliveryPortIdleWorking}
            value={checkboxValues.redeliveryPortIdleWorking}
          />
          <RootCheckbox
            label="Bunker"
            onChange={(checked) =>
              setCheckboxValues({
                type: "handleChange",
                checked,
                name: "redeliveryPortBunker",
              })
            }
            checked={checkboxValues.redeliveryPortBunker}
            value={checkboxValues.redeliveryPortBunker}
          />
        </Grid>
      </Grid>
      <Grid container>
        <RootForm
          autoFocus={false}
          form={form3}
          itemSize={3}
          validation={validation}
        />
      </Grid>
    </Grid>
  )
}

const portsRenderStyles = makeStyles((theme) => ({
  title: {
    fontSize: theme.typography.pxToRem(theme.typography.generalFontSize),
    fontWeight: "bold",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "center",
  },
  titleBox: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    marginRight: 0,
  },
  distanceOnly: {
    color: 'red'
  }
}))

const showViaLadenReducer = (state, action) => {
  switch (action.type) {
    case "add": return [
      ...state,
      false
    ]
    case "check": {
      const newState = [...state]
      for (let i = 0; i < newState.length; i++) {
        if (i === action.index) {
          newState[i] = true
        } else {
          newState[i] = false
        }
      }
      action.setHandlePorts({
        type: 'update-showViaLaden',
        array: newState
      })
      return [
        ...newState
      ]
    }
    case "remove":
      if (action.shouldRemoveOne !== null && action.shouldRemoveOne === true) {
        return state.filter((_, index) => index !== action.index)
      }
      if (state.length - 1 === 0) {
        return state
      }
      return state.filter((_, index) => index !== action.index)
  }
}

const PortsRenderComponent = (props) => {
  const classes = portsRenderStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("md"))
  const {
    addButtonProps = {},
    name,
    portArray,
    portBunkerArray,
    portsToMap = [],
    setHandlePorts,
    ports,
    timeCountings,
    setEditRouteResults,
    shouldRemoveOne = false,
    validationDischargePorts,
    button,
    emptySpeedValue
  } = props

  const [showViaLaden, setShowViaLaden] = useReducer(showViaLadenReducer, name === 'Via Laden Ports' ? portsToMap.map(p => p.showViaLadenPort) : [])

  const handleChangeShowViaLaden = (newValue, index) => {
    setShowViaLaden({
      type: "check",
      index,
      newValue,
      setHandlePorts
    })
  }

  const ViaLadenButton = () => {
    return (
      <IconButton
        justifyContent="center"
        size="small"
        onClick={() => {
          setHandlePorts({ type: "add", emptyValue: emptySpeedValue })
          setShowViaLaden({ type: "add" })
        }}
      >
        <AddIcon />
      </IconButton>
    )
  }

  switch (name) {
    case "Load Ports":
    case "Discharge Ports":
      if (matches) {
        return (
          <Box dispĺay='flex' flexDirection='row' flexWrap='nowrap' justifyContent='flex-start' width='100%'>
            <Grid container>
              <Grid item md={3} container>
                <Box className={classes.box}>
                  <Box className={classes.titleBox}>
                    <Typography display="inline" className={classes.title}>
                      {name}
                    </Typography>
                  </Box>
                  {button}
                </Box>
              </Grid>
              <Grid item md={1}>
                <Typography className={classes.title}>Terms</Typography>
              </Grid>
              <Grid item md={3}>
                <Typography className={classes.title}>Time Counting</Typography>
              </Grid>
              <Grid item md={1}>
                <Typography className={classes.title}>Turn Time</Typography>
              </Grid>
              <Grid
                item
                md={1}
                container
                alignItems="center"
                justify="flex-end"
              >
                <Typography className={classes.title}>Idle/Working</Typography>
              </Grid>
              <Grid
                item
                md={1}
                container
                alignItems="center"
                justify="flex-end"
              >
                <Typography className={classes.title}>Bunker</Typography>
              </Grid>
            </Grid>
            {portsToMap.map((p, index) => {
              const getPortIndex = (id) => {
                for (let i = 0; i < ports.length; i++) {
                  if (ports[i].id === id) {
                    return ports[i].foid === 0
                  }
                }
              }
              const portIndex = getPortIndex(p.portArray)
              return (
                <Fragment key={index}>
                  <Grid container alignItems="flex-end">
                    <Grid item md={3}>
                      <RootAutocomplete
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.value,
                            type: "load",
                            index: index,
                          })
                          const hasFuel = getPortIndex(e.value)
                          if (hasFuel) {
                            setHandlePorts({
                              value: false,
                              type: "bunker",
                              index: index,
                            })
                          }
                          setEditRouteResults(true)
                        }}
                        value={p.portArray}
                        className={p.portArray && p.portArray.distanceOnly && classes.distanceOnly}
                        options={ports}
                        smallInput
                        renderInput={(params) => {
                          return (
                            <TextField className={p.portArray && p.portArray.distanceOnly && classes.distanceOnly} {...params} />
                          )
                         }}
                        renderOption={(option) => {
                          return (
                            <Typography className={option.distanceOnly && classes.distanceOnly}>{option.name}</Typography>
                          )}}
                      />
                    </Grid>
                    <Grid item md={1}>
                      <>
                        <RootTextField
                          onChange={(e) => {
                            setHandlePorts({
                              value: e.target.value,
                              type: "terms",
                              index: index,
                            })
                            setEditRouteResults(true)
                          }}
                          value={p.portTerms}
                        />
                      </>
                    </Grid>
                    <Grid item md={3}>
                      <RootAutocomplete
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.value,
                            type: "time",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portTimeCounting}
                        options={timeCountings}
                        smallInput
                      />
                    </Grid>
                    <Grid item md={1}>
                      <>
                        <RootTextField
                          type="number"
                          onChange={(e) => {
                            setHandlePorts({
                              value: e.target.value,
                              type: "waitingIdle",
                              index: index,
                            })
                            setEditRouteResults(true)
                          }}
                          value={p.portWaitingIdleArray}
                        />
                      </>
                    </Grid>
                    <Grid
                      item
                      md={1}
                      container
                      alignItems="center"
                      justify="flex-end"
                    >
                      <Checkbox
                        checked={p.portIdleWorkingArray}
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.target.checked,
                            type: "idleWorking",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portIdleWorkingArray}
                        disabled={portIndex}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={1}
                      container
                      alignItems="center"
                      justify="flex-end"
                    >
                      <Checkbox
                        checked={p.portBunkerArray}
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.target.checked,
                            type: "bunker",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portBunkerArray}
                        disabled={portIndex}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                    </Grid>
                    <Box
                      width='50px'
                    >
                      {portsToMap.length > 1 && (
                        <RemoveIconButton
                          onClick={() => {
                            setHandlePorts({ type: "remove", index })
                            setEditRouteResults(true)
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                  {p.validate === false ? (
                    <TextError error="All fields are needed please insert the data." />
                  ) : (
                      ""
                    )}
                  {portIndex ? (
                    <TextError error="Port has fuel FOID as 0, bunker disabled." />
                  ) : (
                      ""
                    )}
                </Fragment>
              )
            })}
          </Box>
        )
      } else {
        return (
          <Box dispĺay='flex' flexDirection='row' flexWrap='nowrap' justifyContent='flex-start' width='100%'>
            {portsToMap.map((p, index) => {
              const getPortIndex = (id) => {
                for (let i = 0; i < ports.length; i++) {
                  if (ports[i].id === id) {
                    return ports[i].foid === 0
                  }
                }
              }
              const portIndex = getPortIndex(p.portArray)
              return (
                <Fragment key={index}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box className={classes.box}>
                        <Box className={classes.titleBox}>
                          <Typography
                            display="inline"
                            className={classes.title}
                          >
                            {name}
                          </Typography>
                        </Box>
                        {button}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <RootAutocomplete
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.value,
                            type: "load",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portArray}
                        options={ports}
                        smallInput
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.title}>Terms</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RootTextField
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.target.value,
                            type: "terms",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portTerms}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.title}>
                        Time Counting
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RootAutocomplete
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.value,
                            type: "time",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portTimeCounting}
                        options={timeCountings}
                        smallInput
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.title}>
                        Waiting Idle
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RootTextField
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.target.value,
                            type: "waitingIdle",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portWaitingIdleArray}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Typography className={classes.title}>
                        Idle/Working - Bunker
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justify="flex-start"
                    >
                      <Checkbox
                        checked={p.portIdleWorkingArray}
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.target.checked,
                            type: "idleWorking",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        value={p.portIdleWorkingArray}
                        disabled={portIndex}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                      />
                      <Checkbox
                        checked={p.portBunkerArray}
                        onChange={(e) => {
                          setHandlePorts({
                            value: e.target.checked,
                            type: "bunker",
                            index: index,
                          })
                          setEditRouteResults(true)
                        }}
                        disabled={portIndex}
                        value={p.portBunkerArray}
                        inputProps={{
                          "aria-label": "primary checkbox",
                        }}
                        />
                      </Grid>
                      <Box
                        width='50px'
                      >
                        {portsToMap.length > 1 && (
                          <RemoveIconButton
                            onClick={() => {
                              setHandlePorts({ type: "remove", index })
                              setEditRouteResults(true)
                            }}
                          />
                        )}
                      </Box>
                  </Grid>
                  {validationDischargePorts === false ? (
                    <TextError error="All fields are needed please insert the data." />
                  ) : (
                      ""
                    )}
                </Fragment>
              )
            })}
          </Box>
        )
      }
    default:
      if (matches) {
        return (
          <Box dispĺay='flex' flexDirection='row' flexWrap='nowrap' justifyContent='flex-start' width='100%'>
            <Grid container>
              <Grid item xs={12} md={6} container alignContent="center">
                <Box className={classes.box}>
                  <Box className={classes.titleBox}>
                    <Typography display="inline" className={classes.title}>
                      {name}
                    </Typography>
                  </Box>
                  {name === 'Via Laden Ports' ? (<ViaLadenButton />) : (button)}
                </Box>
              </Grid>
              {/* <Grid item xs={12} md={2} container alignItems='center' justify='flex-end'>
                <Typography className={classes.title}>Waiting Idle</Typography>
              </Grid> */}
              <Grid
                item
                xs={6}
                md={1}
                container
                alignItems="center"
                justify="flex-end"
              >
                <Typography className={classes.title}>Idle/Working</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                md={1}
                container
                alignItems="center"
                justify="flex-end"
              >
                <Typography className={classes.title}>Bunker</Typography>
              </Grid>
                {
                  name === 'Via Laden Ports' && (
                    <Grid
                      item
                      xs={6}
                      md={1}
                      container
                      alignItems="center"
                      justify="flex-end"
                    >
                      <Typography className={classes.title}>Show</Typography>
                    </Grid>
                  )
                }
                <Box
                  width='50px'
                >
              </Box>
            </Grid>
            {portsToMap.map((p, index) => {
              return (
                <Grid container key={index}>
                  <Grid item xs={12} md={6}>
                    <RootAutocomplete
                      onChange={(e) => {
                        setHandlePorts({
                          value: e.value,
                          type: "load",
                          index: index,
                        })
                        setEditRouteResults(true)
                      }}
                      value={p.portArray}
                      options={ports}
                      smallInput
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={2}>
                      <>
                        <RootTextField
                          onChange={(e) => {
                            setHandlePorts({ value: e.target.value, type: 'waitingIdle', index: index })
                            setEditRouteResults(true)
                          }}
                          value={p.portWaitingIdleArray}
                        />
                      </>
                    </Grid> */}
                  <Grid
                    item
                    xs={6}
                    md={1}
                    container
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Checkbox
                      checked={p.portIdleWorkingArray}
                      onChange={(e) => {
                        setHandlePorts({
                          value: e.target.checked,
                          type: "idleWorking",
                          index: index,
                        })
                        setEditRouteResults(true)
                      }}
                      value={p.portIdleWorkingArray}
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    md={1}
                    container
                    alignItems="center"
                    justify="flex-end"
                  >
                    <Checkbox
                      checked={p.portBunkerArray}
                      onChange={(e) => {
                        setHandlePorts({
                          value: e.target.checked,
                          type: "bunker",
                          index: index,
                        })
                        setEditRouteResults(true)
                      }}
                      value={p.portBunkerArray}
                      inputProps={{
                        "aria-label": "primary checkbox",
                      }}
                      />
                  </Grid>
                  {name === 'Via Laden Ports' && (
                      <Grid
                        item
                        xs={6}
                        md={1}
                        container
                        alignItems="center"
                        justify="flex-end"
                      >
                        <Checkbox
                          checked={showViaLaden[index]}
                          onChange={(e, newValue) => handleChangeShowViaLaden(newValue, index)}
                        />
                      </Grid>
                    )}
                  {(shouldRemoveOne || portsToMap.length > 1) && (
                    <Box
                      width='50px'
                    >
                      <RemoveIconButton
                        onClick={() => {
                          setHandlePorts({
                            type: "remove",
                            index,
                            shouldRemoveOne: true,
                          })
                          setShowViaLaden({
                            type: "remove",
                            index,
                            shouldRemoveOne: true,
                          })
                          setEditRouteResults(true)
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              )
            })}
          </Box>
        )
      } else {
        return (
          <Box dispĺay='flex' flexDirection='row' flexWrap='nowrap' justifyContent='flex-start' width='100%'>
            <Grid item xs={12}>
              <Box display='flex' flexDirection='row'>
                <Typography className={classes.title}>{name}</Typography>
                {button}
              </Box>
            </Grid>
              {portsToMap.map((p, index) => {
                return (
                  <Fragment key={index}>
                    <Grid container>
                      
                      <Grid item xs={12}>
                        <RootAutocomplete
                          onChange={(e) => {
                            setHandlePorts({
                              value: e.value,
                              type: "load",
                              index: index,
                            })
                            setEditRouteResults(true)
                          }}
                          value={p.portArray}
                          options={ports}
                          smallInput
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justify="flex-start"
                      >
                        <Typography className={classes.title}>
                          Idle/Working - Bunker
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justify="flex-start"
                      >
                        <Checkbox
                          checked={p.portIdleWorkingArray}
                          onChange={(e) => {
                            setHandlePorts({
                              value: e.target.checked,
                              type: "idleWorking",
                              index: index,
                            })
                            setEditRouteResults(true)
                          }}
                          value={p.portIdleWorkingArray}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                        <Checkbox
                          checked={portBunkerArray}
                          onChange={(e) => {
                            setHandlePorts({
                              value: e.target.checked,
                              type: "bunker",
                              index: index,
                            })
                            setEditRouteResults(true)
                          }}
                          value={portBunkerArray}
                          inputProps={{
                            "aria-label": "primary checkbox",
                          }}
                        />
                        </Grid>
                        <Box width='50px'>
                          {portsToMap.length > 1 && (
                            <RemoveIconButton
                              onClick={() =>
                                setHandlePorts({ type: "remove", index })
                              }
                            />
                          )}
                        </Box>
                    </Grid>
                  </Fragment>
                )
              })}
          </Box>
        )
      }
  }
  // return(
  //   <Fragment>
  //   <Grid container>
  //     <Grid item xs={(name === 'Load Ports' || name === 'Discharge Ports') ? 3 : 8}>
  //       <Typography>{name}</Typography>
  //     </Grid>
  //     <Grid item xs={(name === 'Load Ports' || name === 'Discharge Ports') ? 2 : 4} container alignItems='center' justify='center'>
  //       <Typography>Bunker</Typography>
  //     </Grid>
  //     {(name === 'Load Ports' || name === 'Discharge Ports') ?

  //       <Grid item xs={2}>
  //         <Typography>Terms</Typography>
  //       </Grid>
  //       : ''}
  //     {(name === 'Load Ports' || name === 'Discharge Ports') ?
  //       <Grid item xs={4}>
  //         <Typography>Time Counting</Typography>
  //       </Grid>
  //       : ''}
  //   </Grid>
  //   <Grid container>
  //     <Grid item xs={(name === 'Load Ports' || name === 'Discharge Ports') ? 3 : 8}>
  //       <RootSelect
  //         onChange={(e) => setHandlePorts({value: e.value, type: 'load', index: index})}
  //         value={portArray}
  //         options={ports}
  //       />
  //     </Grid>
  //     <Grid item xs={(name === 'Load Ports' || name === 'Discharge Ports') ? 2 : 4} container alignItems='center' justify='center'>
  //       <Checkbox
  //       checked={portBunkerArray}
  //       onChange={(e) => setHandlePorts({value: e.target.checked, type: 'bunker', index: index})}
  //       value={portBunkerArray}
  //       inputProps={{
  //         'aria-label': 'primary checkbox',
  //       }}/>
  //       {
  //         name !== 'Load Ports' && name !== 'Discharge Ports' &&
  //         <RemoveIconButton onClick={() => setHandlePorts({type: 'remove', index })} />
  //       }
  //     </Grid>
  //     {(name === 'Load Ports' || name === 'Discharge Ports') ?
  //     <Grid item xs={3}>
  //       <Fragment>
  //         <RootTextField
  //           onChange={(e) => setHandlePorts({value: e.target.value, type: 'terms', index: index})}
  //           value={portTerms}/>
  //       </Fragment>
  //     </Grid>
  //     : ''}
  //     {(name === 'Load Ports' || name === 'Discharge Ports') ?
  //       <Fragment>
  //         <Grid item xs={3}>
  //             <RootSelect
  //               onChange={(e) => setHandlePorts({value: e.value, type: 'time', index: index})}
  //               value={portTimeCounting}
  //               options={timeCountings}
  //             />
  //         </Grid>
  //         <Grid item xs={1}>
  //             <RemoveIconButton onClick={() => setHandlePorts({type: 'remove', index })} />
  //         </Grid>
  //       </Fragment>
  //           : ''}
  //   </Grid>
  //   </Fragment>
  // )
}

const HandleDuplicate = (props) => {
  const { id, user, history } = props

  const input = {
    id,
  }
  return (
    <MutationHelper
      mutation={DUPLICATE_ROUTE}
      onCompleted={(data) => {
        history.push(`${data.duplicateRoute.route.id}`)
      }}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootButton
            style={{
              borderRadius: '5px',
              backgroundColor: 'rgba(22, 174, 177, 1)',
              color: 'white',
              marginRight: '15px'
            }}
            justifyContent="center"
            disabled={user.disabled}
            onClick={() => mutation()}
          >
            Duplicate
          </RootButton>
        )
      }}
    </MutationHelper>
  )
}

const RootCheckbox = ({ checked, onChange, label, value }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          color="primary"
          value={value}
        />
      }
      label={label}
    />
  )
}
