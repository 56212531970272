// React
import React from 'react'
import { withRouter } from 'react-router-dom'

// Material UI
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

// Styles
import { makeStyles } from '@material-ui/core/styles'
// Utils
import { VoyageOffer } from './VoyageOffer'
import Popover from '@material-ui/core/Popover'

const useDialogStyles = makeStyles(theme => (
  {
    dialogContainer: {
      width: '300px !important',
      maxHeight: '1000px !important',
      paddingTop: '0px',
    },
    dialogTitle: {
      paddingBottom: '0px',
    }
  }
))

const VoyageOfferDialog = props => {
  const classes = useDialogStyles()
  const { handleClose, open, route, routeName, client, voyageDate, voyageId, queryData, adcomFromClientResult, clientResults = false } = props
  return (
    <Dialog
      variant='popover'
      className={classes.popover}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '2%'
        }
      }}
      // anchorReference='anchorPosition'
      // anchorPosition={{ top: 100, left: 555 }}
      // anchorOrigin={{
      //   vertical: 'top',
      //   horizontal: 'right'
      // }}
      // transformOrigin={{
      //   vertical: 'bottom',
      //   horizontal: 'left'
      // }}
    >
      <Box>
        <DialogTitle className={classes.dialogTitle} id='alert-dialog-title'>Voyage Offer</DialogTitle>
        <DialogContent className={classes.dialogContainer}>
          <VoyageOffer
            handleClose={handleClose}
            route={route}
            routeName={routeName}
            positionDate={voyageDate}
            client={client}
            voyageId={voyageId}
            queryData={queryData}
            adcomFromClientResult={adcomFromClientResult}
            clientResults={clientResults}
          />
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default withRouter(VoyageOfferDialog)
