
// React Library
import React, { useState, useRef, useEffect } from 'react'

// Helmet
import Helmet from 'react-helmet'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Content
import { show as content } from './TimeCountingsContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_TIMECOUNTINGS } from '../Queries/Queries'

// Mutation Related
import { DELETE_TIMECOUNTING } from '../Mutations/Mutations'

// Utils
import AddIconButton from '../Utils/AddIconButton'
import { formatArrayForAgGrid } from '../Utils/utils'
import RootGrid from '../Utils/RootGrid'
import RootAgGrid from '../Utils/RootAgGrid'
import SearchTextInput from '../Utils/SearchTextInput'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Query Component
const ShowTimeCoutings = props => {
  return (
    <>
      <Helmet>
        <title>Time Counting</title>
      </Helmet>
      <QueryHelper query={GET_TIMECOUNTINGS}>
        {({ data }) => {
          const { getTimeCountings: { timeCountings } } = data

          // No Formatting Necessary

          // Render
          return (
            <ShowTimeCountingTable
              timeCountings={timeCountings}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowTimeCountingTable = props => {
  const { timeCountings = [] } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchTimeCounting, setSearchTimeCounting] = useState(timeCountings)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['timeCountingName'], {
    idFieldOrFunction: 'id'
  })

  // The Array To Be Filtered (All Time Countings In the DB)
  ts.addAll(timeCountings)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchTimeCounting(timeCountings)
      } else {
        const foundTimeCountings = ts.get(search, TrieSearch.UNION_REDUCER)
        setSearchTimeCounting(foundTimeCountings)
      }
    }
  }, [search, timeCountings, ts])

  // Column Structure
  const columnDefs = [
    { field: 'timeCountingName', headerName: 'Time Counting', cellRenderer: 'link' },
    { field: 'multiplier', headerName: 'Multiplier' },
    { field: 'abbrev', headerName: 'Abbrev' }
  ]

  // Link Of The Update Time Counting Page
  const updateLink = 'update-time-counting'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>

      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Ship Sectors Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={searchTimeCounting}
        mutation={DELETE_TIMECOUNTING}
        objectNameKey1='timeCountingName'
        type='Time Counting'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowTimeCoutings)
