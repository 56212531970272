import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useRef
} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStylesAutoComplete = makeStyles((theme) => ({
	root: {
		width: 150,
		fontSize: theme.typography.generalFontSize,
	},
	noMargin: {
		margin: 0,
	},
	noMarginAutoComplete: {
		width: '100%',
		height: '100%',
		margin: 0,
		'& .MuiFormControl-root': {
			margin: 0,
		},
	},
	marginBottomAutocomplete: {
		margin: 0,
		marginBottom: 8,
		'& .MuiFormControl-root': {
			margin: 0,
			marginBottom: 8,
		},
	},
	option: {
		fontSize: '12px !important',
	},
	input: {
		fontSize: '12px !important',
	},
}));

export default forwardRef((props, ref) => {
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState(null);
	const appTheme = useTheme();

	const [focus, setFocus] = useState(false);

	const classes = useStylesAutoComplete();

	const onChangeHandler = (event, newValue) => {
		setValue(newValue);
	};

	const onInputChangeHandler = (e, inputValue) => {
		setInputValue(inputValue);
	};

	useImperativeHandle(ref, () => {
		return {
			getValue: () => {
				return value;
			},
			afterGuiAttached: () => {
				// setValue((typeof props.value === 'string') ? props.value : (typeof props.value === 'object') ? props.value?.name : null)
				setValue(props.value);
				setInputValue(props.value);
				setFocus(true);
				if (autocompleteRef.current) {
					autocompleteRef.current.focus();
					autocompleteRef.current.select();
				}
			},
		};
	});

	const autocompleteRef = useRef();

	return (
		<Autocomplete
			focused={focus}
			style={{ padding: '0 10px' }}
			value={value}
			inputValue={inputValue}
			autoSelect
			autoHighlight
			classes={{
				paper: classes.noMarginAutoComplete,
				option: classes.option,
				input: classes.input,
			}}
			selectOnFocus
			onChange={(event, newEvent) => onChangeHandler(event, newEvent)}
			onInputChange={(event, newEvent) => onInputChangeHandler(event, newEvent)}
			clearOnBlur
			handleHomeEndKeys
			options={props.options}
			renderOption={(option) => option}
			disableClearable
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={autocompleteRef}
					placeholder={props.label}
				/>
			)}
		/>
	);
});
