import React from 'react'
import PropTypes from 'prop-types'
// React CSV
import { CSVLink } from 'react-csv'
// Material UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { makeStyles } from '@material-ui/core/styles'
// Mutations
import { Mutation } from 'react-apollo'
import { UPSERT_EXPORT_CLIENT_RESULT } from '../Mutations/Mutations'
// Queries
import { Query } from 'react-apollo'
import { GET_ROUTES_FROM_CLIENT_CSV } from '../Queries/Queries'
// Utils
import TextError from '../Utils/TextError'
import { fireSwalError, formatDate } from '../Utils/utils'

const useStyles = makeStyles(theme => ({
  addFiltersContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  filtersContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row'
    }
  },
  downloadIcon: {
    margin: theme.spacing(1),
    width: 150
  }
}))

const ClientResultCSV = props => {
  const {
    adcom = 0,
    client,
    clientName,
  } = props

  const classes = useStyles()

  const input = {
    adcom: parseFloat(adcom),
    user: client,
    csv: true,
  }

  const filename = `${clientName || 'client'}-adcom${adcom}-${formatDate({ date: new Date(Date.now()) })}.csv`

  const [skip, setSkip] = React.useState(true)
  const [currentClient, setCurrentClient] = React.useState(client)
  const [csv, setCsv] = React.useState(null)
  const [showExport, setShowExport] = React.useState(false)
  const clientRef = React.useRef(currentClient)
  const adcomRef = React.useRef(adcom)

  React.useEffect(() => {
    if (adcomRef.current !== adcom) {
      setShowExport(false)
      setCsv(null)
      adcomRef.current = adcom
    }
  }, [adcom, setCurrentClient, setCsv, setShowExport])

  React.useEffect(() => {
    if (clientRef.current !== client) {
      setCurrentClient(client)
      setShowExport(false)
      setCsv(null)
      clientRef.current = client
    }
  }, [client, setCsv, setCurrentClient, setShowExport])

  if (!client) {
    return <></>
  }

  const generateCsv = () => {
    setSkip(false)
  }

  return (
    <Query
      onError={(error) => {
        fireSwalError({
          title: 'Error',
          text: error ? error.message.split(':')[1] : 'Error generando el reporte'
        })
        setSkip(true)
      }}
      onCompleted={async (data) => {
        const { csv: csvData } = data?.getRoutesFromClient || {}
        if (csvData) {
          setSkip(true)
          setShowExport(true)
          setCsv(csvData)
        }
      }}
      query={GET_ROUTES_FROM_CLIENT_CSV}
      skip={skip}
      variables={{
        input
      }}
      fetchPolicy='network-only'
    >
      {({ data, loading }) => {
        if (loading) {
          return (
            <Box display='flex' alignItems='center' justifyContent='center' className={classes.downloadIcon}>
              <CircularProgress />
            </Box>
          )
        }

        return (
          <>
            {
              !showExport && (

                <Button
                  aria-label='Export'
                  onClick={generateCsv}
                  className={classes.downloadIcon}
                  startIcon={<CloudDownloadIcon />}
                > Export CSV
                </Button>
              )
            }
            {(showExport && csv) &&
              <Box display='flex' alignItems='center' justifyContent='center' className={classes.downloadIcon}>
                <CSVLink filename={filename} headers={csv.headers} data={csv.data}>
                  Download
                </CSVLink>
              </Box>
            }
          </>
        )
      }}
    </Query >
  )
}

ClientResultCSV.defaultValues = {
  adcom: 0
}

ClientResultCSV.propTypes = {
  adcom: PropTypes.number,
  client: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired
}

export default ClientResultCSV
