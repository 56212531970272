import React, { useState } from 'react'
// Apollo
import { Mutation } from 'react-apollo'
// Helmet
import Helmet from 'react-helmet'
// Mutations
import {
  UPSERT_CSV_VESSEL,
  UPSERT_CSV_SPOTINDEX,
  UPSERT_CSV_COMMODITY,
  UPSERT_CSV_DISTANCE,
  UPSERT_CSV_FORWARDCURVE,
  UPSERT_CSV_PORT,
  UPSERT_CSVROUTE,
  UPSERT_CSV_SHIPSECTOR,
  // UPSERT_CSV_SPOTTCSPREAD,
  UPSERT_CSV_TIME_COUNTING,
  UPSERT_CSV_ZONE
} from '../Mutations/Mutations'
// Material UI
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
// Styles
import { makeStyles } from '@material-ui/core/styles'
// Utils
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import { useFormInput } from '../Utils/utils'
import Snackbar from '../Utils/SnackBar'
import RootAutocomplete from '../Utils/RootAutocomplete'
import { CSVLink } from 'react-csv'

const useStyles = makeStyles(theme => ({
  csvButton: {
    textDecoration: 'none',
    color: 'grey',
    marginLeft: theme.spacing(2)
  },
  grid: {
    margin: theme.spacing(2)
  }
}))

const MultipleImports = props => {
  const [file, setFile] = useState(null)
  const [open, setOpen] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const classes = useStyles()

  const form = {
    file: useFormInput({
      initialValue: null,
      label: 'CSV',
      type: 'file',
      file,
      setFile
    })
  }

  const input = {
    file: form.file.file
  }

  const imports = [{
    mutation: UPSERT_CSV_COMMODITY,
    headers: [[
      'Commodity',
      'stowFactor',
      'forwardArray'
    ], []],
    filename: 'commodity-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_DISTANCE,
    headers: [[
      'portID1',
      'portID2',
      'fullDist',
      'ecaDist'
    ], []],
    filename: 'distance-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_FORWARDCURVE,
    headers: [[
      'ForwardCurve',
      'Commodity',
      'SpotIndex',
      'CurveAdjustmentAbsolute',
      'CurveAdjustmentPercent'
    ], []],
    filename: 'forward-curve-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_PORT,
    headers: [[
      'Port',
      'berth',
      'bunkeringCost',
      'bunkeringTime',
      'country',
      'zone',
      'FO05',
      'HSFO380',
      'MGO01',
      'salinity',
      'forwardCommodityFOID',
      'forwardCommodityMGOID',
      'fuelDeliveryPremiumArray',
      'shipSectorArray',
      'costArray',
      'draftArray',
      'waitingIdleArray',
      'eca'
    ], []],
    filename: 'port-csv-format.csv'
  },
  {
    mutation: UPSERT_CSVROUTE,
    headers: [[
      'Route',
      'description',
      'vessel',
      'tolerance',
      'stem',
      'commodity',
      'forwardCurve',
      'ballastBonus',
      'commission',
      'constants',
      'extraInsurance',
      'misCost',
      'spotTCSpread',
      'apsDeliveryPort',
      'deliveryPort',
      'deliveryPortBunker',
      'deliveryPortIdleWorking',
      'viaBallastPorts',
      'viaBallastPortBunker',
      'viaBallastPortWaitingIdle',
      'loadPorts',
      'loadTermsArray',
      'loadTimeCountingArray',
      'loadPortsBunkerArray',
      'loadPortWaitingIdle',
      'viaLadenPorts',
      'viaLadenPortBunkerArray',
      'viaLadenPortWaitingIdle',
      'dischargePorts',
      'dischargeTermsArray',
      'dischargeTimeCountingArray',
      'dischargePortArrayBunker',
      'dischargePortWaitingIdle',
      'viaRedeliveryPort',
      'viaRedeliveryPortBunker',
      'viaRedeliveryPortIdleWorking',
      'redeliveryPort',
      'redeliveryPortBunker',
      'redeliveryPortIdleWorking',
      'linkedRouteArrays',
      'clients'
    ], []],
    filename: 'route-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_SHIPSECTOR,
    headers: [[
      'ShipSector',
      'dwtMax',
      'dwtMin',
      'sw',
      'tf',
      'bw',
      'fw'
    ], []],
    filename: 'ship-sectors-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_SPOTINDEX,
    headers: [[
      'SpotIndex',
      'IndexValue',
      'indexAdjustmentAbsolute',
      'indexAdjustmentPercent',
      'shipSector',
      'route'
    ], []],
    filename: 'spot-index-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_TIME_COUNTING,
    headers: [[
      'TimeCounting',
      'Abbreviation',
      'Multiplier'
    ], []],
    filename: 'time-counting-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_VESSEL,
    headers: [[
      'Vessel',
      'IMONumber',
      'yard',
      'yearBuilt',
      'dwt',
      'draft',
      'cubicFT',
      'cubicMT',
      'tpc',
      'Ballast Speed',
      'Ballast IFO Consumption',
      'Ballast MGO Consumption',
      'Laden Speed',
      'Laden IFO Consumption',
      'Laden MGO Consumption',
      'inPortIFOIdle',
      'inPortIFOWorking',
      'inPortMGOIdle',
      'inPortMGOWorking'
    ], []],
    filename: 'vessel-csv-format.csv'
  },
  {
    mutation: UPSERT_CSV_ZONE, // Zone,Description,Default Bunker Port,Weather Margin Array
    headers: [[
      'Zone',
      'Description',
      'Default Bunker Port',
      'Weather Margin Array'
    ], []],
    filename: 'zone-csv-format.csv'
  }]

  const options = [
    { id: 1, name: 'Commodity' },
    { id: 2, name: 'Distances' },
    { id: 3, name: 'Forward Curves' },
    { id: 4, name: 'Ports' },
    { id: 5, name: 'Routes' },
    { id: 6, name: 'Ship Sector' },
    { id: 7, name: 'Spot Index' },
    { id: 8, name: 'Time Counting' },
    { id: 9, name: 'Vessels' },
    { id: 10, name: 'Zone' }
  ]

  const select = useFormInput({
    initialValue: 1,
    label: 'Choose a type to import',
    options: options,
    select: true,
    disableClearable: true
  })
  const currentImport = imports[select.getValue() - 1]
  return (
    <Container maxWidth='md'>
      <Helmet>
        <title>Import</title>
      </Helmet>
      <Paper maxWidth='md'>
        <Grid container spacing={2} alignItems='center' justify='center'>
          <Grid item xs={12} md={8}>
            <RootAutocomplete
              {...select}
            />
          </Grid>
          <Mutation
            mutation={currentImport.mutation}
            onCompleted={() => {
              setFile(null)
              setOpen(true)
            }}
            variables={{ input }}
            onError={(error) => {
              setShowErrors(true)
            }}
          >
            {(mutation, data, result) => {
              const { loading } = data
              if (data) {
                if (data.error && showErrors) {
                  console.log(data)
                  console.log('Error', data.error)
                  console.log('Message', data.error.message)
                  console.log('GQL', data.error.graphQLErrors[0])
                  if (data.error.graphQLErrors[0]) {
                    if (data.error.graphQLErrors[0].data) {
                      if (data.error.graphQLErrors[0].data.allErrors) {
                        console.log(data.error.graphQLErrors[0].data.allErrors)
                        return (
                          <Grid className={classes.grid} item xs={12}>
                            <Typography variant='h4' gutterBottom color='error'>
                              {data.error.graphQLErrors[0].message}
                            </Typography>
                            <Typography variant='h6' gutterBottom>
                              The rest of the data was updated succesfully!
                            </Typography>
                            <Typography gutterBottom variant='subtitle1'>
                              Errors
                            </Typography>
                            {data.error.graphQLErrors[0].data.allErrors.map((item, i) => {
                              return (
                                <Typography gutterBottom key={i}>
                                  {`${item.index ? `Row: ${item.index - 1}` : ''} - Message: ${item.error}`}
                                </Typography>
                              )
                            })}
                            <RootButton onClick={() => {
                              setFile(null)
                              setShowErrors(false)
                            }}
                            >Try Again
                            </RootButton>
                          </Grid>
                        )
                      }
                    }
                  }
                }
              }
              return (
                <>
                  <Grid item xs={12} md={4}>
                    <RootForm
                      form={form}
                      validation={{}}
                    />
                  </Grid>
                  {
                    !loading && (
                      <>
                        <Grid item>
                          <RootButton disabled={loading || !file} onClick={() => mutation()}>Upload</RootButton>
                        </Grid>
                        <Grid item xs={12}>
                          <CSVLink className={classes.csvButton} filename={currentImport.filename} data={currentImport.headers}>
                            Download format
                          </CSVLink>
                        </Grid>
                      </>
                    )
                  }
                  {
                    loading && (
                      <CircularProgress />
                    )
                  }
                </>
              )
            }}
          </Mutation>
          <Grid item xs={12} />
          <Snackbar open={open} onClose={() => setOpen(false)} variant='success' message='Updated succesfully!' />
        </Grid>
      </Paper>
    </Container>
  )
}

MultipleImports.propTypes = {

}

export default MultipleImports
