import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
// Components
import UpsertCommodity from '../Commodity/UpsertCommodity'
import UpsertForwardCurveQueryHelper from '../ForwardCurve/UpsertForwardCurve'
import UpsertPositionDate from '../PositionDate/UpsertPositionDate'
import UpsertPorts from '../Ports/UpsertPorts'
import UpsertShipSector from '../ShipSectors/UpsertShipSector'
import UpsertSpotIndex from '../SpotIndexes/UpsertSpotIndex'
import UpsertSpotTCSpread from '../SpotTCSpread/UpsertSpoTCSpread'
import UpsertTimeCounting from '../TimeCountings/UpsertTimeCounting'
import UpsertUser from '../Users/UpsertUser'
import UpsertVessel from '../Vessels/UpsertVessel'
import UpsertZone from '../Zones/UpsertZone'
// Material UI
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
// Styles
import { makeStyles } from '@material-ui/core/styles'
// Utils
import BodyTypography from './BodyTypography'
import RootButton from './RootButton'
import RootGrid from './RootGrid'
import RootH5 from './RootH5'
import RootPaper from './RootPaper'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    margin: `${theme.spacing(1)}px 0`,
    fontSize: theme.typography.pxToRem(12),
  },
  name: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    textAlign: 'left',
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(14),
    }
  }
}))

const CreateOptionDialog = props => {
  const { classes, option, setOpen, open } = props
  const handleClose = () => {
    setOpen(false)
  }
  if (!option.name) {
    return <div></div>
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll='paper'
        PaperProps={{ className: classes.root }}
        aria-labelledby="scroll-dialog-title"
      >
        <DialogTitle
          id="scroll-dialog-title"
        >
          {option.name}
        </DialogTitle>
        <DialogContent dividers>
          {
            option.name.toLowerCase() === 'commodity' &&
            <UpsertCommodity
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'forward curve' &&
            <UpsertForwardCurveQueryHelper
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'position dates' &&
            <UpsertPositionDate
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'port' &&
            <UpsertPorts
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'ship sector' &&
            <UpsertShipSector
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'spot index' &&
            <UpsertSpotIndex
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'spot tc spread' &&
            <UpsertSpotTCSpread
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'time counting' &&
            <UpsertTimeCounting
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'user' &&
            <UpsertUser
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'vessel' &&
            <UpsertVessel
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
          {
            option.name.toLowerCase() === 'zone' &&
            <UpsertZone
              onCompleted={() => setOpen(false)}
              refetchQueries={option.refetchQueries}
            />
          }
        </DialogContent>
      </Dialog>
    </>
  )
}

CreateOptionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  option: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
}

const CreateOptionsContainer = props => {
  const { name, options, ...rest } = props
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [currentOption, setCurrentOption] = useState({})

  return (
    <Fragment>
      <RootGrid>
        <RootPaper smallForm>
          <RootH5>
            You need to some items before you can add a {name}
          </RootH5>
          <Box mb={3}></Box>
          {
            options.map((option, index) => {
              return (
                <Grid container key={index} >
                  <Grid item xs={12} sm={8} className={classes.capitalize}>
                    <BodyTypography
                      className={classes.name}
                    >
                      {option.name}
                    </BodyTypography>
                  </Grid>
                  <Grid item sm={4}>
                    <RootButton
                      className={classes.button}
                      justifyContent='flex-end'
                      onClick={() => {
                        setCurrentOption(option)
                        setOpen(true)
                      }}
                    >
                      Add {option.name}
                    </RootButton>
                  </Grid>
                </Grid>
              )
            })
          }
        </RootPaper>
      </RootGrid>
      <CreateOptionDialog
        classes={classes}
        open={open}
        option={currentOption}
        setOpen={setOpen}
        {...rest}
      />
    </Fragment>
  )

}

CreateOptionsContainer.propTypes = {
  options: PropTypes.array.isRequired,
}

export default CreateOptionsContainer
