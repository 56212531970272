import React from 'react'
// Material UI
import InputBase from '@material-ui/core/InputBase'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// Utils
import NumberFormatCustom from './NumberFormatCustom'
import { formatNumber } from './utils'

const editableInputBaseStyles = makeStyles(theme => ({
  borderBottom: {
    borderBottom: '1px solid rgba(0,0,0,0.0)',
    padding: '0'
  },
  hover: {
    '&:hover': {
      borderBottom: '1px solid rgba(0,0,0,0.2)'
    }
  },
  inputCell: {
    // marginRight: 12,
  },
  alignRight: {
    textAlign: 'right'
  },
  maxWidth80: {
    maxWidth: '80px'
  },
  maxWidth90: {
    maxWidth: '90px'
  },
  maxWidth100: {
    maxWidth: '100px'
  }
}))

const EditableInputBase = props => {
  const {
    id,
    noFormat,
    isInteger,
    alignRight,
    className,
    disabled,
    inputProps,
    maxWidth,
    onBlur,
    onChange,
    noMargin = false,
    inputRef,
    value,
    autoFocus,
    removeDecimals,
    type,
    ...rest
  } = props

  const classes = editableInputBaseStyles()
  let formattedValue
  if (value === '-') {
    formattedValue = value
  } else if (isInteger) {
    formattedValue = formatNumber({ number: value, format: '-0,0' })
  } else if (removeDecimals) {
    formattedValue = formatNumber({ number: value, format: '0,0' })
  } else if (noFormat) {
    formattedValue = value
  } else {
    formattedValue = formatNumber({ number: value })
  }
  const finalInputProps = {
    className: clsx(classes.borderBottom,
      {
        [classes.hover]: !disabled,
        [classes.inputCell]: !noMargin,
        [classes.alignRight]: alignRight,
        [classes.maxWidth80]: maxWidth === 80,
        [classes.maxWidth90]: maxWidth === 90,
        [classes.maxWidth100]: maxWidth === 100
      }

    ),
    ...inputProps
  }
  const handleFocus = (event) => event.target.select()

  return (
    <InputBase
      id={id}
      className={clsx({
        [className]: className
      })}
      onFocus={handleFocus}
      onChange={onChange}
      onBlur={onBlur}
      inputRef={inputRef}
      value={formattedValue}
      inputProps={finalInputProps}
      inputComponent={(type !== 'text') ? NumberFormatCustom : 'input'}
      disabled={disabled}
      autoFocus={autoFocus}
      type={type}
      {...rest}
    />
  )
}

export default EditableInputBase
