import React, { Fragment, useState, useContext } from 'react'
import PropTypes from 'prop-types'
// Context
import { UserContext } from '../App/AppContext'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPDATE_CLIENT_RESULTS } from '../Mutations/Mutations'
// Utils
import { useFormInput } from '../Utils/utils'
import RootButton from '../Utils/RootButton'
import RootPaper from '../Utils/RootPaper'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import TuneIcon from '@material-ui/icons/Tune'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { GET_CLIENTS } from '../Queries/Queries'
import RootTextField from '../Utils/RootTextField'

const OptionSwitch = withStyles((theme) => ({
  // root: {
  //   width: 28,
  //   height: 16,
  //   padding: 0,
  //   display: 'flex'
  // },
  // switchBase: {
  //   padding: 2,
  //   color: theme.palette.grey[500],
  //   '&$checked': {
  //     transform: 'translateX(12px)',
  //     color: theme.palette.common.white,
  //     '& + $track': {
  //       opacity: 1,
  //       backgroundColor: theme.palette.primary.main,
  //       borderColor: theme.palette.primary.main
  //     }
  //   }
  // },
  // thumb: {
  //   width: 12,
  //   height: 12,
  //   boxShadow: 'none'
  // },
  // track: {
  //   border: `1px solid ${theme.palette.grey[500]}`,
  //   borderRadius: 16 / 2,
  //   opacity: 1,
  //   backgroundColor: theme.palette.common.white
  // },
  // checked: {}
}))(Switch)

const useStyles = makeStyles(theme => ({
  tuneIcon: {
    margin: theme.spacing(1),
    width: 150
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  paper: {
    width: 300,
    maxHeight: 710
  },
  textAlign: {
    textAlign: 'center'
  }
}))

export const ClientSettingsModal = props => {
  const { client, clientName, refetch, users, adcom, setAdcom, setAdcomValue, setUser } = props

  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (users.length === 0) {
    return null
  }

  if (!client) {
    return null
  }

  const initialClient = users.filter(u => u.id === client)

  if (initialClient.length === 0) {
    // TODO ADD ERROR
    return null
  }

  const userSetting = initialClient[0].userSetting

  return (
    <>
      <Button
        aria-label='Client Result Settings'
        onClick={handleOpen}
        className={classes.tuneIcon}
        startIcon={<TuneIcon />}
      > Settings
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='client-result-settings'
        aria-describedby='client-result-settings'
        className={classes.modal}
      >
        <ClientResultSettings
          classes={classes}
          client={client}
          clientName={clientName}
          handleClose={handleClose}
          refetch={refetch}
          userSetting={userSetting}
          adcom={adcom}
          setAdcom={setAdcom}
          setAdcomValue={setAdcomValue}
          setUser={setUser}
        />
      </Modal>
    </>
  )
}

export const ClientResultSettings = props => {
  const { classes, client, clientName, handleClose, userSetting, adcom, setAdcom, setAdcomValue, refetch, setUser } = props

  const defaultClientResultSettings = {
    loadPort: { hidden: false },
    dischargePort: { hidden: false },
    stem: { hidden: false },
    commodity: { hidden: false },
    sector: { hidden: false },
    deliveryPort: { hidden: false },
    viaLadenPort: { hidden: false },
    intake: { hidden: false },
    draft: { hidden: false },
    loadDischTerm: { hidden: false },
    spread: { hidden: false }
  }

  let clientResultsSettings = defaultClientResultSettings
  if (userSetting) {
    const { baseRoutes, ...columnSettings } = userSetting?.clientResults
    clientResultsSettings = columnSettings
  }

  const [clientSettings, setClientSettings] = useState(clientResultsSettings)
  // const [adcom, setAdcom] = useState(userSetting ? userSetting.adcom : 0)

  const handleChange = (event) => {
    setClientSettings({
      ...clientSettings,
      [event.target.name]: {
        hidden: !event.target.checked
      }
    })
  }

  const clientResultsInput = {}
  const input = {
    id: userSetting.id,
    clientResults: clientSettings,
    adcom: parseFloat(adcom) || 0
  }

  const refetchQueries = [
    {
      query: GET_CLIENTS,
      variables: {
        filter: {
          role: 'client'
        }
      }
    }
  ]

  const onCompleted = () => {
    refetch()
  }

  return (
    <MutationHelper
      mutation={UPDATE_CLIENT_RESULTS}
      onCompleted={onCompleted}
      variables={{ input }}
      // refetchQueries={refetchQueries}
      awaitRefetchQueries
    >
      {(mutation, result) => {
        const onSubmit = () => {
          mutation()
          handleClose()
        }
        return (
          <RootPaper className={classes.paper} smallForm>
            <Typography className={classes.textAlign} variant='h4'>{clientName}</Typography>
            <Typography className={classes.textAlign} variant='body1'>Settings</Typography>
            <Grid container justify='space-around'>
              {
                Object.keys(clientSettings).map((key, i) => {
                  return (
                    <Fragment key={i}>
                      <Grid item xs={6}>
                        <Typography>{key}</Typography>
                      </Grid>
                      <Grid justify='flex-end' container item xs={6}>
                        <OptionSwitch
                          checked={!clientSettings[key].hidden}
                          onChange={handleChange}
                          name={key}
                        />
                      </Grid>
                    </Fragment>
                  )
                })
              }
            </Grid>
            <RootTextField
              label='Adcom'
              type='number'
              onChange={(e) => {
                setAdcom(parseFloat(e.target.value))
                setAdcomValue(parseFloat(e.target.value))
                setUser({
                  type: 'add-client-info',
                  value: {
                    userSetting: {
                      adcom: parseFloat(e.target.value)
                    }
                  }
                })
              }}
              value={parseFloat(adcom) || 0}
              className={classes.adcom}
            />
            <RootButton
              onClick={onSubmit}
              justifyContent='center'
            >
              Update
            </RootButton>
          </RootPaper>
        )
      }}
    </MutationHelper>
  )
}

export default ClientSettingsModal
