import React from 'react'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import SearchIcon from '@material-ui/icons/Search'
import { useSearchTextInputStyles } from './SearchTextInputStyles'

const CustomAutocomplete = ({
  autoFocus = false,
  creatable,
  disableClearable,
  options,
  labelKey = 'name',
  placeholder,
  value,
  onChange,
  name,
  autoHighlight = false,
  children = null,
  optionsKey = 'id',
  multiple,
  readOnly,
  validation,
  comparator = 'id',
  width = '100%'
}) => {
  const [inputValue, setInputValue] = React.useState('')
  const inputClasses = useSearchTextInputStyles()

  return (
    <Paper className={inputClasses.customRoot}>
      <Box style={{ width: '100%' }} display='flex' justifyContent='space-even' flexDirection='row' alignItems='center'>
        <Box style={{ width: children ? '40%' : '100%' }}>
          <Autocomplete
            readOnly={readOnly}
            autoFocus={autoFocus}
            autoHighlight={autoHighlight}
            value={value}
            noOptionsText='No options'
            multiple={multiple}
            onChange={(_e, v) => {
              const event = {
                type: 'select',
                value: v,
                name: name
              }
              onChange(event, validation)
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue)
            }}
            freeSolo={creatable}
            getOptionLabel={(option) => {
              if (
                option !== null &&
							option !== '' &&
							typeof option !== 'object' &&
							options !== null &&
							Array.isArray(options) &&
							options.length > 0
              ) {
                return options.find(
                  (element) => element?.[optionsKey] === option
                )?.[labelKey]
              } else if (
                option !== null &&
							option !== '' &&
							typeof option === 'object' &&
							options !== null &&
							Array.isArray(options) &&
							options.length > 0
              ) {
                return options.find(
                  (element) => element?.[optionsKey] === option?.[comparator]
                )?.[labelKey]
              } else if (
                option !== null &&
							option !== '' &&
							typeof option === 'object'
              ) {
                return option?.[labelKey]
              } else {
                return ''
              }
            }}
            getOptionSelected={(option, value) => {
              if (value === '' || options.length === 0) {
                return true
              } else if (typeof value !== 'object') {
                return value === option?.[optionsKey]
              } else if (typeof value === 'object') {
                return value?.[comparator] === option?.[optionsKey]
              } else {
                return false
              }
            }}
            fullWidth
            options={options}
            renderInput={(params) => (
              <Box
                className={inputClasses.inputContainer}
                ref={params.InputProps.ref}
              >
                <Box className={inputClasses.inputItem}>
                  <IconButton
                    disabled
                    type='text'
                    className={inputClasses.inputItem}
                    aria-label='search'
                  >
                    <SearchIcon />
                  </IconButton>
                </Box>
                <Box className={inputClasses.inputItem}>
                  <Divider
                    className={inputClasses.divider}
                    orientation='vertical'
                  />
                </Box>
                <Box className={inputClasses.textInput}>
                  <input
                  // className={inputClasses.textInput}
                    style={{ width: width, minWidth: '100%' }}
                    placeholder={placeholder}
                    {...params.inputProps}
                  />
                </Box>
              </Box>
            )}
            disableClearable={disableClearable}
          />
        </Box>
        <Box display='flex' justifyContent='space-between' flexDirection='row' alignItems='center'>
          {children}
        </Box>
      </Box>

    </Paper>
  )
}

CustomAutocomplete.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableClearable: PropTypes.bool,
  autoFocus: PropTypes.bool,
  comparator: PropTypes.string,
  creatable: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  validation: PropTypes.object,
  children: PropTypes.object,
  optionsKey: PropTypes.string,
  autoHighlight: PropTypes.bool
}

export default CustomAutocomplete
