import { CREATE_DISTANCE_INFORMATION } from '../Queries/Queries'

const breadCrumbs = [
  {
    link: '/distances',
    name: 'Distance'
  }
]

const module = {
  name: 'Distance'
}

const refetchQueries = [{
  query: CREATE_DISTANCE_INFORMATION
}]

export const show = {
  module,
  createButton: {
    name: 'Create Distance',
    link: '/create-distance'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'portID1', name: 'portID1' },
      { key: 'portID2', name: 'portID2' },
      { key: 'ecaDist', name: 'ecaDist' },
      { key: 'fullDist', name: 'nonecaDist' }
    ],
    rows: 3
  },
  update: {
    link: '/update-distance'
  }
}

export const upsert = {
  module,
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Distance created',
      path: '/distances'
    },
    submitButton: 'Create Distance'
  },
  emptyObjects: {
    ports: {
      name: 'Port',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Distance updated',
      path: '/distances'
    },
    submitButton: 'Update Distance'
  }
}
