// React
import React from 'react'
// Material UI
import Box from '@material-ui/core/Box'
import Cancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/styles/makeStyles'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
// PropTypes
import PropTypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: 800,
    }
  },
  content: {
    background: theme.palette.background.default,
    minHeight: 400
  },
}))

const RootDialog = (props) => {
  const {
    children,
    open,
    setOpen,
    title,
  } = props
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={() => setOpen(false)} scroll='body' TransitionComponent={Transition} maxWidth='lg'>
      <Box display="flex" justifyContent='space-between'>
        <DialogTitle disableTypography>
          <Typography variant='h6' color='primary'>{title}</Typography>
        </DialogTitle>
        <DialogActions>
          <IconButton onClick={() => setOpen(false)}>
            <Cancel color= 'disabled'/>
          </IconButton>
        </DialogActions>
      </Box>
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

RootDialog.defaultProps = {
  title: '',
}

RootDialog.propTypes = {
  component: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default RootDialog