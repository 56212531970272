import React from 'react' // , { Fragment, useContext, useEffect, useState, useReducer }
import { makeStyles, useTheme } from '@material-ui/core/styles'
// Mutation
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_SPOTFIXTURE } from '../Mutations/Mutations'
// Styles
import clsx from 'clsx'
// Material UI
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import Input from '@material-ui/core/Input'
import useMediaQuery from '@material-ui/core/useMediaQuery'

// Utils
import EditableInputBase from '../Utils/EditableInputBase'
// import RootButton from '../Utils/RootButton'
import RootGrid from '../Utils/RootGrid'
import RootAutocomplete from '../Utils/RootAutocomplete'

import { formatNumber } from '../Utils/utils'

const mobile = require('is-mobile')
const isMobile = mobile({ tablet: true, featureDetect: true })

const vesselStyles = makeStyles(theme => ({
  card: {
    minHeight: '350px',
    margin: '0',
    padding: '0',
    width: 'auto',
    '&:last-child': { paddingBottom: 0 },
    [theme.breakpoints.up('md')]: {
      width: '33.33%'
    }
  },
  cardContent: {
    '&:last-child': { paddingBottom: 0 },
    paddingLeft: '30px',
    paddingTop: '15px',
    paddingBottom: '15px'
  },
  vesselSelect: {
    width: '98%',
    height: 25
  },
  rowHeight: {
    margin: 0,
    height: '30px'
  },
  input: {
    width: '20%',
    margin: '1%',
    // fontSize: '12px',
    lineHeight: '1px',
    [theme.breakpoints.up('sm')]: {
      width: '42px'
    }
  },
  text: {
    // fontSize: theme.typography.generalFontSize,
    width: '55px'
    // paddingBottom: '3px',
    // lineHeight: '1px'
    // marginRight: theme.spacing(1)
  },
  textOn: {
    // fontSize: theme.typography.generalFontSize,
    marginLeft: '20px',
    marginRight: '10px',
    width: '30px'
    // margin: '2%',
    // paddingBottom: '3px',
    // lineHeight: '1px'
    // marginRight: theme.spacing(1)
  },
  textTitle: {
    fontWeight: 500
    // fontSize: theme.typography.generalFontSize
  },
  dropDown: {
    minWidth: '80%'
  },
  data: {
    marginTop: theme.spacing(0.5)
  },
  title: {
    fontSize: theme.typography.generalFontSize + 2,
    fontWeight: 'bold'
  },
  contentLadden: {
    display: 'flex',
    alignItems: 'center',
    height: 20
  },
  speedRow: {
    marginBottom: '10px'
  },
  divider: {
    height: '1px',
    width: '90%'
  },
  classesNoMarginAutoComplete: {
    margin: 0
  },
  noMarginAutoComplete: {
    height: '33px',
    margin: 0,
    '& .MuiFormControl-root': {
      margin: 0
    },
    '& .MuiInputBase-input': {
      textAlign: 'right'
    }
  },
  width100: {
    width: 125,
    maxWidth: 125,
    minWidth: 125
  },
  cubicFT: {
    fontSize: theme.typography.generalFontSize - 2
  },
  selectedVessel: {
    backgroundColor: '#E9EBEC'
  },
  normalCell: {
    height: 25
    // fontSize: 15
  },
  smallCell: {
    height: 25,
    fontSize: 12
  }
}))

const titleTypogrphyStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.generalFontSize + 4,
    marginBottom: 2
  }
}))

const TitleTypography = props => {
  const {
    children,
    ...rest
  } = props
  const classes = titleTypogrphyStyles()
  return (
    <Typography className={classes.root} {...rest}>{children}</Typography>
  )
}

const SelectVessel = props => {
  const { calcUpdateMutation, info, isVessel, setInfo, selectedResult, vessels } = props
  const classes = vesselStyles()
  // const value = !info ? '' : info.vessels[isVessel].vesselName
  let value = ''
  if (info) {
    if (info.vessels[isVessel]) {
      const name = info.vessels[isVessel].vessel.vesselName ? info.vessels[isVessel].vessel.vesselName : 'Select a Vessel'
      const dwt = info.vessels[isVessel].vessel.dwt ? info.vessels[isVessel].vessel.dwt : 0
      const yb = info.vessels[isVessel].vessel.yearBuilt ? info.vessels[isVessel].vessel.yearBuilt : 0
      value = {
        id: info.vessels[isVessel].vessel.id,
        name: `${name} (${dwt}/${yb})`
      }
    }
  }
  return (
    <Grid>
      <RootAutocomplete
        id='demo-customized-select-native'
        disableClearable
        optionClass={classes.normalCell}
        inputClass={classes.normalCell}
        style={{ width: '92.5%' }}
        value={value}
        // TODO we geet the index of the vessel which is fine, missing sending these things to backend
        // handleChange({
        //   name: (title === 'Vessel 2') ? 'vessel2' : 'vessel3',
        //   value: e.target.value})
        onChange={(e) => {
          if (e.value) {
            if (e.value.id >= 0) {
              setInfo({ value: vessels.vessels[e.value.id], type: 'setVessel', targetVessel: isVessel, selectedResult, calculation: calcUpdateMutation })
              // calcUpdateMutation()
            }
          }
        }}
        input={<Input />}
        disabled={!info}
        className={classes.dropDown}
        variant='outlined'
        options={vessels.vessels.map((item, i) => {
          return {
            id: i,
            name: `${item.vesselName} (${item.dwt}/${item.yearBuilt ? item.yearBuilt : 0})`
          }
        })}
      />
    </Grid>
  )
  // return (
  //   <Grid>
  //     <Select
  //       displayEmpty
  //       id='demo-customized-select-native'
  //       value={value}
  //       // TODO we geet the index of the vessel which is fine, missing sending these things to backend
  //       // handleChange({
  //       //   name: (title === 'Vessel 2') ? 'vessel2' : 'vessel3',
  //       //   value: e.target.value})
  //       onChange={(e) => {
  //         console.log('Target Value', e.target.value)
  //         if (e.target.value >= 0) {
  //           setInfo({ value: vessels.vessels[e.target.value], type: 'setVessel', targetVessel: isVessel })
  //           calcUpdateMutation()
  //         }
  //       }}
  //       input={<Input />}
  //       disabled={!info}
  //       className={classes.dropDown}
  //       variant='outlined'
  //     >
  //       <MenuItem disabled value=''>
  //         <em>Empty</em>
  //       </MenuItem>
  //       {vessels.vessels.map((item, i) => {
  //         return (
  //           <MenuItem key={i} value={i}>
  //             {item.vesselName}
  //           </MenuItem>
  //         )
  //       })}
  //     </Select>
  //   </Grid>
  // )
}

const VesselCalc = props => {
  const { calcUpdateMutation, isVessel, info, setInfo, setUpdateCheck, targetVessel, selectedResult, result, title, updateCheck, vessel, vessels, setRecalc } = props
  const classes = vesselStyles()
  const getSpeeds = data => {
    const result = []
    if (data && data.ballastSpeedArray) {
      for (let i = 0; i < data.ballastSpeedArray.length; i++) {
        result.push({
          ballastSpeed: data.ballastSpeedArray[i],
          ballastIfo: data.ballastIFOConsumption[i],
          ballastMgo: data.ballastMGOConsumption[i],
          ladenSpeed: data.ladenSpeedArray[i],
          ladenIfo: data.ladenIFOConsumption[i],
          ladenMgo: data.ladenMGOConsumption[i]
        })
      }
    }
    return result
  }

  const speeds = (vessel.vessel) ? getSpeeds(vessel.vessel) : []

  const theme = useTheme()
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      {isVessel === 'vessel1'
        ? <TitleTypography style={{ fontSize: 24 }}>{title}</TitleTypography>
        : <SelectVessel calcUpdateMutation={calcUpdateMutation} data={vessel.vessel} info={info} isVessel={isVessel} selectedResult={selectedResult} setInfo={setInfo} vessels={vessels} />}

      {
        speeds.length === 0 && (
          <Grid container>
            <Grid container item xs={10}>
              <Grid item xs={12} className={classes.contentLadden} />
            </Grid>
          </Grid>
        )
      }
      {vessel.vessel
        ? <Typography className={classes.smallCell}>{`CubicFT: ${formatNumber({ number: vessel.vessel.cubicFT })}`}</Typography>
        : <></>}
      {speeds.map((item, i) => (
        <Grid container key={i}>
          <Grid container item xs={10} className={classes.speedRow}>
            <Grid item xs={12} className={classes.contentLadden}>
              <Typography className={clsx([classes.text, classes.normalCell])} variant='body1'>Ballast</Typography>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                onChange={(e) => setInfo({ value: e.target.value, type: 'ballastSpeed', speed: i, vessel: isVessel })}
                value={item.ballastSpeed}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                  }
                }}
                className={classes.input}
              />
              <Typography className={clsx([classes.textOn, classes.normalCell])}>IFO</Typography>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                onChange={(e) => setInfo({ value: e.target.value, type: 'ballastIfo', speed: i, vessel: isVessel })}
                value={item.ballastIfo}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                  }
                }}
                className={classes.input}
              />
              <Typography className={clsx([classes.textOn, classes.normalCell])}>MGO</Typography>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                onChange={(e) => setInfo({ value: e.target.value, type: 'ballastMgo', speed: i, vessel: isVessel })}
                value={item.ballastMgo}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                  }
                }}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={12} className={classes.contentLadden}>
              <Typography className={clsx([classes.text, classes.normalCell])}>Laden</Typography>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                onChange={(e) => setInfo({ value: e.target.value, type: 'ladenSpeed', speed: i, vessel: isVessel })}
                value={item.ladenSpeed}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                  }
                }}
                className={classes.input}
              />
              <Typography className={clsx([classes.textOn, classes.normalCell])}>IFO</Typography>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                onChange={(e) => setInfo({ value: e.target.value, type: 'ladenIfo', speed: i, vessel: isVessel })}
                value={item.ladenIfo}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                  }
                }}
                className={classes.input}
              />
              <Typography className={clsx([classes.textOn, classes.normalCell])}>MGO</Typography>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                onChange={(e) => setInfo({ value: e.target.value, type: 'ladenMgo', speed: i, vessel: isVessel })}
                value={item.ladenMgo}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'speed'
                    })
                    setRecalc(true)
                  }
                }}
                className={classes.input}
              />
            </Grid>
          </Grid>
          <Grid container item xs={2} justify='flex-start'>
            <Checkbox
              checked={vessel[`checked${i}`]}
              style={{ marginLeft: 10 }}
              onChange={() => {
                setInfo({ value: `checked${i}`, type: 'change-checked', targetVessel: targetVessel, target: result ? 'no-target' : 'speed', updateCheckFn: () => setUpdateCheck(updateCheck + 1) })
              }}
              value={vessel[`checked${i}`]}
              color='primary'
              inputProps={{
                'aria-label': 'primary checkbox'
              }}
            />

          </Grid>
        </Grid>
      ))}
    </>
  )
}

const VesselDOPAndAps = props => {
  const { adcomValue, calcUpdateMutation, info, index, isVessel, setInfo, setRecalc } = props //, setUpdateCheck, updateCheck
  function formatDate (date) {
    var monthNames = [
      'Jan', 'Feb', 'Mar',
      'Apr', 'May', 'Jun', 'Jul',
      'Aug', 'Sep', 'Oct',
      'Nov', 'Dec'
    ]

    var day = date.getDate()
    var monthIndex = date.getMonth()
    var year = date.getFullYear()

    return day + ' ' + monthNames[monthIndex] + ' ' + year
  }

  const date = formatDate(new Date(Date.now()))
  const classes = vesselStyles()

  const theme = useTheme()
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Table aria-label='APS-Table' style={{ width: 350 }}>
        <TableBody>
          <TableRow className={classes.rowHeight}>
            <TableCell className={clsx([classes.normalCell])} style={{ minWidth: 35 }}>
              Aps
            </TableCell>
            <TableCell align='right' className={classes.width100}>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                removeDecimals
                onChange={(e) => setInfo({ value: e.target.value, type: 'apsDailyHire', index, isVessel })}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'aps'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'aps'
                    })
                    setRecalc(true)
                  }
                }}
                value={(info && info.vessels) ? info.vessels[isVessel].aps.dailyHire : 0}
              />
            </TableCell>
            <TableCell align='right' className={classes.width100}>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                removeDecimals
                onChange={(e) => setInfo({ value: e.target.value, type: 'apsBunkeringCost', index, isVessel })}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'aps'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'aps'
                    })
                    setRecalc(true)
                  }
                }}
                value={(info && info.vessels) ? info.vessels[isVessel].aps.bunkeringCost : 0}
              />
            </TableCell>
            <TableCell align='right' className={clsx([classes.width100, classes.normalCell])}>
              {formatNumber({ number: (info && info.vessels) && info.vessels[isVessel].aps.result / (1 - adcomValue / 100) })}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table aria-label='DOP-Table' style={{ width: 350 }}>
        <TableBody>
          <TableRow className={classes.rowHeight}>
            <TableCell className={classes.normalCell} style={{ minWidth: 35 }}>Dop</TableCell>
            <TableCell align='right' className={classes.width100}>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                removeDecimals
                onChange={(e) => setInfo({ value: e.target.value, type: 'dop', index, isVessel })}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'dop'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'dop'
                    })
                    setRecalc(true)
                  }
                }}
                value={(info && info.vessels) ? info.vessels[isVessel].dop.dailyHire : 0}
              />
            </TableCell>
            <TableCell className={classes.width100}>
              <EditableInputBase
                alignRight
                inputProps={{ style: { height: 25 } }}
                removeDecimals
                onChange={(e) => setInfo({ value: e.target.value, type: 'dopBunkeringCost', index, isVessel })}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {
                    setInfo({
                      type: 'setTarget',
                      target: 'dop'
                    })
                    setRecalc(true)
                    // calcUpdateMutation()
                  }
                }}
                onBlur={(e) => {
                  if (mobileSize || isMobile) {
                    setInfo({
                      type: 'setTarget',
                      target: 'dop'
                    })
                    setRecalc(true)
                  }
                }}
                value={(info && info.vessels) ? info.vessels[isVessel].dop.bunkeringCost : 0}
              />
            </TableCell>
            <TableCell align='right' className={clsx([classes.width100, classes.normalCell])}>
              {formatNumber({ number: (info && info.vessels) && info.vessels[isVessel].dop.result / (1 - adcomValue / 100) })}
            </TableCell>
          </TableRow>
          <TableRow className={classes.rowHeight}>
            <TableCell className={classes.normalCell} style={{ minWidth: 35 }}>Date</TableCell>
            <TableCell className={classes.normalCell} align='right' colSpan={3}>{date}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

const VesselPorts = props => {
  const { isVessel, ports, setInfo, vessel, setUpdateCheck, updateCheck } = props
  const classes = vesselStyles()
  return (
    <>
      <Table style={{ width: 410 }}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.normalCell}>
              Delivery
            </TableCell>
            <TableCell align='right'>
              <RootAutocomplete
                id={`port-select-${vessel.id}`}
                optionClass={classes.normalCell}
                inputClass={classes.normalCell}
                rootClassName={classes.noMarginAutoComplete}
                autoHighlight
                noMargin
                value={vessel.customDelivery.index}
                disabled={!vessel.vessel.id}
                className={classes.vesselSelect}
                classes={{
                  root: classes.classesNoMarginAutoComplete
                }}
                size='small'
                onChange={(e) => {
                  // setVessel({value: ports[e.target.value], type: 'delivery'})
                  let index
                  if (e.value) {
                    index = e.value.id
                  }
                  setInfo({
                    value: index,
                    type: 'customDelivery',
                    id: index
                      ? ports.ports[index].id
                      : '',
                    isVessel,
                    updateCheckFn:
                      () => setUpdateCheck(updateCheck + 1)
                  })
                }}
                options={
                  ports.ports.map((item, i) => {
                    return {
                      id: i,
                      name: `${item.portName} - ${item.berth}`
                    }
                  })
                }
                input={<Input />}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.normalCell}>
              Ballast
            </TableCell>
            <TableCell align='right'>
              <RootAutocomplete
                id={`port-select-${vessel.id}`}
                autoHighlight
                noMargin
                optionClass={classes.normalCell}
                inputClass={classes.normalCell}
                rootClassName={classes.noMarginAutoComplete}
                value={vessel.customBallast.index}
                disabled={!vessel.vessel.id}
                className={classes.vesselSelect}
                onChange={(e) => {
                  // setDeliveryPortIndex(e.target.value)
                  let index
                  if (e.value) {
                    index = e.value.id
                  }
                  setInfo({
                    value: index,
                    type: 'customBallast',
                    id: index ? ports.ports[index].id : '',
                    isVessel,
                    updateCheckFn:
                      () => setUpdateCheck(updateCheck + 1)
                  })
                }}
                classes={{
                  root: classes.classesNoMarginAutoComplete
                }}
                size='small'
                options={
                  ports.ports.map((item, i) => {
                    return {
                      id: i,
                      name: `${item.portName} - ${item.berth}`
                    }
                  })
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  )
}

const VesselResults = props => {
  const { adcomValue, calcUpdateMutation, index, info, isVessel, result, setInfo, vessel, setAdcomValue, setRecalc } = props
  const classes = vesselStyles()
  const input = {
    apsDH: info.vessels[isVessel].aps.dailyHire,
    apsPC: info.vessels[isVessel].aps.bunkeringCost,
    apsResult: info.vessels[isVessel].aps.result,
    dop: info.vessels[isVessel].dop.dailyHire,
    dopResult: info.vessels[isVessel].dop.result,
    vessel: info.vessels[isVessel].vessel.id,
    delivery: info.vessels[isVessel].customDelivery.id,
    ballast: info.vessels[isVessel].customBallast.id,
    route: info.id
  }
  const rateAdcome = result ? ((result.rate)) : ''

  const theme = useTheme()
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      {result &&
        <Grid container alignItems='center' justifyContent='center'>
          <Grid item xs={6} md={3}>
            <Typography className={clsx(classes.textTitle, classes.data, classes.normalCell)}>Govdraft:</Typography>
            <EditableInputBase
              onChange={(e) => setInfo({ value: e.target.value, type: 'governingDraft', index, isVessel })}
              inputProps={{ style: { height: 25 } }}
              value={(result) ? result.intake.governingDraft : ''}
              onKeyDown={(e) => {
                if (e.keyCode === 9 || e.keyCode === 13) {
                  setInfo({
                    type: 'setTarget',
                    target: 'governingDraft'
                  })
                  setRecalc(true)
                  // calcUpdateMutation()
                }
              }}
              onBlur={(e) => {
                if (mobileSize || isMobile) {
                  setInfo({
                    type: 'setTarget',
                    target: 'governingDraft'
                  })
                  setRecalc(true)
                }
              }}
              className={classes.margin}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography className={clsx([classes.textTitle, classes.normalCell])}>{'Intake: '}&nbsp;</Typography>
            <EditableInputBase
              onChange={(e) => setInfo({ value: e.target.value, type: 'intake', index, isVessel })}
              inputProps={{ style: { height: 25 } }}
              value={(result) ? result.intake.intake : ''}
              onKeyDown={(e) => {
                if (e.keyCode === 9 || e.keyCode === 13) {
                  setInfo({
                    type: 'setTarget',
                    target: 'intake'
                  })
                  setRecalc(true)
                  // calcUpdateMutation()
                }
              }}
              onBlur={(e) => {
                if (mobileSize || isMobile) {
                  setInfo({
                    type: 'setTarget',
                    target: 'intake'
                  })
                  setRecalc(true)
                }
              }}
              className={classes.margin}
            />
          </Grid>
          {/* <Grid item xs={6} md={3}>
              <Typography className={classes.textTitle}>{'TCE: '}&nbsp;</Typography>
              <EditableInputBase
                onChange={(e) => setInfo({value: e.target.value, type: 'tce', index, isVessel})}
                value={(result) ? result.tce : ''}
                onKeyDown={(e) => {
                  if (e.keyCode === 9 || e.keyCode === 13) {

                  }
                  calcUpdateMutation()
                }}
                className={classes.margin}
              />
            </Grid> */}
          <Grid item xs={6} md={3}>
            <Typography className={clsx([classes.textTitle, classes.normalCell])}>{'Rate: '}&nbsp;</Typography>
            <EditableInputBase
              onChange={(e) => {
                const tempRate = (e.target.value)
                if (tempRate !== (rateAdcome / (1 - (adcomValue / 100))).toFixed(2)) {
                  setAdcomValue('0.00')
                  setInfo({ value: e.target.value, type: 'rate', index, isVessel })
                }
              }}
              inputProps={{ style: { height: 25 } }}
              value={(rateAdcome / (1 - (adcomValue / 100))).toFixed(2)}
              onKeyDown={(e) => {
                if (e.keyCode === 9 || e.keyCode === 13) {
                  setInfo({
                    type: 'setTarget',
                    target: 'rate'
                  })
                  setRecalc(true)
                  // calcUpdateMutation()
                }
              }}
              onBlur={(e) => {
                if (mobileSize || isMobile) {
                  setInfo({
                    type: 'setTarget',
                    target: 'rate'
                  })
                  setRecalc(true)
                }
              }}
              className={classes.margin}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            {/* <Typography className={classes.textTitle}>{'Adcom: '}&nbsp;</Typography>
            <Typography
              disabled
              className={classes.margin}
            >{(rateAdcome / (1 + (adcomValue / 100))).toFixed(2)}
            </Typography> */}
          </Grid>
          <MutationHelper
            mutation={UPSERT_SPOTFIXTURE}
            {...(props.onCompleted
              ? { onCompleted: props.onCompleted }
              : {
                onCompletedObject: {
                  title: 'Success',
                  message: 'Spot Fixture Created'
                  // path: '/spot-indexes'
                }
              }
            )}
            variables={{ input }}
          >
            {(mutation, result) => {
              return (
                <RootGrid>
                  <Link component='button' variant='body2' disabled={!vessel.vessel.id} onClick={() => mutation()}>
                    Create Spot Fixture
                  </Link>
                </RootGrid>
              )
            }}
          </MutationHelper>
        </Grid>}
    </>
  )
}

const VesselContainer = props => {
  const {
    adcomValue,
    calcUpdateMutation,
    checks,
    data,
    index,
    info,
    isVessel,
    ports,
    result,
    setInfo,
    setUpdateCheck,
    targetVessel,
    title,
    updateCheck,
    vessel,
    vessels,
    selectedResult,
    setAdcomValue,
    setRecalc
  } = props
  const classes = vesselStyles()
  return (
    <Card className={clsx(classes.card, { [classes.selectedVessel]: info.targetVessel === isVessel })}>
      <CardContent className={classes.cardContent}>
        <VesselCalc
          adcomValue={adcomValue}
          title={title}
          data={data}
          index={index}
          info={info}
          isVessel={isVessel}
          vessel={vessel}
          targetVessel={targetVessel}
          setInfo={setInfo}
          calcUpdateMutation={calcUpdateMutation}
          vessels={vessels}
          checks={checks}
          result={result}
          setUpdateCheck={setUpdateCheck}
          updateCheck={updateCheck}
          selectedResult={selectedResult}
          setRecalc={setRecalc}
        />
        <VesselDOPAndAps
          adcomValue={adcomValue}
          calcUpdateMutation={calcUpdateMutation}
          index={index}
          info={info}
          isVessel={isVessel}
          setInfo={setInfo}
          setUpdateCheck={setUpdateCheck}
          updateCheck={updateCheck}
          setRecalc={setRecalc}
        />
        <VesselPorts
          adcomValue={adcomValue}
          isVessel={isVessel}
          setInfo={setInfo}
          vessel={vessel}
          setUpdateCheck={setUpdateCheck}
          updateCheck={updateCheck}
          ports={ports}
          setRecalc={setRecalc}
        />
        <VesselResults
          adcomValue={adcomValue}
          calcUpdateMutation={calcUpdateMutation}
          index={index}
          info={info}
          isVessel={isVessel}
          result={result}
          setInfo={setInfo}
          vessel={vessel}
          setAdcomValue={setAdcomValue}
          setRecalc={setRecalc}
        />
      </CardContent>
    </Card>
  )
}

export default VesselContainer
