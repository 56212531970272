import React, { useState, useEffect, useRef, useContext } from 'react'
import PropTypes from 'prop-types'

// Helmet
import Helmet from 'react-helmet'

import { withRouter } from 'react-router-dom'
// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './PositionDatesContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_POSITIONDATE } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_POSITIONDATE } from '../Mutations/Mutations'
// Utils
import {
  positionIdRequired,
  nameRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

// Material UI
import Autocomlete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'

const switchPosNameDisplay = (positionId, selectedMonth) => {
  if (selectedMonth) {
    const posDateArray = selectedMonth.split('h')
    if ((positionId === 0 || positionId === 2) && posDateArray.length === 2) {
      return `2h${posDateArray[1]}`
    } else if ((positionId === 0 || positionId === 2) && posDateArray.length === 1) {
      return `2h${selectedMonth}`
    } else if (positionId === 1 && posDateArray.length === 2) {
      return `1h${posDateArray[1]}`
    } else if (positionId === 1 && posDateArray.length === 1) {
      return `1h${selectedMonth}`
    } else if (positionId > 2 && posDateArray.length === 2) {
      return posDateArray[1]
    } else if (positionId > 2 && posDateArray.length === 1) {
      return selectedMonth
    } else {
      return selectedMonth
    }
  }
}

const UpsertPositionDate = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.positionDate
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={GET_POSITIONDATE}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let positionDate = null
        if (data && data.getPositionDate && data.getPositionDate.positionDate) {
          positionDate = data.getPositionDate.positionDate
        }
        return (
          <>
            {
              positionDate
                ? <Helmet><title>Update Position Date</title></Helmet>
                : <Helmet><title>Create Position Date</title></Helmet>
            }
            <CreatePositionDate
              {...rest}
              positionDate={positionDate}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

const UpsertPositionDateWithRouter = withRouter(UpsertPositionDate)

UpsertPositionDateWithRouter.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default UpsertPositionDateWithRouter

const CreatePositionDate = props => {
  const { refetchQueries, positionDate } = props
  const { user, setUser } = useContext(UserContext)

  const content = positionDate ? upsertContent.update : upsertContent.create

  const validation = {
    positionId: positionIdRequired,
    positionDateName: nameRequired
  }

  const initialValues = {
    positionId: positionDate ? positionDate.positionId : '',
    positionDateName: positionDate ? positionDate.positionDateName : ''
  }

  const months2h = ['2hJan', '2hFeb', '2hMar', '2hApr', '2hMay', '2hJun', '2hJul', '2hAug', '2hSep', '2hOct', '2hNov', '2hDec']
  const months1h = ['1hJan', '1hFeb', '1hMar', '1hApr', '1hMay', '1hJun', '1hJul', '1hAug', '1hSep', '1hOct', '1hNov', '1hDec']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const [posId, setPosId] = useState(initialValues.positionId)
  const [selectedName, setSelectedName] = useState(initialValues.positionDateName)
  const autocompleteRef = useRef()
  const countRef = useRef(0)
  const [options, setOptions] = useState(
    posId === 0 || posId === 2
      ? months2h
      : posId === 1
        ? months1h
        : months
  )

  useEffect(() => {
    if (countRef.current > 0) {
      if (posId === 0 || posId === 2) {
        setOptions(months2h)
      } else if (posId === 1) {
        setOptions(months1h)
      } else {
        setOptions(months)
      }
    }
    setSelectedName(switchPosNameDisplay(posId, selectedName))
    countRef.current++
  }, [posId])

  const form = {
    positionId: useFormInput({
      initialValue: initialValues.positionId,
      label: 'positionId',
      value: posId,
      required: true,
      onChange: (e) => {
        setPosId(parseFloat(e.target.value))
      }
    })
  }

  const input = {
    ...(positionDate && { id: positionDate.id }),
    positionId: parseFloat(posId),
    positionDateName: selectedName
  }

  const next = ({ mutation }) => {
    validateStep({
      form,
      next: () => mutation(),
      validation
    })
  }

  return (
    <MutationHelper
      mutation={UPSERT_POSITIONDATE}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <RootForm
                form={form}
                validation={validation}
              />
              <Autocomlete
                value={selectedName}
                options={options}
                renderOption={(option) => option}
                onChange={(e, newValue) => {
                  setSelectedName(newValue)
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      inputRef={autocompleteRef}
                      placeholder='positionDateName'
                    />
                  )
                }}
                filterSelectedOptions
              />
              <RootButton disabled={result.loading || user.disabled} justifyContent='center' onClick={() => next({ mutation })}>
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreatePositionDate.defaultProps = {
  refetchQueries: []
}

CreatePositionDate.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array,
  positionDate: PropTypes.object
}
