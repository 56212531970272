import React from 'react'
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { versions } from './versions'
import { formatDate } from '../Utils/utils'

export const VersionInformation = props => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%'
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary
    }
  }))

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Release Notes</title>
      </Helmet>
      <Typography variant='h4' gutterBottom>Release Notes</Typography>
      {
        versions.map((version, index) => {
          return (
            <Accordion key={index}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${version.version}-content`}
                id={`${version.version}-header`}
              >
                <Typography className={classes.heading}>Version: {version.version}</Typography>
                <Typography className={classes.secondaryHeading}>{formatDate({ date: new Date(version.date) })}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {
                    version.fixes.map((fix, i) => {
                      return (
                        <li key={i}>
                          <Typography>
                            {fix}
                          </Typography>
                        </li>
                      )
                    })
                  }
                </ul>
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </div>
  )
}

VersionInformation.propTypes = {

}

export default VersionInformation
