
import { Filters } from 'react-data-grid-addons'
const { SingleSelectFilter } = Filters

const breadCrumbs = [
  {
    link: '/vessels',
    name: 'Vessels'
  }
]

const defaultColumnProperties = {
  width: 120
}

export const show = {
  createButton: {
    name: 'Create vessel',
    link: '/create-vessel'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'vesselName', name: 'vesselName', filterRenderer: SingleSelectFilter },
      { key: 'dwt', name: 'dwt' },
      { key: 'draft', name: 'draft' },
      { key: 'tpc', name: 'tpc' },
      { key: 'cubicFT', name: 'cubicFT' },
      { key: 'yard', name: 'yard' },
      // { key: 'yearBuilt', name: 'yearBuild' },
      { key: 'yearBuilt', name: 'build' },
      // Used to be ballast speed
      { key: 'ballastSpeedArray', name: 'BSpeed' },
      // Removed consumption
      { key: 'ballastIFOConsumption', name: 'ballastIFO' },
      { key: 'ballastMGOConsumption', name: 'ballastMGO' },
      // Used to be ladenSpeedArray
      { key: 'ladenSpeedArray', name: 'LSpeed' },
      { key: 'ladenIFOConsumption', name: 'ladenIFO' },
      { key: 'ladenMGOConsumption', name: 'laddenMGO' }
      // { key: 'inPortIFOIdle', name: 'inPortIFOIdle' },
      // { key: 'inPortIFOWorking', name: 'inPortIFOWorking' },
      // { key: 'inPortMGOIdle', name: 'inPortMGOIdle' },
      // { key: 'inPortMGOWorking', name: 'inPortMGOWorking' },

    ].map(c => ({ ...c, ...defaultColumnProperties })),
    rows: 3
  },
  module: {
    name: 'vessel'
  },
  update: {
    link: '/update-vessel'
  }
}

export const upsert = {
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Vessel created',
      path: '/vessels'
    },
    submitButton: 'Create Vessel'
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Vessel updated',
      path: '/vessels'
    },
    submitButton: 'Update Vessel'
  },
  form: {
    ballastIFOConsumption: {
      array: {
        initialQty: 0,
        title: 'Ballast IFO Consumption'
      },
      item: {
        type: 'number',
        name: 'consumption',
        emptyValue: ''
      }
    },
    ballastSpeed: {
      array: {
        initialQty: 0,
        title: 'Ballast Speed Array'
      },
      item: {
        type: 'number',
        name: 'speed',
        emptyValue: ''
      }
    },
    ballastMGOConsumption: {
      array: {
        initialQty: 0,
        title: 'Ballast MGO Consumption'
      },
      item: {
        type: 'number',
        name: 'consumption',
        emptyValue: ''
      }
    },
    ladenIFOConsumption: {
      array: {
        initialQty: 0,
        title: 'Laden IFO Consumption'
      },
      item: {
        type: 'number',
        name: 'consumption',
        emptyValue: ''
      }
    },
    ladenSpeed: {
      array: {
        initialQty: 0,
        title: 'Laden Speed Array'
      },
      item: {
        type: 'number',
        name: 'speed',
        emptyValue: ''
      }
    },
    ladenMGOConsumption: {
      array: {
        initialQty: 0,
        title: 'Laden MGO Consumption'
      },
      item: {
        type: 'number',
        name: 'consumption',
        emptyValue: ''
      }
    }
  }
}
