// React
import React from 'react'
// Material UI
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
// PropTypes
import PropTypes from 'prop-types'
// Styles
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1 1 auto',
    padding: '5%',
    margin: '0 auto',
    width: 'calc(100% - 16px)',
    maxWidth: '1600px',
  },
  rootNoPadding: {
    flex: '1 1 auto',
    maxWidth: '1600px',
    margin: '0 auto',
  },
}))

const RootGrid = props => {
  const classes = useStyles()
  const {
    children,
    padding,
    ...rest
  } = props

  return (
    <Grid container direction='column' className={clsx({[classes.root]: padding, [classes.rootNoPadding]: !padding})} {...rest}>
      { children }
    </Grid>
  )
}

RootGrid.propTypes = {
  padding: PropTypes.bool,
}

export default RootGrid
