// Client Results
import ClientResultsQueryHelper from '../ClientResults/ClientResults'
// Commodity
import ShowCommodity from '../Commodity/ShowCommodity'
import UpsertCommodityQueryHelper from '../Commodity/UpsertCommodity'
// CargoWorking
import ShowCargoWorkings from '../CargoWorking/ShowCargoWorkings'
import UpsertCargoWorking from '../CargoWorking/UpsertCargoWorking'
// Distance
import ShowDistance from '../Distance/ShowDistance'
import UpsertDistanceQueryHelper from '../Distance/UpsertDistance'
// Forward Curve
import ShowForwardCurve from '../ForwardCurve/ShowForwardCurve'
import UpsertForwardCurveQueryHelper from '../ForwardCurve/UpsertForwardCurve'
// ForwardValidation
import ForwardValidationWrapper from '../ForwardValidation/ForwardValidation'
import Validation from '../ForwardValidation/Validation'
// Owners
import ShowOwners from '../Owner/ShowOwners'
import UpsertOwner from '../Owner/UpsertOwner'
// Multiple Imports
import MultipleImports from './MultipleImports'
// Port
import ShowPorts from '../Ports/ShowPorts'
import UpsertPortQueryHelper from '../Ports/UpsertPorts'
// Position Date
// import ShowPositionDate from '../PositionDate/ShowPositionDate'
// import UpsertPositionDate from '../PositionDate/UpsertPositionDate'
// RoutesResult
import ShowRouteResultsArray from '../RouteResultsArray/ShowRouteResultsArray'
// Routes
import ShowClientRoutes from '../Route/ShowClientRoutes'
import ShowRoute from '../Route/ShowRoute'
import UpsertRouteQueryHelper from '../Route/UpsertRoute'
// Ship Sectors
import ShowShipSectors from '../ShipSectors/ShowShipSectors'
import UpsertShipSector from '../ShipSectors/UpsertShipSector'
// Spot index
import ShowSpotIndexes from '../SpotIndexes/ShowSpotIndexes'
import UpsertSpotIndexQueryHelper from '../SpotIndexes/UpsertSpotIndex'
// Spot TC Spread
import ShowSpotTCSpread from '../SpotTCSpread/ShowSpotTCSpread'
import UpsertSpoTCSpread from '../SpotTCSpread/UpsertSpoTCSpread'
// Spot Validation
import SpotValidation from '../SpotValidation/SpotValidation'
// Time Counting
import ShowTimeCountings from '../TimeCountings/ShowTimeCountings'
import UpsertTimeCounting from '../TimeCountings/UpsertTimeCounting'
// Users
import ShowUsers from '../Users/ShowUsers'
import UpsertUser from '../Users/UpsertUser'
// Vessels
import ShowVessels from '../Vessels/ShowVessels'
import UpsertVessel from '../Vessels/UpsertVessel'
// Zone
import ShowZones from '../Zones/ShowZones'
import UpsertZone from '../Zones/UpsertZone'
import { VoyageOffer } from '../VoyageOffer/VoyageOffer'
// Daily Upload
import Import from './Import'

const client = {
  buttons: {
    routeResults: {
      create: false,
      edit: false,
      delete: false
    }
  },
  menuItems: [
    {
      link: '/my-routes',
      text: 'My Routes',
      activeLinks: ['/my-routes']
    }
  ],
  headerItems: [],
  routes: [
    {
      path: '/',
      // TODO CHANGE CLIENT RESULTS
      component: ShowClientRoutes,
      exact: true
    },
    {
      path: '/my-routes',
      component: ShowClientRoutes,
      exact: true
    }
  ],
  shortCuts: []
}

const admin = {
  buttons: {
    routeResults: {
      create: false,
      edit: false,
      delete: false,
      view: true,
      voyageOffer: true
    },
    routes: {
      create: true,
      edit: true,
      delete: true
    },
    commodity: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    distance: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    vessels: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    forwardCurve: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    forwardValidation: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    ports: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    // positionDate: {
    //   create: true,
    //   edit: true,
    //   delete: true,
    //   view: true
    // },
    spotIndexes: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    shipSectors: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    spotTCSpread: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    timeCountings: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    users: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    owners: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    zones: {
      create: true,
      edit: true,
      delete: true,
      view: true
    },
    cargoWorkings: {
      create: true,
      edit: true,
      delete: true,
      view: true
    }
  },
  menuItems: [
    {
      link: '/spot-indexes',
      text: 'Spot index',
      activeLinks: ['/spot-indexes', '/create-spot-index', '/update-spot-index']
    },
    {
      link: '/spot-tc-spreads',
      text: 'Spot TC Spread',
      activeLinks: ['/spot-tc-spreads', '/create-spot-tc-spread', '/update-spot-tc-spread']
    },
    {
      link: '/forward-curves',
      text: 'Forward Curve',
      activeLinks: ['/forward-curves', '/create-forward-curve', '/update-forward-curve']
    },
    {
      link: '/commodities',
      text: 'Commodity',
      activeLinks: ['/commodities', '/create-commodity', '/update-commodity']
    },
    {
      link: '/distances',
      text: 'Distance',
      activeLinks: ['/distances', '/create-distance', '/update-distance']
    },
    {
      link: '/ports',
      text: 'Ports',
      activeLinks: ['/ports', '/create-port', '/update-port']
    },
    // {
    //   link: '/position-dates',
    //   text: 'Position Dates',
    //   activeLinks: ['/position-dates', '/create-position-date', '/update-position-date']
    // },
    {
      link: '/time-countings',
      text: 'Time Counting',
      activeLinks: ['/time-countings', '/create-time-counting', '/update-time-counting']
    },
    {
      link: '/ship-sectors',
      text: 'Ship Sectors',
      activeLinks: ['/ship-sectors', '/create-ship-sector', '/update-ship-sector']
    },
    {
      link: '/users',
      text: 'Users',
      activeLinks: ['/users', '/create-user', '/update-user']
    },
    {
      link: '/owners',
      text: 'Owners',
      activeLinks: ['/owners', '/create-owner', '/update-owner']
    },
    {
      link: '/vessels',
      text: 'Vessels',
      activeLinks: ['/vessels', '/create-vessel', '/update-vessel']
    },
    {
      link: '/zones',
      text: 'Zones',
      activeLinks: ['/zones', '/create-zone', '/update-zone']
    },
    {
      link: '/import',
      text: 'Import',
      activeLinks: ['/import']
    },
    {
      link: '/daily-upload',
      text: 'Daily Upload',
      activeLinks: ['/daily-upload', '/daily-uploads']
    },
    {
      link: 'https://app.usdpmt.com/admin/historic-route-data',
      text: 'Historic',
      activeLinks: []
    }
  ],
  headerItems: [
    {
      link: '/routes',
      text: 'Route Library',
      activeLinks: ['/routes']
    },
    {
      link: '/calculation-page',
      text: 'Calculation page',
      activeLinks: ['/calculation-page']
    },
    {
      link: '/client-results',
      text: 'Client Results',
      activeLinks: ['/client-results']
    },
    {
      link: '/validations',
      text: 'Validation',
      activeLinks: ['/validations']
    },
    {
      link: '/cargo-workings',
      text: 'Cargo Working',
      activeLinks: ['/cargo-workings', '/create-cargo-working', 'update-cargo-working']
    }
  ],
  routes: [
    {
      path: '/',
      component: ShowRoute,
      exact: true
    },
    // Calculation page
    {
      path: '/calculation-page/:route?',
      component: ShowRouteResultsArray,
      exact: false
    },
    // Client Results
    {
      path: '/client-results',
      component: ClientResultsQueryHelper,
      exact: true
    },
    // Comodities
    {
      path: '/create-commodity',
      component: UpsertCommodityQueryHelper,
      exact: true
    },
    {
      path: '/update-commodity/:commodity',
      component: UpsertCommodityQueryHelper,
      exact: false
    },
    {
      path: '/commodities',
      component: ShowCommodity,
      exact: true
    },
    // Distance
    {
      path: '/create-distance',
      component: UpsertDistanceQueryHelper,
      exact: true
    },
    {
      path: '/distances',
      component: ShowDistance,
      exact: true
    },
    {
      path: '/update-distance/:distance',
      component: UpsertDistanceQueryHelper,
      exact: false
    },
    // Forward curve
    {
      path: '/update-forward-curve/:forwardCurve',
      component: UpsertForwardCurveQueryHelper,
      exact: false
    },
    {
      path: '/forward-curves',
      component: ShowForwardCurve,
      exact: true
    },
    {
      path: '/create-forward-curve',
      component: UpsertForwardCurveQueryHelper,
      exact: true
    },
    // Forwarrd Validation
    {
      path: '/validations',
      component: Validation,
      exact: true
    },
    // Owner
    {
      path: '/owners',
      component: ShowOwners,
      exact: true
    },
    {
      path: '/create-owner',
      component: UpsertOwner,
      exact: true
    },
    {
      path: '/update-owner/:owner',
      component: UpsertOwner,
      exact: false
    },
    // Multiple imports
    {
      path: '/import',
      component: MultipleImports,
      exact: true
    },
    // Port
    {
      path: '/create-port',
      component: UpsertPortQueryHelper,
      exact: true
    },
    {
      path: '/ports',
      component: ShowPorts,
      exact: true
    },
    {
      path: '/update-port/:port',
      component: UpsertPortQueryHelper,
      exact: false
    },
    // Position date
    // {

    //   path: '/create-position-date',
    //   component: UpsertPositionDate,
    //   exact: true
    // },
    // {
    //   path: '/position-dates',
    //   component: ShowPositionDate,
    //   exact: true
    // },
    // {
    //   path: '/update-position-date/:positionDate',
    //   component: UpsertPositionDate,
    //   exact: false
    // },
    // Route
    {
      path: '/create-route',
      component: UpsertRouteQueryHelper,
      exact: true
    },
    {
      path: '/routes',
      component: ShowRoute,
      exact: true
    },
    {
      path: '/update-route/:route',
      component: UpsertRouteQueryHelper,
      exact: false
    },
    // Ship sector
    {
      path: '/update-ship-sector/:shipSector',
      component: UpsertShipSector,
      exact: false
    },
    {
      path: '/create-ship-sector',
      component: UpsertShipSector,
      exact: true
    },
    {
      path: '/ship-sectors',
      component: ShowShipSectors,
      exact: true
    },
    // Spot Index
    {
      path: '/create-spot-index',
      component: UpsertSpotIndexQueryHelper,
      exact: true
    },
    {
      path: '/spot-indexes',
      component: ShowSpotIndexes,
      exact: true
    },
    {
      path: '/update-spot-index/:spotIndex',
      component: UpsertSpotIndexQueryHelper,
      exact: false
    },
    // Spot TC Spread
    {
      path: '/create-spot-tc-spread',
      component: UpsertSpoTCSpread,
      exact: true
    },
    {
      path: '/update-spot-tc-spread/:spotTCSpread',
      component: UpsertSpoTCSpread,
      exact: true
    },
    {
      path: '/spot-tc-spreads',
      component: ShowSpotTCSpread,
      exact: true
    },
    // Spot Validation
    {
      path: '/tc-input',
      component: SpotValidation,
      exact: true
    },
    // Time countings
    {
      path: '/create-time-counting',
      component: UpsertTimeCounting,
      exact: true
    },
    {
      path: '/time-countings',
      component: ShowTimeCountings,
      exact: true
    },
    {
      path: '/update-time-counting/:timeCounting',
      component: UpsertTimeCounting,
      exact: false
    },
    // Users
    {
      component: ShowUsers,
      path: '/users',
      exact: true
    },
    {
      component: ShowUsers,
      path: '/users',
      exact: true
    },
    {
      path: '/create-user',
      component: UpsertUser,
      exact: true
    },
    {
      path: '/update-user/:user',
      component: UpsertUser,
      exact: false
    },
    // Vessel
    {
      path: '/create-vessel',
      component: UpsertVessel,
      exact: true
    },
    {
      path: '/vessels',
      component: ShowVessels,
      exact: true
    },
    {
      path: '/update-vessel/:vessel',
      component: UpsertVessel,
      exact: false
    },
    // Zone
    {
      path: '/create-zone',
      component: UpsertZone,
      exact: true
    },
    {
      path: '/zones',
      component: ShowZones,
      exact: true
    },
    {
      path: '/update-zone/:zone',
      component: UpsertZone,
      exact: false
    },
    {
      path: '/voyage-offer',
      component: VoyageOffer,
      exact: true
    },
    // Cargo Workings
    {
      path: '/cargo-workings',
      component: ShowCargoWorkings,
      exact: true
    },
    {
      path: '/create-cargo-working',
      component: UpsertCargoWorking,
      exact: true
    },
    {
      path: '/update-cargo-working/:cargoWorking',
      component: UpsertCargoWorking,
      exact: false
    },
    {
      path: '/daily-upload',
      component: Import,
      exact: true
    },
    {
      path: '/import',
      component: MultipleImports,
      exact: true
    }
  ],
  shortCuts: [
    {
      held: ['Control'],
      ordered: ['1'],
      link: '/routes'
    },
    {
      held: ['Control'],
      ordered: ['2'],
      link: '/calculation-page'
    },
    {
      held: ['Control'],
      ordered: ['3'],
      link: '/client-results'
    },
    {
      held: ['Control'],
      ordered: ['4'],
      link: '/validations'
    },
    {
      held: ['Control'],
      ordered: ['5'],
      link: '/tc-input'
    }
  ]

}

const owner = {
  ...admin
}

export default {
  roles: {
    admin,
    client,
    owner
  }
}
