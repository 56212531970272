import gql from 'graphql-tag'

export const CREATE_USER_INFORMATION = gql`
  query createUserInformation {
    createUserInformation {
      roles
      routes {
        id
        routeName
        commodity {
          commodityName
        }
        loadPortArray {
          portName
          country {
            name
          }
        }
        dischargePortArray {
          portName
          country {
            name
          }
        }
      }
    }
  }
`

export const CREATE_VOYAGEOFFER_INFORMATION = gql`
  query createVoyageOfferInformation {
    createVoyageOfferInformation {
      # positionDates {
      #   id
      #   positionDateName
      # }
      # routes {
      #   id
      #   routeName
      #   codeNameLong
      #   commodity {
      #     commodityName
      #   }
      #   loadPorts {
      #     id
      #     portName
      #   }
      #   dischargePorts {
      #     id
      #     portName
      #   }
      # }
      owners {
        id
        ownerName
      }
      # clients {
      #   id
      #   name
      # }
    }
  }
`

export const CREATE_CARGOWORKING_INFORMATION = gql`
  query createCargoWorkingInformation {
    createCargoWorkingInformation {
      admins {
        id
        name
      }
      owners {
        id
        ownerName
      }
      positionDates {
        id
        positionDateName
      }
      privacyTypes
      routes {
        id
        routeName
      }
      users {
        id
        name
        userSetting {
          adcom
        }
      }
    }
  }
`

export const CREATE_CARGOWORKING_OFFER_INFORMATION = gql`
  query createCargoWorkingOfferInformation {
    createCargoWorkingOfferInformation {
      owners {
        id
        name
      }
      ports {
        id
        name
      }
      vessels {
        id
        name
      }
    }
  }
`

export const CREATE_COMMODITY_INFORMATION = gql`
  query createCommodityInformation{
    createCommodityInformation {
      positionDates {
        id
        positionDateName
      }
    }
  }
`

export const CREATE_DISTANCE_INFORMATION = gql`
  query createDistanceInformation {
    createDistanceInformation{
      ports {
        id
        portName
        distanceOnly
      }
    }
  }
`

export const SPOT_VALIDATION_ROUTE = gql`
  query GetRoute($input: GetRouteInput!){
  getRoute(input: $input){
    route {
      id
      vessel {
        id
        vesselName
      }
      routeName
      deliveryPort {
        id
        portName
      }
      # viaBallastPortIDArray {
      #   id
      #   bunkeringTime
      #   costArray
      #   draftArray
      #   FO05
      #   MGO01
      #   forwardCommodityFOID {
      #     forwardArray
      #   }
      #   forwardCommodityMGOID {
      #     forwardArray
      #   }
      #   fuelDeliveryPremiumArray
      #   portName
      #   salinity
      #   shipSectorIDArray {
      #     shipSectorName
      #   }
      #   waitingIdleArray
      #   zone {
      #     id
      #     weatherMarginArray
      #   }
      # }
      viaBallastPortBunkerArray
      viaBallastPortIdleWorking
      deliveryPortBunker
      deliveryPortIdleWorking
      forwardCurve {
        spotIndex {
          id
          indexName
        }
      }
      routeResults {
        routeResults
      }
    }
  }
}
`

export const GET_VOYAGE_OFFER_INFORMATION = gql`
  query getVoyageOfferInformation($input: GetVoyageOfferInformationInput!){
    getVoyageOfferInformation (input: $input)
  }
`

export const GET_VOYAGE_OFFER = gql`
  query getVoyageOffer($input: GetVoyageOfferInput!) {
    getVoyageOffer(input: $input) {
      voyageOffer {
        id
        adcome
        bid
        originalBid
        offer
        originalOffer
        positionDate {
          id
          positionDateName
        }
        route {
          id
          routeName
        }
        owner {
          id
          ownerName
        }
        client {
          id
          name
        }
      }
    }
  }
`

export const GET_SPOTFIXTUERE = gql`
  query getSpotFixture($input: GetSpotFixtureInput!){
    getSpotFixture (input: $input) {
      spotFixture {
        id
        apsDH
        apsPC
        apsResult
        dop
        dopResult
        vessel {
          id
          vesselName
        }
        spotIndex {
          id
          indexName
        }
        deliveryPort {
          id
          portName
        }
        ballastPort {
          id
          portName
        }
      }
    }
  }
`

export const GET_SPOTFIXTUERES = gql`
  query getSpotFixtures {
    getSpotFixtures{
      spotFixtures {
        id
        apsDH
        apsPC
        apsResult
        dop
        dopResult
        vessel {
          id
          vesselName
        }
        spotIndex {
          id
          indexName
        }
        deliveryPort {
          id
          portName
        }
        ballastPort {
          id
          portName
        }
      }
    }
  }
`

export const CREATE_SPOTVALIDATION_INFORMATION = gql`
  query createSpotValidationInformation {
    createSpotValidationInformation{
      ports {
        id
        portName
        berth
      }
      shipSectors {
        id
        shipSectorName
        dwtMax
        dwtMin
        spotIndexes {
          id
          indexName
          indexValue
          indexAdjustmentAbsolute
          indexAdjustmentPercent
        }
      }
      spotTCSpreads {
        id
        spotTCSpreadName
        spreadTCValue
      }
      routes {
        id
        routeName
        forwardCurve {
          spotIndex {
            id
            indexName
          }
        }
      }
      spotFixtures {
        id
        dopResult
        dop
        spotIndex {
          id
        }
      }
      vessels {
        id
        ballastSpeedArray
        ballastIFOConsumption
        ballastMGOConsumption
        inPortIFOIdle
        inPortIFOWorking
        inPortMGOIdle
        inPortMGOWorking
        ladenSpeedArray
        ladenIFOConsumption
        ladenMGOConsumption
        vesselName
        cubicFT
        dwt
        draft
        tpc
    }
    }
  }
`

export const CREATE_FORWARDCURVE_INFORMATION = gql`
  query createForwardCurveInformation{
    createForwardCurveInformation {
      positionDates {
        id
        positionDateName
      }
      commodities {
        id
        commodityName
      }
      forwardCurves {
        id
        forwardCurveName
        basedOnForwardCurve {
          id
        }
      }
      routes {
        id
        routeName
        codeNameLong
      }
      spotIndexes {
        id
        indexName
      }
    }
  }
`

export const CREATE_FORWARDVALIDATION_INFORMATION = gql`
  query createForwardValidationInformation{
    createForwardValidationInformation {
      forwardCurves {
        id
        forwardCurveName
      }
    }
  }
`

export const GET_FORWARDVALIDATION = gql`
  query getForwardValidation($input: GetForwardValidationInput!) {
    getForwardValidation(input: $input) {
      forwardValidation {
        id
        forwardCurve {
          id
          forwardCurveName
        }
        forwardPremiumPercent
        forwardPremiumAbsolute
      }
    }
  }
`

export const GET_FORWARDVALIDATIONS = gql`
  query getForwardValidations {
    getForwardValidations {
      forwardValidations {
        id
        forwardCurve {
          id
          forwardCurveName
        }
        forwardPremiumPercent
        forwardPremiumAbsolute
      }
    }
  }
`

export const GET_FOB_FUTURE_MAPPING = gql`
  query getFobFutureMapping($input: GetFobFutureMappingInput!) {
    getFobFutureMapping(input: $input) {
      fobFutureMapping {
        id
        monthKeys
        monthValues
        monthsMapping
      }
    }
  }
` 

export const GET_FOBDATA_FROM_CLIENT = gql`
  query getFobDataFromClient($input: GetFobDataFromClientInput!) {
    getFobDataFromClient(input: $input) {
      fobPremiums {
        id
        country {
          id
          name
        }
        index
        value
      }
      fobImports {
        id
        country {
          id
          name
        }
        index
        value
      }
      fobFutures {
        id
        index
        value
      }
      loadCountries
      dischargeCountries
      fobDataStructure
    }
  }
`

export const CREATE_PORT_INFORMATION = gql`
  query createPortInformation{
    createPortInformation {
      commodities {
        id
        commodityName
      }
      countries {
        id
        name
      }
      positionDates {
        positionDateName
      }
      shipSectors {
        id
        shipSectorName
      }
      timeCountings {
        id
        timeCountingName
      }
      zones {
        id
        zoneName
      }
    }
  }
`

export const CREATE_ROUTE_INFORMATION = gql`
query createRouteInformation{
  createRouteInformation{
    commodities {
      id
      commodityName
    }
    forwardCurves {
      id
      forwardCurveName
      spotIndex {
        id
        shipSector {
          id
          shipSectorName
        }
      }
      spotTCSpreads {
        id
        spotTCSpreadName
      }
    }
    ports {
      id
      portName
      berth
      distanceOnly
      draftArray
      FO05
    }
    routes {
      id
      routeName
    }
    spotTCSpreads {
      id
      spotTCSpreadName
      spreadTCValue
      shipSector {
        id
        shipSectorName
      }
    }
    timeCountings {
      id
      timeCountingName
    }
    users {
      id
      name
    }
    vessels {
      id
      vesselName
      dwt
    }
  }
}
`
export const CREATE_SPOTINDEX_INFORMATION = gql`
  query createSpotIndexInformation{
    createSpotIndexInformation {
      shipSectors {
        id
        shipSectorName
      }
      routes {
        id
        routeName
      }
    }
  }
`
export const CREATE_SPOTTCSPREAD_INFORMATION = gql`
  query createSpotTCSpreadInformation{
    createSpotTCSpreadInformation {
      shipSectors {
        id
        shipSectorName
      }
      positionDates {
        id
        positionDateName
      }
      zones {
        id
        zoneName
        zoneDescription
      }
    }
  }
`

export const CREATE_ZONE_INFORMATION = gql`
  query createZoneInformation{
    createZoneInformation {
      ports {
        id
        portName
      }
    }
  }
`

export const GET_CLIENT_RESULT = gql`
  query getRoute($input: GetRouteInput!) {
    getRoute(input: $input) {
      route {
        id
        routeName
        stem
        tolerance
        commodity {
          id
          cargo
          commodityName
        }
        commission
        constants
        # dischargePortArray {
        #   id
        #   portName
        # }
        # loadPortArray {
        #   id
        #   portName
        #   country {
        #     id
        #     name
        #     flag
        #   }
        # }
        redeliveryPortBunker
        redeliveryPortIdleWorking
        routeResults {
          routeResults
        }
        vessel {
          id
          ballastSpeedArray
          ballastIFOConsumption
          ballastMGOConsumption
          inPortIFOIdle
          inPortIFOWorking
          inPortMGOIdle
          inPortMGOWorking
          ladenSpeedArray
          ladenIFOConsumption
          ladenMGOConsumption
          vesselName
          cubicFT
          dwt
          draft
          tpc
        }
      }
    }
  }
`

export const GET_CLIENT_RESULTS = gql`
  query getRoutes {
    getRoutes {
      routes {
        id
        routeName
      }
    }
  }
`

export const GET_CLIENT_ROUTES = gql`
  query getClientRoutes {
    getClientRoutes {
      routes {
        id
        routeName
      }
    }
  }
`

export const GET_COMMODITY = gql`
  query GetCommodity($input: GetCommodityInput!){
    getCommodity(input: $input){
      commodity {
        id
        cargo
        commodityName
        stowFactor
        forwardArray
      }
    }
  }
`

export const GET_CARGO_COMMODITIES = gql`
  query getCargoCommodities {
    getCargoCommodities {
      commodities {
        id
        cargo
        commodityName
        stowFactor
        forwardArray
      }
    }
  }
`

export const GET_COMMODITIES = gql`
  query getCommodities {
    getCommodities {
      commodities {
        id
        cargo
        commodityName
        stowFactor
        forwardArray
      }
    }
  }
`

export const GET_DISTANCE = gql`
  query getDistance($input: GetDistanceInput!) {
    getDistance(input: $input) {
      distance {
        id
        portID1 {
          id
          portName
        }
        portID2 {
          id
          portName
        }
        fullDist
        ecaDist
      }
    }
  }
`

export const GET_DISTANCES = gql`
  query getDistances {
    getDistances {
      distances {
        id
        portID1 {
          id
          portName
        }
        portID2 {
          id
          portName
        }
        fullDist
        ecaDist
      }
    }
  }
`

export const GET_FORWARDCURVE = gql`
query GetForwardCurve($input: GetForwardCurveInput!){
  getForwardCurve(input: $input){
    forwardCurve {
      id
      curveAdjustmentAbsolute
      curveAdjustmentPercent
      forwardCurveName
      forwardCommodity {
        id
        commodityName
      }
      basedOnForwardCurve {
        id
        forwardCurveName
      }
      coreRoute {
        id
        routeName
        codeNameLong
      }
      spotIndex {
        id
        indexName
      }
    }
    familyTree
  }
}
`

export const GET_FORWARDCURVES = gql`
  query getForwardCurves {
    getForwardCurves {
      forwardCurves {
        id
        forwardCurveName
        forwardCommodity {
          id
          commodityName
        }
        basedOnForwardCurve {
          id
          forwardCurveName
        }
        spotIndex {
          indexValue
          indexName
          indexAdjustmentAbsolute
          indexAdjustmentPercent
        }
        coreRoute {
          id
          routeName
          codeNameLong
        }
      }
    }
  }
`

export const GET_CARGOWORKINGS = gql`
query getCargoWorkings {
  getCargoWorkings {
    cargoWorkings
    positionDates {
      id
      positionDateName
    }
  }
}
`

export const GET_CARGOWORKING = gql`
query getCargoWorking($input: GetCargoWorkingInput!) {
  getCargoWorking(input: $input) {
    cargoWorking {
      id
      status
      account {
        id
      }
      route {
        id
      }
      laycan
      commission
      month {
        id
      }
      bid
      privacySettings
    }
  }
}
`

export const GET_MY_INFORMATION = gql`
  query getMyInformation{
    getMyInformation {
      user{
        id
      }
    }
  }
`
export const GET_FORWARDVALIDATION_INFORMATION = gql`
  query getForwardValidationInformation{
    getForwardValidationInformation {
      customSectors
      shipSectors {
        id
        shipSectorName
      }
      forwardCurves {
        id
        curveAdjustmentAbsolute
        curveAdjustmentPercent
        forwardCurveName
        updatedAt
        spotIndex {
          id
          indexName
          shipSector {
            id
          }
        }
      }
      voyageOffers {
        offer
        adcome
        createdBy {
          id
          name
        }
        user {
          id
          name
        }
        route {
          id
          routeName
        }
        positionDate {
          id
        }
      }
      routes {
        id
        routeName
        forwardCurve {
          id
          forwardCurveName
          curveAdjustmentAbsolute
          curveAdjustmentPercent
          spotIndex {
            id
            indexValue
            indexName
            indexAdjustmentAbsolute
            indexAdjustmentPercent
          }
          forwardCommodity {
            id
            forwardArray
          }
        }
        # routeResults {
        #   routeResults
        # }
      }
      positionDates {
        id
        positionDateName
      }
    }
  }
`

export const GET_FORWARDVALIDATION_ROUTE = gql`
query GetRoute($input: GetRouteInput!){
  getRoute(input: $input){
    route {
      id
      routeName
      forwardCurve {
        id
        forwardCurveName
        curveAdjustmentAbsolute
        curveAdjustmentPercent
        spotIndex {
          id
          indexValue
          indexName
          indexAdjustmentAbsolute
          indexAdjustmentPercent
        }
        forwardCommodity {
          id
          forwardArray
        }
      }
      routeResults {
        routeResults
      }
    }
  }
}
`

export const GET_HEAT_MAP_VALUES = gql`
  query GetHeatMapValues {
    getHeatMapValues {
      heatMapLow
      heatMapMedium
      heatMapHigh
      heatMapDays
    }
  }
`

export const GET_SUMMARY_FROM_CLIENT = gql`
  query GetSummaryFromUser($input: GetSummaryFromUserInput!){
    getSummaryFromUser(input: $input)
  }
`

export const GET_OFFERS_FROM_CURVE = gql`
  query GetOffersFromCurve($input: GetOffersFromCurveInput!) {
    getOffersFromCurve(input: $input) {
      voyageOffers {
        id
        activeValidation
        adcome
        bid
        createdAt
        createdBy {
          id
          name
        }
        updatedAt
        offer
        originalOffer
        owner {
          ownerName
        }
        user {
          id
          name
        }
        route {
          id
          routeName
          forwardCurve {
            id
          }
          vessel {
            vesselName
          }
        }
        positionDate {
          id
          positionDateName
          positionId
        }
        savedBase
        savedFuelPrice
      }
    }
  }
` 

export const GET_OWNER = gql`
  query GetOwner($input: GetOwnerInput!){
    getOwner(input: $input) {
      owner {
        id
        ownerName
        offers {
          active
          adcome
          accepted
          originalBid
          createdAt
          createdBy {
            name
          }
          originalOffer
          positionDate {
            positionDateName
          }
          route {
            routeName
          }
        }
      }
    }
  }
`

export const GET_OWNERS = gql`
  query getOwners {
    getOwners
  }
`

export const GET_PORT = gql`
  query GetPort($input: GetPortInput!){
    getPort(input: $input){
      port {
        id
        berth
        bunkeringCost
        bunkeringTime
          country {
            id
            name
          }
        costArray
        distanceOnly
        draftArray
        eca #TODO UNCOMMENT
        FO05
        HSFO380
        MGO01
        forwardCommodityFOID {
          id
          commodityName
        }
        forwardCommodityMGOID {
          id
          commodityName
        }
        fuelDeliveryPremiumArray
        portName
        salinity
        shipSectors
        shipSectorIDArray {
          id
          shipSectorName
        }
        waitingIdleArray
        zone {
          id
          zoneName
        }
      }
    }
  }
`

export const GET_PORTS = gql`
  query getPorts {
    getPorts {
      ports {
        id
        berth
        bunkeringCost
        bunkeringTime
        country {
          id
          name
        }
        costArray
        distanceOnly
        draftArray
        eca
        FO05
        HSFO380
        MGO01
        forwardCommodityFOID {
          id
          commodityName
        }
        forwardCommodityMGOID {
          id
          commodityName
        }
        fuelDeliveryPremiumArray
        portName
        salinity
        shipSectors
        shipSectorIDArray {
          id
          shipSectorName
        }
        waitingIdleArray
        zone {
          id
          zoneName
        }
      }
    }
  }
`

export const GET_POSITIONDATE = gql`
  query getPositionDate($input: GetPositionDateInput!) {
    getPositionDate(input: $input) {
      positionDate {
        id
        positionId
        positionDateName
      }
    }
  }
`

export const GET_POSITIONDATES = gql`
  query getPositionDates {
    getPositionDates {
      positionDates {
        id
        positionId
        positionDateName
      }
    }
  }
`

export const GET_ROUTE = gql`
query GetRoute($input: GetRouteInput!){
  getRoute(input: $input){
    route {
      id
      error
      codeNameLong
      clients {
        id
        name
      }
      routeName
      ballastBonus
      commodity {
        id
        commodityName
        cargo
      }
      commission
      constants
      spotTCSpread {
          id
          spotTCSpreadName
      }
      apsDeliveryPort {
        id
        portName
      }
      deliveryPort {
        id
        portName
      }
      deliveryPortBunker
      deliveryPortIdleWorking
      description
      # dischargePortArray {
      #   id
      #   portName
      # }
      dischargePortArrayBunker
      dischargePortArrayIdleWorkingArray
      dischargeTermsArray
      dischargeTimeCountingArray
      extraInsurance
      forwardCurve {
        id
        forwardCurveName
      }
      miscCost
      linkedRouteArrays {
        id
        routeName
      }
      # loadPortArray {
      #   id
      #   portName
      # }
      loadPortArrayBunkerArray
      loadPortArrayIdleWorking
      loadTermsArray
      loadTimeCountingArray
      redeliveryPort {
        id
        portName
      }
      redeliveryPortBunker
      redeliveryPortIdleWorking
      stem
      summary
      tolerance
      vessel {
        id
        vesselName
        dwt
        yearBuilt
      }
      # viaBallastPortIDArray {
      #   id
      #   portName
      # }
      viaBallastPortBunkerArray
      viaBallastPortIdleWorking
      # viaLadenPortIDArray {
      #   id
      #   portName
      # }
      viaLadenPortBunkerArray
      viaLadenPortIdleWorking
      viaRedeliveryPort {
        id
        portName
      }
      viaRedeliveryPortBunker
      viaRedeliveryPortIdleWorking
      dischargePorts
      loadPorts
      viaBallastPorts
      viaLadenPorts
      deliveryPortWaitingIdle
      dischargePortWaitingIdle
      loadPortWaitingIdle
      redeliveryPortWaitingIdle
      viaBallastPortWaitingIdle
      viaLadenPortWaitingIdle
      viaRedeliveryPortWaitingIdle
    }
  }
}
`

export const GET_ROUTE_WITH_NET_DAILYHIRE = gql`
query GetRouteWithNetDailyHire($input: GetRouteWithNetDailyHireInput!){
  getRouteWithNetDailyHire(input: $input){
    route {
      id
      error
      codeNameLong
      clients {
        id
        name
      }
      routeName
      ballastBonus
      commodity {
        id
        commodityName
        cargo
      }
      commission
      constants
      spotTCSpread {
          id
          spotTCSpreadName
          spreadTCValue
      }
      apsDeliveryPort {
        id
        portName
      }
      deliveryPort {
        id
        portName
      }
      deliveryPortBunker
      deliveryPortIdleWorking
      description
      dischargePortArrayBunker
      dischargePortArrayIdleWorkingArray
      dischargeTermsArray
      dischargeTimeCountingArray
      extraInsurance
      forwardCurve {
        id
        forwardCurveName
        spotTCSpreads {
          id
          spotTCSpreadName
        }
      }
      miscCost
      linkedRouteArrays {
        id
        routeName
      }
      loadPortArrayBunkerArray
      loadPortArrayIdleWorking
      loadTermsArray
      loadTimeCountingArray
      redeliveryPort {
        id
        portName
      }
      redeliveryPortBunker
      redeliveryPortIdleWorking
      stem
      summary
      tolerance
      vessel {
        id
        vesselName
        dwt
        yearBuilt
      }
      viaBallastPortBunkerArray
      viaBallastPortIdleWorking
      viaLadenPortBunkerArray
      viaLadenPortIdleWorking
      viaRedeliveryPort {
        id
        portName
      }
      viaRedeliveryPortBunker
      viaRedeliveryPortIdleWorking
      dischargePorts
      loadPorts
      viaBallastPorts
      viaLadenPorts
      showViaLadenPort
      deliveryPortWaitingIdle
      dischargePortWaitingIdle
      loadPortWaitingIdle
      redeliveryPortWaitingIdle
      viaBallastPortWaitingIdle
      viaLadenPortWaitingIdle
      viaRedeliveryPortWaitingIdle
      netDailyHire
    }
  }
}
`

export const GET_ROUTES = gql`
query getRoutes {
  getRoutes {
    routes {
      id
      codeNameLong
      clients {
        id
        name
      }
      routeName
      ballastBonus
      error
      commodity {
        id
        commodityName
        cargo
      }
      commission
      constants
      spotTCSpread {
          id
          spotTCSpreadName
      }
      deliveryPort {
        id
        portName
        distanceOnly
      }
      deliveryPortBunker
      deliveryPortIdleWorking
      description
      # dischargePortArray {
      #   id
      #   portName
      #   country {
      #     name
      #   }
      # }
      dischargePortArrayBunker
      dischargePortArrayIdleWorkingArray
      dischargeTermsArray
      dischargeTimeCountingArray
      extraInsurance
      forwardCurve {
        id
        forwardCurveName
      }
      # routeResults {
      #   routeResults
      # }
      miscCost
      linkedRouteArrays {
        id
        routeName
      }
      # loadPortArray {
      #   id
      #   portName
      #   country{
      #     name
      #   }
      # }
      loadPortArrayBunkerArray
      loadPortArrayIdleWorking
      loadTermsArray
      loadTimeCountingArray
      redeliveryPort {
        id
        portName
        distanceOnly
      }
      redeliveryPortBunker
      redeliveryPortIdleWorking
      stem
      summary
      tolerance
      vessel {
        id
        vesselName
        dwt
        yearBuilt
      }
      # viaBallastPortIDArray {
      #   id
      #   portName
      # }
      viaBallastPortBunkerArray
      viaBallastPortIdleWorking
      # viaLadenPortIDArray {
      #   id
      #   portName
      # }
      viaLadenPortBunkerArray
      viaLadenPortIdleWorking
      viaRedeliveryPort {
        id
        portName
        distanceOnly
      }
      viaRedeliveryPortBunker
      viaRedeliveryPortIdleWorking
      dischargePorts
      loadPorts
      viaBallastPorts
      viaLadenPorts
    }
    ports {
      id
      portName
      berth
      country {
        name
      }
      distanceOnly
    }
    shipSectors {
      id
      dwtMin
      dwtMax
      shipSectorName
    }
    timeCountings {
      id
      timeCountingName
    }
  }
}
`

export const GET_ROUTES_FROM_CLIENT = gql`
  query getRoutesFromClient($input: GetRoutesFromClientInput!) {
    getRoutesFromClient(input: $input) {
      timeCountings {
        id
        abbrev
      }
      shipSectors {
        id
        shipSectorName
        dwtMin
        dwtMax
      }
      positionDates {
        id
        positionDateName
      }
      ports {
        id
        country {
          id
          flag
          name
        }
        portName
      }
      routes {
        id
        routeName
        stem
        tolerance
        error
        dischargeTimeCountingArray
        dischargeTermsArray
        loadTermsArray
        loadTimeCountingArray
        routeResults {
          routeResults
        }
        dischargePorts
        loadPorts
        vessel {
          id
          vesselName
          dwt
        }
      }
    }
  }
`

export const GET_ROUTES_FROM_CLIENT_CSV = gql`
  query getRoutesFromClient($input: GetRoutesFromClientInput!) {
    getRoutesFromClient(input: $input) {
      csv
    }
  }
`

export const GET_ROUTES_FROM_CLIENT_HORIZONTAL = gql`
  query getRoutesFromClient($input: GetRoutesFromClientInput!) {
    getRoutesFromClient(input: $input) {
      clientRoutes
      hasNextPage
      quarterArray
    }
  }
`

export const GET_ROUTES_FROM_CLIENT_PDF = gql`
  query getRoutesFromClient($input: GetRoutesFromClientInput!) {
    getRoutesFromClient(input: $input) {
      report
      quarterArray
      totalColumns
    }
  }
`

export const GET_ROUTES_FROM_LOGGED_CLIENT = gql`
  query GetRoutesFromLoggedClient($input: GetRoutesFromLoggedClientInput!) {
    getRoutesFromLoggedClient(input: $input) {
      clientRoutes
      hasNextPage
      quarterArray
    }
  }
`

export const GET_ROUTES_FROM_LOGGED_CLIENT_CSV = gql`
  query GetRoutesFromLoggedClient($input: GetRoutesFromLoggedClientInput!) {
    getRoutesFromLoggedClient(input: $input) {
      csv
    }
  }
`

export const GET_ROUTES_FROM_LOGGED_CLIENT_PDF = gql`
  query GetRoutesFromLoggedClient($input: GetRoutesFromLoggedClientInput!) {
    getRoutesFromLoggedClient(input: $input) {
      report
      quarterArray
      totalColumns
    }
  }
`

export const GET_ROUTES_PER_COMMODITY = gql`
  query getRoutesPerCommodity($input: GetRoutesPerCommodityInput!) {
    getRoutesPerCommodity(input: $input) {
      clientRoutes
      hasNextPage
      quarterArray
    }
  }
`

export const GET_RESULTS_BY_DAY = gql`
  query GetResultsByDay($input: GetResultsByDayInput!) {
    getResultsByDay(input: $input)
  }
`

export const GET_SIMPLE_ROUTES = gql`
query getRoutes {
  getRoutes {
    routes {
      id
      routeName
      codeNameLong
      commodity {
        commodityName
      }
      loadPortArray {
        portName
      }
      dischargePortArray {
        portName
      }
      error
    }
  }
}
`

export const GET_CALCULATION_ROUTE_INFO = gql`
query GetRoute($input: GetRouteInput!){
  getRoute(input: $input){
    route {
      id
      routeName
      stem
      error
      tolerance
      commission
      constants
      miscCost
      forwardCurve {
        forwardCommodity {
          id
          commodityName
          stowFactor
          forwardArray
        }
      }
      commodity {
        id
        cargo
        commodityName
        stowFactor
        forwardArray
      }
      deliveryPort {
        portName
        zone {
          weatherMarginArray
        }
      }
      deliveryPortBunker
      deliveryPortIdleWorking
      # loadPortArray {
      #   portName
      #   zone {
      #     weatherMarginArray
      #   }
      # }
      loadPortArrayBunkerArray
      loadPortArrayIdleWorking
      loadTermsArray
      loadTimeCountingArray
      # dischargePortArray {
      #   portName
      #   zone {
      #     weatherMarginArray
      #   }
      # }
      dischargePortArrayBunker
      dischargePortArrayIdleWorkingArray
      dischargeTermsArray
      dischargeTimeCountingArray
      redeliveryPort {
        portName
        zone {
          weatherMarginArray
        }
      }
      redeliveryPortBunker
      redeliveryPortIdleWorking
      routeResults {
        id
        routeResults
      }
      # viaBallastPortIDArray {
      #   portName
      #   zone {
      #     weatherMarginArray
      #   }
      # }
      viaBallastPortBunkerArray
      viaBallastPortIdleWorking
      # viaLadenPortIDArray {
      #   portName
      #   zone {
      #     weatherMarginArray
      #   }
      # }
      viaLadenPortBunkerArray
      viaLadenPortIdleWorking
      viaRedeliveryPort {
        portName
        zone {
          weatherMarginArray
        }
      }
      viaRedeliveryPortBunker
      viaRedeliveryPortIdleWorking
      dischargePorts
      loadPorts
      viaBallastPorts
      viaLadenPorts
      vessel {
        id
        ballastSpeedArray
        ballastIFOConsumption
        ballastMGOConsumption
        inPortIFOIdle
        inPortIFOWorking
        inPortMGOIdle
        inPortMGOWorking
        ladenSpeedArray
        ladenIFOConsumption
        ladenMGOConsumption
        vesselName
        cubicFT
        dwt
        draft
        tpc
      }
    }
  }
}
`
export const GET_SHIPSECTOR = gql`
  query getShipSector($input: GetShipSectorInput!) {
    getShipSector(input: $input) {
      shipSector {
        id
        shipSectorName
        dwtMin
        dwtMax
        sw
        bw
        fw
        tf
      }
    }
  }
`

export const GET_SHIPSECTORS = gql`
  query getShipSectors {
    getShipSectors {
      shipSectors {
        id
        shipSectorName
        dwtMin
        dwtMax
        sw
        bw
        fw
        tf
      }
    }
  }
`

export const GET_SPOTINDEX = gql`
  query GetSpotIndex($input: GetSpotIndexInput!){
    getSpotIndex(input: $input){
      spotIndex {
        id
        indexAdjustmentAbsolute
        indexAdjustmentPercent
        indexName
        indexValue
        shipSector {
          id
          shipSectorName
        }
      }
    }
  }
`

export const GET_SPOTINDEXES = gql`
  query getSpotIndexes {
    getSpotIndexes {
      spotIndexes {
        id
        indexAdjustmentAbsolute
        indexAdjustmentPercent
        indexName
        indexValue
        shipSector {
          id
          shipSectorName
        }
      }
    }
  }
`

export const GET_SPOTTCSPREAD = gql`
  query getSpotTCSpread($input: GetSpotTCSpreadInput!) {
    getSpotTCSpread(input: $input) {
      spotTCSpread {
        id
        spotTCSpreadName
        spreadTCValue
        type
        shipSector {
          id
          shipSectorName
        }
        deliveryZone {
          id
        }
        redeliveryZone {
          id
        }
      }
    }
  }
`

export const GET_SPOTTCSPREADS = gql`
  query getSpotTCSpreads {
    getSpotTCSpreads {
      spotTCSpreads
    }
  }
`

export const GET_TIMECOUNTING = gql`
  query GetTimeCounting($input: GetTimeCountingInput!){
    getTimeCounting(input: $input){
      timeCounting {
        id
        abbrev
        multiplier
        timeCountingName
      }
    }
  }
`

export const GET_TIMECOUNTINGS = gql`
  query getTimeCountings {
    getTimeCountings {
      timeCountings {
        id
        abbrev
        multiplier
        timeCountingName
      }
    }
  }
`

export const GET_USER = gql`
  query getUser($input: GetUserInput!) {
    getUser(input: $input) {
      user {
        id
        name
        email
        role {
          key
        }
        visibleRoutes {
          id
          routeName
          active
        }
        routeOrder
      }
    }
  }
`

export const GET_USERS = gql`
  query getUsers($input: GetUsersInput) {
    getUsers(input: $input) {
      users {
        id
        name
        email
        role {
          key
        }
        userSetting {
          id
          adcom
        }
      }
    }
  }
`

export const GET_CLIENTS = gql`
  query getUsers($input: GetUsersInput) {
    getUsers(input: $input) {
      users {
        id
        name
        routeOrder
        userSetting {
          id
          clientResults
          adcom
        }
      }
    }
  }
`

export const GET_LOGGED_CLIENT = gql`
  query GetLoggedClient {
    getLoggedClient {
      user {
        id
        name
        userSetting {
          adcom
        }
        routeOrder
      }
      commodities {
        id
        commodityName
      }
    }
  }
`

export const GET_VESSEL = gql`
  query GetVessel($input: GetVesselInput!){
    getVessel(input: $input){
      vessel {
        id
        ballastSpeedArray
        ballastIFOConsumption
        ballastMGOConsumption
        cubicFT
        cubicMT
        dwt
        draft
        IMONumber
        inPortIFOIdle
        inPortIFOWorking
        inPortMGOIdle
        inPortMGOWorking
        ladenSpeedArray
        ladenIFOConsumption
        ladenMGOConsumption
        vesselName
        yard
        yearBuilt
        tpc
      }
    }
  }
`

export const GET_VESSELS = gql`
  query getVessels {
    getVessels {
      vessels {
        id
        ballastSpeedArray
        ballastIFOConsumption
        ballastMGOConsumption
        cubicFT
        dwt
        draft
        IMONumber
        inPortIFOIdle
        inPortIFOWorking
        inPortMGOIdle
        inPortMGOWorking
        ladenSpeedArray
        ladenIFOConsumption
        ladenMGOConsumption
        vesselName
        yard
        yearBuilt
        tpc
      }
    }
  }
`

export const GET_SIMPLE_VESSELS = gql`
  query getVessels {
    getVessels {
      vessels {
        id
        ballastSpeedArray
        ballastIFOConsumption
        ballastMGOConsumption
        inPortIFOIdle
        inPortIFOWorking
        inPortMGOIdle
        inPortMGOWorking
        ladenSpeedArray
        ladenIFOConsumption
        ladenMGOConsumption
        vesselName
        cubicFT
        dwt
        draft
        tpc
        yearBuilt
      }
    }
  }
`

export const GET_SYSTEM_SETTINGS = gql`
  query GetSystemSettings {
    getSystemSetting {
      systemSetting {
        id
        offerAgeYoung
        offerAgeMedium
        offerAgeOld
        heatMapLow
        heatMapMedium
        heatMapHigh
        heatMapDays
      }
      userTokens {
        id
        name
        token
      }
    }
  }
`

export const GET_ZONE = gql`
  query GetZone($input: GetZoneInput!){
    getZone(input: $input){
      zone {
        id
        defaultBunkerPort {
          id
          portName
        }
        zoneName
        zoneDescription
        weatherMarginArray
      }
    }
  }
`

export const GET_ZONES = gql`
  query getZones {
    getZones {
      zones {
        id
        defaultBunkerPort {
          id
          portName
        }
        weatherMarginArray
        zoneName
        zoneDescription
      }
    }
  }
`

export const GET_UPDATING_ROUTES = gql`
  query getUpdatingRoutes {
    getUpdatingRoutes {
      updatingRoutes {
        id
        count
        length
        finished
      }
    }
  }
`

// Suscriptions Queries

export const GET_TC_INPUT = gql`
  query getTCInput {
    getTCInput {
      tcInput{
        id
        tcInput
      }
    }
  }
`
export const GET_TC_INPUT_INFORMATION = gql`
  query getTCInputInforamtion($input: GetTCInputInforamtionInput!){
    getTCInputInforamtion(input: $input)
  }
`

export const GET_VALIDATION = gql`
  query getValidation {
    getValidation {
      validation {
        id
        validation
        updatedAt
      }
      quarterArray
    }
  }
`

export const GET_VALIDATION_DATA = gql`
  query GetValidationData($input: GetValidationDataInput!) {
    getValidationData(input: $input) {
      commodityDates
      portDate
      fuelPrice
      validationCurves
      positionDates
      systemSetting
      quarterArray
    }
  }
`
export const GET_VALIDATION_CURVE = gql`
  query GetValidationCurve($input: GetValidationCurveInput!) {
    getValidationCurve(input: $input) {
      validationCurve {
        id
        channel
        curveName
        base
        calcInfo
        curveAdjustmentAbsolute
        curveAdjustmentPercent
        latestChange
        locked
        rate
        result
        apsDh
        apsBB
        apsResult
        spotTCSpreads {
          id
          spotTCSpreadName
          spreadTCValue
        }
        spotIndex {
          id
          indexName
          indexValue
        }
        coreRoute {
          id
          routeName
          spotTCSpread {
            spreadTCValue
          }
        }
        basedOnForwardCurve{
          id
          forwardCurveName
        }
        tce
        update
      }
    }
  }
`

export const GET_VALIDATION_INFORMATION = gql`
  query GetValidationInformation($input: GetValidationInformationInput!) {
    getValidationInformation(input: $input)
  }
`

export const GET_VALIDATION_UPDATED_RESULTS = gql`
  query getUpdatedResults($input: GetUpdatedResultsInput!) {
    getUpdatedResults(input: $input)
  }
`
