// React Library
import React, { useState, useRef, useEffect } from 'react'

// Helmet
import Helmet from 'react-helmet'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Content
import { show as content } from './ZoneContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_ZONES } from '../Queries/Queries'

// Mutation Related
import { DELETE_ZONE } from '../Mutations/Mutations'

// Utils
import AddIconButton from '../Utils/AddIconButton'
import RootAgGrid from '../Utils/RootAgGrid'
import SearchTextInput from '../Utils/SearchTextInput'
import RootGrid from '../Utils/RootGrid'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Query Component
const ShowZones = props => {
  // Months
  const months = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']

  return (
    <>
      <Helmet>
        <title>Zones</title>
      </Helmet>
      <QueryHelper
        query={GET_ZONES}
      >
        {({ data }) => {
          const { getZones: { zones } } = data

          // Some Formatting...
          const zonesInformation = zones.map(zone => {
            const weatherMarginArray = zone.weatherMarginArray.map((weather, index) => {
              if (index - 1 === zone.weatherMarginArray.length) {
                return `${months[index]}: ${weather}`
              }
              return `${months[index]}: ${weather}, `
            })

            return {
              ...zone,
              defaultBunkerPort: zone.defaultBunkerPort ? zone.defaultBunkerPort.portName : '-',
              weatherMarginArray
            }
          })

          // Render
          return (
            <ShowZonesTable
              zones={zonesInformation}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowZonesTable = props => {
  const { zones = [] } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchZone, setSearchZone] = useState(zones)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['zoneName'], {
    idFieldOrFunction: 'id'
  })

  // The Array To Be Filtered (All Zones In the DB)
  ts.addAll(zones)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchZone(zones)
      } else {
        const foundZones = ts.get(search, TrieSearch.UNION_REDUCER)
        setSearchZone(foundZones)
      }
    }
  }, [search, zones, ts])

  // Column Structure
  const columnDefs = [
    { field: 'defaultBunkerPort', headerName: 'Default Port', cellRenderer: 'link' },
    { field: 'zoneName', headerName: 'Zone' },
    { field: 'weatherMarginArray', headerName: 'Weather Margin', width: 350 },
    { field: 'zoneDescription', headerName: 'Description' }
  ]

  // Link Of The Update Zone Page
  const updateLink = 'update-zone'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>

      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Zones Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={searchZone}
        mutation={DELETE_ZONE}
        objectNameKey1='zoneName'
        type='Zone'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowZones)
