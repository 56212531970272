import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import CalendarIcon from '@material-ui/icons/CalendarToday'
import ClearIcon from '@material-ui/icons/Clear'
import TextField from '@material-ui/core/TextField'
// React date picker
import DatePicker from 'react-date-picker'

const Calendar = () => {
  return(
      <CalendarIcon fontSize='inherit'/>
  )
}

const Clear = () => {
  return(
    <ClearIcon fontSize='inherit'/>
  )
}

const RootDatePicker = props => {
  const {
    classes,
    field,
    minDate,
    name,
    onChange,
    readOnly,
    setError,
    validation,
    value,
    ...rest
  } = props
  const dateValue = value && new Date(value)
  return (
    <TextField
      fullWidth
      name={field}
      InputProps={{inputComponent:DatePicker, inputProps: {
        disabled: readOnly,
        onChange:event => onChange({target: {name, value: event}}, validation),
        calendarIcon: readOnly ? null : <Calendar />,
        clearIcon: readOnly || !dateValue ? null : <Clear/>,
        format: 'd-M-yyyy',
        showLeadingZeros: false,
        locale: 'es',
        ...(minDate ? {minDate: new Date(minDate)} : ''),
      }}}
      value={dateValue}
      // variant='outlined'
      size='small'
      InputLabelProps={{
        shrink: true,
      }}
      {...rest}
    />
  )
}

RootDatePicker.propTypes = {
  classes: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setError: PropTypes.func.isRequired,
  validation: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default RootDatePicker