const validatejs = require('validate.js')

export const abbreviation = {
  length: {
    maximum: 50,
    message: '^*The name must be 3000 character or less'
  }
}

export const abbreviationRequired = {
  ...abbreviation,
  presence: {
    allowEmpty: false,
    message: '^*Abbreviation is required.'
  }
}

export const account = {

}

export const yearRequired = {

}

export const accountRequired = {
  ...account,
  presence: {
    allowEmpty: false,
    message: '^*Account is required.'
  }
}

export const adcom = {}
export const adcomRequired = {
  ...adcom,
  presence: {
    allowEmpty: false,
    message: '^* Adcom is required'
  }
}

export const adcome = {

}

export const adcomePositive = {
  ...adcome,
  numericality: {
    greaterThanOrEqualTo: 0,
    message: '^*Adcom should be a positive number'
  }
}

export const ballastBonus = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Ballast Bonus must be a valid number'
  }
}

export const ballastBonusRequired = {
  ...ballastBonus,
  presence: {
    allowEmpty: false,
    message: '^*Ballast bonus is required.'
  }
}

export const ballastIFOConsumption = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    greaterThanOrEqualTo: 0,
    message: '^*Ballast IFO Consumption must be a valid number'
  }
}

export const ballastIFOConsumptionRequired = {
  ...ballastIFOConsumption,
  presence: {
    allowEmpty: false,
    message: '^*Ballast IFO Consumption is required.'
  }
}

export const ballastMGOConsumption = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    greaterThanOrEqualTo: 0,
    message: '^*Ballast MGO Consumption must be a valid number'
  }
}

export const ballastMGOConsumptionRequired = {
  ...ballastMGOConsumption,
  presence: {
    allowEmpty: false,
    message: '^*Ballast MGO Consumption is required.'
  }
}

export const ballastSpeed = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    greaterThanOrEqualTo: 0,
    message: '^*Ballast Speed must be a valid number'
  }
}

export const ballastSpeedRequired = {
  ...ballastSpeed,
  presence: {
    allowEmpty: false,
    message: '^*Ballast Speed is required.'
  }
}
export const berth = {
  length: {
    maximum: 255,
    message: '^*The berth must be 255 character or less'
  }
}

export const berthRequired = {
  ...berth,
  presence: {
    allowEmpty: false,
    message: '^*Berth is required.'
  }
}

export const bid = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    greaterThanOrEqualTo: 0,
    message: '^*Bid must be a valid number'
  }
}

export const bidRequired = {
  ...bid,
  presence: {
    allowEmpty: false,
    message: '^*Bid is required.'
  }
}

export const bunkeringCost = {
}

export const bunkeringCostRequired = {
  ...bunkeringCost,
  presence: {
    allowEmpty: false,
    message: '^*Bunkering Cost is required.'
  }
}

export const bunkeringTime = {
}

export const bunkeringTimeRequired = {
  ...bunkeringTime,
  presence: {
    allowEmpty: false,
    message: '^*Bunkering Time required.'
  }
}

export const bw = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*fw must be a valid number'
  }
}

export const bwRequired = {
  ...bw,
  presence: {
    allowEmpty: false,
    message: '^*bw is required'
  }
}

export const client = {}

export const clientRequired = {
  presence: {
    allowEmpty: false,
    message: '^*Client is required'
  }
}

export const commission = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Commission must be a valid number'
  }
}

export const commissionRequired = {
  ...commission,
  presence: {
    allowEmpty: false,
    message: '^*Commission is required'
  }
}

export const commodity = {
}

export const commodityRequired = {
  ...commodity,
  presence: {
    allowEmpty: false,
    message: '^*Commodity is required'
  }
}

export const constants = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Constant must be a valid number'
  }
}

export const constantsRequired = {
  ...constants,
  presence: {
    allowEmpty: false,
    message: '^*Constant is required.'
  }
}

export const cost = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Cost must be a valid number'
  }
}

export const costRequired = {
  ...cost,
  presence: {
    allowEmpty: false,
    message: '^*Cost is required.'
  }
}

export const country = {
}

export const countryRequired = {
  ...country,
  presence: {
    allowEmpty: false,
    message: '^*Country is required.'
  }
}

export const cubicFt = {
}

export const cubicFtRequired = {
  ...cubicFt,
  presence: {
    allowEmpty: false,
    message: '^*Cubic Feets are required.'
  }
}

export const cubicMt = {
}

export const cubicMtRequired = {
  ...cubicMt,
  presence: {
    allowEmpty: false,
    message: '^*Cubic mts are required.'
  }
}

export const cve = {
}

export const cveRequired = {
  ...cve,
  presence: {
    allowEmpty: false,
    message: '^*CVE is required.'
  }
}

export const tf = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*fw must be a valid number'
  }
}

export const tfRequired = {
  ...tf,
  presence: {
    allowEmpty: false,
    message: '^* tf is required'
  }
}

export const fullDist = {
}

export const fullDistRequired = {
  ...fullDist,
  presence: {
    allowEmpty: false,
    message: '^*Full Distance is required.'
  }
}

export const fw = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*fw must be a valid number'
  }
}

export const fwRequired = {
  ...fw,
  presence: {
    allowEmpty: false,
    message: '^*fw is required'
  }
}

export const draftArray = {
}

export const draftArrayRequired = {
  ...draftArray,
  presence: {
    allowEmpty: false,
    message: '^*At least one draft is required.'
  }
}

export const deadWeight = {
}

export const deadWeightRequired = {
  ...deadWeight,
  presence: {
    allowEmpty: false,
    message: '^*Dead weight is required.'
  }
}

export const description = {
  length: {
    maximum: 3000,
    message: '^*The name must be 3000 character or less'
  }
}

export const descriptionRequired = {
  ...description,
  presence: {
    allowEmpty: false,
    message: '^*Description is required.'
  }
}

export const deliveryPort = {}
export const deliveryPortRequired = {
  ...deliveryPort,
  presence: {
    allowEmpty: true,
    message: '^*Delivery Port is required.'
  }
}

export const deliveryPortBunker = {}
export const deliveryPortBunkerRequired = {
  ...deliveryPortBunker,
  presence: {
    allowEmpty: true,
    message: '^*Delivery Port Bunker is required.'
  }
}
export const dischargePortArray = {}
export const dischargePortArrayRequired = {
  ...dischargePortArray,
  presence: {
    allowEmpty: true,
    message: '^*At least one Port is required.'
  }
}

export const dischargePortArrayBunker = {}
export const dischargePortArrayBunkerRequired = {
  ...dischargePortArrayBunker,
  presence: {
    allowEmpty: true,
    message: '^*Discharge Port Bunker is required.'
  }
}
export const dischargeTermsArray = {}
export const dischargeTermsArrayRequired = {
  ...dischargeTermsArray,
  presence: {
    allowEmpty: true,
    message: '^*At least one Port is required.'
  }
}

export const dischargeTimeCountingArray = {}
export const dischargeTimeCountingArrayRequired = {
  ...dischargeTimeCountingArray,
  presence: {
    allowEmpty: true,
    message: '^*At least one Time counting is required.'
  }
}

export const draft = {
}

export const draftRequired = {
  ...draft,
  presence: {
    allowEmpty: false,
    message: '^*Draft is required.'
  }
}

export const dwtMin = {
}

export const dwtMinRequired = {
  ...dwtMin,
  presence: {
    allowEmpty: false,
    message: '^*Min dead weight is required.'
  }
}

export const dwtMax = {
}

export const dwtMaxRequired = {
  ...dwtMax,
  presence: {
    allowEmpty: false,
    message: '^*Max dead weight is required.'
  }
}

export const ecaDist = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*A valid number is required for Eca Distance'
  }
}

export const ecaDistRequired = {
  ...ecaDist,
  presence: {
    allowEmpty: false,
    message: '^*Eca Distance is required'
  }
}

export const email = {
  email: {
    message: '^*Enter a valid email'
  }
}

export const emailRequired = {
  ...email,
  presence: {
    allowEmpty: false,
    message: '^*Email is required.'
  }
}

export const extraInsurance = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Extra insurance must be a valid number'
  }
}
export const extraInsuranceRequired = {
  ...extraInsurance,
  presence: {
    allowEmpty: true,
    message: '^*Extra insurance is required.'
  }
}

export const fo05 = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*A valid number is required for FO05'
  }
}

export const fo05Required = {
  ...fo05,
  presence: {
    allowEmpty: false,
    message: '^*FO05 is required'
  }
}

export const forwardCurve = {
}

export const forwardCurveRequired = {
  ...forwardCurve,
  presence: {
    allowEmpty: false,
    message: '^*Forward Curve is required.'
  }
}

export const forwardArray = {
}

export const forwardArrayRequired = {
  ...forwardArray,
  presence: {
    allowEmpty: false,
    message: '^*Forward Array is required'
  }
}

export const forwardCommodityRequired = {
  presence: {
    allowEmpty: false,
    message: '^*Forward Commodity is required'
  }
}

export const basedOnForwardCurveRequired = {
  presence: {
    allowEmpty: false,
    message: '^*Curve is required'
  }
}

export const forwardCommodityFOIDRequired = {
  presence: {
    allowEmpty: false,
    message: '^*ForwardCommodityFOID is required'
  }
}

export const forwardCommodityMGOIDRequired = {
  presence: {
    allowEmpty: false,
    message: '^*ForwardCommodityMGOID is required'
  }
}

export const forwardPremiumAbsolute = {
}

export const forwardPremiumAbsoluteRequired = {
  ...forwardPremiumAbsolute,
  presence: {
    allowEmpty: false,
    message: '^*Forward Premium Absolute is required'
  }
}

export const forwardPremiumPercent = {
}

export const forwardPremiumPercentRequired = {
  ...forwardPremiumPercent,
  presence: {
    allowEmpty: false,
    message: '^*Forward Premium Percent is required'
  }
}

export const HSFO380 = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*A valid number is required for HSFO380'
  }
}

export const HSFO380Required = {
  ...HSFO380,
  presence: {
    allowEmpty: false,
    message: '^*HSFO380 is required'
  }
}

// export const IMONumber = {}
// export const IMONUmberRequired = {
//   ...IMONumber,
//   presence: {
//     allowEmpty: false,
//     message: '^*IMONumber is required'
//   }
// }

export const indexAdjustmentAbsolute = {}

export const indexAdjustmentAbsoluteRequired = {
  ...indexAdjustmentAbsolute,
  presence: {
    allowEmpty: false,
    message: '^*Index Adjustment Absolute is required.'
  }
}

export const indexAdjustmentPercent = {}

export const indexAdjustmentPercentRequired = {
  ...indexAdjustmentAbsolute,
  presence: {
    allowEmpty: false,
    message: '^*Index Adjustment Percent is required.'
  }
}

export const indexValue = {}

export const indexValueRequired = {
  ...indexValue,
  presence: {
    allowEmpty: false,
    message: '^*Index Value is required.'
  }
}

export const inPortIFOIdle = {
}

export const inPortIFOIdleRequired = {
  ...inPortIFOIdle,
  presence: {
    allowEmpty: false,
    message: '^*In Port IFO Idle is required.'
  }
}

export const inPortIFOWorking = {
}

export const inPortIFOWorkingRequired = {
  ...inPortIFOWorking,
  presence: {
    allowEmpty: false,
    message: '^*In port IFO working is required.'
  }
}

export const inPortMGOIdle = {
}

export const inPortMGOIdleRequired = {
  ...inPortMGOIdle,
  presence: {
    allowEmpty: false,
    message: '^*In port MGO Idle is required.'
  }
}

export const inPortMGOWorking = {
}

export const inPortMGOWorkingRequired = {
  ...inPortMGOWorking,
  presence: {
    allowEmpty: false,
    message: '^*In port MGO Working is required.'
  }
}

export const iwlInsurance = {}
export const iwlInsuranceRequired = {
  ...iwlInsurance,
  presence: {
    allowEmpty: true,
    message: '^*iwl Insurance is required.'
  }
}

export const ladenSpeed = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    greaterThanOrEqualTo: 0,
    message: '^*Laden Speed must be a valid number'
  }
}

export const ladenSpeedRequired = {
  ...ladenSpeed,
  presence: {
    allowEmpty: false,
    message: '^*Laden Speed is required.'
  }
}

export const ladenIFOConsumption = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    greaterThanOrEqualTo: 0,
    message: '^*Laden IFO Consumption must be a valid number'
  }
}

export const ladenIFOConsumptionRequired = {
  ...ladenIFOConsumption,
  presence: {
    allowEmpty: false,
    message: '^*Laden IFO Consumption is required.'
  }
}

export const ladenMGOConsumption = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    greaterThanOrEqualTo: 0,
    message: '^*Laden MGO Consumption must be a valid number'
  }
}

export const ladenMGOConsumptionRequired = {
  ...ladenMGOConsumption,
  presence: {
    allowEmpty: false,
    message: '^*Laden MGO Consumption is required.'
  }
}

export const linkedRouteArrays = {}
export const linkedRouteArraysRequired = {
  ...linkedRouteArrays,
  presence: {
    allowEmpty: true,
    message: '^*Linked Route Arrays is required.'
  }
}

export const loadPortArray = {}
export const loadPortArrayRequired = {
  ...loadPortArray,
  presence: {
    allowEmpty: true,
    message: '^*Load Port Array is required.'
  }
}
export const loadPortArrayBunkerArray = {}
export const loadPortArrayBunkerArrayRequired = {
  ...loadPortArrayBunkerArray,
  presence: {
    allowEmpty: true,
    message: '^*Load Port Array BunkerArray is required.'
  }
}
export const loadTermsArray = {}
export const loadTermsArrayRequired = {
  ...loadTermsArray,
  presence: {
    allowEmpty: true,
    message: '^*Load Terms Array is required.'
  }
}

export const loadTimeCountingArray = {}
export const loadTimeCountingArrayRequired = {
  ...loadTimeCountingArray,
  presence: {
    allowEmpty: true,
    message: '^*Load Time Counting Array is required.'
  }
}

export const lsIFO = {
}

export const lsIFORequired = {
  ...lsIFO,
  presence: {
    allowEmpty: false,
    message: '^*LS IFO is required.'
  }
}

export const MDO = {
}

export const MDORequired = {
  ...MDO,
  presence: {
    allowEmpty: false,
    message: '^*MDO is required.'
  }
}

export const MGO01 = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*A valid number is required for MGO01'
  }
}

export const MGO01Required = {
  ...MGO01,
  presence: {
    allowEmpty: false,
    message: '^*MGO01 is required'
  }
}

export const misc = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Misc must be a valid number'
  }
}
export const miscRequired = {
  ...misc,
  presence: {
    allowEmpty: true,
    message: '^*Misc is required.'
  }
}

export const month = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*A valid number is required for the period'
  }
}

export const monthRequired = {
  ...month,
  presence: {
    allowEmpty: false,
    message: '^*Month is required'
  }
}

export const monthPositionDate = {

}

export const monthPositionDateRequired = {
  ...monthPositionDate,
  presence: {
    allowEmpty: null,
    message: '^*Month is required'
  }
}

export const multiplier = {
}

export const multiplierRequired = {
  ...multiplier,
  presence: {
    allowEmpty: false,
    message: '^*Multiplier is required.'
  }
}

export const name = {
  length: {
    maximum: 51,
    message: '^*The name must be 50 character or less'
  }
}

export const nameRequired = {
  ...name,
  presence: {
    allowEmpty: false,
    message: '^*Name is required.'
  }
}

export const offer = {
}

export const offerRequired = {
  ...offer,
  presence: {
    allowEmpty: false,
    message: '^*Offer is required.'
  },
  numericality: {
    greaterThanOrEqualTo: 0,
    message: '^*Offer must be a positive number'
  }
}

export const offerAgeCorrectValues = {
  // TODO: Finish validation of offerAge values.
}

export const password = {
  length: {
    minimum: 8,
    message: '^*The password must be at least 8 characters long'
  }
}

export const passwordRequired = {
  presence: {
    allowEmpty: false,
    message: '^*Password is required'
  },
  ...password,
}

export const passwordLogin = {
  presence: {
    allowEmpty: false,
    message: '^*Password is required'
  }
}

export const port = {
}

export const portRequired = {
  ...port,
  presence: {
    allowEmpty: false,
    message: '^*Port is required'
  }
}

export const portID1 = {
}

export const portID1Required = {
  ...portID1,
  presence: {
    allowEmpty: false,
    message: '^*Port is required'
  }
}

export const portID2 = {}

export const portID2Required = {
  ...portID2,
  presence: {
    allowEmpty: false,
    message: '^*Port is required'
  }
}

export const positionDate = {}

export const positionDateRequired = {
  ...positionDate,
  presence: {
    allowEmpty: false,
    message: '^*PositionDate is required.'
  }
}

export const positionId = {}
export const positionIdRequired = {
  ...positionId,
  presence: {
    allowEmpty: false,
    message: '^*Position ID is required'
  }
}

export const redeliveryPort = {}
export const redeliveryPortRequired = {
  ...redeliveryPort,
  presence: {
    allowEmpty: true,
    message: '^*Redelivery Port is required.'
  }
}
export const redeliveryPortBunker = {}
export const redeliveryPortBunkerRequired = {
  ...redeliveryPortBunker,
  presence: {
    allowEmpty: true,
    message: '^*Redelivery Port Bunker is required.'
  }
}

export const role = {
  // length: {
  //   maximum: 51,
  //   message: '^*The role must be 50 character or less.'
  // },
}

export const roleRequired = {
  ...role,
  presence: {
    allowEmpty: false,
    message: '^*Role is required.'
  }
}

export const route = {

}

export const routeRequired = {
  ...route,
  presence: {
    allowEmpty: false,
    message: '^*Route is required.'
  }
}

export const salinity = {
}

export const salinityRequired = {
  ...salinity,
  presence: {
    allowEmpty: false,
    message: '^*Salinity is required.'
  }
}

export const spotIndex = {
}

export const spotIndexRequried = {
  ...spotIndex,
  presence: {
    allowEmpty: false,
    message: '^*Spot Index is required'
  }
}

export const spotTCSpreadArray = {}
export const spotTCSpreadArrayRequired = {
  ...spotTCSpreadArray,
  presence: {
    allowEmpty: true,
    message: '^*Spot TC Spread is required.'
  }
}

export const spotTCSpreadName = {
}

export const spotTCSpreadNameRequired = {
  ...spotTCSpreadName,
  presence: {
    allowEmpty: false,
    message: '^*Spot TC Spread Name is required'
  }
}

export const spreadTCValue = {
}

export const spreadTCValueRequired = {
  ...spreadTCValue,
  presence: {
    allowEmpty: false,
    message: '^*Spread TC Value is required'
  }
}

export const shipSector = {
}

export const shipSectorRequired = {
  ...shipSector,
  presence: {
    allowEmpty: false,
    message: '^*Ship Sector is required'
  }
}

export const status = {

}

export const statusRequired = {
  ...status,
  presence: {
    allowEmpty: false,
    message: '^*Status is required'
  }
}

export const stem = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Stem must be a valid number'
  }
}
export const stemRequired = {
  ...stem,
  presence: {
    allowEmpty: true,
    message: '^*Stem is required.'
  }
}

export const stowFactor = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*stowFactor must be a valid number'
  }
}

export const stowFactorRequired = {
  ...stowFactor,
  presence: {
    allowEmpty: false,
    message: '^*Stow Factor is required'
  }
}

export const sw = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*fw must be a valid number'
  }
}

export const swRequired = {
  ...sw,
  presence: {
    allowEmpty: false,
    message: '^sw is required'
  }
}

export const terms = {
}

export const termsRequired = {
  ...terms,
  presence: {
    allowEmpty: false,
    message: '^*Terms are required.'
  }
}

export const timeCounting = {
}

export const timeCountingRequired = {
  ...timeCounting,
  presence: {
    allowEmpty: false,
    message: '^*Time Counting is required.'
  }
}

export const tolerance = {
  numericality: {
    lessThanOrEqualTo: 999999999999999,
    message: '^*Tolerance must be a valid number'
  }
}
export const toleranceRequired = {
  ...tolerance,
  presence: {
    allowEmpty: true,
    message: '^*Tolerance is required.'
  }
}

export const tpc = {
}

export const tpcRequired = {
  ...tpc,
  presence: {
    allowEmpty: false,
    message: '^*TPC is required.'
  }
}

export const user = {
}

export const userRequired = {
  ...user,
  presence: {
    allowEmpty: false,
    message: '^*User is required.'
  }
}

export const owner = {}

export const ownerRequired = {
  ...owner,
  presence: {
    allowEmpty: false,
    message: '^*Owner is required'
  }
}

export const vessel = {}
export const vesselRequired = {
  ...vessel,
  presence: {
    allowEmpty: true,
    message: '^*Vessel is required.'
  }
}

export const viaBallastPortIDArray = {}
export const viaBallastPortIDArrayRequired = {
  ...viaBallastPortIDArray,
  presence: {
    allowEmpty: true,
    message: '^*Via Ballast Port is required.'
  }
}

export const viaBallastPortBunkerArray = {}
export const viaBallastPortBunkerArrayRequired = {
  ...viaBallastPortBunkerArray,
  presence: {
    allowEmpty: true,
    message: '^*Via Ballast Port Bunker is required.'
  }
}

export const viaLadenPortIDArray = {}
export const viaLadenPortIDArrayRequired = {
  ...viaLadenPortIDArray,
  presence: {
    allowEmpty: true,
    message: '^*Via laden Port is required.'
  }
}

export const viaLadenPortBunkerArray = {}
export const viaLadenPortBunkerArrayRequired = {
  ...viaLadenPortBunkerArray,
  presence: {
    allowEmpty: true,
    message: '^*Via laden Port Bunker is required.'
  }
}

export const viaRedeliveryPort = {}
export const viaRedeliveryPortRequired = {
  ...viaRedeliveryPort,
  presence: {
    allowEmpty: true,
    message: '^*Via Redelivery Port is required.'
  }
}

export const viaRedeliveryPortBunker = {}
export const viaRedeliveryPortBunkerRequired = {
  ...viaRedeliveryPortBunker,
  presence: {
    allowEmpty: true,
    message: '^*Via Redelivery Port Bunker is required.'
  }
}

export const waitingIdleArray = {
}

export const waitingIdleArrayRequired = {
  ...waitingIdleArray,
  presence: {
    allowEmpty: false,
    message: '^* At least one Waiting Idle value is required.'
  }
}

export const yard = {
}

export const requiredYard = {
  ...yard,
  presence: {
    allowEmpty: false,
    message: '^* The yard is required'
  }
}

// export const year = {
// }

// export const yearRequired = {
//   ...year,
//   presence: {
//     allowEmpty: false,
//     message: '^*Year is required.'
//   }
// }

export const zone = {
}

export const zoneRequired = {
  ...zone,
  presence: {
    allowEmpty: false,
    message: '^*Zone is required.'
  }
}

/*******************
 * Objects
 *******************/
export const exampleObject = {
  email: emailRequired
}

/**
 * @param {Object} params
 * @param {Object} params.form
 * @param {Function} params.next
 * @param {Object} params.validation
 */
export const validateStep = params => {
  const { form, next, validation } = params

  let valid = true
  // Validate form
  Object.keys(form).forEach(field => {
    const value = form[field].getValue() ? form[field].getValue() : form[field].value
    const error = validateWithObject({
      fieldName: field,
      value: value,
      validation
    })
    if (error) {
      valid = false
      form[field].setError(error)
    }
  })
  if (valid) {
    next()
  }

  return valid
}

/**
 *
 * @param {Object} params
 * @param {Object} params.fieldName
 * @param {Object} params.validation
 * @param {Object} params.value
 */
export const validateWithObject = params => {
  const { fieldName, validation = {}, value } = params
  const formValues = {}
  formValues[fieldName] = value
  const formFields = {}
  formFields[fieldName] = validation[fieldName]
  const result = validatejs(formValues, formFields)

  // If there is an error message, return it!
  if (result) {
    // Return only the field error message if there are multiple
    return result[fieldName][0]
  }

  return null
}
