import React from 'react';
import PropTypes from 'prop-types';
// Material UI
// Material UI
import TextField from '@material-ui/core/TextField';
// Styles
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles/';
// Utils
import NumberFormatCustom from './NumberFormatCustom';

const useStyles = makeStyles((theme) => ({
	textField: {
		width: '100%',
		background: theme.palette.common.white,
		textAlign: 'left',
	},
	smallInput: {
		// width: '95%',
		// marginLeft: '2.5%'
	},
	noMarginAutoComplete: {
		margin: 0,
		'& .MuiFormControl-root': {
			margin: 0,
		},
	},
	marginBottomAutocomplete: {
		margin: 0,
		// marginBottom: 8,
		'& .MuiFormControl-root': {
			margin: 0,
			// marginBottom: 8
		},
	},
	date: {
		border: 'none',
	},
}));

const RootTextField = (props) => {
	const {
		multiline,
		readOnly,
		field,
		onChange,
		type,
		value,
		allowedDecimalSeparators = ['.'],
		marginBottom = false,
		smallInput = false,
		...rest
	} = props;
	console.log(
		'🚀 ~ file: RootTextField.js ~ line 54 ~ RootTextField ~ onChange',
		onChange
	);
	const classes = useStyles();

	let defaultValue = '';
	if (value || value === 0) {
		defaultValue = value;
	}

	let marginAutocomplete = classes.noMarginAutoComplete;
	if (marginBottom) {
		marginAutocomplete = classes.marginBottomAutocomplete;
	}
	return (
		<>
			<TextField
				className={clsx(classes.textField, {
					[classes.smallInput]: smallInput,
				})}
				fullWidth
				InputProps={{
					readOnly: readOnly,
				}}
				classes={{
					root: marginAutocomplete,
				}}
				multiline={multiline}
				name={field}
				onChange={(event) => {
					onChange(event);
				}}
				type={type === 'number' || type === 'select' ? 'text' : type}
				{...(type === 'number' && {
					InputProps: { inputComponent: NumberFormatCustom },
					inputProps: {
						allowedDecimalSeparators,
					},
				})}
				{...(type === 'date' && { InputLabelProps: { shrink: true } })}
				value={defaultValue}
				size='small'
				{...(multiline && { rows: 1 })}
				// variant='outlined'
				{...rest}
			/>
		</>
	);
};

RootTextField.defaultProps = {
	multiline: false,
	readOnly: false,
	type: 'text',
};

RootTextField.propTypes = {
	multiline: PropTypes.bool.isRequired,
	onChange: PropTypes.func,
	readOnly: PropTypes.bool.isRequired,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default RootTextField;
