import { CREATE_PORT_INFORMATION } from '../Queries/Queries'
import { Filters } from 'react-data-grid-addons'
const { SingleSelectFilter } = Filters

const breadCrumbs = [
  {
    link: '/ports',
    name: 'Ports'
  }
]

const defaultColumnProperties = {
  width: 120
}

const module = {
  name: 'Port'
}

const refetchQueries = [{
  query: CREATE_PORT_INFORMATION
}]

export const show = {
  module,
  createButton: {
    name: 'Create port',
    link: '/create-port'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'portName', name: 'portName', filterRenderer: SingleSelectFilter },
      { key: 'berth', name: 'berth' },
      { key: 'eca', name: 'eca' },
      { key: 'country', name: 'Country' },
      { key: 'zone', name: 'Zone' },
      { key: 'draftArray', name: 'Drafts' },
      { key: 'salinity', name: 'Salinities' },
      { key: 'costArray', name: 'costArray' },
      { key: 'waitingIdleArray', name: 'Waiting Idles' },
      { key: 'fuelDeliveryPremiumArray', name: 'fuelDeliveryPremiumArray' },
      { key: 'FO05', name: 'FO05' },
      { key: 'HSFO380', name: 'HSFO380' },
      { key: 'MGO01', name: 'MGO01' },
      { key: 'bunkeringCost', name: 'bunkeringCost' },
      { key: 'bunkeringTime', name: 'bunkeringTime' },
      { key: 'forwardCommodityFOID', name: 'forwardCommodityFOID' },
      { key: 'forwardCommodityMGOID', name: 'forwardCommodityMGOID' },
      { key: 'shipSectorIDArray', name: 'Ship Sector' }
    ].map(c => ({ ...c, ...defaultColumnProperties })),
    rows: 3
  },
  update: {
    link: '/update-port'
  }
}

export const upsert = {
  module,
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Port created',
      path: '/ports'
    },
    submitButton: 'Create Port'
  },
  emptyObjects: {
    commodity: {
      name: 'commodity',
      refetchQueries
    },
    shipSector: {
      name: 'Ship Sector',
      refetchQueries
    },
    positionDate: {
      name: 'Position Dates',
      refetchQueries
    },
    zone: {
      name: 'Zone',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Port updated',
      path: '/ports'
    },
    submitButton: 'Update Port'
  },
  form: {
    costArray: {
      array: {
        initialQty: 0,
        title: 'costArray'
      },
      item: {
        type: 'number',
        name: 'costArray',
        emptyValue: ''
      }
    },
    forwardCommodityFOID: {
      array: {
        initialQty: 0,
        title: 'forwardCommodityFOID'
      },
      item: {
        type: 'number',
        name: 'forwardCommodityFOID',
        emptyValue: ''
      }
    },
    forwardCommodityMGOID: {
      array: {
        initialQty: 0,
        title: 'forwardCommodityMGOID'
      },
      item: {
        type: 'number',
        name: 'forwardCommodityMGOID',
        emptyValue: ''
      }
    },
    fuelDeliveryPremiumArray: {
      array: {
        initialQty: 0,
        title: 'fuelDeliveryPremiumArray'
      },
      item: {
        type: 'number',
        name: 'fuelDeliveryPremiumArray',
        emptyValue: ''
      }
    },
    draftArray: {
      array: {
        initialQty: 0,
        title: 'Drafts'
      },
      item: {
        type: 'number',
        name: 'Draft',
        emptyValue: ''
      }
    },
    salinity: {
      array: {
        initialQty: 0,
        title: 'Salinities'
      },
      item: {
        type: 'number',
        name: 'Salinity',
        emptyValue: ''
      }
    },
    waitingIdleArray: {
      array: {
        initialQty: 0,
        title: 'Waiting Idle'
      },
      item: {
        type: 'number',
        name: 'Waiting Idle',
        emptyValue: ''
      }
    }
  }
}
