import React, { useState, useReducer, useRef, useEffect } from 'react'
import Helmet from 'react-helmet'
import { makeStyles } from '@material-ui/core/styles'

// Apollo
import { useQuery, useMutation } from 'react-apollo'

// Queries
import {
  GET_SYSTEM_SETTINGS
} from '../Queries/Queries'

// Mutations
import {
  CREATE_APIKEY,
  DELETE_APIKEY,
  UPDATE_APIKEY_NAME,
  UPDATE_SYSTEM_SETTINGS
} from '../Mutations/Mutations'

// Material UI
import Input from '@mui/material/Input'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import Box from '@material-ui/core/Box'
import Button from '@mui/material/Button'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@material-ui/icons/Delete'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import InputBase from '@material-ui/core/InputBase'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Paper from '@material-ui/core/Paper'
import TuneIcon from '@mui/icons-material/Tune'
import WarningIcon from '@mui/icons-material/Warning'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CopyToClipboard } from 'react-copy-to-clipboard'

// Utils
import EditableInputBase from '../Utils/EditableInputBase'
import {
  validateStep
} from '../Utils/form-validations'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '20px',
    marginBottom: '10px'
  },
  subtitle: {
    fontSize: '16px',
    marginLeft: '3px'
    // marginBottom: '5px',
    // fontWeight: 360
  },
  ageCell: {
    border: 'none',
    width: '55px'
  }
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const tokenReducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      return [
        ...action.array
      ]
    }
    case 'delete-token': {
      const newState = [...state]
      newState.splice(action.index, 1)
      return [
        ...newState
      ]
    }
    case 'new-token': {
      let newState = [...state]
      const tokenId = action.id
      const newToken = {
        id: tokenId,
        name: action.name,
        token:  action.token
      }
      newState.unshift(newToken)

      return [
        ...newState
      ]
    }
    case 'update-name': {
      const newState = [...state]
      newState[action.index].name = action.name
      return [
        ...newState
      ]
    }
  }
}

export const SystemSettings = (props) => {

  const classes = useStyles()

  const [green, setGreen] = useState(null)
  const [orange, setOrange] = useState(null)
  const [red, setRed] = useState(null)
  const [heatMapHigh, setHeatMapHigh] = useState(null)
  const [heatMapMedium, setHeatMapMedium] = useState(null)
  const [heatMapLow, setHeatMapLow] = useState(null)
  const [heatMapDays, setHeatMapDays] = useState(null)
  const systemSettingId = useRef(null)
  const [valueError, setValueError] = useState(false)
  const [tokens, setTokens] = useReducer(tokenReducer, {})
  
  const {loading, data, error} = useQuery(GET_SYSTEM_SETTINGS, {
    fetchPolicy: 'network-only',
    onError(err) {
      console.log(err)
    },
    onCompleted(data) {
      systemSettingId.current = data.getSystemSetting.systemSetting.id
      setGreen(data.getSystemSetting.systemSetting.offerAgeYoung)
      setOrange(data.getSystemSetting.systemSetting.offerAgeMedium)
      setRed(data.getSystemSetting.systemSetting.offerAgeOld)
      setHeatMapHigh(data.getSystemSetting.systemSetting.heatMapHigh)
      setHeatMapMedium(data.getSystemSetting.systemSetting.heatMapMedium)
      setHeatMapLow(data.getSystemSetting.systemSetting.heatMapLow)
      setHeatMapDays(data.getSystemSetting.systemSetting.heatMapDays)
      setTokens({
        type: 'add',
        array: data.getSystemSetting.userTokens
      })
    }
  })

  const refetchQueries = [{
    query: GET_SYSTEM_SETTINGS
  }]

  const [updateSystemSettings] = useMutation(UPDATE_SYSTEM_SETTINGS, {
    refetchQueries
  })

  const [createNewToken] = useMutation(CREATE_APIKEY, {
    onCompleted(data) {
      setTokens({
        type: 'new-token',
        id: data.createApiKey.apiKey.id,
        name: data.createApiKey.apiKey.name,
        token: data.createApiKey.apiKey.token
      })
    }
  })

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 9 || e.keyCode === 13) {

      const validRanges = green < orange && green < red && orange < red
      const validThresholds = heatMapLow < heatMapMedium && heatMapLow < heatMapHigh && heatMapMedium < heatMapHigh

      if (validRanges && validThresholds) {
        updateSystemSettings({
          variables: {
            input: {
              id: systemSettingId.current,
              offerAgeYoung: green,
              offerAgeMedium: orange,
              offerAgeOld: red,
              heatMapHigh,
              heatMapMedium,
              heatMapLow,
              heatMapDays
            }
          }
        })
      } else {
        setValueError(true)
        return
      }
    }
  }

  const handleOnBlur = () => {

    const validRanges = green < orange && green < red && orange < red
    const validThresholds = heatMapLow < heatMapMedium && heatMapLow < heatMapHigh && heatMapMedium < heatMapHigh

    if (validRanges && validThresholds) {
      updateSystemSettings({
        variables: {
          input: {
            id: systemSettingId.current,
            offerAgeYoung: green,
            offerAgeMedium: orange,
            offerAgeOld: red,
            heatMapHigh,
            heatMapMedium,
            heatMapLow,
            heatMapDays
          }
        }
      })
    } else {
      setValueError(true)
      return
    }
  }

  const handleChangeGreen = (event) => {
    if (event.keyCode === 109) {
      return
    }

    if (!isNaN(parseFloat(event.target.value))) {
      setGreen(Math.abs(parseFloat(event.target.value)))
    }
  }

  const handleChangeOrange = (event) => {
    if (event.keyCode === 109) {
      return
    }

    if (!isNaN(parseFloat(event.target.value))) {
      setOrange(Math.abs(parseFloat(event.target.value)))
    }
  }

  const handleChangeRed = (event) => {
    if (event.keyCode === 109) {
      return
    }

    if (!isNaN(parseFloat(event.target.value))) {
      setRed(Math.abs(parseFloat(event.target.value)))
    }
  }

  const handleChangeHeatMapLow = (event) => {
    if (event.keyCode === 109) {
      return
    }
    if (!isNaN(parseFloat(event.target.value))) {
      setHeatMapLow(Math.abs(parseFloat(event.target.value)))
    }
  }

  const handleChangeHeatMapMedium = (event) => {
    if (event.keyCode === 109) {
      return
    }
    if (!isNaN(parseFloat(event.target.value))) {
      setHeatMapMedium(Math.abs(parseFloat(event.target.value)))
    }
  }

  const handleChangeHeatMapHigh = (event) => {
    if (event.keyCode === 109) {
      return
    }
    if (!isNaN(parseFloat(event.target.value))) {
      setHeatMapHigh(Math.abs(parseFloat(event.target.value)))
    }
  }

  const handleChangeHeatMapDays = (event) => {
    if (event.keyCode === 109) {
      return
    }
    if (!isNaN(parseFloat(event.target.value))) {
      setHeatMapDays(Math.abs(parseInt(event.target.value)))
    }
  }

  const handleNewToken = () => {
    createNewToken({
      variables: {
        input: {
          name: 'New token'
        }
      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setValueError(false)
  }

  if (loading) {
    return <></>
  } else {
    return (
      <>
        <Helmet>
          <title>Settings</title>
        </Helmet>
        <Grid>
          <Box width='400px' display='flex' flexDirection='row' alignItems='center'>
            <Typography className={classes.title}>System Settings</Typography>
            <TuneIcon style={{ color: 'gray', marginBottom: '6px', marginLeft: '5px' }}/>
          </Box>

          <Paper style={{ width: '510px', height: '600px', borderRadius: '1.5%', padding: '10px'}} elevation={3}>
            <Box>
              <Typography className={classes.subtitle}>Offer age ranges:</Typography>
            </Box>

            <Box style={{ marginBottom: '7px' }} display='flex' flexDirection='row' alignItems='flex-end'>
              <WarningIcon style={{ color: 'gray', transform: 'scale(0.65)' }} />
              <Typography style={{ fontSize: '12px', color: '#F17300', marginBottom: '2px' }}>These ranges will apply for all voyage offers in the system!</Typography>
            </Box>

            {/* Voyage offer age inputs */}
            <Table style={{ width: '125px', marginLeft: '40px' }}>
              <TableBody>
                <TableRow>
                  <TableCell align='left' className={classes.ageCell}>
                    <span style={{ color: 'green' }}>Green:</span>
                  </TableCell>
                  <TableCell align='right' className={classes.ageCell}>
                    <EditableInputBase
                      alignRight
                      style={{ width: '50px' }}
                      value={green}
                      onKeyDown={handleOnKeyDown}
                      onBlur={handleOnBlur}
                      onChange={handleChangeGreen}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <span>%</span>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.ageCell}>
                    <span style={{ color: '#F17300' }}>Orange:</span>
                  </TableCell>
                  <TableCell align='right' className={classes.ageCell}>
                    <EditableInputBase
                      alignRight
                      style={{ width: '50px' }}
                      value={orange}
                      onKeyDown={handleOnKeyDown}
                      onBlur={handleOnBlur}
                      onChange={handleChangeOrange}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <span>%</span>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.ageCell}>
                    <span style={{ color: 'red' }}>Red:</span>
                  </TableCell>
                  <TableCell align='right' className={classes.ageCell}>
                    <EditableInputBase
                      alignRight
                      style={{ width: '50px' }}
                      value={red}
                      onKeyDown={handleOnKeyDown}
                      onBlur={handleOnBlur}
                      onChange={handleChangeRed}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <span>%</span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Box style={{ marginBottom: '10px', marginTop: '15px'}}>
              <Typography className={classes.subtitle}>Heat-map:</Typography>
            </Box>

            {/* Heatmap threshold values */}
            <Table style={{ width: '125px', marginLeft: '40px' }}>
              <TableBody>
                <TableRow>
                  <TableCell align='left' className={classes.ageCell}>
                    Lower:
                  </TableCell>
                  <TableCell align='right' className={classes.ageCell}>
                    <EditableInputBase
                      alignRight
                      style={{ width: '50px' }}
                      value={heatMapLow}
                      onKeyDown={handleOnKeyDown}
                      onBlur={handleOnBlur}
                      onChange={handleChangeHeatMapLow}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <span>%</span>
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' className={classes.ageCell}>
                      <Box display='flex' justifyContent='center' alignItems='center' width='20px' height='20px' style={{ backgroundColor: '#97e7a4', color: 'white', borderRadius: '2px', marginRight: '5px' }}></Box>
                      <Box display='flex' justifyContent='center' alignItems='center' width='20px' height='20px' style={{ backgroundColor: '#ffc8ba', color: 'white', borderRadius: '2px' }}></Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.ageCell}>
                    Medium:
                  </TableCell>
                  <TableCell align='right' className={classes.ageCell}>
                    <EditableInputBase
                      alignRight
                      style={{ width: '50px' }}
                      value={heatMapMedium}
                      onKeyDown={handleOnKeyDown}
                      onBlur={handleOnBlur}
                      onChange={handleChangeHeatMapMedium}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <span>%</span>
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' className={classes.ageCell}>
                      <Box display='flex' justifyContent='center' alignItems='center' width='20px' height='20px' style={{ backgroundColor: '#6bd47c', color: 'white', borderRadius: '2px', marginRight: '5px' }}></Box>
                      <Box display='flex' justifyContent='center' alignItems='center' width='20px' height='20px' style={{ backgroundColor: '#f2856b', color: 'white', borderRadius: '2px' }}></Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.ageCell}>
                    Higher:
                  </TableCell>
                  <TableCell align='right' className={classes.ageCell}>
                    <EditableInputBase
                      alignRight
                      style={{ width: '50px' }}
                      value={heatMapHigh}
                      onKeyDown={handleOnKeyDown}
                      onBlur={handleOnBlur}
                      onChange={handleChangeHeatMapHigh}
                    />
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <span>%</span>
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <Box display='flex' flexDirection='row' justifyContent='flex-end' className={classes.ageCell}>
                      <Box display='flex' justifyContent='center' alignItems='center' width='20px' height='20px' style={{ backgroundColor: '#3aac4d', color: 'white', borderRadius: '2px', marginRight: '5px' }}></Box>
                      <Box display='flex' justifyContent='center' alignItems='center' width='20px' height='20px' style={{ backgroundColor: '#f36746', color: 'white', borderRadius: '2px' }}></Box>
                    </Box>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell align='left' className={classes.ageCell}>
                    Days:
                  </TableCell>
                  <TableCell align='left' className={classes.ageCell}>
                    <EditableInputBase
                      alignRight
                      style={{ width: '50px' }}
                      value={heatMapDays}
                      onKeyDown={handleOnKeyDown}
                      onBlur={handleOnBlur}
                      onChange={handleChangeHeatMapDays}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>


            {/* Api keys / tokens */}
            <Box style={{ marginTop: '30px' }} display='flex' flexDirection='row' justifyContent='space-between'>
              <Typography className={classes.subtitle}>Your tokens:</Typography>
              <Button
                variant='contained' 
                color="success" 
                style={{ marginLeft: '3px', marginRight: '10px' }}
                onClick={handleNewToken}
              >
                  <AddIcon style={{ fontSize: '15px'}} />
                  <Typography style={{ textTransform: 'none', fontSize: '12px' }}>New token</Typography>
                </Button>
            </Box>

            <Box
              style={{
                backgroundColor: '#DEE1E3',
                marginLeft: '3px',
                marginTop: '10px',
                borderRadius: '1.05%',
                overflowY: 'auto'
              }}
              display='flex'
              flexDirection='column'
              width='480px' height='200px'
            >
              {(tokens && tokens.length > 0)
              ? tokens.map((token, i) => {
                return (
                  <TokenBox token={token} setTokens={setTokens} key={i} index={i} refetchQueries={refetchQueries} />
                )
              })
              : [0].map(item => 'No tokens')
            }
            </Box>
          </Paper>
        </Grid>
        <Snackbar open={valueError} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: '70%' }}>
            The values should go from lowest to greatest.
          </Alert>
        </Snackbar>
      </>
    )
  }
}

const TokenBox = (props) => {
  const { token, refetchQueries, index, setTokens } = props
  
  const [tokenName, setTokenName] = useState(token.name)
  const [visible, setVisible] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [copied, setCopied] = useState(false)
  const [updateTokenName] = useMutation(UPDATE_APIKEY_NAME)

  useEffect(() => {
    if (token) {
      setTokenName(token.name)
    }
  }, [token])
  
  const handleChange = (event) => {
    setTokenName(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 9) {
      setTokens({
        type: 'update-name',
        name: tokenName ? tokenName : 'New token',
        index: index
      })
      updateTokenName({
        variables: {
          input: {
            id: token.id,
            name: tokenName ? tokenName : 'New token'
          }
        }
      })
      if (!tokenName) {
        setTokenName('New token')
      }
    }
    
  }

  const handleClickDelete = () => {
    setOpenDialog(true)
  }

  const handleBlur = () => {
    updateTokenName({
      variables: {
        input: {
          id: token.id,
          name: tokenName ? tokenName : 'New token'
        }
      }
    })
    if (!tokenName) {
      setTokenName('New token')
    }
  }

  const handleClickVisible = () => {
    setVisible(value => !value)
  }

  const handleClickCopy = () => {
    setCopied(true)
  }

  const handleCloseCopy = () => {
    setCopied(false)
  }

  return (
    <Box
      display='flex'
      flexDirection='column'
      style={{
        backgroundColor: 'whitesmoke',
        margin: '5px',
        padding: '5px',
        borderRadius: '0.5%'
      }}
    >
      {/* upper */}
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        style={{
          marginBottom: '10px'
        }}
      >
        <Input
          value={tokenName}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
        />
        <Box>
          <Button
            onClick={handleClickVisible}
          >
            {visible ? (
              <VisibilityIcon color='action'/>
              ) : (
              <VisibilityOffIcon color='action' />
            )}
          </Button>
          <CopyToClipboard text={token.token}>
            <Button onClick={handleClickCopy}>
              <ContentCopyIcon color='action'/>
            </Button>
          </CopyToClipboard>
          <Button
            onClick={handleClickDelete}
          >
            <DeleteIcon color='action'/>
          </Button>
        </Box>
      </Box>

      {/* lower */}
      <Box style={{ width: '420px', height: '20px' }}>
        {visible ? (
            <Typography noWrap style={{ fontSize: '12px' }} >{token.token}</Typography>

          ) : (
            <Typography noWrap style={{ fontSize: '12px', '-webkit-text-security': 'disc' }} >{token.token}</Typography>
        )}
      </Box>
      <DeleteTokenDialog
        index={index}
        open={openDialog}
        setOpen={setOpenDialog}
        token={token}
        setTokens={setTokens}
        refetchQueries={refetchQueries}
      />

      <Snackbar open={copied} onClose={handleCloseCopy} autoHideDuration={2500}>
        <Alert onClose={handleCloseCopy} severity="success" sx={{ width: '70%' }}>
          Token copied!
        </Alert>
      </Snackbar>
    </Box>
  )
}

const DeleteTokenDialog = props => {
  const { open, setOpen, token, index, setTokens } = props
  
  const [deleteApiKey] = useMutation(DELETE_APIKEY)
  
  const handleDelete = () => {
    setTokens({
      type: 'delete-token',
      index
    })
    deleteApiKey({
      variables: {
        input: {
          id: token.id
        }
      }
    })
    setOpen(false)
  }

  const handleCloseDialog = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        {`You're about to delete token "${token.name}", proceed?`}
      </DialogTitle>
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='center'
      >
        <Button
          style={{ marginRight: '5px' }}
          onClick={handleCloseDialog}
        >
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
        >
          Delete
        </Button>
      </Box>
    </Dialog>
  )
}

SystemSettings.propTypes = {

}

export default SystemSettings
