// Import React Tools
import React, { useState, useReducer, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'

// Import Ag Grid Components
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

// Import Material UI Components
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MuiAlert from '@material-ui/lab/Alert'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import RootColumnLink from './RootColumnLink'
import { Snackbar, Typography } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'

// Import Styles
import { makeStyles } from '@material-ui/core/styles'

// Apollo
import { useMutation } from 'react-apollo'

// Transition Component For Delete Dialog
const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// Alert For the Error Message
function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

// Styles
const useStyles = makeStyles((theme) => ({
  delete: {
    marginLeft: '5px',
    color: theme.palette.common.red
  },
  dialogContent: {
    width: '350px !important',
    maxHeight: '200px !important'
  },
  dialogButtons: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    margin: '20px'
  },
  cancel: {
    marginRight: '5px',
    color: theme.palette.common.black
  }
}))

// Initial State of mouse...?
const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

// Context Reducer
const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

// The Component That Shows The Grid
const RootAgGrid = props => {
  const {
    columnDefs,
    filteredArray = [],
    mutation,
    objectNameKey1,
    objectNameKey2,
    type,
    updateLink
  } = props

  // Get The Styles
  const classes = useStyles()

  // Needed Hooks For Ag Grid
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)

  // Reducer Hook To Handle Menu
  const [contextState, setContextState] = useReducer(contextReducer, initialState)

  // Hook To Open Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  // The Objects To Be Displayed In The Grid
  const [rowData, setRowData] = useState(filteredArray)

  // The Array Of Deleted Objects
  const [deletedObjectArray, setDeletedObjectArray] = useState([])

  // The Current Object
  const [currentObject, setCurrentObject] = useState({})

  // When Filtered Array Changes (Trie-Search)
  useEffect(() => {
    if (filteredArray) {
      // Show The Filtered Objects Minus The Deleted Ones
      setRowData((prev) => {
        return filteredArray.filter(item => !deletedObjectArray.includes(item.id))
      })
    }
  }, [filteredArray])

  // Right-Click On Row Routine
  const handleClick = (event, params) => {
    event.preventDefault()
    setContextState({
      event,
      selectedRow: params,
      type: 'open'
    })
    setCurrentObject(params)
  }

  // Close Menu Routine
  const handleClose = () => {
    setContextState({
      type: 'close'
    })
  }

  // Handle Click To Delete Row
  const handleClickDelete = (props) => {
    setOpenDeleteDialog(true)
  }

  // Handle Close Dialog
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false)
  }

  // Context Cell Renderer
  const ContextMenuCell = props => {
    const { value } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }

    return (
      <div onContextMenu={handleClickCell}>
        {value}
      </div>
    )
  }

  // Cell Renderer For Link
  const LinkWithContextMenu = props => {
    const { value } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }

    return (
      <div onContextMenu={handleClickCell}>
        <RootColumnLink viewLink={`${updateLink}/${props.data.id}`} value={value} row={props.data} />
      </div>
    )
  }

  // JSON With The Grid's Setup
  const gridOptions = {
    columnDefs: columnDefs.map(c => { return { ...c, cellRenderer: c.cellRenderer ? c.cellRenderer : 'contextCell' } }),
    defaultColDef: {
      filter: 'agTextColumnFilter',
      resizable: true,
      floatingFilter: false,
      sortable: true,
      debounceVerticalScrollbar: true,
      debounceHorizontalScrollbar: true,
      flex: 1,
      minWidth: 150
    }
  }

  // Function To Be Executed When Loading The Grid
  function onGridReady (params) {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
    setGridColumnApi(params.columnApi)
  }

  // We Render Grid + Right Click Menu + Popup
  return (
    <>
      {/* Ag Grid */}
      <div className='ag-theme-balham' style={{ height: 600, width: '100%' }}>
        <AgGridReact
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          animateRows
          rowData={rowData}
          rowBuffer={50}
          frameworkComponents={{
            contextCell: ContextMenuCell,
            link: LinkWithContextMenu
          }}
        />
      </div>

      {/* Right Click Menu */}
      <Menu
        keepMounted
        open={contextState.mouseY !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX }
            : undefined
        }
      >

        <MenuItem onClick={handleClickDelete}>{`Delete ${type}`}</MenuItem>

      </Menu>

      {/* Popup Before Deleting */}
      <DeleteObjectDialog
        closeDialog={handleCloseDialog}
        closeMenu={handleClose}
        mutation={mutation}
        object={currentObject}
        objectNameKey1={objectNameKey1}
        objectNameKey2={objectNameKey2}
        open={openDeleteDialog}
        setRowData={setRowData}
        setDeletedObjectArray={setDeletedObjectArray}
        type={type}
      />
    </>
  )
}

const DeleteObjectDialog = props => {
  const {
    closeDialog,
    closeMenu,
    mutation,
    object,
    objectNameKey1,
    objectNameKey2,
    open,
    setRowData,
    setDeletedObjectArray,
    type
  } = props

  const classes = useStyles()

  // Delete Error
  const [snackBarMessage, setSnackBarMessage] = useState({ open: false, message: '', severity: '' })

  // Delete Object Mutation Hook #MoveBack
  const [deleteObject] = useMutation(mutation, {

    onError (error) {
      // The Error From The Server
      const errorString = error?.message?.split(':')[1]

      // The Only Acceptable Errors...
      if (errorString.includes('Routes have errors') || errorString.includes('Route has errors')) {
        // Set Message For Error Snack Bar
        setSnackBarMessage({
          open: true,
          message: `${errorString} But ${type} was deleted succesfully!`,
          severity: 'error'
        })

        // Remove Object From The Grid
        setRowData((prev) => prev.filter(item => item.id !== object.id))

        // Save It As Deleted Object
        setDeletedObjectArray((prev) => {
          const arr = prev
          arr.push(object.id)
          return arr
        })
      } else {
        setSnackBarMessage({
          open: true,
          message: errorString,
          severity: 'error'
        })
      }
    },

    onCompleted () {
      // Remove Object From The Grid
      setRowData((prev) => prev.filter(item => item.id !== object.id))

      // Save It As Deleted Object
      setDeletedObjectArray((prev) => {
        const arr = prev
        arr.push(object.id)
        return arr
      })

      setSnackBarMessage({
        open: true,
        message: `${type} deleted succesfully!`,
        severity: 'success'
      })
    }
  })

  // Close Both Dialog and Menu
  const closeDialogAndMenu = () => {
    closeMenu()
    closeDialog()
  }

  // Handle Delete Object
  const handleDelete = () => {
    // Delete Object Mutation (See onError & onCompleted)
    deleteObject({
      variables: {
        input: {
          id: object.id
        }
      }
    })

    // Close Popups
    closeDialogAndMenu()
  }

  // Render Dialog
  if (object) {
    return (
      <Grid>
        <Dialog
          TransitionComponent={Transition}
          aria-labelledby='delete-object-dialog'
          onClose={closeDialogAndMenu}
          open={open}
        >
          <Grid className={classes.dialogContent}>
            {/* Feedback Message */}
            <DialogTitle>{`You're about to delete ${type} "${object[objectNameKey1]}${(!objectNameKey2) ? '"' : ''}${(objectNameKey2) ? ` - ${object[objectNameKey2]}"` : ''}. How to proceed?`}</DialogTitle>

            {/* Buttons */}
            <Grid className={classes.dialogButtons}>
              <Button variant='outlined' className={classes.cancel} onClick={closeDialogAndMenu}>Cancel</Button>
              <Button variant='outlined' className={classes.delete} onClick={handleDelete}>Delete it</Button>
            </Grid>

          </Grid>
        </Dialog>

        {/* Error Snack Bar */}
        <Snackbar open={snackBarMessage.open} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })} variant='error'>
          <Alert severity={snackBarMessage.severity} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </Grid>
    )
  } else {
    // Prevents Bug...
    return (
      <>
      </>
    )
  }
}

// Our Componentes Requirements
RootAgGrid.propTypes = {
  history: PropTypes.object.isRequired,
  rowData: PropTypes.array.isRequired
}

export default RootAgGrid
