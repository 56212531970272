import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
// Context
import { UserContext } from '../App/AppContext'
// import { withRouter } from 'react-router-dom'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { CREATE_VOYAGEOFFER_INFORMATION, GET_VOYAGE_OFFER, GET_VALIDATION_CURVE } from '../Queries/Queries'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_VOYAGEOFFER, DELETE_VOYAGE_OFFER } from '../Mutations/Mutations'
import { useMutation } from 'react-apollo'
// Utils
import {
  bid,
  clientRequired,
  offerRequired,
  positionDateRequired,
  routeRequired,
  ownerRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput, formatNumber } from '../Utils/utils'

// import CreateOptionsContainer from '../Utils/CreateOptionsContainer'
// import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
// import Button from '@material-ui/core/Button'
import TrieSearch from 'trie-search'
import RootTextField from '../Utils/RootTextField'
import Button from '@material-ui/core/Button'
import TextError from '../Utils/TextError'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  smallInputs: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-between'
  },
  gridContainer: {
    padding: '0px',
    margin: '0px',
    height: '100%'
  },
  paperContainer: {
    padding: '0px',
    margin: '0px',
    marginRight: '0px'
  },
  autocomplete: {
    marginTop: '0px'
  },
  button: {
    marginTop: '25px'
  },
  offer: {
    marginLeft: '8px'
  },
  bid: {
    marginRight: '8px'
  },
  buttons: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-end'
  }
}))

export const VoyageOffer = props => {
  const { voyageId, route: routeProps, routeName, client: clientProps, positionDate: positionDateProps, queryData, adcomFromClientResult, clientResults = false } = props

  console.log({ positionDateProps })
  const skip = !voyageId
  return (
    <QueryHelper
      query={CREATE_VOYAGEOFFER_INFORMATION}
    >
      {({ data }) => {
        const { createVoyageOfferInformation } = data
        if (createVoyageOfferInformation) {
          // const routes = []
          // let maxLoadPorts = 0
          // let maxDischargePorts = 0
          // for (let index = 0; index < createVoyageOfferInformation.routes.length; index++) {
          //   const loadPortsLength = createVoyageOfferInformation.routes[index].loadPorts.length
          //   const dischargePortsLength = createVoyageOfferInformation.routes[index].dischargePorts.length

          //   const load = createVoyageOfferInformation.routes[index].loadPorts
          //   const discharge = createVoyageOfferInformation.routes[index].dischargePorts

          //   if (loadPortsLength > maxLoadPorts) {
          //     maxLoadPorts = loadPortsLength
          //   }
          //   if (dischargePortsLength > maxDischargePorts) {
          //     maxDischargePorts = dischargePortsLength
          //   }

          //   let loadPorts = {}
          //   for (let i = 0; i < loadPortsLength; i++) {
          //     loadPorts = {
          //       ...loadPorts,
          //       [`loadPort${i}`]: load[i].portName
          //     }
          //   }

          //   let dischargePorts = {}
          //   for (let j = 0; j < dischargePortsLength; j++) {
          //     dischargePorts = {
          //       ...dischargePorts,
          //       [`dischargePort${j}`]: discharge[j].portName
          //     }
          //   }

          //   routes.push({
          //     id: createVoyageOfferInformation.routes[index].id,
          //     commodityName: createVoyageOfferInformation.routes[index].commodity.commodityName,
          //     name: createVoyageOfferInformation.routes[index].codeNameLong ? createVoyageOfferInformation.routes[index].codeNameLong : createVoyageOfferInformation.routes[index].routeName,
          //     ...loadPorts,
          //     ...dischargePorts
          //   })
          // }
          // const positionDates = []
          // for (let index = 0; index < createVoyageOfferInformation.positionDates.length; index++) {
          //   positionDates.push({ id: createVoyageOfferInformation.positionDates[index].id, name: createVoyageOfferInformation.positionDates[index].positionDateName })
          // }

          return (
            <GetOfferQueryHelper
              routeProps={routeProps}
              routeName={routeName}
              clientProps={clientProps}
              positionDateProps={positionDateProps}
              // maxLoadPorts={maxLoadPorts}
              // maxDischargePorts={maxDischargePorts}
              // routes={routes}
              // clients={createVoyageOfferInformation.clients}
              // positionDates={positionDates}
              owners={createVoyageOfferInformation.owners}
              handleClose={props.handleClose}
              voyageId={voyageId}
              skip={skip}
              queryData={queryData}
              adcomFromClientResult={adcomFromClientResult}
              clientResults={clientResults}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}

VoyageOffer.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const GetOfferQueryHelper = props => {
  const { voyageId, skip, routeProps, routeName, owners, clientProps, positionDateProps, handleClose, queryData, adcomFromClientResult = null, clientResults = false } = props
  if (voyageId) {
    return (
      <QueryHelper
        query={GET_VOYAGE_OFFER}
        variables={{ input: { id: voyageId } }}
        skip={skip}
      >
        {({ data }) => {
          let voyage = null
          if (data && data.getVoyageOffer && data.getVoyageOffer.voyageOffer) {
            voyage = data.getVoyageOffer.voyageOffer
          }

          return (
            <CreateVoyageOffer
              clientResults={clientResults}
              routeProps={routeProps}
              routeName={routeName}
              clientProps={clientProps}
              positionDateProps={positionDateProps}
              // maxLoadPorts={maxLoadPorts}
              // maxDischargePorts={maxDischargePorts}
              // routes={routes}
              // clients={clients}
              // positionDates={positionDates}
              owners={owners}
              handleClose={handleClose}
              voyage={voyage}
              queryData={queryData}
            />
          )
        }}
      </QueryHelper>
    )
  } else {
    return (
      <CreateVoyageOffer
        clientResults={clientResults}
        routeProps={routeProps}
        routeName={routeName}
        clientProps={clientProps}
        positionDateProps={positionDateProps}
        // maxLoadPorts={maxLoadPorts}
        // maxDischargePorts={maxDischargePorts}
        // routes={routes}
        // clients={clients}
        // positionDates={positionDates}
        owners={owners}
        handleClose={handleClose}
        adcomFromClientResult={adcomFromClientResult}
      />
    )
  }
}

// export default withRouter(VoyageOffer)

const CreateVoyageOffer = props => {
  const { routeProps, routeName, owners, voyageOffer, clientProps, positionDateProps, handleClose, voyage, queryData, adcomFromClientResult, clientResults = false } = props
  const classes = useStyles()

  const validation = {
    bid: bid,
    client: clientRequired,
    offer: offerRequired,
    positionDate: positionDateRequired,
    route: routeRequired,
    owner: ownerRequired
  }

  // const getRouteFormat = (route) => {
    // for (let i = 0; i < routes.length; i++) {
    //   if (routes[i].id === route) {
    //     return { id: routes[i].id, name: routes[i].name }
    //   }
    // }
    // return !!route
  // }

  const adcomAllowComas = value => {
    if (value === null || typeof value === 'number' || isNaN(parseFloat(value.replace(',', '.')))) {
      return 0
    } else {
      return parseFloat(value.replace(',', '.'))
    }
  }

  let route = (routeProps && routeName) ? { id: routeProps, name: routeName } : { id: '', name: '' }
  let owner = clientProps
  let positionDate = positionDateProps ? positionDateProps : ''
  if (route && !route.id) {
    // TODO if voyageOffer then {id: voyageOffer.route.id, name: voyageOffer.route.name}
    route = voyage ? {id: voyage.route.id, name: voyage.route.routeName } : { id: '', name: '' }
  } else if (!route) {
    route = { id: '', name: '' }
  }
  // console.log(route)
  if (!owner) {
    owner = voyageOffer ? voyageOffer.owner.id : ''
  }
  if (!positionDate) {
    positionDate = voyageOffer ? voyageOffer.positionDate : ''
  }

  const { user: userContext, setUserContext } = useContext(UserContext)

  const ownersToShow = owners?.map(item => { return { id: item.id, name: item.ownerName } })

  const initialValues = {
    offer: voyage ? voyage.originalOffer : '',
    route: voyage ? { id: voyage.route?.id, name: voyage.route?.routeName } : (route) || '',
    client: voyage ? { id: voyage.client?.id, name: voyage.client?.name } : (clientProps) || '',
    // TODO when update this one wont work, check it
    positionDate: voyage ? { id: voyage.positionDate?.id, name: voyage.positionDate?.positionDateName } : (positionDate) || '',
    adcome: (voyage) ? (voyage.adcome).toString() : (adcomFromClientResult) ? adcomFromClientResult.toString() :  userContext.adcom,
    bid: (voyage) ? voyage.originalBid : 0,
    owner: voyage?.owner ? voyage.owner.id : ownersToShow?.filter(owner => owner.name === 'Mkt')[0]
  }

  console.log({ initialValues })


  const form = {
    client: useFormInput({
      initialValue: initialValues.client,
      select: true,
      selectValues: [],
      label: 'Client',
      required: true,
      disabled: (!!voyage && voyage?.client) || (routeProps),
    }),
    owner: useFormInput({
      initialValue: initialValues.owner,
      select: true,
      selectValues: ownersToShow,
      label: 'Owner',
      required: true,
    }),
    positionDate: useFormInput({
      initialValue: initialValues.positionDate,
      select: true,
      selectValues: [],
      label: 'Position Date',
      required: true,
      disabled: (!!voyage) || (routeProps),
    })
  }

  const form2 = {
    adcome: useFormInput({
      label: 'Adcom',
      required: true,
      initialValue: initialValues.adcome,
      type: 'text',
    })
  }

  const form3 = {
    offer: useFormInput({
      label: 'Offer',
      initialValue: initialValues.offer,
      required: true,
      type: 'number',
      autoFocus: clientResults
    })
  }

  const form4 = {
    bid: useFormInput({
      label: 'Bid',
      initialValue: initialValues.bid,
      required: false,
      type: 'number'
    })
  }

  // const [routeId, setRouteId] = useState(initialValues.route)
  const [showRouteError, setShowRouteError] = useState('')

  const input = {
    id: voyage && voyage.id,
    owner: form.owner.getValue(),
    client: form.client.getValue(),
    route: initialValues.route && initialValues.route.id,
    positionDate: form.positionDate.getValue(),
    offer: form3.offer.getValue(),
    bid: form4.bid.getValue(),
    adcome: adcomAllowComas(form2.adcome.getValue())
  }

  const refetchQueries = [{
    query: GET_VALIDATION_CURVE,
    variables: queryData ? { input: { id: queryData } } : {}
  }]

  const [deleteVoyageOffer] = useMutation(DELETE_VOYAGE_OFFER, {
    refetchQueries
  })

  const handleDelete = () => {
    deleteVoyageOffer({
      variables: {
        input: { id: voyage.id }
      }
    })
    handleClose()
  }

  const next = ({ mutation }) => {
    if (!initialValues.route || !initialValues.route.id || initialValues.route.id === '') {
      setShowRouteError('*Route is required')
      return
    }
    validateStep({
      form: {
        owner: form.owner,
        client: form.client,
        positionDate: form.positionDate,
        adcome: form2.adcome,
        offer: form3.offer,
        bid: form4.bid
      },
      next: () => {
        mutation()
        handleClose()
      },
      validation
    })
  }

  // const loadPortKeys = []
  // for (let index = 0; index < maxLoadPorts; index++) {
  //   loadPortKeys.push(`loadPort${index}`)
  // }
  // const dischargePortKeys = []
  // for (let index = 0; index < maxDischargePorts; index++) {
  //   dischargePortKeys.push(`dischargePort${index}`)
  // }

  // const [optionRoutes, setOptionRoutes] = useState(routes)
  // const ts = new TrieSearch(['name', 'commodityName', ...loadPortKeys, ...dischargePortKeys], {
  //   idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  // })
  // const [autocompleteValue, setAutocompleteValue] = useState({})
  // ts.addAll(routes)
  // const searchRef = React.useRef(autocompleteValue)
  // React.useEffect(() => {
  //   if (searchRef.current !== autocompleteValue) {
  //     searchRef.current = autocompleteValue
  //     if (!autocompleteValue) {
  //       setOptionRoutes(routes)
  //     } else {
  //       const searchArray = autocompleteValue.split(',')
  //       const foundRoutes = ts.get(searchArray, TrieSearch.UNION_REDUCER)
  //       setOptionRoutes(foundRoutes)
  //     }
  //   }
  // }, [autocompleteValue, optionRoutes, routes, ts])

  return (
    <MutationHelper
      mutation={UPSERT_VOYAGEOFFER}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : {
          onCompletedObject: {
            title: 'Success',
            message: 'Voyage Offer Created'
            // path: '/validations'
          }
        }
      )}
      refetchQueries={queryData && refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <Grid alignContent='stretch' className={classes.gridContainer}>
            <RootPaper className={classes.paperContainer} {...(!props.onCompleted)}>
              <Grid>
                <Autocomplete
                  className={classes.autocomplete}
                  id='highlights-demo'
                  style={{ width: '100%' }}
                  filterOptions={(x) => {
                    return x
                  }}
                  // options={optionRoutes}
                  value={initialValues.route}
                  disabled={routeProps || voyage}
                  getOptionLabel={(option) => option.name}
                  // onChange={(_e, v) => {
                  //   setRouteId({ id: v ? v.id : null, name: v ? v.name : null })
                  // }}
                  // onInputChange={(_e, v) => {
                  //   setShowRouteError((v === '') ? '*Route is required' : '')
                  //   setAutocompleteValue(v)
                  // }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} label='Routes' margin='none' />
                  )}
                />
                <TextError error={showRouteError} />
              </Grid>
              <RootForm
                autoFocus={false}
                form={form}
                validation={validation}
                quitFocus
                onKeyDown={(e) => {
                  if (clientResults && (e.keyCode === 13)) {
                    next({ mutation })
                  }
                }}
              />
              <Grid
                className={classes.smallInputs}
              >
                <Grid
                  className={classes.bid}
                >
                  <RootForm
                    label='bid'
                    form={form4}
                    validation={validation}
                    quitFocus
                    onKeyDown={(e) => {
                      if (clientResults && (e.keyCode === 13)) {
                        next({ mutation })
                      }
                    }}
                  />

                </Grid>
                <Grid
                  className={classes.offer}
                >
                  <RootForm
                    label='offer'
                    form={form3}
                    validation={validation}
                    quitFocus
                    onKeyDown={(e) => {
                      if (clientResults && (e.keyCode === 13)) {
                        next({ mutation })
                      }
                    }}
                  />
                </Grid>

              </Grid>
              <RootForm
                autoFocus={false}
                form={form2}
                validation={validation}
                quitFocus
                onKeyDown={(e) => {
                  if (clientResults && (e.keyCode === 13)) {
                    next({ mutation })
                  }
                }}
              />
              <Grid className={classes.buttons}>
                <Button className={classes.button} onClick={handleClose} color='primary' autoFocus={!clientResults}>
                  Cancel
                </Button>
                {[0].map(item => {
                  if (voyage) {
                    return (
                      <Button className={classes.button} color='primary' onClick={handleDelete}>
                        Delete
                      </Button>
                    )
                  }
                })}
                <Button className={classes.button} color='primary' disabled={result.loading} onClick={() => next({ mutation })}>
                  {(voyage) ? 'Update' : 'Create'}
                </Button>
              </Grid>

            </RootPaper>
          </Grid>
        )
      }}
    </MutationHelper>
  )
}

CreateVoyageOffer.defaultProps = {
  refetchQueries: []
}

CreateVoyageOffer.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array.isRequired
}
