import React from 'react'
import PropTypes from 'prop-types'
// Apollo
import {
  Query
} from 'react-apollo'
// Date fns
import { format, utcToZonedTime } from 'date-fns-tz'
// Material UI
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
// Queries
import { GET_VOYAGE_OFFER_INFORMATION } from '../Queries/Queries'
// Utils
import {
  formatNumber
} from '../Utils/utils'
import differenceInHours from 'date-fns/differenceInHours'
import clsx from 'clsx'

const HtmlTooltip = withStyles((theme) => ({
  tooltip: ({ background, border }) => ({
    borderRadius: '7px',
    elevation: 'above',
    backgroundColor: background.color,
    borderColor: border.color,
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    boxShadow: '3px 3px 4px rgba(0, 0, 0, 0.15)'
  })
}))(Tooltip)

const getFormattedDate = (updated, timeZone, i) => {
  let date, zonedTime
  if (i >= 0) {
    zonedTime = (updated[i] && updated[i]) ? utcToZonedTime(updated[i], timeZone) : ''
    date = (updated[i] && updated[i]) ? new Date(updated[i]) : ''
  } else {
    zonedTime = (updated && updated) ? utcToZonedTime(updated, timeZone) : ''
    date = (updated && updated) ? new Date(updated) : ''
  }

  const year = date.toLocaleString('default', { year: 'numeric', timeZone })
  const month = date.toLocaleString('default', { month: 'short', timeZone })
  const day = date.toLocaleString('default', { day: 'numeric', timeZone })

  const timeString = `${day}${month}${year} ${format(zonedTime, 'HH:mm:ss', { timeZone })}`

  return timeString // returns a string
}

const OfferInformationQuery = props => {
  const { id, classes, baseDifference, fuelDifference } = props
  return (
    <Query
      fetchPolicy='network-only'
      query={GET_VOYAGE_OFFER_INFORMATION}
      variables={{ input: { offerID: id } }}
    >
      {({ data }) => {
        if (data) {
          // curves = data.getUpdatedResults.forwardCurves
          const voyage = data.getVoyageOfferInformation.offer
          const secondLoad = voyage.route.loadPortArray.length > 1 ? `-${voyage.route.loadPortArray[1].portName}(${voyage.route.loadPortArray[1].draftArray})` : ''
          const secondDisch = voyage.route.dischargePortArray.length > 1 ? `-${voyage.route.dischargePortArray[1].portName}(${voyage.route.dischargePortArray[1].draftArray})` : ''
          const secondLterms = voyage.route.loadTermsArray.length > 1 ? `-${voyage.route.loadTermsArray[1]}` : ''
          const secondDterms = voyage.route.dischargeTermsArray.length > 1 ? `-${voyage.route.dischargeTermsArray[1]}` : ''

          let spreadStrings = ''
          for (let k = 0; k < voyage.route.percentages.length; k++) {
            spreadStrings += `${formatNumber({ number: voyage.route.percentages[k].value, format: '0,0' })} (${voyage.route.percentages[k].name}) `
          }

          return (
            <Grid className={classes.tooltipArrange} style={{ padding: '7px'}}>
              <Typography className={classes.tooltipText}>{`${(voyage.route.stem / 1000).toFixed(0)} / ${voyage.route.tolerance} ${voyage.route.commodity.commodityName} `}</Typography>
              <Typography className={classes.tooltipText}>{`${voyage.route.loadPortArray[0].portName} (${voyage.route.loadPortArray[0].draftArray}) ${secondLoad} / ${voyage.route.dischargePortArray[0].portName} (${voyage.route.dischargePortArray[0].draftArray}) ${secondDisch}`}</Typography>
              <Typography className={classes.tooltipText}>{`${voyage.route.loadTermsArray[0]}${secondLterms} / ${voyage.route.dischargeTermsArray[0]}${secondDterms}`}</Typography>
              <Typography className={classes.tooltipText}>{`Bid: ${formatNumber({ number: voyage.originalBid })} (${voyage.client ? voyage.client.name : 'None'}) / Offer: ${formatNumber({ number: voyage.originalOffer })} (${voyage.owner ? voyage.owner.ownerName : 'None'})`}</Typography>
              <Typography className={classes.tooltipText}>{`System Rate: ${formatNumber({ number: voyage.route.routeResultsRate * (1 + (voyage.adcome / 100)) })}`}</Typography>
              <Typography className={classes.tooltipText}>{`Original Rate: ${voyage.rateAtCreation ? formatNumber({ number: voyage.rateAtCreation }) : 'Not recorded'}   ${getFormattedDate(voyage.createdAt, 'Europe/Copenhagen')}`}</Typography>
              <Typography className={classes.tooltipText}>{`Base Difference: ${baseDifference ? formatNumber({ number: baseDifference, format: '0.0' }) : 0} Saved: ${voyage.savedBase ? voyage.savedBase : 'Not recorded'}`}</Typography>
              <Typography className={classes.tooltipText}>{`Fuel Difference: ${fuelDifference ? formatNumber({ number: fuelDifference, format: '0.0' }) : 0} Saved: ${voyage.savedFuelPrice ? voyage.savedFuelPrice : 'Not recorded'}`}</Typography>
              <Typography className={classes.tooltipText}>{`Saved Absolute: ${voyage.savedAbsolute !== undefined ? formatNumber({ number: voyage.savedAbsolute, format: '0.0'}) : 'Not recorded'}`}</Typography>
              <Typography className={classes.tooltipText}>{`Saved Percent: ${voyage.savedPercent !== undefined ? formatNumber({ number: voyage.savedPercent, format: '0,0'}) : 'Not recorded'}`}</Typography>
              <Typography className={classes.tooltipText}>{`(${voyage.adcome}%)`}</Typography>
              {/* {voyage.route.percentages.length > 0 && <Typography className={classes.tooltipText}>{`Total Spread ${formatNumber({ number: voyage.route.spreadPercentage, format: '0,0' })}`}</Typography>}
              {voyage.route.percentages.length > 0 && <Typography className={classes.tooltipText}>{`Spreads ${spreadStrings}`}</Typography>} */}
              <div style={{ margin: '5px' }}> </div>
              <Typography className={classes.tooltipText}>{`Last updated: ${voyage.createdBy.name} - ${getFormattedDate(voyage.updatedAt, 'Europe/Copenhagen')}`}</Typography>
              {}
            </Grid>
          )
        }
        return (
          <Grid className={classes.tooltipArrange} style={{ width: 300 }} />
        )
      }}
    </Query>
  )
}

const OfferInformation = props => {
  const { adcomValue, handleVoyageOpen, voyage, classes, curvesHandler, systemSetting, posdateIndex, index, fuelPrice } = props
  const hours = differenceInHours(new Date(Date.now()), new Date(voyage.updatedAt))

  const lower = systemSetting && systemSetting.offerAgeYoung
  const medium = systemSetting && systemSetting.offerAgeMedium
  const upper = systemSetting && systemSetting.offerAgeOld

  let green = false
  let orange = false
  let red = false

  const baseDifference = curvesHandler.body.base[posdateIndex] - voyage.savedBase

  const differencePercentage = (Math.abs(baseDifference) * 100) / voyage.savedBase

  green = differencePercentage <= lower
  orange = lower <= differencePercentage && differencePercentage <= medium
  red = medium <= differencePercentage && differencePercentage <= upper

  const fuelDifference = fuelPrice - voyage.savedFuelPrice

  return (
    <HtmlTooltip
      background={{
        color: (green) ? '#D0FFC7' : (orange) ? '#FFF3C7' : (red) ? '#FFC7C7' : ''
      }}
      border={{
        color: (green)
          ? 'rgba(0, 150, 0, 0.3)'
          : (orange)
          ? 'rgba(255, 165, 0, 0.5)'
          : (red)
          ? 'rgba(150, 0, 0, .3)'
          : ''
      }}
      interactive
      title={
        <OfferInformationQuery
          id={voyage.id}
          classes={classes}
          baseDifference={baseDifference}
          fuelDifference={fuelDifference}
        />
      }
    >
      <TableCell
        style={{ borderLeft: '1px solid rgba(0,0,0,0.5)' }}
        align='center'
        className={{
          [classes.greenVoyage]: green,
          [classes.orangeVoyage]: orange,
          [classes.redVoyage]: red
        }}
        onClick={() => handleVoyageOpen(voyage)}
      >
        <Grid className={classes.offerBox}>
          {
            (voyage.bid && voyage.bid > 0)
              ? <Typography style={{ lineHeight: 1.7 }} className={clsx([classes.bid, classes.tableFont])}>
                {(typeof voyage.bid === 'number') && `${formatNumber({ number: voyage.bid * (1 + adcomValue / 100), format: '$0,0.00' })} `}
              </Typography> : null
          }
          <Typography
            style={{ lineHeight: 1.7 }}
            className={classes.tableFont}
          >
            {`${formatNumber({ number: ((voyage.offer) * (1 + ((adcomValue / 100)))) })} - ${voyage.owner ? voyage.owner.ownerName : (voyage.user) ? voyage.user.name : 'No Owner'}`}
          </Typography>
        </Grid>
      </TableCell>
    </HtmlTooltip>
    
  )

}

OfferInformation.propTypes = {

}

export default OfferInformation
