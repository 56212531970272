export default {
  logoutButton: 'Logout',
  logoSrc: '',
  title: 'Freights',
  menuItems: [
    {
      link: '/routes',
      text: 'Routes'
    },
    {
      link: '/ports',
      text: 'Ports'
    },
    {
      link: '/forward-curves',
      text: 'Forward Curve'
    },
    {
      link: '/commodities',
      text: 'Commodity'
    },
    {
      link: '/spot-indexes',
      text: 'Spot index'
    },
    {
      link: '/spot-tc-spreads',
      text: 'Spot TC Spread'
    },
    {
      link: '/vessels',
      text: 'Vessels'
    },
    {
      link: '/time-countings',
      text: 'Time Counting'
    },
    {
      link: '/forward-validations',
      text: 'Forward Validation'
    },
    {
      link: '/position-dates',
      text: 'Position Dates'
    },
    {
      link: '/distances',
      text: 'Distance'
    },
    {
      link: '/ship-sectors',
      text: 'Ship Sectors'
    },
    {
      link: '/users',
      text: 'Users'
    },
    {
      link: '/zones',
      text: 'Zones'
    },
    {
      link: '/cargo-workings',
      text: 'CargoWorking'
    }
  ]
}
