// React
import React from 'react'
// Material UI
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
// Prop types
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: theme.typography.generalFontSize - 3,
    marginLeft: 1
  }
}))

const TextError = props => {
  const classes = useStyles()
  const { error = '', fontSize } = props

  return (
    <Box
      alignSelf='flex-start'
      ml={2}
      className={classes.root}
      mb={1}
    >
      <Typography
        color='error' 
        variant='inherit'
        >
        {error}
      </Typography>
    </Box>
  )
}

TextError.propTypes = {
  error: PropTypes.string
}

export default TextError
