import { CREATE_FORWARDCURVE_INFORMATION } from '../Queries/Queries'

const breadCrumbs = [
  {
    link: '/forward-curves',
    name: 'Forward Curves'
  }
]

const module = {
  name: 'Forward Curve'
}

const refetchQueries = [{
  query: CREATE_FORWARDCURVE_INFORMATION
}]

export const show = {
  module,
  createButton: {
    name: 'Create Forward Curve',
    link: '/create-forward-curve'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'forwardCurveName', name: 'forwardCurveName' },
      { key: 'spotIndex', name: 'spotIndex' },
      { key: 'forwardCommodity', name: 'forwardCommodity' },
      { key: 'coreRoute', name: 'route' }
    ],
    rows: 3
  },
  update: {
    link: '/update-forward-curve'
  }
}

export const upsert = {
  module,
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Forward Curve created',
      path: '/forward-curves'
    },
    submitButton: 'Create Forward Curve'
  },
  emptyObjects: {
    commodity: {
      name: 'Commodity',
      refetchQueries
    },
    spotIndex: {
      name: 'spot index',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Forward Curve updated',
      path: '/forward-curves'
    },
    submitButton: 'Update Forward Curve'
  }
}
