// React
import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  text: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1,
    textTransform: 'capitalize'
  }
}))

const RootH4 = props => {
  const classes = useStyles()
  const { children, className, justify, textAlign, ...rest } = props
  return (
    <Box
      justifyContent={justify}
      display='flex'
      alignItems='center'
      textAlign={textAlign}
      flexDirection='column'
    >
      <Typography
        {...rest}
        className={clsx(classes.text, {
          [className]: className
        })}
      >
        {children}
      </Typography>
    </Box>
  )
}

RootH4.defaultProps = {
  justify: 'flex-start',
  textAlign: 'left'
}

RootH4.propTypes = {
  className: PropTypes.string,
  justify: PropTypes.string.isRequired,
  textAlign: PropTypes.string.isRequired
}

export default RootH4