export const versions = [
  {
    version: '1.2.19',
    date: new Date(2022, 9, 1),
    fixes: [
      'Solved the server memory problem.',
      'Backed up all the Historic Route Results registries in a separate space.',
      'Every week results will be backed up in a separate space and will me removed from the server.'
    ]
  },
  {
    version: '1.2.18',
    date: new Date(2022, 6, 14),
    fixes: [
      'Validation module has been restructured and optimized, and now has a quicker and better performance.',
      'The Voyage Offer dialog was optimized and it is loading quicker.',
      'Voyage Offers now appear and disappear  live as they are created, deleted or modified.',
      'Solved sort problem in CargoWorking table.',
      'Tweaked the design of Validation.'
    ]
  },
  {
    version: '1.2.17',
    date: new Date(2022, 3, 6),
    fixes: [
      'Added Redelivery Port to Client Results & Settings.',
      'Sorted Voyage Offers from newst to oldest.',
      'Removed space between Ballast & Laden in Vessel.',
      'Developed the logic to save Historic Validation data.',
      'Wrote a script to fetch Validation data from previous backups.',
      'Now Voyage save the Absolute and Percent at creation.',
      'Defaulted Adcom in Cargo Working when changing clients.',
      'Repaired System Rate in Voyage Offer hover menu.'
    ]
  },
  {
    version: '1.2.16',
    date: new Date(2022, 2, 23),
    fixes: [
      'Added heat map functionality to Client Results.',
      'Heat map values can be modified in System Settings.',
      'Fixed math bug in Aps rate in Calculation Page.',
      'Fixed bug when changing Result in Validation Cards.',
      'Fixed bug when tabbing on Result in Validaion Cards.',
      'Corrected logic of saving Forward Curves in Spot Spreads.',
      'Made Spot Spread drop down show the ones linked to the Forward Curve of the route.',
      'Sorted Commodities drop down in Update Route.'
    ]
  },
  {
    version: '1.2.15',
    date: new Date(2022, 1, 18),
    fixes: [
      'Added APS inputs to the Validation Cards.',
      'Made TCE`s 1st position editable.',
      'Fixed rerender bug when updating result 1st position in Validation and TC Input.',
      'Now when selecting Forward Curves within the drop downs of the Spot TC Spread table, the spread will be connected to the appropriate routes.',
      'Client Output: now you can select which Via Laden port you wish to see in any route.'
    ]
  },
  {
    version: '1.2.14',
    date: new Date(2022, 1, 7),
    fixes: [
      'Now you can create API Keys from System Settings to verify you have the right to update futures with a Python script.',
      'Now every Commodity tab in Cnf has a Mapping form in which you can select which future from Reuters will appear in which Position Date.',
      'Now you can update the Fob Futures of every client and commodity by sending a request with our function updateFobFuturesFor() in a Python script.',
      'Fob Futures, Fob Premiums and Fob Imports will roll every new month.',
      'The Historic Fob data will be saved every month everyday only if it has changed.',
      'You can create and edit Mappings for specific routes.',
    ]
  },
  {
    version: '1.2.13',
    date: new Date(2021, 11, 14),
    fixes: [
      'Created the new Voyage Offer aging system.',
      'Created the System Settings page, where you can now select the age ranges for all Voyage Offers.',
      'Whenever the Voyage Offer is due (its Position Date is due) it is passed to hidden Historic Table.',
      'Due Voyage Offers can no longer be posted back in. They will be stored in the Historic Table.',
      'Displayed the time elapsed from the last time the futures where changed in Validation Page.',
      'Displayed the quarter separators in Validation.',
      'Now when you update a parent curve, the child curve is saved as well.',
      'Fixed Owner duplication error when creating a Cargo Offer.'
    ]
  },
  {
    version: '1.2.12',
    date: new Date(2021, 10, 29),
    fixes: [
      'Voyage Offer: Made cursor begin at Owner. Also, now you can Create the offer by pressing ENTER in any input (make sure every field is filled).',
      'Enabled Absolute and Percent inputs for child curves in 1st position, and removed child curves in TC Input. If you change any of those input the result must change. If you change the parent result, the result of the child must change accordingly.',
      'When the month rolls, Absolute and Percent roll up one position. Leaving a 0 in the las position.',
      'Daily and Weekly changes last position should be right now. Every time the month rolls, the changes in las position will be equal to the Freight due to no previous register of that new month.',
      'Rounded Freight and Changes to multiples of 0.25 in Client Results Vertical. Test by changing Adcom from 0 to any other number.',
      'Sorted both Default and Historic tables in CargoWorking both by user and by date. Logged user should appear on top.',
      'To avoid creating multiple Owners through CargoWorking, we crated a hidden code name of them. This code name is their name but lowercased and trimmed (no spaces at the end). To test this try creating two Cargo Offers with the same owner name, but one lowercase and one uppercased. Only one should be created.',
      'Now we remove Voyage Offers from Validation once they are more than a week old.',
      'Sorted Spot TC Spread dropdown in Route Page.'
    ]
  },
  {
    version: '1.2.11',
    date: new Date(2021, 10, 8),
    fixes: [
      'Beautified Login page.',
      'Created Client Routes page with a table showing the freight of all the routes linked to the client.',
      'Allowed clients to export PDF, CSV and Excel files.',
      'Created the CNF interface that contains inputs for the Fob Futures, Fob Premiums and Imports for every load and discharge country linked to the client.',
      'Instead of the Freight rate, we display the sum of the Fob parameters, Import and Freight rate.',
      'Created a retractable pannel that shows the values of the parameters of every result.'
    ]
  },
  {
    version: '1.2.10',
    date: new Date(2021, 9, 6),
    fixes: [
      'Position Dates now will roll automatically every 1st of every month at midnight.',
      'When it rolls, 14 new Position Dates will be created.',
      'Every active Voyage Offer and Cargo Working will be connected to its correspondent Position Date every month.',
      'Position Dates now have Date of Reference and Date of Creation, which will allow us to do analytics in the future.',
      'Route Results are now connected to Position Dates through their id, instead of saving the name string, which will also help with analytics.',
      'Every day at midnight Route Results will be passed in to a new table where type is completely rigid (since info was saved in JSON format before) ,it will make it much easier to query for analytics.',
      `If a Cargo Working is overdue, it will automatically move to Historic with "Dead" Status.`,
      'Cargo Working is sorted by User and by Date, placing the logged user on top.',
      `Validation: Offers' on hover pop-up has been changed to point out the System Rate and the Original Rate.`,
      'Offers` on hover pop-up now shows the original Bid, instead of the bid backward calculation. ',
      `Create Cargo Working now saves the bid backward calculation as "0" if "0" was typed in.`,
      'Create Route: TC Spot Spread drop-down has been fixed.',
      'Validation/TC Input: now child curves inherit the 1st Position`s result from the parent curve; but it can be overrided by manually typing in the result in TC Input.',
      'Validation: Spot Spread table has been widened and character limit increased so the spread names are easier to read. Also we added a full spread name on hover message for names that are too long.'
    ]
  },
  {
    version: '1.2.9',
    date: new Date(2021, 8, 13),
    fixes: [
      'The Spot TC Spread table rowHeight was ammended along with the Forward Curve dropdown in it.',
      'Sorted Commodities table by cargo and by name.',
      'Made fields in Calc Page recalculate onBlur, should also work from phone.',
      'Fixed flex error in Client Results that made impossible to change Adcom from mobile. Also made Adcom field not to show 0.00 in positions when empty.',
      'TC Input: Implemented partial fake Live in TC Input. Only Result field`s state resets for a couple of seconds when changed. Faking Live there would make Validation not respond to the pubsub when opened as side tab in the browser.',
      'Fixed Delta Daily and Delta Weekly. The math with the adcom was wrong.',
      'Fixed Port Cost area in Update Port. Line was jumping when deleting any of the two fields. Made sure it doesn`t happen anymore and look symmetrical.',
      'CargoWorking: sorted CargoWorkings by User, Account and Date of update.',
      'Added new column in Users table. This column is for the Adcom and it is editable. That Adcom is the same Adcom of the User Settings and if you change it the Adcom in Client Results will too.'
    ]
  },
  {
    version: '1.2.8',
    date: new Date(2021, 7, 2),
    fixes: [
      'Calc Page: Fixed Position Dates Results table.',
      'Calc Page: Made every row have the same height',
      'Calc Page: removed DOP and APS net rates to only show rates after adcomm.',
      'Implemented some automatic tests for Calc Page for easier maintenance (this does not affect any functionality, it`s merely a maintenance tool to make sure everything works as expected and developers can spot bugs easily).',
      'Changed Position Date Names input to dropdowns (this is temporary, we`ll change that functionality to update automatically in a later version).'
    ]
  },
  {
    version: '1.2.7',
    date: new Date(2021, 6, 18),
    fixes: [
      'Able to link spot tc spreads with forwardCurves through spread table dropdown.',
      'Render the spreads linked to each curve in Validation.',
      'Amend TCE + Value to TCE and place under TCE above',
      'Remove shadows from validation spread tables',
      'Amend Value to Absolute and place under the on above',
      'Remove scroll unless needed',
      'Remove bottom empty line',
      'Solved error when saving card.'
    ]
  },
  {
    version: '1.2.6',
    date: new Date(2021, 6, 10),
    fixes: [
      'Validation Cards were redesigned and now they show the Spot Spreads that are relevant to it`s particular Validation Curve.',
      'The Spot Spread table now shows the Validation Curves that are relevant to any particular Spot Spread.',
      'Added tab names to every page in the system.',
      'Fixed responsive behavior of Valdation`s Appbar.',
      'Reformatted Voyage Offers` pop up and added the system`s rate of that particular Validation Route at the moment of the offer`s creation.'
    ]
  },
  {
    version: '1.2.5',
    date: new Date(2021, 6, 2),
    fixes: [
      'Calculation Page: All top left fields modify every vessel and every position!',
      'Link Curves: Gave feedback on which curve is connected to which in Validation. Also ammended order of FC dropdown and added label to checkbox.'
    ]
  },
  {
    version: '1.2.4',
    date: new Date(2021, 5, 25),
    fixes: [
      'Implemented an algorithm to update all dependcies of a Validation Block! Now when you change data from a parent block, that same change should be made throughout all its family tree.',
      'Fixed Lock logic to work with the linking dynamic.',
      'Client Results user dropdown is ordered alphabetically now.',
      'Inserted a Lock Icon so we can know when a block is locked.'
    ]
  },
  {
    version: '1.2.3',
    date: new Date(2021, 5, 20),
    fixes: [
      'Now you can link a Validation Block with another one. To do this, update a Forward Curve and click on the check box at the bottom. That will allow you to select another Forward Curve instead of a Commodity. Then go to Validation and update the Parent Block. All of the Child Blocks should change accordingly!',
      'Adding a non-existent owner in Cargo Offers now only creates one of each.'
    ]
  },
  {
    version: '1.2.2',
    date: new Date(2021, 5, 9),
    fixes: [
      'Repaired quarter separators in PDF Export.',
      'Stem, Tolerance, Brokerage, Commodity, Load info, Discharge info, FOID Fuel Price, MGO Fuel Price, and all Port Costs should affect Aps rate.',
      'Cargo Intake affects Aps rate.',
      'Added DOP BB to Calc page.',
      'Deleted Adcom rate, and made Rate dependent of Adcom (top left).',
      'Fixed ENTER and TAB dynamic in Calculation Page. No need to reset fields anymore to recalculate with the same figures.'
    ]
  },
  {
    version: '1.2.1',
    date: new Date(2021, 5, 2),
    fixes: [
      'Copy functionality added to Summary.',
      'Fix load time in cargo working.',
      'Export client results to excel file.',
      'Disconnected and connected corresponding routes when updating forwardCurves in Spot TC Spreads` dropdowns'
    ]
  }
]
