const breadCrumbs = [
  {
    link: '/position-dates',
    name: 'Position Date'
  }
]

export const show = {
  createButton: {
    name: 'Create position date',
    link: '/create-position-date'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'positionId', name: 'positionId' },
      { key: 'positionDateName', name: 'positionDateName' }
    ],
    rows: 3
  },
  module: {
    name: 'Position date'
  },
  update: {
    link: '/update-position-date'
  }
}

export const upsert = {
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Position date created',
      path: '/position-dates'
    },
    submitButton: 'Create Position Date'
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Position date updated',
      path: '/position-dates'
    },
    submitButton: 'Update Position Date'
  }
}
