// React
import React, { useContext } from 'react'
import { UserContext } from '../App/AppContext'
import PropTypes from 'prop-types'
// Material UI
import Box from '@material-ui/core/Box'
import Cancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
// Utils
import RootButton from '../Utils/RootButton'

const DeleteModal = (props) => {
  const {
    input,
    mutation,
    name,
    open,
    deleteCalcPageLink,
    refetchQueries,
    setOpen
  } = props

  const { user, setUser } = useContext(UserContext)

  const doMutation = ({ mutation }) => {
    mutation()
    setOpen(false)
  }

  const onCompletedObject = {
    title: 'Success',
    message: `${name} deleted`,
    path: null
  }

  const errorObject = {
    title: 'Error',
    message: `There was an error while deleting this ${name.toLowerCase()}`
  }

  return (
    <MutationHelper
      mutation={mutation}
      variables={{ input }}
      onCompletedObject={onCompletedObject}
      errorObject={errorObject}
      refetchQueries={refetchQueries}
    >
      {(mutation, result) => {
        return (
          <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby={`Delete ${name}`}>
            <Box display='flex' justifyContent='flex-end'>
              <DialogActions>
                <IconButton onClick={() => setOpen(false)}>
                  <Cancel color='disabled' />
                </IconButton>
              </DialogActions>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='center'>
              <DialogTitle color='primary' id={`Delete ${name.toLowerCase()}`} disableTypography>
                <Typography variant='h6' color='primary'>Confirm</Typography>
              </DialogTitle>
              <DialogContent>Are you sure you want to delete this {name.toLowerCase()}?</DialogContent>
              <DialogActions>
                <RootButton
                  disabled={result.loading}
                  size='large'
                  onClick={() => {
                    if (deleteCalcPageLink && (input.id === user.calc.routeId)) {
                      setUser({ type: 'add-calc-info', value: { routeIndex: null, routeId: null, routeName: null } })
                    }
                    doMutation({ mutation })
                  }}
                >
                  Delete
                </RootButton>
              </DialogActions>
            </Box>
          </Dialog>
        )
      }}
    </MutationHelper>
  )
}

DeleteModal.defaultProps = {
  refetchQueries: []
}

DeleteModal.propTypes = {
  input: PropTypes.object.isRequired,
  mutation: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  refetchQueries: PropTypes.array.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default DeleteModal
