import React, { useState, useCallback, useRef, useEffect } from 'react'
import { withRouter, useHistory } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Queries
import { GET_CARGOWORKINGS } from '../Queries/Queries'
import { show as content } from './CargoWorkingsContent'
import { useQuery, useMutation } from 'react-apollo'

// Mutations

import Grid from '@material-ui/core/Grid'
// Styles
import Box from '@material-ui/core/Box'
// import Card from '@material-ui/core/Card'
// import CardContent from '@material-ui/core/CardContent'
import Switch from '@material-ui/core/Switch'
import AddIconButton from '../Utils/AddIconButton'
// Mutation
import MutationHelper from '../Mutations/MutationHelper'
import { UPDATE_CARGO_OFFER } from '../Mutations/Mutations'
// Table
import ShowCargoWorkingAgGrid from './ShowCargoWorkingAgGrid'
import _ from 'lodash'

const reducer = (state, action) => {
  switch (action.type) {
    case 'manual-update': {

      // To-do: modify so it pushes the new offer with the data

      const result = state.map((item) => {
        if (item.cargoId === action.value.cargoId) {
          if (item.offerId === action.value.offerId) {
            return {
              ...item,
              bid: action.value.cargo.bid,
              laycan: action.value.cargo.laycan,
              offer: action.value.offer.offer,
              port: action.value.port.portName,
              vessel: action.value.vessel.vesselName,
              owner: action.value.offer.owner.ownerName,
              offerDate: action.value.cargo.updatedAt,
              comments: action.value.comments,
              deleted: action.value.deleted
            }
          } else {
            return {
              ...item
            }
          }
        } else {
          return {
            ...item
          }
        }
      })
      return result
    }
    case 'addAll': {
      const result = action.value.filter((row) => !row.hidden)
      return result
    }
    case 'expand-less': {
      let count = 0
      const newState = []
      for (let i = 0; i < action.value.length; i++) {
        let found = false
        for (let index = 0; index < action.visibleRows.length; index++) {
          if (
            action.value[i].offerId === action.visibleRows[index].offerId &&
            action.value[i].cargoId === action.visibleRows[index].cargoId
          ) {
            newState.push({ ...action.visibleRows[index] })
            found = true
          }
        }
        if (!found) {
          newState.push({ ...action.value[i] })
        }
      }
      const result = newState.map((item) => {
        if (item.cargoId !== action.cargoId) {
          return {
            ...item
          }
        } else {
          if (count < 3) {
            count++
            if (item.offerNumber > 0) {
              return {
                ...item,
                expand: false,
                hidden: false
              }
            } else {
              return {
                ...item,
                hidden: false
              }
            }
          } else {
            count++
            return {
              ...item,
              hidden: true
            }
          }
        }
      })
      return result
    }
    case 'expand-more': {
      const newState = []
      for (let i = 0; i < action.value.length; i++) {
        let found = false
        for (let index = 0; index < action.visibleRows.length; index++) {
          if (
            action.value[i].offerId === action.visibleRows[index].offerId &&
            action.value[i].cargoId === action.visibleRows[index].cargoId
          ) {
            newState.push({ ...action.visibleRows[index] })
            found = true
          }
        }
        if (!found) {
          newState.push({ ...action.value[i] })
        }
      }
      const result = []
      newState.forEach((item, i) => {
        if (item.cargoId === action.cargoId) {
          result.push({
            ...item,
            expand: true,
            hidden: false
          })
        } else {
          result.push({
            ...item
          })
        }
      })
      return result
    }
    case 'expand': {
      const result = []
      action.value.forEach((item, i) => {
        if (item.cargoId === action.cargoId) {
          result.push({
            ...item,
            expand: true,
            hidden: false
          })
        } else {
          if (!item.hidden) {
            result.push({
              ...item
            })
          }
        }
      })
      return result
    }
    case 'add': {
      const newState = []
      for (let i = 0; i < state.length; i++) {
        let found = false
        for (let index = 0; index < action.visibleRows.length; index++) {
          if (
            state[i].offerId === action.visibleRows[index].offerId &&
            state[i].cargoId === action.visibleRows[index].cargoId
          ) {
            newState.push({ ...action.visibleRows[index] })
            found = true
          }
        }
        if (!found) {
          newState.push({ ...state[i] })
        }
      }
      const result = []
      let foundCargoIndex = 0
      for (let i = 0; i < newState.length; i++) {
        const item = newState[i]
        if (item.cargoId !== action.mainRow) {
          result.push({
            ...item
          })
        } else {
          // Here we get the index of the cargo we are going to add the row to
          if (item.offerNumber > 0) {
            foundCargoIndex = i
          }
          // IF the base cargo has only one offer number it means that it may have only one offer or none
          if (item.offerNumber === 1) {
            // If it has an offer, add the item meaning the current offer and then add the new offer
            if (item.offerId) {
              result.push({
                ...item
              })
              result.push({
                cargoId: action.mainRow,
                offerId: (action.when === 'add-offer')
                  ? action.data.createCargoOfferWithoutOffer.cargoOffer.id
                  : (action.when === 'add-after-delete')
                    ? action.data.deleteCargoOffer.cargoOffer.id
                    : ''
              })

              result[foundCargoIndex].offerNumber += 1
              // If not, add the new offer id in the new item
            } else {
              result.push({
                ...item,
                offerId: (action.when === 'add-offer')
                  ? action.data.createCargoOfferWithoutOffer.cargoOffer.id
                  : (action.when === 'add-after-delete')
                    ? action.data.deleteCargoOffer.cargoOffer.id
                    : '',
                offer: '',
                owner: '',
                port: '',
                vessel: '',
                offerDate: '',
                comments: ''
              })
            }
            // if item.offerId === action.row that means we found the offer above the new one
            // If offer number is greater than one it means that we have at least two offers, if it is not expanded
          } else if (
            item.offerId === action.row &&
            newState[foundCargoIndex].offerNumber > 1 &&
            !newState[foundCargoIndex].expand
          ) {
            // If it is not expanded and we have only 2 then add the item and the new offer bewlow
            if (newState[foundCargoIndex].offerNumber < 3) {
              result.push({
                ...item
              })
              result.push({
                cargoId: action.mainRow,
                offerId: action.data.createCargoOfferWithoutOffer.cargoOffer.id
              })
              result[foundCargoIndex].offerNumber += 1
              // If it is not below 3 it means tha
            } else {
              result.push({
                ...item
              })
              result.push({
                cargoId: action.mainRow,
                offerId: action.data.createCargoOfferWithoutOffer.cargoOffer.id
              })
              result[foundCargoIndex].expand = true
              const currentShownCargoId = newState[foundCargoIndex].cargoId
              for (let j = foundCargoIndex; j < newState.length; j++) {
                if (newState[j].cargoId === currentShownCargoId) {
                  newState[j].hidden = false
                }
              }
              result[foundCargoIndex].offerNumber += 1
            }
            console.log('result not expanded', { result: result.filter(r => ["FIRM", "PRICING", "SUB-SALE"].includes(r.status)) })
          } else if (
            item.offerId === action.row &&
            newState[foundCargoIndex].offerNumber > 1 &&
            newState[foundCargoIndex].expand
          ) {
            result.push({
              ...item
            })
            result.push({
              cargoId: action.mainRow,
              offerId: action.data.createCargoOfferWithoutOffer.cargoOffer.id
            })
            result[foundCargoIndex].offerNumber += 1
            console.log('cargoOffer: ', { offer: action.data.createCargoOfferWithoutOffer.cargoOffer })
            console.log('result expanded', { result: result.filter(r => ["FIRM", "PRICING", "SUB-SALE"].includes(r.status)) })

          } else {
            result.push({
              ...item
            })
          }
        }
      }
      return result
    }
    case 'delete': {
      // If the way we display the data changes, this algorithm must be changed too.
      const newState = []
      for (let i = 0; i < state.length; i++) {
        let found = false
        for (let index = 0; index < action.visibleRows.length; index++) {
          if (
            state[i].offerId === action.visibleRows[index].offerId &&
            state[i].cargoId === action.visibleRows[index].cargoId
          ) {
            newState.push({ ...action.visibleRows[index] })
            found = true
          }
        }
        if (!found) {
          newState.push({ ...state[i] })
        }
      }
      let result = newState.map((item) => {
        return { ...item }
      })
      let deleteRow = true
      let oneRowCargoIndex = null

      for (let i = 0; i < result.length; i++) {
        if (
          result[i].cargoId === action.value.cargo &&
          result[i].offerNumber > 1
        ) {
          result[i].offerNumber -= 1
          break
        } else if (
          result[i].cargoId === action.value.cargo &&
          result[i].offerNumber === 1
        ) {
          deleteRow = false
          oneRowCargoIndex = i
        }
      }

      if (deleteRow) {
        const correctIteration = []
        let cargoIndex = 0
        let next = false
        for (let i = 0; i < result.length; i++) {
          if (result[i].offerNumber > 0) {
            cargoIndex = i
          }
          if (result[i].offerId !== action.value.offer) {
            if (next) {
              correctIteration.push({
                ...result[cargoIndex],
                offer: result[i].offer,
                owner: result[i].owner,
                port: result[i].port,
                vessel: result[i].vessel,
                offerDate: result[i].offerDate,
                comments: result[i].comments,
                offerId: result[i].offerId
              })
            } else {
              correctIteration.push({
                ...result[i]
              })
            }
            next = false
          } else {
            if (i < result.length - 1) {
              for (let h = i + 1; h < result.length; h++) {
                if (result[h].cargoId === result[i].cargoId) {
                  if (result[h].hidden) {
                    result[h].hidden = false
                    break
                  }
                } else {
                  break
                }
              }
            }
            if (
              result[cargoIndex].offerNumber <= 3 &&
              result[cargoIndex].expanded
            ) {
              result[cargoIndex].expanded = false
            }
            if (result[i].offerNumber > 0) {
              next = true
            }
          }
        }
        result = correctIteration
      } else {
        result[oneRowCargoIndex].offer = null
        result[oneRowCargoIndex].owner = null
        result[oneRowCargoIndex].port = null
        result[oneRowCargoIndex].vessel = null
        result[oneRowCargoIndex].offerDate = null
        result[oneRowCargoIndex].comments = null
        result[oneRowCargoIndex].offerId = null
      }
      return result
    }
    case 'initital':
      return action.value
    case 'setOnComplete':
      return state.map((cargoOffer) => {
        if (cargoOffer.offerId === action.data.id) {
          return {
            ...cargoOffer,
            owner: action.data.offer.owner.ownerName,
            vessel: {
              name: action.data.vessel.vesselName,
              id: action.data.vessel.id
            },
            port: {
              name: action.data.port.portName,
              id: action.data.port.id
            }
          }
        } else return cargoOffer
      })

    default:
      return state
  }
}

const ShowCargoWorkings = (props) => {
  const [vertical, setVertical] = React.useState(false)
  const historicArray = ['DEAD', 'OTHERS', 'US', 'FAILED']
  const history = useHistory()
  const [cargoWorkings, setCargoWorkings] = React.useReducer(reducer, [])
  const [localOwner, setLocalOwner] = useState(null)
  const [localVessel, setLocalVessel] = useState(null)
  const [localPort, setLocalPort] = useState(null)
  const [currentRowData, setCurrentRowData] = useState(null)
  const [currentNode, setCurrentNode] = useState(null)
  const [offerrMessage, setOfferMessage] = React.useState({
    open: false,
    message: ''
  })
  const globalRef = useRef('')
  const ownerRef = useRef(localOwner)
  const rowRef = useRef(currentRowData)
  const vesselRef = useRef(localVessel)
  const portRef = useRef(localPort)

  const [updateCargoWorkingOffers] = useMutation(UPDATE_CARGO_OFFER, {
    onCompleted(data) {
      setCargoWorkings({
        type: 'manual-update',
        value: data.updateCargoOffer
      })
    },
    onError (error) {
      setOfferMessage({
        open: true,
        message: error?.message?.split(':')[1]
      })
    }
  })

  const handleLocalOwner = (newOwner) => {
    if (
      newOwner !== null &&
      newOwner !== undefined &&
      typeof newOwner === 'object'
    ) {
      setLocalOwner((prevState) => {
        const newState = { ...newOwner }
        return newState
      })
      ownerRef.current = newOwner
      globalRef.current = newOwner
      globalRef.current.type = 'owner'
    } else if (typeof newOwner === 'string') {
      const ownerPrototype = {
        id: newOwner,
        name: newOwner,
        type: 'owner'
      }
      setLocalOwner(ownerPrototype)
      ownerRef.current = ownerPrototype
      globalRef.current = ownerPrototype
    }
  }

  const handleLocalVesssel = (newVessel) => {
    if (
      newVessel !== null &&
      newVessel !== undefined &&
      typeof newVessel === 'object'
    ) {
      setLocalVessel((prevState) => {
        const newState = { ...newVessel }
        return newState
      })
      vesselRef.current = newVessel
      globalRef.current = newVessel
      globalRef.current.type = 'vessel'
    } else if (typeof newVessel === 'string') {
      const vesselPrototype = {
        id: newVessel,
        name: newVessel,
        type: 'vessel'
      }

      setLocalVessel(vesselPrototype)
      vesselRef.current = vesselPrototype
      globalRef.current = vesselPrototype
    }
  }

  const handleLocalPort = (newPort) => {
    if (
      newPort !== null &&
      newPort !== undefined &&
      typeof newPort === 'object'
    ) {
      setLocalPort((prevState) => {
        const newState = { ...newPort }
        return newState
      })
      portRef.current = newPort
      globalRef.current = newPort
      globalRef.current.type = 'port'
    } else if (typeof newPort === 'string') {
      const portPrototype = {
        id: newPort,
        name: newPort,
        type: 'port'
      }

      setLocalPort(portPrototype)
      portRef.current = portPrototype
      globalRef.current = portPrototype
    }
  }

  const handleCurrentRowData = (newRowData) => {
    if (
      newRowData !== null &&
      newRowData !== undefined &&
      typeof newRowData === 'object'
    ) {
      setCurrentRowData((prevState) => {
        const newState = { ...newRowData }
        return newState
      })
      rowRef.current = newRowData
    }
  }

  const handleTabMutation = () => {
    if (ownerRef.current && rowRef.current) {
      let {
        owner,
        vessel,
        port,
        offerId,
        offerDate,
        comments,
        offer,
        deleted
      } = rowRef.current

      if (ownerRef.current?.id) {
        owner = ownerRef.current.id
      }
      if (vessel?.id) {
        vessel = vessel.id
      }
      if (port?.id) {
        port = port.id
      }

      const offerToUpdate = {
        id: offerId,
        offer: offer,
        port,
        vessel,
        owner,
        date: offerDate ? new Date(offerDate) : null,
        comments: comments || null,
        toDelete: deleted || false
      }

      if (offer) {
        updateCargoWorkingOffers({
          variables: {
            input: offerToUpdate
          }
        })
      }
    }
  }

  const handleTabMutationVessel = () => {
    if (vesselRef.current && rowRef.current) {
      let {
        owner,
        vessel,
        port,
        offerId,
        offerDate,
        comments,
        offer,
        deleted
      } = rowRef.current
      if (owner.id) {
        owner = owner.id
      }
      if (vesselRef?.current?.id) {
        vessel = vesselRef.current.id
      }
      if (port?.id) {
        port = port.id
      }

      const offerToUpdate = {
        id: offerId,
        offer: offer,
        port,
        vessel,
        owner,
        date: offerDate ? new Date(offerDate) : null,
        comments: comments || null,
        toDelete: deleted || false
      }

      if (offer) {
        updateCargoWorkingOffers({
          variables: {
            input: offerToUpdate
          }
        })
      }
    }
  }

  const handleTabMutationPort = () => {
    if (portRef.current && rowRef.current) {
      let {
        owner,
        vessel,
        port,
        offerId,
        offerDate,
        comments,
        offer,
        deleted
      } = rowRef.current
      if (owner.id) {
        owner = owner.id
      }
      if (vessel?.id) {
        vessel = vessel.id
      }
      if (portRef?.current?.id) {
        port = portRef?.current?.id
      }

      const offerToUpdate = {
        id: offerId,
        offer: offer,
        port,
        vessel,
        owner,
        date: offerDate ? new Date(offerDate) : null,
        comments: comments || null,
        toDelete: deleted || false
      }

      if (offer) {
        updateCargoWorkingOffers({
          variables: {
            input: offerToUpdate
          }
        })
      }
    }
  }

  const { loading, data } = useQuery(GET_CARGOWORKINGS, {
    fetchPolicy: 'network-only',
    onCompleted (data) {
      setCargoWorkings({
        type: 'initital',
        value: data.getCargoWorkings.cargoWorkings
      })
    }
  })

  const dataRef = useRef(data)

  useEffect(() => {
    if (!_.isEqual(dataRef.current, data)) {
      if (data && data.getCargoWorkings) {
        setCargoWorkings({
          type: 'initital',
          value: data.getCargoWorkings.cargoWorkings
        })

        dataRef.current = data
      }
    }
  }, [data])

  // const cargoWorkingsArray = []
  const getFocusedData = (cargoWorkings) => {
    const cargoWorkingsArray = []
    let colorVertical = true
    let colorDefault = true
    for (let i = 0; i < cargoWorkings.length; i++) {
      const cargoWorking = cargoWorkings[i]

      if (vertical && historicArray.includes(cargoWorking.status)) {
        cargoWorkingsArray.push({ ...cargoWorking, color: colorVertical })

        // Choosing the color
        if (i !== cargoWorkings.length - 1 && cargoWorking.cargoId !== cargoWorkings[i + 1].cargoId) {
          colorVertical = !colorVertical
        } else if (i === cargoWorkings.length - 1 && cargoWorking.cargoId !== cargoWorkings[i - 1].cargoId) {
          colorVertical = !colorVertical
        }
      } else if (!vertical && !historicArray.includes(cargoWorking.status)) {
        cargoWorkingsArray.push({ ...cargoWorking, color: colorDefault })

        // Choosing the color
        if (i !== cargoWorkings.length - 1 && cargoWorking.cargoId !== cargoWorkings[i + 1].cargoId) {
          colorDefault = !colorDefault
        } else if (i === cargoWorkings.length - 1 && cargoWorking.cargoId !== cargoWorkings[i - 1].cargoId) {
          colorDefault = !colorDefault
        }
      }
    }

    return cargoWorkingsArray
  }
  if (loading) {
    return (
      <Grid>
        <Helmet>
          <title>Cargo Working</title>
        </Helmet>
        <Box maxWidth={450} width='100%'>
          <Grid component='label' container alignItems='center' spacing={1}>
            <Grid item>Default</Grid>
            <Grid item>
              <Switch
                checked={vertical}
                onChange={e => {
                  setVertical(e.target.checked)
                  if (e.target.checked) {
                    history.go(0)
                  }
                }}
                value={vertical}
              />
            </Grid>
            <Grid item>Historic</Grid>
            <AddIconButton
              // to={content.createButton.link}
              onClick={() => history.push(content.createButton.link)}
              color='primary'
            >
              {content.createButton.name}
            </AddIconButton>
          </Grid>
        </Box>
      </Grid>
    )
  }
  return (
    <Grid>
      <Box maxWidth={450} width='100%'>
        <Grid component='label' container alignItems='center' spacing={1}>
          <Grid item>Default</Grid>
          <Grid item>
            <Switch
              checked={vertical}
              // onChange={e => setVertical(e.target.checked)}
              onChange={(e) => {
                setVertical(e.target.checked)
                if (vertical) {
                  if (!e.target.checked) {
                    history.go(0)
                  }
                }
              }}
              value={vertical}
            />
          </Grid>
          <Grid item>Historic</Grid>
          <AddIconButton
            // to={content.createButton.link}
            onClick={() => history.push(content.createButton.link)}
            color='primary'
          >
            {content.createButton.name}
          </AddIconButton>
        </Grid>
      </Box>
      <ShowCargoWorkingAgGrid
        setCargoWorkings={setCargoWorkings}
        cargoWorkings={cargoWorkings}
        rowData={getFocusedData(cargoWorkings)}
        vertical={vertical}
        setLocalOwner={handleLocalOwner}
        localOwner={localOwner}
        setLocalPort={handleLocalPort}
        setLocalVessel={handleLocalVesssel}
        localPort={localPort}
        localVessel={localVessel}
        setCurrentRowData={handleCurrentRowData}
        currentRowData={currentRowData}
        handleTabMutation={handleTabMutation}
        handleTabMutationPort={handleTabMutationPort}
        handleTabMutationVessel={handleTabMutationVessel}
        updateCargoWorkingOffers={updateCargoWorkingOffers}
        setOfferMessage={setOfferMessage}
        currentNode={currentNode}
        setCurrentNode={setCurrentNode}
        ownerRef={ownerRef}
        globalRef={globalRef}
      />
    </Grid>
  )
}

export default withRouter(ShowCargoWorkings)
