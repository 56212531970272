import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
// Helmet
import Helmet from 'react-helmet'
// Context
import { UserContext } from '../App/AppContext'
// Content
import { upsert as upsertContent } from './ZoneContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { CREATE_ZONE_INFORMATION, GET_ZONE } from '../Queries/Queries' // GET_CALCULATION_ROUTE_INFO
// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { UPSERT_ZONE } from '../Mutations/Mutations'
// Utils
import {
  description,
  monthRequired,
  nameRequired,
  validateStep
} from '../Utils/form-validations'
import { useFormInput } from '../Utils/utils'

import RootBreadCrumb from '../Utils/RootBreadcrumb'
import RootButton from '../Utils/RootButton'
import RootForm from '../Utils/RootForm'
import RootGrid from '../Utils/RootGrid'
import RootPaper from '../Utils/RootPaper'
import RootTextField from '../Utils/RootTextField'
import TextError from '../Utils/TextError'
//  import RootDataGrid from '../Utils/RootDataGrid'

// Material UI
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const validatejs = require('validate.js')
const UpsertZoneQueryHelper = props => {
  const { match, history: _history, location: _location, ...rest } = props
  let id
  if (match && match.params) {
    id = match.params.zone
  }
  const skip = !id
  const searchInput = {
    id
  }
  return (
    <QueryHelper
      query={CREATE_ZONE_INFORMATION}
    >
      {({ data }) => {
        const { createZoneInformation } = data
        if (createZoneInformation) {
          const { ports } = createZoneInformation
          const emptyOptions = []
          if (ports.length === 0) {
            emptyOptions.push(upsertContent.emptyObjects.ports)
          }

          const portsArray = []
          if (ports.length >= 0) {
            ports.map(item => {
              return (portsArray.push({ id: item.id, name: item.portName }))
            })
          }
          // TODO not required check to delete
          // if (emptyOptions.length > 0) {
          //   return (
          //     <CreateOptionsContainer
          //       options={emptyOptions}
          //       name={upsertContent.module.name}
          //     />
          //   )
          // }
          return (
            <UpsertZone
              searchInput={searchInput}
              skip={skip}
              ports={portsArray}
              {...rest}
            />
          )
        }
        return <div />
      }}
    </QueryHelper>
  )
}

UpsertZoneQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

export default withRouter(UpsertZoneQueryHelper)

const UpsertZone = props => {
  const { searchInput, skip, ...rest } = props

  return (
    <QueryHelper
      query={GET_ZONE}
      variables={{ input: searchInput }}
      skip={skip}
    >
      {({ data }) => {
        let zone = null
        if (data && data.getZone && data.getZone.zone) {
          zone = data.getZone.zone
        }
        return (
          <>
            {
              zone
                ? <Helmet><title>Update Zone</title></Helmet>
                : <Helmet><title>Create Zone</title></Helmet>
            }
            <CreateZone
              {...rest}
              zone={zone}
            />
          </>
        )
      }}
    </QueryHelper>
  )
}

UpsertZone.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '10px'
  },
  button: {
    borderRadius: '5px',
    backgroundColor: 'rgba(91, 196, 34, 1)',
    color: 'white'
  }
}))

const CreateZone = props => {
  const { refetchQueries, zone, ports } = props

  const classes = useStyles()

  const content = zone ? upsertContent.update : upsertContent.create
  const [editRouteResults, setEditRouteResults] = useState(false)

  const { user } = useContext(UserContext)

  const validation = {
    zoneDescription: description,
    zoneName: nameRequired
  }

  const weatherValidation = {
    month: monthRequired
  }

  const initialValues = {
    // TODO FIX THIS, why should i fix this ??
    defaultBunkerPort: zone && zone.defaultBunkerPort ? zone.defaultBunkerPort.id : '',
    zoneDescription: zone ? zone.zoneDescription : '',
    zoneName: zone ? zone.zoneName : '',
    weatherMarginArray: zone ? zone.weatherMarginArray : Array.from(Array(12), () => 0)
  }

  const form = {
    defaultBunkerPort: useFormInput({ initialValue: initialValues.defaultBunkerPort, select: true, selectValues: ports, smallInput: true, label: 'defaultBunkerPort' }),
    zoneName: useFormInput({ label: 'zoneName', required: true, initialValue: initialValues.zoneName }),
    zoneDescription: useFormInput({ label: 'zoneDescription', multiline: true, initialValue: initialValues.zoneDescription })
  }

  const [weatherMarginArrayObj, setWeatherMarginArrayObj] = useState(
    initialValues.weatherMarginArray.map(
      weather => {
        return {
          value: weather,
          valid: weather !== null,
          errorMessage: ''
        }
      }
    )
  )

  const input = {
    ...(zone && { id: zone.id }),
    defaultBunkerPort: form.defaultBunkerPort.getValue(),
    editRouteResults,
    zoneName: form.zoneName.getValue(),
    zoneDescription: form.zoneDescription.getValue(),
    weatherMarginArray: weatherMarginArrayObj.map(weather => weather.value)
  }

  const next = ({ mutation }) => {
    weatherMarginArrayObj.reduce((valid, currentObj, index) => {
      if (index === 1) {
        return valid.valid && currentObj.valid
      } else {
        return valid && currentObj.valid
      }
    }) &&
      validateStep({
        form,
        next: () => mutation(),
        validation
      })
  }

  return (
    <MutationHelper
      mutation={UPSERT_ZONE}
      {...(props.onCompleted
        ? { onCompleted: props.onCompleted }
        : { onCompletedObject: content.onCompleted }
      )}
      refetchQueries={refetchQueries}
      variables={{ input }}
    >
      {(mutation, result) => {
        return (
          <RootGrid>
            <RootPaper elevation={2} className={classes.paper} {...(!props.onCompleted && { smallForm: true })}>
              <RootBreadCrumb current={content.current} links={content.breadCrumbs} />
              <RootForm
                form={form}
                validation={validation}
              />
              <WeatherMarginArrayInputs
                setEditRouteResults={setEditRouteResults}
                setWeatherMarginArrayObj={setWeatherMarginArrayObj}
                weatherMarginArrayObj={weatherMarginArrayObj}
                validation={weatherValidation}
              />
              <RootButton className={classes.button} disabled={result.loading || user.disabled} justifyContent='center' onClick={() => next({ mutation })}>
                {content.submitButton}
              </RootButton>
            </RootPaper>
          </RootGrid>
        )
      }}
    </MutationHelper>
  )
}

CreateZone.defaultProps = {
  refetchQueries: []
}

// const WeatherMarginArrayHeader = ({ title, onChange, value }) => {
//   return (
//     <Grid
//       container
//       alignContent='center'

//     >
//       <Grid
//         item
//         sm={6}
//         container
//         alignContent='center'
//       >
//         <Typography>{title}</Typography>
//       </Grid>
//       <Grid
//         item
//         sm={6}
//       />
//     </Grid>
//   )
// }

CreateZone.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array.isRequired,
  zone: PropTypes.object
}

const WeatherMarginArrayInputs = props => {
  const { setEditRouteResults, setWeatherMarginArrayObj, weatherMarginArrayObj, validation } = props

  if (weatherMarginArrayObj.length !== 12) {
    return null
  }

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

  return (
    <RootGrid>
      <Box
        display='flex'
        width='100%'
        margin='2.4%'
      >
        <Typography>Weather Margin</Typography>
      </Box>
      {/*
      <WeatherMarginArrayHeader
        title = 'Weather Margin'
      /> */}
      {
        weatherMarginArrayObj.map((weather, index) => {
          return (
            <>
              <RootTextField
                key={index}
                smallInput
                label={months[index]}
                onChange={(e) => {
                  setEditRouteResults(true)
                  const newWeatherMarginArray = weatherMarginArrayObj
                  newWeatherMarginArray[index].value = parseFloat(e.target.value)
                  setWeatherMarginArrayObj(prevState => (
                    prevState.map((value, i) => {
                      if (i === index) {
                        const validationResult = validatejs(
                          {
                            month: parseFloat(e.target.value)
                          },
                          validation
                        )
                        return {
                          value: parseFloat(e.target.value),
                          valid: !validationResult,
                          errorMessage: validationResult ? validationResult.month[0] : null
                        }
                      } else {
                        return value
                      }
                    })
                  ))
                }}
                type='number'
                value={parseFloat(weather.value)}
              />
              <TextError error={weather.errorMessage} />
            </>
          )
        })

      }
    </RootGrid>
  )
}

WeatherMarginArrayInputs.propTypes = {
  setWeatherMarginArray: PropTypes.func.isRequired,
  weatherMarginArray: PropTypes.array.isRequired
}
