// React
import React from 'react'
import PropTypes from 'prop-types'
// Material Ui
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  rootButton: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: 16,
    marginTop: 16,
    padding: '4px 24px'
  }
}))

const RootButton = props => {
  const { alignItems, children, className, justifyContent, ...rest } = props
  const classes = useStyles()
  return (
    <Box display='flex' alignItems={alignItems} justifyContent={justifyContent}>
      <Button
        className={clsx(classes.rootButton, {
          [className]: className
        })}
        color='secondary'
        size='small'
        {...rest}
      >
        {children}
      </Button>
    </Box>
  )
}

RootButton.defaultProps = {
  alignItems: 'flex-start',
  justifyContent: 'flex-start'
}

RootButton.propTypes = {
  alignItems: PropTypes.string.isRequired,
  className: PropTypes.string,
  justifyContent: PropTypes.string
}

export default RootButton
