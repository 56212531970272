import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
// Clsx
import clsx from 'clsx'
// Material UI
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Skeleton from '@material-ui/lab/Skeleton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
// React Image
import ReactImage from 'react-image'
// Utils
import { formatNumber, formatNumberWithMultiplesOfFive, formatNumberWithMultiplesOfTwentyFive } from '../Utils/utils'
import { UserContext } from '../App/AppContext'
import { route } from '../Utils/form-validations'

const useTooltipClasses = makeStyles(theme => ({
  text: {
    fontSize: theme.typography.generalFontSize - 2
  }
}))

const HtmlTooltip = props => {
  const { children, onHoverData = [] } = props
  const classes = useTooltipClasses()
  return (
    <Tooltip
      title={
        <div className={classes.tooltip}>
          {
            onHoverData.map(data => {
              return (
                <Typography key={data} className={classes.text} color='inherit'>{data}</Typography>
              )
            })
          }
        </div>
      }
    >
      {children}
    </Tooltip>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingTop: '1%',
    paddingBottom: '1%',
    // background: theme.palette.common.white
  },
  table: {
    tableLayout: 'fixed'
  },
  routesColumn: {
    flex: '0 0 10%',
    maxWidth: '15%'
  },
  routesColumnTypography: {
    fontSize: theme.typography.generalFontSize
  },
  flag: {
    flex: '0 0 85%',
    marginLeft: '15%'
  },
  flagTypography: {
    fontSize: theme.typography.generalFontSize,
    fontWeight: 'bold'
  },
  smallCell: {
    fontSize: theme.typography.generalFontSize - 2,
    fontWeight: 'light'
  },
  boldCell: {
    fontWeight: 'bold'
  },
  cellName: {
    minWidth: 100,
    maxWidth: 100,
    // fontWeight: 'bold'
    fontWeight: 400
  },
  quartersCell: {
    // borderRight: '1px solid #000',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  baseRoute: {
    backgroundColor: '#70FFC1'
  },
  divider: {
    display: 'block',
    height: 20
  },
  link: {
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  quarter: {
    borderRight: '1px solid rgba(123, 129, 130, 1)'
  },
  errorClass: {
    color: 'red'
  },
  countryStyle: {
    display: 'flex',
    alignItems: 'center'
  },
  countryStyleTypography: {
    marginLeft: 8,
    fontSize: theme.typography.generalFontSize + 1,
    fontWeight: 'bold'
  },
  routeResultsCell: {
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    }
  },
  flag: {
    width: 32
  }
}))

const ShowClientRoutesHorizontal = props => {
  const { adcom, clientRoutes, hasNextPage, quarterArray } = props
  
  const classes = useStyles()

  const headers = clientRoutes.headers

  const [countries, setCountries] = React.useState(clientRoutes.clientRoutes)

  const recalculateCountries = props => {
    const a = new Date(Date.now())
    const { adcom, countries } = props

    const newCountries = countries.map((country, i) => {
      const newRoutes = country.routes.map((route, i) => {
        let first = formatNumber({ number: parseFloat(country.routes[0].routeResults[0].rate) * (1 + adcom / 100) });
        ({ formattedRate: first } = formatNumberWithMultiplesOfTwentyFive({ formattedRate: first }))
        first = formatNumber({ number: first })
        let spread = route.routeResults[0].rate
        try {
          let current = parseFloat(spread)
          current = formatNumber({ number: current * (1 + adcom / 100) })
          const { formattedRate } = formatNumberWithMultiplesOfTwentyFive({ formattedRate: current })
          current = formatNumber({ number: formattedRate })
          // Calculate rate with adcom
          spread = formatNumber({
            number: parseFloat(current) - parseFloat(first)
          })
        } catch (e) {
          spread = route.spread
        }
        const newRouteResults = route.routeResults.map((result, j) => {
          let text = result.rate
          try {
            // Calculate rate with adcom
            let formattedRate = formatNumber({ number: parseFloat(text) * (1 + adcom / 100) });
            // Format the rate with decimals with multiple of five
            ({ formattedRate } = formatNumberWithMultiplesOfTwentyFive({ formattedRate }))
            text = formatNumber({ number: formattedRate })
          } catch (e) {
            text = result.text
          }
          return {
            ...result,
            text
          }
        })

        return {
          ...route,
          routeResults: newRouteResults,
          spread
        }
      })
      return {
        ...country,
        routes: newRoutes
      }
    })
    const b = new Date(Date.now())
    return newCountries
  }

  React.useEffect(() => {
    const newCountries = recalculateCountries({ adcom, countries })
    setCountries(newCountries)
  }, [adcom, setCountries])

  React.useEffect(() => {
    if (countries.length !== clientRoutes.clientRoutes) {
      const newCountries = recalculateCountries({ adcom, countries: clientRoutes.clientRoutes })
      setCountries(newCountries)
    }
  }, [clientRoutes])

  return (
    <Paper className={classes.root} elevation={0}>
      <Table size='small'>
        {
          countries.map((country, i) => {
            const flag = country.flag
            const name = country.name
            const routes = country.routes
            return (
              <Fragment key={i}>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={8}>
                      <div className={classes.countryStyle}>
                        <ReactImage className={classes.flag} src={`/flags/${flag}.png`} alt={flag} />
                        <Typography className={classes.countryStyleTypography} display='inline'>
                          {country.name}
                        </Typography>
                      </div>
                      {/* <ReactImage src={country.flag} alt={country.name} /> */}
                    </TableCell>
                    {/* <TableCell>{country.name} </TableCell> */}
                  </TableRow>
                  <TableRow>
                    {
                      headers.headersData.map((header, i) => {
                        return (
                          <TableCell key={`${header.text}${i}`} align={header.align} className={classes[header.className]}>{header.text}</TableCell>
                        )
                      })
                    }
                    {
                      headers.headersPositionDates.map((header, i) => {
                        return (
                          <TableCell key={`${header.text}${i}`} align={header.align} className={classes[header.className]}>{header.text}</TableCell>
                        )
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    routes.map((route, i) => {
                      return (
                        <TableRow index={i} key={i} className={[classes.quartersCell]}>
                          {
                            headers.headersData.map((header, j) => {
                              const data = route.routeData[header.key]
                              if (j === 0) {
                                return (
                                  <TableCell key={`${data.text}${j}`}>
                                    {data.text}
                                  </TableCell>
                                )
                              }
                              if (header.key === 'loadDischTerm') {
                                return (
                                  <HtmlTooltip key={`${data.text}${j}`} onHoverData={route.onHoverData}>
                                    <TableCell align={data.align} className={classes[data.className]}>{data.text}</TableCell>
                                  </HtmlTooltip>
                                )
                              }
                              if (header.key === 'spread') {
                                return (
                                  <TableCell key={`${data.text}${j}`} align={data.align} className={clsx(classes[data.className], classes.quarter)}>{data.text}</TableCell>
                                )
                              }
                              return (
                                <TableCell key={`${data.text}${j}`} align={data.align} className={classes[data.className]}>{data.text}</TableCell>
                              )
                            })
                          }
                          {
                            route.routeResults.map((result, j) => {
                              const needsBorderQuarter = [10, 7, 4, 0]
                              const quarter = quarterArray.includes(j)
                              const text = result.rate
                              return (
                                <TableCell
                                  key={`${text}${j}`}
                                  align={result.align}
                                  className={classes[result.className]}
                                  className={clsx(classes.routeResultsCell, {
                                    [classes.quarter]: quarter
                                  })}
                                >
                                  {result.text}
                                </TableCell>
                              )
                            })
                          }
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Fragment>
            )
          })
        }

      </Table>
      {
        hasNextPage && (
          <Table style={{ marginTop: 8 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={8}>
                  <div className={classes.countryStyle}>
                    <Skeleton animation='wave' variant='rect' width={32} height={32} />
                    <Typography className={classes.countryStyleTypography} display='inline'>
                      <Skeleton animation='wave' width={200} />
                    </Typography>
                  </div>
                  {/* <ReactImage src={country.flag} alt={country.name} /> */}
                </TableCell>
                {/* <TableCell>{country.name} </TableCell> */}
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton animation='wave' width='100%' />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Skeleton animation='wave' width='100%' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton animation='wave' width='100%' />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Skeleton animation='wave' width='100%' />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
      }
    </Paper>
  )
}

ShowClientRoutesHorizontal.propTypes = {

}

export default ShowClientRoutesHorizontal
