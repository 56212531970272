import gql from 'graphql-tag'

import React, { Fragment, useContext, useEffect, useState, useReducer } from 'react'
// Material UI
import { withRouter } from 'react-router-dom'

// Content
import AppContent from './AppContent'
// Context
import { UserContext } from './AppContext'

// Utils
import { getRoleValue } from '../Utils/utils'
import SnackBar from '../Utils/SnackBar'

import Import from './Import'

// Query
import { GET_UPDATING_ROUTES } from '../Queries/Queries'
import QueryHelper from '../Queries/QueryHelper'

const NEW_ROUTES_UPDATED = gql`
  subscription RouteUpdatedSubscription {
    routeUpdated {
      id
      count
      length
      finished
    }
  }
`

const SubscriptionsApp = props => {
  const [routes, setRoutes] = useState([])
  const snackbarReducer = (state, action) => {
    switch (action.type) {
      case 'info':
        return {
          ...state,
          open: true,
          variant: 'info'
        }
      case 'error':
        return {
          ...state,
          open: true,
          variant: 'error'
        }
      case 'success':
        return {
          ...state,
          open: true,
          variant: 'success',
          message: action.message
        }
      case 'close': {
        return {
          ...state,
          open: false,
          variant: '',
          message: ''
        }
      }
      default: break
    }
  }
  const [snackbar, setSnackbar] = useReducer(snackbarReducer, { open: false, message: '' })

  const [updatingRoutes, setUpdatingRoutes] = useState(0)

  const { user, setUser } = useContext(UserContext)

  let currentRole = null
  // TODO ask raul
  let shortCuts = []
  if (user) {
    currentRole = getRoleValue({ role: user.role })
  }
  if (currentRole) {
    shortCuts = AppContent.roles[currentRole].shortCuts.map(shortCut => {
      let onMatch = () => shortCut.onMatch()
      if (shortCut.link) {
        onMatch = () => props.history.push(shortCut.link)
      }
      return {
        ...shortCut,
        onMatch
      }
    })
  }
  const _newUpdatingRoutes = subscribeToMore => {
    subscribeToMore({
      document: NEW_ROUTES_UPDATED,
      updateQuery: (previousData, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousData
        }
        // const newUpdatingRoute = subscriptionData.data.routeUpdated.routeUpdated
        return Object.assign({}, previousData, {
          getUpdatingRoutes: {
            updatingRoutes: [subscriptionData.data.routeUpdated]
          }
        })
      }
    })
  }
  useEffect(() => {
    if (currentRole) {
      setRoutes(AppContent.roles[currentRole].routes)
    }
  }, [currentRole])

  let setNewValues = () => {}

  const updatingRoutesRef = React.useRef(updatingRoutes)

  useEffect(() => {
    if (updatingRoutes !== updatingRoutesRef.current) {
      updatingRoutesRef.current = updatingRoutes
      setNewValues()
      // if (!user.disabled) {
      //   setUser({ value: true, type: 'update-route' })
      // }
      // if (updatingRoutes === 0) {
      //   setUser({ value: false, type: 'update-route' })
      // }
    }
  }, [updatingRoutes, user])
  return (
    <>
      <QueryHelper
        query={GET_UPDATING_ROUTES}
        skip={!user.role}
      >
        {({ error, data, subscribeToMore }) => {
          _newUpdatingRoutes(subscribeToMore)
          if (error) {
            // setUser({value: false, type: 'update-route'})
            return (
              <SnackBar
                open={snackbar.open}
                onClose={() => {
                  setSnackbar({
                    type: 'close'
                  })
                }}
                variant={snackbar.variant}
                message={`Error Updating Route. ${updatingRoutes} `}
              />
            )
          }
          if (!data) {
            return null
          }
          setUpdatingRoutes(data.getUpdatingRoutes.updatingRoutes[0].count)
          if (data.getUpdatingRoutes.updatingRoutes[0].count > 0) {
            setNewValues = () => { setSnackbar({ type: 'success', message: `${currentRole === 'client' ? 'Routes are being updated' : `${updatingRoutes} / ${data.getUpdatingRoutes.updatingRoutes[0].length} Routes have been updated.`}` }) }
            return (
              <SnackBar
                open={snackbar.open}
                onClose={() => {
                  setSnackbar({
                    type: 'close'
                  })
                }}
                variant={snackbar.variant}
                message={`${currentRole === 'client' ? 'Routes are being updated' : `${updatingRoutes} / ${data.getUpdatingRoutes.updatingRoutes[0].length} Routes have been updated.`}`}
              />
            )
          } else {
            return (
              <></>
            )
          }
        }}
      </QueryHelper>
    </>
  )
}

const SubscriptionsApps = withRouter(SubscriptionsApp)
export default SubscriptionsApps
