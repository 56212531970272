// React
import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Paper from '@material-ui/core/Paper'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    padding: theme.spacing(2)
  },
  smallForm: {
    margin: '0 auto',
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '800px'
    }
  }
}))

const RootPaper = props => {
  const { children, className, smallForm, ...rest } = props

  const classes = useStyles()
  return (
    <Paper
      className={clsx(classes.paper, {
        [classes.smallForm]: smallForm,
        [className]: className,
      })}
      elevation={0}
      {...rest}
    >
      {children}
    </Paper>
  )
}

RootPaper.defaultProps = {
  smallForm: false
}

RootPaper.propTypes = {
  className: PropTypes.string,
  smallForm: PropTypes.bool,
}

export default RootPaper
