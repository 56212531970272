
// React Library
import React from 'react'

// Helmet
import Helmet from 'react-helmet'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Content
import { show as content } from './PortsContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_PORTS } from '../Queries/Queries'

// Mutation Related
import { DELETE_PORT } from '../Mutations/Mutations'

// Utils
import AddIconButton from '../Utils/AddIconButton'
import Grid from '@material-ui/core/Grid'
import RootGrid from '../Utils/RootGrid'
import RootAgGrid from '../Utils/RootAgGrid'
import SearchTextInput from '../Utils/SearchTextInput'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Query Component
const ShowPorts = props => {
  return (
    <>
      <Helmet>
        <title>Ports</title>
      </Helmet>
      <QueryHelper
        query={GET_PORTS}
      >
        {({ data }) => {
          const { getPorts: { ports } } = data

          // Some Formatting...
          const portsArray = ports.map(port => {
            return {
              ...port,
              eca: port.eca ? 'eca' : 'nonEca',
              country: port.country ? port.country.name : '-',
              shipSectorIDArray: (port.shipSectorIDArray.length > 0) ? port.shipSectorIDArray[0].shipSectorName : '',
              zone: port.zone.zoneName,
              costArray: port.costArray.length > 0 ? port.costArray[0] : '-',
              draftArray: port.draftArray.length > 0 ? port.draftArray[0] : '-',
              forwardCommodityFOID: port.forwardCommodityFOID.commodityName,
              forwardCommodityMGOID: port.forwardCommodityMGOID.commodityName,
              fuelDeliveryPremiumArray: port.fuelDeliveryPremiumArray.length > 0 ? port.fuelDeliveryPremiumArray[0] : '-',
              waitingIdleArray: port.waitingIdleArray.length > 0 ? port.waitingIdleArray[0] : '-'
            }
          })

          // Render
          return (
            <Grid>
              <PortsTable portsArray={portsArray} />
            </Grid>
          )
        }}
      </QueryHelper>
    </>
    
  )
}

const PortsTable = (props) => {
  const { portsArray } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = React.useState('')
  const [ports, setPorts] = React.useState(portsArray)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['portName', 'berth'], {
    idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  })

  // The Array To Be Filtered (All Ports In the DB)
  ts.addAll(portsArray)

  // Trie-Search Logic
  const searchRef = React.useRef(search)
  React.useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setPorts(portsArray)
      } else {
        const searchArray = search.split(',')
        const foundDistances = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setPorts(foundDistances)
      }
    }
  }, [search, portsArray, ts])

  // Column Structure
  const columnDefs = [
    { field: 'portName', headerName: 'Port', cellRenderer: 'link', width: 500 },
    { field: 'berth', headerName: 'Berth', width: 300 },
    { field: 'eca', headerName: 'Eca', width: 200 },
    { field: 'country', headerName: 'Country', width: 450 },
    { field: 'zone', headerName: 'Zone', width: 600 },
    { field: 'draftArray', headerName: 'Drafts' },
    { field: 'salinity', headerName: 'Salinities' },
    { field: 'costArray', headerName: 'Costs' },
    { field: 'waitingIdleArray', headerName: 'Waiting Idle' },
    { field: 'fuelDeliveryPremiumArray', headerName: 'Fuel Delivery Premium' },
    { field: 'FO05', headerName: 'FO05' },
    { field: 'HSFO380', headerName: 'HSFO380' },
    { field: 'MGO01', headerName: 'MGO01' },
    { field: 'bunkeringCost', headerName: 'Bunkering Cost' },
    { field: 'bunkeringTime', headerName: 'Bunkering Time' },
    { field: 'forwardCommodityFOID', headerName: 'FC FOID' },
    { field: 'forwardCommodityMGOID', headerName: 'FC MGO' },
    { field: 'shipSectorIDArray', headerName: 'Ship Sector' }
  ]

  // Link Of The Update Port Page
  const updateLink = 'update-port'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          // to={content.createButton.link}
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>

      </SearchTextInput>

      {/* Ports Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={ports}
        mutation={DELETE_PORT}
        objectNameKey1='portName'
        objectNameKey2='berth'
        type='Port'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowPorts)
