import React, { useContext, useEffect, useState, useRef, useReducer } from 'react' // Fragment
// import PropTypes from 'prop-types'
// { Fragment, useEffect, useState, useReducer }
import { withRouter } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Context
import { UserContext } from '../App/AppContext'
// Material
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

// Queries
import QueryHelper from '../Queries/QueryHelper'
import {
  GET_CLIENTS,
  GET_ROUTES_FROM_CLIENT_HORIZONTAL,
  GET_HISTORIC_ROUTE_RESULTS_BY_DAY,
  GET_RESULTS_BY_DAY,
  GET_SYSTEM_SETTINGS,
  GET_HEAT_MAP_VALUES
} from '../Queries/Queries'
// Mutations
import { useMutation, useQuery } from 'react-apollo'
import {
  CREATE_BASE_ROUTE_FOR_CLIENT,
  DELETE_BASE_ROUTE_FOR_CLIENT
} from '../Mutations/Mutations'
// Styles
import { makeStyles } from '@material-ui/core/styles'
// import clsx from 'clsx'
// Utils
import EditableInputBase from '../Utils/EditableInputBase'
// import { formatNumber } from '../Utils/utils'
import ClientResultCSV from './ClientResultCSV'
import ClientResultExcel from './ClientResultExcel'
import ClientResultPdf from './ClientResultPdf'
import ClientResultHorizontal from './ClientResultHorizontal'
import ClientResultVertical from './ClientResultVertical'
import ClientSettingsModal from './ClientSettingsModal'
// Utils
import CustomTextField from '../Utils/CustomTextField'
import SnackBar from '../Utils/SnackBar'
import CustomAutocomplete from '../Utils/CustomAutocomplete'
import Switch from '@material-ui/core/Switch'

import ShowSummary from '../Summary/ShowSummary'

// DATE FNS
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'

const queryHelperStyles = makeStyles((theme) => ({
  initialWidgets: {
    width: '100%'
  },
  textField: {
    textAlign: 'left',
    fontSize: theme.typography.generalFontSize,
    width: 300,
    marginRight: 16
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  autocomplete: {
    minWidth: '100%',
    width: '100%'
  },
  adcom: {
    marginLeft: 'auto'
  },
  adcomField: {
    width: 100
  },
  heatMapLowGreen: {
    backgroundColor: '#97e7a4',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  heatMapMediumGreen: {
    backgroundColor: '#6bd47c',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  heatMapHighGreen: {
    backgroundColor: '#3aac4d',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  heatMapOutGreen: {
    backgroundColor: '#2c943d',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
  },
  heatMapLowRed: {
    backgroundColor: '#ffc8ba',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  heatMapMediumRed: {
    backgroundColor: '#f2856b',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  heatMapHighRed: {
    backgroundColor: '#f36746',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  heatMapOutRed: {
    backgroundColor: '#f7532d',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px'
  },
  heatMapNeutral: {
    backgroundColor: 'white',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  noResult: {
    backgroundColor: '#90CAF9',
    marginLeft: '10px',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderRadius: '5px'
  }
}))

const historicReducer = (state, action) => {
  switch(action.type) {
    case 'add': {
      return {
        ...action.obj
      }
    }
  }
}

const ClientResultsQueryHelper = React.memo(() => {
  const classes = queryHelperStyles()
  const input = {
    filter: {
      role: 'client'
    }
  }

  const { user, setUser } = useContext(UserContext)

  let initialClient = null
  let initialClientName = null
  let initialClientSetting = null

  if (user.client) {
    initialClient = user.client.id
    initialClientName = user.client.name
    initialClientSetting = user.client.userSetting
  }

  const [client, setClient] = useState(initialClient)
  const [clientName, setClientName] = useState(initialClientName)
  const [userSetting, setUserSetting] = useState(initialClientSetting)
  const [vertical, setVertical] = useState(false)
  const [heatMap, setHeatMap] = useState(false)
  const [resultsToCompare, setResultsToCompare] = useReducer(historicReducer, {})
  const heatMapLow = useRef(0)
  const heatMapMedium = useRef(0)
  const heatMapHigh = useRef(0)
  const [heatMapDays, setHeatMapDays] = useState(0)
  const startDay = useRef(startOfDay(new Date(Date.now())))
  const endDay = useRef(endOfDay(new Date(startOfDay)))
  
  const { loading, error: systemSettingError, data } = useQuery(GET_HEAT_MAP_VALUES, {
    onCompleted(data) {
      if (data) {
        heatMapLow.current = data.getHeatMapValues.heatMapLow
        heatMapMedium.current = data.getHeatMapValues.heatMapMedium
        heatMapHigh.current = data.getHeatMapValues.heatMapHigh
        setHeatMapDays(data.getHeatMapValues.heatMapDays)
      }
    }
  })
  
  const routeOrderFromClient = (users) => {
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === client) {
        return users[i].routeOrder
      }
    }
  }
  const initialAdcom = (user && user.client && user.client.userSetting) ? `${user.client.userSetting.adcom}` : ''

  
  const [userAdcom, setUserAdcom] = useState(
    user.client ? parseFloat(initialAdcom) : 0
  )
  const [adcomValue, setAdcomValue] = useState(
    user.client ? parseFloat(initialAdcom) : 0
  )

  useEffect(() => {
    setAdcomValue(user.client ? parseFloat(initialAdcom) : 0)
  }, [client])

  useEffect(() => {
    const today = new Date(Date.now()) 
    let dayOfInterest
    if (heatMapDays >= 0 && heatMapDays <= 6) {
      dayOfInterest = new Date(today.setDate(today.getDate() - heatMapDays))
    } else if (heatMapDays > 6) {
      dayOfInterest = new Date(today.setDate(today.getDate() - heatMapDays + 6))
    } else {
      dayOfInterest = today
    }
    startDay.current = new Date(startOfDay(dayOfInterest))
    endDay.current = new Date(endOfDay(dayOfInterest))
  }, [heatMapDays])

  const handleChangeHeatMapDays = (event) => {
    if (event.keyCode === 109) {
      return
    } else if (!isNaN(parseFloat(event.target.value))) {
      setHeatMapDays(event.target.value)
    }
  }

  return (
    <>
      <Helmet>
        <title>Client Results</title>
      </Helmet>
      <QueryHelper
        query={GET_CLIENTS}
        variables={{ input }}
        fetchPolicy='cache-and-network'
      >
        {({ data, refetch }) => {
          if (data) {
            const {
              getUsers: { users }
            } = data

            return (
              <Grid container>
                <Box className={classes.autocomplete}>
                  <CustomAutocomplete
                    width='100%'
                    smallInput
                    labelKey='name'
                    onChange={(e) => {
                      setClient(e.value.id)
                      setClientName(e.value.name)
                      setUserSetting(e.value.userSetting)
                      setUserAdcom(
                        e.value.userSetting &&
                          parseFloat(e.value.userSetting.adcom)
                      )
                      setAdcomValue(
                        e.value.userSetting &&
                          parseFloat(e.value.userSetting.adcom)
                      )
                      setUser({
                        type: 'add-client-info',
                        value: {
                          id: e.value.id,
                          name: e.value.name,
                          userSetting: e.value.userSetting
                        }
                      })
                    }}
                    disableClearable
                    value={client}
                    options={users}
                  >
                    <Box className={classes.adcom}>
                      <ClientResultCSV
                        adcom={adcomValue}
                        client={client}
                        clientName={clientName}
                      />
                    </Box>
                    <Box>
                      <ClientResultExcel
                        adcom={adcomValue}
                        clientName={clientName}
                        client={client}
                      />
                    </Box>
                    <Box className={classes.adcom}>
                      <ClientResultPdf
                        adcom={adcomValue}
                        client={client}
                        clientName={clientName}
                      />
                    </Box>
                    <Box className={classes.adcom}>
                      <ClientSettingsModal
                        client={client}
                        clientName={clientName}
                        userSetting={userSetting}
                        refetch={refetch}
                        users={users}
                        adcom={userAdcom}
                        setAdcom={setUserAdcom}
                        setAdcomValue={setAdcomValue}
                        setUser={setUser}
                      />
                    </Box>
                    <Box className={classes.adcom}>
                      <CustomTextField
                        label='adcom'
                        type='number'
                        placeholder='adcom'
                        onChange={(e) => {
                          setAdcomValue(parseFloat(e.target.value))
                        }}
                        value={parseFloat(adcomValue)}
                      />
                    </Box>
                  </CustomAutocomplete>
                </Box>
                <Box 
                  maxWidth={1900}
                  width='100%'
                  display='flex'
                  flexWrap='nowrap'
                  flexDirection='row'
                >
                  <Grid
                    component='label'
                    container
                    alignItems='center'
                    spacing={1}
                    wrap='nowrap'
                  >
                    <Grid item>Horizontal</Grid>e
                    <Grid item>
                      <Switch
                        checked={vertical}
                        onChange={(e) => setVertical(e.target.checked)}
                        value={vertical}
                      />
                    </Grid>
                    <Grid item>Vertical</Grid>
                  </Grid>
                  {
                    !vertical && (
                      <Grid
                        component='label'
                        container
                        alignItems='center'
                        spacing={1}
                        wrap='nowrap'
                      >
                        <Grid item>Heatmap</Grid>
                        <Grid item>
                          <Switch
                            checked={heatMap}
                            onChange={(e) => setHeatMap(e.target.checked)}
                            value={heatMap}
                          />
                        </Grid>
                        
                        {
                          heatMap && (
                            // <Box display='flex' flexDirection='row' flexWrap='nowrap'>
                              <Box
                                display='flex'
                                flexDirection='row'
                                flexWrap='nowrap'
                              >
                                <Grid item style={{ marginRight: '7px'}}>
                                  <EditableInputBase
                                    alignRight
                                    noFormat
                                    style={{ width: '50px'}}
                                    value={heatMapDays}
                                    onChange={handleChangeHeatMapDays}
                                  />
                                </Grid>
                                <Grid>days</Grid>
                                <Grid item>
                                  <Box display='flex' flexDirection='row' flexWrap='nowrap' justifyContent='flex-start' style={{ marginLeft: '50px'}}>
                                    <Box width='50px' className={classes.heatMapOutRed}>{`${-heatMapHigh.current}% <`}</Box>
                                    <Box width='50px' className={classes.heatMapHighRed}>{`${-heatMapHigh.current}%`}</Box>
                                    <Box width='50px' className={classes.heatMapMediumRed}>{`${-heatMapMedium.current}%`}</Box>
                                    <Box width='50px' className={classes.heatMapLowRed}>{`${-heatMapLow.current}%`}</Box>
                                    <Box width='50px' className={classes.heatMapNeutral}>{'0%'}</Box>
                                    <Box width='50px' className={classes.heatMapLowGreen}>{`${heatMapLow.current}%`}</Box>
                                    <Box width='50px' className={classes.heatMapMediumGreen}>{`${heatMapMedium.current}%`}</Box>
                                    <Box width='50px' className={classes.heatMapHighGreen}>{`${heatMapHigh.current}%`}</Box>
                                    <Box width='50px' className={classes.heatMapOutGreen}>{`> ${heatMapHigh.current}%`}</Box>
                                    <Box width='80px' className={classes.noResult}>No Results</Box>
                                  </Box>
                                </Grid>
                              </Box>
                          )
                        }
                      </Grid>
                    )
                  }

                </Box>
                <Grid item xs={12}>
                  <ClientResultsContainerQueryHelper
                    adcomValue={adcomValue}
                    client={client}
                    clientName={clientName}
                    routeOrder={client ? routeOrderFromClient(users) : []}
                    userSetting={userSetting}
                    vertical={vertical}
                    resultsToCompare={resultsToCompare}
                    setResultsToCompare={setResultsToCompare}
                    heatMap={heatMap}
                    days={heatMapDays}
                    heatMapLow={heatMapLow}
                    heatMapMedium={heatMapMedium}
                    heatMapHigh={heatMapHigh}
                    startDay={startDay}
                    endDay={endDay}
                  />
                </Grid>
              </Grid>
            )
          }
          return <div />
        }}
      </QueryHelper>
    </>
  )
})

const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      action.event.preventDefault()
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow,
        baseRouteText: action.selectedRow.isBaseRoute
          ? 'Delete base route'
          : 'Create base route'
      }
    default:
      return {
        ...state
      }
  }
}

const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

const viaPortsReducer = (state, action) => {
  switch(action.type) {
    case 'select-new-port': {
      const newState = { ...state }

      newState[action.route] = action.value

      action.mutation({
        variables: {
          input: {
            id: action.setting.id,
            viaPorts: newState,
          }
        }
      })

      action.setUser({
        type: 'add-client-info',
        value: {
          id: action.client,
          name: action.name,
          userSetting: {
            ...action.setting,
            showViaPorts: newState
          }
        }
      })

      return {
        ...newState
      }
    }
  }
}

const ClientResultsContainerQueryHelper = (props) => {
  const { client, clientName, routeOrder, userSetting, vertical, adcomValue, resultsToCompare, setResultsToCompare, heatMap, days, heatMapHigh, heatMapMedium, heatMapLow, startDay, endDay, ...rest } = props
  // Initial context menu state

  const [contextState, setContextState] = React.useReducer(
    contextReducer,
    initialState
  )
  const viaPortRef = useRef(null)

  const [showSummary, setShowSummary] = useState(false)

  const [error, setError] = React.useState({ open: false, message: '' })
  const input = {
    user: client,
    pdf: true
  }

  const {laoding, error: heatMapError, data} = useQuery(GET_RESULTS_BY_DAY, {
    fetchPolicy: 'network-only',
    skip: !heatMap,
    variables: {
      input: {
        client,
        days: days ? parseInt(days) : 0,
        startOfDay: startDay.current,
        endOfDay: endDay.current
      }
    },
    onCompleted(data) {
      if (data) {
        setResultsToCompare({
          type: 'add',
          obj: data.getResultsByDay
        })
      }
    }
  })

  const refetchQueries = [
    {
      query: GET_ROUTES_FROM_CLIENT_HORIZONTAL,
      variables: { input }
    }
  ]
  const [createBaseRoute, {}] = useMutation(CREATE_BASE_ROUTE_FOR_CLIENT, {
    refetchQueries,
    onError (error) {
      setError({
        open: true,
        message: error?.message?.split(':')[1]
      })
    }
  })
  const [deleteBaseRoute, {}] = useMutation(DELETE_BASE_ROUTE_FOR_CLIENT, {
    refetchQueries,
    onError (error) {
      setError({
        open: true,
        message: error?.message?.split(':')[1]
      })
    }
  })
  // If no user return message
  if (!client) {
    return <div>Select a client</div>
  }

  const handleCreateBaseRoute = () => {
    if (contextState.selectedRow.isBaseRoute) {
      deleteBaseRoute({
        variables: {
          input: {
            userSetting: userSetting.id,
            route: contextState.selectedRow.id
          }
        }
      })
    } else {
      createBaseRoute({
        variables: {
          input: {
            userSetting: userSetting.id,
            route: contextState.selectedRow.id
          }
        }
      })
    }
    setContextState({
      type: 'close'
    })
  }

  const handleClose = () => {
    setContextState({
      type: 'close'
    })
  }

  const handleCloseSummary = () => {
    setShowSummary(false)
    handleClose()
  }

  const handleSummary = () => {
    setShowSummary(true)
  }
  return (
    <QueryHelper
      linearProgress
      query={GET_ROUTES_FROM_CLIENT_HORIZONTAL}
      variables={{ input }}
    >
      {({ data, fetchMore }) => {
        if (data) {
          const {
            getRoutesFromClient: {
              clientRoutes,
              report,
              ports,
              positionDates,
              routes,
              shipSectors,
              timeCountings
            }
          } = data

          return (
            <>
              <RenderResults
                adcom={adcomValue || 0}
                client={client}
                clientName={clientName}
                userSetting={userSetting}
                clientRoutes={clientRoutes}
                contextState={contextState}
                fetchMore={fetchMore}
                hasNextPage={clientRoutes.hasNextPage}
                offset={clientRoutes.clientRoutes.length}
                quarterArray={clientRoutes.quarterArray}
                report={report}
                setContextState={setContextState}
                vertical={vertical}
                resultsToCompare={resultsToCompare}
                heatMap={heatMap}
                heatMapHigh={heatMapHigh}
                heatMapMedium={heatMapMedium}
                heatMapLow={heatMapLow}
              />
              <Menu
                keepMounted
                open={contextState.mouseY !== null}
                onClose={handleClose}
                anchorReference='anchorPosition'
                anchorPosition={
                  contextState.mouseY !== null && contextState.mouseX !== null
                    ? { top: contextState.mouseY, left: contextState.mouseX }
                    : undefined
                }
              >
                <MenuItem onClick={handleCreateBaseRoute}>
                  {contextState.baseRouteText}
                </MenuItem>
                <MenuItem onClick={handleSummary}>Summary</MenuItem>
              </Menu>
              <ShowSummary
                open={showSummary}
                handleClose={handleCloseSummary}
                routeId={
                  contextState.selectedRow ? contextState.selectedRow.id : ''
                }
                user={client}
              />
              <SnackBar
                open={error.open}
                onClose={() => setError({ open: false, message: '' })}
                variant='error'
                message={error.message}
              />
            </>
          )
        }
      }}
    </QueryHelper>
  )
}

const RenderResults = React.memo((props) => {
  const {
    adcom,
    client,
    clientName,
    clientRoutes,
    fetchMore,
    hasNextPage,
    offset,
    report,
    setContextState,
    setOffset,
    vertical,
    quarterArray,
    userSetting,
    resultsToCompare,
    heatMap,
    heatMapHigh,
    heatMapMedium,
    heatMapLow
  } = props

  const [results, setResults] = React.useState(clientRoutes)

  const updateQuery = (previousResult, { fetchMoreResult }) => {
    if (!fetchMoreResult) {
      return previousResult
    }

    const previousEdges =
			previousResult.getRoutesFromClient.clientRoutes.clientRoutes
    const fetchMoreEdges =
			fetchMoreResult.getRoutesFromClient.clientRoutes.clientRoutes
    fetchMoreResult.getRoutesFromClient.clientRoutes.clientRoutes = [
      ...previousEdges,
      ...fetchMoreEdges
    ]
    return { ...fetchMoreResult }
  }

  const resultsNotEmpty = Object.keys(resultsToCompare).length > 0

  // React.useEffect(() => {
  //   if (fetchMore) {
  //     const nextPage = hasNextPage
  //     const after = clientRoutes.clientRoutes.length
  //     if (nextPage && after !== null) {
  //       // setOffset(after)
  //       fetchMore({
  //         updateQuery,
  //         variables: {
  //           input: {
  //             user: client,
  //             pdf: true,
  //             offset: after
  //           }
  //         }
  //       })
  //     }
  //   }
  // }, [fetchMore, updateQuery])

  if (!vertical) {
      return (
      <ClientResultHorizontal
        adcom={adcom}
        client={client}
        clientName={clientName}
        clientRoutes={clientRoutes}
        hasNextPage={hasNextPage}
        report={report}
        setContextState={setContextState}
        quarterArray={quarterArray}
        userSetting={userSetting}
        heatMap={heatMap}
        resultsToCompare={resultsToCompare}
        heatMapHigh={heatMapHigh}
        heatMapMedium={heatMapMedium}
        heatMapLow={heatMapLow}
      />
    )
  } else {
    return (
      <ClientResultVertical
        adcom={adcom}
        client={client}
        clientRoutes={clientRoutes}
        hasNextPage={hasNextPage}
      />
    )
  }
})

export default withRouter(ClientResultsQueryHelper)
