import React from 'react'
import PropTypes from 'prop-types'
// Date FNS
import { format } from 'date-fns'
// Material UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
// Queries
import { Query } from 'react-apollo'
import { GET_ROUTES_FROM_LOGGED_CLIENT_PDF } from '../Queries/Queries'
// PDF MAKE
import pdfMake from "pdfmake/build/pdfmake"
import pdfFonts from "pdfmake/build/vfs_fonts"
// Utils
import { fireSwalError } from '../Utils/utils'
pdfMake.vfs = pdfFonts.pdfMake.vfs

const useStyles = makeStyles(theme => ({
  downloadIcon: {
    margin: theme.spacing(1)
  }
}))

/**
 * Layout options for the columns and rows in the report
 */
const createLayout = props => {
  const { quarterArray = [], totalColumns = 0 } = props


  return {
    fillColor: function (rowIndex, node, columnIndex) {
      if (rowIndex === 0) {
        return '#e0eaf7'
      } else {
        return null
      }
    },
    hLineWidth(i, node) {
      if (i === 0 || i === node.table.body.length) {
        return 1
      }
      return (i === node.table.headerRows) ? 1 : 0
    },
    vLineWidth(i) {
      // TODO Position Dates
      const needsBorder1 = [0, totalColumns - 14, totalColumns - 13, totalColumns]
      const needsBorderHalf = []

      for (let index = 0; index < quarterArray.length; index++) {
        const tempUgly = quarterArray[index] === 13 ? totalColumns - (quarterArray[index]) : totalColumns - 13 + quarterArray[index]
        needsBorderHalf.push(tempUgly)
      }
      if (needsBorder1.includes(i)) {
        return 1
      }
      if (needsBorderHalf.includes(i)) {
        return .5
      }
      return 0
    },
    paddingLeft(i) {
      return i === 0 ? 2 : 1
    },
    paddingRight(i, node) {
      return 1
    }
  }
}

const ClientRoutesPdf = props => {
  const { client, clientName } = props
  const classes = useStyles()

  const onClick = () => {
    setSkip(false)
  }
  // Variable initialization
  const [skip, setSkip] = React.useState(true)
  const input = {
    pdf: true,
  }
  if (!client) {
    return <></>
  }
  const createPdf = (data) => {
    const { report: reportData, quarterArray, totalColumns } = data?.getRoutesFromLoggedClient || {}
    if (reportData) {

      let report = {
        ...reportData,
      }

      //TODO seendd array
      const layout = createLayout({ totalColumns, quarterArray })
      let content = []
      for (let index = 0, lt = report.content.length; index < lt; index++) {
        let row = report.content[index]
        if (Array.isArray(row)) {
          row = row.map(r => {
            if (r.layout) {
              return ({
                ...r,
                layout
              })
            } else {
              return (r)
            }
          })
        }
        content.push(row)
        // For each row push the layout if it is needed
      }
      report.content = content
      return report
    }
  }
  const dowloadDate = new Date(Date.now())

  const year = dowloadDate.toLocaleString('default', { year: 'numeric', timeZone: 'Europe/Copenhagen' })
  const month = dowloadDate.toLocaleString('default', { month: 'short', timeZone: 'Europe/Copenhagen' })
  const day = dowloadDate.toLocaleString('default', { day: 'numeric', timeZone: 'Europe/Copenhagen' })

  const timeString = `${day}${month}${year}`

  return (
    <Query
      onError={(error) => {
        fireSwalError({
          title: 'Error',
          text: error ? error.message.split(':')[1] : 'Error generando el reporte'
        })
        setSkip(true)
      }}
      onCompleted={async (data) => {
        setSkip(true)
        const report = await createPdf(data)
        // If there is a report to create, generate it
        if (report) {
          // pdfMake.createPdf(report).open()
          pdfMake.createPdf(report).download(`${clientName}-${timeString}.pdf`)
        }
      }}
      query={GET_ROUTES_FROM_LOGGED_CLIENT_PDF}
      skip={skip}
      variables={{
        input
      }}
      fetchPolicy='network-only'
    >
      {({ data, loading }) => {
        if (loading) {
          return (
            <Box
              width='150px'
              display='flex'
              justifyContent='center'
            >
              <CircularProgress />
            </Box>
          )
        }
        return (
          <Box width='150px'>
            <Button
              aria-label='Export'
              onClick={() => onClick()}
              className={classes.downloadIcon}
              startIcon={<CloudDownloadIcon />}
            >
              Export PDF
          </Button>
          </Box>
        )
      }}
    </Query >
  )
}

ClientRoutesPdf.propTypes = {

}

export default ClientRoutesPdf
