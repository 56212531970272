import React from 'react'
import RootColumnLink from '../Utils/RootColumnLink'
import { CREATE_VOYAGEOFFER_INFORMATION } from '../Queries/Queries'
import { Filters } from "react-data-grid-addons"
const { SingleSelectFilter } = Filters


const breadCrumbs = [
  {
    link: '/cargo-workings',
    name: 'Cargo Working'
  }
]

const defaultColumnProperties = {
  width: 120
}

const module = {
  name: 'cargoWorkings'
}

const refetchQueries = [{
  query: CREATE_VOYAGEOFFER_INFORMATION
}]

export const showClient = {
  module,
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true},
      { key: 'route', name: 'routeName', formatter: (props) => <RootColumnLink {...props} viewLink={`/view-results/${props.row.id}`} />, filterRenderer: SingleSelectFilter },
    ],
    rows: 3,
  },
  update: {
    link: null
  }
}

export const show ={
  module,
  createButton: {
    name: 'Create Cargo Working',
    link: '/create-cargo-working'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true},
      { key: 'status', name: 'Status' },
      { key: 'account', name: 'Account'},
      { key: 'route', name: 'Route' },
      { key: 'commission', name: 'Adcom' },
      { key: 'month', name: 'Month' },
      { key: 'bid', name: 'Bid' },
      { key: 'result', name: 'Result' },
      { key: 'offer1', name: 'Offer 1' },
      { key: 'offer2', name: 'Offer 2' },
      { key: 'offer3', name: 'Offer 3' },

    ].map(c => ({ ...c, ...defaultColumnProperties })),
    rows: 3,
  },
  update: {
    link: '/update-cargo-working'
  }
}

export const upsert = {
  module,
  //Arrays of no objs, dischargePortBunker, loadPortArrayBunkerArray
  //Arrays of objs, dischargePortArray, dischargeTermsArray, linkedRouteArrays, loadPortArray, loadTermsArray
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Cargo Working created',
      path: '/cargo-workings'
    },
    submitButton: 'Create Cargo Working'
  },
  emptyObjects: {
    positionDates: {
      name: 'PositionDates',
      refetchQueries
    },
    routes: {
      name: 'Routes',
      refetchQueries
    },
    users: {
      name: 'Users',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Cargo Working updated',
      path: '/cargo-workings'
    },
    submitButton: 'Update Cargo Working'
  },
}