import React, { useContext, useEffect, useState } from 'react' // Fragment
// import PropTypes from 'prop-types'
// { Fragment, useEffect, useState, useReducer }
import { withRouter } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Context
import { UserContext } from '../App/AppContext'
// Material
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import {
  GET_LOGGED_CLIENT,
  GET_ROUTES_FROM_LOGGED_CLIENT
} from '../Queries/Queries'
// Mutations
import { useMutation } from 'react-apollo'
import {
  CREATE_BASE_ROUTE_FOR_CLIENT,
  DELETE_BASE_ROUTE_FOR_CLIENT
} from '../Mutations/Mutations'
// Styles
import { makeStyles } from '@material-ui/core/styles'
// import clsx from 'clsx'
// Utils
// import { formatNumber } from '../Utils/utils'
import ClientResultCSV from '../ClientResults/ClientResultCSV'
import ClientResultExcel from '../ClientResults/ClientResultExcel'
import ClientResultPdf from '../ClientResults/ClientResultPdf'
import ClientResultHorizontal from '../ClientResults/ClientResultHorizontal'
import ShowClientRoutesVertical from './ShowClientRoutesVertical'
import ShowClientRoutesHorizontal from './ShowClientRoutesHorizontal'
import ClientRoutesCSV from './ClientRoutesCSV'
import ClientRoutesExcel from './ClientRoutesExcel'
import ClientRoutesPdf from './ClientRoutesPdf'
import ShowCnf from './ShowCnf'

// Utils
import SnackBar from '../Utils/SnackBar'
import Switch from '@material-ui/core/Switch'

import { Typography } from '@material-ui/core'
import RootPaper from '../Utils/RootPaper'

const queryHelperStyles = makeStyles((theme) => ({
  initialWidgets: {
    width: '100%'
  },
  textField: {
    textAlign: 'left',
    fontSize: theme.typography.generalFontSize,
    width: 300,
    marginRight: 16
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  paper: {
    height: '30%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  paperBox: {
    // minWidth: '100%',
    height: '100%',
    width: '45%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },  
  adcom: {
    marginLeft: 'auto'
  },
  adcomField: {
    width: 100
  },
  userName: {
    fontSize: theme.typography.generalFontSize + 5,
    display: 'flex',
    alignItems: 'center'
  },
  switches: {
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    justifyContent: 'space-between'
  }
}))

const ShowClientRoutes = () => {
  const classes = queryHelperStyles()

  const [cnf, setCnf] = useState(false)
  const [vertical, setVertical] = useState(false)

  return (
    <>
      <QueryHelper
        query={GET_LOGGED_CLIENT}
        fetchPolicy='cache-and-network'
      >
        {({ data }) => {
          if (data) {
            const {
              getLoggedClient: { user, commodities }
            } = data

            const adcom = user.userSetting ? user.userSetting.adcom : 0

            return (
              <Grid container style={{ height: '50px'}}>

                <Helmet>
                  <title>{`${user.name}'s Routes`}</title>
                </Helmet>

                <RootPaper
                  elevation={1}
                  className={classes.paper}
                >
                  <Box className={classes.paperBox}>

                    <Box className={classes.userName} >
                      <Typography variant='contained'>
                        {user.name}
                      </Typography>
                    </Box>

                    <Box className={classes.adcom}>
                      <ClientRoutesCSV
                        adcom={adcom}
                        client={user.id}
                        clientName={user.name}
                      />
                    </Box>
                    <Box className={classes.adcom}>
                      <ClientRoutesExcel
                        adcom={adcom}
                        client={user.id}
                        clientName={user.name}
                      />
                    </Box>
                    <Box className={classes.adcom}>
                      <ClientRoutesPdf
                        client={user.id}
                        clientName={user.name}
                      />
                    </Box>
                  </Box>
                </RootPaper>

                <Box width='350px' className={classes.switches} >
                  <Box
                    component='label'
                    container
                    alignItems='center'
                    display='flex'
                    flexDirection='row'
                    flexWrap='nowrap'
                  >
                    <Grid>Freight</Grid>
                    <Grid>
                      <Switch
                        checked={cnf}
                        onChange={(e) => setCnf(e.target.checked)}
                        value={cnf}
                      />
                    </Grid>
                    <Grid>CNF</Grid>
                  </Box>

                  {!cnf && (
                    <Box
                      component='label'
                      container
                      alignItems='center'
                      display='flex'
                      flexDirection='row'
                      flexWrap='nowrap'
                    >
                      <Grid item>Horizontal</Grid>
                      <Grid item>
                        <Switch
                          checked={vertical}
                          onChange={(e) => setVertical(e.target.checked)}
                          value={vertical}
                        />
                      </Grid>
                      <Grid item>Vertical</Grid>
                    </Box>
                  )}
                </Box>
                {
                  cnf && (
                    <>
                      <Grid item xs={12}>
                        <ShowCnf
                          client={user.id}
                          commodities={commodities}
                          adcom={adcom || 0}
                        />
                      </Grid>
                    </>
                  )
                }
                {
                  !cnf && (
                    <Grid item xs={12}>
                      <ClientResultsContainerQueryHelper
                        adcomValue={adcom}
                        client={user.id}
                        vertical={vertical}
                      />
                    </Grid>
                  )
                }
              </Grid>
            )
          }
          return <div />
        }}
      </QueryHelper>
    </>
  )
}


const ClientResultsContainerQueryHelper = (props) => {
  const { client, vertical, adcomValue } = props

  const [error, setError] = React.useState({ open: false, message: '' })
  const input = {
    pdf: true
  }

  const refetchQueries = [
    {
      query: GET_ROUTES_FROM_LOGGED_CLIENT,
      variables: { input }
    }
  ]

  // If no user return message
  if (!client) {
    return <div>There has been an error! Please contact the Administrator.</div>
  }

  return (
    <QueryHelper
      linearProgress
      query={GET_ROUTES_FROM_LOGGED_CLIENT}
      variables={{ input }}
    >
      {({ data }) => {
        if (data) {
          const {
            getRoutesFromLoggedClient: {
              clientRoutes,
              report
            }
          } = data
          return (
            <>
              <RenderResults
                adcom={adcomValue || 0}
                client={client}
                clientRoutes={clientRoutes}
                hasNextPage={clientRoutes.hasNextPage}
                offset={clientRoutes.clientRoutes.length}
                quarterArray={clientRoutes.quarterArray}
                report={report}
                vertical={vertical}
              />
              <SnackBar
                open={error.open}
                onClose={() => setError({ open: false, message: '' })}
                variant='error'
                message={error.message}
              />
            </>
          )
        }
      }}
    </QueryHelper>
  )
}

const RenderResults = (props) => {
  const {
    adcom,
    client,
    clientRoutes,
    hasNextPage,
    offset,
    report,
    setOffset,
    vertical,
    quarterArray
  } = props

  if (!vertical) {
    return (
      <ShowClientRoutesHorizontal
        adcom={adcom}
        client={client}
        clientRoutes={clientRoutes}
        hasNextPage={hasNextPage}
        report={report}
        quarterArray={quarterArray}
      />
    )
  } else {
    return (
      <ShowClientRoutesVertical
        adcom={adcom}
        client={client}
        clientRoutes={clientRoutes}
        hasNextPage={hasNextPage}
      />
    )
  }
}

export default withRouter(ShowClientRoutes)
