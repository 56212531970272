import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import { LinearProgress } from '@material-ui/core'
// Styles
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 9999,
  }
}))

const RootLinearProgress = props => {
  const { open } = props
  const classes = useStyles()
  return (
    open && <LinearProgress className={classes.root} color='secondary' />
  )
}

RootLinearProgress.defaultProps = {
  open: false
}

RootLinearProgress.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default RootLinearProgress
