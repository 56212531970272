import React, { useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

// Content
import { show as content } from './RoutesContent'
// Queries
import QueryHelper from '../Queries/QueryHelper'
import { GET_ROUTES } from '../Queries/Queries'
// Mutations
import { DELETE_ROUTE } from '../Mutations/Mutations'
// Utils
import AddButton from '../Utils/AddButton'
import AddIconButton from '../Utils/AddIconButton'
import RootDataGrid, { MenuItem } from '../Utils/RootDataGrid'
import VerifyPermissionsHOC from '../Utils/VerifyPermissionsHOC'
import Summary from '../Summary/ShowSummary'
// Material
import Grid from '@material-ui/core/Grid'
import TrieSearch from 'trie-search'
import { UserContext } from '../App/AppContext'
import SearchTextInput from '../Utils/SearchTextInput'
import ShowRouteAgGrid from './ShowRouteAgGrid'

const ShowRoutes = props => {
  // const kFormatter = (num) => {
  //   return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
  // }

  const getVesselSector = (dwt, shipSectors) => {
    try {
      for (let i = 0; i < shipSectors.length; i++) {
        const element = shipSectors[i]
        if (element.dwtMin <= dwt && element.dwtMax >= dwt) {
          return (element.shipSectorName)
        }
      }
      return ''
    } catch (err) {
      return ''
    }
  }

  const getPortData = (port, ports) => {
    for (let i = 0; i < ports.length; i++) {
      if (port === ports[i].id) {
        return { ...ports[i] }
      }
    }
  }

  const history = useHistory()
  return (
    <>
      <Helmet>
        <title>Route Library</title>
      </Helmet>
      <QueryHelper
        query={GET_ROUTES}
      >
        {({ data }) => {
          const { getRoutes: { routes, shipSectors, ports, timeCountings } } = data
          let maxClients = 0
          let maxLoadPorts = 0
          let maxDischargePorts = 0
          const routesArray = routes.map(route => {
            const vesselSector = getVesselSector(route.vessel.dwt, shipSectors)
            const routeName = route.routeName
            const codeNameLong = route.codeNameLong ? route.codeNameLong : '-'
            // const intake = getRouteResultProperties('intake', route.routeResults)
            // const governingDraft = getRouteResultProperties('governingDraft', route.routeResults)
            // const discharge = route.dischargePorts.length > 0 ? getPortData(route.dischargePorts[0], ports) : { portName: '-', country: { name: '-' } }// route.dischargePortArray[0]
            const discharge = route.dischargePorts.length > 0 ? route.dischargePorts.map(item => getPortData(item, ports)) : { portName: '-', country: { name: '-' } }
            // const load = route.loadPorts.length > 0 ? getPortData(route.loadPorts[0], ports) : route.loadPortArray[0]
            const load = route.loadPorts.length > 0 ? route.loadPorts.map(item => getPortData(item, ports)) : route.loadPortArray
            const ballast = route.viaBallastPorts.length > 0 ? getPortData(route.viaBallastPorts[0], ports) : '-'
            const loadPortsLength = route.loadPorts.length
            const dischargePortsLength = route.dischargePorts.length
            const clientsLength = route.clients.length
            if (clientsLength > maxClients) {
              maxClients = clientsLength
            }
            if (loadPortsLength > maxLoadPorts) {
              maxLoadPorts = loadPortsLength
            }
            if (dischargePortsLength > maxDischargePorts) {
              maxDischargePorts = dischargePortsLength
            }
            let clients = {}
            for (let index = 0; index < clientsLength; index++) {
              clients = {
                ...clients,
                [`client${index}`]: route.clients[index].name
              }
            }
            let loadPorts = {}
            for (let index = 0; index < loadPortsLength; index++) {
              loadPorts = {
                ...loadPorts,
                [`loadPort${index}`]: load[index].portName,
                [`loadPortDistanceOnly${index}`]: load[index].distanceOnly
              }
            }
            let dischargePorts = {}
            for (let index = 0; index < dischargePortsLength; index++) {
              dischargePorts = {
                ...dischargePorts,
                [`dischargePort${index}`]: discharge[index].portName,
                [`dischargePortDistanceOnly${index}`]: discharge[index].distanceOnly
              }
            }
            return {
              ...route,
              ...clients,
              ...loadPorts,
              ...dischargePorts,
              routeName,
              codeNameLong,
              code: route.name,
              vesselSector: vesselSector || '-',
              forwardCurve: route.forwardCurve ? route.forwardCurve.forwardCurveName : '-',
              // intake: intake ? formatNumber({ number: intake }) : '-',
              // governingDraft: governingDraft ? formatNumber({ number: governingDraft }) : '-',
              deliveryPort: route.deliveryPort ? route.deliveryPort.portName : '-',
              deliveryDistanceOnly: !!(route.deliveryPort && route.deliveryPort.distanceOnly),
              loadCountry: load[0].country.name,
              // loadPort: load.map(item => item.portName),
              viaBallastPort: ballast.portName,
              viaBallastePortDistanceOnly: ballast.distanceOnly,
              dischargeCountry: discharge[0].country.name,
              // dischargePort: discharge.map(item => item.portName),
              commodity: route.commodity ? route.commodity.commodityName : '-'
            }
          })

          return (
            <>
              <ShowRouteTable
                maxClients={maxClients}
                maxLoadPorts={maxLoadPorts}
                maxDischargePorts={maxDischargePorts}
                history={history}
                routes={routesArray}
                timeCountings={timeCountings}
              />
            </>
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowRouteTable = (props) => {
  const { history, maxClients = 0, maxLoadPorts = 0, maxDischargePorts = 0, routes = [], timeCountings } = props
  const { setUser } = React.useContext(UserContext)
  const [search, setSearch] = React.useState('')
  const [searchRoutes, setSearchRoutes] = React.useState(routes)
  const clientsKey = []
  for (let index = 0; index < maxClients; index++) {
    clientsKey.push(`client${index}`)
  }
  const loadPortKeys = []
  for (let index = 0; index < maxLoadPorts; index++) {
    loadPortKeys.push(`loadPort${index}`)
  }
  const dischargePortKeys = []
  for (let index = 0; index < maxDischargePorts; index++) {
    dischargePortKeys.push(`dischargePort${index}`)
  }
  const ts = new TrieSearch(['vesselSector', 'commodity', 'routeName', ...clientsKey, ...loadPortKeys, ...dischargePortKeys], {
    idFieldOrFunction: 'id' // Required to uniquely identify during union (AND)
  })
  ts.addAll(routes)
  const searchRef = React.useRef(search)
  React.useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchRoutes(routes)
      } else {
        const searchArray = search.split(',')
        const foundRoutes = ts.get(searchArray, TrieSearch.UNION_REDUCER)
        setSearchRoutes(foundRoutes)
      }
    }
  }, [search, routes, ts])

  const [openSummary, setOpenSummary] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOpenSummary = (event) => {
    setAnchorEl(event.currentTarget)
    setOpenSummary(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpenSummary(false)
  }

  return (
    <Grid>
      <SearchTextInput
        autoFocus
        label='Search'
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          // to={content.createButton.link}
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>
      <ShowRouteAgGrid
        history={history}
        routes={searchRoutes}
        handleOpenSummary={handleOpenSummary}
        timeCountings={timeCountings}
      />
      {/* <RootDataGrid
        deleteCalcPageLink
        fixedWidth
        columns={content.dataGrid.columns}
        contextChildren={props =>
          <>
            <VerifyPermissionsHOC
              moduleName='routeResults'
              type='buttons'
              permission='view'
            >
              <MenuItem
                data={{ rowIdx: props.rowIdx, idx: props.idx }}
                onClick={() => history.push(`/calculation-page/${props.rows[props.data.rowIdx].id}`)}
              // onClick={() => console.log(props)}
              >
                Go to calc
              </MenuItem>
            </VerifyPermissionsHOC>
            <VerifyPermissionsHOC
              moduleName='routeResults'
              type='buttons'
              permission='voyageOffer'
            >
              <MenuItem
                data={{ rowIdx: props.rowIdx, idx: props.idx }}
                // onClick={() => history.push(`/calculation-page/${props.rows[props.data.rowIdx].id}`)}
                // onClick={() => console.log(props)}
                onClick={() => setUser({ type: 'voyage-offer', value: true, voyageRoute: props.rows[props.data.rowIdx].id })}
              >
                Voyage Offer
              </MenuItem>
            </VerifyPermissionsHOC>
            <VerifyPermissionsHOC
              moduleName='routeResults'
              type='buttons'
              permission='view'
            >
              <MenuItem
                data={{ rowIdx: props.rowIdx, idx: props.idx }}
                // onClick={() => history.push(`/calculation-page/${props.rows[props.data.rowIdx].id}`)}
                // onClick={() => console.log(props)}
                onClick={handleOpenSummary}
              >
                Summary
              </MenuItem>
              <Summary route={props.rows[props.data.rowIdx]} timeCountings={timeCountings} open={openSummary} anchorEl={anchorEl} handleClose={handleClose} />
            </VerifyPermissionsHOC>
          </>}
        deleteMutation={DELETE_ROUTE}
        editLink={content.update.link}
        name={content.module.name}
        refetchQueries={[{ query: GET_ROUTES }]}
        rows={searchRoutes}
        rowsCount={content.dataGrid.row}
        type='buttons'
        moduleName='routes'
      /> */}
    </Grid>
  )
}

export default withRouter(ShowRoutes)
