// React 
import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
// Material UI
import MuiAlert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Snackbar from '@material-ui/core/Snackbar'

import { formatNumber } from '../Utils/utils'

// Custom Alert
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FuturePrices = props => {
  const { openPrices, setOpenPrices, apiFuture } = props
  const handleClose = () => {
    setOpenPrices(false)
  }

  return (
    <>
      <Dialog
        open={openPrices}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '1.5%',
          }
        }}
      >
        <DialogTitle>
          <Typography>
            <b>
              Last future prices saved
            </b>
          </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            margin: '0px 27px 27px 27px'
          }}
        >
          <Table>
            {Object.entries(apiFuture).map(([key, value], i) => {
              return (
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                >
                  <TableRow>
                    <TableCell style={{ width: '70px' }}>
                      <Typography align='left'>{key}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography align='left' style={{ width: '70px' }} >{formatNumber({ number: parseFloat(value), format: '0.00' })}</Typography>
                    </TableCell>
                  </TableRow>
                </Box>
              )
            })}
          </Table>
        </DialogContent>
      </Dialog>
    </>
  )
}

FuturePrices.propTypes = {

}

export default FuturePrices
