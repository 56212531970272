import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const RootCheckbox = ({ checked, onChange, label, value }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={e => onChange(e.target.checked)}
          color='primary'
          value={value}
        />
      }
      label={label}
    />
  )
}



export default RootCheckbox
