import React from 'react'
import PropTypes from 'prop-types'
// Context
import { UserContext } from '../App/AppContext'
// Clsx
import clsx from 'clsx'
// Material UI
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
// React image
import ReactImage from 'react-image'
// Utils
import { formatNumber, formatNumberWithMultiplesOfFive, formatNumberWithMultiplesOfTwentyFive } from '../Utils/utils'

/**
 * @description Function to get the actual month from a Position Date
 */
const getActualMonth = (posdate) => {
  if (posdate.charAt(1) === 'h') {
    const splittedPosdate = posdate.split('h')

    return splittedPosdate[1]
  }

  return posdate
}

const cardClientStyles = makeStyles(theme => ({
  rootCard: {
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
    borderRadius: '7px',
    padding: '10px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      maxWidth: 420,
      width: 420,
      // height: 570,
      marginRight: 2,
      marginBottom: 2,
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  cardContent: {
    padding: '16px 0'
  },
  cellRightAlign: {
    textAlign: 'right'
  },
  correctCell: {
    color: theme.palette.common.green
  },
  wrongCell: {
    color: theme.palette.common.red
  },
  routeResultsCell: {
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    }
  }
}))

const LoadingCard = props => {
  const { classes } = props
  return (
    <Card className={classes.rootCard} style={{ width: 420 }}>
      <CardContent className={classes.cardContent}>
        <Table size='small'>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <Skeleton width='100%' />
              </TableCell>
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
            <TableRow>
              <Skeleton width='100%' />
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

const ClientCard = props => {
  const classes = cardClientStyles()
  const { adcom, client, headers, route, setUser } = props
  console.log('Route', route)
  return (
    <Card className={classes.rootCard}>
      <CardContent className={classes.cardContent}>
        <Table size='small'>
          <TableBody>
            <TableRow style={{ borderBottom: 'none' }}>
              {
                route.routeData.loadPort && (
                  <TableCell colSpan={2}>{route.routeData.loadPort.text}</TableCell>
                )
              }
              {
                route.routeData.vessel && (
                  <TableCell colSpan={2} align='right'>{route.routeData.vessel.text}</TableCell>
                )
              }
            </TableRow>
            <TableRow style={{ borderBottom: 'none' }}>
              {
                route.routeData.dischargePort && (
                  <TableCell colSpan={2}>{route.routeData.dischargePort.text} {route.routeData.draft?.text}</TableCell>
                )
              }
              {
                route.routeData.sector && (
                  <TableCell colSpan={2} align='right'>{route.routeData.sector.text}</TableCell>
                )
              }
            </TableRow>
            <TableRow style={{ borderBottom: 'none' }}>
              {
                route.routeData.loadDischTerm && (
                  <TableCell colSpan={2}>{route.routeData.loadDischTerm.text}</TableCell>
                )
              }
              {
                route.routeData.intake && (
                  <TableCell colSpan={2} align='right'>{route.routeData.intake.text}</TableCell>
                )
              }
            </TableRow>
            <TableRow>
              <TableCell align='right' colSpan={2}>Freight</TableCell>
              <TableCell align='right'>&#916; Daily</TableCell>
              <TableCell align='right'>&#916; Weekly</TableCell>
            </TableRow>
            {
              route.routeResults.map((result, j) => {
                // The Result's rate ande it's Position Date
                let text = result.rate
                const positionDate = result.positionDate.name

                // Get the actual month without halves
                const actualMonth = getActualMonth(positionDate)

                // Yesterday and last week months without halves
                const yesterdayMonths = (result.previousRouteResults.length >= 1) && result.previousRouteResults[0].positionDates.map(item => getActualMonth(item))
                const lastWeekMonths = (result.previousRouteResults.length === 2) && result.previousRouteResults[1].positionDates.map(item => getActualMonth(item))

                // console.log(yesterdayMonths)
                // console.log(lastWeekMonths)

                const resultLength = route.routeResults.length

                // The Indexes that match the current result's month name (without halves)
                let yesterdayCorrectIndex, lastWeekCorrectIndex
                if (j === resultLength - 1) {
                  // If last position, then get the second time the month occurrs
                  yesterdayCorrectIndex = (result.previousRouteResults.length >= 1) && yesterdayMonths.indexOf(actualMonth, 2)
                  lastWeekCorrectIndex = (result.previousRouteResults.length === 2) && lastWeekMonths.indexOf(actualMonth, 2)

                  // In case the month has changed... if the index of the result is not the last, then it means position dates are from last month, in that case make them -1
                  yesterdayCorrectIndex = yesterdayCorrectIndex === 13 ? yesterdayCorrectIndex : -1
                  lastWeekCorrectIndex = lastWeekCorrectIndex === 13 ? lastWeekCorrectIndex : -1
                } else {
                  // If any other position, then get the new index of the month
                  yesterdayCorrectIndex = (result.previousRouteResults.length >= 1) && yesterdayMonths.indexOf(actualMonth)
                  lastWeekCorrectIndex = (result.previousRouteResults.length === 2) && lastWeekMonths.indexOf(actualMonth)
                }

                // Yesterday and last week's rates
                let yesterdayRate = (result.previousRouteResults.length >= 1) && formatNumber({ number: yesterdayCorrectIndex !== -1 ? parseFloat(result.previousRouteResults[0].rates[yesterdayCorrectIndex] * (1 + adcom / 100)) : 0 })
                let lastWeekRate = (result.previousRouteResults.length === 2) && formatNumber({ number: lastWeekCorrectIndex !== -1 ? parseFloat(result.previousRouteResults[1].rates[lastWeekCorrectIndex] * (1 + adcom / 100)) : 0 })

                const onClick = () => {
                  setUser({ type: 'voyage-offer', value: true, voyageRoute: result.route, voyageClient: client, voyageIndex: j, adcomFromClientResult: adcom, voyageFromClientResults: true })
                }
                try {
                  // Calculate rate with adcom
                  let formattedRate = formatNumber({ number: parseFloat(text * (1 + adcom / 100)) });
                  ({ formattedRate: yesterdayRate } = formatNumberWithMultiplesOfTwentyFive({ formattedRate: `${yesterdayRate}` }));
                  ({ formattedRate: lastWeekRate } = formatNumberWithMultiplesOfTwentyFive({ formattedRate: `${lastWeekRate}` }));

                  // Format the rate with decimals with multiple of five
                  ({ formattedRate } = formatNumberWithMultiplesOfTwentyFive({ formattedRate }))
                  
                  text = formatNumber({ number: formattedRate })
                } catch (e) {
                  console.log(e)
                  text = result.text
                }
                let difference, correct, wrong
                try {
                  difference = formatNumber({ number: text - yesterdayRate })
                  if (difference !== '0.00') {
                    if (difference[0] === '-') {
                      wrong = true
                    } else {
                      correct = true
                    }
                  }
                } catch (e) {
                  difference = undefined
                }

                return (
                  <TableRow key={j}>
                    <TableCell align='right'>{headers.headersPositionDates[j].text}</TableCell>
                    <TableCell onClick={onClick} align='right' className={classes.routeResultsCell}>{formatNumber({ number: text })}</TableCell>
                    {/* <TableCell align='right'>{(yesterdayRate) ? formatNumber({ number: (parseFloat(text) - parseFloat(yesterdayRate)) }) : '-'}</TableCell> */}
                    <TableCell
                      align='right'
                      className={clsx(classes.cellRightAlign, {
                        [classes.correctCell]: correct,
                        [classes.wrongCell]: wrong
                      })}
                    >
                      {difference ? formatNumber({ number: difference }) : `${difference}`}
                    </TableCell>
                    <TableCell className={classes.cellRightAlign} align='right'>{(lastWeekRate) ? formatNumber({ number: (parseFloat(text) - parseFloat(lastWeekRate)) }) : '-'}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'no-wrap'
  },
  container: {
    marginTop: `${theme.spacing(2)}px`,
    marginRight: `${theme.spacing(2)}px`,
    marginBottom: `${theme.spacing(2)}px`
  },
  resultsContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  routeName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  flag: {
    marginRight: theme.spacing(2),
    width: 32
  }
}))

const ClientResultVertical = props => {
  const classes = useStyles()
  const { adcom, client, clientRoutes, hasNextPage, report } = props
  const countries = clientRoutes.clientRoutes
  const headers = clientRoutes.headers
  const { setUser } = React.useContext(UserContext)
  return (
    <Box className={classes.root}>
      {
        countries.map((country, i) => {
          const flag = country.flag
          const name = country.name
          const routes = country.routes
          return (
            <Box className={classes.container} key={i}>
              <Box className={classes.routeName}>
                <ReactImage className={classes.flag} src={`/flags/${flag}.png`} alt={name} />
                <Typography>{name}</Typography>
              </Box>
              <Box className={classes.resultsContainer}>
                {
                  routes.map((route, index) => {
                    return (
                      <ClientCard
                        adcom={adcom}
                        client={client}
                        headers={headers}
                        key={index}
                        route={route}
                        setUser={setUser}
                      />
                    )
                  })
                }
              </Box>
            </Box>
          )
        })
      }
      {
        hasNextPage && (
          <Box className={classes.container}>
            <Box className={classes.resultsContainer}>
              <LoadingCard classes={classes} />
            </Box>
          </Box>
        )
      }
    </Box>
  )
}

ClientResultVertical.propTypes = {

}

export default ClientResultVertical
