// React
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { ApolloConsumer } from 'react-apollo'
import { Link, withRouter } from 'react-router-dom'
// Components
import Menu from './Menu'
// Content
import content from './HeaderContent'
// Context
import { UserContext } from '../App/AppContext'
// Material UI
import AppBar from '@mui/material/AppBar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import TuneIcon from '@mui/icons-material/Tune'
import Tooltip from '@material-ui/core/Tooltip'

// Mutations
import MutationHelper from '../Mutations/MutationHelper'
import { LOGOUT } from '../Mutations/Mutations'
// Roles
import RolesContent from '../App/AppContent'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// Utils
import { AUTH_TOKEN, USER } from '../../constants'
import RootButton from '../Utils/RootButton'
import { getRoleValue } from '../Utils/utils'
import { VoyageOffer } from '../VoyageOffer/VoyageOffer'

import Popover from '@material-ui/core/Popover'
import { versions } from '../VersionInformation/versions'
import { result } from 'validate.js'

const drawerWidth = 240

const useDialogStyles = makeStyles(theme => (
  {
    dialogContainer: {
      width: '300px !important',
      maxHeight: '1000px !important',
      paddingTop: '0px'
    },
    dialogTitle: {
      paddingBottom: '0px'
    }
  }
))

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    position: 'static'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: '40px !important'
  },
  content: {
    // flexGrow: 1,
    // maxWidth: '100%',
    width: '100%',
    // padding: theme.spacing(1),
    padding: 24,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('sm')]: {
      // padding: theme.spacing(3),
    }
    // marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
    // marginLeft: 0
  },
  img: {
    height: 64
  },
  link: {
    color: 'inherit',
    padding: theme.spacing(1),
    textDecoration: 'none',
    fontSize: theme.typography.generalFontSize
  },
  active: {
    textDecoration: 'underline'
  },
  calcHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: '20px !important'
  },
  shortCutContainer: {
    display: 'flex',
    overflowX: 'scroll',
    overflowY: 'hidden',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden'
    }
  },
  toolbar: {
    minHeight: 40,
    height: 'auto'
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'inherit'
    },
    fontSize: theme.typography.generalFontSize + 2
  },
  popover: {
    maxWidth: '1000px',
    maxHeight: '1000px',
    borderRadius: 3
  },
  voyageOfferBox: {
    minWidth: '800px',
    maxHeight: '400px'
  },
  settingsIcon: {
    '&:hover': {
      animation: '$animation',
      animationDuration: '2s',
      animationIterationCount: 'infinite'
    }
  },
  '@keyframes animation': {
    '0%': { transform: 'scale(1)' },
    '50%': { transform: 'scale(1.2)' },
    '100%': { transform: 'scale(1)' }
  }
}))

const Logout = props => {
  const { closeDrawer, history } = props
  const { setUser } = useContext(UserContext)

  const removeItems = ({ client }) => {
    localStorage.removeItem(AUTH_TOKEN)
    setUser({ type: 'reset' })
    localStorage.removeItem(USER)
    localStorage.clear()
    client.resetStore()
    closeDrawer(false)
    history.push('/')
  }

  return (
    <ApolloConsumer>
      {(client) => (
        <MutationHelper
          mutation={LOGOUT}
          onCompleted={() => removeItems({ client })}
          onError={() => removeItems({ client })}
        >
          {(mutation, result) => {
            return (
              <Box>
                <RootButton
                  style={{
                    borderRadius: '5px',
                    backgroundColor: 'rgba(228, 74, 32, 1)',
                    color: 'white',
                    marginRight: '15px'
                  }}
                  alignItems='flex-end'
                  disabled={result.loading}
                  justifyContent='center'
                  onClick={() => mutation()}
                >
                  {content.logoutButton}
                </RootButton>
              </Box>
            )
          }}
        </MutationHelper>
      )}
    </ApolloConsumer>
  )
}

Logout.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

const PersistentDrawerLeft = props => {
  const { children } = props
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [anchor, setAnchor] = React.useState(null)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [currentSection, setCurrentSection] = React.useState('')
  const isLogged = localStorage.getItem(AUTH_TOKEN)

  const current = props.history.location.pathname.split('/')[1]
  const handleClickOpen = (e) => {
    setOpenDialog(true)
    setAnchor(e.currentTarget)
  }

  const handleClose = () => {
    setOpenDialog(false)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  let menuItems = []
  let headerItems = []
  const { user } = useContext(UserContext)
  let currentRole = null
  if (user) {
    currentRole = getRoleValue({ role: user.role })
  }
  if (currentRole) {
    menuItems = RolesContent.roles[currentRole].menuItems
    headerItems = RolesContent.roles[currentRole].headerItems
  }

  if (props.location.pathname === '/') {
    if (!isLogged) {
      return (
        <main>
          {children}
        </main>
      )
    }
  }

  if (props.location.pathname === '/login') {
    return (
      <main>
        {children}
      </main>
    )
  }

  return (
    <div className={classes.root}>
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar
          className={classes.toolbar}
        >
          {
            isLogged &&
              <IconButton
              color='inherit'
              aria-label='Open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          }
          {/* <Typography variant='h6' noWrap className={classes.title}>
            <Link to='/' className={classes.link}>
              {content.title} {currentSection ? `- ${currentSection}` : ''}
            </Link>
          </Typography> */}
          <Box className={classes.shortCutContainer} wrap='nowrap'>
            {
              headerItems.map((headerItem, index) => {
                return (
                  <Link
                    to={headerItem.link}
                    className={clsx(classes.link, {
                      [classes.active]: headerItem.activeLinks.includes(`/${current}`)
                    })}
                    onClick={() => {
                      setCurrentSection(headerItem.text)
                    }}
                    key={index}
                  >
                    {headerItem.text}
                  </Link>
                )
              })
            }
          </Box>
          <Box justifyContent='flex-end' alignItems='flex-end' flexGrow='1' display='flex' wrap='nowrap'>
            {
              currentRole !== 'client' && (
                <Link to='/system-settings' style={{ marginRight: '10px'}}>
                  <Tooltip title='System Settings' arrow style={{ borderRadius: '5%'}}>
                    <TuneIcon className={classes.settingsIcon} style={{ color: 'white', marginBottom: '2px' }}/>
                  </Tooltip>
                </Link>
              )
            }
            <Link
              to='/versions'
              className={clsx(classes.link, {
                [classes.active]: ['/versions'].includes(`/${current}`)
              })}
            >
              V{versions[0].version}
            </Link>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        anchor='left'
        open={open}
        onClick={() => handleDrawerClose()}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {/* <div className={classes.drawerHeader}>
          <ReactImage
            src={ content.logoSrc }
            className={classes.img}
          />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider /> */}
        <Menu items={menuItems} setCurrentSection={setCurrentSection} closeDrawer={() => handleDrawerClose()} />
        <Logout closeDrawer={() => handleDrawerClose()} history={props.history} />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        {current !== 'calculation-page' ? <div className={classes.drawerHeader} /> : <div className={classes.calcHeader} />}
        {children}
      </main>
      <VoyageOfferDialog
        anchorEl={anchor}
        open={openDialog}
        handleClose={handleClose}
        styles={classes}
      />
    </div>
  )
}

const VoyageOfferDialog = ({ open, handleClose, anchorEl }) => {
  const classes = useDialogStyles()
  return (
    <Popover
      variant='popover'
      className={classes.popover}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
      // anchorReference='anchorPosition'
      // anchorPosition={{ top: 200, PersistentDrawerLeft: 0 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <Box className={classes.voyageOfferBox}>

        <DialogTitle className={classes.dialogTitle} id='alert-dialog-title'>Voyage Offer</DialogTitle>
        <DialogContent className={classes.dialogContainer}>
          <VoyageOffer
            handleClose={handleClose}
          />
        </DialogContent>
      </Box>
    </Popover>
  )
}

export default withRouter(PersistentDrawerLeft)
