import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'

const RemoveIconButton = props => {
  const { alignItems, justifyContent, onClick, ...rest } = props
  return (
    <Box display='flex' justifyContent={justifyContent} height='100%' alignItems={alignItems}>
      <IconButton onClick={onClick} {...rest} aria-label="delete">
        <DeleteIcon />
      </IconButton>
    </Box>
  )
}

RemoveIconButton.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center'
}

RemoveIconButton.propTypes = {
  justifyContent: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default RemoveIconButton

