import React, { useState, forwardRef, useImperativeHandle } from 'react'
import DateFnsUtils from '@date-io/date-fns'
// import { format } from 'date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

export default forwardRef((props, ref) => {
  const [selectedDate, setSelectedDate] = useState(null)
  const dateRef = React.useRef()
  function handleDateChange (d) {
    if (d && !isNaN(d.getTime())) {
      d.setHours(0, 0, 0, 0)
      setSelectedDate(d)
    } else {
      setSelectedDate(null)
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        let dateString = null
        if (selectedDate) {
          dateString = selectedDate
        }
        return dateString
      },
      // isCancelAfterEnd: () => {
      //   return !selectedDate
      // },
      afterGuiAttached: () => {
        if (dateRef.current) {
          dateRef.current.focus()
          dateRef.current.select()
        }
        setSelectedDate(props.value)
      }
    }
  })

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoFocus
        inputProps={{ ref: dateRef }}
        style={{ width: '100%', margin: 0 }}
        id='date-picker-dialog'
        format='dd/MM/yyyy'
        value={selectedDate}
        onChange={handleDateChange}
        variant='inline'
        disableToolbar
        clearable
      />
    </MuiPickersUtilsProvider>
  )
})
