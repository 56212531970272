import gql from 'graphql-tag'

import React, { Fragment, useContext, useEffect, useState, useReducer } from 'react'
// Material UI
import CssBaseline from '@material-ui/core/CssBaseline'
import { Route, Switch, withRouter } from 'react-router-dom'
// import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider"
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
// import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";

// Components
import Header from '../Header/Header'
import Login from '../Login/Login'
import Register from '../Register/Register'
import ShowRoute from '../Route/ShowRoute'
import VoyageOfferDialog from '../VoyageOffer/VoyageOfferDialog'
import VersionInformation from '../VersionInformation/VersionInformation'
import SystemSettings from '../SystemSettings/SystemSettings'
// Content
import AppContent from './AppContent'
// Context
import { UserContext, UserProvider } from './AppContext'
// TODO Later Remove example
import Example from '../Example/Example'
// Shortcut
import { Shortcut, ShortcutProvider } from '@shopify/react-shortcuts'
// Utils
import { getRoleValue } from '../Utils/utils'
import PrivateRoute from '../Utils/PrivateRoute'
import ServerTestRoute from '../Utils/ServerTestRoute'
import SnackBar from '../Utils/SnackBar'
import RootButton from '../Utils/RootButton'

import Import from './Import'
import { createTheme } from '../../theme'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import SubscriptionsApps from './SubscriptionsApp'

const NEW_ROUTES_UPDATED = gql`
  subscription RouteUpdatedSubscription {
    routeUpdated {
      id
      count
      length
    }
  }
`

const AppWithContext = () => {
  return (
    <UserProvider>
      <ShortcutProvider>
        <AppWithRouter />
        <SubscriptionsApps />
      </ShortcutProvider>
    </UserProvider>
  )
}

export default AppWithContext

const App = props => {
  const [routes, setRoutes] = useState([])
  const snackbarReducer = (state, action) => {
    switch (action.type) {
      case 'info':
        return {
          ...state,
          open: true,
          variant: 'info'
        }
      case 'error':
        return {
          ...state,
          open: true,
          variant: 'error'
        }
      case 'success':
        return {
          ...state,
          open: true,
          variant: 'success',
          message: action.message
        }
      case 'close': {
        return {
          ...state,
          open: false,
          variant: '',
          message: ''
        }
      }
      default: break
    }
  }
  const [snackbar, setSnackbar] = useReducer(snackbarReducer, { open: false, message: '' })

  const [updatingRoutes, setUpdatingRoutes] = useState(0)
  const [fontSize, setFontSize] = useState(null)
  const { user, setUser } = useContext(UserContext)
  const [storageTheme, setTheme] = useState(createTheme({ fontSize: 12 }))
  let currentRole = null
  let currentFontSize = 12
  let shortCuts = []
  if (user) {
    currentRole = getRoleValue({ role: user.role })
    currentFontSize = user.fontSize
  }
  if (currentRole) {
    shortCuts = AppContent.roles[currentRole].shortCuts.map(shortCut => {
      let onMatch = () => shortCut.onMatch()
      if (shortCut.link) {
        onMatch = () => props.history.push(shortCut.link)
      }
      return {
        ...shortCut,
        onMatch
      }
    })
  }
  const handleCloseVoyageOffer = () => {
    setUser({
      type: 'voyage-offer',
      value: false
    })
  }

  const _newUpdatingRoutes = subscribeToMore => {
    subscribeToMore({
      document: NEW_ROUTES_UPDATED,
      updateQuery: (previousData, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousData
        }
        const newUpdatingRoute = subscriptionData.data.routeUpdated.routeUpdated
        return Object.assign({}, previousData, {
          getUpdatingRoutes: {
            updatingRoutes: [subscriptionData.data.routeUpdated]
          }
        })
      }
    })
  }
  useEffect(() => {
    if (currentRole) {
      setRoutes(AppContent.roles[currentRole].routes)
    }
  }, [currentRole])

  useEffect(() => {
    if (currentFontSize) {
      setFontSize(currentFontSize)
      setTheme(createTheme({ fontSize: fontSize }))
    }
  }, [currentFontSize, fontSize])
  return (
    <MuiThemeProvider theme={storageTheme}>
      <CssBaseline />
      {
        shortCuts.map((shortCut, index) => {
          return (
            <Shortcut
              key={index}
              held={shortCut.held}
              ordered={shortCut.ordered}
              onMatch={() => shortCut.onMatch()}
            />
          )
        })
      }
      <VoyageOfferDialog open={user.voyageOffer} route={user.voyageRoute} routeName={user.voyageRouteName} voyageDate={user.voyageDate} client={user.voyageClient} adcomFromClientResult={user.adcomFromClientResult} clientResults={user.voyageFromClientResults} handleClose={() => setUser({ type: 'voyage-offer', value: false, voyageRoute: null, voyageRouteName: null, voyageClient: null, voyagePosition: null, adcomFromClienResults: null, voyageFromClientResults: false })} />

      <Header>

        <Switch>
          {
            routes.length === 0 && (
              <PrivateRoute exact path='/' component={ShowRoute} />
            )
          }
          {
            routes.map((route, index) => {
              return (
                <PrivateRoute
                  key={index}
                  exact={route.exact}
                  path={route.path}
                  component={route.component}
                />
              )
            })
          }
          <Route exact path='/import' component={Import} />
          <Route exact path='/example' component={Example} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/system-settings' component={SystemSettings} />
          <Route exact path='/versions' component={VersionInformation} />
        </Switch>
        {
          currentRole && (
            <div style={{ marginTop: 16, position: 'fixed', bottom: 0, display: 'flex', alignItems: 'center' }}>
              <Button
                onClick={() => setUser({
                  type: 'substractFontSize'
                })}
                disabled={user.fontSize === 6 && true}
              >
                A-
              </Button>
              <Typography style={{ fontSize: user.fontSize }}>{user.fontSize}</Typography>
              <Button
                onClick={() => setUser({
                  type: 'addFontSize'
                })}
                disabled={user.fontSize === 24 && true}
              >
                A+
              </Button>
            </div>
          )
        }
      </Header>
    </MuiThemeProvider>

  )
}

const AppWithRouter = withRouter(App)
