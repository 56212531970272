// React
import React from 'react'
// Material UI
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
// Style
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
// Prop types
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
  text: {
    ...theme.typography.body1,
    alignSelf: 'center',
    fontSize: theme.typography.generalFontSize + 1,
    lineHeight: 1,
    textTransform: 'uppercase',
    width: '100%'
  },
  horizontalText: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left'
    }
  },
  noTextTransform: {
    textTransform: 'none'
  },
  textCenter: {
    textAlign: 'center',
    paddingLeft: 0
  }
}))

const BodyTypography = props => {
  const classes = useStyles()
  const {
    children,
    className,
    justify,
    noTextTransform,
    textCenter,
    ...rest
  } = props
  return (
    <Box
      justifyContent={justify}
      display='flex'
      alignItems='center'
      py={2}
      height='100%'
    >
      <Typography
        {...rest}
        className={clsx(classes.text, classes.horizontalText, {
          [classes.noTextTransform]: noTextTransform,
          [classes.textCenter]: textCenter,
          [className]: className
        })}
      >
        {children}
      </Typography>
    </Box>
  )
}

BodyTypography.defaultProps = {
  justify: 'flex-start',
  noTextTransform: false,
  textCenter: false
}

BodyTypography.propTypes = {
  className: PropTypes.string,
  justify: PropTypes.string.isRequired,
  noTextTransform: PropTypes.bool.isRequired,
  textCenter: PropTypes.bool.isRequired
}

export default BodyTypography