import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import Box from '@material-ui/core/Box'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'

const AddIconButton = props => {
  const { alignItems, justifyContent, onClick, ...rest } = props
  return (
    <Box display='flex' justifyContent={justifyContent} height='100%' alignItems={alignItems}>
      <IconButton onClick={onClick} {...rest} aria-label='add'>
        <AddIcon />
      </IconButton>
    </Box>
  )
}

AddIconButton.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center'
}

AddIconButton.propTypes = {
  justifyContent: PropTypes.string,
  onClick: PropTypes.func.isRequired
}

export default AddIconButton
