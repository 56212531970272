import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

const useStylesAutoComplete = makeStyles(theme => ({
  root: {
    width: 150
  },
  noMargin: {
    margin: 0
  },
  noMarginAutoComplete: {
    width: '100%',
    height: '100%',
    margin: 0,
    '& .MuiFormControl-root': {
      margin: 0
    }
  },
  marginBottomAutocomplete: {
    margin: 0,
    marginBottom: 8,
    '& .MuiFormControl-root': {
      margin: 0,
      marginBottom: 8
    }
  }
}))

const filter = createFilterOptions()

const secureString = (value) => {
  if (typeof value === 'string') {
    return value
  } else if (typeof value === 'object') {
    return value?.inputValue
  } else {
    return null
  }
}

export default forwardRef((props, ref) => {
  const { sameOwner, setSameOwner } = props

  const [value, setValue] = useState({ id: null, name: null })
  const [inputValue, setInputValue] = useState('')
  const {mutationFn, setLocalValue, handleTab} = props
  const [focus, setFocus] = useState(false)

  const firstRender = useRef(true)

  const chosenManually = useRef(true)

  const classes = useStylesAutoComplete()

  const onChangeHandler = (event, newValue) => {
    
    if (setSameOwner) {
      setSameOwner({
        row: props.rowIndex,
        owner: newValue?.inputValue
      })
    }

    if (typeof newValue === 'string') {
      setLocalValue(newValue?.inputValue)
      setValue(newValue?.inputValue)
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      chosenManually.current = true
      setLocalValue(newValue?.inputValue)
      setValue(newValue?.inputValue)
    } else {
      setValue(newValue)
    }
  }

  function onInputChangeHandler(e, inputValue) {
    if (inputValue) {
      setInputValue(inputValue)
      if (firstRender.current === true) {
        firstRender.current = false;
      } else if (chosenManually.current === false) {
        setLocalValue(inputValue)
      } else if (chosenManually.current === true) {
        chosenManually.current = false
      }

    } else {
      setInputValue('')
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getValue: () => {
        return value
      },
      afterGuiAttached: () => {
        // setValue((typeof props.value === 'string') ? props.value : (typeof props.value === 'object') ? props.value?.name : null)
        setValue(props.value)
        setInputValue((typeof props.value === 'string') ? props.value : (typeof props.value === 'object') ? props.value?.name : null)
        setFocus(true)
        if (autocompleteRef.current) {
          autocompleteRef.current.focus()
          autocompleteRef.current.select()
        }
      },
    }
  })

  const autocompleteRef = useRef()


  return (
    <Autocomplete
      focused={focus}
      style={{ padding: '0 10px' }}
      value={value}
      inputValue={inputValue ? inputValue : ''}
      autoSelect={true}
      autoHighlight
      onKeyDown={event => {
        if (event.key === 'Tab' || event.key === 'Enter') {
          handleTab && handleTab()
        }
      }}
      onHighlightChange={(event, option) => {
        if (option && event && event.key !== 'tab') {
          chosenManually.current = true
          setLocalValue && setLocalValue(option)
        }
      }}
      onChange={(event, newEvent) => onChangeHandler(event, newEvent)}
      onInputChange={(event, newEvent) => onInputChangeHandler(event, newEvent)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)

        // Suggest the creation of a new value
        if (params && params.inputValue && params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`
          })
        }

        return filtered
      }}
      className={classes.noMarginAutoComplete}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id='free-solo-with-text-demo'
      options={props.options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'object' && option.id === null && option.name === null){
          return ""
        }
        if (typeof option === 'string') {
          return option
        }
        // Add "xxx" option created dynamically
        if (option?.inputValue) {
          return option.inputValue
        }
        // Regular option
        return option.name
      }}
      renderOption={(option) => option.name}
      freeSolo
      disableClearable={props.label === 'Owners'}
      renderInput={(params) => (
        <TextField  {...params} inputRef={autocompleteRef} placeholder={props.label} />
      )}
    />
  )
})
