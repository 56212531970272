import React from 'react'
import RootColumnLink from '../Utils/RootColumnLink'
import { CREATE_ROUTE_INFORMATION } from '../Queries/Queries'
import { Filters } from 'react-data-grid-addons'
const { SingleSelectFilter } = Filters

const breadCrumbs = [
  {
    link: '/routes',
    name: 'Routes'
  }
]

const defaultColumnProperties = {
  width: 120
}

const module = {
  name: 'Route'
}

const refetchQueries = [{
  query: CREATE_ROUTE_INFORMATION
}
  // {
  //   query: GET_SIMPLE_ROUTES,
  // },{
  //   query={GET_CALCULATION_ROUTE_INFO}
  //   variables={{input: {id: route1}}}
  //   skip={!route1 ? true : false}
  // }
  // GET_COMMODITIES, GET_SIMPLE_VESSELS, GET_CALCULATION_ROUTE_INFO, GET_PORTS
]

export const showClient = {
  module,
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'routeName', name: 'routeName', formatter: (props) => <RootColumnLink {...props} viewLink={`/view-results/${props.row.id}`} />, filterRenderer: SingleSelectFilter }
    ],
    rows: 3
  },
  update: {
    link: null
  }
}

export const show = {
  module,
  createButton: {
    name: 'Create Route',
    link: '/create-route'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'routeName', name: 'routeName' },
      { key: 'codeNameLong', name: 'Long Name' },
      { key: 'vesselSector', name: 'Vessel Sector' },
      { key: 'forwardCurve', name: 'forwardCurve' },
      // { key: 'intake', name: 'Intake' },
      // { key: 'governingDraft', name: 'Governing Draft' },
      { key: 'deliveryPort', name: 'Delivery Port' },
      { key: 'loadCountry', name: 'LOAD COUNTRY' },
      { key: 'loadPort0', name: 'Load Port' },
      { key: 'viaBallastPort', name: 'ViaBallastPort' },
      { key: 'dischargeCountry', name: 'DISCHARGE COUNTRY' },
      { key: 'dischargePort0', name: 'Discharge Port' },
      // { key: 'loadCountry', name: 'Load Country' },
      { key: 'commodity', name: 'Commodity' }
    ].map(c => ({ ...c, ...defaultColumnProperties })),
    rows: 3
  },
  update: {
    link: '/update-route'
  }
}

export const upsert = {
  module,
  // Arrays of no objs, dischargePortBunker, loadPortArrayBunkerArray
  // Arrays of objs, dischargePortArray, dischargeTermsArray, linkedRouteArrays, loadPortArray, loadTermsArray
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Route created',
      path: '/routes'
    },
    submitButton: 'Create Route'
  },
  emptyObjects: {
    commodities: {
      name: 'Commodity',
      refetchQueries
    },
    forwardCurves: {
      name: 'Forward Curve',
      refetchQueries
    },
    ports: {
      name: 'Port',
      refetchQueries
    },
    spotTCSpreads: {
      name: 'Spot TC Spread',
      refetchQueries
    },
    timeCountings: {
      name: 'time counting',
      refetchQueries
    },
    users: {
      name: 'user',
      refetchQueries
    },
    vessels: {
      name: 'Vessel',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Route updated',
      path: '/routes'
    },
    submitButton: 'Update Route'
  },
  form: {
    // Arrary objs
    // ballastIFOConsumption: {
    //   array: {
    //     initialQty: 0,
    //     title: 'Ballast IFO Consumption'
    //   },
    //   item: {
    //     object: [
    //       {
    //         name: 'consumption',
    //         type: 'number'
    //       },
    //     ],
    //     emptyObject: {
    //       consumption: '',
    //     },
    //   }
    // },
    // Array no objs
    // draftArray: {
    //   array: {
    //     initialQty: 0,
    //     title: 'Drafts'
    //   },
    //   item: {
    //     type: 'number',
    //     name: 'Draft',
    //     emptyValue: '',
    //   },
    // },
    dischargePortArray: {
      array: {
        initialQty: 0,
        title: 'Discharge Ports'
      },
      item: {
        object: [
          {
            name: 'port',
            type: 'select'
          }
        ],
        emptyObject: {
          port: {
            name: 'port'
          }
        }
      }
    }
  }
}
