// React
import React from 'react'
import PropTypes from 'prop-types'
// Material UI
import MenuItem from '@material-ui/core/MenuItem'
import NoSsr from '@material-ui/core/NoSsr'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
// Styles
import { makeStyles, useTheme } from '@material-ui/core/styles'
import clsx from 'clsx'
// React Select
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: '100%'
    }
  },
  textField: {
    marginLeft: '2.5%',
    background: theme.palette.common.white,
    textAlign: 'left',
    fontSize: theme.typography.generalFontSize
  },
  smallInput: {
    width: '95%',
    margin: '0 auto'
  },
  input: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    height: '1.1875em',
    margin: 0,
    overflowX: 'auto',
    overflowY: 'hidden',
    height: '100%'
    // padding: '18.5px 14px',
  },
  valueContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexWrap: 'wrap'
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2)
  },
  singleValue: {
    color: 'rgba(86, 93, 95, 1)',
    fontFamily: 'Montserrat,Source Sans Pro,sans-serif',
    fontSize: theme.typography.generalFontSize,
    margin: 0
  },
  paper: {
    left: 0,
    position: 'absolute',
    right: 0,
    zIndex: 20
  }
}))

const groupStyles = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between'
}
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center'
}

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
)

const creatableLabel = data => {
  return (
    <Typography style={{ color: '#000' }}>
      {`Crear: ${data}`}
    </Typography>
  )
}

function NoOptionsMessageCreateable (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
    No options. Start writing to add one.
    </Typography>
  )
}

function NoOptionsMessage (props) {
  return (
    <Typography
      color='textSecondary'
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
    No options.
    </Typography>
  )
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
}

function inputComponent ({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

function Control (props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps }
  } = props

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps
        }
      }}
      {...TextFieldProps}
    />
  )
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired
}

function Option (props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component='div'
      style={{
        fontWeight: props.isSelected ? 500 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  )
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool
}

function SingleValue (props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  )
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired
}

function ValueContainer (props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  )
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired
}

function Menu (props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  )
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  SingleValue,
  ValueContainer
}

const createOptions = params => {
  const { name, options } = params
  const optionsArray = options.map(option => {
    let optionName = option.name
    if (name === 'visibleRoutes') {
      optionName = option.routeName
    }
    return {
      label: optionName,
      value: option.id
    }
  })

  return {
    opts: optionsArray
  }
}

/**
 *
 * @param {Object} params
 * @param {String} params.value
 * @param {Object} params.opts
 * @param {Object} params.opts.value
 */
const findValue = params => {
  const { value, opts, isValueInt } = params

  let currentValue = ''
  opts.forEach(o => {
    if (isValueInt) {
      if (o.value === parseInt(value)) {
        currentValue = o
      }
    } else if (o.options) {
      o.options.forEach(secondOptions => {
        if (secondOptions.value === value) {
          currentValue = secondOptions
        }
      })
    } else if (o.value === value) {
      currentValue = o
    }
  })

  return currentValue
}

export default function IntegrationReactSelect (props) {
  const {
    clearable,
    creatable,
    error,
    grouped,
    isMulti,
    isValueInt,
    label,
    onChange,
    readOnly,
    smallInput,
    validation,
    value,
    ...rest
  } = props
  const classes = useStyles()
  const theme = useTheme()

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit'
      }
    }),
    indicatorsContainer: base => ({
      ...base,
      display: readOnly ? 'none' : 'flex'
    })
  }

  if (!creatable) {
    let opts = []

    if (grouped) {
      opts = grouped.map(g => {
        return {
          label: g.label,
          options: createOptions({ options: g.options, name: rest.name }).opts
        }
      })
    } else {
      ;({ opts } = createOptions(rest))
    }

    let currentValue = value
    if (value && value.value) {
      currentValue = value
    } else if (!isMulti) {
      currentValue = findValue({ value, opts, isValueInt })
    }

    if (clearable) {
      opts = [{ label: 'Sin Asignar', value: null }, ...opts]
    }

    return (
      <div className={clsx(classes.root, {
        [classes.smallInput]: smallInput
      })}
      >
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            inputId='react-select-single'
            TextFieldProps={{
              label: label,
              InputLabelProps: {
                htmlFor: 'react-select-single',
                shrink: true,
                transform: 'translate(0px, -20px) scale(1)'
              }
              // variant: 'outlined',
            }}
            isMulti ={isMulti}
            {...isMulti ? {
              className: 'basic-multi-select',
              classNamePrefix: 'select'
            } : ''}
            placeholder='-'
            options={opts}
            components={components}
            value={currentValue}
            onChange={event => onChange(event, validation)}
            formatGroupLabel={formatGroupLabel}
            isDisabled={readOnly}
          />
        </NoSsr>
      </div>
    )
  } else {
    const c = {
      ...components,
      NoOptionsMessage: NoOptionsMessageCreateable
    }
    const { options } = rest
    let currentValue = value
    if (value && value.value) {
      currentValue = value
    } else {
      currentValue = findValue({ value, opts: options, isValueInt })
    }
    return (
      <div className={classes.root}>
        <CreatableSelect
          classes={classes}
          components={c}
          formatCreateLabel={creatableLabel}
          formatGroupLabel={formatGroupLabel}
          inputId='react-select-single'
          isDisabled={readOnly}
          onChange={event => onChange(event, validation)}
          options={rest.options}
          placeholder='-'
          styles={selectStyles}
          value={currentValue}
          TextFieldProps={{
            label: label,
            InputLabelProps: {
              htmlFor: 'react-select-single',
              shrink: true,
              transform: 'translate(0px, -20px) scale(1)'
            }
            // variant: 'outlined',
          }}
        />
      </div>
    )
  }
}
