const breadCrumbs = [
  {
    link: '/users',
    name: 'Users'
  }
]

export const show ={
  createButton: {
    name: 'Create user',
    link: '/create-user'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'name', name: 'Name' },
      { key: 'email', name: 'Email' },
      { key: 'role', name: 'Role' }
    ],
    rows: 3,
  },
  module: {
    name: 'User'
  },
  update: {
    link: '/update-user'
  }
}

export const upsert = {
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'User created',
      path: '/users'
    },
    submitButton: 'Create User'
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'User updated',
      path: '/users'
    },
    submitButton: 'Update User'
  }
}
