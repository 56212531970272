// React
import React, { useState, useContext, useRef, useReducer, useEffect, useCallback, createRef } from 'react'
// Prop Types
import PropTypes from 'prop-types'
// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'
// Helmet
import Helmet from 'react-helmet'
// Styles 
import { makeStyles, withStyles } from '@material-ui/core/styles'
// Clsx
import clsx from 'clsx'
// Context
import { UserContext } from '../App/AppContext'
// GQL
import gql from 'graphql-tag'
// Debounce
import debounce from 'lodash/debounce'
// Apollo
import {
  useQuery,
  useMutation
} from 'react-apollo'
// Queries
import {
  GET_OFFERS_FROM_CURVE,
  GET_VALIDATION_DATA,
  GET_VALIDATION_CURVE
} from '../Queries/Queries'
// Mutations   //to-do: change to new mutations
import {
  VALIDATE_CURVE_POSITION,
  RESET_VALIDATION_CURVE,
  UPDATE_APS_RESULT,
  SAVE_VALIDATIONCURVE,
  LOCK_SINGLE_ROW,
  LOCK_VALIDATIONCURVE,
  LOCK_ALL_CURVES_IN_SECTOR,
  UPDATE_RESULT
} from '../Mutations/Mutations'
// Date Fns
import { format, utcToZonedTime } from 'date-fns-tz'
// Material UI
import AppBar from '@mui/material/AppBar'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import LockIcon from '@material-ui/icons/Lock'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@mui/material/Tab'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

// Utils
import RootTextField from '../Utils/RootTextField'
import EditableInputBase from '../Utils/EditableInputBase'
import { formatNumber, fireSwalError } from '../Utils/utils'

// Components
import OfferInformation from './OfferInformation'
import VoyageOfferDialog from '../VoyageOffer/VoyageOfferDialog'

const LIVE_VALIDATION_CURVE = gql`
  subscription LiveValidationCurve($input: LiveValidationCurveInput!) {
    liveValidationCurve(input: $input) {
      validationCurve {
        id
        channel
        curveName
        base
        calcInfo
        curveAdjustmentAbsolute
        curveAdjustmentPercent
        latestChange
        locked
        rate
        result
        apsDh
        apsBB
        apsResult
        spotTCSpreads {
          id
          spotTCSpreadName
          spreadTCValue
        }
        spotIndex {
          id
          indexName
          indexValue
        }
        coreRoute {
          id
          routeName
          spotTCSpread {
            spreadTCValue
          }
        }
        basedOnForwardCurve{
          id
          forwardCurveName
        }
        tce
        update
      }
    }
  }
`

const LIVE_VALIDATION_OFFERS = gql`
  subscription LiveValidationOffers($input: LiveValidationOffersInput!) {
    liveValidationOffers(input: $input) {
      voyageOffers {
        id
        activeValidation
        adcome
        bid
        createdAt
        createdBy {
          id
          name
        }
        updatedAt
        offer
        originalOffer
        owner {
          ownerName
        }
        user {
          id
          name
        }
        route {
          id
          routeName
          forwardCurve {
            id
          }
          vessel {
            vesselName
          }
        }
        positionDate {
          id
          positionDateName
          positionId
        }
        savedBase
        savedFuelPrice
      }
    }
  }
`

/**
 * @description Function parseFloat a whole array
 */
const parseFloatArray = (array) => {
  const newArray = []
  for (const element of array) {
    if (element) {
      newArray.push(parseFloat(element))
    } else {
      newArray.push(0)
    }
  }

  return newArray
}

/**
 * @description PopUp To Be Rendered On Hover
 */
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

/**
 * @description Function to calculate tce after changing any of its factors
*/
const calculateTCE = (params) => {
  let {
    result = 0,
    base = 0,
    absolute = 0,
    percent = 0,
    posdate,
    spotSpreads
  } = params

  if (isNaN(absolute)) absolute = 0
  if (isNaN(percent)) percent = 0
  if (isNaN(base)) base = 0
  if (isNaN(result)) base = 0

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  // Calculate spreads
  let matchingName
  let resultMonth
  if (posdate.positionDateName.charAt(1) === 'h') {
    matchingName = posdate.positionDateName.split('h')
    resultMonth = months.indexOf(matchingName[1])
  } else {
    matchingName = posdate.positionDateName
    resultMonth = months.indexOf(matchingName)
  }
  let spreads = 0
  for (let i = 0; i < spotSpreads.length; i++) {
    spreads += spotSpreads ? spotSpreads[i].spreadTCValue[resultMonth] : 0
  }

  return {
    tce: (base * (1 + percent / 100) + absolute) * (1 + spreads / 100),
    spreads
  }
}

/**
 * @description Function to calculate the rate off of the Curve's Calc Info
 */
const calculateRate = (params) => {
  const {
    tce = 0,
    calcInfo,
  } = params

  if (calcInfo && tce) {
    const totalCosts = calcInfo.totalFuel + calcInfo.portCosts + calcInfo.miscCost + ((tce * 0.95) * calcInfo.totalTime)
    const commission = calcInfo.routeCommission
      ? totalCosts / (1 - calcInfo.routeCommission / 100) * (calcInfo.routeCommission / 100)
      : calcInfo.commission

    return (totalCosts + commission) / calcInfo.intake
  }

  return '-'
}

/**
 * @description Function to format the date with timezone
 */
const getFormattedDate = (updated, timeZone, i) => {
  let date, zonedTime
  if (i >= 0) {
    zonedTime = (updated[i] && updated[i]) ? utcToZonedTime(updated[i], timeZone) : ''
    date = (updated[i] && updated[i]) ? new Date(updated[i]) : ''
  } else {
    zonedTime = (updated && updated) ? utcToZonedTime(updated, timeZone) : ''
    date = (updated && updated) ? new Date(updated) : ''
  }

  const year = date.toLocaleString('default', { year: 'numeric', timeZone })
  const month = date.toLocaleString('default', { month: 'short', timeZone })
  const day = date.toLocaleString('default', { day: 'numeric', timeZone })

  const timeString = `${day}${month}${year} ${format(zonedTime, 'HH:mm:ss', { timeZone })}`

  return timeString
}

/**
 * @description Function to round amount of hours in 0.5 increments
 */
const getTimeSinceRoundedToHalves = (timeSince) => {
  const hours = timeSince / (1000 * 60 * 60)

  const integerPart = Math.floor(hours)

  const decimalPart = hours - integerPart

  const halvesElapsed = decimalPart > 0.5 ? integerPart + 0.5 : integerPart

  return halvesElapsed
}

/**
 * @description Function to sort an array of objects by date of creation
 */
 const sortByDate = (a, b) => {
  const userA = (new Date(a.createdAt)).getTime()
  const userB = (new Date(b.createdAt)).getTime()

  if (userA > userB) {
    return -1
  } else if (userA < userB) {
    return 1
  } else {
    return 0
  }
}

/**
 * @description Function to place Voyage Offers in the right Position Date
 */
const getOffers = (offers, posdate) => {
  const rightOffers = []

  for (const offer of offers) {
    if (offer.positionDate.id === posdate.id) {
      rightOffers.push(offer)
    }
  }

  return rightOffers.sort(sortByDate)
}

// Styles
const appBarStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // backgroundColor: theme.palette.background.paper
  },
  appBar: {
    overflowX: 'scroll',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden'
    }
  },
  adcom: {
    width: '50px',
    margin: '3%',
    // marginLeft: '100px',
    backgroundColor: 'white'
  },
  adcomBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: '0%',
    position: 'static'
  },
  adcomBoxArrange: {
    width: '210px',
    objectPosition: 'static',
    marginRight: '10px',
    marginLeft: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'space-between'
  },
  commoditiesBundle: {
    display: 'flex',
    objectPosition: 'static',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between'
  },
  commodities: {
    fontsize: theme.typography.generalFontSize - 2
  },
  commoditiesDate: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    }
  },
  updatedAt: {
    fontsize: '10px'
  }
}))

// Main component
const Validation = props => {

  // Hook definitions
  // const history = useHistory()
  const { user, setUser } = useContext(UserContext)

  let initialValues = {
    tab: 0
  }
  if (user.forwardValidation) {
    initialValues = user.forwardValidation
  }

  const [value, setValue] = useState(initialValues.tab || 0)

  // Initial Values
  const initialAdcom = user && user.adcom ? user.adcom : 0

  // More hook definitions
  const [adcomValue, setAdcomValue] = useState(initialAdcom)
  const commodities = useRef([
    'Baltic 38k FFA',
    'Baltic 58k FFA',
    'Baltic 82k 5tc FFA'
  ])
  const sectors = useRef([
    'Handy',
    'Supramax',
    'Kamsarmax'
  ])

  // Queries
  const {
    laoding,
    error,
    data
  } = useQuery(GET_VALIDATION_DATA, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        index: value
      }
    }
  })
  
  // Functions
  const setAdcomValueAndUser = (e) => {
    setUser({
      type: 'adcom',
      value: parseFloat(e.target.value)
    })
  }
  const setAdcomDebounce = debounce(setAdcomValueAndUser, 3000)

  const handleChange = (_event, newValue) => {
    setUser({
      type: 'add-forwardValidation-info',
      value: {
        ...initialValues,
        tab: newValue
      }
    })
    setValue(newValue)
  }

  const a11yProps = (index) => {
    return {
      id: `ship-sector-tab-${index}`,
      'aria-controls': `ship-sector-panel-${index}`
    }
  }

  // Other Variables
  const classes = appBarStyles()
  let rightNow
  let formattedPortDate
  let singapore05FCDate
  let selectedCommodityDate
  let selectedCommodityTime
  let timeSinceCommodityHalves
  let positionDates
  let systemSetting
  let voyageOffers
  let fuelPrice
  
  if (data) {
    rightNow = new Date(Date.now())
    formattedPortDate = getFormattedDate(data.getValidationData.portDate, 'Europe/Copenhagen')
    singapore05FCDate = getFormattedDate(data.getValidationData.commodityDates.singapore, 'Europe/Copenhagen')
    selectedCommodityDate = getFormattedDate(data.getValidationData.commodityDates.selected, 'Europe/Copenhagen')
    selectedCommodityTime = new Date(data.getValidationData.commodityDates.selected).getTime()
    timeSinceCommodityHalves = getTimeSinceRoundedToHalves(rightNow.getTime() - selectedCommodityTime)
    positionDates = data.getValidationData.positionDates
    systemSetting = data.getValidationData.systemSetting
    fuelPrice = data.getValidationData.fuelPrice
  }

  // Render
  return (
    <Box className={classes.root}>
      <Helmet>
      <title>Validation</title>
      </Helmet>
      <AppBar position='static' className={classes.appBar}>
        <Grid container direction='row' justifyContent='flex-start' alignItems='center' wrap='nowrap' position='static'>
          <Box item wrap='nowrap' position='static'>
            <Tabs indicatorColor='primary' value={value} onChange={handleChange} aria-label='ship sector tabs' position='static'>
              {sectors.current.map((sector, i) => {
                return (
                  <Tab style={{ color: 'white' }} position='static' wrap='nowrap' key={i} label={sector} {...a11yProps(i)} />
                )
              })}
            </Tabs>
          </Box>
          <Box className={classes.adcomBox} xs={10} md={2} container item wrap='nowrap'>

            <Grid className={classes.adcomBoxArrange} xs={12} container item wrap='nowrap'>

              <Grid item className={classes.commoditiesBundle}>
                <Typography className={classes.commodities} variant='caption'>
                  {formattedPortDate ? `Sin LSFO Spot` : ''}
                </Typography>
                <Typography className={classes.commoditiesDate} variant='caption' textAlign='right'>
                  {formattedPortDate ? ` ${formattedPortDate}` : ''}
                </Typography>
              </Grid>

              <Grid item className={classes.commoditiesBundle}>

                <Typography className={classes.commodities} variant='caption'>
                  {singapore05FCDate ? 'Sin LSFO Futures' : ''}
                </Typography>
                <Typography className={classes.commoditiesDate} variant='caption'>
                  {singapore05FCDate ? ` ${singapore05FCDate}` : ''}
                </Typography>
              </Grid>


              <Grid item className={classes.commoditiesBundle}>
                <Typography className={classes.commodities} variant='caption'>
                  {selectedCommodityDate ? commodities.current[value] : ''}
                </Typography>
                <Typography className={classes.commoditiesDate} variant='caption'>
                  {selectedCommodityDate ? ` ${selectedCommodityDate}` : ''}
                </Typography>
              </Grid>

            </Grid>
          </Box>

          <Box width='150px' height='57px' display='flex' alignItems='flex-end' style={{ marginRight: '20px' }}>
            <Typography noWrap={true} style={{ fontSize: '10px' }}>{(timeSinceCommodityHalves >= 0) ? ` (${timeSinceCommodityHalves} hours)` : ''}</Typography>  
          </Box>

          {
            data && (
              <Box item xs={2} position='static'>
                <RootTextField
                  style={{ borderRadius: '10%' }}
                  inputProps={{ fontSize: 15 }}
                  label='adcom'
                  type='number'
                  onChange={(e) => {
                    setAdcomValue((e.target.value) && parseFloat(e.target.value))
                    setAdcomDebounce(e)
                  }}
                  value={adcomValue}
                  className={classes.adcom}
                  allowedDecimalSeparators={[',', '.']}
                />
              </Box>
            )
          }

        </Grid>
      </AppBar>
      {
        data && (
          <TabPanel
            validationCurves={data.getValidationData.validationCurves}
            positionDates={data.getValidationData.positionDates}
            value={value}
            adcomValue={adcomValue}
            systemSetting={systemSetting}
            value={value}
            quarterArray={data.getValidationData.quarterArray}
            fuelPrice={fuelPrice}
          />
        )
      }
    </Box>
  )
}

const curvesReducer = (state, action) => {
  switch(action.type) {
    case 'add': {
      return {
        ...action.curve
      }
    }

    case 'complete-data': {
      let newState = { ...state }
      newState = {
        id: newState.id,
        body: { ...action.data }
      }

      return {
        ...newState
      }
    }

    case 'per': {
      const newState = { ...state }
      newState.body.curveAdjustmentPercent[action.rowIndex] = parseFloat(action.value)

      return {
        ...newState
      }
    }

    case 'abs': {
      const newState = { ...state }
      
      newState.body.curveAdjustmentAbsolute[action.rowIndex] = action.value
      
      return {
        ...newState
      }
    }

    case 'validate': {
      const newState = { ...state }

      const abs = parseFloatArray(newState.body.curveAdjustmentAbsolute)
      const per = parseFloatArray(newState.body.curveAdjustmentPercent)

      let isAbsNumeric = true
      let isPerNumeric = true
      for (let i = 0; i < abs.length; i++) {
        if (typeof abs[i] !== 'number') {
          isAbsNumeric = false
          break
        }
        if (typeof per[i] !== 'number') {
          isPerNumeric = false
          break
        }
      }

      if (isAbsNumeric && isPerNumeric) {
        action.mutation({
          variables: {
            input: {
              id: action.id,
              index: action.rowIndex,
              adcomValue: action.adcomValue ? parseFloat(action.adcomValue) : 0,
              adjustments: {
                curveAdjustmentPercent: per,
                curveAdjustmentAbsolute: abs
              }
            }
          }
        })
      }


      return {
        ...newState
      }
    }

    case 'apsDh': {
      const newState = { ...state }
      newState.body.apsDh = parseFloat(action.value)

      return {
        ...newState
      }
    }

    case 'apsBB': {
      const newState = { ...state }
      newState.body.apsBB = parseFloat(action.value)

      return {
        ...newState
      }
    }

    case 'apsBB-unFocus': {
      const newState = { ...state }

      action.mutation({
        variables: {
          input: {
            id: newState.id,
            apsDh: parseFloat(newState.body.apsDh),
            apsBB: parseFloat(newState.body.apsBB)
          }
        }
      })

      return {
        ...newState
      }
    }

    case 'apsResult': {
      const newState = { ...state }
      newState.apsResult = action.value

      return {
        ...newState
      }
    }

    case 'result': {
      const newState = { ...state }
      newState.body.result = parseFloat(action.value)

      return {
        ...newState
      }
    }

    case 'lock-single-row': {
      const newState = { ...state }
      newState.body.locked[action.index] = true

      action.mutation({
        variables: {
          input: {
            id: newState.id,
            index: action.index
          }
        }
      })

      return {
        ...newState
      }
    }

    case 'lock-card': {
      const newState = { ...state }
      newState.body.locked = [true, true, true, true, true, true, true, true, true, true, true, true, true, true]

      action.mutation({
        variables: {
          input: {
            id: newState.id
          }
        }
      })

      return {
        ...newState
      }
    }

    case 'lock-all-cards': {
      action.mutation({
        variables: {
          input: {
            index: action.index
          }
        }
      })
    }

    default: {
      return state
    }
  }
}

// Styles
const tabpanelStyles = makeStyles(theme => ({
  tabpanel: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexWrap: 'wrap',
    justifyContent: 'flex-start'
  }
}))

const TabPanel = props => {
  // Arguments
  const {
    adcomValue,
    validationCurves,
    value,
    positionDates,
    quarterArray,
    systemSetting,
    fuelPrice
  } = props

  // Other Variables
  const classes = tabpanelStyles()

  // Render
  return (
    <Box className={classes.tabpanel}>
      {
        (validationCurves) && validationCurves.map((validationCurve, curveIndex) => {
          return (
            <ValidationCurve
              id={validationCurve.id}
              curve={validationCurve}
              curveIndex={curveIndex}
              value={value}
              adcomValue={adcomValue}
              positionDates={positionDates}
              quarterArray={quarterArray}
              systemSetting={systemSetting}
              fuelPrice={fuelPrice}
            />
          )
        })
      }
    </Box>
  )
}

// Styles
const curveStyles = makeStyles(theme => ({
  '@keyframes coolEntrance': {
    from: {
      transform: 'scale(0)'
    },
    to: {
      transform: 'scale(1)'
    },
  },
  card: {
    [theme.breakpoints.up('md')]: {
      width: '24.3%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    margin: '5px',
    borderRadius: '1.5%',
    overflowX: 'auto',
    animation: '$coolEntrance',
    animationDuration: '1s'
  }
}))

const ValidationCurve = React.memo((props) => {
  // Arguments
  const {
    id,
    curve,
    curveIndex,
    value,
    adcomValue,
    positionDates,
    quarterArray,
    systemSetting,
    fuelPrice
  } = props

  // Hooks
  const [curvesHandler, setCurvesHandler] = useReducer(curvesReducer, {
    ...curve
  })
  const [selectedInput, setSelectedInput] = useState()
  const availableFieldsRef = useRef([])

  // Imperative code

  // Add the curve to the Reducer object everytime it changes
  useEffect(() => {
    setCurvesHandler({
      type: 'add',
      curve
    })
  }, [curve])

 
  useEffect(() => {
    if (selectedInput !== null && selectedInput >= (curveIndex * 31) && selectedInput <= ((curveIndex * 31) + 30) && availableFieldsRef.current[selectedInput] !== null) {
      availableFieldsRef.current[selectedInput].focus()
    }
  }, [selectedInput])

  // Subscribe the query
  const _newValidation = (subscribeToMore) => {
    subscribeToMore({
      document: LIVE_VALIDATION_CURVE,
      variables: {
        input: {
          id
        }
      },
      updateQuery: (previousData, { subscriptionData }) => {
        
        if (!subscriptionData.data) {
          return previousData
        }

        const newFeedItem = subscriptionData.data.liveValidationCurve.validationCurve

        if (id === newFeedItem.id) {
          setCurvesHandler({
            type: 'complete-data',
            data: newFeedItem
          })
        }

        return Object.assign({}, previousData, {
          getValidationCurve: {
            validationCurve: newFeedItem
          }
        })
      }
    })
  }

  // Queries
  const {
    error,
    loading,
    data,
    subscribeToMore,
    refetch
  } = useQuery(GET_VALIDATION_CURVE, {
    variables: {
      input: {
        id
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      _newValidation(subscribeToMore)
      setCurvesHandler({
        type: 'complete-data',
        index: curveIndex,
        data: data.getValidationCurve.validationCurve
      })
    }
  })

  // Refetch the query
  useCallback(refetch, [])

  // Other Variables
  const classes = curveStyles()

  // Render
  if (curvesHandler) {
    return (
      <>
        <Card className={classes.card} elevation={5}>
          <ValidationHeader
            id={id}
            value={value}
            index={curveIndex}
            curvesHandler={curvesHandler}
            setCurvesHandler={setCurvesHandler}
            selectedInput={selectedInput}
            setSelectedInput={setSelectedInput}
            availableFieldsRef={availableFieldsRef}
          />
          <ValidationCurveContent
            id={id}
            value={value}
            index={curveIndex}
            adcomValue={adcomValue}
            curvesHandler={curvesHandler}
            setCurvesHandler={setCurvesHandler}
            selectedInput={selectedInput}
            setSelectedInput={setSelectedInput}
            availableFieldsRef={availableFieldsRef}
            positionDates={positionDates}
            quarterArray={quarterArray}
            systemSetting={systemSetting}
            fuelPrice={fuelPrice}
          />
        </Card>
      </>
    )
  } else {
    return <></>
  }
})

// Component to format strings in the Validation Curve Header 
const ItalicBasedFormat = props => {
  // Arguments
  const {
    isCurve = false,
    text = '',
    className,
    isLink = false,
    href = null,
    onClick = null
  } = props

  // Render
  if (isCurve && !isLink) {
    return (
      <Typography
        className={className}
      >
        <i>{text}</i>
      </Typography>
    )
  } else if (isCurve && isLink) {
    return (
      <Typography
        className={className}
      >
        <i>
          <Link
            className={className}
            href={href}
            onClick={onClick}
          >
            {text}
          </Link>
        </i>
      </Typography>
    )
  } else if (!isCurve & isLink) {
    return (
      <Typography
        className={className}
      >
        <Link
          className={className}
          href={href}
          onClick={onClick}
        >
          {text}
        </Link>
      </Typography>
    )
  } else {
    return (
      <Typography
        className={className}
      >
        {text}
      </Typography>
    )
  }
}

const headerStyles = makeStyles(theme => ({
  '@keyframes fadeAnimation': {
    from: {opacity: 0},
    to: {opacity: 1},
  },
  buttonsContainer: {
    textAlign: 'right'
  },
  updated: {
    fontSize: theme.typography.generalFontSize - 2,
    margin: '0%',
    color: 'green'
  },
  noMargin: {
    margin: '0%',
    paddingTop: '1px',
    paddingBottom: '1px'
  },
  notUpdated: {
    fontSize: theme.typography.generalFontSize - 2,
    color: 'red'
  },
  smallUpdated: {
    color: 'green',
    fontSize: '10px'
  },
  smallNotUpdated: {
    color: 'red',
    fontSize: '10px'
  },
  titleContainer: {
    paddingRight: '8px',
    paddingLeft: '8px',
    animation: '$fadeAnimation',
    animationDuration: '1s'
  },
  small: {
    fontSize: '12px'
  }
}))

const ValidationHeader = React.memo((props) => {
  // Arguments
  const {
    id,
    index,
    curvesHandler,
    setCurvesHandler,
    value,
    selectedInput,
    setSelectedInput,
    handleSelectedInput,
    availableFieldsRef
  } = props

  // Hook Definitions
  const { user } = useContext(UserContext)
  const [modifiedIndex, setModifiedIndex] = useState()
  const [vesselAps, setVesselAps] = useState()

  // Mutations
  const [updateApsResult] = useMutation(UPDATE_APS_RESULT)
  const [resetValidationCurve] = useMutation(RESET_VALIDATION_CURVE)
  const [saveValidationCurve] = useMutation(SAVE_VALIDATIONCURVE)

  // Functions
  const handleSelectedField2 = (value) => {
    setSelectedInput(value)
  }

  // Other Variables
  const classes = headerStyles()
  const preventDefault = (event) => event.preventDefault()

  let updated
  let updatedTimezonedDate
  let locked
  if (curvesHandler.body) {
    updated = new Date(curvesHandler.body.update.time) >= new Date(curvesHandler.body.latestChange)
    updatedTimezonedDate = (curvesHandler.body.update && curvesHandler.body.update.time) ? getFormattedDate(curvesHandler.body.update.time, 'Europe/Copenhagen') : ''
    locked = curvesHandler.body.locked.reduce((acc, curr) => acc && curr)
  }

  // Render
  if (curvesHandler.body) {
    return (
      <>
        <Grid item container className={classes.titleContainer}>
          <Grid item xs={10}>
            <Grid item container alignItems='center' className={classes.noMargin}>
              <ItalicBasedFormat
                isCurve={curvesHandler.body.basedOnForwardCurve}
                className={(updated) ? classes.updated : classes.notUpdated}
                text={curvesHandler.body.curveName}
              />
              <ItalicBasedFormat
                isCurve={curvesHandler.body.basedOnForwardCurve}
                className={(updated) ? classes.smallUpdated : classes.smallNotUpdated}
                text={(curvesHandler.body.update && curvesHandler.body.update.user) ? ` - ${curvesHandler.body.update.user} -${updatedTimezonedDate} ` : ''}
              />
            </Grid>
            <Grid>
              <ItalicBasedFormat
                isCurve={curvesHandler.body.basedOnForwardCurve}
                className={(updated) ? classes.updated : classes.notUpdated}
                text={curvesHandler.body.basedOnForwardCurve && `Based on: ${curvesHandler.body.basedOnForwardCurve.forwardCurveName}`}
              />
            </Grid>
            <Grid item container alignItems='center'>
              <ItalicBasedFormat
                isCurve={curvesHandler.body.basedOnForwardCurve}
                className={(updated) ? classes.updated : classes.notUpdated}
                isLink
                href={`/calculation-page/${curvesHandler.body.coreRoute && curvesHandler.body.coreRoute.id}`}
                onClick={() => { preventDefault() }}
                text={curvesHandler.body.coreRoute && curvesHandler.body.coreRoute.routeName ? curvesHandler.body.coreRoute.routeName : ''}
              />
              <Grid style={{ marginLeft: '5px' }}>
                {
                  locked ? <LockIcon /> : <LockOpenIcon />
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={2} justify='flex-end' className={classes.buttonsContainer}>
            <Grid item xs={12}>
              <Link
                disabled={user.disabled}
                onClick={() => {
                  handleSelectedField2(null)
                  const milliseconds = 500
                  setTimeout(() => {
                    saveValidationCurve({
                      variables: {
                        input: {
                          id: curvesHandler.id
                        }
                      }
                    })
                  }, milliseconds)
                }}>
                Save
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Link
                disabled={user.disabled}
                onClick={e => {
                  resetValidationCurve({
                    variables: {
                      input: {
                        id
                      }
                    }
                  })
                }}>
                Reset
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Box>
  
          {/*APS INPUTS*/}
          <Box display='flex' flexDirection='row' flexWrap='nowrap' className={classes.titleContainer} style={{ marginTop: '15px'}}>
            <Box display='flex' alignItems='flex-start' style={{ width: '188px', height: '43px'}}>
              <ItalicBasedFormat
                text={curvesHandler.body.spotIndex && `${curvesHandler.body.spotIndex.indexName}: ${curvesHandler.body.spotIndex.indexValue}`}
                isCurve={curvesHandler.body.basedOnForwardCurve && curvesHandler.body.basedOnForwardCurve}
                className={classes.small}
              />
            </Box>
            <Box display='flex' flexDirection='column' flexWrap='nowrap' alignItems='flex-end' style={{ width: '70px'}}>
              <Box style={{ padding: '0px 4px' }}>
                <Typography style={{ fontSize: '12px' }}><b>APS Hire</b></Typography>
              </Box>
              <Box style={{ padding: '0px 4px' }}>
                <EditableInputBase
                  id={`${curvesHandler.body.forwardCurveName}-apsDh`}
                  alignRight
                  removeDecimals
                  inputRef={(e) => (availableFieldsRef.current[index * 31] = e)}
                  onKeyDown={(e) => {
                    if (e.keyCode === 9 || e.keyCode === 13) {
                      // e.preventDefault()
                      if (curvesHandler.body.apsDh !== 0) {
                        updateApsResult({
                          variables: {
                            input: {
                              id,
                              apsDh: parseFloat(curvesHandler.body.apsDh),
                              apsBB: parseFloat(curvesHandler.body.apsBB)
                            }
                          }
                        })
                      }
                      if (e.shiftKey) {
                        handleSelectedField2(index !== 0 ? index * 31 - 30 : 0)
                      } else {
                        handleSelectedField2(index * 31 + 1)
                      }
                    }
                  }}
                  onClick={(e) => handleSelectedField2(index * 31)}
                  onBlur={() =>
                    curvesHandler.body.apsDh !== 0
                      ? updateApsResult({
                        variables: {
                          input: {
                            id,
                            apsDh: parseFloat(curvesHandler.body.apsDh),
                            apsBB: parseFloat(curvesHandler.body.apsBB)
                          }
                        }
                      })
                    : ''}
                  onChange={(e) =>
                    setCurvesHandler({
                      type: 'apsDh',
                      value: e.target.value
                  })}
                  value={curvesHandler.body.apsDh}
                />
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' alignItems='flex-end' flexWrap='nowrap' style={{ width: '60px'}}>
              <Box style={{ padding: '0px 4px' }}>
                <Typography style={{ fontSize: '12px' }}><b>APS BB</b></Typography>
              </Box>
              <Box style={{ padding: '0px 4px' }}>
  
                <Box display='flex' flexDirection='row' flexWrap='nowrap' >
                  <EditableInputBase
                    id={`${curvesHandler.body.forwardCurveName}-apsBB`}
                    alignRight
                    noFormat
                    inputRef={(e) => (availableFieldsRef.current[index * 31 + 1] = e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 9 || e.keyCode === 13) {
                        // e.preventDefault()
                        if (curvesHandler.body.apsDh !== 0) {
                          setCurvesHandler({
                            type: 'apsBB-unFocus',
                            index,
                            mutation: updateApsResult,
                            shipSectorIndex: value
                          })
                        }
                        if (e.shiftKey) {
                          handleSelectedField2(index * 31)
                        } else {
                          handleSelectedField2(index * 31 + 31)
                        }
                      }
                    }}
                    onClick={(e) => handleSelectedField2(index * 31 + 1)}
                    onBlur={() =>
                      curvesHandler.body.apsDh !== 0 ? setCurvesHandler({ type: 'apsBB-unFocus', index, mutation: updateApsResult, shipSectorIndex: value }) : ''
                    }
                    onChange={(e) =>
                      setCurvesHandler({
                        type: 'apsBB',
                        value: e.target.value,
                      })}
                    value={formatNumber({ number: curvesHandler.body.apsBB, format: '0,0' })}
                  />
                  <Box style={{ height: 20, fontSize: '15px' }}>
                    K
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' flexWrap='nowrap' alignItems='flex-end' style={{ width: '70px'}}>
              <Box style={{ padding: '0px 4px' }}>
                <Typography style={{ fontSize: '12px' }}><b>APS Rate</b></Typography>
              </Box>
              <Box style={{ padding: '0px 4px' }}>
                <EditableInputBase
                  alignRight
                  value={curvesHandler.body.apsResult}
                  disabled
                />
              </Box>
            </Box>
          </Box>
  
        </Box>
      </>
    )
  } else {
    return (
      <Box style={{ height: '100%', height: '100%', padding: '10px'}}>
        {/* <CircularProgress /> */}
        {
          [0,0,0,0].map(pd => {
            return <Skeleton animation="wave" />
          })
        }
      </Box>
    )
  }
})

const bodyStyles = makeStyles(theme => ({
  '@keyframes fadeAnimation': {
    from: {opacity: 0},
    to: {opacity: 1},
  },
  tableLayout: {
    animation: '$fadeAnimation',
    animationDuration: '1s'
  },
  quartersCell: {
    border: 'none',
    '&:hover': {
      color: '#db5461',
      backgroundColor: 'rgba(0,0,0,0.1)'
    },
    lineHeight: 3
  },
  curveCell: {
    fontSize: '15px',
    padding: '3px',
    borderBottom: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    },
  },
  fixed50: {
    minWidth: '50px',
    width: '50px',
    maxWidth: '50px'
  },
  fixed60: {
    minWidth: '60px',
    width: '60px',
    maxWidth: '60px'
  },
  fixed70: {
    minWidth: '70px',
    width: '70px',
    maxWidth: '70px'
  },
  fixed240: {
    width: '240px',
    maxWidth: '240px'
  },
  quarter: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)'
  },
  spreads: {
    maxWidth: '400px',
    overFlowY: 'auto',
    borderRadius: '1%',
    height: 100
  },
  spreadTable: {
    maxHeight: '100px',
    padding: '5px',
    overflowY: 'auto',
    padding: 0
  },
  spreadTableLayout: {
    tableLayout: 'fixed',
    width: '400px',
    animation: '$fadeAnimation',
    animationDuration: '1s'
  },
  tableFont: {
    fontSize: 15,
  },
  spreadColumn: {
    width: '70px',
    paddingLeft: 4, 
    paddingRight: 4,
    paddingBottom: 4,
    paddingTop: 4,
    borderBottom: 'none',
    fontSize: 15
  },
  spredName: {
    paddingBottom: 4,
    paddingTop: 4,
    width: 240,
    borderBottom: 'none'
  },
  greenVoyage: {
    color: 'green',
    backgroundColor: '#D0FFC7'
  },
  orangeVoyage: {
    color: '#F17300',
    backgroundColor: '#FFF3C7'
  },
  redVoyage: {
    color: 'red',
    backgroundColor: '#FFC7C7'
  },
  tooltipText: {
    fontSize: '11px',
    // fontWeight: 'bold'
  },
  bid: {
    color: 'green',
    marginRight: '5px'
  },
  offerBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'nowrap'
  }
}))

const offersReducer = (state, action) => {
  switch (action.type) {
    case 'add': {
      return [
        ...action.voyageOffers
      ]
    }
  }
}

// Initial State of mouse...?
const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

// Context Reducer
const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

const ValidationCurveContent = React.memo((props) => {
  // Arguments
  const {
    id,
    index,
    value,
    adcomValue,
    curvesHandler,
    setCurvesHandler,
    availableFieldsRef,
    selectedInput,
    setSelectedInput,
    positionDates,
    quarterArray,
    systemSetting,
    fuelPrice
  } = props

  // Hooks
  const [voyageOffers, setVoyageOffers] = useReducer(offersReducer, null)
  const [contextState, setContextState] = useReducer(contextReducer, initialState)
  const [deleteVoyageDialog, setDeleteVoyageDialog] = useState(false)
  const [voyageToDelete, setVoyageToDelete] = useState()

  // Spread popper hooks
  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)
  const [placement, setPlacement] = useState()
  const [popperIndex, setPopperIndex] = useState(0)

  const [selectedResult, setSelectedResult] = useState({
    posIndex: 0,
    posName: positionDates[0].positionDateName
  })

  // Subscribe the query
  const _newValidationOffers = (subscribeToMore) => {
    subscribeToMore({
      document: LIVE_VALIDATION_OFFERS,
      variables: {
        input: {
          id
        }
      },
      updateQuery: (previousData, { subscriptionData }) => {
        
        if (!subscriptionData.data) {
          return previousData
        }

        const newFeedItem = subscriptionData.data.liveValidationOffers.voyageOffers

        setVoyageOffers({
          type: 'add',
          voyageOffers: newFeedItem
        })

        return Object.assign({}, previousData, {
          getValidationOffers: {
            voyageOffers: newFeedItem
          }
        })
      }
    })
  }

  // Queries
  const {
    error: offersError,
    loading: offersLoading,
    data: offersData,
    subscribeToMore: subscribeToOffers,
    refetch: refecthOffers
  } = useQuery(GET_OFFERS_FROM_CURVE, {
    variables: {
      input: {
        id
      }
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted (data) {
      _newValidationOffers(subscribeToOffers)
      setVoyageOffers({
        type: 'add',
        voyageOffers: data.getOffersFromCurve.voyageOffers
      })
    }
  })

  useCallback(refecthOffers, [])

  // Mutations
  const [validateCurvePosition] = useMutation(VALIDATE_CURVE_POSITION)
  const [lockSingleRow] = useMutation(LOCK_SINGLE_ROW)
  const [lockValidationCurve] = useMutation(LOCK_VALIDATIONCURVE)
  const [lockAllCurvesInSector] = useMutation(LOCK_ALL_CURVES_IN_SECTOR)
  const [updateResult] = useMutation(UPDATE_RESULT)

  // Functions
  const handleOpenMenu = (event, curve, index) => {
    event.preventDefault()

    if (!curve.locked || !curve.locked[index]) {
      setContextState({
        event,
        selectedRow: {
          id: curve.id,
          index,
        },
        type: 'open'
      })
    }
  }
  const handleCloseMenu = () => {
    setContextState({
      type: 'close'
    })
  }

  const handleLockRow = () => {
    setCurvesHandler({
      type: 'lock-single-row',
      index: contextState.selectedRow.index,
      mutation: lockSingleRow
    })
    handleCloseMenu()
  }

  const handleLockCard = () => {
    setCurvesHandler({
      type: 'lock-card',
      mutation: lockValidationCurve
    })
    handleCloseMenu()
  }

  const handleLockAllCards = () => {
    setCurvesHandler({
      type: 'lock-all-cards',
      index: value,
      mutation: lockAllCurvesInSector
    })
    handleCloseMenu()
  }

  const handleVoyageClose = () => {
    setDeleteVoyageDialog(false)
  }

  const handleVoyageOpen = (voyage) => {
    setVoyageToDelete(voyage)
    setDeleteVoyageDialog(true)
  }

  // Other Variables
  const classes = bodyStyles()
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  let selectedMonth
  let nameMatch
  let tceNoSpreads

  // Functions
  const handleSelectedField2 = (value) => {
    setSelectedInput(value)
  }

  const handleClickBase = (newPlacement, index) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
    setPopperIndex(index)
  }

  //to-do: render whole table here with spreads

  // Render
  if (curvesHandler.body && (offersData && voyageOffers)) {
    if (selectedResult.posName.charAt(1) === 'h') {
      nameMatch = selectedResult.posName.split('h')
      selectedMonth = months.indexOf(nameMatch[1])
    } else {
      nameMatch = selectedResult.posName
      selectedMonth = months.indexOf(nameMatch)
    }

    const auxBase = curvesHandler.body.base[selectedResult.posIndex]
    const auxAbsolute = curvesHandler.body.curveAdjustmentAbsolute[selectedResult.posIndex]
    const auxPercent = curvesHandler.body.curveAdjustmentPercent[selectedResult.posIndex]

    const baseInSelectedRow = (!auxBase || isNaN(auxBase)) ? 0 : auxBase
    const absoluteInSelectedRow = (!auxAbsolute || isNaN(auxAbsolute)) ? 0 : auxAbsolute
    const percentInSelectedRow = (!auxPercent || isNaN(auxPercent)) ? 0 : auxPercent

    tceNoSpreads = selectedResult.posIndex === 0
      ? curvesHandler.body.result
      : ((baseInSelectedRow * (1 + percentInSelectedRow / 100)) + absoluteInSelectedRow)

    const isChild = !!(curvesHandler.body.basedOnForwardCurve)
    return (
      <Box>
        <CardContent className={classes.cardContent}>

          {/* TCE Table */}

          {/* TCE Table body */}
          <Table className={classes.tableLayout} size='small'>
            <TableBody>
              <TableRow>
                <TableCell className={clsx([classes.fixed50, classes.tableFont])}>
                  <b style={{ fontWeight: 450 }}>Date</b>
                </TableCell>
                <TableCell align='right' className={classes.fixed60}>
                  <b style={{ fontWeight: 450 }}>Base</b>
                </TableCell>
                <TableCell align='right' className={clsx([classes.fixed70, classes.tableFont])}>
                  <b style={{ fontWeight: 450 }}>Percent</b>
                </TableCell>
                <TableCell align='right' className={clsx([classes.fixed70, classes.tableFont])}>
                  <b style={{ fontWeight: 450 }}>Absolute</b>
                </TableCell>
                <TableCell align='right' className={clsx([classes.fixed60, classes.tableFont])}>
                  <b style={{ fontWeight: 450 }}>TCE</b>
                </TableCell>
                <TableCell align='right' className={clsx([classes.fixed70, classes.tableFont])}>
                  <b style={{ fontWeight: 450 }}>Voyage</b>
                </TableCell>
                <TableCell align='left' colSpan={voyageOffers.length} className={classes.tableFont}>
                  <b style={{ fontWeight: 450 }}>Offers</b>
                </TableCell>
              </TableRow>

              {/* TCE Table content */}
              {positionDates.map((posdate, i) => {

                const result = curvesHandler.body.result
                const calcInfo = curvesHandler.body.calcInfo[i]

                const absolute = curvesHandler.body.curveAdjustmentAbsolute[i]
                const percent = curvesHandler.body.curveAdjustmentPercent[i]
                const base = curvesHandler.body.base[i]
                const spotSpreads = curvesHandler.body.coreRoute.spotTCSpread

                const { tce, spreads} = calculateTCE({
                  posdate,
                  absolute,
                  percent,
                  base,
                  spotSpreads
                })

                let rate
                if (i === 0) {
                  rate = calculateRate({
                    tce: result,
                    calcInfo
                  })
                } else {
                  rate = calculateRate({
                    tce,
                    calcInfo
                  })
                }

                const quarter = quarterArray.includes(i)

                return (
                  <TableRow
                    className={classes.quartersCell}
                    onContextMenu={e => {
                      handleOpenMenu(e, curvesHandler, i)
                    }}
                    onClick={() => setSelectedResult({ posIndex: i, posName: posdate.positionDateName })}
                    selected={selectedResult.posIndex === i}
                  >
                    <SpreadsPopper
                      open={openPopper}
                      anchorEl={anchorEl}
                      placement={placement}
                      spreads={spreads}
                      index={popperIndex}
                      base={curvesHandler.body.base}
                    />
                    {/* Position Date Name */}
                    <TableCell className={clsx(classes.curveCell, { [classes.quarter]: quarter })}>
                      {posdate.positionDateName}
                    </TableCell>

                    {/* Base */}
                    <TableCell align='right' className={clsx(classes.curveCell, { [classes.quarter]: quarter })} onClick={handleClickBase('top', i)}>
                      {formatNumber({ format: '0,0', number: curvesHandler.body.base[i] })}
                    </TableCell>

                    {/* Percent */}
                    <TableCell align='right' className={clsx(classes.curveCell, { [classes.quarter]: quarter })} >
                      <EditableInputBase
                        id={`${curvesHandler.body.curveName}-input-per-${i + 1}`}
                        disabled={
                          (i === 0 && !curvesHandler.body.basedOnForwardCurve) ||
                          (curvesHandler.body.locked[i])
                        }
                        alignRight
                        inputRef={(e) => (availableFieldsRef.current[i === 0 ? index * 31 + 2 : (index*31) + 2*(i+1) + 1] = e)}
                        value={curvesHandler.body.curveAdjustmentPercent[i]}
                        onChange={e => {
                          setCurvesHandler({
                            type: 'per',
                            id,
                            rowIndex: i,
                            value: e.target.value,
                            posdate
                          })
                        }}
                        onClick={(event) => {
                          handleSelectedField2(i === 0 ? index * 31 + 2 : (index*31) + 2*(i+1) + 1)
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 9) {
                            event.preventDefault()

                            if (event.shiftKey) {
                              handleSelectedField2(i === 0 ?  index * 31 + 2 : i === 1 ? index * 31 + 2 : (index*31) + 2*(i+1) - 1)
                            } else if (i === 13) {
                              handleSelectedField2((index*31) + 6)
                            } else {
                              handleSelectedField2((index*31) + 2*(i+1) + 3)
                            }
                          }
                        }}
                        onBlur={e => {
                          setCurvesHandler({
                            type: 'validate',
                            id,
                            rowIndex: i,
                            mutation: validateCurvePosition,
                            adcomValue
                          })
                        }}
                      />
                    </TableCell>

                    {/* Absolute */}
                    <TableCell align='right' className={clsx(classes.curveCell, { [classes.quarter]: quarter })}>
                      <EditableInputBase
                        id={`${curvesHandler.body.curveName}-input-absolute-${i + 1}`}
                        disabled={
                          (i === 0 && !curvesHandler.body.basedOnForwardCurve) ||
                          (curvesHandler.body.locked[i])
                        }
                        alignRight
                        noFormat
                        inputRef={(e) => (availableFieldsRef.current[i === 0 ? index * 31 + 3 : (index*31) + 2*(i+1) + 2] = e)}
                        value={curvesHandler.body.curveAdjustmentAbsolute[i]}
                        onChange={e => {
                          setCurvesHandler({
                            type: 'abs',
                            curveIndex: index, 
                            rowIndex: i,
                            value: parseFloat(e.target.value)
                          })
                        }}
                        onClick={(event) => {
                          handleSelectedField2(i === 0 ? index * 31 + 3 : (index*31) + 2*(i+1) + 2)
                        }}
                        onKeyDown={(event) => {
                          if (event.keyCode === 9) {
                            event.preventDefault()
                            if (event.shiftKey && i === 1) {
                              handleSelectedField2(index*31 + 29)
                            } else if (event.shiftKey) {
                              handleSelectedField2(i === 0 ? index*31 + 3 : i === 1 ? index*31 + 3 : (index*31) + 2*(i+1))
                            } else {
                              handleSelectedField2((index*31) + 2*(i+1) + 4)
                            }
                          }
                        }}
                        onBlur={e => {
                          setCurvesHandler({
                            type: 'validate',
                            id,
                            rowIndex: i,
                            mutation: validateCurvePosition,
                            adcomValue
                          })
                        }}
                      />
                    </TableCell>

                    {/* TCE */}
                    <TableCell align='right' className={clsx(classes.curveCell, { [classes.quarter]: quarter })}>
                      {
                        i === 0 && (
                          <EditableInputBase
                            id={`${curvesHandler.body.curveName}-result`}
                            alignRight
                            removeDecimals
                            inputRef={(e) => (availableFieldsRef.current[index * 31 + 4] = e)}
                            value={curvesHandler.body.result}
                            onClick={(e) => handleSelectedField2(index*31 + 4)}
                            onChange={e => {
                              setCurvesHandler({
                                type: 'result',
                                value: e.target.value,
                                curveIndex: index,
                              })
                            }}
                            onKeyDown={event => {
                              if (event.keyCode === 9 ) {
                                event.preventDefault()
                                if (event.shiftKey) {
                                  handleSelectedField2(index*31 + 1)
                                } else {
                                  handleSelectedField2((index*31) + 6)
                                }
                              }
                            }}
                            onBlur={e => {
                              updateResult({
                                variables: {
                                  input: {
                                    id: curvesHandler.id,
                                    value: curvesHandler.body.result
                                  }
                                }
                              })
                            }}
                          />
                        )

                        ||

                        i > 0 && (
                          <>
                            {formatNumber({ format: '0,0', number: tce * (1 + adcomValue / 100) })}
                          </>
                        )
                      }
                    </TableCell>

                    {/* Voyage */}
                    <TableCell align='right' className={clsx(classes.curveCell, { [classes.quarter]: quarter })}>
                      {formatNumber({ isInteger: true, number: rate * (1 + adcomValue / 100) })}
                    </TableCell>
                    {voyageOffers && getOffers(voyageOffers, posdate).map((voyage, j) => {
                      return (
                        <OfferInformation
                          adcomValue={adcomValue}
                          curvesHandler={curvesHandler}
                          voyage={voyage}
                          classes={classes}
                          key={j}
                          index={j}
                          posdateIndex={i}
                          systemSetting={systemSetting}
                          handleVoyageOpen={handleVoyageOpen}
                          fuelPrice={fuelPrice}
                        />
                      )
                    })}

                  </TableRow>
                )
              })}
            </TableBody>
          </Table>

          {/* Spreads Table */}
          <Table className={classes.spreadTableLayout} size='small'>
            <TableBody>
              <TableRow>
                <Grid style={{ marginTop: '15px', marginBottom: '5px' }}>

                  <TableCell className={clsx([classes.fixed240])} align='left'>
                    <b style={{ fontSize: 15, fontWeight: 600 }}>Spot Spreads</b>
                  </TableCell>

                  <TableCell align='center'>
                    <b style={{ fontSize: 15, fontWeight: 600, paddingLeft: 4, paddingRight: 4 }}>Absolute</b>
                  </TableCell>

                  <TableCell className={clsx([classes.fixed60])} style={{ paddingLeft: 4, paddingRight: 4 }} align='right'>
                    <b style={{ fontSize: 15, fontWeight: 600 }}>TCE</b>
                  </TableCell>
                </Grid>
              </TableRow>
              <Card className={classes.spreads} elevation={0}>
                <CardContent className={classes.spreadTable}>
                  {
                    curvesHandler.body.spotTCSpreads.length > 0 
                    ? (
                      curvesHandler.body.spotTCSpreads.map((s, j) => {
                        return (
                          <TableRow key={j}>
                            <TableCell align='left' className={classes.spredName}>
                              <HtmlTooltip
                                  title={s.spotTCSpreadName}
                                  arrow
                                >
                                  <Link
                                    target='_blank'
                                    className={classes.tableFont}
                                    underline='none'
                                    color='inherit'
                                    href={`/update-spot-tc-spread/${s.id}`}
                                  >
                                    {
                                      s.spotTCSpreadName.split('').length > 32
                                        ? `${s.spotTCSpreadName.split('').filter((char, i) => i < 32).join('')}...`
                                        : s.spotTCSpreadName
                                    }
                                  </Link>
                                </HtmlTooltip>
                            </TableCell>
                            <TableCell className={classes.spreadColumn} align='right'>
                              {formatNumber({ format: '0,0', number: tceNoSpreads * s.spreadTCValue[selectedMonth] / 100 })}
                            </TableCell>

                            <TableCell className={classes.spreadColumn} align='right'>
                              {formatNumber({ format: '0,0', number: tceNoSpreads * (1 + s.spreadTCValue[selectedMonth] / 100)})}
                            </TableCell>
                          </TableRow>
                        )
                      })
                    )
                    : <>
                        No spreads to display
                      </>
                  }
                </CardContent>
              </Card>
            </TableBody>
          </Table>

        </CardContent>
        <Menu
          keepMounted
          open={contextState.mouseY !== null}
          onClose={handleCloseMenu}
          anchorReference='anchorPosition'
          anchorPosition={
            contextState.mouseY !== null && contextState.mouseX !== null
              ? { top: contextState.mouseY, left: contextState.mouseX }
              : undefined
          }
        >
          <MenuItem onClick={handleLockRow}>Lock Row</MenuItem>
          <MenuItem onClick={handleLockCard}>Lock Card</MenuItem>
          <MenuItem onClick={handleLockAllCards}>Lock All Blocks</MenuItem>
        </Menu>

        <VoyageOfferDialog
          queryData={id}
          voyageId={voyageToDelete && voyageToDelete.id}
          open={deleteVoyageDialog}
          handleClose={handleVoyageClose}
        />

      </Box>
    )
  } else {
    return (
      <Box style={{ height: '100%', height: '100%', padding: '10px'}}>
        {/* <CircularProgress /> */}
        {
          [...positionDates, ...positionDates].map(pd => {
            return <Skeleton animation="wave" />
          })
        }
      </Box>
    )
  }
})

const spreadPopperStyles = makeStyles(theme => ({
  popper: {
    padding: '5px',
    borderRadius: '5px',
    border: '1px solid gray',
    boxShadow: '2px 2px 1px rgba(0, 0, 0, 0.05)'
  }
}))

const SpreadsPopper = (props) => {
  const { open, anchorEl, placement, spreads, base, index } = props

  const classes = spreadPopperStyles()

  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper className={classes.popper} elevation={0}>
            <Typography>{`${formatNumber({ number: base[index], format: '0,0' })} + ${formatNumber({ number: spreads, format: '0,0'})}%`}</Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}

export default withRouter(Validation)
