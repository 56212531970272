import React, {
  useRef,
  useState,
  useReducer,
  useEffect,
  useContext,
  createRef
} from 'react'
// Helmet
import Helmet from 'react-helmet'
// Apollo
import { Query, Mutation, useQuery } from 'react-apollo'
// Context
import { UserContext } from '../App/AppContext'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles' // withStyles
// Context
// import { UserContext } from '../App/AppContext'
// Queries
import QueryHelper from '../Queries/QueryHelper'
// import { GET_SPOTFIXTUERES, GET_SPOTFIXTUERE, GET_TC_INPUT, GET_TC_INPUT_INFORMATION } from '../Queries/Queries'
import {
  GET_SPOTFIXTUERES,
  GET_SPOTFIXTUERE,
  GET_VALIDATION,
  GET_VALIDATION_INFORMATION,
  GET_VALIDATION_UPDATED_RESULTS,
  GET_MY_INFORMATION
} from '../Queries/Queries'

// TODO check Subscriptions App
// Mutations
// import MutationHelper from '../Mutations/MutationHelper'
// import { SPOTINDEX_DOP, SPOTINDEX_APS, SAVE_RESULT } from '../Mutations/Mutations'
import {
  SPOTINDEX_DOP,
  SPOTINDEX_APS,
  SAVE_RESULT
} from '../Mutations/Mutations'

// import { SPOTINDEX_INDEX_EQUIVALENT, SPOTINDEX_APS, SPOTINDEX_DOP } from '../Mutations/Mutations'
import gql from 'graphql-tag'

// Material UI
import Grid from '@material-ui/core/Grid'
// import NativeSelect from '@material-ui/core/NativeSelect'
// import InputBase from '@material-ui/core/InputBase'
import Tabs from '@material-ui/core/Tabs'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import TableHead from '@material-ui/core/TableHead'
import Chip from '@material-ui/core/Chip'
import Drawer from '@material-ui/core/Drawer'
import Avatar from '@material-ui/core/Avatar'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

// Utils
import EditableInputBase from '../Utils/EditableInputBase'
import { formatNumber, fireSwalError } from '../Utils/utils'
import RootDialog from '../Utils/RootDialog'
import TextError from '../Utils/TextError'
// import { v4 as uuidv4 } from 'uuid'
import { format, utcToZonedTime } from 'date-fns-tz'

import clsx from 'clsx'
import differenceInHours from 'date-fns/differenceInHours'

const ItalicBasedFormat = props => {
  const { isCurve = false, text = 'No text', style, isLink = false, href = null, onClick = null } = props

  if (isCurve && !isLink) {
    return (
      <Typography
        className={style}
      >
        <i>{text}</i>
      </Typography>
    )
  } else if (isCurve && isLink) {
    return (
      <Typography
        className={style}
      >
        <i>
          <Link
            className={style}
            href={href}
            onClick={onClick}
          >
            {text}
          </Link>
        </i>
      </Typography>
    )
  } else if (!isCurve & isLink) {
    return (
      <Typography
        className={style}
      >
        <Link
          className={style}
          href={href}
          onClick={onClick}
        >
          {text}
        </Link>
      </Typography>
    )
  } else {
    return (
      <Typography
        className={style}
      >
        {text}
      </Typography>
    )
  }
}

const SpotValidationContainer = (props) => {
  const { ...rest } = props
  const { data: userId, error: userError, loading: loadingError } = useQuery(GET_MY_INFORMATION, { fetchPolicy: 'network-only' })

  const { data, error, loading } = useQuery(GET_SPOTFIXTUERES)

  return (
    <>
      <Helmet>
        <title>TC Input</title>
      </Helmet>
      <SpotValidationWrapper
        // availableFieldsRef={availableFieldsRef}
        dataFixtures={data ? data.getSpotFixtures.spotFixtures : []}
        loading={loading}
        error={error}
        userId={userId ? userId.getMyInformation.user.id : '-1'}
        {...rest}
      />
    </>
  )
}

const LIVE_VALIDATION = gql`
  subscription LiveValidationSuscription($input: LiveValidationInput!) {
    liveValidation(input: $input) {
      id
      validation
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  spotFixturesDrawer: {
    minWidth: '400px',
    margin: '1%'
  },
  tableCont: {
    marginRight: '2%',
    marginTop: '2%'
  },
  signleTableCont: {
    marginTop: '2%'
  },
  tableCell: {
    width: 50
  },
  isToday: {
    color: 'green'
  },
  tableParent: {
    width: '100%',
    overflowY: 'hidden',
    overflowX: 'scroll'
  },
  appBar: {
    minWidth: '100%',
    width: '100%',
    maxWidth: '100%'
  }
}))

const getFormattedDate = (updated, timeZone, i) => {
  let date, zonedTime
  if (i >= 0) {
    zonedTime = (updated[i] && updated[i]) ? utcToZonedTime(updated[i], timeZone) : ''
    date = (updated[i] && updated[i]) ? new Date(updated[i]) : ''
  } else {
    zonedTime = (updated && updated) ? utcToZonedTime(updated, timeZone) : ''
    date = (updated && updated) ? new Date(updated) : ''
  }

  const year = date.toLocaleString('default', { year: 'numeric', timeZone })
  const month = date.toLocaleString('default', { month: 'short', timeZone })
  const day = date.toLocaleString('default', { day: 'numeric', timeZone })

  const timeString = `${day}${month}${year} ${format(zonedTime, 'HH:mm:ss', { timeZone })}`

  return timeString // returns a string
}

const SpotValidationWrapper = React.memo((props) => {
  const { ...rest } = props

  const [firstRender, setFirstRender] = useState(true)
  const [queryData, setQueryData] = useState()
  const [shipSectors, setShipSectors] = useState([])
  const [indexes, setIndexes] = useState({
    shipSectorIndex: '',
    forwardCurveIndex: '',
    positionDateIndex: ''
  })
  const firstRenderSkip = !!firstRender
  const skip = !!queryData
  return (
    <SpotValidationQueryHelper
      firstRenderSkip={firstRenderSkip}
      queryData={queryData}
      setFirstRender={setFirstRender}
      setQueryData={setQueryData}
      skip={skip}
      shipSectors={shipSectors}
      setShipSectors={setShipSectors}
      indexes={indexes}
      setIndexes={setIndexes}
      {...rest}
    />
  )
})

export default withRouter(React.memo(SpotValidationContainer))

const SpotValidationQueryHelper = React.memo((props) => {
  const {
    queryData,
    indexes,
    setIndexes,
    setQueryData,
    shipSectors,
    setShipSectors,
    skip,
    loading,
    error,
    dataFixtures,
    userId,
    ...rest
  } = props

  const { user, setUser } = useContext(UserContext)

  const [focusName, setFocusName] = useState('')

  let initialValues = {
    tab: 0
  }
  if (user.forwardValidation) {
    initialValues = user.forwardValidation
  }

  const [value, setValue] = React.useState(initialValues.tab || 0)
  const [spotFixtures, setSpotFixtures] = useState(false)

  const classes = useStyles()

  const _newValidation = (subscribeToMore) => {
    subscribeToMore({
      document: LIVE_VALIDATION,
      variables: { input: { id: userId } },
      updateQuery: (previousData, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousData
        }

        // const newUpdatingRoute = subscriptionData.data.routeUpdated.routeUpdated
        return Object.assign({}, previousData, {
          getValidation: {
            validation: subscriptionData.data.liveValidation
          }
        })
      }
    })
  }
  const handleChange = (_event, newValue) => {
    setUser({
      type: 'add-forwardValidation-info',
      value: {
        ...initialValues,
        tab: newValue
      }
    })
    setValue(newValue)
  }

  const a11yProps = (index) => {
    return {
      id: `ship-sector-tab-${index}`,
      'aria-controls': `ship-sector-panel-${index}`
    }
  }

  const getCurrentIndex = (sectors, current) => {
    let skips = 0
    for (let i = 0; i < sectors.length; i++) {
      if (sectors[i].forwardCurves.length > 0) {
        if (sectors[i].id === current.id) {
          return i - skips
        }
      } else {
        skips++
      }
    }
  }

  const TabPanel = React.memo((props) => {
    const {
      actualIndex,
      value,
      index,
      positionDates,
      indexes,
      spotFixtures,
      forwardCurves,
      queryData,
      setFirstRender,
      firstRenderSkip,
      ...rest
    } = props

    // console.log('query data', queryData)

    return (
      <Grid>
        {value === index && (
          <Query
            fetchPolicy='network-only'
            query={GET_VALIDATION_UPDATED_RESULTS}
            variables={
              queryData
                ? {
                  input: {
                    index: actualIndex,
                    validation: { ...queryData, forwardCurves }
                  }
                }
                : {}
            }
            skip={!firstRenderSkip}
          >
            {({ data }) => {
              let curves = forwardCurves

              // console.log('rerenders query')

              if (data) {
                curves = data.getUpdatedResults.forwardCurves
                setFirstRender(false)
              }
              return (
                <ForwardValidationSpotIndexes
                  // handlers={handlers}
                  // setHandlers={setHandlers}
                  forwardCurves={curves}
                  positionDates={positionDates}
                  focusName={focusName}
                  setFocusName={setFocusName}
                  shipSectorIndex={actualIndex}
                  indexes={indexes}
                  spotFixtures={spotFixtures}
                  {...rest}
                />
              )
            }}
          </Query>
        )}
      </Grid>
    )
  })

  return (
    <Query fetchPolicy='network-only' query={GET_VALIDATION}>
      {({ error, data, subscribeToMore }) => {
        if (error) {
          return <div />
        }
        if (data) {
          _newValidation(subscribeToMore)
          const { getValidation: validation } = data
          if (validation) {
            setQueryData(validation.validation)
            return (
              <div className={classes.root}>
                <Query
                  fetchPolicy='network-only'
                  query={GET_VALIDATION_INFORMATION}
                  variables={
                    queryData ? { input: { validation: { ...queryData } } } : {}
                  }
                  skip={!skip}
                >
                  {({ data }) => {
                    if (data) {
                      setShipSectors(
                        data.getValidationInformation.validation.validation
                          .shipSectors
                      )
                      if (
                        data.getValidationInformation.validation.validation
                          .indexes
                      ) {
                        setIndexes(
                          data.getValidationInformation.validation.validation
                            .indexes
                        )
                      }
                      return (
                        <div className={classes.root}>
                          <AppBar position='static'>
                            <Box
                              display='flex'
                              direction='row'

                              justifyContent='space-between'
                              alignItems='center'
                            >
                              <Tabs
                                value={value}

                                onChange={handleChange}
                                aria-label='ship sector tabs'
                              >
                                {shipSectors.map((item, i) => {
                                  const index = getCurrentIndex(
                                    shipSectors,
                                    item
                                  )
                                  if (index >= 0) {
                                    return (
                                      <Tab

                                        key={i}
                                        label={item.name}
                                        {...a11yProps(i)}
                                      />
                                    )
                                  }
                                })}
                              </Tabs>
                              {!loading && (
                                <Chip
                                  avatar={<Avatar>F</Avatar>}
                                  label='Fixtures'
                                  onClick={() => setSpotFixtures(true)}
                                />
                              )}
                            </Box>
                          </AppBar>
                          {shipSectors.map((item, i) => {
                            const index = getCurrentIndex(shipSectors, item)
                            return (
                              <TabPanel
                                key={i}
                                queryData={queryData}
                                indexes={indexes}
                                forwardCurves={shipSectors[i].forwardCurves.filter(curve => !curve.basedOnForwardCurve)}
                                positionDates={
                                  data.getValidationInformation.positionDates
                                }
                                voyageOffers={
                                  data.getValidationInformation.voyageOffers
                                }
                                value={value}
                                index={index}
                                spotFixtures={dataFixtures}
                                actualIndex={i}
                                {...rest}
                              />
                            )
                          })}
                          <Drawer
                            anchor='right'
                            open={spotFixtures}
                            onClose={() => setSpotFixtures(false)}
                          >
                            {error && (
                              <TextError error='Error while getting the Fixtures' />
                            )}
                            <Box className={classes.spotFixturesDrawer}>
                              {dataFixtures.map((item, i) => {
                                return (
                                  <Box
                                    key={i}
                                    className={classes.spotFixturesDrawer}
                                  >
                                    {dataFixtures.map((item, i) => {
                                      return (
                                        <Fixture
                                          key={i}
                                          item={item}
                                          i={i}
                                          single={false}
                                        />
                                      )
                                    })}
                                  </Box>
                                )
                              })}
                            </Box>
                          </Drawer>
                        </div>
                      )
                    } else {
                      return <div />
                    }
                  }}
                </Query>
              </div>
            )
          }
          return <div />
        } else {
          return <div />
        }
      }}
    </Query>
  )
})

SpotValidationQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const ForwardValidationSpotIndexes = React.memo((props) => {
  const {
    forwardCurves,
    shipSectorIndex,
    positionDates,
    indexes,
    spotFixtures,
    setFocusName,
    focusName,
    ...rest
  } = props

  const classes = useStyles()

  // console.log('rerenders state')

  const reducer = (state, action) => {
    switch (action.type) {
      case 'apsDh':
        // console.log('Happened', action.value)
        state.curves[action.index].apsDh = action.value
        return {
          ...state
        }
      case 'apsBB':
        state.curves[action.index].apsBB = action.value
        return {
          ...state
        }
      case 'apsResult':
        // console.log(action.value)
        state.curves[action.index].apsResult = action.value
        return {
          ...state
        }
      case 'dopDh':
        state.curves[action.index].dopDh = action.value
        return {
          ...state
        }
      case 'dopResult':
        state.curves[action.index].dopResult = action.value
        return {
          ...state
        }
      case 'latestChange':
        state.curves[action.index].tcInputUpdate = new Date(Date.now())
        return {
          ...state
        }
      case 'percent':
        // console.log('%', action.value)
        state.curves[action.index].per = action.value
        // if (!state.modifiedIndexes.includes(state.curves[action.index].id)) {
        //   state.modifiedIndexes.push(state.curves[action.index].id)
        // }
        return {
          ...state
        }
      case 'spread':
        state.spreads[action.index].spreadTCValue = action.value
        if (!state.modifiedSpreads.includes(state.spreads[action.index].id)) {
          state.modifiedSpreads.push(state.spreads[action.index].id)
        }
        return {
          ...state
        }
      case 'result':
        state.curves[action.index].result = action.value
        return {
          ...state
        }
      default:
        return {
          ...state
        }
    }
  }
  const createIndexes = (params) => {
    const { forwardCurves } = params
    // console.log(params)

    const results = []
    for (let i = 0; i < forwardCurves.length; i++) {
      results.push({
        forwardCurveName: forwardCurves[i].forwardCurveName,
        basedOnForwardCurve: forwardCurves[i].basedOnForwardCurve,
        indexID: forwardCurves[i].spotIndex.id,
        indexName: forwardCurves[i].spotIndex.indexName,
        forwardCurveValue: forwardCurves[i].base ? forwardCurves[i].base[0] : forwardCurves[i].forwardCommodity.forwardArray[0],
        per: forwardCurves[i].curveAdjustmentPercent[0],
        abs: forwardCurves[i].curveAdjustmentAbsolute[0],
        result: forwardCurves[i].result ? forwardCurves[i].result : 0,
        route: forwardCurves[i].coreRoute,
        apsDh: forwardCurves[i].apsDh ? forwardCurves[i].apsDh : 0,
        apsBB: forwardCurves[i].apsBB ? forwardCurves[i].apsBB : 0,
        apsResult: forwardCurves[i].apsResult ? forwardCurves[i].apsResult : 0,
        dopDh: forwardCurves[i].dopDh ? forwardCurves[i].dopDh : 0,
        dopResult: forwardCurves[i].dopResult ? forwardCurves[i].dopResult : 0,
        indexValue: forwardCurves[i].spotIndex.indexValue,
        tcInputUpdate: forwardCurves[i].tcInputUpdate ? forwardCurves[i].tcInputUpdate : '',
        updateUser: forwardCurves[i].update && forwardCurves[i].update.user ? forwardCurves[i].update.user : ''
      })
    }
    // console.log(results)
    return results
  }
  const initialState = {
    // TODO Hardest Stuff in here
    // spreads: spotTCSpreads,
    // TODO Hardest Stuff in here
    curves: createIndexes({ forwardCurves }),
    modifiedIndexes: [],
    modifiedSpreads: []
  }

  const [handlers, setHandlers] = useReducer(reducer, initialState)

  return (
    <Grid className={classes.tableParent}>
      {forwardCurves.length > 0 && (
        <SpotIndexes
          // routes={data.routes}
          forwardCurves={forwardCurves}
          shipSector={shipSectorIndex}
          // handlers={handlers}
          // setHandlers={setHandlers}
          // vessels={data.vessels}
          // ports={data.ports}
          spotFixtures={spotFixtures}
          spotIndexes={indexes}
          shipSectorIndex={shipSectorIndex}
          positionDates={positionDates}
          handlers={handlers}
          setHandlers={setHandlers}
          // spotTCSpreads={data.spotTCSpreads}
          // setSelectedInput={setSelectedInput}
          // selectedInput={selectedInput}
          // availableFieldsRef={availableFieldsRef}
          {...rest}
        />
      )}
    </Grid>
  )
})

const Fixture = (props) => {
  const { item, i, single } = props
  const classes = useStyles()

  return (
    <TableContainer
      component={Paper}
      key={i}
      className={single ? classes.signleTableCont : classes.tableCont}
    >
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>{`Fixture ${i + 1}`}</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{`Aps DH ${item.apsDH}`}</TableCell>
            <TableCell>{`Aps BB ${item.apsPC}`}</TableCell>
            <TableCell>
              {`Aps Result ${formatNumber({ number: item.apsResult })}`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{`Dop DH ${item.dop}`}</TableCell>
            <TableCell />
            <TableCell>
              {`Dop Result ${formatNumber({ number: item.dopResult })}`}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {`Vessel ${item.vessel ? item.vessel.vesselName : '-'}`}
            </TableCell>
            <TableCell />
            <TableCell>{`Index ${item.spotIndex && item.spotIndex.indexName}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {`Delivery ${
                item.deliveryPort ? item.deliveryPort.portName : '-'
              }`}
            </TableCell>
            <TableCell />
            <TableCell>
              {`Ballast ${item.ballastPort ? item.ballastPort.portName : '-'}`}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

/**
 * @description This component handles everything related to filtering and Spot Indexes.
 */
const SpotIndexes = React.memo((props) => {
  const {
    // routes,
    // vessels,
    forwardCurves,
    spotIndexes,
    shipSector,
    handlers,
    setHandlers,
    // ports,
    spotFixtures,
    // spotTCSpreads,
    shipSectorIndex,
    // setSelectedInput,
    // selectedInput,
    // availableFieldsRef,
    positionDates,
    ...rest
  } = props
  const classes = useStyles()

  const availableFieldsRef = useRef([])

  const [selectedInput, setSelectedInput] = useState(null)

  const selectedField = useRef()

  const availableFields = []

  for (let i = 0; i < forwardCurves.length; i++) {
    for (let i = 0; i < positionDates.length * 2; i++) {
      availableFields.push(createRef())
    }
  }

  useEffect(() => {
    if (selectedInput !== null && selectedInput !== selectedField) {
      selectedField.current = selectedInput
    }
  }, [selectedInput])
  return (
    <>
      <Divider />
      {forwardCurves.length > 0 && (
        <Grid container>
          <Grid item xs={12} md={12}>
            <SpotIndexesTable
              spotFixtures={spotFixtures} // TODO Fixtures
              handlers={handlers}
              forwardCurves={forwardCurves}
              setHandlers={setHandlers}
              shipSectorIndex={shipSectorIndex}
              availableFieldsRef={availableFieldsRef}
              setSelectedInput={setSelectedInput}
              selectedInput={selectedInput}
              rest={rest}
              // vessels={selectVessels(vessels, shipSector)}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
})

const styles = makeStyles((theme) => ({
  card: {
    minHeight: '300px',
    margin: `${theme.spacing(2)}px 0`
  },
  typography: {
    width: '10%',
    marginRight: '2%'
  },
  fixture: {
    width: '130px',
    borderRight: '1px solid rgba(123, 129, 130, 1)',
    borderLeft: '1px solid rgba(123, 129, 130, 1)'
  },
  border: {
    borderLeft: '1px solid rgba(221, 221, 221, 1)'
  },
  fixedWidth250: {
    fontSize: '13px',
    minWidth: '250px'
  },
  bodyContainer: {
    whiteSpace: 'normal'
  },
  tableCell: {
    minWidth: '85px !important',
    width: '85px !important',
    maxWidth: '85px !important'
  },
  isToday: {
    color: 'green'
  },
  isYesterday: {
    color: 'orange'
  },
  isFourDays: {
    color: 'red'
  },
  widerTableCell: {
    minWidth: '125px !important',
    width: '125px !important',
    maxWidth: '125px !important'
  },
  dateFS: {
    fontSize: theme.typography.generalFontSize * 0.75
  },
  bigTableCell: {
    // fontSize: theme.typography.generalFontSize,
    minWidth: '250px !important',
    width: '250px !important',
    maxWidth: '250px !important'
  },
  fixturesCell: {
    minWidth: '10px',
    width: '10px !important',
    maxWidth: '10px !important',
    overflowX: 'scroll'
  },
  boxContainer: {
    display: 'flex',
    minWidth: '100%',
    width: '100%',
    wrap: 'nowrap',
    alignContent: 'center',
    alignItems: 'center',
    maxWidth: '100% !important',
    overflowX: 'scroll'
  },
  tableLayout: {
    tableLayout: 'fixed',
    width: 'auto'
  },
  normalText: {
    fontSize: theme.typography.generalFontSize
  },
  smallerText: {
    fontSize: theme.typography.generalFontSize * 0.75
  }
}))

const SpotIndexesTable = React.memo((props) => {
  const {
    selectedInput,
    setSelectedInput,
    availableFieldsRef,
    handlers,
    forwardCurves,
    setHandlers,
    shipSectorIndex,
    spotFixtures
  } = props
  const classes = styles()

  useEffect(() => {
    if (
      selectedInput !== null &&
      availableFieldsRef.current[selectedInput] !== null
    ) {
      try {
        availableFieldsRef.current[selectedInput].focus()
      } catch (err) {
        try {
          setSelectedInput(0)
        } catch (err) {}
      }
    }
  }, [selectedInput])

  // const setResultMutation = () => { }
  // let setApsMutation = () => { }
  // let setDopMutation = () => { }
  const [vesselDop, setVesselDop] = useState()
  const vesselDopRef = React.useRef(vesselDop)

  const [vesselAps, setVesselAps] = useState()
  const vesselApsRef = React.useRef(vesselAps)

  const [modifiedIndex, setModifiedIndex] = useState()

  useEffect(() => {
    if (vesselDop !== vesselDopRef.current) {
      vesselDopRef.current = vesselDop
      setHandlers({
        type: 'dopResult',
        value: vesselDop,
        index: modifiedIndex
      })
    }
  }, [vesselDop])

  useEffect(() => {
    if (vesselAps !== vesselApsRef.current) {
      vesselApsRef.current = vesselAps
      setHandlers({
        type: 'apsResult',
        value: vesselAps,
        index: modifiedIndex
      })
    }
  }, [vesselAps])

  const compare = (a, b) => {
    const bandA = a.dopResult
    const bandB = b.dopResult
    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }
  const getIndexesFixtures = (fixtures, item) => {
    const result = []
    for (let i = 0; i < fixtures.length; i++) {
      if (fixtures[i].spotIndex.id === item.indexID) {
        if (fixtures[i].dop !== 0) {
          result.push(fixtures[i])
        }
      }
    }
    return result.sort(compare)
  }
  const fixtures = []
  for (let i = 0; i < handlers.curves.length; i++) {
    fixtures.push(getIndexesFixtures(spotFixtures, handlers.curves[i]))
  }
  let highestFixture = 0
  for (let index = 0; index < fixtures.length; index++) {
    if (fixtures[index].length > highestFixture) {
      highestFixture = fixtures[index].length
    }
  }
  const [fixturesDialog, setFixturesDialog] = useState(false)
  const [fixtureToRead, setfixtureToRead] = useState('')

  const openFixtureDialog = (params = {}) => {
    const { id } = params
    if (id) {
      setfixtureToRead(id)
      setFixturesDialog(true)
    }
  }

  // let firstIndex = 0

  // const getIndex = () => {
  //   const currIdx = firstIndex
  //   firstIndex++
  //   return currIdx
  // }

  const tableRowColor = 'green'
  console.log('handlers', handlers)
  // console.log('--------------')
  return (
    <>
      <Table className={classes.tableLayout} size='small' aria-label='Spot Indexes Table'>
        <TableHead>
          <TableRow>
            <TableCell>Forward Curve</TableCell>
            <TableCell align='right'>Forward</TableCell>
            <TableCell align='right'>Spread %</TableCell>
            <TableCell align='right'>Value + %</TableCell>
            <TableCell align='right'>Spread Absolute</TableCell>
            <TableCell align='right'>Result</TableCell>
            <TableCell align='right'>APS Hire</TableCell>
            <TableCell align='right'>APS BB</TableCell>
            <TableCell align='right'>APS Rate</TableCell>
            <TableCell align='right'>DOP Hire</TableCell>
            <TableCell align='right'>DOP Rate</TableCell>
            <TableCell align='right'>Updated</TableCell>
            <TableCell colSpan={highestFixture} align='left'>
              Fixtures
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.bodyContainer}>
          {handlers.curves.map((item, i) => (
            <TableRow key={i}>
              <TableCell className={classes.bigTableCell} align='right'>
                {/* <Typography className={classes.normalText}>
                  {item.forwardCurveName}
                </Typography> */}

                <ItalicBasedFormat
                  text={item.forwardCurveName}
                  isCurve={item.basedOnForwardCurve}
                  style={classes.normalText}
                />

                <ItalicBasedFormat
                  text={item.basedOnForwardCurve && `Based on: ${item.basedOnForwardCurve.forwardCurveName}`}
                  isCurve={item.basedOnForwardCurve}
                  style={classes.normalText}
                />

                {/* <Typography className={classes.smallerText}>
                  {`${item.indexName}: ${item.indexValue}`}
                </Typography> */}

                <ItalicBasedFormat
                  text={`${item.indexName}: ${item.indexValue}`}
                  isCurve={item.basedOnForwardCurve}
                  style={classes.smallerText}
                />

              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='right'
              >{`${item.forwardCurveValue}`}
              </TableCell>
              {item.route ? (
                <>
                  <Mutation
                    mutation={SAVE_RESULT}
                    // { ...( props.onCompleted ?
                    //   { onCompleted: props.onCompleted } :
                    //   { onCompletedObject: {
                    //     title: 'Success',
                    //     message: 'Result Created'
                    //   } }
                    // )}
                    variables={{
                      input: {
                        data: {
                          result: item.result,
                          cp: item.per,
                          ca:
                            item.result -
                            item.forwardCurveValue * (1 + item.per / 100),
                          route: item.route.id,
                          shipSectorIndex,
                          forwardCurveIndex: i
                        }
                      }
                    }}
                    onError={(error) => {
                      fireSwalError({
                        title: 'Error',
                        text: error
                          ? error.message.split(':')[1]
                          : 'Error calculating please verify core route.'
                      })
                    }}
                  >
                    {(mutation, result) => {
                      return (
                        <TableCell align='right' className={classes.tableCell}>
                          <EditableInputBase
                            alignRight
                            // inputRef={(e) => (availableFieldsRef.current[0 + (2 * i)] = e)}
                            onBlur={() => mutation()}
                            onKeyDown={(e) => {
                              if (e.keyCode === 9 || e.keyCode === 13) {
                                e.preventDefault()
                                mutation()
                                setSelectedInput(1 + 4 * i)
                              }
                            }}
                            isInteger
                            // onClick={(e) => setSelectedInput(0 + (2 * i))}
                            onChange={(e) =>
                              setHandlers({
                                value: e.target.value,
                                type: 'percent',
                                index: i
                              })}
                            value={item.per}
                          />
                        </TableCell>
                      )
                    }}
                  </Mutation>
                  {/* <TableCell align='right'>
                    <EditableInputBase
                      alignRight
                      onChange={(e) => setHandlers({ value: e.target.value, type: 'percent', index: i })}
                      value={formatNumber({ number: (item.per), format: '0,0' })}
                    />
                  </TableCell> */}
                  <TableCell align='right' className={classes.tableCell}>
                    <EditableInputBase
                      alignRight
                      disabled
                      removeDecimals
                      value={formatNumber({
                        number: item.forwardCurveValue * (1 + item.per / 100),
                        format: '0,0'
                      })}
                    />
                  </TableCell>
                  <TableCell align='right' className={classes.tableCell}>
                    <EditableInputBase
                      alignRight
                      disabled
                      removeDecimals
                      value={
                        item.result -
                        item.forwardCurveValue * (1 + item.per / 100)
                      }
                    />
                  </TableCell>
                  <Mutation
                    mutation={SAVE_RESULT}
                    variables={{
                      input: {
                        data: {
                          result: item.result,
                          cp: item.per,
                          ca:
                            item.result -
                            item.forwardCurveValue * (1 + item.per / 100),
                          route: item.route.id,
                          shipSectorIndex,
                          forwardCurveIndex: i
                        }
                      }
                    }}
                    onError={(error) => {
                      fireSwalError({
                        title: 'Error',
                        text: error
                          ? error.message.split(':')[1]
                          : 'Error calculating please verify core route.'
                      })
                    }}
                  >
                    {(mutation, result) => {
                      // console.log(item)
                      const hours = differenceInHours(new Date(Date.now()), new Date(item.tcInputUpdate))
                      // console.log(hours)
                      return (
                        <TableCell align='right' className={classes.tableCell}>
                          <EditableInputBase
                            alignRight
                            className={clsx({
                              [classes.isFourDays]: hours > 96,
                              [classes.isYesterday]: hours > 24 && hours <= 96,
                              [classes.isToday]: hours <= 24
                            })}
                            removeDecimals
                            inputRef={(e) =>
                              (availableFieldsRef.current[0 + 4 * i] = e)}
                            // onBlur={() => mutation()}
                            onKeyDown={(e) => {
                              if (e.keyCode === 9 || e.keyCode === 13) {
                                e.preventDefault()

                                if (item.result !== forwardCurves[i].result) {
                                  mutation()
                                  setHandlers({
                                    type: 'latestChange',
                                    index: i
                                  })
                                }
                                if (e.shiftKey) {
                                  setSelectedInput(4 * i - 1)
                                } else {
                                  setSelectedInput(1 + 4 * i)
                                }
                              }
                            }}
                            onClick={(e) => setSelectedInput(0 + 4 * i)}
                            onChange={(e) => setHandlers({
                              value: e.target.value,
                              type: 'result',
                              index: i
                            })}
                            value={item.result}
                          />
                        </TableCell>
                      )
                    }}
                  </Mutation>
                  <Mutation
                    mutation={SPOTINDEX_APS}
                    // { ...( props.onCompleted ?
                    //   { onCompleted: props.onCompleted } :
                    //   { onCompletedObject: {
                    //     title: 'Success',
                    //     message: 'Result Created'
                    //   } }
                    // )}
                    variables={{
                      input: {
                        data: {
                          aps: item.apsDh,
                          apsBunkerCost: item.apsBB,
                          route: item.route.id,
                          shipSectorIndex,
                          forwardCurveIndex: i
                        }
                      }
                    }}
                    onError={(error) => {
                      fireSwalError({
                        title: 'Error',
                        text: error
                          ? error.message.split(':')[1]
                          : 'Error calculating please verify core route.'
                      })
                    }}
                  >
                    {(mutation, result) => {
                      // setApsMutation = () => { mutation() }
                      if (result.data) {
                        setModifiedIndex(i)
                        setVesselAps(result.data.apsResult[0].rate)
                      }
                      return (
                        <>
                          <TableCell
                            className={classes.tableCell}
                            align='right'
                          >
                            <EditableInputBase
                              alignRight
                              removeDecimals
                              inputRef={(e) =>
                                (availableFieldsRef.current[1 + 4 * i] = e)}
                              onKeyDown={(e) => {
                                if (e.keyCode === 9 || e.keyCode === 13) {
                                  e.preventDefault()
                                  if (item.apsDh !== 0) {
                                    mutation()
                                  }
                                  if (e.shiftKey) {
                                    setSelectedInput(4 * i)
                                  } else {
                                    setSelectedInput(2 + 4 * i)
                                  }
                                }
                              }}
                              className={classes.border}
                              onBlur={() =>
                                item.apsDh !== 0 ? mutation() : ''}
                              onClick={(e) => setSelectedInput(1 + 4 * i)}
                              onChange={(e) =>
                                setHandlers({
                                  value: e.target.value,
                                  type: 'apsDh',
                                  index: i
                                })}
                              value={item.apsDh}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.tableCell}
                            align='right'
                          >
                            <EditableInputBase
                              alignRight
                              removeDecimals
                              inputRef={(e) =>
                                (availableFieldsRef.current[2 + 4 * i] = e)}
                              onKeyDown={(e) => {
                                if (e.keyCode === 9 || e.keyCode === 13) {
                                  e.preventDefault()
                                  if (item.apsDh !== 0) {
                                    mutation()
                                  }
                                  if (e.shiftKey) {
                                    setSelectedInput(4 * i + 1)
                                  } else {
                                    setSelectedInput(3 + 4 * i)
                                  }
                                }
                              }}
                              onClick={(e) => setSelectedInput(2 + 4 * i)}
                              onBlur={() =>
                                item.apsDh !== 0 ? mutation() : ''}
                              onChange={(e) =>
                                setHandlers({
                                  value: e.target.value,
                                  type: 'apsBB',
                                  index: i
                                })}
                              value={item.apsBB}
                            />
                          </TableCell>
                        </>
                      )
                    }}
                  </Mutation>
                  <TableCell className={classes.tableCell} align='right'>
                    <EditableInputBase
                      alignRight
                      disabled
                      value={item.apsResult}
                    />
                  </TableCell>
                  <Mutation
                    mutation={SPOTINDEX_DOP}
                    // { ...( props.onCompleted ?
                    //   { onCompleted: props.onCompleted } :
                    //   { onCompletedObject: {
                    //     title: 'Success',
                    //     message: 'Result Created'
                    //   } }
                    // )}
                    variables={{
                      input: {
                        data: {
                          dopDh: item.dopDh,
                          route: item.route.id,
                          shipSectorIndex,
                          forwardCurveIndex: i
                        }
                      }
                    }}
                    onError={(error) => {
                      fireSwalError({
                        title: 'Error',
                        text: error
                          ? error.message.split(':')[1]
                          : 'Error calculating please verify core route.'
                      })
                    }}
                  >
                    {(mutation, result) => {
                      // setDopMutation = () => { mutation() }
                      if (result.data) {
                        setModifiedIndex(i)
                        setVesselDop(result.data.dopResult.rate)
                      }
                      return (
                        <TableCell className={classes.tableCell} align='right'>
                          <EditableInputBase
                            alignRight
                            removeDecimals
                            inputRef={(e) =>
                              (availableFieldsRef.current[3 + 4 * i] = e)}
                            onKeyDown={(e) => {
                              if (e.keyCode === 9 || e.keyCode === 13) {
                                e.preventDefault()
                                mutation()
                                if (e.shiftKey) {
                                  setSelectedInput(2 + 4 * i)
                                } else {
                                  setSelectedInput(4 + 4 * i)
                                }
                              }
                            }}
                            onClick={(e) => setSelectedInput(3 + 4 * i)}
                            onBlur={() => (item.dopDh !== 0 ? mutation() : '')}
                            onChange={(e) =>
                              setHandlers({
                                value: e.target.value,
                                type: 'dopDh',
                                index: i
                              })}
                            value={item.dopDh}
                          />
                        </TableCell>
                      )
                    }}
                  </Mutation>
                  <TableCell className={classes.tableCell} align='right'>
                    <EditableInputBase
                      alignRight
                      disabled
                      value={item.dopResult}
                    />
                  </TableCell>
                  <TableCell className={classes.widerTableCell} align='right'>
                    <Typography className={classes.dateFS}>
                      {`${item.updateUser} ${item.tcInputUpdate ? getFormattedDate(item.tcInputUpdate, 'Europe/Copenhagen') : ''}`}
                    </Typography>
                  </TableCell>
                  {fixtures[i].map((fixture, index) => (
                    <TableCell
                      key={index}
                      className={classes.fixture}
                      align='center'
                      onClick={() => openFixtureDialog({ id: fixture.id })}
                    >
                      {`${formatNumber({
                        number: fixture.dop,
                        format: '0,0'
                      })} - ${formatNumber({ number: fixture.dopResult })}`}
                    </TableCell>
                  ))}
                </>
              ) : (
                <TableCell align='center' colSpan={highestFixture + 8}>
                  {' '}
                  There is no core Route in this index{' '}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <RootDialog
        open={fixturesDialog}
        title='Fixture'
        setOpen={setFixturesDialog}
      >
        <QueryHelper
          query={GET_SPOTFIXTUERE}
          variables={{ input: { id: fixtureToRead } }}
        >
          {({ data }) => {
            const { getSpotFixture } = data
            return <Fixture item={getSpotFixture.spotFixture} i={0} single />
          }}
        </QueryHelper>
      </RootDialog>
    </>
  )
})

// const SpotTCSpreadsTable = (props) => {
//   const { handlers, setHandlers } = props
//   return (
//     <Table size='small' aria-label='SpotTC Spread Table'>
//       <TableBody>
//         {handlers.spreads.map((item, i) => (
//           <TableRow key={i}>
//             <TableCell>
//               {item.spotTCSpreadName}
//             </TableCell>
//             <TableCell align='right'>
//               <EditableInputBase
//                 alignRight
//                 onChange={(e) => setHandlers({ value: e.target.value, type: 'spread', index: i })}
//                 value={item.spreadTCValue}
//               />
//             </TableCell>
//           </TableRow>
//         ))}
//       </TableBody>
//     </Table>
//   )
// }
