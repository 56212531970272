// CLSX
import clsx from 'clsx'

import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
// Ag Grid
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'
// MAterial UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { UserContext } from '../App/AppContext'
// Mutation
import MutationHelper from '../Mutations/MutationHelper'
import {
  UPSERT_CARGOWORKING_TABLE,
  UPDATE_CARGO_OFFER
} from '../Mutations/Mutations'
import { CREATE_CARGOWORKING_OFFER_INFORMATION } from '../Queries/Queries'

// Editors
import CargoOfferAutocomplete from './CargoOfferAutocomplete'
import CargoOfferDatePicker from './CargoOfferDatePicker'
import StatusAutocomplete from './StatusAutocomplete'
import ShowSummary from '../Summary/ShowSummary'

// Utils
import TableSettingsModal from '../Utils/TableSettingsModal'
import Link from '@material-ui/core/Link'
import RootColumnLink from '../Utils/RootColumnLink'
import Snackbar from '../Utils/SnackBar'
import { formatNumber, getFormattedUtcDate } from '../Utils/utils'
// Apollo
import { useMutation, useQuery } from 'react-apollo'

import ActionsRenderer from './ActionsRenderer'
const { format, utcToZonedTime } = require('date-fns-tz')

const useStyles = makeStyles((theme) => ({
  noExpandIcon: {
    marginLeft: '30px'
  }
}))

function suppressEnter (params) {
  var KEY_ENTER = 13
  var event = params.event
  var key = event.which
  var suppress = key === KEY_ENTER
  if (params.editing) {
    return suppress
  } else {
    return false
  }
}

// import Typography from '@material-ui/core/Typography'
// Utils
// import RootColumnLink from '../Utils/RootColumnLink'

const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

const cargoReducer = (state, action) => {
  switch (action.type) {
    // case 'acceptedOffer': {
    //   const result = []
    //   for (let i = 0; i < state.length; i++) {
    //     const item = state[i]
    //     result.push({
    //       ...state[i]
    //     })
    //     if (item.cargoId === action.mainRow && item.offerId === action.row) {
    //       console.log(state[i])
    //       result.push({
    //         ...state[i]
    //       })
    //     }
    //   }

    //   return result
    // }
    case 'addAll': {
      return action.value.filter((row) => !row.hidden)
    }
    case 'expand-less': {
      return action.value.filter((row) => !row.hidden)
    }
    case 'expand-more': {
      const result = []
      action.value.forEach((item, i) => {
        if (item.cargoId === action.cargoId) {
          result.push({
            ...item,
            expand: true,
            hidden: false
          })
        } else {
          if (!item.hidden) {
            result.push({
              ...item
            })
          }
        }
      })
      return result
    }
    case 'expand': {
      const result = []
      action.value.forEach((item, i) => {
        if (item.cargoId === action.cargoId) {
          result.push({
            ...item,
            expand: true,
            hidden: false
          })
        } else {
          if (!item.hidden) {
            result.push({
              ...item
            })
          }
        }
      })
      return result
    }
    case 'add-offers': {
      const result = state.map((item, i) => {
        if (item.id !== action.value) {
          return { ...item }
        } else {
          if (item.offers.length < 10) {
            return {
              ...item
            }
          } else {
            return {
              ...item
            }
          }
        }
      })
      return result
    }
    case 'delete-offer': {
      // If the way we display the data changes, this algorithm must be changed too.
      let result = state.map((item) => {
        return { ...item }
      })
      let deleteRow = true
      let oneRowCargoIndex = null

      for (let i = 0; i < result.length; i++) {
        if (
          result[i].cargoId === action.value.cargo &&
          result[i].offerNumber > 1
        ) {
          result[i].offerNumber -= 1
          break
        } else if (
          result[i].cargoId === action.value.cargo &&
          result[i].offerNumber === 1
        ) {
          deleteRow = false
          oneRowCargoIndex = i
        }
      }

      if (deleteRow) {
        result = result.filter((item) => item.offerId !== action.value.offer)
      } else {
        result[oneRowCargoIndex].offer = null
        result[oneRowCargoIndex].owner = null
        result[oneRowCargoIndex].port = null
        result[oneRowCargoIndex].vessel = null
        result[oneRowCargoIndex].offerDate = null
        result[oneRowCargoIndex].comments = null
        result[oneRowCargoIndex].offerId = null
        // result = state
      }
      return result
    }
    case 'hide-offers': {
      const result = state.map((item, i) => {
        if (item.id !== action.value) {
          return { ...item }
        } else {
          return {
            ...item
          }
        }
      })
      return result
    }
    case 'new-data': {
      const result = action.value.map((item) => {
        return { ...item }
      })
      return result
    }
    case 'addRowBelow': {
      const result = []
      let foundCargoIndex = -1
      for (let i = 0; i < state.length; i++) {
        const item = state[i]
        if (item.cargoId === action.mainRow) {
          if (item.offerNumber > 0) {
            foundCargoIndex = i
          }
          if (item.offerNumber === 1) {
            if (item.offerId) {
              result.push({
                ...item
              })
              result.push({
                cargoId: action.mainRow,
                offerId: action.data.createCargoOfferWithoutOffer.cargoOffer.id
              })
              result[foundCargoIndex].offerNumber += 1
            } else {
              result.push({
                ...item,
                offerId: action.data.createCargoOfferWithoutOffer.cargoOffer.id
              })
            }
          } else if (item.offerId === action.row) {
            if (result[foundCargoIndex].offerNumber < 3) {
              result.push({
                ...item
              })
            }
            result.push({
              cargoId: action.mainRow,
              offerId: action.data.createCargoOfferWithoutOffer.cargoOffer.id
            })
            result[foundCargoIndex].offerNumber += 1
          } else {
            result.push({
              ...state[i]
            })
          }
        } else {
          result.push({
            ...state[i]
          })
        }
      }

      return result
    }
  }
}
// RowSpan
function rowSpan (params) {
  const expanded = false
  if (params.data.offerNumber) {
    if (params.data.expand) {
      return params.data.offerNumber
    } else if (params.data.offerNumber > 3) {
      return 3
    } else {
      return params.data.offerNumber
    }
  } else {
    return 1
  }
}

const getFormattedDate = (updated, timeZone, i) => {
  let date, zonedTime
  if (i >= 0) {
    zonedTime =
      updated[i] && updated[i] ? utcToZonedTime(updated[i], timeZone) : ''
    date = updated[i] && updated[i] ? new Date(updated[i]) : ''
  } else {
    zonedTime = updated && updated ? utcToZonedTime(updated, timeZone) : ''
    date = updated && updated ? new Date(updated) : ''
  }

  const year = date.toLocaleString('default', { year: 'numeric', timeZone })
  const month = date.toLocaleString('default', { month: 'short', timeZone })
  const day = date.toLocaleString('default', { day: 'numeric', timeZone })

  const timeString = `${day}${month}${year}`

  return timeString // returns a string
}

const getInititalRowData = (data) => {
  let prevId = ''
  let count = 0
  let max = 0
  const result = []
  for (let i = 0; i < data.length; i++) {
    if (prevId !== data[i].cargoId) {
      count = 1
      max = data[i].maxRows
      prevId = data[i].cargoId
      result.push({
        ...data[i]
      })
    } else if (data[i].cargoId === prevId && count < max) {
      count++
      result.push({
        ...data[i]
      })
    }
  }
  return result
}

// Main component
const ShowCargoWorkingAgGrid = (props) => {
  const {
    setCargoWorkings,
    cargoWorkings,
    rowData = [],
    vertical,
    localOwner,
    setLocalOwner,
    localPort,
    setLocalPort,
    localVessel,
    setLocalVessel,
    currentRowData,
    setCurrentRowData,
    handleTabMutation,
    handleTabMutationPort,
    handleTabMutationVessel,
    currentNode,
    ownerRef,
    setCurrentNode,
    updateCargoWorkingOffers,
    globalRef,
    offerrMessage = {
      open: false,
      message: ''
    },
    setOfferMessage = () => {}
  } = props

  const classes = useStyles()

  const visibleRows = rowData.filter((row) => !row.hidden)
  const [gridApi, setGridApi] = React.useState(null)
  const [gridColumnApi, setGridColumnApi] = React.useState(null)
  const [cargo, setCargo] = React.useReducer(cargoReducer, visibleRows)
  const [vessel, setVessel] = useState({})
  const [showSummary, setShowSummary] = useState(false)

  const lastOwnerRef = useRef(null)
  const lastRowRef = useRef(null)
  const [sameOwner, setSameOwner] = useState({ row: null, owner: null })

  console.log('row data: ', rowData)

  // React.useEffect(() => {
  //   if (visibleRows.length !== cargo.length) {
  //     setCargo({ type: 'new-data', value: visibleRows })
  //   }
  // }, [visibleRows, cargo])

  const { data: offerOptions, error, loading } = useQuery(
    CREATE_CARGOWORKING_OFFER_INFORMATION,
    {
      fetchPolicy: 'network-only'
    }
  )
  const ownerOptions = offerOptions?.createCargoWorkingOfferInformation.owners?.map(
    (item) => {
      return { id: item.id, name: item.name }
    }
  )
  const vesselOptions = offerOptions?.createCargoWorkingOfferInformation.vessels?.map(
    (item) => {
      return { id: item.id, name: item.name }
    }
  )
  const portOptions = offerOptions?.createCargoWorkingOfferInformation.ports?.map(
    (item) => {
      return { id: item.id, name: item.name }
    }
  )

  // Structure of the data to be rendered in the Grid.
  // Styling the grid
  const getCellStyle = params => {
    // if (params.data.color/* % 2 === 0*/) {
    //     return { background: '#D7EAFF'}
    // }
    return { fontSize: '14px' }
  }

  const columnDefs = [
    {
      field: 'date',
      colId: 'cargoOffer',
      headerName: 'Date',
      cellRenderer: 'link',
      width: 115,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1,
        'show-cell': 'value !== undefined'
      },
      cellRendererParams: {
        rowData,
        vertical
      },
      cellStyle: getCellStyle
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: 'showCellRenderer',
      width: 80,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1,
        'show-cell': 'value !== undefined'
      },
      editable: (params) => {
        if (params.data.canEdit) {
          return true
        } else {
          return false
        }
      },
      cellEditor: 'statusAutocompleteEditor',
      cellEditorParams: {
        options: [
          'FIRM',
          'SUB-SALE',
          'PRICING',
          'DEAD',
          'OTHERS',
          'US',
          'FAILED'
        ],
        label: 'Status'
      },
      suppressKeyboardEvent: (params) => {
        return suppressEnter(params)
      },
      cellStyle: getCellStyle
    },
    {
      field: 'account',
      headerName: 'Account',
      cellRenderer: 'showCellRenderer',
      width: 140,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    {
      field: 'route',
      headerName: 'Route',
      cellRenderer: 'routeCellRenderer',
      width: 200,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle,
      comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
        // to-do: replicate this function for every single column in the table
        const userA = nodeA.data.userId
        const userB = nodeB.data.userId
        const routeA = nodeA.data.routeName.split('-')[0].trim()
        const routeB = nodeB.data.routeName.split('-')[0].trim()

        if (isInverted) {
          if (userA === userB) {
            if (routeA == routeB) {
              return 0
            } else if (routeA > routeB) {
              return 1
            } else if (routeA < routeB) {
              return -1
            }
          }
        } else {
          if (userA === userB) {
            if (routeA === routeB) {
              return 0
            } else if (routeA > routeB) {
              return 1
            } else if (routeA < routeB) {
              return -1
            }
          }
        }
      }
    },
    {
      field: 'stemTolerance',
      headerName: 'Stem Tolerance',
      cellRenderer: 'showCellRenderer',
      width: 65,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    {
      field: 'portsInCargo',
      headerName: 'Ports in cargo',
      cellRenderer: 'showCellRenderer',
      width: 120,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    // {
    //   field: "portsInCargo",
    //   headerName: "Ports in cargo",
    //   cellRenderer: "showCellRenderer",
    //   width: 120,
    //   rowSpan: rowSpan,
    //   cellClassRules: {
    //     "cell-span": (params) => params.data.offerNumber > 1,
    //   },
    //   cellStyle: getCellStyle
    // },
    {
      field: 'commission',
      headerName: 'Commission',
      cellRenderer: 'numberCellRenderer',
      width: 50,
      filter: false,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    {
      field: 'laycan',
      headerName: 'Laycan',
      cellRenderer: 'showCellRenderer',
      editable: (params) => {
        if (params.data.canEdit && !vertical) {
          return true
        } else {
          return false
        }
      },
      width: 100,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    {
      field: 'month',
      headerName: 'Position',
      cellRenderer: 'showCellRenderer',
      width: 50,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    {
      field: 'bid',
      headerName: 'Bid',
      cellRenderer: 'numberCellRenderer',
      editable: (params) => {
        if (params.data.canEdit && !vertical) {
          return true
        } else {
          return false
        }
      },
      width: 60,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    {
      field: 'result',
      headerName: 'Result',
      cellRenderer: 'numberCellRenderer',
      width: 60,
      rowSpan: rowSpan,
      cellClassRules: {
        'cell-span': (params) => params.data.offerNumber > 1
      },
      cellStyle: getCellStyle
    },
    {
      headerName: '',
      colId: 'actions',
      cellRendererSelector: (params) => {
        const renderButtons = {
          component: 'actionsRenderer',
          params: {
            params,
            setCargo,
            setCargoWorkings,
            cargoWorkings,
            visibleRows

          }
        }

        // if (params.data.offerId) {
        //   return renderButtons
        // } else {
        //   return null
        // }
        return renderButtons
      },
      // cellRenderer: "actionsRenderer",
      editable: false,
      cellRendererParams: {
        setCargoWorkings,
        vertical,
        isAddButton: true
      },
      filter: false,
      width: 30,
      cellStyle: getCellStyle
    },
    {
      field: 'offer',
      headerName: 'Offer',
      width: 60,
      cellRenderer: 'offerCellRenderer',
      editable: (params) => {
        if (params.data.offerId && !vertical) {
          return true
        } else {
          return false
        }
      },
      cellClassRules: {
        'accepted-cell-span': (params) => params.data.acceptedCargoOffer
      },
      cellStyle: getCellStyle
    },
    {
      field: 'owner',
      headerName: 'Owner',
      editable: true,
      width: 120,
      cellRenderer: 'ownerCellRenderer',
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        setLocalValue: setLocalOwner,
        handleTab: handleTabMutation,
        options: ownerOptions,
        label: 'Owners',
        sameOwner,
        setSameOwner
      },
      cellClassRules: {
        'accepted-cell-span': (params) => params.data.acceptedCargoOffer
      },
      editable: (params) => {
        if (params.data.offer && !vertical) {
          return true
        } else {
          return false
        }
      },
      cellStyle: getCellStyle
    },
    {
      field: 'comments',
      headerName: 'Comments',
      width: 200,
      cellClassRules: {
        'accepted-cell-span': (params) => params.data.acceptedCargoOffer
      },
      editable: (params) => {
        if (params.data.offerId && !vertical) {
          return true
        } else {
          return false
        }
      },
      cellStyle: getCellStyle
    },
    {
      field: 'vessel',
      headerName: 'Vessel',
      width: 120,
      editable: true,
      cellRenderer: 'vesselCellRenderer',
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        setLocalValue: setLocalVessel,
        handleTab: handleTabMutationVessel,
        options: vesselOptions,
        label: 'Vessel'
      },
      editable: (params) => {
        if (params.data.offerId && !vertical) {
          return true
        } else {
          return false
        }
      },
      cellClassRules: {
        'accepted-cell-span': (params) => params.data.acceptedCargoOffer
      },
      cellStyle: getCellStyle
    },
    {
      field: 'port',
      headerName: 'Port Open',
      width: 120,
      cellRenderer: 'portCellRenderer',
      cellEditor: 'autocompleteEditor',
      cellEditorParams: {
        options: portOptions,
        label: 'Port',
        setLocalValue: setLocalPort,
        handleTab: handleTabMutationPort
      },
      cellClassRules: {
        'accepted-cell-span': (params) => params.data.acceptedCargoOffer
      },
      editable: (params) => {
        if (params.data.offerId && !vertical) {
          return true
        } else {
          return false
        }
      },
      cellStyle: getCellStyle
    },
    {
      field: 'offerDate',
      headerName: 'Date Open',
      width: 120,
      cellRenderer: 'dateCellRenderer',
      cellEditor: 'datePickerEditor',
      cellClassRules: {
        'accepted-cell-span': (params) => params.data.acceptedCargoOffer
      },
      editable: (params) => {
        if (params.data.offerId && !vertical) {
          return true
        } else {
          return false
        }
      },
      cellStyle: getCellStyle
    },
    {
      headerName: '',
      colId: 'actions',
      cellRendererSelector: (params) => {
        const renderButtons = {
          component: 'actionsRenderer',
          params: {
            params,
            setCargo,
            setCargoWorkings,
            visibleRows

          }
        }

        // if (params.data.offerId) {
        //   return renderButtons
        // } else {
        //   return null
        // }
        return renderButtons
      },
      // cellRenderer: "actionsRenderer",
      editable: false,
      cellRendererParams: {
        setCargoWorkings,
        vertical,
        isAddButton: false
      },
      filter: false,
      width: 120,
      cellStyle: getCellStyle
    }
  ]

  const verticalRef = React.useRef(vertical)
  // React.useEffect(() => {
  //   if (verticalRef.current !== vertical) {
  //     setCargoWorkings({
  //       value: rowData,
  //       type: 'addAll'
  //     })
  //     verticalRef.current = vertical
  //   }
  // }, [vertical, rowData, setCargoWorkings])
  /*
  CONTEXT MENU STATES
  */
  const { setUser } = React.useContext(UserContext)

  const [contextState, setContextState] = React.useReducer(
    contextReducer,
    initialState
  )
  const handleClose = () => {
    setContextState({
      type: 'close'
    })
  }

  const handleClick = (event, params) => {
    console.log('params ', { params })

    event.preventDefault()
    setContextState({
      event,
      selectedRow: params,
      type: 'open'
    })
  }

  const saveState = () => {
    window.colState = gridColumnApi.getColumnState()
  }

  const saveSettings = (props) => {
    gridColumnApi.applyColumnState({ state: props.columns })
  }

  /*
  CELL RENDERER WITH CONTEXT MENU
  */
  const ContextMenuCell = (props) => {
    const { value } = props
    // return <div>{`Renderer 1: ${value}`}</div>
    return <div>{value}</div>
  }

  // Show Cell Renderer
  const createShowCellRenderer = (params) => {
    const handleClickCell = (e) => {
      handleClick(e, params.data)
    }

    const cellBlank = !params.value
    if (cellBlank) {
      return null
    }

    if (params.data.offerNumber > 0) {
      return (
        <div onContextMenu={handleClickCell}>
          {params.value}
        </div>
      )
    } else {
      return <></>
    }
  }

  // Number Cell Renderer
  const createNumberCellRenderer = (params) => {
    const handleClickCell = (e) => {
      handleClick(e, params.data)
    }

    const cellBlank = !params.value
    if (cellBlank) {
      return null
    }

    if (params.data.offerNumber > 0) {
      return (
        <div onContextMenu={handleClickCell}>
          {(!isNaN(parseFloat(params.value))) ? formatNumber({ number: parseFloat(params.value), format: '0.00' }) : params.value}
        </div>
      )
    } else {
      return <></>
    }
  }

  // Offer Cell Renderer
  const offerCellRenderer = (params) => {
    const { value } = params
    if (params.data.accepted) {
      return <div style={{ color: 'green' }}>{value && formatNumber({ number: value, format: '0.00' })}</div>
    } else {
      return <div>{value && formatNumber({ number: value, format: '0.00' })}</div>
    }
  }

  // Owner Cell Renderer
  const createOwnerCellRenderer = (params) => {
    const cellBlank = !params.value

    if (cellBlank) {
      return null
    }

    if (params.data?.owner && typeof params.data.owner === 'object') {
      return <div>{params.data?.owner?.name}</div>
    } else if (params.data?.owner && typeof params.data.owner === 'string') {
      return <div>{params.data?.owner}</div>
    } else {
      return <div />
    }
  }

  // Port Cell Renderer
  const createPortCellRenderer = (params) => {
    const cellBlank = !params.value

    if (cellBlank) {
      return null
    }

    if (params.data?.port && typeof params.data.port === 'object') {
      return <div>{params.data?.port?.name}</div>
    } else if (params.data?.port && typeof params.data.port === 'string') {
      return <div>{params.data?.port}</div>
    }
  }

  // Vessel Cell Renderer
  const createVesselCellRenderer = (params) => {
    const cellBlank = !params.value

    if (cellBlank) {
      return <div>TBN</div>
    }

    if (params.data?.vessel && typeof params.data.vessel === 'object') {
      return <div>{params.data?.vessel?.name}</div>
    } else if (params.data?.vessel && typeof params.data.vessel === 'string') {
      return <div>{params.data?.vessel}</div>
    } else {
      return <div />
    }
  }

  // CargoOffer Date Cell Renderer
  const createDateCellRenderer = (params) => {
    const cellBlank = !params.value

    if (cellBlank) {
      return null
    }

    if (params.data?.offerDate) {
      return (
        <div>
          {getFormattedDate(params.data.offerDate, 'Europe/Copenhagen')}
        </div>
      )
    }
  }

  // The Update CargoWorking link
  const editLink = 'update-cargo-working'

  // CargoWorking Date Cell Renderer
  const LinkWithContextMenu = (props) => {
    const { vertical, value, rowData } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }
    const handleExpandLess = (e) => {
      setCargoWorkings({
        value: rowData,
        cargoId: props.data.cargoId,
        type: 'expand-less',
        visibleRows
      })
    }

    const handleExpandMore = (e) => {
      setCargoWorkings({
        value: rowData,
        cargoId: props.data.cargoId,
        type: 'expand-more',
        visibleRows
      })
    }
    if (!props.data.canEdit || vertical) {
      return (
        <div
          onContextMenu={handleClickCell}
          style={{ borderBottom: '0px' }}
          className={clsx({
            [classes.noExpandIcon]: props.data.offerNumber <= 3
          })}
        >
          {(props.data.expand && props.data.offerNumber > 3) && (
            <IconButton size='small' onClick={handleExpandLess}>
              <ExpandLessIcon />
            </IconButton>
          )}
          {(!props.data.expand && props.data.offerNumber > 3) && (
            <IconButton size='small' onClick={handleExpandMore}>
              <ExpandMoreIcon />
            </IconButton>
          )}
          {value}
        </div>
      )
    }
    return (
      <div
        onContextMenu={handleClickCell}
        className={clsx({
          [classes.noExpandIcon]: props.data.offerNumber <= 3
        })}
      >
        {(props.data.expand && props.data.offerNumber > 3 && !vertical) && (
          <IconButton size='small' onClick={handleExpandLess}>
            <ExpandLessIcon />
          </IconButton>
        )}
        {(!props.data.expand && props.data.offerNumber > 3 && !vertical) && (
          <IconButton size='small' onClick={handleExpandMore}>
            <ExpandMoreIcon />
          </IconButton>
        )}
        <RootColumnLink
          viewLink={`${editLink}/${props.data.id}`}
          value={value}
          row={props.data}
        />
      </div>
    )
  }

  // Calculation Page Link
  const calcPageLink = 'calculation-page'

  // The Route Cell Renderer
  const createRouteCellRenderer = (params) => {
    const cellBlank = !params.value

    const handleClickCell = (e) => {
      handleClick(e, params.data)
    }

    if (cellBlank) {
      return null
    }

    return (
      <div onContextMenu={handleClickCell}>
        <RootColumnLink
          viewLink={`${calcPageLink}/${params.data.routeID}`}
          value={params.value}
          row={params.data}
        />
      </div>
    )
  }

  // A parseFloat function that checks for NaNs
  function correctParseFloat (nonNumber) {
    if (isNaN(parseFloat(nonNumber))) {
      return 0
    } else if (parseFloat(nonNumber) < 0) {
      return 0
    } else {
      return parseFloat(nonNumber)
    }
  }

  const [input, setInput] = React.useState({})

  let updateCargoWorking = () => {}
  const gridOptions = {
    // columnDefs: columnDefs.map(c => { return { ...c, cellRenderer: c.cellRenderer ? c.cellRenderer : 'contextCell' } }),
    defaultColDef: {
      // filter: true, // set filtering on for all columns
      filter: 'agTextColumnFilter',
      resizable: true,
      floatingFilter: true,
      sortable: true
    },
    onCellEditingStopped: (event) => {
      if (
        (event.colDef?.field === 'port' ||
          event.colDef?.field === 'owner' ||
          event.colDef?.field === 'vessel') &&
        globalRef?.current
      ) { event.node.setDataValue(globalRef.current.type, globalRef.current.name) }
    },
    onCellEditingStarted: (event) => {
      event && event.data && event.data !== null && setCurrentNode(event?.node)
      setCurrentRowData({
        owner: event.data?.owner,
        vessel: event.data?.vessel,
        port: event.data?.port,
        offerId: event.data?.offerId,
        offerDate: event.data?.offerDate,
        comments: event.data?.comments,
        offer: event.data?.offer,
        deleted: event.data?.deleted
      })
    },

    singleClickEdit: false,
    onCellValueChanged: function (event) {
      const field = event.colDef?.field
      if (field === 'laycan' || field === 'bid' || field === 'status') {
        setInput({
          id: event.data.id,
          laycan: event.data.laycan,
          bid: correctParseFloat(event.data.bid),
          status: event.data.status
        })
        // setCargoWorkings({ type: 'manual-update', value: event.data })
        updateCargoWorking()
      } else if (
        field === 'offer' ||
        field === 'vessel' ||
        field === 'port' ||
        field === 'comments' ||
        'offerDate'
      ) {
        const { data } = event

        let {
          owner,
          vessel,
          port,
          offerId,
          offerDate,
          comments,
          offer,
          deleted
        } = event.data
        if (owner?.id) {
          owner = owner.id
        }
        if (vessel?.id) {
          vessel = vessel.id
        }
        if (port?.id) {
          port = port.id
        }

        const offerToUpdate = {
          id: offerId,
          offer: offer || '',
          port,
          vessel,
          owner,
          date: offerDate ? new Date(offerDate) : null,
          comments: comments || null,
          toDelete: deleted || false
        }

        if (offer && (lastOwnerRef !== sameOwner.owner && lastRowRef !== sameOwner.row)) {
          lastOwnerRef.current = sameOwner.owner
          lastRowRef.current = sameOwner.row
          updateCargoWorkingOffers({
            variables: {
              input: offerToUpdate
            }
          })
        }
        // setCargoWorkings({ type: 'manual-update', value: data })
      }
    }
    // getRowHeight: getRowHeight
  }
  function onGridReady (params) {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  // Menu items handle clicks
  // const handleGoToCalcClick = (props) => {
  //   setContextState({
  //     type: 'close'
  //   })
  //   history.push(`/calculation-page/${contextState.selectedRow.id}`)
  // }
  const handleSummary = () => {
    setShowSummary(true)
  }

  const handleCloseSummary = () => {
    setShowSummary(false)
    handleClose()
  }

  // const handleGoToOffer = (props) => {
  //   setUser({
  //     type: 'voyage-offer',
  //     value: true,
  //     voyageRoute: contextState.selectedRow.routeID,
  //     voyageRouteName: contextState.selectedRow.routeName,
  //     voyageIndex: contextState.selectedRow.monthId,
  //     voyageClient: contextState.selectedRow.clientId,
  //     adcomFromClienResults: null
  //   })
  //   setContextState({
  //     type: 'close'
  //   })
  // }
  let emptyStringCount = 0
  const emptyCells = () => {
    const result = 'emptyString' + emptyStringCount
    emptyStringCount = emptyStringCount + 1
    return result
  }
  console.log('Data', visibleRows);
  return (
    <MutationHelper
      mutation={UPSERT_CARGOWORKING_TABLE}
      variables={{ input }}
      onError={(err) => {
        console.log(err)
      }}
    >
      {(mutation, result) => {
        updateCargoWorking = () => {
          mutation()
        }
        return (
          <div>
            <div
              className='ag-theme-balham'
              style={{ height: 600, width: '100%' }}
            >
              <AgGridReact
                columnDefs={columnDefs}
                getRowNodeId={(data) => data.id || emptyCells()}
                gridOptions={gridOptions}
                onGridReady={onGridReady}
                animateRows
                rowData={visibleRows}
                rowBuffer={50}
                suppressRowTransform
                singleClickEdit
                frameworkComponents={{
                  contextCell: ContextMenuCell,
                  link: LinkWithContextMenu,
                  showCellRenderer: createShowCellRenderer,
                  ownerCellRenderer: createOwnerCellRenderer,
                  portCellRenderer: createPortCellRenderer,
                  vesselCellRenderer: createVesselCellRenderer,
                  dateCellRenderer: createDateCellRenderer,
                  autocompleteEditor: CargoOfferAutocomplete,
                  statusAutocompleteEditor: StatusAutocomplete,
                  datePickerEditor: CargoOfferDatePicker,
                  actionsRenderer: ActionsRenderer,
                  offerCellRenderer: offerCellRenderer,
                  routeCellRenderer: createRouteCellRenderer,
                  numberCellRenderer: createNumberCellRenderer
                }}
              >
                {
                  columnDefs.map((c, i) => {
                    return (
                      <AgGridColumn
                        {...c}
                        key={c.field}
                        field={c.field}
                        cellRenderer={c.cellRenderer ? c.cellRenderer : 'contextCell'}
                        cellEditor={c.cellEditor ? c.cellEditor : ''}
                        headerName={c.headerName}
                        editable={c.editable}
                        rowSpan={c.rowSpan}
                        cellClassRules={c.cellClassRules}
                        width={c.width}
                        height={c.height}
                        cellStyle={c.cellStyle}
                      />
                    )
                  })
                }
              </AgGridReact>
            </div>
            <Menu
              keepMounted
              open={contextState.mouseY !== null}
              onClose={handleClose}
              anchorReference='anchorPosition'
              anchorPosition={
                contextState.mouseY !== null && contextState.mouseX !== null
                  ? { top: contextState.mouseY, left: contextState.mouseX }
                  : undefined
              }
            >
              {/* <MenuItem onClick={handleGoToOffer}>Create Offer</MenuItem> */}
              <MenuItem onClick={handleSummary}>Summary</MenuItem>
            </Menu>
            <ShowSummary
              open={showSummary}
              handleClose={handleCloseSummary}
              routeId={
                contextState.selectedRow ? contextState.selectedRow.routeID : ''
              }
              user={contextState.selectedRow ? contextState.selectedRow.clientId : ''}
            />
            <Snackbar
              open={offerrMessage.open}
              onClose={() => setOfferMessage({ open: false, message: '' })}
              variant='error'
              message={offerrMessage.message}
            />
          </div>
        )
      }}
    </MutationHelper>
  )
}

ShowCargoWorkingAgGrid.propTypes = {
  history: PropTypes.object,
  rowData: PropTypes.array.isRequired
}

export default ShowCargoWorkingAgGrid
