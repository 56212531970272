// React
import React from 'react'
import PropTypes from 'prop-types'
// Material Ui
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
// Styles
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

//const localState = JSON.parse(localStorage.getItem('user'))
const useStyles = makeStyles(theme => ({
  rootButton: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: 32,
    marginTop: 32,
    padding: '4px 24px',
    fontSize: theme.typography.generalFontSize
  },
  noMargin: {
    margin: 0,
  }
}))

const RootButton = props => {
  const { 
    alignItems,
    boxHeight,
    boxWidth,
    children,
    className,
    disabled,
    justifyContent,
    noMargin,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <Box
      style={
        (boxHeight || boxWidth)
        ? {
            width: boxWidth,
            height: boxHeight
          }
        : {}
      }
      display='flex' 
      alignItems={alignItems} 
      justifyContent={justifyContent}
    >
      <Button
        className={clsx(classes.rootButton, {
          [classes.noMargin]: noMargin,
          [className]: className
        })}
        disabled={disabled}
        variant='contained'
        color='primary'
        {...rest}
      >
        {children}
      </Button>
    </Box>
  )
}

RootButton.defaultProps = {
  alignItems: 'flex-start',
  disabled: false,
  justifyContent: 'flex-start',
  noMargin: false,
}

RootButton.propTypes = {
  alignItems: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  justifyContent: PropTypes.string,
  noMargin: PropTypes.bool,
}

export default RootButton