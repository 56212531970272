// React Library
import React, { useState, useEffect } from 'react'

// React Router DOM
import { useHistory } from 'react-router-dom'

// Material UI
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import { Snackbar } from '@material-ui/core'
import { SubdirectoryArrowRightOutlined } from '@material-ui/icons'
import IconButton from '@material-ui/core/IconButton'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'


// Utils
import { fireSwalError } from '../Utils/utils'

// Mutation Related
import { DELETE_CARGO_OFFER } from '../Mutations/Mutations'
import { ACCEPT_CARGO_AND_OFFER, CREATE_CARGO_WORKING_WITHOUT_OFFER } from '../Mutations/Mutations'

// Query Related
import { GET_CARGOWORKINGS } from '../Queries/Queries'

// CLSX
import clsx from 'clsx'

// Apollo
import { useMutation, useQuery } from 'react-apollo'

const useStyles = makeStyles(theme => ({
  accept: {
    color: theme.palette.common.blue,
    fontSize: '12px',
    textTransform: 'inherit'
  },
  add: {
    color: theme.palette.common.blue,
    fontSize: '18px',
    textTransform: 'inherit'
  },
  delete: {
    color: theme.palette.common.red,
    fontSize: '18px',
    textTransform: 'inherit'
  },
  buttonDistribution: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-evenly'
  }
}))

const ActionsRenderer = (props) => {
  const history = useHistory()
  const [offerrMessage, setOfferMessage] = useState({ open: false, message: '' })

  // const refetchQueries = [{
  //   query: GET_CARGOWORKINGS
  // }]

  const [deleteCargoOffer] = useMutation(DELETE_CARGO_OFFER, {
    // refetchQueries,
    // fetchPolicy: 'no-cache',
    onCompleted (data) {
      console.log(data)
      
      props.setCargoWorkings({
        type: 'delete',
        value: {
          offer: props.data.offerId,
          cargo: props.data.cargoId
        },
        visibleRows: props.visibleRows
      })

      if (props.data.offerNumber && props.data.offerNumber === 1) {
        props.setCargoWorkings({ type: 'add', when: 'add-after-delete',  mainRow: props.data.cargoId, row: props.data.offerId, data, visibleRows: props.visibleRows })
      }

    },
    onError(error) {
      setOfferMessage({
        open: true,
        message: error?.message?.split(':')[1]
      })
    }
  })

  const classes = useStyles()
  const deleteLoading = false
  // TODO DELETE OFFER
  // const [deleteOffer, { loading: deleteLoading }] = useMutation(DELETE_OFFER, {
  //   refetchQueries,
  //   onError(error) {
  //     fireSwalError({
  //       title: 'Error',
  //       text: error ? error.message.split(':')[1] : 'There was an unexpected error while deleting the offer'
  //     })
  //   },
  // })

  // TODO ACCEPT OFFER
  const [acceptCargoAndOffer, { loading: acceptLoading }] = useMutation(ACCEPT_CARGO_AND_OFFER, {
    // refetchQueries,
    onCompleted(data) {
      // TODO Reload here page
      history.go(0)
      // props.setCargo({ type: 'acceptedOffer', mainRow: props.data.cargoId, row: props.data.offerId, data })
      
    },
    onError(error) {
      fireSwalError({
        title: 'Error',
        text: error ? error.message.split(':')[1] : 'There was an unexpected error while accepting the offer'
      })
    }
  })
  // TODO ACCEPT OFFER
  const [addOffer, { loading: addLoading }] = useMutation(CREATE_CARGO_WORKING_WITHOUT_OFFER, {
    // refetchQueries,
    onCompleted(data) {
      console.log('props: ', props.data)
      console.log('payload', data)
      // props.setCargo({ type: 'addRowBelow', mainRow: props.data.cargoId, row: props.data.offerId, data })
      props.setCargoWorkings({ type: 'add', when: 'add-offer', mainRow: props.data.cargoId, row: props.data.offerId, data, visibleRows: props.visibleRows })

      console.log('cargoWorkings: ', { cargoWorkings: props.cargoWorkings })
    },
    onError(error) {
      fireSwalError({
        title: 'Error',
        text: error ? error.message.split(':')[1] : 'There was an unexpected error while adding the offer'
      })
    }
  })

  const onClickAcceptMutation = () => {
    acceptCargoAndOffer({
      variables: {
        input: {
          cargo: props.data.cargoId,
          offer: props.data.offerId
        }
      }
    })
  }

  const onClickAddOffer = () => {
    addOffer({
      variables: {
        input: {
          cargo: props.data.cargoId
        }
      }
    })
  }

  const onClickDeleteMutation = () => {
    deleteCargoOffer({
      variables: {
        input: {
          id: props.data.offerId
        }
      }
    })
    
  }

  if (props.vertical) {
    return <></>
  }


  // const disableAccept = !props.data.offer || props.data.offer === ''

  return (
    <div className={classes.buttonDistribution}>
      
      {
        props.isAddButton && (
          <IconButton
            onClick={onClickAddOffer}
            className={classes.add}
            disabled={addLoading}
            size='small'
          >
          <AddIcon fontSize='inherit'/>
          </IconButton>
        )
      }

    

      {
        (props.data.offerId && !props.isAddButton) && (
          <>
              <Button
                color='primary'
                size='small'
                onClick={onClickAcceptMutation}
                className={clsx([classes.accept])}
                disabled={acceptLoading}
              > Accept </Button>

            <IconButton
              onClick={onClickDeleteMutation}
              disabled={deleteLoading}
              className={classes.delete}
              size='small'
              >
              <RemoveIcon fontSize='inherit'/>
            </IconButton>
      

          </>
        )
      }



      <Snackbar open={offerrMessage.open} onClose={() => setOfferMessage({ open: false, message: '' })} variant='error' message={offerrMessage.message} />
    </div>
  )
}

export default ActionsRenderer
