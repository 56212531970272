const breadCrumbs = [
  {
    link: '/time-countings',
    name: 'Time countings'
  }
]

export const show = {
  createButton: {
    name: 'Create time counting',
    link: '/create-time-counting'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'timeCountingName', name: 'timeCoutingName' },
      { key: 'abbrev', name: 'abbrev' },
      { key: 'multiplier', name: 'multipier' }
    ],
    rows: 3
  },
  module: {
    name: 'Time counting'
  },
  update: {
    link: '/update-time-counting'
  }
}

export const upsert = {
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Time counting created',
      path: '/time-countings'
    },
    submitButton: 'Create Time Counting'
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Time counting updated',
      path: '/calculation-page'
    },
    submitButton: 'Update Time Counting'
  }
}
