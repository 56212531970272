// React Library
import React, { useState, useRef, useEffect } from 'react'

// Helmet
import Helmet from 'react-helmet'

// React Router DOM
import { withRouter, useHistory } from 'react-router-dom'

// Content
import { show as content } from './ForwardCurvesContent'

// Query Related
import QueryHelper from '../Queries/QueryHelper'
import { GET_FORWARDCURVES } from '../Queries/Queries'

// Mutation Related
import { DELETE_FORWARDCURVE } from '../Mutations/Mutations'

// Material UI Components
import AddIconButton from '../Utils/AddIconButton'
import RootGrid from '../Utils/RootGrid'
import RootAgGrid from '../Utils/RootAgGrid'
import SearchTextInput from '../Utils/SearchTextInput'

// Trie-Search Algorithm
import TrieSearch from 'trie-search'

// Query Component
const ShowForwardCurves = props => {
  return (
    <>
      <Helmet>
        <title>Forward Curve</title>
      </Helmet>
      <QueryHelper query={GET_FORWARDCURVES}>
        {({ data }) => {
          const { getForwardCurves: { forwardCurves } } = data

          // Some Formatting...
          const forwardCurvesArray = forwardCurves.map(forwardCurve => {
            return {
              ...forwardCurve,
              spotIndex: forwardCurve.spotIndex.indexName,
              forwardCommodity: forwardCurve.forwardCommodity && forwardCurve.forwardCommodity.commodityName,
              basedOnForwardCurve: forwardCurve.basedOnForwardCurve && forwardCurve.basedOnForwardCurve.forwardCurveName,
              coreRoute: forwardCurve.coreRoute ? forwardCurve.coreRoute.codeNameLong ? forwardCurve.coreRoute.codeNameLong : forwardCurve.coreRoute.routeName : '-'
            }
          })

          // Render
          return (
            <ShowForwardCurvesTable
              curves={forwardCurvesArray}
            />
          )
        }}
      </QueryHelper>
    </>
  )
}

const ShowForwardCurvesTable = props => {
  const {
    curves = []
  } = props

  // History For Links
  const history = useHistory()

  // Hooks For Search Bar Functionality
  const [search, setSearch] = useState('')
  const [searchCurve, setSearchCurve] = useState(curves)

  // Trie-Search To Look For The Following Fields...
  const ts = new TrieSearch(['forwardCurveName'], {
    idFieldOrFunction: 'id'
  })
  // The Array To Be Filtered (All Forward Curves In the DB)
  ts.addAll(curves)

  // Trie-Search Logic
  const searchRef = useRef(search)
  useEffect(() => {
    if (searchRef.current !== search) {
      searchRef.current = search
      if (!search) {
        setSearchCurve(curves)
      } else {
        const foundCurves = ts.get(search, TrieSearch.UNION_REDUCER)
        setSearchCurve(foundCurves)
      }
    }
  }, [search, curves, ts])

  // Column Structure
  const columnDefs = [
    { field: 'forwardCurveName', headerName: 'Forward Curve Name', cellRenderer: 'link' },
    { field: 'forwardCommodity', headerName: 'Forward Commodity' },
    { field: 'basedOnForwardCurve', hedarName: 'Based On Forward Curve' },
    { field: 'spotIndex', headerName: 'Spot Index' },
    { field: 'coreRoute', headerName: 'Core Route' }
  ]

  // Link Of The Update Forward Curve Page
  const updateLink = 'update-forward-curve'

  // We Render Search Bar + Create Button + Grid
  return (
    <RootGrid>

      {/* Search Bar + Create Button */}
      <SearchTextInput
        autoFocus
        label='Search'
        style={{ maxWidth: 600, margin: '0 auto' }}
        onChange={e => {
          setSearch(e.target.value)
        }}
        value={search}
      >
        <AddIconButton
          onClick={() => history.push(content.createButton.link)}
          color='primary'
        >
          {content.createButton.name}
        </AddIconButton>
      </SearchTextInput>

      {/* Forward Curves Grid (Made By Us) */}
      <RootAgGrid
        columnDefs={columnDefs}
        filteredArray={searchCurve}
        mutation={DELETE_FORWARDCURVE}
        objectNameKey1='forwardCurveName'
        type='Forward Curve'
        updateLink={updateLink}
      />

    </RootGrid>
  )
}

export default withRouter(ShowForwardCurves)
