// React 
import React, { useState, useReducer } from 'react'
import PropTypes from 'prop-types'
// Material UI
import MuiAlert from '@material-ui/lab/Alert'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Snackbar from '@material-ui/core/Snackbar'

// Utils
import RootAutocomplete from '../Utils/RootAutocomplete'

// Apollo
import { useQuery, useMutation } from 'react-apollo'

// Queries
import {
  GET_FOB_FUTURE_MAPPING
} from '../Queries/Queries'

// Mutations
import {
  UPDATE_FOB_FUTURE_MAPPING
} from '../Mutations/Mutations'

// Custom Alert
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FobFutureMapping = props => {
  const { apiFuture, mapping, mappingId, setFobStruct, open, setOpen, setContextState, client, commodity, route } = props

  const [noFutures, setNoFutures] = useState(false)

  const [runningMutation, setRunningMutation] = useState(false)

  const [updateFobFutureMapping] = useMutation(UPDATE_FOB_FUTURE_MAPPING, {
    onCompleted(data) {
      setRunningMutation(false)
    }
  })

  const handleClose = () => {
    setOpen(false)
    setContextState({ type: 'close' })
  }

  const handleSelect = (event, key) => {
    if (apiFuture) {
      setFobStruct({
        type: 'select-mapping-value',
        mappingId,
        key,
        value: event.value.name
      })
    } else {
      setNoFutures(true)
    }
  }

  const handleBlur = () => {
    if (apiFuture && !runningMutation) {
      setRunningMutation(true)
      updateFobFutureMapping({
        variables: {
          input: {
            id: mapping.id,
            monthValues: Object.values(mapping.monthsMapping)
          }
        }
      })
    } else {
      setNoFutures(true)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '1.5%',
          }
        }}
      >
        <DialogTitle>
          <Typography align='center'>
            <b>
              {
                route
                  ? `Mapping for route ${route.routeName}`
                  : `Default mapping for ${commodity.commodityName}`
              }
            </b>
            </Typography>
        </DialogTitle>
        <DialogContent
          style={{
            margin: '0px 27px 27px 27px'
          }}
        >
          {mapping.monthKeys && mapping.monthKeys.map((key, i) => {
            const formattedKey = [key[0], key[1], key[2]].join('')
            return (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                alignItems='center'
              >
                <Typography key={i} style={{ marginRight: '10px' }}>{formattedKey}</Typography>
                <RootAutocomplete
                  style={{
                    width: '150px'
                  }}
                  key={i}
                  options={mapping.monthValues.map(item => { return { name: item } } )}
                  value={{ name: mapping.monthsMapping[key] }}
                  onChange={(e) => handleSelect(e, key)}
                  onBlur={handleBlur}
                  smallInput={false}
                  disableClearable
                />
              </Box>
            )
          })}
        </DialogContent>
      </Dialog>
      <Snackbar open={noFutures} onClose={() => setNoFutures(false)} autoHideDuration={5000}>
        <Alert severity='error'>There are no futures for this Commodity in the database yet!</Alert>
      </Snackbar>
    </>
  )
}

FobFutureMapping.propTypes = {

}

export default FobFutureMapping
