import React, { useState, useReducer, useEffect, useContext, useRef, createRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter, useHistory } from 'react-router-dom'

// Helmet
import Helmet from 'react-helmet'

import { makeStyles, withStyles } from '@material-ui/core/styles'

// Clsx
import clsx from 'clsx'
// Context
import { UserContext } from '../App/AppContext'
// Queries
import { GET_MY_INFORMATION, GET_VALIDATION, GET_VALIDATION_INFORMATION, GET_VALIDATION_UPDATED_RESULTS } from '../Queries/Queries'

import gql from 'graphql-tag'
import debounce from 'lodash/debounce'

// Shortcut
// import { useShortcut } from '@shopify/react-shortcuts'

// Apollo
import {
  Query,
  useQuery,
  useMutation,
  Mutation
} from 'react-apollo'

// Mutations
import MutationHelper from '../Mutations/MutationHelper'

import {
  RESET_CURVES,
  FORWARD_VALIDATE_RESULT,
  UPSERT_FORWARDCURVE,
  DELETE_VOYAGE_OFFER,
  LOCK_FORWARD_CURVE_INSTANCE,
  LOCK_ALL_FORWARD_CURVE_INSTANCES,
  LOCK_ALL_FORWARD_CURVES_FROM_SECTOR,
  SAVE_RESULT,
  SPOTINDEX_APS
} from '../Mutations/Mutations'

// Material UI
import AppBar from '@material-ui/core/AppBar'
import Popper from '@material-ui/core/Popper'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import Link from '@material-ui/core/Link'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Tooltip from '@material-ui/core/Tooltip'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import LockIcon from '@material-ui/icons/Lock'

// Utils
import EditableInputBase from '../Utils/EditableInputBase'
import VoyageOfferDialog from '../VoyageOffer/VoyageOfferDialog'
// import RootGrid from '../Utils/RootGrid'
// import RootPaper from '../Utils/RootPaper'
import RootTextField from '../Utils/RootTextField'
import RootButton from '../Utils/RootButton'
import { formatNumber, formatDate, fireSwalError } from '../Utils/utils'
import { format, utcToZonedTime } from 'date-fns-tz'
import OfferInformation from './OfferInformation'

// import { v4 as uuidv4 } from 'uuid';
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

var _ = require('lodash')

const LIVE_VALIDATION = gql`
  subscription LiveValidationSuscription($input: LiveValidationInput!) {
    liveValidation(input: $input) {
      id
      validation
    }
  }
`
// PopUp To Be Rendered On Hover
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip)

// Initial State of mouse...?
const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

const sortByDate = (a, b) => {
  const userA = (new Date(a.createdAt)).getTime()
  const userB = (new Date(b.createdAt)).getTime()

  if (userA > userB) {
    return -1
  } else if (userA < userB) {
    return 1
  }
}

// Context Reducer
const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

const curvesReducer = (state, action) => {
  let newForwardCurve = {}
  switch (action.type) {
    case 'abs':
      newForwardCurve = state[action.curveIndex]
      if (action.value === '-') {
        newForwardCurve.curveAdjustmentAbsolute[action.posIndex] = '-0.000000000000000000001'
      } else {
        newForwardCurve.curveAdjustmentAbsolute[action.posIndex] = parseFloat(action.value)
      }

      if (action.posIndex === 0 && action.child) {
        const percent = newForwardCurve.curveAdjustmentPercent[0]
        const absolute = newForwardCurve.curveAdjustmentAbsolute[0]
        const base = newForwardCurve.base[0]

        newForwardCurve.result = absolute + base * (1 + percent / 100)
      }

      return [
        ...state.slice(0, action.curveIndex),
        {
          ...newForwardCurve
        },
        ...state.slice(action.curveIndex + 1)
      ]

    case 'per':
      newForwardCurve = state[action.curveIndex]

      if (action.value === '-') {
        newForwardCurve.curveAdjustmentPercent[action.posIndex] = '-0.000000000000000000001'
      } else {
        newForwardCurve.curveAdjustmentPercent[action.posIndex] = parseFloat(action.value)
      }

      if (action.posIndex === 0 && action.child) {
        const percent = newForwardCurve.curveAdjustmentPercent[0]
        const absolute = newForwardCurve.curveAdjustmentAbsolute[0]
        const base = newForwardCurve.base[0]

        newForwardCurve.result = absolute + base * (1 + percent / 100)
      }

      return [
        ...state.slice(0, action.curveIndex),
        {
          ...newForwardCurve
        },
        ...state.slice(action.curveIndex + 1)
      ]

    case 'base': {
      newForwardCurve = state[action.curveIndex]

      const isLocked = newForwardCurve.locked[action.posIndex]

      if (!isLocked) {
        newForwardCurve.base[action.posIndex] = parseFloat(action.value)
      }

      return [
        ...state.slice(0, action.curveIndex),
        {
          ...newForwardCurve
        },
        ...state.slice(action.curveIndex + 1)
      ]
    }

    case 'latestChange': {
      newForwardCurve = state[action.curveIndex]

      const isLocked = newForwardCurve.locked[action.posIndex]

      if (!isLocked) {
        newForwardCurve.latestChange = new Date(Date.now())
      }

      return [
        ...state.slice(0, action.curveIndex),
        {
          ...newForwardCurve
        },
        ...state.slice(action.curveIndex + 1)
      ]
    }

    case 'inherit': {
      const root = action.root
      const positionDates = action.positionDates
      const pos = action.pos

      const curvesHandler = [...state]

      // Function to get the children of a curve
      const getChildren = params => {
        const {
          curveId
        } = params

        const children = []
        for (let j = 0; j < curvesHandler.length; j++) {
          if (curvesHandler[j].basedOnForwardCurve && curvesHandler[j].basedOnForwardCurve.id === curveId) {
            children.push(curvesHandler[j].id)
          }
        }
        return children
      }

      const updateCurve = params => {
        const {
          curveId,
          parentId
        } = params

        let tceValue
        for (let i = 0; i < curvesHandler.length; i++) {
          if (curvesHandler[i].id === parentId) {
            let matchingName
            let resultMonth
            if (positionDates[pos].positionDateName.charAt(1) === 'h') {
              matchingName = positionDates[pos].positionDateName.split('h')
              resultMonth = months.indexOf(matchingName[1])
            } else {
              matchingName = positionDates[pos].positionDateName
              resultMonth = months.indexOf(matchingName)
            }

            let spreads = 0
            for (let i = 0; i < curvesHandler[i].coreRoute.spotTCSpread.length; i++) {
              spreads += curvesHandler[i].coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] ? curvesHandler[i].coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] : 0
            }

            tceValue = pos === 0 ? parseFloat(curvesHandler[i].result) : ((curvesHandler[i].base[pos] * (1 + curvesHandler[i].curveAdjustmentPercent[pos] / 100)) + curvesHandler[i].curveAdjustmentAbsolute[pos]) * (1 + spreads / 100)
          }
        }

        for (let j = 0; j < curvesHandler.length; j++) {
          if (curvesHandler[j].id === curveId) {
            const isLocked = curvesHandler[j].locked[pos]
            if (!isLocked) {
              curvesHandler[j].latestChange = new Date(Date.now())
              curvesHandler[j].base[pos] = tceValue
            }
          }
        }
      }

      const inherit = params => {
        const {
          curveId,
          parentId
        } = params

        // Update parent
        if (curveId !== root && parentId) {
          updateCurve({ curveId, parentId })
        }

        // Get children
        const childrenIds = getChildren({ curveId })

        // Update children using recursion
        for (const childId of childrenIds) {
          inherit({ curveId: childId, parentId: curveId })
        }
      }

      inherit({ curveId: root })

      return [...curvesHandler]
    }


    case 'result': {
      const newState = [...state]
      newState[action.index].result = action.result
      newState[action.index].latestChange = new Date(Date.now())

      return [
        ...newState
      ]
    }

    case 'inherit-result': {
      const newState = [...state]
      const result = newState[action.index].result

      // Recalc aboslute
      const base = newState[action.index].base[0]
      const per = newState[action.index].curveAdjustmentPercent[0]
      newState[action.index].curveAdjustmentAbsolute[0] = parseFloat(result) - base * (1 + per / 100)

      // Inherit base to children
      const curveId = newState[action.index].id
      for (let i = 0; i < newState.length; i++) {
        const curve = newState[i]
        if (curve.basedOnForwardCurve && curve.basedOnForwardCurve.id === curveId) {
          newState[i].base[0] = parseFloat(result)
          const childPer = newState[i].curveAdjustmentPercent[0]
          const childAbs = newState[i].curveAdjustmentAbsolute[0]

          let spreads = 0
          for (let j = 0; i < newState[action.index].coreRoute.spotTCSpread.length; j++) {
            spreads += newState[action.index].coreRoute.spotTCSpread[j].spreadTCValue[0] ? newState[action.index].coreRoute.spotTCSpread[j].spreadTCValue[0] : 0
          }

          newState[i].result = (parseFloat(result) * (1 + childPer / 100) + childAbs) * (1 + spreads / 100)
        }
      }

      return [
        ...newState
      ]
    }

    case 'apsDh': {
      const newState = [...state]

      newState[action.index].apsDh = action.value

      return [
        ...newState
      ]
    }

    case 'apsBB': {
      const newState = [...state]

      newState[action.index].apsBB = action.value

      return [
        ...newState
      ]
    }

    case 'apsBB-unFocus': {
      const newState = [...state]
      const index = action.index
      const shipSectorIndex = action.shipSectorIndex

      action.mutation({
        variables: {
          input: {
            data: {
              aps: newState[index].apsDh,
              apsBunkerCost: newState[index].apsBB * 1000,
              route: newState[index].coreRoute.id,
              shipSectorIndex,
              forwardCurveIndex: index
            }
          }
        }
      })

      return [
        ...newState
      ]
    }

    case 'apsResult': {
      const newState = [...state]

      newState[action.index].apsResult = action.value

      return [
        ...newState
      ]
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
    // backgroundColor: theme.palette.background.paper
  },
  appBar: {
    overflowX: 'scroll',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden'
    }
  },
  adcom: {
    width: '50px',
    margin: '3%',
    // marginLeft: '100px',
    backgroundColor: 'white'
  },
  adcomBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    paddingRight: '0%',
    position: 'static'
  },
  adcomBoxArrange: {
    width: '210px',
    objectPosition: 'static',
    marginRight: '10px',
    marginLeft: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'space-between'
  },
  commoditiesBundle: {
    display: 'flex',
    objectPosition: 'static',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'space-between'
  },
  commodities: {
    fontsize: theme.typography.generalFontSize - 2
  },
  commoditiesDate: {
    fontSize: '10px',
    display: 'flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    }
  },
  updatedAt: {
    fontsize: '10px'
  }
}))

const getTimeSinceRoundedToHalves = (timeSince) => {
  const hours = timeSince / (1000 * 60 * 60)

  const integerPart = Math.floor(hours)

  const decimalPart = hours - integerPart

  const halvesElapsed = decimalPart > 0.5 ? integerPart + 0.5 : integerPart

  return halvesElapsed
}

const getFormattedDate = (updated, timeZone, i) => {
  let date, zonedTime
  if (i >= 0) {
    zonedTime = (updated[i] && updated[i]) ? utcToZonedTime(updated[i], timeZone) : ''
    date = (updated[i] && updated[i]) ? new Date(updated[i]) : ''
  } else {
    zonedTime = (updated && updated) ? utcToZonedTime(updated, timeZone) : ''
    date = (updated && updated) ? new Date(updated) : ''
  }

  const year = date.toLocaleString('default', { year: 'numeric', timeZone })
  const month = date.toLocaleString('default', { month: 'short', timeZone })
  const day = date.toLocaleString('default', { day: 'numeric', timeZone })

  const timeString = `${day}${month}${year} ${format(zonedTime, 'HH:mm:ss', { timeZone })}`

  return timeString // returns a string
}

const ForwardValidationWrapper = props => {
  const { ...rest } = props

  const [firstRender, setFirstRender] = useState(true)
  const [queryData, setQueryData] = useState()
  const [shipSectors, setShipSectors] = useState([])
  const { data, error, loading } = useQuery(GET_MY_INFORMATION, { fetchPolicy: 'network-only' })
  const [indexes, setIndexes] = useState({
    shipSectorIndex: '',
    forwardCurveIndex: '',
    positionDateIndex: ''
  })
  const firstRenderSkip = !!firstRender
  const skip = !!queryData
  return (
    <>
      <Helmet>
        <title>Validation</title>
      </Helmet>
      <ForwardValidationQueryHelper
        firstRenderSkip={firstRenderSkip}
        queryData={queryData}
        setFirstRender={setFirstRender}
        setQueryData={setQueryData}
        skip={skip}
        shipSectors={shipSectors}
        setShipSectors={setShipSectors}
        indexes={indexes}
        setIndexes={setIndexes}
        userId={data ? data.getMyInformation.user.id : '-1'}
        {...rest}
      />
    </>
  )
}
export default withRouter(ForwardValidationWrapper)

const ForwardValidationQueryHelper = props => {
  const { queryData, indexes, setIndexes, setQueryData, shipSectors, setShipSectors, skip, userId, ...rest } = props
  const classes = useStyles()
  const { user, setUser } = useContext(UserContext)
  const [focusName, setFocusName] = useState('')

  let initialValues = {
    tab: 0
  }
  if (user.forwardValidation) {
    initialValues = user.forwardValidation
  }

  const [value, setValue] = React.useState(initialValues.tab || 0)

  const _newValidation = subscribeToMore => {
    subscribeToMore({
      document: LIVE_VALIDATION,
      variables: { input: { id: userId } },
      updateQuery: (previousData, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousData
        }

        // const newUpdatingRoute = subscriptionData.data.routeUpdated.routeUpdated
        return Object.assign({}, previousData, {
          getValidation: {
            validation: subscriptionData.data.liveValidation
          }
        })
      }
    })
  }
  const history = useHistory()

  const handleChange = (_event, newValue) => {
    setUser({
      type: 'add-forwardValidation-info',
      value: {
        ...initialValues,
        tab: newValue
      }
    })
    setValue(newValue)
    history.go(0)
  }

  const a11yProps = (index) => {
    return {
      id: `ship-sector-tab-${index}`,
      'aria-controls': `ship-sector-panel-${index}`
    }
  }

  const getCurrentIndex = (sectors, current) => {
    let skips = 0
    for (let i = 0; i < sectors.length; i++) {
      if (sectors[i].forwardCurves.length > 0) {
        if (sectors[i].id === current.id) {
          return i - skips
        }
      } else {
        skips++
      }
    }
  }

  const getSectorNames = (sectors, current) => {
    const nameArray = []
    for (let i = 0; i < sectors.length; i++) {
      if (sectors[i].forwardCurves.length > 0) {
        nameArray.push(sectors[i].name)
      }
    }
    return nameArray
  }

  const TabPanel = (props) => {
    const { actualIndex, value, index, positionDates, indexes, voyageOffers, forwardCurves, queryData, setFirstRender, firstRenderSkip, systemSetting, fuelPrice, ...rest } = props

    // const [gottenCurves, setGottenCurves] = useState([])
    return (
      <Grid>
        {value === index &&
          <Query
            fetchPolicy='network-only'
            query={GET_VALIDATION_UPDATED_RESULTS}
            variables={queryData ? { input: { index: actualIndex, validation: { ...queryData, forwardCurves } } } : {}}
            skip={!firstRenderSkip}
          >
            {({ data }) => {
              // const curves = forwardCurves

              const curves = []
              for (const curve of forwardCurves) {
                curves.push({
                  ...curve,
                  apsBB: curve.apsBB ? curve.apsBB / 1000 : 0,
                  apsDh: curve.apsDh ? curve.apsDh : 0,
                  apsResult: curve.apsResult ? curve.apsResult : 0
                })
              }

              if (data) {
                // curves = data.getUpdatedResults.forwardCurves
                setFirstRender(false)
              }
              return (
                <ForwardValidationSpotIndexes
                  forwardCurves={curves}
                  positionDates={positionDates}
                  focusName={focusName}
                  setFocusName={setFocusName}
                  voyageOffers={voyageOffers}
                  shipSectorIndex={actualIndex}
                  indexes={indexes}
                  shipSectors={shipSectors}
                  // selectedInput={selectedInput}
                  // handleSelectedInput={handleSelectedInput}
                  queryData={queryData}
                  systemSetting={systemSetting}
                  fuelPrice={fuelPrice}
                  {...rest}
                />
              )
            }}
          </Query>}
      </Grid>
    )
  }
  const initialAdcom = user && user.adcom ? user.adcom : 0
  // const [adcomValue, setAdcomValue] = useState(isNaN(parseFloat((initialAdcom).replace(',', '.'))) ? 0 : parseFloat((initialAdcom).replace(',', '.')))
  const [adcomValue, setAdcomValue] = useState(initialAdcom)

  const setAdcomValueAndUser = (e) => {
    // setAdcomValue(e.target.value)
    setUser({
      type: 'adcom',
      value: e.target.value
    })
  }
  const setAdcomDebounce = debounce(setAdcomValueAndUser, 3000)

  return (
    <Query
      fetchPolicy='network-only'
      query={GET_VALIDATION}
    >
      {({ error, data, subscribeToMore }) => {
        if (error) {
          return <div />
        }
        if (data) {
          _newValidation(subscribeToMore)
          const { getValidation: { validation, quarterArray } } = data

          if (validation) {
            setQueryData(validation)
            return (
              <div className={classes.root}>
                <Query
                  fetchPolicy='network-only'
                  query={GET_VALIDATION_INFORMATION}
                  variables={queryData ? { input: { validation: { ...queryData } } } : {}}
                  skip={!skip}
                >
                  {({ data }) => {
                    let commodities = []
                    let singapore = ''
                    let singaporeUpdateDate = ''
                    let singaporeForwardCurve = ''
                    let singaporeForwardCurveUpdated = ''
                    let baltic38kPos = -1
                    let baltic58kPos = -1
                    let baltic82kPos = -1
                    let singaporePos = -1
                    let timeSinceBaltic38k = ''
                    let timeSinceBaltic58k = ''
                    let timeSinceBaltic82k = ''
                    const timeStrings = []
                    let systemSetting = {}
                    let fuelPrice = 0

                    const rightNow = new Date(Date.now())

                    if (data) {
                      systemSetting = data.getValidationInformation.systemSetting

                      setShipSectors(data.getValidationInformation.validation.validation.shipSectors)
                      if (data.getValidationInformation.validation.validation.indexes) {
                        setIndexes(data.getValidationInformation.validation.validation.indexes)
                      }

                      if (data.getValidationInformation.validation.validation.port) {
                        singapore = data.getValidationInformation.validation.validation.port.portName
                        singaporeUpdateDate = data.getValidationInformation.validation.validation.port.updatedAt
                        singaporeUpdateDate = getFormattedDate(singaporeUpdateDate, 'Europe/Copenhagen')
                        fuelPrice = data.getValidationInformation.port.FO05
                      }

                      commodities = data.getValidationInformation.validation.validation.commodities
                      if (commodities && commodities.length > 0) {
                        baltic38kPos = commodities.map(item => item.commodityName).indexOf('Baltic 38k Forward Curve')
                        baltic58kPos = commodities.map(item => item.commodityName).indexOf('Baltic 58k Forward Curve')
                        baltic82kPos = commodities.map(item => item.commodityName).indexOf('Baltic 82k 5tc Forward Curve')

                        singaporePos = commodities.map(item => item.commodityName).indexOf('Singapore 0.5 Forward Curve')
                        singaporeForwardCurve = singaporePos >= 0 ? commodities[singaporePos].commodityName : ''

                        singaporeForwardCurveUpdated = singaporePos >= 0 ? commodities[singaporePos].updatedAt : ''
                        singaporeForwardCurveUpdated = singaporePos >= 0 ? getFormattedDate(singaporeForwardCurveUpdated, 'Europe/Copenhagen') : ''

                        const updated = commodities.map((item) => item.updatedAt)

                        timeSinceBaltic38k = getTimeSinceRoundedToHalves(rightNow.getTime() - new Date(updated[baltic38kPos]).getTime())
                        timeSinceBaltic58k = getTimeSinceRoundedToHalves(rightNow.getTime() - new Date(updated[baltic58kPos]).getTime())
                        timeSinceBaltic82k = getTimeSinceRoundedToHalves(rightNow.getTime() - new Date(updated[baltic82kPos]).getTime())

                        for (let i = 0; i < updated.length; i++) {
                          const singleTimeString = getFormattedDate(updated, 'Europe/Copenhagen', i)

                          timeStrings.push(singleTimeString)
                        }
                      }
                    }                    

                    return (
                      <Box className={classes.root}>
                        <AppBar position='static' className={classes.appBar}>
                          <Grid container direction='row' justifyContent='flex-start' alignItems='center' wrap='nowrap' position='static'>
                            <Box item wrap='nowrap' position='static'>
                              <Tabs value={value} onChange={handleChange} aria-label='ship sector tabs' position='static'>
                                {shipSectors.map((item, i) => {
                                  const index = getCurrentIndex(shipSectors, item)
                                  if (index >= 0) {
                                    return (
                                      <Tab position='static' wrap='nowrap' key={i} label={item.name} {...a11yProps(i)} />
                                    )
                                  }
                                })}
                              </Tabs>
                            </Box>
                            <Box className={classes.adcomBox} xs={10} md={2} container item wrap='nowrap'>

                              <Grid className={classes.adcomBoxArrange} xs={12} container item wrap='nowrap'>

                                <Grid item className={classes.commoditiesBundle}>
                                  <Typography className={classes.commodities} variant='caption'>
                                    {singapore && singapore ? `Sin LSFO Spot` : ''}
                                  </Typography>
                                  <Typography className={classes.commoditiesDate} variant='caption' textAlign='right'>
                                    {singapore && singaporeUpdateDate ? ` ${singaporeUpdateDate} ` : ''}
                                  </Typography>
                                </Grid>

                                <Grid item className={classes.commoditiesBundle}>

                                  <Typography className={classes.commodities} variant='caption'>
                                    {singaporeForwardCurve && singaporeForwardCurveUpdated ? 'Sin LSFO Futures' : ''}
                                  </Typography>
                                  <Typography className={classes.commoditiesDate} variant='caption'>
                                    {singaporeForwardCurve && singaporeForwardCurveUpdated ? ` ${singaporeForwardCurveUpdated}` : ''}
                                  </Typography>
                                </Grid>

                                {shipSectors.map((item, i) => {
                                  const index = getCurrentIndex(shipSectors, item)
                                  const sectorNames = getSectorNames(shipSectors, item)

                                  if (index >= 0 && value === index && sectorNames[value] === 'Handy') {
                                    return (
                                      <Grid key={i} item className={classes.commoditiesBundle}>
                                        <Typography className={classes.commodities} variant='caption'>
                                          {baltic38kPos >= 0 ? 'Baltic 38k FFA' : ''}
                                        </Typography>
                                        <Typography className={classes.commoditiesDate} variant='caption'>
                                          {baltic38kPos >= 0 ? ` ${timeStrings[baltic38kPos]}` : ''}
                                        </Typography>
                                      </Grid>
                                    )
                                  } else if (index >= 0 && value === index && sectorNames[value] === 'Supramax') {
                                    return (
                                      <Grid key={i} item className={classes.commoditiesBundle}> 
                                        <Typography className={classes.commodities} variant='caption'>
                                          {baltic58kPos >= 0 ? 'Baltic 58k FFA' : ''}
                                        </Typography>
                                      <Typography className={classes.commoditiesDate} variant='caption'>
                                          {baltic58kPos >= 0 ? ` ${timeStrings[baltic58kPos]}` : ''}
                                        </Typography>
                                      </Grid>
                                    )
                                  } else if (index >= 0 && value === index && sectorNames[value] === 'Kamsarmax') {
                                    return (
                                      <Grid key={i} item className={classes.commoditiesBundle}>                                        
                                        <Typography className={classes.commodities} variant='caption'>
                                          {baltic82kPos >= 0 ? 'Baltic 82k 5tc FFA' : ''}
                                        </Typography>
                                      <Typography className={classes.commoditiesDate} variant='caption'>
                                          {baltic82kPos >= 0 ? ` ${timeStrings[baltic82kPos]}` : ''}
                                        </Typography>
                                      </Grid>
                                    )
                                  }
                                })}

                              </Grid>
                            </Box>

                            <Box width='150px' height='57px' display='flex' alignItems='flex-end' style={{ marginRight: '20px' }}>
                              {shipSectors.map((item, i) => {
                                const index = getCurrentIndex(shipSectors, item)
                                const sectorNames = getSectorNames(shipSectors, item)
                                if (index >= 0 && value === index && sectorNames[value] === 'Handy') {
                                  return <Typography noWrap={true} style={{ fontSize: '10px' }}>{` (${timeSinceBaltic38k} hours)`}</Typography>
                                } else if (index >= 0 && value === index && sectorNames[value] === 'Supramax') {
                                  return <Typography noWrap={true} style={{ fontSize: '10px' }}>{` (${timeSinceBaltic58k} hours)`}</Typography>
                                } else if (index >= 0 && value === index && sectorNames[value] === 'Kamsarmax') {
                                  return <Typography noWrap={true} style={{ fontSize: '10px' }}>{` (${timeSinceBaltic82k} hours)`}</Typography>
                                }
                              })}
                            </Box>

                            <Box item xs={2} position='static'>
                              <RootTextField
                                style={{ borderRadius: '10%' }}
                                inputProps={{ fontSize: 15 }}
                                label='adcom'
                                type='number'
                                onChange={(e) => {
                                  // setAdcomValue(isNaN(parseFloat((e.target.value).replace(',', '.'))) ? 0 : parseFloat((e.target.value).replace(',', '.')))
                                  setAdcomValue((e.target.value) && e.target.value)
                                  // setUser({
                                  //   type: 'adcom',
                                  //   value: e.target.value
                                  // })
                                  setAdcomDebounce(e)
                                }}
                                value={adcomValue}
                                className={classes.adcom}
                                allowedDecimalSeparators={[',', '.']}
                              />
                            </Box>
                          </Grid>
                        </AppBar>
                        {
                          shipSectors.map((item, i) => {
                            const index = getCurrentIndex(shipSectors, item)
                            return (
                              <TabPanel
                                key={i}
                                queryData={queryData}
                                indexes={indexes}
                                forwardCurves={shipSectors[i].forwardCurves}
                                positionDates={data.getValidationInformation.positionDates}
                                voyageOffers={data.getValidationInformation.voyageOffers}
                                value={value}
                                index={index}
                                actualIndex={i}
                                adcomValue={adcomValue}
                                quarterArray={quarterArray}
                                systemSetting={systemSetting}
                                fuelPrice={fuelPrice}
                                {...rest}
                              />
                            )
                          })
                        }
                      </Box>
                    )
                  }}
                </Query>
              </div>
            )
          }
        } else {
          return <div />
        }
      }}
    </Query>
  )
}

ForwardValidationQueryHelper.propTypes = {
  onCompleted: PropTypes.func,
  refetchQueries: PropTypes.array
}

const styles = makeStyles(theme => ({
  card: {
    // minHeight: '420px',
    maxHeight: '1000px',
    width: '100%',
    margin: '8px 4px',
    padding: '0',
    overflowX: 'auto'
  },
  validationContainer: {
    // maxWidth: 410
  },
  fixed50: {
    minWidth: '50px',
    width: '50px',
    maxWidth: '50px'
  },
  fixed60: {
    minWidth: '60px',
    width: '60px',
    maxWidth: '60px'
    // maxWidth: '100px'
  },
  fixed70: {
    minWidth: '70px',
    width: '70px',
    maxWidth: '70px'
    // maxWidth: '150px'
  },
  fixed100: {
    minWidth: '100px',
    width: '100px',
    maxWidth: '100px'
  },
  fixed110: {
    minWidth: '110px',
    width: '110px',
    maxWidth: '110px'
  },
  fixed200Width: {
    width: '200px',
    maxWidth: '200px'
  },
  fixed240: {
    width: '240px',
    maxWidth: '240px'
  },
  fixed250: {
    width: '250px',
    maxWidth: '250px'
  },
  positionDate: {
    marginRight: '2%'
    // width: '100px'
  },
  typography: {
    // width: '10%',
    marginRight: '2%'
  },
  smallInput: {
    // width: '10%',
    marginLeft: '2%'
  },
  selectContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },
  updated: {
    fontSize: theme.typography.generalFontSize - 2,
    margin: '0%',
    color: 'green'
  },
  noMargin: {
    margin: '0%',
    paddingTop: '1px',
    paddingBottom: '1px'
  },
  notUpdated: {
    fontSize: theme.typography.generalFontSize - 2,
    color: 'red'
  },
  smallUpdated: {
    color: 'green',
    fontSize: '10px'
  },
  smallNotUpdated: {
    color: 'red',
    fontSize: '10px'
  },
  small: {
    fontSize: '12px'
  },
  tableFont: {
    fontSize: 15
  },
  offers: {
    backgroundColor: '#DAFBF6',
    borderRight: '1px solid rgba(123, 129, 130, 1)',
    borderLeft: '1px solid rgba(123, 129, 130, 1)',
    // margin: '0%',
    // padding: '0%'
    width: '120px'
  },
  offersOther: {
    backgroundColor: '#F5E0E7',
    borderRight: '1px solid rgba(123, 129, 130, 1)',
    borderLeft: '1px solid rgba(123, 129, 130, 1)',
    // margin: '0%',
    // padding: '0%',
    // color: 'white',
    width: '120px'
  },
  offerCells: {
    // width: '120px'
  },
  glowingBorder: {
    border: '2px solid #A6D9F7',
    borderRadius: '7px'
    // width: '150px'
  },
  glowingLocalBorder: {
    backgroundColor: '#78FECF'
    // width: '150px'
  },
  tableLayout: {
    tableLayout: 'fixed',
    width: 'auto'
  },
  titleContainer: {
    paddingRight: '8px',
    paddingLeft: '8px'
  },
  buttonsContainer: {
    textAlign: 'right'
  },
  offersCell: {
    maxWidth: 50
  },
  cardContent: {
    // overflowX: 'auto'
    // paddingTop: '0px'
  },
  adcomInput: {
    backgroundColor: 'white',
    borderRadius: 0
  },
  tooltipArrange: {
    display: 'flex',
    flexDirection: 'column'
  },
  tooltipText: {
    fontSize: '11px',
    fontWeight: 'bold'
  },
  quarter: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)'
    // borderBottom: '2px'
    // borderBottom: '1px solid rgba(0, 0, 0 , 1)'
  },
  curveCell: {
    borderBottom: 'none'
  },
  bid: {
    color: 'green',
    marginRight: '5px'
  },
  offerBox: {
    display: 'flex',
    direction: 'row'
  },
  quartersCell: {
    // borderRight: '1px solid #000',
    border: 'none',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  routeResultsCell: {
    cursor: 'pointer',
    '&:hover': {
      color: '#db5461'
    }
  },
  isToday: {
    color: 'green'
  },
  isYesterday: {
    color: '#F17300'
  },
  isFourDays: {
    color: 'red'
  },
  spreads: {
    maxWidth: '400px',
    overFlowY: 'auto'
  },
  spreadTable: {
    maxHeight: '100px',
    padding: '5px'
  },
  spreadTableLayout: {
    tableLayout: 'fixed',
    width: '400px'
  },
  greenVoyage: {
    color: 'green',
    backgroundColor: '#D0FFC7'
  },
  orangeVoyage: {
    color: '#F17300',
    backgroundColor: '#FFF3C7'
  },
  redVoyage: {
    color: 'red',
    backgroundColor: '#FFC7C7'
  }
}))

const ForwardValidationSpotIndexes = props => {
  const { adcomValue, forwardCurves, shipSectorIndex, positionDates, indexes, voyageOffers, setFocusName, focusName, quarterArray, queryData, shipSectors, systemSetting, fuelPrice, ...rest } = props

  const classes = styles()

  const [selectedInput, setSelectedInput] = useState()

  const handleSelectedInput = value => {
    setSelectedInput(value)
  }

  const getVoyageOffers = (params) => {
    const {
      voyageOffers,
      curve
    } = params
    const result = []
    for (let index = 0; index < voyageOffers.length; index++) {
      if (voyageOffers[index].route.forwardCurve.id === curve.id) {
        result.push({ ...voyageOffers[index] })
      }
    }
    return result
  }

  // const selectedInput = useRef()

  const selectedField = useRef()

  const availableFields = []

  for (let i = 0; i < forwardCurves.length; i++) {
    for (let i = 0; i < (positionDates.length * 2); i++) {
      availableFields.push(createRef())
    }
  }

  const availableFieldsRef = useRef([])

  const [lastInput, setLastInput] = useState({})

  useEffect(() => {
    if (selectedInput !== null && selectedInput !== selectedField) {
      selectedField.current = selectedInput
    }
  }, [selectedInput])

  // const initialState = []
  // for (const curve of forwardCurves) {
  //   initialState.push({
  //     ...curve,
  //     apsBB: curve.apsBB ? curve.apsBB : 0,
  //     apsDh: curve.apsDh ? curve.apsDh : 0,
  //     apsResult: curve.apsResult ? curve.apsResult : 0
  //   })
  // }

  const [curvesHandler, setCurvesHandler] = useReducer(curvesReducer, [...forwardCurves])

  const [lookForDpdncies, setLookFordpdncies] = useState(false)
  const curveRef = useRef('')
  const posRef = useRef(null)

  const [localFwCurve, setLocalFwCurve] = useState([])

  const [calcDpdtPos, setCalcDpdPos] = useState(false)

  // Function to get the children of a curve
  const getChildren = params => {
    const {
      id
    } = params

    const children = []
    for (let j = 0; j < curvesHandler.length; j++) {
      if (curvesHandler[j].basedOnForwardCurve && curvesHandler[j].basedOnForwardCurve.id === id) {
        children.push({ id: curvesHandler[j].id })
      }
    }
    return children
  }

  const highlight = (id) => {
    for (let i = 0; i < curvesHandler.length; i++) {
      if (curvesHandler[i].id === id) {
        setLocalFwCurve(prev => [...prev, i])
      }
    }
  }

  const inheritHighlight = params => {
    const {
      id
    } = params

    // Highligh parent
    highlight(id)

    // Get children
    const children = getChildren({ id })

    // Inherit highlight
    for (const child of children) {
      inheritHighlight(child)
    }
  }

  useEffect(() => {
    if (calcDpdtPos) {
      setCalcDpdPos(false)
      inheritHighlight({ id: curveRef.current })
    }
  }, [calcDpdtPos])

  const tcesFromSector = forwardCurves.map(curve => {
    const curveId = curve.id
    const tceArray = positionDates.map((posdate, k) => {
      let matchingName
      let resultMonth
      if (posdate.positionDateName.charAt(1) === 'h') {
        matchingName = posdate.positionDateName.split('h')
        resultMonth = months.indexOf(matchingName[1])
      } else {
        matchingName = posdate.positionDateName
        resultMonth = months.indexOf(matchingName)
      }
      let spreads = 0
      for (let i = 0; i < curve.coreRoute.spotTCSpread.length; i++) {
        spreads += curve.coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] ? curve.coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] : 0
      }

      if (curve.base) {
        return k === 0 ? parseFloat(curve.result) : ((curve.base[k] * (1 + curve.curveAdjustmentPercent[k] / 100)) + curve.curveAdjustmentAbsolute[k]) * (1 + spreads / 100)
      } else {
        return k === 0 ? parseFloat(curve.result) : ((curve.forwardCommodity.forwardArray[k] * (1 + curve.curveAdjustmentPercent[k] / 100)) + curve.curveAdjustmentAbsolute[k]) * (1 + spreads / 100)
      }
    })

    return { curveId, tceArray }
  })

  return (
    <div>
      <Grid container>
        {forwardCurves.map((item, i) => {
          const latestChange = item.latestChange ? new Date(item.latestChange) : new Date()
          const updated = item.update && item.update.time ? new Date(item.update.time) : new Date(1994, 12, 2)
          if (!item.coreRoute) {
            return (
              <Grid container item xs={12} md={4} xl={3}>
                <Card className={classes.card}>
                  <Typography style={{ marginLeft: '2%', marginTop: '1%', color: 'red' }}>{item.forwardCurveName}</Typography>
                  <Typography style={{ marginLeft: '170px', marginTop: '100px' }}>No Route linked</Typography>
                </Card>
              </Grid>
            )
          }
          return (
            <ForwardValidation
              key={i}
              updatedDate={updated}
              latestChange={latestChange}
              positionDates={positionDates}
              forwardCurves={forwardCurves}
              forwardCurve={item}
              voyageOffers={getVoyageOffers({ voyageOffers, curve: item })}
              focusName={focusName}
              setFocusName={setFocusName}
              adcomValue={adcomValue}
              shipSectorIndex={shipSectorIndex}
              selectedInput={selectedInput}
              forwardCurveIndex={i}
              indexes={indexes}
              handleSelectedInput={handleSelectedInput}
              availableFieldsRef={availableFieldsRef}
              index={i}
              selectedField={selectedField}
              lastInput={lastInput}
              setLastInput={setLastInput}
              quarterArray={quarterArray}
              queryData={queryData}
              shipSectors={shipSectors}
              tcesFromSector={tcesFromSector}
              curvesHandler={curvesHandler}
              setCurvesHandler={setCurvesHandler}
              lookForDpdncies={lookForDpdncies}
              setLookFordpdncies={setLookFordpdncies}
              curveRef={curveRef}
              posRef={posRef}
              localFwCurve={localFwCurve}
              setLocalFwCurve={setLocalFwCurve}
              calcDpdtPos={calcDpdtPos}
              setCalcDpdPos={setCalcDpdPos}
              systemSetting={systemSetting}
              fuelPrice={fuelPrice}
              setSelectedInput={setSelectedInput}
              selectedInput={selectedInput}
              {...rest}
            />
          )
        })}
      </Grid>
    </div>
  )
}

const ItalicBasedFormat = props => {
  const { isCurve = false, text = 'No text', className, isLink = false, href = null, onClick = null } = props

  if (isCurve && !isLink) {
    return (
      <Typography
        className={className}
      >
        <i>{text}</i>
      </Typography>
    )
  } else if (isCurve && isLink) {
    return (
      <Typography
        className={className}
      >
        <i>
          <Link
            className={className}
            href={href}
            onClick={onClick}
          >
            {text}
          </Link>
        </i>
      </Typography>
    )
  } else if (!isCurve & isLink) {
    return (
      <Typography
        className={className}
      >
        <Link
          className={className}
          href={href}
          onClick={onClick}
        >
          {text}
        </Link>
      </Typography>
    )
  } else {
    return (
      <Typography
        className={className}
      >
        {text}
      </Typography>
    )
  }
}

const ForwardValidation = props => {
  const classes = styles()
  const { user } = useContext(UserContext)

  const { quarterArray, latestChange, lastInput, setLastInput, availableFieldsRef, index, handleSelectedInput, selectedInput, indexes, forwardCurveIndex, positionDates, shipSectorIndex, updatedDate, adcomValue, voyageOffers, queryData, shipSectors, tcesFromSector, curvesHandler, setCurvesHandler, curveRef, posRef, localFwCurve, setLocalFwCurve, setCalcDpdPos, systemSetting, fuelPrice, setSelectedInput } = props

  const [lockForwardCurveInstance] = useMutation(LOCK_FORWARD_CURVE_INSTANCE, {
    onError (error) {
      // when error occurs
      console.log(error)
    }
  })

  const [lockAllForwardCurveInstances] = useMutation(LOCK_ALL_FORWARD_CURVE_INSTANCES, {
    onError (error) {
      // when error occurs
      console.log(error)
    }
  })

  const [lockAllForwardCurvesFromSector] = useMutation(LOCK_ALL_FORWARD_CURVES_FROM_SECTOR, {
    onError (error) {
      console.log(error)
    }
  })
  // Reducer Hook To Handle Menu
  const [contextState, setContextState] = useReducer(contextReducer, initialState)
  const [vesselAps, setVesselAps] = useState()
  const [modifiedIndex, setModifiedIndex] = useState()

  const [spotIndexAps] = useMutation(SPOTINDEX_APS, {
    onError(error) {
      fireSwalError({
        title: 'Error',
        text: error
          ? error.message.split(':')[1]
          : 'Error calculating please verify core route.'
      })
    },
    onCompleted(data) {
      if (data) {
        setModifiedIndex(index)
        setVesselAps(data.apsResult[0].rate) 
      }
    }
  })

  const vesselApsRef = React.useRef(vesselAps)

  useEffect(() => {
    if (vesselAps !== vesselApsRef.current) {
      vesselApsRef.current = vesselAps
      setCurvesHandler({
        type: 'apsResult',
        value: vesselAps,
        index: modifiedIndex
      })
    }
  }, [vesselAps])

  let forwValidateMutation = () => { }
  const setUpdate = () => { }

  const [routeUpdate] = useState('')
  const updateRouteRef = React.useRef(routeUpdate)

  const [selectedResult, setSelectedResult] = useState({ posIndex: 0, posName: positionDates[0].positionDateName })

  useEffect(() => {
    if (routeUpdate !== updateRouteRef.current) {
      updateRouteRef.current = routeUpdate
      setUpdate()
    }
  })

  useEffect(() => {
    if (selectedInput !== null && selectedInput >= (index * 31) && selectedInput <= ((index * 31) + 30) && availableFieldsRef.current[selectedInput] !== null) {
      availableFieldsRef.current[selectedInput].focus()
    }
  }, [selectedInput])

  const [positionModified, setPositionModified] = useState(0)
  const input = {
    id: curvesHandler[index].coreRoute.id,
    curveAdjustmentAbsolute: curvesHandler[index].curveAdjustmentAbsolute,
    curveAdjustmentPercent: curvesHandler[index].curveAdjustmentPercent,
    forwardCommodity: curvesHandler[index].forwardCommodity && curvesHandler[index].forwardCommodity,
    basedOnForwardCurve: curvesHandler[index].basedOnForwardCurve && curvesHandler[index].basedOnForwardCurve,
    forwardCurveName: curvesHandler[index].forwardCurveName,
    spotIndex: curvesHandler[index].spotIndex.id,
    forwardCurve: { ...curvesHandler[index] },
    positionModified,
    shipSectorIndex,
    forwardCurveIndex,
    editRouteResults: true
  }

  // Right-Click On Row Routine
  const handleOpenMenu = (event, forwardCurve, index, tceArray) => {
    event.preventDefault()

    if (!forwardCurve.locked || !forwardCurve.locked[index]) {
      setContextState({
        event,
        selectedRow: {
          id: forwardCurve.id,
          index,
          tceArray
        },
        type: 'open'
      })
    }
  }

  // Close Menu Routine
  const handleCloseMenu = () => {
    setContextState({
      type: 'close'
    })
  }

  // Lock Row Routine
  const handleLockRow = () => {
    lockForwardCurveInstance({
      variables: {
        input: {
          id: contextState.selectedRow.id,
          index: contextState.selectedRow.index,
          tce: contextState.selectedRow.tceArray[contextState.selectedRow.index]
        }
      }
    })
    handleCloseMenu()
  }

  // Lock Block Routine
  const handleLockBlock = () => {
    lockAllForwardCurveInstances({
      variables: {
        input: {
          id: contextState.selectedRow.id,
          tceArray: contextState.selectedRow.tceArray
        }
      }
    })
    handleCloseMenu()
  }

  // Lock All Blocks Routine
  const handleLockAllBlocks = () => {
    if (shipSectors.length > 0 && shipSectorIndex >= 0) {
      lockAllForwardCurvesFromSector({
        variables: {
          input: {
            sectorId: shipSectors[shipSectorIndex].id,
            tcesFromSector
          }
        }
      })
      handleCloseMenu()
    }
  }

  const compare = (a, b) => {
    const bandA = (a.offer) + (a.offer * ((adcomValue - a.adcome) / 100))
    const bandB = (b.offer) + (b.offer * ((adcomValue - b.adcome) / 100))
    let comparison = 0
    if (bandA > bandB) {
      comparison = 1
    } else if (bandA < bandB) {
      comparison = -1
    }
    return comparison
  }
  const getOffers = (voyageOffers, item) => {
    const result = []

    for (let i = 0; i < voyageOffers.length; i++) {
      if (voyageOffers[i].positionDate.id === item.id) {
        result.push(voyageOffers[i])
      }
    }
    return result.sort(sortByDate)
  }

  const forwardCurveInput = {
    id: curvesHandler[index].id,
    forwardCommodity: curvesHandler[index].forwardCommodity && curvesHandler[index].forwardCommodity.id,
    basedOnForwardCurve: curvesHandler[index].basedOnForwardCurve && curvesHandler[index].basedOnForwardCurve.id,
    forwardCurveName: curvesHandler[index].forwardCurveName,
    spotIndex: curvesHandler[index].spotIndex.id,
    curveAdjustmentAbsolute: curvesHandler[index].curveAdjustmentAbsolute.map((item, i) => { return parseFloat(item) }),
    curveAdjustmentPercent: curvesHandler[index].curveAdjustmentPercent.map((item, i) => { return parseFloat(item) }),
    coreRoute: curvesHandler[index].coreRoute.id,
    editRouteResults: true
  }

  const [anchorEl, setAnchorEl] = useState(null)
  const [openPopper, setOpenPopper] = useState(false)
  const [placement, setPlacement] = useState()
  const [popperIndex, setPopperIndex] = useState(0)

  const updated = new Date(curvesHandler[index].update.time) > new Date(curvesHandler[index].latestChange)

  const handleClick = (newPlacement, index) => (event) => {
    setAnchorEl(event.currentTarget)
    setOpenPopper((prev) => placement !== newPlacement || !prev)
    setPlacement(newPlacement)
    setPopperIndex(index)
  }
  const getSpreadsFromRotue = () => {
    let result = 0
    for (let i = 0; i < curvesHandler[index].coreRoute.spotTCSpread.length; i++) {
      result += curvesHandler[index].coreRoute.spotTCSpread[i].spreadTCValue
    }
    return result
  }
  const currentSpreads = getSpreadsFromRotue()
  const [validationSpreads] = useState(currentSpreads)
  const handleSelectedField2 = (value) => {
    handleSelectedInput(value)
  }

  const [deleteVoyageDialog, setDeleteVoyageDialog] = useState(false)

  const [voyageToDelete, setVoyageToDelete] = useState()

  const handleVoyageClose = () => {
    setDeleteVoyageDialog(false)
  }

  const handleVoyageOpen = (voyage) => {
    setVoyageToDelete(voyage)
    setDeleteVoyageDialog(true)
  }

  const updatedTimezonedDate = (curvesHandler[index].update && curvesHandler[index].update.time) ? getFormattedDate(curvesHandler[index].update.time, 'Europe/Copenhagen') : ''

  const preventDefault = (event) => event.preventDefault()

  const tceArray = positionDates.map((posdate, k) => {
    let matchingName
    let resultMonth
    if (posdate.positionDateName.charAt(1) === 'h') {
      matchingName = posdate.positionDateName.split('h')
      resultMonth = months.indexOf(matchingName[1])
    } else {
      matchingName = posdate.positionDateName
      resultMonth = months.indexOf(matchingName)
    }
    let spreads = 0
    for (let i = 0; i < curvesHandler[index].coreRoute.spotTCSpread.length; i++) {
      spreads += curvesHandler[index].coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] ? curvesHandler[index].coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] : 0
    }

    if (curvesHandler[index].base) {
      return k === 0 ? parseFloat(curvesHandler[index].result) : ((curvesHandler[index].base[k] * (1 + curvesHandler[index].curveAdjustmentPercent[k] / 100)) + curvesHandler[index].curveAdjustmentAbsolute[k]) * (1 + spreads / 100)
    } else {
      return k === 0 ? parseFloat(curvesHandler[index].result) : ((curvesHandler[index].forwardCommodity.forwardArray[k] * (1 + curvesHandler[index].curveAdjustmentPercent[k] / 100)) + curvesHandler[index].curveAdjustmentAbsolute[k]) * (1 + spreads / 100)
    }
  })

  // Get month from positionDate
  let selectedMonth
  let nameMatch
  if (selectedResult.posName.charAt(1) === 'h') {
    nameMatch = selectedResult.posName.split('h')
    selectedMonth = months.indexOf(nameMatch[1])
  } else {
    nameMatch = selectedResult.posName
    selectedMonth = months.indexOf(nameMatch)
  }

  // Tce with no spreads
  const tceNoSpreads = selectedResult.posIndex === 0 ? curvesHandler[index].result : ((curvesHandler[index].base[selectedResult.posIndex] * (1 + curvesHandler[index].curveAdjustmentPercent[selectedResult.posIndex] / 100)) + curvesHandler[index].curveAdjustmentAbsolute[selectedResult.posIndex])

  // Is all block locked or not
  const locked = curvesHandler[index].locked.reduce((acc, curr) => acc && curr)

  // console.log('curves handler', curvesHandler)

  // <Grid container item className={classes.validationContainer}>
  return (
    <Grid container item xs={12} md={4} xl={3}>
      <MutationHelper
        mutation={FORWARD_VALIDATE_RESULT}
        {...(props.onCompleted
          ? { onCompleted: props.onCompleted }
          : {
            onCompletedObject: {
              title: 'Success',
              message: 'Result Created',
              path: null
            }
          }
        )}
        variables={{ input: { data: { ...input, adcomValue } } }}
      >
        {(mutation, result) => {
          forwValidateMutation = (i, specialCase) => {
            if (specialCase) {
              mutation()
              setLastInput(input)
            } else if ((!_.isEqual(input, lastInput))) {
              setLastInput(input)
              mutation()
            }
          }
          return (
            <></>
          )
        }}
      </MutationHelper>
      {(curvesHandler[index].id !== '' && curvesHandler[index].coreRoute) &&
        <Card className={classes.card} style={{ borderRadius: '1%' }}>
          <Grid item container className={classes.titleContainer}>
            <Grid item xs={10}>
              <Grid item container alignItems='center' className={classes.noMargin}>
                <ItalicBasedFormat
                  isCurve={curvesHandler[index].basedOnForwardCurve}
                  className={(updated) ? classes.updated : classes.notUpdated}
                  text={curvesHandler[index].forwardCurveName}
                />
                <ItalicBasedFormat
                  isCurve={curvesHandler[index].basedOnForwardCurve}
                  className={(updated) ? classes.smallUpdated : classes.smallNotUpdated}
                  text={curvesHandler[index].update && curvesHandler[index].update.user ? ` - ${curvesHandler[index].update.user} -${updatedTimezonedDate} ` : ''}
                />
              </Grid>
              <Grid>
                <ItalicBasedFormat
                  isCurve={curvesHandler[index].basedOnForwardCurve}
                  className={(updated) ? classes.updated : classes.notUpdated}
                  text={curvesHandler[index].basedOnForwardCurve && `Based on: ${curvesHandler[index].basedOnForwardCurve.forwardCurveName}`}
                />
              </Grid>
              <Grid item container alignItems='center'>
                <ItalicBasedFormat
                  isCurve={curvesHandler[index].basedOnForwardCurve}
                  className={(updated) ? classes.updated : classes.notUpdated}
                  isLink
                  href={`/calculation-page/${curvesHandler[index].coreRoute && curvesHandler[index].coreRoute.id}`}
                  onClick={() => { preventDefault() }}
                  text={curvesHandler[index].coreRoute && curvesHandler[index].coreRoute.routeName ? curvesHandler[index].coreRoute.routeName : ''}
                />
                <Grid style={{ marginLeft: '5px' }}>
                  {
                    locked ? <LockIcon /> : <LockOpenIcon />
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={2} justify='flex-end' className={classes.buttonsContainer}>
              <Grid item xs={12}>
                <MutationHelper
                  mutation={UPSERT_FORWARDCURVE}
                  {...(props.onCompleted
                    ? { onCompleted: props.onCompleted }
                    : {
                      onCompletedObject: {
                        title: 'Success',
                        message: 'Result Created',
                        path: null
                      }
                    }
                  )}
                  variables={{ input: { ...forwardCurveInput } }}
                >
                  {(mutation, result) => {
                    return (
                      <Link disabled={result.loading || user.disabled} onClick={() => mutation()}>
                        Save
                      </Link>
                    )
                  }}
                </MutationHelper>
              </Grid>
              <Grid item xs={12}>
                <MutationHelper
                  mutation={RESET_CURVES}
                  {...(props.onCompleted
                    ? { onCompleted: props.onCompleted }
                    : {
                      onCompletedObject: {
                        title: 'Success',
                        message: 'Result Created',
                        path: null
                      }
                    }
                  )}
                  variables={{ input: { shipSectorIndex, forwardCurveIndex } }}
                >
                  {(mutation, result) => {
                    return (
                      <Link disabled={result.loading || user.disabled} onClick={() => mutation()}>
                        Reset
                      </Link>
                    )
                  }}
                </MutationHelper>
              </Grid>
            </Grid>
            <Box display='flex' flexDirection='row' flexWrap='nowrap' style={{ marginTop: '15px'}}>
              <Box display='flex' alignItems='flex-start' style={{ width: '188px', height: '43px'}}>
                <ItalicBasedFormat
                  text={`${curvesHandler[index].spotIndex.indexName}: ${curvesHandler[index].spotIndex.indexValue}`}
                  isCurve={curvesHandler[index].basedOnForwardCurve}
                  className={classes.small}
                />
              </Box>
              <Box display='flex' flexDirection='column' flexWrap='nowrap' alignItems='flex-end' style={{ width: '70px'}}>
                <Box style={{ padding: '0px 4px' }}>
                  <Typography style={{ fontSize: '12px' }}><b>APS Hire</b></Typography>
                </Box>
                <Box style={{ padding: '0px 4px' }}>
                  <EditableInputBase
                    id={`${curvesHandler[index].forwardCurveName}-apsDh`}
                    alignRight
                    removeDecimals
                    inputRef={(e) => (availableFieldsRef.current[index * 31] = e)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 9 || e.keyCode === 13) {
                        e.preventDefault()
                        if (curvesHandler[index].apsDh !== 0) {
                          spotIndexAps({
                            variables: {
                              input: {
                                data: {
                                  aps: curvesHandler[index].apsDh,
                                  apsBunkerCost: curvesHandler[index].apsBB * 1000,
                                  route: curvesHandler[index].coreRoute.id,
                                  shipSectorIndex,
                                  forwardCurveIndex: index
                                }
                              }
                            }
                          })
                        }
                        if (e.shiftKey) {
                          handleSelectedField2(index !== 0 ? index * 31 - 30 : 0)
                        } else {
                          handleSelectedField2(index * 31 + 1)
                        }
                      }
                    }}
                    onClick={(e) => handleSelectedField2(index * 31)}
                    onBlur={() =>
                      curvesHandler[index].apsDh !== 0
                       ? spotIndexAps({
                        variables: {
                          input: {
                            data: {
                              aps: curvesHandler[index].apsDh,
                              apsBunkerCost: curvesHandler[index].apsBB * 1000,
                              route: curvesHandler[index].coreRoute.id,
                              shipSectorIndex,
                              forwardCurveIndex: index
                            }
                          }
                        }
                      }) 
                      : ''}
                    onChange={(e) =>
                      setCurvesHandler({
                        type: 'apsDh',
                        value: e.target.value,
                        index
                    })}
                    value={curvesHandler[index].apsDh}
                  />
                </Box>
              </Box>
              <Box display='flex' flexDirection='column' alignItems='flex-end' flexWrap='nowrap' style={{ width: '60px'}}>
                <Box style={{ padding: '0px 4px' }}>
                  <Typography style={{ fontSize: '12px' }}><b>APS BB</b></Typography>
                </Box>
                <Box style={{ padding: '0px 4px' }}>

                  <Box display='flex' flexDirection='row' flexWrap='nowrap' >
                    <EditableInputBase
                      id={`${curvesHandler[index].forwardCurveName}-apsBB`}
                      alignRight
                      noFormat
                      inputRef={(e) => (availableFieldsRef.current[index * 31 + 1] = e)}
                      onKeyDown={(e) => {
                        if (e.keyCode === 9 || e.keyCode === 13) {
                          e.preventDefault()
                          if (curvesHandler[index].apsDh !== 0) {
                            setCurvesHandler({
                              type: 'apsBB-unFocus',
                              index,
                              mutation: spotIndexAps,
                              shipSectorIndex
                            })
                          }
                          if (e.shiftKey) {
                            handleSelectedField2(index * 31)
                          } else {
                            handleSelectedField2(index * 31 + 31)
                          }
                        }
                      }}
                      onClick={(e) => handleSelectedField2(index * 31 + 1)}
                      onBlur={() =>
                        curvesHandler[index].apsDh !== 0 ? setCurvesHandler({ type: 'apsBB-unFocus', index, mutation: spotIndexAps, shipSectorIndex }) : ''}
                      onChange={(e) =>
                        setCurvesHandler({
                          type: 'apsBB',
                          value: e.target.value,
                          index
                        })}
                      value={formatNumber({ number: curvesHandler[index].apsBB, format: '0,0' })}
                    />
                    <Box style={{ height: 20, fontSize: '15px' }}>
                      K
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box display='flex' flexDirection='column' flexWrap='nowrap' alignItems='flex-end' style={{ width: '70px'}}>
                <Box style={{ padding: '0px 4px' }}>
                  <Typography style={{ fontSize: '12px' }}><b>APS Rate</b></Typography>
                </Box>
                <Box style={{ padding: '0px 4px' }}>
                  <EditableInputBase
                    alignRight
                    value={curvesHandler[index].apsResult}
                    disabled
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <CardContent className={classes.cardContent}>
            <Table className={classes.tableLayout} size='small'>
              <TableBody>
                <TableRow>
                  <TableCell className={clsx([classes.fixed50, classes.tableFont])}>
                    <b style={{ fontWeight: 450 }}>Date</b>
                  </TableCell>
                  <TableCell align='right' className={classes.fixed60}>
                    <b style={{ fontWeight: 450 }}>Base</b>
                  </TableCell>
                  <TableCell align='right' className={clsx([classes.fixed70, classes.tableFont])}>
                    <b style={{ fontWeight: 450 }}>Percent</b>
                  </TableCell>
                  <TableCell align='right' className={clsx([classes.fixed70, classes.tableFont])}>
                    <b style={{ fontWeight: 450 }}>Absolute</b>
                  </TableCell>
                  <TableCell align='right' className={clsx([classes.fixed60, classes.tableFont])}>
                    <b style={{ fontWeight: 450 }}>TCE</b>
                  </TableCell>
                  <TableCell align='right' className={clsx([classes.fixed70, classes.tableFont])}>
                    <b style={{ fontWeight: 450 }}>Voyage</b>
                  </TableCell>
                  <TableCell align='left' colSpan={voyageOffers.length} className={classes.tableFont}>
                    <b style={{ fontWeight: 450 }}>Offers</b>
                  </TableCell>
                </TableRow>
                {positionDates.map((posdate, i, array) => {
                  let matchingName
                  let resultMonth
                  if (posdate.positionDateName.charAt(1) === 'h') {
                    matchingName = posdate.positionDateName.split('h')
                    resultMonth = months.indexOf(matchingName[1])
                  } else {
                    matchingName = posdate.positionDateName
                    resultMonth = months.indexOf(matchingName)
                  }

                  let spreads = 0
                  for (let i = 0; i < curvesHandler[index].coreRoute.spotTCSpread.length; i++) {
                    spreads += curvesHandler[index].coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] ? curvesHandler[index].coreRoute.spotTCSpread[i].spreadTCValue[resultMonth] : 0
                  }
                  const quarter = quarterArray.includes(i)

                  const isChild = !!curvesHandler[index].basedOnForwardCurve

                  let tce
                  if (curvesHandler[index].base) {
                    tce = i === 0 ? curvesHandler[index].result : parseFloat((((parseFloat(curvesHandler[index].base[i]).toFixed(0) * (1 + curvesHandler[index].curveAdjustmentPercent[i] / 100)) + curvesHandler[index].curveAdjustmentAbsolute[i]) * (1 + spreads / 100)).toFixed(0))
                  } else {
                    // TODO Erase if-else once every curve has a base
                    tce = i === 0 ? curvesHandler[index].result : ((curvesHandler[index].forwardCommodity.forwardArray[i] * (1 + curvesHandler[index].curveAdjustmentPercent[i] / 100)) + curvesHandler[index].curveAdjustmentAbsolute[i]) * (1 + spreads / 100)
                  }
                  
                  const totalCosts = curvesHandler[index] && curvesHandler[index].calcInfo ? curvesHandler[index].calcInfo[i].totalFuel + curvesHandler[index].calcInfo[i].portCosts + curvesHandler[index].calcInfo[i].miscCost + ((tce * 0.95) * curvesHandler[index].calcInfo[i].totalTime) : 0

                  const commission = curvesHandler[index].calcInfo[i].routeCommission ? totalCosts / (1 - (curvesHandler[index].calcInfo[i].routeCommission / 100)) * (curvesHandler[index].calcInfo[i].routeCommission / 100) : curvesHandler[index].calcInfo[i].commission
                  const calcedRate = (curvesHandler[index] && curvesHandler[index].calcInfo) ? (totalCosts + commission) / curvesHandler[index].calcInfo[i].intake : 0

                  return (
                    <TableRow style={{ lineHeight: 2 }} key={i} className={classes.quartersCell} onContextMenu={e => handleOpenMenu(e, curvesHandler[index], i, tceArray)} onClick={() => setSelectedResult({ posIndex: i, posName: posdate.positionDateName })} selected={selectedResult.posIndex === i}>
                      <SpreadsPopper open={openPopper} anchorEl={anchorEl} placement={placement} spreads={spreads} index={popperIndex} indexValue={curvesHandler[index].spotIndex.indexValue} forwardArray={curvesHandler[index].base} />
                      <TableCell className={clsx([classes.positionDate, classes.fixed50, classes.routeResultsCell, classes.tableFont], { [classes.quarter]: quarter, [classes.curveCell]: !quarter })}>{posdate.positionDateName}</TableCell>
                      <TableCell align='right' className={clsx([classes.fixed60, classes.routeResultsCell, classes.tableFont], { [classes.quarter]: quarter, [classes.curveCell]: !quarter })} onClick={handleClick('top', i)}>
                        {`${formatNumber({ format: '0,0', number: (curvesHandler[index].base && curvesHandler[index].base[i]) })}`}
                      </TableCell>
                      <TableCell align='right' className={clsx([classes.fixed70, classes.routeResultsCell], { [classes.quarter]: quarter, [classes.curveCell]: !quarter })}>
                        <EditableInputBase
                          id={`${curvesHandler[index].forwardCurveName}-input-per-${i + 1}`}
                          // ref={i === 1 ? uniqueField : availableFields.current[0]}
                          disabled={(i === 0 && !curvesHandler[index].basedOnForwardCurve) || (curvesHandler[index].locked && curvesHandler[index].locked[i])}
                          alignRight
                          noMargin
                          inputRef={(e) => (availableFieldsRef.current[i === 0 ? index * 31 + 2 : (index*31) + 2*(i+1) + 1] = e)}
                          inputProps={{ style: { fontSize: 15, height: 24 } }}
                          // inputRef={i=== 1? uniqueField : elementsRef.current[i]}
                          onClick={(event) => {
                            setPositionModified(i)
                            setLocalFwCurve([])
                            handleSelectedField2(i === 0 ? index * 31 + 2 : (index*31) + 2*(i+1) + 1)
                            if (i === 0) {
                              input.positionModified = 0
                              forwValidateMutation(i, true)
                            }
                          }}

                          onKeyDown={(e) => {
                            if (e.keyCode === 9) {
                              e.preventDefault()
                              if (e.shiftKey) {
                                handleSelectedField2(i === 0 ?  index * 31 + 2 : i === 1 ? index * 31 + 2 : (index*31) + 2*(i+1) - 1)
                              } else {
                                handleSelectedField2((index*31) + 2*(i+1) + 3)
                              }
                              forwValidateMutation(i)
                              curveRef.current = curvesHandler[index].id
                              posRef.current = i
                              setCalcDpdPos(true)
                              setCurvesHandler({
                                type: 'inherit',
                                root: curvesHandler[index].id,
                                positionDates: positionDates,
                                pos: i
                              })
                            }
                          }}

                          onChange={(e) => {
                            setPositionModified(i)
                            setCurvesHandler({ value: e.target.value, type: 'per', posIndex: i, curveIndex: index, child: isChild })
                            setCurvesHandler({
                              type: 'latestChange',
                              posIndex: i,
                              curveIndex: index
                            })
                          }}
                          value={curvesHandler[index].curveAdjustmentPercent[i]}
                        />
                      </TableCell>
                      <TableCell align='right' className={clsx(classes.fixed70, { [classes.quarter]: quarter, [classes.curveCell]: !quarter })}>
                        <EditableInputBase
                          id={`${curvesHandler[index].forwardCurveName}-input-absolute-${i + 1}`}
                          alignRight
                          inputRef={(e) => (availableFieldsRef.current[i === 0 ? index * 31 + 3 : (index*31) + 2*(i+1) + 2] = e)}
                          inputProps={{ style: { fontSize: 15, height: 24 } }}
                          onChange={(e) => {
                            setPositionModified(i)
                            setCurvesHandler({ value: e.target.value, type: 'abs', posIndex: i, curveIndex: index, child: isChild })
                            setCurvesHandler({
                              type: 'latestChange',
                              posIndex: i,
                              curveIndex: index
                            })
                          }}

                          onKeyDown={(e) => {
                            if (e.keyCode === 9) {
                              e.preventDefault()
                              if (e.shiftKey) {
                                handleSelectedField2(i === 0 ? index*31 + 3 : i === 1 ? index*31 + 3 : (index*31) + 2*(i+1))
                              } else {
                                handleSelectedField2((index*31) + 2*(i+1) + 4)
                              }
                              forwValidateMutation(i)
                              curveRef.current = curvesHandler[index].id
                              posRef.current = i
                              setCalcDpdPos(true)
                              setCurvesHandler({
                                type: 'inherit',
                                root: curvesHandler[index].id,
                                positionDates: positionDates,
                                pos: i
                              })
                            }
                          }}

                          onClick={() => {
                            setPositionModified(i)
                            setLocalFwCurve([])
                            handleSelectedField2(i === 0 ? index * 31 + 3 : (index*31) + 2*(i+1) + 2)
                            if (i === 0) {
                              input.positionModified = 0
                              forwValidateMutation(i, true)
                            }
                          }}
                          isInteger
                          value={curvesHandler[index].curveAdjustmentAbsolute[i]}
                          disabled={(i === 0 && !curvesHandler[index].basedOnForwardCurve) || (curvesHandler[index].locked && curvesHandler[index].locked[i])}
                        />
                      </TableCell>
                      <TableCell align='right' className={clsx([(posRef.current === i && localFwCurve.includes(index)) && classes.glowingLocalBorder, (indexes.shipSectorIndex === shipSectorIndex && indexes.forwardCurveIndex === forwardCurveIndex && indexes.positionDateIndex === i) ? classes.glowingBorder : classes.fixed60, classes.routeResultsCell, classes.tableFont], { [classes.quarter]: quarter, [classes.curveCell]: !quarter })}>
                        <>
                          {
                            i === 0 && (
                              <Mutation
                                mutation={SAVE_RESULT}
                                variables={{
                                  input: {
                                    data: {
                                      result: curvesHandler[index].result,
                                      cp: curvesHandler[index].curveAdjustmentPercent[i],
                                      ca: curvesHandler[index].result -
                                      curvesHandler[index].base[i] * (1 + curvesHandler[index].curveAdjustmentPercent[i] / 100),
                                      route: curvesHandler[index].coreRoute.id,
                                      shipSectorIndex,
                                      forwardCurveIndex: index
                                    }
                                  }
                                }}
                                onError={(error) => {
                                  fireSwalError({
                                    title: 'Error',
                                    text: error
                                      ? error.message.split(':')[1]
                                      : 'Error calculating please verify core route.'
                                  })
                                }}
                              >
                                {(mutation, result) => {
                                  return (
                                    <EditableInputBase
                                      id={`${curvesHandler[index].forwardCurveName}-result`}
                                      alignRight
                                      inputRef={(e) => (availableFieldsRef.current[index*31 + 4] = e)}
                                      inputProps={{ style: { fontSize: 15, height: 24 } }}
                                      onChange={(e) => {
                                        setPositionModified(i)
                                        setCurvesHandler({
                                          type: 'result',
                                          index,
                                          result: e.target.value
                                        })
                                      }}
      
                                      onKeyDown={(e) => {
                                        if (e.keyCode === 9 || e.keyCode === 13) {
                                          e.preventDefault()
                                          if (e.shiftKey) {
                                            handleSelectedField2(index*31 + 3)
                                          } else {
                                            handleSelectedField2(index*31 + 6)
                                          }
                                          setCurvesHandler({
                                            type: 'inherit-result',
                                            index
                                          })
                                          forwValidateMutation(i)
                                          mutation()
                                          curveRef.current = curvesHandler[index].id
                                          posRef.current = i
                                          setCalcDpdPos(true)
                                        }
                                      }}
      
                                      onClick={() => {
                                        setPositionModified(i)
                                        setLocalFwCurve([])
                                        handleSelectedField2(index*31 + 4)
                                        if (i === 0) {
                                          input.positionModified = 0
                                          forwValidateMutation(i, true)
                                        }
                                      }}
                                      isInteger
                                      value={curvesHandler[index].result}
                                    />
                                  )
                                }}
                              </Mutation>
                              
                            )
                          }
                          {
                            i > 0 && (
                              <>
                                {`${formatNumber({ format: '0,0', number: tce })}`}
                              </>
                            )
                          }
                        </>
                      </TableCell>
                      <TableCell align='right' className={clsx([(posRef.current === i && localFwCurve.includes(index)) && classes.glowingLocalBorder, (indexes.shipSectorIndex === shipSectorIndex && indexes.forwardCurveIndex === forwardCurveIndex && indexes.positionDateIndex === i) ? classes.glowingBorder : classes.fixed70, classes.routeResultsCell, classes.tableFont], { [classes.quarter]: quarter, [classes.curveCell]: !quarter })}>
                        {`${formatNumber({ number: (calcedRate + (calcedRate * (adcomValue / 100))), isInteger: true })}`}
                      </TableCell>
                      {getOffers(voyageOffers, posdate).map((voyage, j) => {
                        return (
                          <OfferInformation
                            adcomValue={adcomValue}
                            curveHandler={curvesHandler[index]}
                            handleVoyageOpen={handleVoyageOpen}
                            voyage={voyage}
                            classes={classes}
                            key={j}
                            index={j}
                            systemSetting={systemSetting}
                            posdateIndex={i}
                            fuelPrice={fuelPrice}
                          />
                        )
                      })}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Table className={classes.spreadTableLayout}>
              <TableBody>
                <TableRow>
                  <Grid style={{ marginTop: '15px', marginBottom: '5px' }}>

                    <TableCell className={clsx([classes.fixed240])} align='left'>
                      <b style={{ fontSize: 15, fontWeight: 600 }}>Spot Spreads</b>
                    </TableCell>

                    <TableCell />
                    <TableCell />
                    <TableCell />

                    <TableCell className={clsx([classes.fixed200])} align='center'>
                      <b style={{ fontSize: 15, fontWeight: 600, paddingLeft: 4, paddingRight: 4 }}>Absolute</b>
                    </TableCell>

                    <TableCell className={clsx([classes.fixed70])} style={{ paddingLeft: 4, paddingRight: 4 }} align='right'>
                      <b style={{ fontSize: 15, fontWeight: 600 }}>TCE</b>
                    </TableCell>
                  </Grid>
                </TableRow>

                <Card className={classes.spreads} style={{ borderRadius: '1%', height: 100 }} elevation={0}>
                  {/* <hr style={{ width: 400, height: 0.05 }} /> */}
                  <CardContent className={classes.spreadTable} style={{ overflowY: 'auto', padding: 0 }}>
                    {
                      curvesHandler[index].spotSpreads.length > 0
                        ? curvesHandler[index].spotSpreads.map((spotSpread, j) => {
                          return (
                            <TableRow key={j}>
                              <TableCell style={{ width: 240 }} align='left'>
                                <HtmlTooltip
                                  title={spotSpread.spotTCSpreadName}
                                  arrow
                                >
                                  <Link
                                    target='_blank'
                                    className={classes.tableFont}
                                    underline='none'
                                    color='inherit'
                                    href={`/update-spot-tc-spread/${spotSpread.id}`}
                                  >
                                    {
                                      spotSpread.spotTCSpreadName.split('').length > 32
                                        ? `${spotSpread.spotTCSpreadName.split('').filter((char, i) => i < 32).join('')}...`
                                        : spotSpread.spotTCSpreadName
                                    }
                                  </Link>
                                </HtmlTooltip>
                              </TableCell>
                              <TableCell className={clsx([classes.tableFont])} style={{ width: '70px', paddingLeft: 4, paddingRight: 4 }} align='right'>
                                {formatNumber({ format: '0,0', number: tceNoSpreads * (spotSpread.spreadTCValue[selectedMonth] / 100) })}
                              </TableCell>
                              <TableCell className={clsx([classes.tableFont])} style={{ width: '70px', paddingLeft: 4, paddingRight: 4 }} align='right'>
                                {formatNumber({ format: '0,0', number: tceNoSpreads * (1 + spotSpread.spreadTCValue[selectedMonth] / 100) })}
                              </TableCell>
                            </TableRow>
                          )
                        })
                        : <Grid>
                          <Typography className={clsx([classes.tableFont])}>
                            No spreads to display
                          </Typography>
                          </Grid>
                    }
                  </CardContent>
                </Card>
              </TableBody>
            </Table>
          </CardContent>
        </Card>}

      <Menu
        keepMounted
        open={contextState.mouseY !== null}
        onClose={handleCloseMenu}
        anchorReference='anchorPosition'
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleLockRow}>Lock Row</MenuItem>
        <MenuItem onClick={handleLockBlock}>Lock Block</MenuItem>
        <MenuItem onClick={handleLockAllBlocks}>Lock All Blocks</MenuItem>
      </Menu>

      <VoyageOfferDialog
        queryData={queryData}
        voyageId={voyageToDelete && voyageToDelete.id}
        open={deleteVoyageDialog}
        handleClose={handleVoyageClose}
      />
    </Grid>
  )
}

const useDialogStyles = makeStyles(theme => (
  {
    dialogContainer: {
      width: '600px !important'
    }
  }
))

const VoyageOfferDeleteDialog = (props) => {
  const classes = useDialogStyles()
  const { adcomValue, handleClose, open, voyage } = props
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Route Voyage Offer Details</DialogTitle>
        <DialogContent className={classes.dialogContainer}>
          <Typography>{`${voyage && formatNumber({ number: ((voyage.offer) + (voyage.offer * ((adcomValue - voyage.adcome) / 100))) })} - ${(voyage && voyage.owner) ? voyage.owner.ownerName : (voyage && voyage.user) ? voyage.user.name : ''} `}</Typography>
          <Typography>{`${voyage && voyage.route.routeName} ${voyage && voyage.route.vessel.vesselName}`}</Typography>
          <MutationHelper
            mutation={DELETE_VOYAGE_OFFER}
            {...(props.onCompleted
              ? { onCompleted: props.onCompleted }
              : {
                onCompletedObject: {
                  title: 'Success',
                  message: 'Result Created',
                  path: null
                }
              }
            )}
            variables={{ input: { id: voyage && voyage.id } }}
          >
            {(mutation, result) => {
              return (
                <RootButton
                  color='primary' onClick={() => {
                    handleClose()
                    mutation()
                  }}
                >
                  Delete Offer
                </RootButton>
              )
            }}
          </MutationHelper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary' autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const SpreadsPopper = (props) => {
  const { open, anchorEl, placement, spreads, index, forwardArray } = props
  return (
    <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper>
            <Typography>{`${formatNumber({ number: forwardArray[index], format: '0,0' })} + ${formatNumber({ number: spreads, format: '0,0'})}%`}</Typography>
          </Paper>
        </Fade>
      )}
    </Popper>
  )
}
