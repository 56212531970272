import { CREATE_ZONE_INFORMATION } from '../Queries/Queries'

const breadCrumbs = [
  {
    link: '/zones',
    name: 'Zones'
  }
]

const refetchQueries = [{
  query: CREATE_ZONE_INFORMATION
}]

const module = {
  name: 'Zone'
}

export const show = {
  createButton: {
    name: 'Create zone',
    link: '/create-zone'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'defaultBunkerPort', name: 'defaultBunkerPort' },
      { key: 'zoneName', name: 'zoneName' },
      { key: 'weatherMarginArray', name: 'weatherMarginArray', width: 520 },
      { key: 'zoneDescription', name: 'zoneDescription' }
    ],
    rows: 3
  },
  module: {
    name: 'Zone'
  },
  update: {
    link: '/update-zone'
  }
}

export const upsert = {
  module,
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Zone created',
      path: '/zones'
    },
    submitButton: 'Create Zone'
  },
  emptyObjects: {
    ports: {
      name: 'Port',
      refetchQueries
    }
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Zone updated',
      path: '/calculation-page'
    },
    submitButton: 'Update Zone'
  }
}
