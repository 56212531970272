// Import React Tools
import React, { useImperativeHandle, useState, useReducer, useEffect, forwardRef, useRef } from 'react'
import PropTypes from 'prop-types'

// Import Ag Grid Components
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

// Mutations
import { LINK_CURVES_TO_SPREAD } from '../Mutations/Mutations'

// Import Material UI Components
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import InputBase from '@material-ui/core/InputBase'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MuiAlert from '@material-ui/lab/Alert'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import RootColumnLink from '../Utils/RootColumnLink'
import { Snackbar } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'

import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

// Import Styles
import { makeStyles } from '@material-ui/core/styles'

// Apollo
import { useMutation } from 'react-apollo'
import RootLinearProgress from '../Utils/RootLinearProgress'

// Transition Component For Delete Dialog
const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// Alert For the Error Message
function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

// Styles
const useStyles = makeStyles((theme) => ({
  delete: {
    marginLeft: '5px',
    color: theme.palette.common.red
  },
  dialogContent: {
    width: '350px !important',
    maxHeight: '200px !important'
  },
  dialogButtons: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    margin: '20px'
  },
  cancel: {
    marginRight: '5px',
    color: theme.palette.common.black
  }
}))

// Initial State of mouse...?
const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

// Context Reducer
const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

const useStylesAutoComplete = makeStyles((theme) => ({
  root: {
    width: 150,
    fontSize: theme.typography.generalFontSize
  },
  noMargin: {
    margin: 0
  },
  noMarginAutoComplete: {
    width: '100%',
    height: '50%',
    margin: 0,
    '& .MuiFormControl-root': {
      margin: 0
    }
  },
  // marginBottomAutocomplete: {
  //   margin: 0,
  //   marginBottom: 8,
  //   '& .MuiFormControl-root': {
  //     margin: 0,
  //     marginBottom: 8
  //   }
  // },
  option: {
    fontSize: '12px !important',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingRight: 0
    // backgroundColor: '#A9B5BD',
    // '&:hover': {
    //   backgroundColor: 'cyan !important'
    // }
  },
  input: {
    fontSize: '12px !important',
    height: '100%',
    marginBottom: 5,
    paddingBottom: 10
  },
  textField: {
    margin: 0,
    display: 'flex',
    alignItems: 'center'
  },
  checkbox: {
    paddingBottom: 10
  }
}))

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' color='action' />

const CreateCurvesRenderer = props => {
  const { value } = props

  const [linkCurvesToSpread, { loading }] = useMutation(LINK_CURVES_TO_SPREAD, {
    onCompleted () {
      // To-do: create state for sanck bar success message and set it to true here.
    }
  })

  const [curves, setCurves] = useState(props.data.forwardCurves.map(item => item.id))

  const handleMutation = () => {
    linkCurvesToSpread({
      variables: {
        input: {
          id: props.data.id,
          curveIds: curves
        }
      }
    })
  }

  const handleCheck = (check, id) => {
    if (check) {
      setCurves(prev => [...prev, id])
    } else {
      setCurves(prev => prev.filter(item => item !== id))
    }
  }

  const classes = useStylesAutoComplete()

  const autocompleteRef = useRef()

  if (value.length === 0) {
    return null
  }

  const curveNames = []
  value.forEach(v => {
    if (curves.includes(v.id)) {
      curveNames.push(v.name)
    }
  })

  return (
    <Box display='flex' alignContent='flex-start' alignItems='flex-end' style={{ height: 30, paddingBottom: 10 }}>
      <RootLinearProgress open={loading} />
      <Autocomplete
        // focused
        multiple
        style={{ marginBottom: 20, marginTop: 0, paddingBottom: 15, paddingTop: 0, height: 0, fontSize: 0 }}
        size='small'
        options={value}
        // selectOnFocus
        // autoFocus
        disableClearable
        disableCloseOnSelect
        fullWidth
        fullHeight
        // autoHighlight
        handleHomeEndKeys
        classes={{
          // paper: classes.noMarginAutoComplete,
          option: classes.option,
          input: classes.input
        }}
        getOptionLabel={(option) => option.name}
        onClose={handleMutation}
        renderTags={() => { }}
        openOnFocus
        renderOption={(option, { selected }) => {
          return (
            <Box style={{ height: 30, paddingBottom: 0, paddingTop: 5 }} alignItems='center' flexWrap='wrap'>
              <Checkbox
                className={classes.checkbox}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8, height: 20 }}
                checked={curves.includes(option.id)}
                onChange={(check, newValue) => handleCheck(newValue, option.id)}
              />
              {option.name}
            </Box>
          )
        }}
        renderInput={(params) => {
          let label = 'Select Curves'
          if (loading) {
            label = 'Saving...'
          } else {
            if (curves.length === 1) {
              label = curveNames[0]
            } else if (curves.length > 1) {
              label = `${curveNames.length} curves`
            }
          }
          return (
            <TextField
              {...params}
              label={label}
              className={classes.textField}
              style={{ height: 0, marginBottom: 0, marginTop: 0, paddingBottom: 0, fontSize: 12 }}
              inputRef={autocompleteRef}
              InputLabelProps={{
                style: {
                  marginBottom: 0,
                  paddingBottom: 10,
                  fontSize: 12,
                  color: 'black',
                  height: 0
                }
              }}
              inputProps={{ ...params.inputProps, style: { marginBottom: 6, marginTop: 0, paddingBottom: 5, paddingTop: 7, height: 10, fontSize: 12 }, placeholder: `Curves in ${props.data.shipSector}`, readOnly: true, underline: { '&&&before': { borderBottom: 'none ' }, '&&&after': { borderBottom: 'none' } } }}
            />
          )
        }}
      />
    </Box>
  )
}

// The Component That Shows The Grid
const SpreadsAgGrid = props => {
  const {
    filteredArray = [],
    mutation,
    objectNameKey1,
    objectNameKey2,
    type,
    updateLink
  } = props

  // Get The Styles
  const classes = useStyles()

  // The Column Structure
  const columnDefs = [
    { field: 'spotTCSpreadName', headerName: 'Spot TC Spread', cellRenderer: 'link' },
    {
      field: 'curveOptions',
      headerName: 'Validation Curves',
      cellRenderer: 'curvesRenderer',
      width: 250
    },
    { field: 'spreadTCValue', headerName: 'Value', width: 300, cellRenderer: 'cellRenderer' },
    { field: 'shipSector', headerName: 'Ship Sector', width: 120, cellRenderer: 'cellRenderer' },
    { field: 'type', headerName: 'Type', width: 120, cellRenderer: 'cellRenderer' },
    { field: 'deliveryZone', headerName: 'Delivery Zone', cellRenderer: 'cellRenderer' },
    { field: 'redeliveryZone', headerName: 'Redelivery Zone', cellRenderer: 'cellRenderer' }
  ]

  // Needed Hooks For Ag Grid
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)

  // Reducer Hook To Handle Menu
  const [contextState, setContextState] = useReducer(contextReducer, initialState)

  // Hook To Open Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  // The Objects To Be Displayed In The Grid
  const [rowData, setRowData] = useState(filteredArray)

  // The Array Of Deleted Objects
  const [deletedObjectArray, setDeletedObjectArray] = useState([])

  // The Current Object
  const [currentObject, setCurrentObject] = useState({})

  // When Filtered Array Changes (Trie-Search)
  useEffect(() => {
    if (filteredArray) {
      // Show The Filtered Objects Minus The Deleted Ones
      setRowData((prev) => {
        return filteredArray.filter(item => !deletedObjectArray.includes(item.id))
      })
    }
  }, [filteredArray])

  // Right-Click On Row Routine
  const handleClick = (event, params) => {
    event.preventDefault()
    setContextState({
      event,
      selectedRow: params,
      type: 'open'
    })
    setCurrentObject(params)
  }

  // Close Menu Routine
  const handleClose = () => {
    setContextState({
      type: 'close'
    })
  }

  // Handle Click To Delete Row
  const handleClickDelete = (props) => {
    setOpenDeleteDialog(true)
  }

  // Handle Close Dialog
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false)
  }

  // Context Cell Renderer
  const ContextMenuCell = props => {
    const { value } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }

    return (
      <div onContextMenu={handleClickCell}>
        {value}
      </div>
    )
  }

  // Cell Renderer For Link
  const LinkWithContextMenu = props => {
    const { value } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }

    return (
      <Box style={{ height: 30, paddingBottom: '5px' }} onContextMenu={handleClickCell} display='flex' alignItems='flex-end'>
        <RootColumnLink viewLink={`${updateLink}/${props.data.id}`} value={value} row={props.data} />
      </Box>
    )
  }

  // Cell Renderer For Link
  const createCellRenderer = props => {
    const { value } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }

    return (
      <Box style={{ height: 30, paddingBottom: '5px' }} onContextMenu={handleClickCell} display='flex' alignItems='flex-end'>
        {value}
      </Box>
    )
  }

  // JSON With The Grid's Setup
  const gridOptions = {
    columnDefs: columnDefs.map(c => { return { ...c, cellRenderer: c.cellRenderer ? c.cellRenderer : 'contextCell' } }),
    defaultColDef: {
      filter: 'agTextColumnFilter',
      resizable: true,
      floatingFilter: false,
      sortable: true,
      debounceVerticalScrollbar: true,
      debounceHorizontalScrollbar: true,
      flex: 1,
      minWidth: 150,
      minHeight: 50
    }
  }

  // Function To Be Executed When Loading The Grid
  function onGridReady (params) {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
    setGridColumnApi(params.columnApi)
  }

  // We Render Grid + Right Click Menu + Popup
  return (
    <>
      {/* Ag Grid */}
      <div className='ag-theme-balham' style={{ height: 600, width: '100%' }}>
        <AgGridReact
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          animateRows
          rowHeight={30}
          rowData={rowData}
          rowBuffer={50}
          frameworkComponents={{
            contextCell: ContextMenuCell,
            link: LinkWithContextMenu,
            cellRenderer: createCellRenderer,
            curvesRenderer: CreateCurvesRenderer
          }}
        />
      </div>

      {/* Right Click Menu */}
      <Menu
        keepMounted
        open={contextState.mouseY !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX }
            : undefined
        }
      >

        <MenuItem onClick={handleClickDelete}>{`Delete ${type}`}</MenuItem>

      </Menu>

      {/* Popup Before Deleting */}
      <DeleteObjectDialog
        closeDialog={handleCloseDialog}
        closeMenu={handleClose}
        mutation={mutation}
        object={currentObject}
        objectNameKey1={objectNameKey1}
        objectNameKey2={objectNameKey2}
        open={openDeleteDialog}
        setRowData={setRowData}
        setDeletedObjectArray={setDeletedObjectArray}
        type={type}
      />
    </>
  )
}

const DeleteObjectDialog = props => {
  const {
    closeDialog,
    closeMenu,
    mutation,
    object,
    objectNameKey1,
    objectNameKey2,
    open,
    setRowData,
    setDeletedObjectArray,
    type
  } = props

  const classes = useStyles()

  // Delete Error
  const [snackBarMessage, setSnackBarMessage] = useState({ open: false, message: '', severity: '' })

  // Delete Object Mutation Hook #MoveBack
  const [deleteObject] = useMutation(mutation, {

    onError (error) {
      // The Error From The Server
      const errorString = error?.message?.split(':')[1]

      // The Only Acceptable Errors...
      if (errorString.includes('Routes have errors') || errorString.includes('Route has errors')) {
        // Set Message For Error Snack Bar
        setSnackBarMessage({
          open: true,
          message: `${errorString} But ${type} was deleted succesfully!`,
          severity: 'error'
        })

        // Remove Object From The Grid
        setRowData((prev) => prev.filter(item => item.id !== object.id))

        // Save It As Deleted Object
        setDeletedObjectArray((prev) => {
          const arr = prev
          arr.push(object.id)
          return arr
        })
      } else {
        setSnackBarMessage({
          open: true,
          message: errorString,
          severity: 'error'
        })
      }
    },

    onCompleted () {
      // Remove Object From The Grid
      setRowData((prev) => prev.filter(item => item.id !== object.id))

      // Save It As Deleted Object
      setDeletedObjectArray((prev) => {
        const arr = prev
        arr.push(object.id)
        return arr
      })

      setSnackBarMessage({
        open: true,
        message: `${type} deleted succesfully!`,
        severity: 'success'
      })
    }
  })

  // Close Both Dialog and Menu
  const closeDialogAndMenu = () => {
    closeMenu()
    closeDialog()
  }

  // Handle Delete Object
  const handleDelete = () => {
    // Delete Object Mutation (See onError & onCompleted)
    deleteObject({
      variables: {
        input: {
          id: object.id
        }
      }
    })

    // Close Popups
    closeDialogAndMenu()
  }

  // Render Dialog
  if (object) {
    return (
      <Grid>
        <Dialog
          TransitionComponent={Transition}
          aria-labelledby='delete-object-dialog'
          onClose={closeDialogAndMenu}
          open={open}
        >
          <Grid className={classes.dialogContent}>
            {/* Feedback Message */}
            <DialogTitle>{`You're about to delete ${type} "${object[objectNameKey1]}${(!objectNameKey2) ? '"' : ''}${(objectNameKey2) ? ` - ${object[objectNameKey2]}"` : ''}. How to proceed?`}</DialogTitle>

            {/* Buttons */}
            <Grid className={classes.dialogButtons}>
              <Button variant='outlined' className={classes.cancel} onClick={closeDialogAndMenu}>Cancel</Button>
              <Button variant='outlined' className={classes.delete} onClick={handleDelete}>Delete it</Button>
            </Grid>

          </Grid>
        </Dialog>

        {/* Error Snack Bar */}
        <Snackbar open={snackBarMessage.open} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })} variant='error'>
          <Alert severity={snackBarMessage.severity} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </Grid>
    )
  } else {
    // Prevents Bug...
    return (
      <>
      </>
    )
  }
}

// Our Componentes Requirements
SpreadsAgGrid.propTypes = {
  history: PropTypes.object.isRequired,
  rowData: PropTypes.array.isRequired
}

export default SpreadsAgGrid
