const breadCrumbs = [
  {
    link: '/ship-sectors',
    name: 'Ship Sectors'
  }
]

export const show = {
  createButton: {
    name: 'Create ship sector',
    link: '/create-ship-sector'
  },
  dataGrid: {
    columns: [
      { key: 'id', name: 'ID', hidden: true },
      { key: 'shipSectorName', name: 'shipSectorName' },
      { key: 'dwtMin', name: 'dwtMin' },
      { key: 'dwtMax', name: 'dwtMax' },
      { key: 'sw', name: 'sw' },
      { key: 'bw', name: 'bw' },
      { key: 'fw', name: 'fw' },
      { key: 'tf', name: 'tf' }
    ],
    rows: 3
  },
  module: {
    name: 'Ship sector'
  },
  update: {
    link: '/update-ship-sector'
  }
}

export const upsert = {
  create: {
    breadCrumbs: [...breadCrumbs],
    current: 'Create',
    onCompleted: {
      title: 'Success',
      message: 'Ship sector created',
      path: '/ship-sectors'
    },
    submitButton: 'Create Ship Sector'
  },
  update: {
    breadCrumbs: [...breadCrumbs],
    current: 'Update',
    onCompleted: {
      title: 'Success',
      message: 'Ship sector updated',
      path: '/calculation-page'
    },
    submitButton: 'Update Ship Sector'
  }
}
