// React Library
import React, { useState, useReducer, useEffect, forwardRef } from 'react'

// Prop Types
import PropTypes from 'prop-types'

// Ag Grid
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

// Utils
import RootColumnLink from '../Utils/RootColumnLink'
import { format, utcToZonedTime } from 'date-fns-tz'

// Material UI
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import MuiAlert from '@material-ui/lab/Alert'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import { Snackbar, Typography } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'

// Styles
import { makeStyles } from '@material-ui/core/styles'
import differenceInHours from 'date-fns/differenceInHours'
import clsx from 'clsx'

// Mutation Related
import { DELETE_OWNER } from '../Mutations/Mutations'

// Apollo
import { useMutation } from 'react-apollo'

// Transition Component For Delete Dialog
const Transition = forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

// Alert For the Error Message
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Function To Format Date
const getFormattedDate = (updated, timeZone, i) => {
  let date, zonedTime
  if (i >= 0) {
    zonedTime = (updated[i] && updated[i]) ? utcToZonedTime(updated[i], timeZone) : ''
    date = (updated[i] && updated[i]) ? new Date(updated[i]) : ''
  } else {
    zonedTime = (updated && updated) ? utcToZonedTime(updated, timeZone) : ''
    date = (updated && updated) ? new Date(updated) : ''
  }

  const year = date.toLocaleString('default', { year: 'numeric', timeZone })
  const month = date.toLocaleString('default', { month: 'short', timeZone })
  const day = date.toLocaleString('default', { day: 'numeric', timeZone })

  const timeString = `${day}${month}${year} ${format(zonedTime, 'HH:mm:ss', { timeZone })}`

  return timeString // returns a string
}

const useStyles = makeStyles((theme) => ({
  distanceOnly: {
    color: 'red'
  },
  isToday: {
    color: 'green'
  },
  isYesterday: {
    color: 'orange'
  },
  isFourDays: {
    color: 'red'
  },
  delete: {
    marginLeft: '5px',
    color: theme.palette.common.red
  },
  dialogContent: {
    width: '350px !important',
    maxHeight: '200px !important'
  },
  dialogButtons: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'center',
    margin: '20px'
  },
  cancel: {
    marginRight: '5px',
    color: theme.palette.common.black
  }
}))

const initialState = {
  mouseX: null,
  mouseY: null,
  selectedRow: null
}

const contextReducer = (state, action) => {
  switch (action.type) {
    case 'close':
      return {
        ...initialState
      }
    case 'open':
      return {
        ...state,
        mouseX: action.event.clientX - 2,
        mouseY: action.event.clientY - 4,
        selectedRow: action.selectedRow
      }
    default:
      return {
        ...state
      }
  }
}

// COLUMN DEFS
const columnsDefs = [
  { field: 'ownerName', headerName: 'Owner', cellRenderer: 'link' },
  { field: 'avgOffer', headerName: 'Avg Offer' },
  { field: 'avgAdcome', headerName: 'Avg Adcom' },
  { field: 'acceptedOffers', headerName: 'Accepted' },
  { field: 'acceptedPer', headerName: 'Accepted %', cellRenderer: 'per' },
  { field: 'totalOffers', headerName: 'Given' },
  { field: 'recentOffer', headerName: 'Latest Offer', cellRenderer: 'oldDate' }
]

const editLink = 'update-owner'

const ShowOwnersAgGrid = props => {
  const {
    history,
    owners = []
  } = props

  const classes = useStyles()

  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)
  /*
  CONTEXT MENU STATES
  */
  const [contextState, setContextState] = useReducer(contextReducer, initialState)

  // Hook To Open Dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  // The Objects To Be Displayed In The Grid
  const [rowData, setRowData] = useState(owners)

  // The Array Of Deleted Objects
  const [deletedObjectArray, setDeletedObjectArray] = useState([])

  // The Current Object
  const [currentObject, setCurrentObject] = useState({})

  // When Filtered Array Changes (Trie-Search)
  useEffect(() => {
    if (owners) {
      // Show The Filtered Objects Minus The Deleted Ones
      setRowData((prev) => {
        return owners.filter(item => !deletedObjectArray.includes(item.id))
      })
    }
  }, [owners])

  // Right-Click On Row Routine
  const handleClick = (event, params) => {
    event.preventDefault()
    setContextState({
      event,
      selectedRow: params,
      type: 'open'
    })
    setCurrentObject(params)
  }

  // Close Menu Routine
  const handleClose = () => {
    setContextState({
      type: 'close'
    })
  }

  // Handle Click To Delete Row
  const handleClickDelete = (props) => {
    setOpenDeleteDialog(true)
  }

  // Handle Close Dialog
  const handleCloseDialog = () => {
    setOpenDeleteDialog(false)
  }
  /*
  CELL RENDERER WITH CONTEXT MENU
  */
  const ContextMenuCell = props => {
    const { value } = props
    // return <div>{`Renderer 1: ${value}`}</div>
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }
    return (
      <div onContextMenu={handleClickCell}>
        {value}
      </div>
    )
  }

  const LinkWithContextMenu = props => {
    const { value } = props
    const handleClickCell = (e) => {
      handleClick(e, props.data)
    }

    return (
      <div onContextMenu={handleClickCell}>
        <RootColumnLink viewLink={`${editLink}/${props.data.id}`} value={value} row={props.data} />
      </div>
    )
  }

  const PerCellRenderer = props => {
    const { value } = props
    let color
    if (value > 66) {
      color = '#8BBD8B'
    } else if (value < 34) {
      color = '#E44E58'
    } else {
      color = '#DC6B41'
    }
    return (
      <div>
        <div style={{ width: `${value}%`, backgroundColor: color }}>
          {value}%
        </div>
      </div>
    )
  }

  const gridOptions = {
    columnDefs: columnsDefs.map(c => { return { ...c, cellRenderer: c.cellRenderer ? c.cellRenderer : 'contextCell' } }),
    defaultColDef: {
      filter: 'agTextColumnFilter',
      resizable: true,
      floatingFilter: false,
      sortable: true,
      debounceVerticalScrollbar: true,
      debounceHorizontalScrollbar: true,
      flex: 1,
      minWidth: 150
    }
  }

  function onGridReady (params) {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
    setGridColumnApi(params.columnApi)
  }

  const OldDate = props => {
    const { value } = props
    const hours = differenceInHours(new Date(Date.now()), new Date(value))

    return (
      <div
        className={clsx({
          [classes.isFourDays]: hours > 96,
          [classes.isYesterday]: hours > 24 && hours <= 96,
          [classes.isToday]: hours <= 24
        })}
      >{value && getFormattedDate(value, 'Europe/Copenhagen')}
      </div>
    )
  }
  return (
    <>
      <div className='ag-theme-balham' style={{ height: 600, width: '100%' }}>
        <AgGridReact
          gridOptions={gridOptions}
          onGridReady={onGridReady}
          animateRows
          rowData={rowData}
          rowBuffer={50}
          frameworkComponents={{
            contextCell: ContextMenuCell,
            link: LinkWithContextMenu,
            oldDate: OldDate,
            per: PerCellRenderer
          }}
        />
      </div>

      {/* Right Click Menu */}
      <Menu
        keepMounted
        open={contextState.mouseY !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={
          contextState.mouseY !== null && contextState.mouseX !== null
            ? { top: contextState.mouseY, left: contextState.mouseX }
            : undefined
        }
      >

        <MenuItem onClick={handleClickDelete}>Delete Owner</MenuItem>

      </Menu>

      {/* Popup Before Deleting */}
      <DeleteObjectDialog
        closeDialog={handleCloseDialog}
        closeMenu={handleClose}
        mutation={DELETE_OWNER}
        object={currentObject}
        objectNameKey1='ownerName'
        open={openDeleteDialog}
        setRowData={setRowData}
        setDeletedObjectArray={setDeletedObjectArray}
      />
    </>
  )
}

const DeleteObjectDialog = props => {
  const {
    closeDialog,
    closeMenu,
    mutation,
    object,
    objectNameKey1,
    objectNameKey2,
    open,
    setRowData,
    setDeletedObjectArray
  } = props

  const classes = useStyles()

  // Delete Error
  const [snackBarMessage, setSnackBarMessage] = useState({ open: false, message: '', severity: ''})

  // Delete Object Mutation Hook #MoveBack
  const [deleteObject] = useMutation(mutation, {

    onError (error) {
      // The Error From The Server
      const errorString = error?.message?.split(':')[1]

      // The Only Acceptable Errors...
      if (errorString.includes('Routes have errors') || errorString.includes('Route has errors')) {

        // Set Message For Error Snack Bar
        setSnackBarMessage({
          open: true,
          message: `${errorString} But Owner was deleted succesfully!`,
          severity: 'error'
        })

        // Remove Object From The Grid
        setRowData((prev) => prev.filter(item => item.id !== object.id))

        // Save It As Deleted Object
        setDeletedObjectArray((prev) => {
          const arr = prev
          arr.push(object.id)
          return arr
        })

      } else {

        setSnackBarMessage({
          open: true,
          message: errorString,
          severity: 'error'
        })

      }
    },

    onCompleted () {
      
      // Remove Object From The Grid
      setRowData((prev) => prev.filter(item => item.id !== object.id))

      // Save It As Deleted Object
      setDeletedObjectArray((prev) => {
        const arr = prev
        arr.push(object.id)
        return arr
      })

      setSnackBarMessage({
        open: true,
        message: `Owner deleted succesfully!`,
        severity: 'success'
      })

    }
  })


  // Close Both Dialog and Menu
  const closeDialogAndMenu = () => {
    closeMenu()
    closeDialog()
  }

  // Handle Delete Object
  const handleDelete = () => {
    // Delete Object Mutation (See onError & onCompleted)
    deleteObject({
      variables: {
        input: {
          id: object.id
        }
      }
    })

    // Close Popups
    closeDialogAndMenu()
  }

  // Render Dialog
  if (object) {
    return (
      <Grid>
        <Dialog
          TransitionComponent={Transition}
          aria-labelledby='delete-object-dialog'
          onClose={closeDialogAndMenu}
          open={open}
        >
          <Grid className={classes.dialogContent}>
            {/* Feedback Message */}
            <DialogTitle>{`You're about to delete Owner "${object[objectNameKey1]}${(!objectNameKey2) ? '"' : ''}${(objectNameKey2) ? ` - ${object[objectNameKey2]}"` : ''}. How to proceed?`}</DialogTitle>

            {/* Buttons */}
            <Grid className={classes.dialogButtons}>
              <Button variant='outlined' className={classes.cancel} onClick={closeDialogAndMenu}>Cancel</Button>
              <Button variant='outlined' className={classes.delete} onClick={handleDelete}>Delete it</Button>
            </Grid>

          </Grid>
        </Dialog>

        {/* Error Snack Bar */}
        <Snackbar open={snackBarMessage.open} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })} variant='error'>
          <Alert severity={snackBarMessage.severity} onClose={() => setSnackBarMessage({ open: false, message: '', severity: '' })}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </Grid>
    )
  } else {
    // Prevents Bug...
    return (
      <>
      </>
    )
  }
}

ShowOwnersAgGrid.propTypes = {
  history: PropTypes.object.isRequired,
  rowData: PropTypes.array.isRequired
}

export default ShowOwnersAgGrid
